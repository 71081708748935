<!--List and Grid View-->
<mat-spinner style="margin:0 auto;" *ngIf="showLoader"></mat-spinner>
<div *ngIf="(galleryList | json) == '{}' && !showLoader" class="row" id="preview_hidden"
  style="margin: 9rem auto;width: fit-content;">
  <img src="../../../assets/images/viewer_icon/no_gallery_data.png" />
</div>
<div class="gridscroll">
  <div class="accordion" id="accordionExample_l">
    <div class="card" *ngFor="let slide of galleryList | keyvalue: returnZero;let i = index">
      <div class="card-header" [ngClass]="{'collapsed': i!=openCollapseIndex}" id="{{'heading_'+i}}"
        [attr.key]="slide.key" [attr.index]="i" (click)="groupChange(i, slide.key)">
        <i class="fa fa-angle-down rotate-icon"></i>
        <input (click)="$event.stopPropagation();"
          *ngIf="((activeMode == 'image-viewer' && dialogData?.dialogType == 'compare') || activeMode != 'image-viewer')  && activeMode != 'scanned' && slide.value.alreadyrender && i==openCollapseIndex"
          class="cloumnIcons_acc {{'selectAll_'+i}}" type="checkbox" [checked]="slide.value?.selecteMessage"
          (change)="checkAll($event, slide.value.slidesArr, slide.key)">
        <span class="textSpan">{{slide.key}}</span>
        <span class="selectedMsgText" *ngIf="slide.value?.selecteMessage"> ({{slide.value?.selecteMessage}})</span>
      </div>
      <div [ngClass]="{'show': i == openCollapseIndex}" class="collapse" data-parent="#accordionExample_l"
        (scroll)="onTableScroll($event,slide.key)">
        <!-- data-parent="#accordionExample_l" -->
        <div class="card-body">
          <!--Table Data Display as per tab selection-->
          <div class="row tabledata" *ngIf="slide.value.alreadyrender && slide.value.slidesArr?.length">
            <table id="{{'gallery_list_'+i}}" class="display list_one_table gallery_list table table-hover"
              cellspacing="0" width="100%" *ngIf="activeMode != 'scanned'">
              <thead class="fullwidth ">
                <tr *ngIf="activeMode != 'scanned'">
                  <th scope="col"></th>
                  <th scope="col">Subject Id </th>
                  <th scope="col" *ngIf="!galleryFilter.isTCRStudy">Dosage</th>
                  <th scope="col" *ngIf="galleryFilter.isTCRStudy">Control</th>
                  <th scope="col">Sex</th>
                  <!-- <th scope="col">Stain</th> -->
                  <!-- <th scope="col">WNL</th> -->
                  <th scope="col">Tissue</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let animal of slide.value.slidesArr;let j = index"
                  [ngClass]="{ 'active' : (selectgroupSlideKey == i+'_'+j) && preview, 
                  'grayout' : (animal.opened),'boldrows' : (!animal.opened),
                  'disablebtn_func' : (isSelectedSample(animal.slidePk) && activeMode == 'image-viewer' && (dialogData?.dialogType == 'filter' || dialogData?.dialogType == 'compare'))}">
                  <!-- 'disablebtn_func' : (dialogData?.selectedImage == animal.slidePk && activeMode == 'image-viewer' && (dialogData?.dialogType == 'filter' || dialogData?.dialogType == 'compare'))}"> -->
                  <td class="icondisplay">
                    <div class="iconinline">
                      <input class="cloumnIcons" aria-label="" type="checkbox" [checked]="animal.state == 'true'"
                        (click)="setSelectedSlideList(animal,$event.target.checked, j, slide.key);"
                        [(ngModel)]="animal.state" [disabled]="animal.isDisabled"
                        *ngIf="((activeMode == 'image-viewer' && dialogData?.dialogType == 'compare') || activeMode != 'image-viewer')  && activeMode != 'scanned'">
                      <div title="Show Preview" (click)="openPreview(i+'_'+j);"><i class="fa fa-eye righteyeicon"></i>
                      </div>
                      <!-- <span class="cloumnIcons imgicontable" (click)="openViewer(animal.slide.slidePk)"><i
                        class="fa fa-picture-o imageicon"></i></span> -->

                      <div class="float-right " *ngIf="animal.readStatus == 'UNREAD'">
                        <div class="readgrey" title="Unread"><i class="fa fa-circle"></i></div>
                      </div>
                      <div class="float-right " *ngIf="animal.readStatus == 'READ'">
                        <div class="readorange" title="Read"><i class="fa fa-circle"></i></div>
                      </div>
                      <div class="float-right " *ngIf="animal.reviewStatus == 'LATER'">
                        <div class="readorange reviewfont" title="Review Later"><i class="fa fa-sticky-note"></i></div>
                      </div>
                      <div class="float-right " *ngIf="animal.reviewStatus == 'DONE'">
                        <div class="readgrey reviewfont" title="Review Done"><i class="fa fa-sticky-note"></i></div>
                      </div>
                      <div class="float-right " *ngIf="animal.reviewStatus != 'DONE' && animal.reviewStatus != 'LATER'">
                        <div class="readgrey reviewfont" title="Not marked for review"><i
                            class="fa fa-sticky-note-o"></i></div>
                      </div>

                      <!-- <span class="cloumnIcons peerreview_icon" data-toggle="tooltip" data-html="true" title="Peer Review Image">P</span> -->
                      <!-- <span class="cloumnIcons"><i class="fa fa-share share_icon" aria-hidden="true" data-toggle="tooltip" data-html="true" title="Mark for Share"></i></span> -->
                    </div>
                  </td>
                  <td class="pointer" (click)="openViewer(animal.slidePk, i, slide.key)"><span>{{animal.animalNo}}</span></td>
                  <td class="pointer" *ngIf="!galleryFilter.isTCRStudy"
                    (click)="openViewer(animal.slidePk, i, slide.key)"><span>{{animal.dosageLevel}}</span></td>
                  <td class="pointer" *ngIf="galleryFilter.isTCRStudy"
                    (click)="openViewer(animal.slidePk, i, slide.key)">
                    <span>{{animal.slideControl=='Test' ? '' : animal.slideControl}}</span></td>
                  <td class="pointer" (click)="openViewer(animal.slidePk, i, slide.key)"><span>{{animal.sex}}</span></td>
                  <!-- <td>{{animal.cassetteOrgan.stain}}</td> -->
                  <!-- <td>{{animal.slide.slideComments}}</td> -->
                  <td class="pointer" (click)="openViewer(animal.slidePk, i, slide.key)">
                  <span>{{animal.organ?.join(', ')}}</span></td>
                </tr>
              </tbody>
            </table>
            <mat-spinner style="margin:0 auto;" *ngIf="showLoaderScannedList && activeMode == 'scanned'"></mat-spinner>
            <table id="{{'gallery_list_'+i}}" class="display list_one_table gallery_list table table-hover tablewidth"
              cellspacing="0" width="100%" *ngIf="activeMode == 'scanned' && !showLoaderScannedList">
              <thead class="fullwidth ">
                <!-- below tr for view by folderlocation in scanned -->
                <tr>
                  <th scope="col">Image Name ({{slide.value.slidesArr.length}})</th>
                </tr>
              </thead>
              <!-- below tbody for viewby foldrlocation in scanned -->
              <tbody *ngIf="activeMode == 'scanned' && slide.value.slidesArr.length > 0">
                <tr *ngFor="let animal of slide.value.slidesArr;let j = index">
                  <td>
                    {{animal}}</td>
                </tr>
              </tbody>
              <tbody *ngIf="activeMode == 'scanned' && slide.value.slidesArr.length == 0">
                <tr>
                  <td>
                    No File Found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!slide.value.slidesArr?.length" class="nodataImg">
            <img src="../../../assets/images/viewer_icon/no_gallery_data.png" />
          </div>
        </div>
        <div class="loadingMessage" *ngIf="slide.value && slide.value.pageInfo && slide.value.pageInfo.more">
          <div class="loadMessage">
            <span>Loading...</span>
            <!-- <span *ngIf="!slide.value.pageInfo.more">No More data to load</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
