<div class="view_wrap">
  <div class="top_view_bar">
    <div class="disableTopBar"></div>
    <div class="row" style="margin: 0;">
      <div class="col-sm-4" style="padding: 0%;">
        <ul class="icon_inline">
          <li class="topbarlogo nohoverli">
            <img src="../../../assets/images/viewer_icon/logo_512.png" />
          </li>

          <li class="nohoverli">
            <p class="viewer_studynm" *ngIf="openedFrom == 'gallery' || openedFrom == 'mapped'"
              [title]="slideData?.study?.studyNo">
              {{slideData?.study?.studyNo}}</p>
          </li>
          <li title="Study Details">
            <!-- {{this.slideData.study.isTCR |  json }} -->
            <button *ngIf="openedFrom == 'gallery' || openedFrom == 'mapped'" type="button" class="btn infoicon"
              (click)="openStudyDetailDialog()" aria-label="studydetail"><img
                src="../../../assets/images/viewer_icon/Study Details Topbar-Primary.svg" alt="Study Details"
                aria-label="Study Details" /></button>
          </li>
          <!-- <li (click)="toggleSlideOverview()">
                    <img src="../../../assets/images/viewer_icon/Slide_Label_Icon.svg" alt="Slide Label" />
                    
                </li> -->
          <li [ngClass]="{'img-viewer-icon-selected': panelList.Slide_Overview.isVisible}" *ngIf="!noImageFlag">
            <div class="tabordion">
              <section id="section1">
                <input type="radio" name="sections" id="option1">
                <label for="option1" (click)="togglePanel('Slide_Overview')" title="Slide Label">
                  <img src="../../../assets/images/viewer_icon/Slide Label Topbar-Primary.svg" alt="Slide Label"
                    aria-label="Slide Label" />

                </label>
                <article *ngIf="panelList.Slide_Overview.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Slide Label &amp; Overview</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('Slide_Overview')"></i>
                    </div>
                  </div>
                  <div class="divpop_body">
                    <!--Slider-->
                    <div class="grid">
                      <div class="slider-box">
                        <div class="slider" id="slider">
                          <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="slider__item carousel-item active" style="height:9vw;">
                                <img [ngStyle]="{'transform': 'rotate('+ config.labelViewRotateDegree +'deg)'}"
                                  src="{{thumbnailUrl+slideData?.slide?.identifier+'_label.jpeg'}}" height="150px"
                                  alt="sample_img" align="middle" class="d-block">
                              </div>
                              <div class=" slider__item carousel-item" style="height:9vw;">
                                <img src="{{thumbnailUrl+slideData?.slide?.identifier+'_macro.jpeg'}}" height="150px"
                                  alt="sample_img" align="middle" class="d-block">
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="slider-nav">
                          <div class="slider-nav__prev carousel-control-prev" href="#carouselExampleControls"
                            data-slide="prev" id="prev"><i class="fa fa-angle-left"></i>
                          </div>
                          <div class="slider-nav__next carousel-control-next" id="next" href="#carouselExampleControls"
                            data-slide="next"><i class="fa fa-angle-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Slider End-->
                    <!--Slide Details-->
                    <div class="topslidedetail">
                      <table class="table table-bordered">
                        <tr>
                          <td>
                            <label>Image Name:</label>
                            <p>{{slideData?.slide?.slidename}}</p>
                          </td>
                          <td>
                            <label>Study No:</label>
                            <p>{{slideData?.study?.studyNo}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>Slide ID:</label>
                            <p>{{slideData?.slide?.slideLabel}}</p>
                          </td>
                          <td>
                            <label>Tissue Id:</label>
                            <p>{{slideData?.cassetteOrgan.organ}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>Subject Id:</label>
                            <p>{{slideData?.animal?.animalNo}}</p>
                          </td>
                          <td *ngIf="!slideData.study.isTCRStudy">
                            <label>Dosage Group:</label>
                            <p>{{slideData?.animal?.dosageCode}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>Treatment:</label>
                            <p *ngIf="slideData?.study?.treatment">{{slideData?.study?.treatment}}</p>
                            <p *ngIf="!slideData?.study?.treatment">--</p>
                          </td>
                          <td>
                            <label>Sex:</label>
                            <p *ngIf="slideData.animal.sex == 'M'">Male</p>
                            <p *ngIf="slideData.animal.sex == 'F'">Female</p>
                            <p *ngIf="slideData.animal.sex == 'N'">Not Known</p>
                          </td>

                        </tr>
                        <tr *ngIf="!slideData.study.isTCRStudy">
                          <td>
                            <label>Dose:</label>
                            <p *ngIf="slideData?.animal?.dose ">{{slideData?.animal?.dose}}</p>
                            <p *ngIf="!slideData?.animal?.dose">--</p>
                          </td>
                          <td>
                            <label>Dose Units:</label>
                            <p *ngIf="slideData?.animal?.dosageUnit ">{{slideData?.animal?.dosageUnit}}</p>
                            <p *ngIf="!slideData?.animal?.dosageUnit">--</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>Sacrifice:</label>
                            <p *ngIf="slideData?.study?.sacrifice">{{slideData?.study?.sacrifice}}</p>
                            <p *ngIf="!slideData?.study?.sacrifice">--</p>
                          </td>
                          <td>
                            <label>Magnification:</label>
                            <p *ngIf="slideData?.slide?.scanner?.scanMagnification">
                              {{slideData?.slide?.scanner.scanMagnification}}</p>
                            <p *ngIf="!slideData?.slide?.scanner?.scanMagnification">--</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>Scan Date:</label>
                            <p *ngIf="slideData?.slide?.scanDateTime">{{slideData?.slide?.scanDateTime}}</p>
                            <p *ngIf="!slideData?.slide?.scanDateTime">--</p>
                          </td>
                          <td>
                            <label>Tissue Section Cutting:</label>
                            <p *ngIf="slideData?.slide?.tissueSectionCutting ">
                              {{slideData?.slide?.tissueSectionCutting}}
                            </p>
                            <p *ngIf="!slideData?.slide?.tissueSectionCutting">--</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>Stain:</label>
                            <p *ngIf="slideData?.cassetteOrgan?.stain">{{slideData?.cassetteOrgan.stain}}</p>
                            <p *ngIf="!slideData?.cassetteOrgan?.stain">--</p>
                          </td>
                          <td *ngIf="slideData.study.isTCRStudy">
                            <label>Chromogen :</label>
                            <p *ngIf="slideData?.ihcStain?.chromogen ">{{slideData?.ihcStain.chromogen}}</p>
                            <p *ngIf="!slideData?.ihcStain?.chromogen">--</p>
                          </td>
                        </tr>
                        <tr *ngIf="slideData.study.isTCRStudy">
                          <td>
                            <label>IHC Experiment Num:</label>
                            <p *ngIf="slideData?.ihcStain?.ihcExperimentNumber">
                              {{slideData?.ihcStain.ihcExperimentNumber}}</p>
                            <p *ngIf="!slideData?.ihcStain?.ihcExperimentNumber">--</p>
                          </td>
                          <td>
                            <label>Target Antigen:</label>
                            <p *ngIf="slideData?.ihcStain?.targetAntigen ">{{slideData?.ihcStain.targetAntigen}}</p>
                            <p *ngIf="!slideData?.ihcStain?.targetAntigen">--</p>
                          </td>
                        </tr>
                        <tr *ngIf="slideData.study.isTCRStudy">
                          <td>
                            <label>Primary AntiBody Id:</label>
                            <p *ngIf="slideData?.ihcStain?.primaryAntibodyID">{{slideData?.ihcStain.primaryAntibodyID}}
                            </p>
                            <p *ngIf="!slideData?.ihcStain?.primaryAntibodyID">--</p>
                          </td>
                          <td>
                            <label>Secondary AntiBody Id:</label>
                            <p *ngIf="slideData?.ihcStain?.secondayAntibodyID ">
                              {{slideData?.ihcStain.secondayAntibodyID}}
                            </p>
                            <p *ngIf="!slideData?.ihcStain?.secondayAntibodyID">--</p>
                          </td>
                        </tr>
                        <tr *ngIf="slideData.study.isTCRStudy">
                          <td>
                            <label>Detection Method:</label>
                            <p *ngIf="slideData?.ihcStain?.detectionMethod">{{slideData?.ihcStain.detectionMethod}}</p>
                            <p *ngIf="!slideData?.ihcStain?.detectionMethod">--</p>
                          </td>
                          <td>
                            <label>Comments:</label>
                            <p *ngIf="slideData?.slide?.slideComments">{{slideData?.slide?.slideComments}}</p>
                            <p *ngIf="!slideData?.slide?.slideComments">--</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </li>
          <!-- li Slide ROI starts  -->
          <li [ngClass]="{'img-viewer-icon-selected': panelList?.Slide_ROI.isVisible}" *ngIf="!noImageFlag">
            <div class="tabordion">
              <section id="section2">
                <input type="radio" name="sections" id="option2">
                <label for="option2" (click)="togglePanel('Slide_ROI')" title="Slide ROIs">
                  <img src="../../../assets/images/viewer_icon/ROI-Primary.svg" alt="Slide ROI"
                    aria-label="Slide ROI" />

                </label>
                <article *ngIf="panelList?.Slide_ROI.isVisible">
                  <div class="roidiv">
                    <!--Name of Popup Start-->
                    <div class="row divpop_header">
                      <div class="col-sm-10">
                        <p>Slide ROI ({{totalROIs}})</p>
                      </div>
                      <div class="col-sm-2 closing">
                        <i class="fa fa-times rightfloat" (click)="togglePanel('Slide_ROI')"></i>
                      </div>
                    </div>
                    <!--Name of Popup End-->
                    <!--Slider-->
                    <div class="grid">
                      <div class="slider-box">
                        <div class="slider" id="roi_slider">
                          <div id="crousal_ROI_slider" class="carousel slide" data-interval="false">
                            <div class="carousel-inner">
                              <div class="slider__item carousel-item" [ngClass]="{'active' : i == activeROIIndex}"
                                style="height:9vw;" *ngFor="let item of [].constructor(totalROIs); let i = index">
                                <img src="{{thumbnailUrl+slideData?.slide?.identifier+'_roi_'+(i+1)+'.jpeg'}}"
                                  style="height:9vw;" alt="sample_img" align="middle" class="d-block w-50">
                                <p class="roicount">{{i + 1}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="slider-nav">
                          <div class="slider-nav__prev carousel-control-prev" id="prev" (click)="onPrevious()"
                            [ngClass]="{'next-prev-dis' : !prevRoi}" href="#crousal_ROI_slider" data-slide="prev">
                            <i class="fa fa-angle-left"></i>
                          </div>
                          <div class="slider-nav__next carousel-control-next" id="next" (click)="onNext()"
                            [ngClass]="{'next-prev-dis' : !nextRoi}" href="#crousal_ROI_slider" data-slide="next">
                            <i class="fa fa-angle-right"></i>
                          </div>
                          <!--<div class="slider-nav__dots" id="dots"></div>-->
                        </div>
                      </div>
                    </div>
                    <!--Slider End-->
                  </div>
                </article>
              </section>
            </div>
          </li>
          <li *ngIf="openedFrom == 'gallery' && !isSplitView && !noImageFlag" class="nohoverli">
            <p *ngIf="slideData?.slide?.readStatus == 'UNREAD'" class="readgrey" title="Unread"><i
                class="fa fa-circle"></i></p>
            <p *ngIf="slideData?.slide?.readStatus == 'READ'" class="readorange" title="Read"><i
                class="fa fa-circle"></i>
            </p>
            <span _ngcontent-kva-c289="" class="downdropdown" style="visibility: hidden;"></span>
          </li>
          <li *ngIf="openedFrom == 'gallery' && !isSplitView && !noImageFlag" class="nohoverli">
            <p *ngIf="slideData?.slide?.reviewStatus == 'LATER'" class="readorange" title="Review Later"><i
                class="fa fa-sticky-note"></i></p>
            <p *ngIf="slideData?.slide?.reviewStatus == 'DONE'" class="readgrey" title="Review Done"><i
                class="fa fa-sticky-note"></i></p>
            <p *ngIf="slideData?.slide?.reviewStatus != 'DONE' && slideData?.slide?.reviewStatus != 'LATER'"
              class="readgrey" title="Not marked for review"><i class="fa fa-sticky-note-o"></i></p>
            <span _ngcontent-kva-c289="" class="downdropdown" style="visibility: hidden;"></span>
          </li>
          <!-- li Slide ROI ends  -->
          <!-- <li *ngIf="!noImageFlag && config.showImportAnalysis" title="Import Analysis Result" >
            <i class="fa fa-download analysisIcon" aria-hidden="true"
              *ngIf="activeRole == 'PATHOLOGIST' && !isSplitView" (click)="getAnalysisResult()"
              [ngClass]="{'disableFunction' : isResultLoaded}" ></i>
          </li> -->
          <li class="nohoverli dn">
            <p class="tileLevel">{{currentTileLevel}}</p>
          </li>
        </ul>
      </div>
      <div class="col-sm-4 middlecenter">
        <div class="row middletraverse">
          <ul>
            <li (click)="previousSlide()" id="prevBtn" [ngClass]="{'next-prev-dis' : !prevSlideId || isSplitView}">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </li>
            <li [ngClass]="{'disableGallery' : isSplitView}" class="nohoverli" title="{{slideData?.animal?.animalNo}}">
              <button type="button" class="btn" data-toggle="modal"
                [ngClass]="{'exampleModalLong': openedFrom=='gallery', 'exampleModalLong_noimg': openedFrom !='gallery'}"
                data-target="#exampleModalLong" (click)="openGalleryModal('filter')">
                <span class="btnTextTopBar" *ngIf="openedFrom == 'gallery' || openedFrom == 'mapped'"
                  title="{{slideData?.animal?.animalNo}}">{{slideData?.animal?.animalNo}}</span>
                <span class="btnTextTopBar" *ngIf="openedFrom == 'scanned' || openedFrom == 'uploaded'"
                  title="{{slideData?.animal?.slidePk}}">{{slideData?.slide?.slidePk}}</span>
                <!-- <span *ngIf="openedFrom=='gallery'" class="modalcaret"></span> -->
              </button>
            </li>
            <li (click)="nextSlide()" id="nextBtn" [ngClass]="{'next-prev-dis' : !nextSlideId || isSplitView}">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4" style="padding-right: 0;">
        <ul class="icon_inline rightfloat">

          <!-- li Fine Tune starts  -->
          <li *ngIf="!isSplitView && enableFineTune">
            <p class="fineTune" *ngIf="!isTopLevelTile && fineTuneEnable">
              <img id="imgFineTuneOn" (click)="setFineTune(false)"
                src="../../../assets/images/viewer_icon/FineTuneOn.png" title="Fine Tuning On" alt="Fine Tuning On"
                aria-label="Fine Tuning On" style="display: none;" />
              <img id="imgFineTuneOff" (click)="setFineTune(true)"
                src="../../../assets/images/viewer_icon/FineTuneOff.png" title="Fine Tuning Off" alt="Fine Tuning Off"
                aria-label="Fine Tuning Off" />
            </p>
            <p class="fineTune" *ngIf="isTopLevelTile || !fineTuneEnable">
              <img id="imgFineTuneDisabled" src="../../../assets/images/viewer_icon/FineTuneOff.png"
                title="Optimum view" alt="Optimum view" style="opacity: 0.5;" aria-label="Optimum view" />
            </p>
          </li>
          <!-- li Fine Tune ends  -->

          <li class="nohoverli">
            <p class="networkStatus">
              <img id="imgInternetStatus" src="../../../assets/images/viewer_icon/no-connection.png"
                style="width: 1.5vw;margin: 0.25vw;padding: 0.1vw;vertical-align: text-top;" title="Server Status"
                alt="Network Status" aria-label="Network Status" />
            </p>
            <span _ngcontent-kva-c289="" class="downdropdown" style="visibility: hidden;"></span>
          </li>

          <li *ngIf="openedFrom == 'gallery' && !isSplitView && !noImageFlag"
            [ngClass]="{'img-viewer-icon-selected': panelList?.Slide_Share.isVisible}">
            <div class="tabordion_ryt">
              <section id="section19">
                <input type="radio" name="sections" id="option19" checked>
                <label for="option19" (click)="togglePanel('Slide_Share')" title="Mark">
                  <mat-icon
                    style="-webkit-transform: scaleX(-1);
                transform: scaleX(-1);font-size: 1.3vw;width: auto;height: auto;padding: .5vw 0.3vw 0 0.3vw;margin: 0;color: #fff;">
                    reply</mat-icon>

                </label>
                <article *ngIf="panelList?.Slide_Share.isVisible" class="dropdown-menu sharePanel"
                  aria-labelledby="dropdownMenuLink">
                  <div class="divpop_body">
                    <a *ngIf="slideData?.slide?.readStatus == 'UNREAD'" class="dropdown-item"
                      style=" line-height: 1.5;font-size: .75vw !important;" (click)="changeReadStatus('READ')"
                      href="javascript:void(0)">Mark as Read</a>
                    <a *ngIf="slideData?.slide?.readStatus == 'READ'" class="dropdown-item"
                      style=" line-height: 1.5; font-size: .75vw !important;" (click)="changeReadStatus('UNREAD')"
                      href="javascript:void(0)">Mark as Unread</a>
                    <a *ngIf="slideData?.slide?.reviewStatus == 'DONE' || slideData?.slide?.reviewStatus == 'NONE'"
                      style=" line-height: 1.5;font-size: .75vw !important;" class="dropdown-item"
                      (click)="changeReviewStatus('LATER')" href="javascript:void(0)">Review Later</a>
                    <a *ngIf="slideData?.slide?.reviewStatus == 'LATER' || slideData?.slide?.reviewStatus == 'NONE'"
                      class="dropdown-item" style=" line-height: 1.5;font-size: .75vw !important;"
                      (click)="changeReviewStatus('DONE')" href="javascript:void(0)">Review Done</a>
                  </div>
                </article>
              </section>
            </div>

          </li>
          <!-- <li *ngIf="openedFrom == 'gallery' && !isSplitView && !noImageFlag" class="nohoverli">
            <p *ngIf="slideData?.slide?.readStatus == 'UNREAD'" class="readgrey" title="Unread"><i
                class="fa fa-circle"></i></p>
            <p *ngIf="slideData?.slide?.readStatus == 'READ'" class="readorange" title="Read"><i class="fa fa-circle"></i>
            </p>
            <span _ngcontent-kva-c289="" class="downdropdown" style="visibility: hidden;"></span>
          </li>
          <li *ngIf="openedFrom == 'gallery' && !isSplitView && !noImageFlag" class="nohoverli">
            <p *ngIf="slideData?.slide?.reviewStatus == 'LATER'" class="readorange" title="Review Later"><i
                class="fa fa-sticky-note"></i></p>
            <p *ngIf="slideData?.slide?.reviewStatus == 'DONE'" class="readgrey" title="Review Done"><i
                class="fa fa-sticky-note"></i></p>
            <p *ngIf="slideData?.slide?.reviewStatus != 'DONE' && slideData?.slide?.reviewStatus != 'LATER'"
              class="readgrey" title="Not marked for review"><i class="fa fa-sticky-note-o"></i></p>
            <span _ngcontent-kva-c289="" class="downdropdown" style="visibility: hidden;"></span>
          </li> -->
          <li *ngIf="openedFrom == 'gallery' && !noImageFlag && (viewerScreens < 4)">
            <div class="tabordion_ryt">
              <section id="section9">
                <!-- <input type="radio" name="sections" id="option9" checked> -->
                <label for="option9" (click)="openGalleryModal('compare')" title="Compare">
                  <img src="../../../assets/images/viewer_icon/compare.svg" alt="Share for Review"
                    aria-label="Compare Images" />

                </label>
              </section>
            </div>
          </li>
          <li *ngIf="openedFrom == 'gallery' && !isSplitView && !noImageFlag"
            [ngClass]="{'img-viewer-icon-selected': panelList?.Slide_Review.isVisible}">
            <!-- <img src="../../../assets/images/viewer_icon/Share_for_Review.svg" alt="Share for Review" />-->
            <!-- slide review starts -->
            <div class="tabordion_ryt">
              <section id="section4">
                <input type="radio" name="sections" id="option4" checked>
                <label for="option4" (click)="togglePanel('Slide_Review')" title="Slide Review">
                  <img src="../../../assets/images/viewer_icon/Share for Review-Primary.svg" alt="Share for Review"
                    aria-label="Share for Review" />
                </label>
                <article *ngIf="panelList?.Slide_Review.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Slide Review</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('Slide_Review')"></i>
                    </div>
                  </div>
                  <div class="divpop_body">
                    <div class="padding_tabs">
                      <div class="row ">
                        <div class="col-sm-3">
                          <p>Tissue</p>
                        </div>
                        <div class="col-sm-9">
                          <div class="dropdown selectgrade">
                            <select class="form-control" (change)="selectOrgan($event.target.value)">
                              <option *ngFor="let organ of organs" [value]="organ">{{organ}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <mat-spinner style="margin:0 auto;" *ngIf="!isSlideReviewLoaded"></mat-spinner>

                      <mat-accordion>
                        <mat-expansion-panel *ngFor="let review of slideReviewArr; let i=index"
                          [expanded]="review.get('userFk').value == currentUser.userName && ((quantificationResult | json) == '{}')">
                          <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title">
                              <mat-icon [ngStyle]="{'color' : review.get('activeUser').value? '#000000' : '#929292' }" style="margin-right:0.5vw;"> supervisor_account</mat-icon> {{review.get('userFk').value}} ({{review.get('roleFk').value}})
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <form [formGroup]="review" (ngSubmit)="saveSlideReview(review)">
                            <div *ngIf="!slideData.study.isTCRStudy" class="row padbot_margin">
                              <div class="col-sm-3">
                                <p>Select</p>
                              </div>
                              <div class="col-sm-9">
                                <div class="form-check form-check-inline">
                                  <mat-radio-group aria-label="Select an option" formControlName="summary">
                                    <mat-radio-button class="form-check-input" value="WNL">WNL </mat-radio-button>
                                    &nbsp;
                                    <mat-radio-button class="form-check-input" value="NOT_WNL">NOT WNL
                                    </mat-radio-button>
                                  </mat-radio-group>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!slideData.study.isTCRStudy" class="row padbot_margin">
                              <div class="col-sm-3">
                                <p>Observation</p>
                              </div>
                              <div class="col-sm-9">
                                <div *ngFor="let obs of review.controls.observations.controls; let pointIndex=index">
                                  <div [formGroup]="obs" class="row observationRow" style="margin: 0px;">
                                    <ng-container *ngIf="studyCreateType != 'TCR'">
                                      <div class="col-sm-8">
                                        <textarea class="form-control" cols="10" rows="1" formControlName="description"
                                          maxlength="250" placeholder="Description"></textarea>
                                      </div>
                                      <div class="col-sm-3" style="padding: 0rem 0rem 0rem .3rem;">
                                        <select class="form-control" formControlName="gradingCode">
                                          <option *ngFor="let grad of gradings" [value]="grad.severityCode">
                                            {{grad.severityCode}}</option>
                                        </select>
                                      </div>
                                      <div *ngIf="review.get('userFk').value == currentUser.userName" class="col-sm-1">
                                        <div class="plusicon_review"
                                          *ngIf="pointIndex+1 == review.controls.observations.value.length"
                                          style="cursor: pointer; text-align: right;">
                                          <i class="fa fa-plus" (click)="addObservation(review)"></i>
                                        </div>
                                        <div class="plusicon_review"
                                          *ngIf="pointIndex+1 != review.controls.observations.value.length "
                                          style="cursor: pointer; text-align: right;">
                                          <i class="fa fa-times" (click)="removeObservation(review,pointIndex)"></i>
                                        </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngIf="studyCreateType == 'TCR'">
                                      <div class="col-sm-5">
                                        <textarea class="form-control" cols="10" rows="1" formControlName="description"
                                          placeholder="Description"></textarea>
                                      </div>
                                      <div class="col-sm-3" style="padding: 0rem 0rem 0rem .3rem;">
                                        <select class="form-control" formControlName="gradingCode">
                                          <option *ngFor="let grad of gradings" [value]="grad.severityCode">
                                            {{grad.severityCode}}</option>
                                        </select>
                                      </div>
                                      <div class="col-sm-3" style="padding: 0rem 0rem 0rem .3rem;">
                                        <select class="form-control" formControlName="gradingCode">
                                          <option *ngFor="let grad of gradings" [value]="grad.severityCode">
                                            {{grad.severityCode}}</option>
                                        </select>
                                      </div>
                                      <div *ngIf="!review.get('isPeerReviewer').value" class="col-sm-1">
                                        <div class="plusicon_review"
                                          *ngIf="pointIndex+1 == review.controls.observations.value.length"
                                          style="cursor: pointer; text-align: right;">
                                          <i class="fa fa-plus" (click)="addObservation(review)"></i>
                                        </div>
                                        <div class="plusicon_review"
                                          *ngIf="pointIndex+1 != review.controls.observations.value.length "
                                          style="cursor: pointer; text-align: right;">
                                          <i class="fa fa-times" (click)="removeObservation(review,pointIndex)"></i>
                                        </div>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="slideData.study.isTCRStudy" class="row padbot_margin">
                              <div class="col-sm-3">
                                <p>Intensity</p>
                              </div>
                              <div class="col-sm-9">
                                <select class="form-control" formControlName="intensity">
                                  <option *ngFor="let intensity of intensityArr" [value]="intensity.intensity">
                                    {{intensity.intensity}} - {{intensity.description}}</option>
                                </select>
                              </div>
                            </div>
                            <div *ngIf="slideData.study.isTCRStudy" class="row padbot_margin">
                              <div class="col-sm-3">
                                <p>Frequency</p>
                              </div>
                              <div class="col-sm-9">
                                <select class="form-control" formControlName="frequency">
                                  <option *ngFor="let frequency of frequencyArr" [value]="frequency.frequency">
                                    {{frequency.frequency}} - {{frequency.description}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="row padbot_margin">
                              <div class="col-sm-3">
                                <p>Comments</p>
                              </div>
                              <div class="col-sm-9">
                                <textarea class="form-control noresizetext" formControlName="comments" rows="3"
                                  maxlength="1000" cols="10"></textarea>
                              </div>
                            </div>
                            <div class="row filterbtn" *ngIf="review.get('userFk').value == currentUser.userName">
                              <div class="col-sm-8"></div>
                              <div class="col-sm-4 filterbtn">
                                <button type="button" class="btn-default" (click)=" togglePanel('Slide_Review')"
                                  aria-label="cancelbtn">Cancel</button>
                                <button type="submit" class="btn orangebtn" aria-label="searchbtn"
                                  [disabled]="disableSlideRevieweBtn">Save</button>
                              </div>
                            </div>
                          </form>
                        </mat-expansion-panel>
                      </mat-accordion>
                      <mat-accordion *ngIf="((quantificationResult | json) != '{}')">
                        <mat-expansion-panel [expanded]="quantificationResult">
                          <mat-expansion-panel-header>
                            <mat-panel-title>Analysis Results</mat-panel-title>
                          </mat-expansion-panel-header>
                          <mat-panel-description style="display: block;">
                            <div class="row padbot_margin">
                              <div class="col-sm-9">
                                <div class="form-check form-check-inline">
                                  <mat-radio-group [(ngModel)]="normality" [disabled]="true">
                                    <mat-radio-button value="WNL">WNL</mat-radio-button>
                                    <mat-radio-button value="NOT_WNL">Not WNL</mat-radio-button>
                                  </mat-radio-group>
                                </div>
                              </div>
                            </div>
                            <div class="decriptionContainer" style="text-align: left;">
                              <table class="display list_one_tables table table-hover">
                                <thead>
                                  <th></th>
                                  <th>Lesions identified</th>
                                  <th>Quantification</th>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let result of quantificationResult">
                                    <td>
                                      <div style="width: 3vw;"
                                        [ngStyle]="{'border-radius': '50%', 'height':'1vw', 'width':'1vw', 'background':((result.Color.length ==  9)?('#'+result.Color.slice(3,9)) : result.Color)}">
                                      </div>
                                    </td>
                                    <!-- <div
                                      [ngStyle]="{'border-radius': '50%', 'background':result.Color, 'height':'1vw', 'width':'1vw', 'display':'inline-block'}">
                                    </div> -->
                                    <td>{{result.FeatureName}}</td>
                                    <td>
                                      <div *ngFor="let mappedProperty of result.PropertyMap | keyvalue">
                                        {{mappedProperty.key}} : {{mappedProperty.value}}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div>
                              </div>
                            </div>
                            <!-- <div *ngFor="let result of quantificationResult | slice:1">
                            {{result.FeatureName}}
                          </div> -->
                          </mat-panel-description>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </article>
              </section>
            </div>
            <!-- slide review ends -->
          </li>
          <li *ngIf="(openedFrom == 'scanned' || openedFrom == 'uploaded') && !noImageFlag"
            [ngClass]="{'img-viewer-icon-selected': panelList?.DiscardImage.isVisible}">
            <div class="tabordion_ryt">
              <section id="section7">
                <input type="radio" name="sections" id="option7" checked>
                <label for="option7" (click)="togglePanel('DiscardImage')" title="Discard Image">
                  <img src="../../../assets/images/viewer_icon/Delete-Viewer-Primary.svg" alt="Discard Image"
                    aria-label="Discard Image" />

                </label>
                <article class="discardImageArticle" *ngIf="panelList?.DiscardImage.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Discard Image</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('DiscardImage')"></i>
                    </div>
                  </div>
                  <div class="discardImageContainer">
                    <p>Are you sure you want to discard this image?</p>
                    <div>
                      <mat-form-field class="example-full-width">
                        <mat-label>Comment
                          <!-- <span class="mandatory">*</span> -->
                        </mat-label>
                        <textarea [(ngModel)]="discardImageComment" matInput placeholder="Comment..."
                          maxlength="250"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="row filterbtn">
                      <div class="col-sm-3"></div>
                      <div class="col-sm-9 filterbtn">
                        <button mat-button class="btn orangebtn" (click)="discardImage()">Discard
                          Image</button>
                        <button mat-button class="btn btn-default" (click)="togglePanel('DiscardImage')"
                          aria-label="cancelbtn">Cancel</button>
                      </div>
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </li>
          <li *ngIf="(openedFrom == 'scanned'|| openedFrom == 'uploaded') && !noImageFlag"
            [ngClass]="{'img-viewer-icon-selected': panelList?.MapImage.isVisible}">
            <div class="tabordion_ryt">
              <section id="section6">
                <input type="radio" name="sections" id="option6" checked>
                <label for="option6" (click)="togglePanel('MapImage')" title="Map Image">
                  <img src="../../../assets/images/viewer_icon/MapImage-Primary.svg" alt="Map Image"
                    aria-label="Map Image" />

                </label>
                <article class="mapImageArticle" *ngIf="panelList?.MapImage.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Map Image</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('MapImage')"></i>
                    </div>
                  </div>
                  <div class="mapImageContainer">
                    <form [formGroup]="mapForm">
                      <div class="row">
                        <div class="col-md-6 discardImageContainer">
                          <mat-form-field class="example-full-width">
                            <mat-label>Study Name<span class="mandatory">*</span></mat-label>
                            <mat-select formControlName="studyGuId" (selectionChange)="studySelectionChanged($event)">
                              <mat-option *ngFor="let studyData of studyListArr" [value]="studyData.guid">
                                {{studyData.studyNo}}</mat-option>
                            </mat-select>
                            <mat-error>
                              {{mapF.studyGuId.invalid | inputError:'mapStudy' | translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6 discardImageContainer">
                          <mat-form-field class="example-full-width">
                            <mat-label>Cassette<span class="mandatory">*</span></mat-label>
                            <mat-select formControlName="cassetteId"
                              (selectionChange)="cassetteSelectionChanged($event)">
                              <mat-option *ngFor="let cassetteData of cassetteArr" [value]="cassetteData.id">
                                {{cassetteData.cassetteId}} ({{cassetteData.organs}})
                              </mat-option>
                            </mat-select>
                            <mat-error>
                              {{mapF.cassetteId.invalid | inputError:'mapCassette' | translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 discardImageContainer">
                          <mat-form-field class="example-full-width">
                            <mat-label>Stain<span class="mandatory">*</span></mat-label>
                            <mat-select formControlName="stainId" (selectionChange)="stainSelectionChanged($event)">
                              <mat-option *ngFor="let stainData of stainArr" [value]="stainData.id">{{stainData.stain}}
                              </mat-option>
                            </mat-select>
                            <mat-error>
                              {{mapF.stainId.invalid | inputError:'mapStain' | translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6 discardImageContainer">
                          <mat-form-field class="example-full-width">
                            <mat-label>Animal No.<span class="mandatory">*</span></mat-label>
                            <mat-select formControlName="animalId">
                              <mat-option *ngFor="let animalData of animalArr" [value]="animalData.animalId">
                                {{animalData.animalNo}}</mat-option>
                            </mat-select>
                            <mat-error>
                              {{mapF.animalId.invalid | inputError:'mapAnimal' | translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="mtop_1">
                        <mat-form-field class="example-full-width">
                          <mat-label>Comment</mat-label>
                          <textarea formControlName="comment" matInput placeholder="Comment..."
                            maxlength="250"></textarea>
                        </mat-form-field>
                      </div>
                    </form>
                    <div class="row filterbtn">
                      <div class="col-sm-3"></div>
                      <div class="col-sm-9 filterbtn">
                        <button mat-button class="btn orangebtn" (click)="mapImage()"
                          [ngClass]="{ 'disablebtn_func' : mapForm.invalid}">Map Image</button>
                        <button mat-button class="btn btn-default" (click)="togglePanel('MapImage')"
                          aria-label="cancelbtn">Cancel</button>
                      </div>
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </li>
          <li *ngIf="openedFrom == 'mapped' && !noImageFlag && slideData?.slide?.unTouched && unmapFlag"
            [ngClass]="{'img-viewer-icon-selected': panelList?.UnMappImage.isVisible}">
            <div class="tabordion_ryt">
              <section id="section8">
                <input type="radio" name="sections" id="option8" checked>
                <label for="option8" (click)="togglePanel('UnMappImage')" title="Reject Image">
                  <img src="../../../assets/images/viewer_icon/MapImage-Primary.svg" alt="Reject Image"
                    aria-label="Reject Image" />

                </label>
                <article class="unMapImageArticle" *ngIf="panelList?.UnMappImage.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Reject Image</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('UnMappImage')"></i>
                    </div>
                  </div>
                  <div class="unMapImageContainer discardImageContainer">
                    <div class="dn">
                      <p>Are you sure you want to unmap this image?</p>
                      <mat-form-field class="example-full-width">
                        <mat-label>Comment
                          <!-- <span class="mandatory">*</span> -->
                        </mat-label>
                        <textarea [(ngModel)]="unMapImageComment" matInput placeholder="Comment..."
                          maxlength="250"></textarea>
                      </mat-form-field>
                    </div>
                    <form [formGroup]="mapForm" class="">
                      <div class="mtop_1">
                        <mat-form-field class="example-full-width">
                          <mat-label>Comment</mat-label>
                          <textarea formControlName="comment" matInput placeholder="Comment..."
                            maxlength="250"></textarea>
                        </mat-form-field>
                      </div>
                    </form>
                    <div class="row filterbtn">
                      <div class="col-sm-3"></div>
                      <div class="col-sm-9 filterbtn" style="display: block;">

                        <!-- <button mat-button class="btn orangebtn" [ngClass]="{ 'disablebtn_func' : mapForm.invalid}"
                          (click)="reMapImage()">Remap
                          Image</button> -->

                        <button mat-button class="btn orangebtn" (click)="unMapImage()">Unmap
                          Image</button>
                        <button mat-button class="btn btn-default" (click)="togglePanel('UnMappImage')"
                          aria-label="cancelbtn">Cancel</button>
                      </div>
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </li>
          <li [ngClass]="{'img-viewer-icon-selected': panelList?.Screenshot.isVisible}"
            *ngIf="!noImageFlag && !isSplitView">
            <div class="tabordion_ryt">
              <section id="section3">
                <input type="radio" name="sections" id="option5" checked>
                <label for="option5" (click)="togglePanel('Screenshot')" title="Screenshot">
                  <img src="../../../assets/images/viewer_icon/Screenshot-Primary.svg" alt="Screenshot"
                    aria-label="Screenshot" />

                </label>
                <article class="screenshotArticle" *ngIf="panelList?.Screenshot.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Screenshot Type</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('Screenshot')"></i>
                    </div>
                  </div>
                  <div class="divpop_body">
                    <button class="dropdown-item" type="button" (click)="drawScreenshots('FullScreenShot')">
                      <img src="../../../assets/images/viewer_icon/FullScreen Shot-Primary.svg"
                        aria-label="Rectangle Screenshot" />
                      &nbsp;&nbsp;&nbsp;Full Screen
                    </button>
                    <button class="dropdown-item" type="button" (click)="drawScreenshots('Rectangular')">
                      <img src="../../../assets/images/viewer_icon/Rectangular Screenshot-Primary.svg"
                        aria-label="Full Screenshot">
                      &nbsp;&nbsp;&nbsp;Rectangle
                    </button>
                    <button class="dropdown-item" type="button" (click)="drawScreenshots('FilledFreeform')">
                      <img src="../../../assets/images/viewer_icon/Free Form Screenshot-Primary.svg"
                        aria-label="Freeform Screenshot" />
                      &nbsp;&nbsp;&nbsp;Freeform
                    </button>
                  </div>
                </article>
              </section>
            </div>
          </li>
          <!--  <li *ngIf="!noImageFlag">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Help">
                  <img src="../../../assets/images/viewer_icon/Help-Primary.svg" alt="Help" aria-label="Help" />
                  
                </label>
              </section>
            </div>
          </li> -->
          <!-- <li class="top_seperator"></li> -->
          <li (click)="onfullScreen()" *ngIf="!noImageFlag">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Full Screen">
                  <img src="../../../assets/images/viewer_icon/Expand-Primary.svg" alt="FullScreen"
                    aria-label="Full Screen" *ngIf="!isFullScreen" title="FullScreen" />
                  <img src="../../../assets/images/viewer_icon/Restore-Primary.svg" alt="Close FullScreen"
                    aria-label="Close Fullscreen" *ngIf="isFullScreen" title="Close Fullscreen" />
                </label>
              </section>
            </div>
          </li>
          <li (click)="onCloseViewer('SELECTED')" title="Close" *ngIf="imageViewerObj.length == 1">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Close">
                  <img src="../../../assets/images/viewer_icon/Close button-Primary.svg" alt="Close Viewer"
                    aria-label="Close Viewer" />
                </label>
              </section>
            </div>
          </li>
          <li [ngClass]="{'img-viewer-icon-selected': panelList?.Close_Viewer.isVisible}"
            *ngIf="imageViewerObj.length > 1">
            <div class="tabordion_ryt">
              <section id="section3">
                <input type="radio" name="sections" id="option51" checked>
                <label for="option51" (click)="togglePanel('Close_Viewer')" title="Close">
                  <img *ngIf="!isSplitView" src="../../../assets/images/viewer_icon/Close button-Primary.svg"
                    alt="Close Viewer" aria-label="Close Viewer" />
                  <img *ngIf="isSplitView" src="../../../assets/images/viewer_icon/removeSplitViewer.png"
                    alt="Close Compare" aria-label="Close Compare" />

                </label>
                <article class="closeViewerArticle" *ngIf="panelList?.Close_Viewer.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Close Viewers</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('Close_Viewer')"></i>
                    </div>
                  </div>
                  <div class="divpop_body">
                    <div class="gridParentDiv clearfix">
                      <!-- <mat-grid-list cols="{{(imageViewerObj.length == 3 ? '3' : '2')}}">
                        <mat-grid-tile *ngFor="let imgViewer of imageViewerObj; let i = index"
                          (click)="closeCheckboxChange($event, imgViewer.imageDisplayed)">{{i+1}}</mat-grid-tile>
                      </mat-grid-list> -->
                      <div class="viewerMiniDiv"
                        [ngClass]="{'twoDivsViewer':imageViewerObj.length == 2,'threeDivsViewer':imageViewerObj.length == 3, 'fourDivsViewer': imageViewerObj.length == 4}"
                        *ngFor="let imgViewer of imageViewerObj; let i = index"
                        (click)="closeCheckboxChange($event, imgViewer.imageDisplayed, i)">
                        <div class="viewerMiniInnerDiv">{{i+1}}</div>
                      </div>
                    </div>
                    <div class="buttonDiv clearfix">
                      <div *ngIf="!closeSlideArr?.length" class="boldText closeViewerAction"
                        (click)="onCloseViewer('ALL')">Remove All</div>
                      <div *ngIf="closeSlideArr?.length" class="boldText closeViewerAction"
                        (click)="onCloseViewer('SELECTED')">Remove</div>
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="topBarArrow">
      <i _ngcontent-qoh-c5="" (click)="toggleTopBar()" class="fa fa-chevron-down rotateArrow"
        [ngClass]="currentTopBarArrow=='open' ? 'openArrow' : 'closeArrow'" aria-hidden="true"
        ng-reflect-klass="fa fa-chevron-right rotateArro" ng-reflect-ng-class="openArrow"></i>
    </div>
  </div>
</div>