import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CommonService } from './../common.service';
import { ImageViewerService } from './image-viewer.service';
// import * as OpenSeadragon from '../../assets/js/openseadragon';
import * as THREE from 'three';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { notEmpty } from '@rxweb/reactive-form-validators';
import { TranslateService } from '@ngx-translate/core';

declare const OpenSeadragon: any;

@Injectable({
  providedIn: 'root'
})

export class AnnotationService {

  private allAnnotationsSource = new BehaviorSubject([]);
  allAnnotationsArr = this.allAnnotationsSource.asObservable();

  private selectedAnnoInfoSource = new BehaviorSubject({});
  selectedAnnoInfo = this.selectedAnnoInfoSource.asObservable();

  private currentAnnoSource = new BehaviorSubject('');
  currentAnno = this.currentAnnoSource.asObservable();/*For DC send event*/

  /* private showScreenShotPopupSource = new BehaviorSubject(false);
  showScreenShotPopup = this.showScreenShotPopupSource.asObservable(); */

  allAnnotationList: any[] = [];
  textFont: any = null;
  osdObj: any = null;
  threeJsObj: any = null;
  isPerformingTask: boolean = false;
  // selectedAnnoInfoObj: any = { 'comment': '', 'colour': '' };
  // isAnnoEditCommentBoxOpened: boolean = false;
  currentlyShowingAnnoId: number = null;
  currentlyShowingAnno: any;

  circleGeoInt: number = 75;

  newCreateAnnoInfo: any = {
    startAnnoDrawing: false,
    drawingMesh: null,
    drawingPoints: null,
    drawingPointsCount: null,
    initiateDrawing: false,
    drawingAnnoNameArr: [],
    drawingAnnoType: null,
    drawingAnnoLineWidth: 10,
    drawingAnnoBookmark: false,
    drawingAnnoAreaPerimeter: false,
    createAnnoPredefinedColorArr: ['#ff0000', '#0000ff', '#00ff00', '#00ffff', '#FF00FF', '#ffff00'],
    drawingAnnoLineColor: '#ff0000',
    drawingAnnoInitPos: { x: null, y: null, pointX: null, pointY: null },
    drawingAnnoMidPos: null,
    drawingCurserClick: 0,
    filledFreeformShapeArr: [],
    width: null,
    height: null,
    slideId: null,
    showAreaPerimeterVal: false,
    roleFk: null,
    isBatch: false,
    filledAnnoOpacity: 0.4,
    isBatchAnnoCreated: false,
    annoTypeObj: {
      'Rectangular': 'RECTANGLE',
      'Circular': 'CIRCLE',
      'Ellipse': 'ELLIPSE',
      'OpenFreeform': 'FREEFORMOPEN',
      'Freeform': 'FREEFORMCLOSE',
      'FilledFreeform': 'FILLEDFREEFORM',
      'Angle': 'ANGLE',
      'Arrow': 'ARROW',
      'Ruler': 'RULER',
      'Batch_Rectangular': 'RECTANGLE',
      'Batch_Circular': 'CIRCLE',
    },
    canvasInfo: null,
    isDrawingScreenShot: false,
    screenshotPos: {
      initPos: {}, endPos: {}
    },
    screenShotFreeformPosArr: []
  };

  newAnnoSaveInfo: any = {
    roi: null,
    slideId: null,
    info: {
      type: '',
      title: '',
      comment: '',
      size: null,
      color: null,
      style: "Outline",
      zoom: 0.0366,
      isManual: true,
      geomInfo: {
        area: 0,
        perimeter: 0,
        radius: 0,
        length: 0,
        angle: 0,
        alpha: 0,
        isExternal: "FALSE"
      },
      measurementUnit: "µm"
    },
    points: []
    // comment: null,
    // roi: null,
    // areaPerimeterFlag: true,
    // bookmarkFlag: false,
    // area: {
    //   value: null,
    //   unit: null
    // },
    // perimeter: {
    //   value: null,
    //   unit: null
    // },
    // thickness: {
    //   value: null,
    //   "unit": "px"
    // },
    // colour: null,
    // points: [],
    // type: null,
    // slideId: null,
    // roleFk: null
  }
  selectedViewerIndex: number = 0;

  constructor(
    private commonService: CommonService,
    private viewerService: ImageViewerService,
    public translate: TranslateService
  ) {
    this.loadFont();
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.circleGeoInt = (this.circleGeoInt / window.devicePixelRatio);
  }

  resetNewCreateAnnotationInfo(annoInfo, osdObj = null, threeJsObj = null) {
    if (osdObj) {
      this.osdObj = osdObj;
    }
    if (threeJsObj) {
      this.threeJsObj = threeJsObj;
    }

    this.newCreateAnnoInfo.drawingAnnoType = annoInfo?.type;
    this.newCreateAnnoInfo.drawingAnnoLineWidth = annoInfo?.thickness;
    this.newCreateAnnoInfo.drawingAnnoLineColor = annoInfo?.colour;
    this.newCreateAnnoInfo.drawingAnnoBookmark = false;
    this.newCreateAnnoInfo.drawingPointsCount = 0;
    this.newCreateAnnoInfo.drawingCurserClick = 0;
    this.newCreateAnnoInfo.startAnnoDrawing = (!$.isEmptyObject(annoInfo));
    this.newCreateAnnoInfo.drawingAnnoNameArr = [];
    this.newCreateAnnoInfo.showAreaPerimeterVal = (["Rectangular", "Circular", "Ellipse"].indexOf(this.newCreateAnnoInfo.drawingAnnoType) >= 0);
    this.newCreateAnnoInfo.drawingAnnoAreaPerimeter = this.newCreateAnnoInfo.showAreaPerimeterVal;
    this.newCreateAnnoInfo.roleFk = annoInfo?.roleFk;
    this.newCreateAnnoInfo.slideId = annoInfo?.slideId;
    this.newCreateAnnoInfo.isBatch = annoInfo?.isBatch;
    this.newCreateAnnoInfo.isBatchAnnoCreated = false;
    this.newCreateAnnoInfo.canvasInfo = annoInfo?.canvasInfo;
    this.newCreateAnnoInfo.isDrawingScreenShot = annoInfo?.drawScreenShot;
    this.newCreateAnnoInfo.screenshotPos = {
      initPos: {}, endPos: {}
    }
    this.newCreateAnnoInfo.screenShotFreeformPosArr = [];

    /* this.newAnnoSaveInfo.comment = '';
    this.newAnnoSaveInfo.drawingMesh = null;
    this.newAnnoSaveInfo.drawingPoints = null;
    this.newAnnoSaveInfo.drawingPointsCount = null;
    this.newAnnoSaveInfo.initiateDrawing = false;
    this.newAnnoSaveInfo.drawingAnnoType = null;
    this.newAnnoSaveInfo.drawingAnnoInitPos = { x: null, y: null, pointX: null, pointY: null };
    this.newAnnoSaveInfo.drawingAnnoMidPos = null;
    this.newAnnoSaveInfo.filledFreeformShapeArr = [];
    this.newAnnoSaveInfo.width = null;
    this.newAnnoSaveInfo.height = null;
    this.newAnnoSaveInfo.filledAnnoOpacity = 0.4;
    this.newAnnoSaveInfo.meetId = annoInfo?.meetId;
    this.newAnnoSaveInfo.isExternal = annoInfo?.isExternal; */

    this.newAnnoSaveInfo = {
      roi: null,
      slideId: null,
      info: {
        type: '',
        title: '',
        comment: '',
        size: null,
        color: null,
        style: "Outline",
        zoom: 0.0366,
        isManual: true,
        geomInfo: {
          area: 0,
          perimeter: 0,
          radius: 0,
          length: 0,
          angle: 0,
          alpha: 0,
          isExternal: annoInfo?.isExternal ? "TRUE" : "FALSE"
        }
      },
      points: []
    }

    $(".createAnnoPopup .createAnnoInfo.areaPerimeterIcon").removeClass("fa-eye").addClass("fa-eye-slash");
    $(".createAnnoPopup").find(".createAnnoAreaParameterDiv").show();

  }

  stopBatchAnno(value: boolean) {
    if (value) {
      this.resetNewCreateAnnotationInfo({});
      this.osdObj.setMouseNavEnabled(true);
      this.osdObj.panHorizontal = this.osdObj.panVertical = true;
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
      $('.disabledJoystickrotationNavigator,.disableTopBar').hide();
      $('#ViewerRotate').removeClass('disableFunction');
      $('#ViewerEasyView').removeClass('disableFunction');
    }
  }

  loadFont() {
    const manager = new THREE.LoadingManager();
    const loader = new FontLoader(manager);

    loader.load('assets/fonts/helvetiker_regular.typeface.json', (font) => {
      this.textFont = font;
    });
  }

  initiateInputHook(imageData) {
    if (!imageData.threeJsObj.inputHook) {
      imageData.threeJsObj.inputHook = imageData.osd.addViewerInputHook({
        hooks: [
          {
            tracker: 'viewer', handler: 'clickHandler', hookHandler: this.annoDrawingClickHandler.bind(this)
          },
          {
            tracker: 'viewer', handler: 'moveHandler', hookHandler: this.annoDrawingMoveHandler.bind(this)
          }
        ]
      });
    }
  }

  annoDrawingClickHandler(event) { // Disable mouse click drawing
    if (!this.newCreateAnnoInfo.startAnnoDrawing) {
      return;
    }

    let osdContentSize = this.osdObj.world._contentSize;
    var pos = this.getAnnoDrawingImagePosition(event, osdContentSize);
    if (pos.isOutsideImage) {
      if (this.newCreateAnnoInfo.startAnnoDrawing) {
        this.commonService.showToastMsg("Click inside the image to " + (!this.newCreateAnnoInfo.initiateDrawing ? "draw the " : "complete the drawn") + " annotation");
      } else {
        this.commonService.showToastMsg("Click inside the image to " + (!this.newCreateAnnoInfo.initiateDrawing ? "take a" : "complete the") + " screenshot");
      }
      return;
    }

    this.threeJsObj.renderer3.domElement.style.cursor = "crosshair";

    if (this.newCreateAnnoInfo.drawingAnnoType != "Angle") {
      this.newCreateAnnoInfo.initiateDrawing = !this.newCreateAnnoInfo.initiateDrawing;
      if (this.newCreateAnnoInfo.initiateDrawing) {
        this.newCreateAnnoInfo.drawingPointsCount = 0;
        this.newCreateAnnoInfo.drawingCurserClick = 0;
        this.newCreateAnnoInfo.filledFreeformShapeArr = [];
        // this.newCreateAnnoInfo.screenshotPos.initPos = event.position;
        this.setScreenShotPos(event.position);

        if (this.newCreateAnnoInfo.isBatch && this.newCreateAnnoInfo.isBatchAnnoCreated) {
          this.createBatchAnno(event);
        }

      } else {
        // this.newCreateAnnoInfo.screenshotPos.endPos = event.position;
        this.setScreenShotPos(event.position);

        if (this.newCreateAnnoInfo.isBatch) {
          this.threeJsObj.renderer3.domElement.style.cursor = "crosshair";
        } else {
          this.threeJsObj.renderer3.domElement.style.cursor = "default";
        }
        this.osdObj.panHorizontal = this.osdObj.panVertical = true;

        if (this.newCreateAnnoInfo.drawingAnnoType == "Ruler") {
          let initPoint = {
            // x: (Math.min(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[9], this.newCreateAnnoInfo.drawingPoints[12], this.newCreateAnnoInfo.drawingPoints[15])),
            // y: (Math.min(this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[7], this.newCreateAnnoInfo.drawingPoints[10], this.newCreateAnnoInfo.drawingPoints[13], this.newCreateAnnoInfo.drawingPoints[16]))
            x: (Math.min(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[3])),
            y: (Math.min(this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[4]))
          };
          let endPoint = {
            // x: (Math.max(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[9], this.newCreateAnnoInfo.drawingPoints[12], this.newCreateAnnoInfo.drawingPoints[15])),
            // y: (Math.max(this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[7], this.newCreateAnnoInfo.drawingPoints[10], this.newCreateAnnoInfo.drawingPoints[13], this.newCreateAnnoInfo.drawingPoints[16]))
            x: (Math.max(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[3])),
            y: (Math.max(this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[4]))
          };
          Object.assign(this.newCreateAnnoInfo.drawingMesh, { actualSize: { width: Math.abs(endPoint.x - initPoint.x), height: Math.abs(endPoint.y - initPoint.y) } });
          // let rulerReading = this.getRealDistance({ posX0: this.newCreateAnnoInfo.drawingPoints[6], posY0: this.newCreateAnnoInfo.drawingPoints[7], posX1: this.newCreateAnnoInfo.drawingPoints[9], posY1: this.newCreateAnnoInfo.drawingPoints[10] }, this.osdObj.pixelToNanoVal);
          // let txt_mesh = this.createRulerText(this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7], this.newCreateAnnoInfo.drawingPoints[9], this.newCreateAnnoInfo.drawingPoints[10], rulerReading, parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')), null, this.threeJsObj.scene, this.newCreateAnnoInfo.drawingAnnoLineWidth);
          /* let rulerReading = this.getRealDistance({ posX0: this.newCreateAnnoInfo.drawingPoints[0], posY0: this.newCreateAnnoInfo.drawingPoints[1], posX1: this.newCreateAnnoInfo.drawingPoints[3], posY1: this.newCreateAnnoInfo.drawingPoints[4] }, this.osdObj.pixelToNanoVal);
          // let txt_mesh = this.createRulerText(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4],
          let txt_mesh = this.createRulerTextOnEnd(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4],
            rulerReading, parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')), null, this.threeJsObj.scene, this.newCreateAnnoInfo.drawingAnnoLineWidth, true, this.osdObj, this.threeJsObj);
          let meshName = "newRulerAnno_Mesh";
          Object.assign(txt_mesh, { name: meshName });
          this.newCreateAnnoInfo.drawingAnnoNameArr.push(meshName); */

          this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
          this.threeJsObj.camera.updateMatrixWorld();
        }

        if (this.newCreateAnnoInfo.isDrawingScreenShot) {
          if (this.newCreateAnnoInfo.drawingAnnoType == "FilledFreeform") {
            this.newCreateAnnoInfo.screenShotFreeformPosArr.push(this.newCreateAnnoInfo.screenShotFreeformPosArr[0]);
          }
          // this.createScreenShot();
          // this.showScreenShotPopupSource.next(true);
          $("#screenShotDiv").removeClass("dn");
          $("#screenShotDiv span.customType").addClass("dn");

        } else {
          this.newAnnoCreated(event);
        }
      }
    } else if (this.newCreateAnnoInfo.drawingCurserClick == 2) {
      let initPoint = {
        x: (Math.min(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[6])),
        y: (Math.min(this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[7]))
      };
      let endPoint = {
        x: (Math.max(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[6])),
        y: (Math.max(this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[7]))
      };

      Object.assign(this.newCreateAnnoInfo.drawingMesh, { actualSize: { width: Math.abs(endPoint.x - initPoint.x), height: Math.abs(endPoint.y - initPoint.y) } });

      /* let degree_angle = this.get3PointsAngle(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7]);
      let txt_mesh = this.showAngleText(this.newCreateAnnoInfo.drawingMesh, parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')), degree_angle, this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3],
        this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7], this.threeJsObj.scene, true, this.osdObj, this.threeJsObj);
      let meshName = "newAngleAnno_Mesh";
      Object.assign(txt_mesh, { name: meshName });
      this.newCreateAnnoInfo.drawingAnnoNameArr.push(meshName); */

      /* let degree_angle = this.get3PointsAngle(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7]);
      this.showAngleTextOnMid(this.newCreateAnnoInfo.drawingPoints, degree_angle, this.newCreateAnnoInfo.drawingAnnoLineColor, this.newCreateAnnoInfo.drawingAnnoMidPos, pos); */

      this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
      this.threeJsObj.camera.updateMatrixWorld();

      this.newCreateAnnoInfo.initiateDrawing = false;
      this.newCreateAnnoInfo.drawingPointsCount = 0;
      this.newCreateAnnoInfo.drawingCurserClick = 0;
      this.threeJsObj.renderer3.domElement.style.cursor = "default";

      this.osdObj.panHorizontal = this.osdObj.panVertical = true;
      this.newAnnoCreated(event);
    } else {
      this.newCreateAnnoInfo.initiateDrawing = true;
      this.newCreateAnnoInfo.drawingCurserClick++;
    }
  }

  createFullScreenShot(annoInfo, osdObj = null, threeJsObj = null) {
    if (osdObj) {
      this.osdObj = osdObj;
    }
    if (threeJsObj) {
      this.threeJsObj = threeJsObj;
    }

    let actualCanvasWidth = (this.osdObj.viewport.getZoom(true) * this.osdObj.viewport.containerSize.x);
    let actualCanvasHeight = actualCanvasWidth / this.osdObj.viewport._contentAspectRatio;

    let topLeftPos = this.osdObj.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(this.osdObj.viewport.getBounds()));
    let bottomRightPos = new OpenSeadragon.Point(topLeftPos.x + actualCanvasWidth, topLeftPos.y + actualCanvasHeight);

    this.newCreateAnnoInfo.screenshotPos = {
      // initPos: { x: 0, y: 0 }, endPos: this.osdObj.viewport.getContainerSize()
      initPos: {
        x: (topLeftPos.x > 0) ? topLeftPos.x : 0,
        y: (topLeftPos.y > 0) ? topLeftPos.y : 0
      },
      endPos: {
        x: (bottomRightPos.x > this.osdObj.viewport.containerSize.x) ? this.osdObj.viewport.containerSize.x : bottomRightPos.x,
        y: (bottomRightPos.y > this.osdObj.viewport.containerSize.y) ? this.osdObj.viewport.containerSize.y : bottomRightPos.y
      }
    }
    // this.createScreenShot();
    // this.showScreenShotPopupSource.next(true);
    $("#screenShotDiv").removeClass("dn");
    $("#screenShotDiv span.customType").addClass("dn");

  }

  createScreenShot(isWithAnno: boolean = false, isScalebar: boolean = true, resolutionValue: number = 1, dpiValue: number = 1, fileName: string = '') {
    // let isWithAnno = true, isScalebar = true, resolutionValue = 1, dpiValue = 1, fileName = 'Test';

    let initX = this.newCreateAnnoInfo.screenshotPos.initPos.x, initY = this.newCreateAnnoInfo.screenshotPos.initPos.y,
      endX = this.newCreateAnnoInfo.screenshotPos.endPos.x, endY = this.newCreateAnnoInfo.screenshotPos.endPos.y;

    let devicePixelRatio = window.devicePixelRatio;
    let canvas = $("#" + this.osdObj.id + " .openseadragon-canvas").children('canvas')[0] as HTMLCanvasElement;

    var canvas1 = document.createElement("canvas");
    var ctx1 = canvas1.getContext("2d");
    canvas1.width = Math.abs(endX - initX) * resolutionValue;
    canvas1.height = Math.abs(endY - initY) * resolutionValue;

    let InitCordX = 0;
    let InitCordY = 0;
    let desiredWidth = Math.abs(endX - initX) * resolutionValue;
    let desiredHeight = Math.abs(endY - initY) * resolutionValue;
    let sx = ((initX + parseFloat(window.scrollX.toFixed(2))) * devicePixelRatio);
    let sy = ((initY + parseFloat(window.scrollY.toFixed(2))) * devicePixelRatio);
    let sWidth = (Math.abs(endX - initX) * devicePixelRatio);
    let sHeight = (Math.abs(endY - initY) * devicePixelRatio);

    let annoType = this.newCreateAnnoInfo.drawingAnnoType;

    // ctx1.drawImage(((annoType == "FilledFreeform") ? freeFormCanvas : canvas), sx, sy, sWidth, sHeight, InitCordX, InitCordY, desiredWidth, desiredHeight);
    ctx1.drawImage(canvas, sx, sy, sWidth, sHeight, InitCordX, InitCordY, desiredWidth, desiredHeight);

    this.closeAnnotation(null, false);
    if (isWithAnno) {
      let canvasThreeJs = this.threeJsObj.renderer3.domElement;
      canvasThreeJs.getContext('webgl', { preserveDrawingBuffer: true });
      this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
      this.threeJsObj.camera.updateMatrixWorld();

      ctx1.drawImage(canvasThreeJs, initX, initY, Math.abs(endX - initX), Math.abs(endY - initY), InitCordX, InitCordY, desiredWidth, desiredHeight);
      canvasThreeJs.getContext('webgl', { preserveDrawingBuffer: false });
    }

    if (annoType == "FilledFreeform") {
      var freeFormCanvas = document.createElement("canvas");
      var canvas2 = document.createElement("canvas");
      freeFormCanvas.width = canvas2.width = canvas1.width;
      freeFormCanvas.height = canvas2.height = canvas1.height;
      var ctxFreeform = freeFormCanvas.getContext("2d");
      var ctx2 = canvas2.getContext("2d");
      ctxFreeform.fillStyle = 'rgba(0, 0, 0, 0)';
      // ctxFreeform.fillStyle = 'rgb(0, 0, 0)';
      ctxFreeform.fillRect(0, 0, freeFormCanvas.width, freeFormCanvas.height);
      ctxFreeform.beginPath();
      ctxFreeform.moveTo(parseFloat((this.newCreateAnnoInfo.screenShotFreeformPosArr[0].x - initX).toFixed(2)),
        parseFloat(((this.newCreateAnnoInfo.screenShotFreeformPosArr[0].y - initY).toFixed(2))));
      for (var i = 1; i < this.newCreateAnnoInfo.screenShotFreeformPosArr.length; i++) {
        let posX = parseFloat((this.newCreateAnnoInfo.screenShotFreeformPosArr[i].x - initX).toFixed(2));
        let posY = parseFloat((this.newCreateAnnoInfo.screenShotFreeformPosArr[i].y - initY).toFixed(2));
        ctxFreeform.lineTo(posX, posY);
      }
      //added comment for test.
      ctxFreeform.closePath();
      ctxFreeform.clip();
      ctxFreeform.drawImage(canvas1, 0, 0);
      ctx2.drawImage(freeFormCanvas, 0, 0);
      this.resetNewCreateAnnotationInfo({});
    }


    if (isScalebar) {
      let scalbar = this.createScalebar();
      ((annoType == "FilledFreeform") ? ctx2 : ctx1).drawImage(scalbar, desiredWidth - scalbar.width, desiredHeight - scalbar.height, scalbar.width, scalbar.height);
    }

    if (dpiValue < 1) {
      this.downScaleCanvas(((annoType == "FilledFreeform") ? canvas2 : canvas1), dpiValue);
    }
    // console.log(((annoType == "FilledFreeform") ? canvas2 : canvas1).toDataURL('image/png'));

    var link = document.createElement("a");
    link.href = ((annoType == "FilledFreeform") ? canvas2 : canvas1).toDataURL('image/png'); //function blocks CORS
    link.download = `${fileName}.png`;
    link.click();
  }

  createScalebar() {
    var scalbarText = $("#" + this.osdObj.scalebarInstance.scalebarId).find('.scalebarDivText');
    var scalebarLine = $("#" + this.osdObj.scalebarInstance.scalebarId).find('.scalebarMainDiv .scaleBarLine');
    var sacalValue = $(scalbarText).text();

    var scalebarCanvas = document.createElement('canvas');
    scalebarCanvas.width = ($(scalebarLine).width() + 20);
    scalebarCanvas.height = ($(scalbarText).height() + 20);
    var scalbarContext = scalebarCanvas.getContext("2d");
    scalbarContext.font = "0.75vw serif";
    scalbarContext.strokeStyle = "#F47625";
    scalbarContext.fillStyle = "#F47625";
    scalbarContext.lineWidth = 3;
    scalbarContext.fillText(sacalValue, ($(scalebarLine).width() / 4), 10)
    // main line
    scalbarContext.beginPath();
    scalbarContext.moveTo(0, ($(scalbarText).height() + 2));
    scalbarContext.lineTo($(scalebarLine).width(), ($(scalbarText).height() + 2));
    scalbarContext.stroke();
    // end main line 
    // left side line
    scalbarContext.beginPath();
    scalbarContext.moveTo(0, ($(scalbarText).height() - 4));
    scalbarContext.lineTo(0, ($(scalbarText).height() + 8));
    scalbarContext.stroke();
    // left side line end 
    // right side line
    scalbarContext.beginPath();
    scalbarContext.moveTo($(scalebarLine).width(), ($(scalbarText).height() - 4));
    scalbarContext.lineTo($(scalebarLine).width(), ($(scalbarText).height() + 8));
    scalbarContext.stroke();
    // right side line end 
    return scalebarCanvas;
  }

  downScaleCanvas(cv, scale) {
    if (!(scale < 1) || !(scale > 0)) throw ('scale must be a positive number <1 ');
    var sqScale = scale * scale; // square scale = area of source pixel within target
    var sw = cv.width; // source image width
    var sh = cv.height; // source image height
    var tw = Math.floor(sw * scale); // target image width
    var th = Math.floor(sh * scale); // target image height
    var sx = 0,
      sy = 0,
      sIndex = 0; // source x,y, index within source array
    var tx = 0,
      ty = 0,
      yIndex = 0,
      tIndex = 0; // target x,y, x,y index within target array
    var tX = 0,
      tY = 0; // rounded tx, ty
    var w = 0,
      nw = 0,
      wx = 0,
      nwx = 0,
      wy = 0,
      nwy = 0; // weight / next weight x / y
    // weight is weight of current source point within target.
    // next weight is weight of current source point within next target's point.
    var crossX = false; // does scaled px cross its current px right border ?
    var crossY = false; // does scaled px cross its current px bottom border ?
    var sBuffer = cv.getContext('2d').
      getImageData(0, 0, sw, sh).data; // source buffer 8 bit rgba
    var tBuffer = new Float32Array(3 * tw * th); // target buffer Float32 rgb
    var sR = 0,
      sG = 0,
      sB = 0; // source's current point r,g,b
    /* untested !
    var sA = 0;  //source alpha  */

    for (sy = 0; sy < sh; sy++) {
      ty = sy * scale; // y src position within target
      tY = 0 | ty; // rounded : target pixel's y
      yIndex = 3 * tY * tw; // line index within target array
      crossY = (tY != (0 | ty + scale));
      if (crossY) { // if pixel is crossing botton target pixel
        wy = (tY + 1 - ty); // weight of point within target pixel
        nwy = (ty + scale - tY - 1); // ... within y+1 target pixel
      }
      for (sx = 0; sx < sw; sx++, sIndex += 4) {
        tx = sx * scale; // x src position within target
        tX = 0 | tx; // rounded : target pixel's x
        tIndex = yIndex + tX * 3; // target pixel index within target array
        crossX = (tX != (0 | tx + scale));
        if (crossX) { // if pixel is crossing target pixel's right
          wx = (tX + 1 - tx); // weight of point within target pixel
          nwx = (tx + scale - tX - 1); // ... within x+1 target pixel
        }
        sR = sBuffer[sIndex]; // retrieving r,g,b for curr src px.
        sG = sBuffer[sIndex + 1];
        sB = sBuffer[sIndex + 2];

        /* !! untested : handling alpha !!
           sA = sBuffer[sIndex + 3];
           if (!sA) continue;
           if (sA != 0xFF) {
               sR = (sR * sA) >> 8;  // or use /256 instead ??
               sG = (sG * sA) >> 8;
               sB = (sB * sA) >> 8;
           }
        */
        if (!crossX && !crossY) { // pixel does not cross
          // just add components weighted by squared scale.
          tBuffer[tIndex] += sR * sqScale;
          tBuffer[tIndex + 1] += sG * sqScale;
          tBuffer[tIndex + 2] += sB * sqScale;
        } else if (crossX && !crossY) { // cross on X only
          w = wx * scale;
          // add weighted component for current px
          tBuffer[tIndex] += sR * w;
          tBuffer[tIndex + 1] += sG * w;
          tBuffer[tIndex + 2] += sB * w;
          // add weighted component for next (tX+1) px                
          nw = nwx * scale
          tBuffer[tIndex + 3] += sR * nw;
          tBuffer[tIndex + 4] += sG * nw;
          tBuffer[tIndex + 5] += sB * nw;
        } else if (crossY && !crossX) { // cross on Y only
          w = wy * scale;
          // add weighted component for current px
          tBuffer[tIndex] += sR * w;
          tBuffer[tIndex + 1] += sG * w;
          tBuffer[tIndex + 2] += sB * w;
          // add weighted component for next (tY+1) px                
          nw = nwy * scale
          tBuffer[tIndex + 3 * tw] += sR * nw;
          tBuffer[tIndex + 3 * tw + 1] += sG * nw;
          tBuffer[tIndex + 3 * tw + 2] += sB * nw;
        } else { // crosses both x and y : four target points involved
          // add weighted component for current px
          w = wx * wy;
          tBuffer[tIndex] += sR * w;
          tBuffer[tIndex + 1] += sG * w;
          tBuffer[tIndex + 2] += sB * w;
          // for tX + 1; tY px
          nw = nwx * wy;
          tBuffer[tIndex + 3] += sR * nw;
          tBuffer[tIndex + 4] += sG * nw;
          tBuffer[tIndex + 5] += sB * nw;
          // for tX ; tY + 1 px
          nw = wx * nwy;
          tBuffer[tIndex + 3 * tw] += sR * nw;
          tBuffer[tIndex + 3 * tw + 1] += sG * nw;
          tBuffer[tIndex + 3 * tw + 2] += sB * nw;
          // for tX + 1 ; tY +1 px
          nw = nwx * nwy;
          tBuffer[tIndex + 3 * tw + 3] += sR * nw;
          tBuffer[tIndex + 3 * tw + 4] += sG * nw;
          tBuffer[tIndex + 3 * tw + 5] += sB * nw;
        }
      } // end for sx 
    } // end for sy

    // create result canvas
    var resCV = document.createElement('canvas');
    resCV.width = tw;
    resCV.height = th;
    var resCtx = resCV.getContext('2d');
    var imgRes = resCtx.getImageData(0, 0, tw, th);
    var tByteBuffer = imgRes.data;
    // convert float32 array into a UInt8Clamped Array
    var pxIndex = 0; //  
    for (sIndex = 0, tIndex = 0; pxIndex < tw * th; sIndex += 3, tIndex += 4, pxIndex++) {
      tByteBuffer[tIndex] = Math.ceil(tBuffer[sIndex]);
      tByteBuffer[tIndex + 1] = Math.ceil(tBuffer[sIndex + 1]);
      tByteBuffer[tIndex + 2] = Math.ceil(tBuffer[sIndex + 2]);
      tByteBuffer[tIndex + 3] = 255;
    }
    // writing result to canvas.
    resCtx.putImageData(imgRes, 0, 0);
    return resCV;
  }

  newAnnoCreated(event) {

    let currentSlideIdData = JSON.parse(localStorage.getItem('slideIdDsData'));
    currentSlideIdData = currentSlideIdData?.filter((e) => e.slideId == this.newCreateAnnoInfo?.slideId);
    if (currentSlideIdData != undefined && currentSlideIdData.length > 0) {
      var savedDsComment = currentSlideIdData[0]?.dsComments;
    }

    this.osdObj.setMouseNavEnabled(false);
    this.newCreateAnnoInfo.startAnnoDrawing = false;
    let viewerMousePos = {
      x: (event.position.x + $(event.eventSource.element).closest(".viewer").position().left),
      y: (event.position.y + $(event.eventSource.element).closest(".viewer").position().top)
    }
    let posX = ((viewerMousePos.x + $(".createAnnoPopup").width()) > $(event.originalEvent.target).closest(".viewercontent").width()) ? (viewerMousePos.x - $(".createAnnoPopup").width()) : viewerMousePos.x;
    let posY = ((viewerMousePos.y + $(".createAnnoPopup").height()) > $(event.originalEvent.target).closest(".viewercontent").height()) ? (viewerMousePos.y - $(".createAnnoPopup").height()) : viewerMousePos.y;

    if (this.newCreateAnnoInfo.showAreaPerimeterVal) {
      this.getAreaPerimeter(this.newCreateAnnoInfo.drawingAnnoType, this.newCreateAnnoInfo);
      $(".createAnnoPopup").find(".createAnnoAreaParameterDiv").find(".areaValue").html(this.newAnnoSaveInfo.area.value + this.newAnnoSaveInfo.area.unit);
      $(".createAnnoPopup").find(".createAnnoAreaParameterDiv").find(".perimeterValue").html(this.newAnnoSaveInfo.perimeter.value + this.newAnnoSaveInfo.perimeter.unit);
      if (currentSlideIdData != null && currentSlideIdData.length > 0) {
        $(".createAnnoPopup").find(".dsCommentDiv").find("#dsCommentTextCreate").html(savedDsComment);
        $(".createAnnoPopup").find(".dsCommentDiv").find("#dsCommentTextCreate").show();
      } else {
        $(".createAnnoPopup").find(".dsCommentDiv").find("#dsCommentTextCreate").hide();
      }
    }
    $(".createAnnoPopup").css({ 'left': (posX) + 'px', 'top': posY + 'px' }).show();
  }

  getAnnoDrawingImagePosition(event, osdContentSize) {
    var viewportPoint = this.osdObj.viewport.pointFromPixel(event.position);
    var imagePoint = this.osdObj.viewport.viewportToImageCoordinates(viewportPoint);
    var pos = {
      x: imagePoint.x - osdContentSize.x / 2,
      y: (osdContentSize.y / 2 - imagePoint.y),
      isOutsideImage: false,
      ViewportCoordinates: null
    }

    this.threeJsObj.renderer3.domElement.style.cursor = "crosshair";
    if ((pos.x + osdContentSize.x / 2) < 0) {
      pos.x = (-osdContentSize.x / 2);
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
      pos.isOutsideImage = true;
    } else if ((pos.x + osdContentSize.x / 2) > osdContentSize.x) {
      pos.x = osdContentSize.x / 2;
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
      pos.isOutsideImage = true;
    }

    if (pos.y - (osdContentSize.y / 2) > 0) {
      pos.y = osdContentSize.y / 2;
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
      pos.isOutsideImage = true;
    } else if ((pos.y + osdContentSize.y / 2) < 0) {
      pos.y = -osdContentSize.y / 2;
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
      pos.isOutsideImage = true;
    }

    let ViewportPoint = this.osdObj.viewport.imageToViewportCoordinates((pos.x + (osdContentSize.x / 2)), ((osdContentSize.y / 2) - pos.y));
    pos.ViewportCoordinates = this.osdObj.viewport.pixelFromPoint(ViewportPoint);
    return pos;
  }

  setScreenShotPos(position) {
    if (!this.newCreateAnnoInfo.isDrawingScreenShot) {
      return;
    }

    if (!this.newCreateAnnoInfo.screenshotPos.initPos.x || (position.x < this.newCreateAnnoInfo.screenshotPos.initPos.x)) {
      this.newCreateAnnoInfo.screenshotPos.initPos.x = position.x;
    }

    if (!this.newCreateAnnoInfo.screenshotPos.initPos.y || (position.y < this.newCreateAnnoInfo.screenshotPos.initPos.y)) {
      this.newCreateAnnoInfo.screenshotPos.initPos.y = position.y;
    }

    if (!this.newCreateAnnoInfo.screenshotPos.endPos.x || (position.x > this.newCreateAnnoInfo.screenshotPos.endPos.x)) {
      this.newCreateAnnoInfo.screenshotPos.endPos.x = position.x;
    }

    if (!this.newCreateAnnoInfo.screenshotPos.endPos.y || (position.y > this.newCreateAnnoInfo.screenshotPos.endPos.y)) {
      this.newCreateAnnoInfo.screenshotPos.endPos.y = position.y;
    }

    if (this.newCreateAnnoInfo.drawingAnnoType == "FilledFreeform") {
      this.newCreateAnnoInfo.screenShotFreeformPosArr.push(position);
    }
  }

  annoDrawingMoveHandler(event) {
    // (event => { // Disable mouse click drawing
    if (this.newCreateAnnoInfo.initiateDrawing) {

      this.osdObj.panHorizontal = this.osdObj.panVertical = false;
      let osdContentSize = this.osdObj.world._contentSize;
      var pos = this.getAnnoDrawingImagePosition(event, osdContentSize);

      if (this.newCreateAnnoInfo.drawingAnnoType == "FilledFreeform") {
        this.setScreenShotPos(pos.ViewportCoordinates);
      }

      if (!this.newCreateAnnoInfo.drawingPointsCount) {
        let floatArrStorage;
        switch (this.newCreateAnnoInfo.drawingAnnoType) {
          case ("Rectangular" || "Arrow"):
            floatArrStorage = 15;
            break;
          case ("Circular" || "Ellipse"):
            floatArrStorage = 303;
            break;
          case "Ruler":
            // floatArrStorage = 18;
            floatArrStorage = 6;
            break;
          case "Angle":
            floatArrStorage = 9;
            break;
          default:
            floatArrStorage = 30000;
        }

        let geometry = new LineGeometry();
        this.newCreateAnnoInfo.drawingPoints = new Float32Array(floatArrStorage); // 3 vertices per point
        this.newCreateAnnoInfo.drawingPoints[0] = pos.x;
        this.newCreateAnnoInfo.drawingPoints[1] = pos.y;
        this.newCreateAnnoInfo.drawingPoints[2] = 0;
        geometry.setPositions(this.newCreateAnnoInfo.drawingPoints);
        this.newCreateAnnoInfo.drawingAnnoInitPos.x = pos.x;
        this.newCreateAnnoInfo.drawingAnnoInitPos.y = pos.y;
        this.newCreateAnnoInfo.drawingAnnoInitPos.pointX = pos.ViewportCoordinates.x;
        this.newCreateAnnoInfo.drawingAnnoInitPos.pointY = pos.ViewportCoordinates.y;
        geometry.instanceCount = 0;
        geometry.maxInstancedCount = 0;
        let material = new LineMaterial({ color: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')), linewidth: this.newCreateAnnoInfo.drawingAnnoLineWidth });
        material.resolution.set(window.innerWidth, window.innerHeight);
        this.newCreateAnnoInfo.drawingMesh = new Line2(geometry, material);
        let lineName = "newAngleAnno_Line";
        Object.assign(this.newCreateAnnoInfo.drawingMesh, { name: lineName });
        this.newCreateAnnoInfo.drawingAnnoNameArr.push(lineName);
        this.threeJsObj.scene.add(this.newCreateAnnoInfo.drawingMesh);

        if (this.newCreateAnnoInfo.drawingAnnoType == "FilledFreeform") {
          this.newCreateAnnoInfo.filledFreeformShapeArr.push(new THREE.Vector2(pos.x, pos.y));
        }

        this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
        this.threeJsObj.camera.updateMatrixWorld();
        this.newCreateAnnoInfo.drawingPointsCount = 3;
      } else {
        if (this.newCreateAnnoInfo.drawingAnnoType == "FilledFreeform") {
          this.setScreenShotPos(pos.ViewportCoordinates);
        }

        let instanceCount;
        if (this.newCreateAnnoInfo.drawingAnnoType == "Rectangular") {
          this.newCreateAnnoInfo.drawingPoints[3] = pos.x;
          this.newCreateAnnoInfo.drawingPoints[4] = this.newCreateAnnoInfo.drawingPoints[1];
          this.newCreateAnnoInfo.drawingPoints[5] = 0;

          this.newCreateAnnoInfo.drawingPoints[6] = pos.x;
          this.newCreateAnnoInfo.drawingPoints[7] = pos.y;
          this.newCreateAnnoInfo.drawingPoints[8] = 0;

          this.newCreateAnnoInfo.drawingPoints[9] = this.newCreateAnnoInfo.drawingPoints[0];
          this.newCreateAnnoInfo.drawingPoints[10] = pos.y;
          this.newCreateAnnoInfo.drawingPoints[11] = 0;

          this.newCreateAnnoInfo.drawingPoints[12] = this.newCreateAnnoInfo.drawingPoints[0];
          this.newCreateAnnoInfo.drawingPoints[13] = this.newCreateAnnoInfo.drawingPoints[1];
          this.newCreateAnnoInfo.drawingPoints[14] = 0;
          this.newCreateAnnoInfo.drawingPointsCount = 15;
          instanceCount = (this.newCreateAnnoInfo.drawingPointsCount / 3) - 1;

          if (this.newCreateAnnoInfo.isDrawingScreenShot) {
            let arr = [
              new THREE.Vector2(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1]),
              new THREE.Vector2(this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4]),
              new THREE.Vector2(this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7]),
              new THREE.Vector2(this.newCreateAnnoInfo.drawingPoints[9], this.newCreateAnnoInfo.drawingPoints[10]),
            ];
            var shape = new THREE.Shape(arr);
            let shapeGeom = new THREE.ShapeGeometry(shape);
            let mesh = this.threeJsObj.scene.getObjectByName("rectScreenShot_Mesh");

            if (!mesh) {
              let frame1 = new THREE.Mesh(shapeGeom, new THREE.MeshPhongMaterial({
                color: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')),
                emissive: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')),
                opacity: this.newCreateAnnoInfo.filledAnnoOpacity,
                transparent: true,
              }));

              let meshName = "rectScreenShot_Mesh";
              Object.assign(frame1, { name: meshName });
              this.newCreateAnnoInfo.drawingAnnoNameArr.push(meshName);
              this.threeJsObj.scene.add(frame1);
            } else {
              mesh.geometry = shapeGeom;
            }
          }
        } else if (this.newCreateAnnoInfo.drawingAnnoType == "Circular" || this.newCreateAnnoInfo.drawingAnnoType == "Ellipse") {
          let xRadius = Math.abs(pos.x - this.newCreateAnnoInfo.drawingAnnoInitPos.x);
          let yRadius = Math.abs(pos.y - this.newCreateAnnoInfo.drawingAnnoInitPos.y);
          let radius = Math.sqrt(Math.pow(xRadius, 2) + Math.pow(yRadius, 2));

          if (this.newCreateAnnoInfo.drawingAnnoType == "Circular") {
            if (((this.newCreateAnnoInfo.drawingAnnoInitPos.x - radius) < (-osdContentSize.x / 2)) || ((this.newCreateAnnoInfo.drawingAnnoInitPos.y - radius) < (-osdContentSize.y / 2)) ||
              (((this.newCreateAnnoInfo.drawingAnnoInitPos.x + radius) > (osdContentSize.x / 2)) || ((this.newCreateAnnoInfo.drawingAnnoInitPos.y + radius) > (osdContentSize.y / 2)))) {
              return;
            }
          } else {
            if (((this.newCreateAnnoInfo.drawingAnnoInitPos.x - xRadius) < (-osdContentSize.x / 2)) || ((this.newCreateAnnoInfo.drawingAnnoInitPos.y - yRadius) < (-osdContentSize.y / 2)) ||
              (((this.newCreateAnnoInfo.drawingAnnoInitPos.x + xRadius) > (osdContentSize.x / 2)) || ((this.newCreateAnnoInfo.drawingAnnoInitPos.y + yRadius) > (osdContentSize.y / 2)))) {
              return;
            }
          }

          const curve = new THREE.EllipseCurve(
            this.newCreateAnnoInfo.drawingAnnoInitPos.x, this.newCreateAnnoInfo.drawingAnnoInitPos.y,            // ax, aY
            (this.newCreateAnnoInfo.drawingAnnoType == "Circular") ? radius : xRadius, (this.newCreateAnnoInfo.drawingAnnoType == "Circular") ? radius : yRadius,           // xRadius, yRadius
            0, 2 * Math.PI,  // aStartAngle, aEndAngle
            false,            // aClockwise
            0                 // aRotation
          );

          let points = curve.getPoints(100);
          for (let x = 0; x < points.length; x++) {
            this.newCreateAnnoInfo.drawingPoints[(x * 3)] = points[x].x;
            this.newCreateAnnoInfo.drawingPoints[(x * 3) + 1] = points[x].y;
            this.newCreateAnnoInfo.drawingPoints[(x * 3) + 2] = 0;
          }
          this.newCreateAnnoInfo.radius = radius;
          this.newCreateAnnoInfo.xRadius = xRadius;
          this.newCreateAnnoInfo.yRadius = yRadius;
          this.newCreateAnnoInfo.drawingPointsCount = (points.length * 3);
          instanceCount = (this.newCreateAnnoInfo.drawingPointsCount / 3) - 1;
        } else if (this.newCreateAnnoInfo.drawingAnnoType == "Angle") {
          if (this.newCreateAnnoInfo.drawingCurserClick == 1) {
            this.newCreateAnnoInfo.drawingPoints[3] = pos.x;
            this.newCreateAnnoInfo.drawingPoints[4] = pos.y;
            this.newCreateAnnoInfo.drawingPoints[5] = 0;
            this.newCreateAnnoInfo.drawingPointsCount = 6;
            instanceCount = (this.newCreateAnnoInfo.drawingPointsCount / 3) - 1;

            this.newCreateAnnoInfo.drawingAnnoMidPos = pos;

            let dot1Mesh = this.threeJsObj.scene.getObjectByName("newAngleInitDot_Mesh");
            let currZoom = parseFloat((this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom()) * this.threeJsObj.schemaMaxZoomLevel).toFixed(2));
            if (!dot1Mesh) {
              const cirGeo1 = new THREE.CircleGeometry((this.newCreateAnnoInfo.drawingAnnoLineWidth * this.circleGeoInt), 32);
              const cirMat1 = new THREE.MeshBasicMaterial({ color: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')) });
              const circle1 = new THREE.Mesh(cirGeo1, cirMat1);
              circle1.position.set(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], 0);
              circle1.scale.set(1 / currZoom, 1 / currZoom, 1);
              this.threeJsObj.scene.add(circle1);
              Object.assign(circle1, { annoType: 'Angle' });
              Object.assign(circle1, { name: "newAngleInitDot_Mesh" });
              this.newCreateAnnoInfo.drawingAnnoNameArr.push("newAngleInitDot_Mesh");
              this.threeJsObj.textArr.push(circle1);
            } else {
              dot1Mesh.position.set(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], 0);
              dot1Mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
            }

            let dot2Mesh = this.threeJsObj.scene.getObjectByName("newAngleMidDot_Mesh");
            if (!dot2Mesh) {
              const cirGeo2 = new THREE.CircleGeometry((this.newCreateAnnoInfo.drawingAnnoLineWidth * this.circleGeoInt), 32);
              const cirMat2 = new THREE.MeshBasicMaterial({ color: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')) });
              const circle2 = new THREE.Mesh(cirGeo2, cirMat2);
              circle2.position.set(this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4], 0);
              circle2.scale.set(1 / currZoom, 1 / currZoom, 1);
              this.threeJsObj.scene.add(circle2);
              Object.assign(circle2, { annoType: 'Angle' });
              Object.assign(circle2, { name: "newAngleMidDot_Mesh" });
              this.newCreateAnnoInfo.drawingAnnoNameArr.push("newAngleMidDot_Mesh");
              this.threeJsObj.textArr.push(circle2);
            } else {
              // dot1Mesh.geometry = shapeGeom;
              dot2Mesh.position.set(this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4], 0);
              dot2Mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
            }
          } else {
            this.newCreateAnnoInfo.drawingPoints[6] = pos.x;
            this.newCreateAnnoInfo.drawingPoints[7] = pos.y;
            this.newCreateAnnoInfo.drawingPoints[8] = 0;
            this.newCreateAnnoInfo.drawingPointsCount = 9;
            instanceCount = (this.newCreateAnnoInfo.drawingPointsCount / 3) - 1;

            let dot3Mesh = this.threeJsObj.scene.getObjectByName("newAngleEndDot_Mesh");
            let currZoom = parseFloat((this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom()) * this.threeJsObj.schemaMaxZoomLevel).toFixed(2));
            if (!dot3Mesh) {
              const cirGeo2 = new THREE.CircleGeometry((this.newCreateAnnoInfo.drawingAnnoLineWidth * this.circleGeoInt), 32);
              const cirMat2 = new THREE.MeshBasicMaterial({ color: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')) });
              const circle2 = new THREE.Mesh(cirGeo2, cirMat2);
              circle2.position.set(this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7], 0);
              circle2.scale.set(1 / currZoom, 1 / currZoom, 1);
              this.threeJsObj.scene.add(circle2);
              Object.assign(circle2, { annoType: 'Angle' });
              Object.assign(circle2, { name: "newAngleEndDot_Mesh" });
              this.newCreateAnnoInfo.drawingAnnoNameArr.push("newAngleEndDot_Mesh");
              this.threeJsObj.textArr.push(circle2);
            } else {
              // dot1Mesh.geometry = shapeGeom;
              dot3Mesh.position.set(this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7], 0);
              dot3Mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
            }

            let degree_angle = this.get3PointsAngle(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7]);
            this.showAngleTextOnMid(this.newCreateAnnoInfo.drawingPoints, degree_angle, this.newCreateAnnoInfo.drawingAnnoLineColor, this.newCreateAnnoInfo.drawingAnnoMidPos, pos);

            /* let degree_angle = this.get3PointsAngle(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7]);
            let txt_mesh = this.showAngleText(this.newCreateAnnoInfo.drawingMesh, parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')), degree_angle, this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3],
              this.newCreateAnnoInfo.drawingPoints[4], this.newCreateAnnoInfo.drawingPoints[6], this.newCreateAnnoInfo.drawingPoints[7], this.threeJsObj.scene, true, this.osdObj, this.threeJsObj);
            let meshName = "newAngleAnno_Mesh";
            Object.assign(txt_mesh, { name: meshName });
            this.newCreateAnnoInfo.drawingAnnoNameArr.push(meshName); */
          }
        } else if (this.newCreateAnnoInfo.drawingAnnoType == "Arrow") {
          this.newCreateAnnoInfo.drawingPoints[3] = this.newCreateAnnoInfo.drawingPoints[9] = pos.x;
          this.newCreateAnnoInfo.drawingPoints[4] = this.newCreateAnnoInfo.drawingPoints[10] = pos.y;
          this.newCreateAnnoInfo.drawingPoints[5] = this.newCreateAnnoInfo.drawingPoints[11] = 0;

          const dx = pos.x - this.newCreateAnnoInfo.drawingAnnoInitPos.x;
          const dy = pos.y - this.newCreateAnnoInfo.drawingAnnoInitPos.y;
          // const headlen = Math.sqrt(dx * dx + dy * dy) * 0.3; // length of head in pixels
          const headlen = Math.sqrt(Math.pow((dx), 2) + Math.pow((dy), 2)) * 0.1; // length of head in pixels
          const angle = Math.atan2(dy, dx);
          const headAngle = Math.PI / 4;

          this.newCreateAnnoInfo.drawingPoints[6] = pos.x - (headlen * Math.cos(angle - headAngle));
          this.newCreateAnnoInfo.drawingPoints[7] = pos.y - (headlen * Math.sin(angle - headAngle));
          this.newCreateAnnoInfo.drawingPoints[8] = 0;

          this.newCreateAnnoInfo.drawingPoints[12] = pos.x - (headlen * Math.cos(angle + headAngle));
          this.newCreateAnnoInfo.drawingPoints[13] = pos.y - (headlen * Math.sin(angle + headAngle));
          this.newCreateAnnoInfo.drawingPoints[14] = 0;
          this.newCreateAnnoInfo.drawingPointsCount = 15;
          instanceCount = (this.newCreateAnnoInfo.drawingPointsCount / 3) - 1;
        } else if (this.newCreateAnnoInfo.drawingAnnoType == "Ruler") {
          /* const endLen = 200; // length of end lines

          var px = this.newCreateAnnoInfo.drawingAnnoInitPos.y - pos.y; // as vector at 90 deg to the line
          var py = pos.x - this.newCreateAnnoInfo.drawingAnnoInitPos.x;
          const len = endLen / Math.hypot(px, py);
          px *= len;  // make leng 10 pixels
          py *= len; */

          /* this.newCreateAnnoInfo.drawingPoints[0] = this.newCreateAnnoInfo.drawingAnnoInitPos.x + px;
          this.newCreateAnnoInfo.drawingPoints[1] = this.newCreateAnnoInfo.drawingAnnoInitPos.y + py;
          this.newCreateAnnoInfo.drawingPoints[2] = 0;

          this.newCreateAnnoInfo.drawingPoints[3] = this.newCreateAnnoInfo.drawingAnnoInitPos.x - px;
          this.newCreateAnnoInfo.drawingPoints[4] = this.newCreateAnnoInfo.drawingAnnoInitPos.y - py;
          this.newCreateAnnoInfo.drawingPoints[5] = 0;

          this.newCreateAnnoInfo.drawingPoints[6] = this.newCreateAnnoInfo.drawingAnnoInitPos.x;
          this.newCreateAnnoInfo.drawingPoints[7] = this.newCreateAnnoInfo.drawingAnnoInitPos.y;
          this.newCreateAnnoInfo.drawingPoints[8] = 0;

          this.newCreateAnnoInfo.drawingPoints[9] = pos.x;
          this.newCreateAnnoInfo.drawingPoints[10] = pos.y;
          this.newCreateAnnoInfo.drawingPoints[11] = 0;

          this.newCreateAnnoInfo.drawingPoints[12] = pos.x + px;
          this.newCreateAnnoInfo.drawingPoints[13] = pos.y + py;
          this.newCreateAnnoInfo.drawingPoints[14] = 0;

          this.newCreateAnnoInfo.drawingPoints[15] = pos.x - px;
          this.newCreateAnnoInfo.drawingPoints[16] = pos.y - py;
          this.newCreateAnnoInfo.drawingPoints[17] = 0; */

          this.newCreateAnnoInfo.drawingPoints[0] = this.newCreateAnnoInfo.drawingAnnoInitPos.x;
          this.newCreateAnnoInfo.drawingPoints[1] = this.newCreateAnnoInfo.drawingAnnoInitPos.y;
          this.newCreateAnnoInfo.drawingPoints[2] = 0;

          this.newCreateAnnoInfo.drawingPoints[3] = pos.x;
          this.newCreateAnnoInfo.drawingPoints[4] = pos.y;
          this.newCreateAnnoInfo.drawingPoints[5] = 0;

          this.newCreateAnnoInfo.drawingPointsCount = 6;
          instanceCount = (this.newCreateAnnoInfo.drawingPointsCount / 3) - 1;

          let dot1Mesh = this.threeJsObj.scene.getObjectByName("newRulerDot1_Mesh");
          let currZoom = parseFloat((this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom()) * this.threeJsObj.schemaMaxZoomLevel).toFixed(2));

          if (!dot1Mesh) {
            const cirGeo1 = new THREE.CircleGeometry((this.newCreateAnnoInfo.drawingAnnoLineWidth * this.circleGeoInt), 32);
            const cirMat1 = new THREE.MeshBasicMaterial({ color: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')) });
            const circle1 = new THREE.Mesh(cirGeo1, cirMat1);
            circle1.position.set(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], 0);
            circle1.scale.set(1 / currZoom, 1 / currZoom, 1);
            this.threeJsObj.scene.add(circle1);
            Object.assign(circle1, { annoType: 'Ruler' });
            Object.assign(circle1, { name: "newRulerDot1_Mesh" });
            this.newCreateAnnoInfo.drawingAnnoNameArr.push("newRulerDot1_Mesh");
            this.threeJsObj.textArr.push(circle1);
          } else {
            dot1Mesh.position.set(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], 0);
            dot1Mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
          }


          let dot2Mesh = this.threeJsObj.scene.getObjectByName("newRulerDot2_Mesh");
          if (!dot2Mesh) {
            const cirGeo2 = new THREE.CircleGeometry((this.newCreateAnnoInfo.drawingAnnoLineWidth * this.circleGeoInt), 32);
            const cirMat2 = new THREE.MeshBasicMaterial({ color: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')) });
            const circle2 = new THREE.Mesh(cirGeo2, cirMat2);
            circle2.position.set(this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4], 0);
            circle2.scale.set(1 / currZoom, 1 / currZoom, 1);
            this.threeJsObj.scene.add(circle2);
            Object.assign(circle2, { annoType: 'Ruler' });
            Object.assign(circle2, { name: "newRulerDot2_Mesh" });
            this.newCreateAnnoInfo.drawingAnnoNameArr.push("newRulerDot2_Mesh");
            this.threeJsObj.textArr.push(circle2);
          } else {
            // dot1Mesh.geometry = shapeGeom;
            dot2Mesh.position.set(this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4], 0);
            dot2Mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
          }

          // let rulerTextMesh = this.threeJsObj.scene.getObjectByName("newRulerAnno_Mesh");
          // let rulerTextBGMesh = this.threeJsObj.scene.getObjectByName("newRulerAnno_BGMesh");
          let rulerReading = this.getRealDistance({ posX0: this.newCreateAnnoInfo.drawingPoints[0], posY0: this.newCreateAnnoInfo.drawingPoints[1], posX1: this.newCreateAnnoInfo.drawingPoints[3], posY1: this.newCreateAnnoInfo.drawingPoints[4] }, this.osdObj.pixelToNanoVal);
          this.createRulerTextOnEnd(this.newCreateAnnoInfo.drawingPoints, rulerReading, this.newCreateAnnoInfo.drawingAnnoLineColor, pos);
          /* if (!rulerTextMesh) {
            // let txt_mesh = this.createRulerTextOnEnd(this.newCreateAnnoInfo.drawingPoints, this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4],
            //   rulerReading, parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')), null, this.threeJsObj.scene, this.newCreateAnnoInfo.drawingAnnoLineWidth, true, this.osdObj, this.threeJsObj, null);
            this.createRulerTextOnEnd(this.newCreateAnnoInfo.drawingPoints, rulerReading, this.newCreateAnnoInfo.drawingAnnoLineColor, pos);
            let meshName = "newRulerAnno_Mesh";
            let bgMeshName = "newRulerAnno_BGMesh";
            Object.assign(txt_mesh[0], { name: meshName });
            Object.assign(txt_mesh[1], { name: bgMeshName });
            this.newCreateAnnoInfo.drawingAnnoNameArr.push(meshName);
            this.newCreateAnnoInfo.drawingAnnoNameArr.push(bgMeshName);
          } else {
            // this.createRulerTextOnEnd(this.newCreateAnnoInfo.drawingPoints[0], this.newCreateAnnoInfo.drawingPoints[1], this.newCreateAnnoInfo.drawingPoints[3], this.newCreateAnnoInfo.drawingPoints[4],
            //   rulerReading, parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')), rulerTextMesh, this.threeJsObj.scene, this.newCreateAnnoInfo.drawingAnnoLineWidth, true, this.osdObj, this.threeJsObj, rulerTextBGMesh);
            this.createRulerTextOnEnd(this.newCreateAnnoInfo.drawingPoints, rulerReading, this.newCreateAnnoInfo.drawingAnnoLineColor, pos);
          } */

        } else {
          if (this.newCreateAnnoInfo.drawingPointsCount > 8) {
            let prev1LineAngle = (Math.atan2((pos.y - this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount - 2]), (pos.x - this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount - 3])) * 180 / Math.PI),
              prev2LineAngle = (Math.atan2((this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount - 2] - this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount - 5]), (this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount - 3] - this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount - 6])) * 360 / Math.PI);
            prev1LineAngle = Math.ceil((prev1LineAngle < 0) ? prev1LineAngle + 360 : prev1LineAngle);
            prev1LineAngle = (prev1LineAngle == 360) ? 0 : prev1LineAngle;
            prev2LineAngle = Math.ceil((prev2LineAngle < 0) ? prev2LineAngle + 360 : prev2LineAngle);
            prev2LineAngle = (prev2LineAngle == 360) ? 0 : prev2LineAngle;
            if (prev1LineAngle == prev2LineAngle) {
              this.newCreateAnnoInfo.drawingPointsCount = this.newCreateAnnoInfo.drawingPointsCount - 3;
            }
          }

          this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount] = pos.x;
          this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount + 1] = pos.y;
          this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount + 2] = 0;
          this.newCreateAnnoInfo.drawingPointsCount = this.newCreateAnnoInfo.drawingPointsCount + 3;
          instanceCount = (this.newCreateAnnoInfo.drawingPointsCount / 3) - 1;
          if (this.newCreateAnnoInfo.drawingAnnoType != "OpenFreeform") {
            this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount] = this.newCreateAnnoInfo.drawingAnnoInitPos.x;
            this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount + 1] = this.newCreateAnnoInfo.drawingAnnoInitPos.y;
            this.newCreateAnnoInfo.drawingPoints[this.newCreateAnnoInfo.drawingPointsCount + 2] = 0;
            instanceCount++;
            if (this.newCreateAnnoInfo.drawingAnnoType == "FilledFreeform") {
              this.newCreateAnnoInfo.filledFreeformShapeArr.push(new THREE.Vector2(pos.x, pos.y));

              var shape = new THREE.Shape(this.newCreateAnnoInfo.filledFreeformShapeArr);
              let shapeGeom = new THREE.ShapeGeometry(shape);

              let mesh = this.threeJsObj.scene.getObjectByName("newFilledFreeform_Mesh");

              if (!mesh) {
                let frame1 = new THREE.Mesh(shapeGeom, new THREE.MeshPhongMaterial({
                  color: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')),
                  emissive: parseInt(this.newCreateAnnoInfo.drawingAnnoLineColor.replace('#', '0x')),
                  opacity: this.newCreateAnnoInfo.filledAnnoOpacity,
                  transparent: true,
                }));

                let meshName = "newFilledFreeform_Mesh";
                Object.assign(frame1, { name: meshName });
                this.newCreateAnnoInfo.drawingAnnoNameArr.push(meshName);
                this.threeJsObj.scene.add(frame1);
              } else {
                mesh.geometry = shapeGeom;
              }
            }
          }
        }
        this.newCreateAnnoInfo.drawingMesh.geometry.setPositions(this.newCreateAnnoInfo.drawingPoints);
        this.newCreateAnnoInfo.drawingMesh.geometry.instanceCount = instanceCount;
        this.newCreateAnnoInfo.drawingMesh.geometry.maxInstancedCount = instanceCount;

        this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
        this.threeJsObj.camera.updateMatrixWorld();
      }
    }
  }

  createBatchAnno(event) {
    this.newCreateAnnoInfo.initiateDrawing = !this.newCreateAnnoInfo.initiateDrawing;

    this.osdObj.panHorizontal = this.osdObj.panVertical = false;
    let osdContentSize = this.osdObj.world._contentSize;
    var viewportPoint = this.osdObj.viewport.pointFromPixel(event.position);
    var imagePoint = this.osdObj.viewport.viewportToImageCoordinates(viewportPoint);
    var pos = {
      x: imagePoint.x - osdContentSize.x / 2,
      y: (osdContentSize.y / 2 - imagePoint.y),
    }

    this.threeJsObj.renderer3.domElement.style.cursor = "crosshair";
    if ((pos.x + osdContentSize.x / 2) < 0) {
      pos.x = (-osdContentSize.x / 2);
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
    } else if ((pos.x + osdContentSize.x / 2) > osdContentSize.x) {
      pos.x = osdContentSize.x / 2;
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
    }

    if (pos.y - (osdContentSize.y / 2) > 0) {
      pos.y = osdContentSize.y / 2;
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
    } else if ((pos.y + osdContentSize.y / 2) < 0) {
      pos.y = -osdContentSize.y / 2;
      this.threeJsObj.renderer3.domElement.style.cursor = "default";
    }

    if (this.newCreateAnnoInfo.drawingAnnoType == "Rectangular") {
      this.newCreateAnnoInfo.drawingPoints = [];
      let height = this.newCreateAnnoInfo.height;
      let width = this.newCreateAnnoInfo.width;

      let leftPosX = pos.x - width / 2;
      let leftPosY = pos.y - height / 2;

      let point1 = {
        x: leftPosX,
        y: leftPosY
      }

      let point2 = {
        x: leftPosX + width,
        y: leftPosY
      }

      let point3 = {
        x: leftPosX + width,
        y: leftPosY + height
      }

      let point4 = {
        x: leftPosX,
        y: leftPosY + height
      }

      if ((leftPosX < (-osdContentSize.x / 2)) || (leftPosY < (-osdContentSize.y / 2)) || (point3.x > (osdContentSize.x / 2)) || (point3.y > (osdContentSize.y / 2))) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.ANNO_OUT_OF_BOUNDARY"));
        return;
      }

      this.newCreateAnnoInfo.drawingAnnoInitPos.x = point1.x;
      this.newCreateAnnoInfo.drawingAnnoInitPos.y = point1.y;

      this.newCreateAnnoInfo.drawingPoints[0] = point1.x;
      this.newCreateAnnoInfo.drawingPoints[1] = point1.y;
      this.newCreateAnnoInfo.drawingPoints[2] = 0;

      this.newCreateAnnoInfo.drawingPoints[3] = point2.x;
      this.newCreateAnnoInfo.drawingPoints[4] = point2.y;
      this.newCreateAnnoInfo.drawingPoints[5] = 0;

      this.newCreateAnnoInfo.drawingPoints[6] = point3.x;
      this.newCreateAnnoInfo.drawingPoints[7] = point3.y;
      this.newCreateAnnoInfo.drawingPoints[8] = 0;

      this.newCreateAnnoInfo.drawingPoints[9] = point4.x;
      this.newCreateAnnoInfo.drawingPoints[10] = point4.y;
      this.newCreateAnnoInfo.drawingPoints[11] = 0;

      this.newCreateAnnoInfo.drawingPoints[12] = point1.x;
      this.newCreateAnnoInfo.drawingPoints[13] = point1.y;
      this.newCreateAnnoInfo.drawingPoints[14] = 0;

    } else if (this.newCreateAnnoInfo.drawingAnnoType == "Circular") {
      this.newCreateAnnoInfo.drawingAnnoInitPos.x = pos.x;
      this.newCreateAnnoInfo.drawingAnnoInitPos.y = pos.y;

      if (((pos.x - this.newCreateAnnoInfo.radius) < (-osdContentSize.x / 2)) ||  // check left side of image
        ((pos.x + this.newCreateAnnoInfo.radius) > (osdContentSize.x / 2)) || // check right side of image
        ((pos.y + this.newCreateAnnoInfo.radius) > (osdContentSize.y / 2)) || //  check top side of image
        ((pos.y - this.newCreateAnnoInfo.radius) < (-osdContentSize.y / 2))) { // check bottom side of image
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.ANNO_OUT_OF_BOUNDARY"));
        return;
      }

      const curve = new THREE.EllipseCurve(
        this.newCreateAnnoInfo.drawingAnnoInitPos.x, this.newCreateAnnoInfo.drawingAnnoInitPos.y,            // ax, aY
        this.newCreateAnnoInfo.radius, this.newCreateAnnoInfo.radius,           // xRadius, yRadius
        0, 2 * Math.PI,  // aStartAngle, aEndAngle
        false,            // aClockwise
        0                 // aRotation
      );

      let points = curve.getPoints(100);
      for (let x = 0; x < points.length; x++) {
        this.newCreateAnnoInfo.drawingPoints[(x * 3)] = points[x].x;
        this.newCreateAnnoInfo.drawingPoints[(x * 3) + 1] = points[x].y;
        this.newCreateAnnoInfo.drawingPoints[(x * 3) + 2] = 0;
      }
    }

    this.saveNewlyCreatedAnnotation();
  }

  saveNewlyCreatedAnnotation() {
    if (this.newAnnoSaveInfo.comment) {
      this.newAnnoSaveInfo.info.comment = this.newAnnoSaveInfo.comment.trim();
    }
    if (!this.newAnnoSaveInfo.info.comment) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.ENTER_ANNOTATION_COMMENT"));
      return;
    }
    this.newAnnoSaveInfo.info.color = this.newCreateAnnoInfo.drawingAnnoLineColor;
    this.newAnnoSaveInfo.info.zoom = this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom());
    this.newAnnoSaveInfo.info.size = this.newCreateAnnoInfo.drawingAnnoLineWidth;
    this.newAnnoSaveInfo.info.type = this.newCreateAnnoInfo.annoTypeObj[this.newCreateAnnoInfo.drawingAnnoType];
    this.newAnnoSaveInfo.roi = this.osdObj._sequenceIndex - 1;
    this.newAnnoSaveInfo.slideId = this.newCreateAnnoInfo.slideId;
    this.newAnnoSaveInfo.roleFk = this.newCreateAnnoInfo.roleFk;
    this.newAnnoSaveInfo.info.bookmarked = this.newCreateAnnoInfo.drawingAnnoBookmark;
    let currentUserObj = JSON.parse(localStorage.getItem('currentUser'));
    this.newAnnoSaveInfo.info.creator = currentUserObj.email;
    // this.newAnnoSaveInfo.areaPerimeterFlag = this.newCreateAnnoInfo.drawingAnnoAreaPerimeter;
    
    this.newAnnoSaveInfo.points = [];
    let osdContentSize = this.osdObj.world._contentSize;

    if (this.newCreateAnnoInfo.drawingAnnoType == "Rectangular") {
      /* this.newAnnoSaveInfo.points.push({
        "pointX": this.newCreateAnnoInfo.drawingPoints[0] + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingPoints[1] - osdContentSize.y / 2
      }, {
        "pointX": this.newCreateAnnoInfo.drawingPoints[6] + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingPoints[7] - osdContentSize.y / 2
      }); */
      this.newAnnoSaveInfo.points = [
        parseFloat((this.newCreateAnnoInfo.drawingPoints[0] + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[1] - osdContentSize.y / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[6] + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[7] - osdContentSize.y / 2).toFixed(2))
      ]
    } else if (this.newCreateAnnoInfo.drawingAnnoType == "Circular") {
      /* this.newAnnoSaveInfo.points.push({
        "pointX": this.newCreateAnnoInfo.drawingAnnoInitPos.x + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingAnnoInitPos.y - osdContentSize.y / 2
      }, {
        "pointX": this.newCreateAnnoInfo.drawingPoints[0] + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingPoints[1] - osdContentSize.y / 2
      }); */
      this.newAnnoSaveInfo.points = [
        parseFloat((this.newCreateAnnoInfo.drawingAnnoInitPos.x + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingAnnoInitPos.y - osdContentSize.y / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[0] + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[1] - osdContentSize.y / 2).toFixed(2))
      ]
    } else if (this.newCreateAnnoInfo.drawingAnnoType == "Ellipse") {
      /* this.newAnnoSaveInfo.points.push({
        "pointX": this.newCreateAnnoInfo.drawingAnnoInitPos.x + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingAnnoInitPos.y - osdContentSize.y / 2
      }, {
        "pointX": this.newCreateAnnoInfo.drawingAnnoInitPos.x + this.newCreateAnnoInfo.xRadius + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingAnnoInitPos.y - osdContentSize.y / 2
      }, {
        "pointX": this.newCreateAnnoInfo.drawingAnnoInitPos.x + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingAnnoInitPos.y + this.newCreateAnnoInfo.yRadius - + osdContentSize.y / 2
      }); */
      this.newAnnoSaveInfo.points = [
        parseFloat((this.newCreateAnnoInfo.drawingAnnoInitPos.x + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingAnnoInitPos.y - osdContentSize.y / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingAnnoInitPos.x + this.newCreateAnnoInfo.xRadius + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingAnnoInitPos.y - osdContentSize.y / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingAnnoInitPos.x + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingAnnoInitPos.y + this.newCreateAnnoInfo.yRadius - + osdContentSize.y / 2).toFixed(2))
      ]
    } else if (this.newCreateAnnoInfo.drawingAnnoType == "Ruler") {
      /* this.newAnnoSaveInfo.points.push({
        "pointX": this.newCreateAnnoInfo.drawingPoints[0] + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingPoints[1] - osdContentSize.y / 2
      }, {
        "pointX": this.newCreateAnnoInfo.drawingPoints[3] + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingPoints[4] - osdContentSize.y / 2
      }); */
      this.newAnnoSaveInfo.points = [
        parseFloat((this.newCreateAnnoInfo.drawingPoints[0] + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[1] - osdContentSize.y / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[3] + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[4] - osdContentSize.y / 2).toFixed(2))
      ];
    } else if (this.newCreateAnnoInfo.drawingAnnoType == "Arrow") {
      /* this.newAnnoSaveInfo.points.push({
        "pointX": this.newCreateAnnoInfo.drawingPoints[0] + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingPoints[1] - osdContentSize.y / 2
      }, {
        "pointX": this.newCreateAnnoInfo.drawingPoints[3] + osdContentSize.x / 2,
        "pointY": this.newCreateAnnoInfo.drawingPoints[4] - osdContentSize.y / 2
      }); */
      this.newAnnoSaveInfo.points = [
        parseFloat((this.newCreateAnnoInfo.drawingPoints[0] + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[1] - osdContentSize.y / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[3] + osdContentSize.x / 2).toFixed(2)),
        parseFloat((this.newCreateAnnoInfo.drawingPoints[4] - osdContentSize.y / 2).toFixed(2))
      ];
    } else {
      //let totalPointsLength = (this.newCreateAnnoInfo.drawingAnnoType == "Angle") ? this.newCreateAnnoInfo.drawingPoints.length : (this.newCreateAnnoInfo.drawingPointsCount + 1) / 3; //commented by mrunali to resolve openfreeform extra line drawing issue
      let totalPointsLength = (this.newCreateAnnoInfo.drawingAnnoType == "Angle") ? this.newCreateAnnoInfo.drawingPoints.length : this.newCreateAnnoInfo.drawingPointsCount / 3;
      for (let a = 0; a < totalPointsLength; a++) {
        /* this.newAnnoSaveInfo.points.push({
          "pointX": this.newCreateAnnoInfo.drawingPoints[(a * 3) + 0] + osdContentSize.x / 2,
          "pointY": this.newCreateAnnoInfo.drawingPoints[(a * 3) + 1] - osdContentSize.y / 2
        }); */
        this.newAnnoSaveInfo.points.push(parseFloat((this.newCreateAnnoInfo.drawingPoints[(a * 3) + 0] + osdContentSize.x / 2).toFixed(2)));
        this.newAnnoSaveInfo.points.push(parseFloat((this.newCreateAnnoInfo.drawingPoints[(a * 3) + 1] - osdContentSize.y / 2).toFixed(2)));
      }
    }
        if (this.newCreateAnnoInfo.drawingAnnoType == "FilledFreeform") {
      let x = 0;
      this.newAnnoSaveInfo.info.type = 'FREEFORMCLOSE';
      this.newAnnoSaveInfo.info.style = 'Fill';
    }

    let saveAnnotation: any;
    if (this.newAnnoSaveInfo.meetId) {
      saveAnnotation = this.viewerService.addDCAnnotation(this.newAnnoSaveInfo.meetId, this.newAnnoSaveInfo);
    } else {
      saveAnnotation = this.viewerService.addAnnotation(this.newAnnoSaveInfo);
    }
    saveAnnotation.subscribe(((success) => {
      if (this.newAnnoSaveInfo.meetId) {
        success.data.info.geomInfo.area=this.newAnnoSaveInfo.info.geomInfo.area;
        success.data.meetId = this.newAnnoSaveInfo.meetId;
        success.data.dcAction = 'create';
        this.setCurrentAnno(success.data);
      }
      this.closeAnnotation(() => {
        // let threeJsObj = this.imageViewerArr[Number(this.osdObj.id.replace("openseadragon_", ""))].threeJsObj;
        this.threeJsAnnotationSetup([success.data], this.osdObj, this.threeJsObj);
        // this.threeJsAnnotationSetup([success.data], this.osdObj);
        this.addAnnotation(success.data);
        console.log(success.data);
      });
    }).bind(this), ((error) => {
      this.closeAnnotation(() => {
        setTimeout(() => {
          this.isPerformingTask = false;
        }, 100);
      });
    }).bind(this));
  }

  createAnnoSuccess(success) {
    this.closeAnnotation(() => {
      this.threeJsAnnotationSetup([success.data], this.osdObj, this.threeJsObj);
      // this.threeJsAnnotationSetup([success.data], this.osdObj);
      setTimeout(() => {
        this.isPerformingTask = false;
      }, 100);
    });
  }

  closeAnnotation(callback: any = null, closeForBatch: boolean = false) {
    for (let i = 0; i < this.newCreateAnnoInfo.drawingAnnoNameArr.length; i++) {
      let sceneChild = this.threeJsObj.scene.getObjectByName(this.newCreateAnnoInfo.drawingAnnoNameArr[i]);
      if (sceneChild) {
        this.threeJsObj.scene.remove(sceneChild);
        sceneChild.geometry.dispose();
        sceneChild.material.dispose();
        sceneChild.visible = false;
      }
    }
    $(".createAnnoPopup").css({ 'left': 0 + 'px', 'top': 0 + 'px' }).hide();
    this.osdObj.setMouseNavEnabled(true);

    if (this.newCreateAnnoInfo.isBatch) {
      if (closeForBatch) {
        this.viewerService.setStopAnno(true);
        this.viewerService.disableHotKey(false);
      } else {
        this.newCreateAnnoInfo.startAnnoDrawing = true;
        this.newCreateAnnoInfo.isBatchAnnoCreated = true;
      }
    } else {
      if (!(this.newCreateAnnoInfo.isDrawingScreenShot && this.newCreateAnnoInfo.drawingAnnoType == "FilledFreeform")) {
        this.resetNewCreateAnnotationInfo({});
      }
      this.viewerService.disableHotKey(false)
      $('.disabledJoystickrotationNavigator,.disableTopBar').hide();
      $('#ViewerRotate').removeClass('disableFunction');
      $('#ViewerEasyView').removeClass('disableFunction');
    }
    this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
    this.threeJsObj.camera.updateMatrixWorld();

    $(".annoValue").addClass("dn");
    if (callback) {
      callback();
    }
  }

  threeJsAnnotationSetup(AnnoArr, currentOsd, threeJsObj) {
    let osdContentSize = currentOsd.world._contentSize;
    let currScene = threeJsObj.scene;
    let currCamera = threeJsObj.camera;
    for (let i = 0; i < AnnoArr.length; i++) {
      let indvAnno = AnnoArr[i];
      indvAnno.showAreaPerimeter = (["RECTANGLE", "CIRCLE", "ELLIPSE"].indexOf(indvAnno.info.type) >= 0);
      if (indvAnno.info.type == "RECTANGLE") {
        let [coOrdX0, coOrdY0, coOrdX1, coOrdY1] = indvAnno.points;
        var ang = Math.atan2((coOrdY1 - coOrdY0), (coOrdX1 - coOrdX0)) * 180 / Math.PI;
        let width = Math.abs(coOrdX1 - coOrdX0);
        let height = Math.abs(coOrdY1 - coOrdY0);
        let posX, posY;
        if (ang < 0 && ang > -90) { // LeftTop - BottomRight
          posX = (coOrdX0 - (osdContentSize.x / 2));
          posY = (coOrdY1 + (osdContentSize.y / 2));
        } else if (ang < -90) { // topRight - BottomLeft
          posX = (coOrdX1 - (osdContentSize.x / 2));
          posY = (coOrdY1 + (osdContentSize.y / 2));
        } else if (ang > 0 && ang < 90) { // BottomLeft - TopRight
          posX = (coOrdX0 - (osdContentSize.x / 2));
          posY = (coOrdY0 + (osdContentSize.y / 2));
        } else { // BottomRight - LeftTop
          posX = (coOrdX1 - (osdContentSize.x / 2));
          posY = (coOrdY0 + (osdContentSize.y / 2));
        }
        this.createRectangle(posX, posY, width, height, indvAnno, currScene);
      } else if (indvAnno.info.type == "CIRCLE") {
        let [coOrdX0, coOrdY0, coOrdX1, coOrdY1] = indvAnno.points;
        let circleRadius = Math.sqrt(Math.pow((coOrdX0 - coOrdX1), 2) + Math.pow((coOrdY0 - coOrdY1), 2));
        this.createCircle((coOrdX0 - (osdContentSize.x / 2)), (coOrdY0 + (osdContentSize.y / 2)), circleRadius, indvAnno, currScene);
      } else if (indvAnno.info.type == "ELLIPSE") {
        let [x0, y0, x1, y1, x2, y2] = indvAnno.points;
        let minRadius = Math.sqrt(Math.pow((x0 - x2), 2) + Math.pow((y0 - y2), 2));
        let maxRadius = Math.sqrt(Math.pow((x0 - x1), 2) + Math.pow((y0 - y1), 2));

        let posX = (x0 - (osdContentSize.x / 2));
        let posY = (y0 + (osdContentSize.y / 2));

        this.createEllipse(posX, posY, maxRadius, minRadius, 0, 2 * Math.PI, false, 0, indvAnno, currScene)
      } else if (indvAnno.info.type == "FREEFORMOPEN") {
        this.createFreeForm(indvAnno.points, false, false, -(osdContentSize.x / 2), (osdContentSize.y / 2), indvAnno, false, currScene);
      } else if (indvAnno.info.type == "FREEFORMCLOSE") {
        let isFilled = (indvAnno.info.style == 'Fill');
        this.createFreeForm(indvAnno.points, true, false, -(osdContentSize.x / 2), (osdContentSize.y / 2), indvAnno, isFilled, currScene);
      } else if (indvAnno.info.type == "FILLEDFREEFORM") {
        this.createFreeForm(indvAnno.points, true, false, -(osdContentSize.x / 2), (osdContentSize.y / 2), indvAnno, true, currScene);
      } else if (indvAnno.info.type == "ANGLE") {
        this.createAngle(indvAnno.points, -(osdContentSize.x / 2), (osdContentSize.y / 2), indvAnno, currScene, currentOsd, threeJsObj);
      } else if (indvAnno.info.type == "ARROW") {
        this.createArrow(indvAnno.points, -(osdContentSize.x / 2), (osdContentSize.y / 2), indvAnno, currScene);
      } else if (indvAnno.info.type == 'RULER') {
        let [x0, y0, x1, y1] = indvAnno.points;
        let position = {
          posX0: (x0 - (osdContentSize.x / 2)),
          posY0: (y0 + (osdContentSize.y / 2)),

          posX1: (x1 - (osdContentSize.x / 2)),
          posY1: (y1 + (osdContentSize.y / 2)),
        }

        indvAnno.position = position;

        this.createRuler(position, indvAnno, currScene, currentOsd.pixelToNanoVal, currentOsd, threeJsObj)
      }
    }
    threeJsObj.renderer3.render(currScene, currCamera);
    currCamera.updateMatrixWorld();
  }

  createRectangle(x: any, y: any, width: any, height: any, annoInfo: any, currScene: any) {
    let lineWidth = annoInfo.info.size;
    let lineColor = parseInt(annoInfo.info.color.replace('#', '0x'));

    let arr = [
      new THREE.Vector2(x, y + height),
      new THREE.Vector2(x + width, y + height),
      new THREE.Vector2(x + width, y),
      new THREE.Vector2(x, y),
    ];
    let shape = new THREE.Shape(arr);

    let shapeGeom = new THREE.ShapeGeometry(shape);

    let frame1 = new THREE.Mesh(shapeGeom, new THREE.MeshPhongMaterial({
      color: 0x009900,
      opacity: 0,
      transparent: true,
    }));

    currScene.add(frame1);

    const geometry = new LineGeometry();
    geometry.setPositions([x, y, 0, (x + width), y, 0, (x + width), (y + height), 0, x, (y + height), 0, x, y, 0,]); // [ x1, y1, z1,  x2, y2, z2, ... ] format

    const material = new LineMaterial({
      color: lineColor,
      linewidth: lineWidth
    });
    material.resolution.set(window.innerWidth, window.innerHeight);

    const myLine = new Line2(geometry, material);

    currScene.add(myLine);
    Object.assign(myLine, { name: annoInfo.id + "_Line" });
    Object.assign(myLine, { annoType: 'Rectangle' });
    Object.assign(frame1, { name: annoInfo.id + "_Mesh" });
    Object.assign(frame1, { annoType: 'Rectangle' });
    Object.assign(frame1, { wireframe: myLine });
    Object.assign(frame1, { initPoint: { x: x, y: y + height } });
    Object.assign(frame1, { actualSize: { width: width, height: height } });
    Object.assign(frame1, { annoInfo: annoInfo });
  }

  createCircle(cordX: any, cordY: any, radius: any, annoInfo: any, currScene: any) {
    let lineWidth = annoInfo.info.size;
    let lineColor = parseInt(annoInfo.info.color.replace('#', '0x'));

    var circleTri = new THREE.Shape();
    var segments = 108;
    var theta_next, x_next, y_next, j, posArr = [];
    for (var i = 0; i < segments; i++) {
      let theta = ((i + 1) / segments) * Math.PI * 2.0;
      let x = (radius * Math.cos(theta)) + cordX;
      let y = (radius * Math.sin(theta)) + cordY;
      j = i + 2;
      if ((j - 1) === segments) j = 1;
      theta_next = (j / segments) * Math.PI * 2.0;
      x_next = (radius * Math.cos(theta_next)) + cordX;
      y_next = (radius * Math.sin(theta_next)) + cordY;
      circleTri.moveTo(cordX, cordY);
      circleTri.lineTo(x, y);
      circleTri.lineTo(x_next, y_next);
      circleTri.lineTo(cordX, cordY);
      posArr.push(x, y, 0);
    }
    posArr.push(x_next, y_next, 0);

    let shapeGeom = new THREE.ShapeGeometry(circleTri);

    let frame1 = new THREE.Mesh(shapeGeom, new THREE.MeshPhongMaterial({
      color: 0x009900,
      opacity: 0,
      transparent: true,
    }));
    currScene.add(frame1);

    const geometry = new LineGeometry();
    geometry.setPositions(posArr); // [ x1, y1, z1,  x2, y2, z2, ... ] format

    const material = new LineMaterial({
      // color: 0x15447E,
      color: lineColor,
      linewidth: lineWidth, // px
      // linewidth: 3, // px
    });
    material.resolution.set(window.innerWidth, window.innerHeight);

    const myLine = new Line2(geometry, material);
    currScene.add(myLine);
    Object.assign(myLine, { name: annoInfo.id + "_Line" });
    Object.assign(myLine, { annoType: 'Circle' });
    Object.assign(frame1, { name: annoInfo.id + "_Mesh" });
    Object.assign(frame1, { annoType: 'Circle' });
    Object.assign(frame1, { wireframe: myLine });
    Object.assign(frame1, { initPoint: { x: (cordX - radius), y: (cordY + radius) } });
    Object.assign(frame1, { actualSize: { width: 2 * radius, height: 2 * radius } });
    Object.assign(frame1, { annoInfo: annoInfo });
  }

  createEllipse(centerX: any, centerY: any, xRadius: any, yRadius: any, aStartangle: any, aEndAngle: any, aClockwise: any, aRotation: any, annoInfo: any, currScene: any) {
    let lineWidth = annoInfo.info.size;
    let lineColor = parseInt(annoInfo.info.color.replace('#', '0x'));
    let posArr = [];
    const curve = new THREE.EllipseCurve(
      centerX, centerY,            // ax, aY
      xRadius, yRadius,           // xRadius, yRadius
      aStartangle, aEndAngle,  // aStartAngle, aEndAngle
      aClockwise,            // aClockwise
      aRotation                 // aRotation
    );

    let points = curve.getPoints(100);
    let shape = new THREE.Shape(points);

    let shapeGeom = new THREE.ShapeGeometry(shape);

    let frame1 = new THREE.Mesh(shapeGeom, new THREE.MeshPhongMaterial({
      color: 0x009900,
      opacity: 0,
      transparent: true,
    }));
    currScene.add(frame1);
    Object.assign(frame1, { annoType: 'Ellipse' });
    Object.assign(frame1, { name: annoInfo.id + "_Mesh" });
    Object.assign(frame1, { initPoint: { x: (centerX - xRadius), y: (centerY + yRadius) } });
    Object.assign(frame1, { actualSize: { width: 2 * xRadius, height: 2 * yRadius } });
    Object.assign(frame1, { annoInfo: annoInfo });

    for (let i = 0; i < points.length; i++) {
      posArr.push(points[i].x, points[i].y, 0);
    }
    const geometry = new LineGeometry();
    geometry.setPositions(posArr); // [ x1, y1, z1,  x2, y2, z2, ... ] format

    const material = new LineMaterial({
      color: lineColor,
      linewidth: lineWidth, // px
      // linewidth: 3, // px
    });
    material.resolution.set(window.innerWidth, window.innerHeight);

    const myLine = new Line2(geometry, material);
    currScene.add(myLine);
    Object.assign(frame1, { wireframe: myLine });
    Object.assign(myLine, { name: annoInfo.id + "_Line" });
    Object.assign(myLine, { annoType: 'Ellipse' });
  }

  createFreeForm(pointsArr: any = [], isClosedFreeForm: boolean = true, smoothEdge: boolean = false, offSetX: any = 0, offSetY: any = 0, annoInfo: any, isFilled: boolean = false, currScene: any) {
    let lineWidth = annoInfo.info.size;
    let lineColor = parseInt(annoInfo.info.color.replace('#', '0x'));

    if (smoothEdge) {
      pointsArr = this.smoothEdge(pointsArr, isClosedFreeForm, offSetX, offSetY);
    }

    let shapeArr = [], posArr = [], topLeftPoint: any = { x: undefined, y: undefined }, bottomRightPoint: any = { x: undefined, y: undefined };
    for (let a = 0; a < pointsArr.length; a++) {
      if (smoothEdge) {
        if (isClosedFreeForm) {
          shapeArr.push(new THREE.Vector2(pointsArr[a], pointsArr[a + 1]));
        }
        posArr.push((pointsArr[a]["x"]), (pointsArr[a + 1]["y"]), 0);
      } else {
        if (isClosedFreeForm) {
          shapeArr.push(new THREE.Vector2(pointsArr[a] + offSetX, pointsArr[a + 1] + offSetY));
        }
        posArr.push((pointsArr[a] + offSetX), (pointsArr[a + 1] + offSetY), 0);
      }

      //Get Min TopLeft Point
      if (!topLeftPoint.x) {
        topLeftPoint.x = (pointsArr[a] + offSetX);
      }
      if (!topLeftPoint.y) {
        topLeftPoint.y = (pointsArr[a + 1] + offSetY);
      }

      if (topLeftPoint.x && (topLeftPoint.x > (pointsArr[a] + offSetX))) {
        topLeftPoint.x = (pointsArr[a] + offSetX);
      }
      if (topLeftPoint.y && (topLeftPoint.y < (pointsArr[a + 1] + offSetY))) {
        topLeftPoint.y = (pointsArr[a + 1] + offSetY);
      }

      //Get Min BottomRight Point
      if (!bottomRightPoint.x) {
        bottomRightPoint.x = (pointsArr[a] + offSetX);
      }
      if (!bottomRightPoint.y) {
        bottomRightPoint.y = (pointsArr[a + 1] + offSetY);
      }

      if (bottomRightPoint.x && (bottomRightPoint.x < (pointsArr[a] + offSetX))) {
        bottomRightPoint.x = (pointsArr[a] + offSetX);
      }
      if (bottomRightPoint.y && (bottomRightPoint.y > (pointsArr[a + 1] + offSetY))) {
        bottomRightPoint.y = (pointsArr[a + 1] + offSetY);
      }
      a++;
    }

    let frame1: any;
    if (isClosedFreeForm) {
      if ((!smoothEdge) && (pointsArr[0] != pointsArr[pointsArr.length - 2]) && (pointsArr[1] != pointsArr[pointsArr.length - 1])) {
        posArr.push((pointsArr[0] + offSetX), (pointsArr[1] + offSetY), 0);
      }
      var shape = new THREE.Shape(shapeArr);
      let shapeGeom = new THREE.ShapeGeometry(shape);

      frame1 = new THREE.Mesh(shapeGeom, new THREE.MeshPhongMaterial({
        color: lineColor,
        emissive: lineColor,
        opacity: isFilled ? this.newCreateAnnoInfo.filledAnnoOpacity : 0,
        transparent: true,
      }));
      currScene.add(frame1);
      Object.assign(frame1, { annoType: isFilled ? "FilledFreeform" : "ClosedFreeform" });
      Object.assign(frame1, { name: annoInfo.id + "_Mesh" });
    }

    const geometry = new LineGeometry();
    geometry.setPositions(posArr);

    const material = new LineMaterial({
      color: lineColor,
      linewidth: lineWidth, // px
    });
    material.resolution.set(window.innerWidth, window.innerHeight);

    const myLine = new Line2(geometry, material);

    currScene.add(myLine);
    Object.assign(myLine, { name: annoInfo.id + "_Line" });
    if (isClosedFreeForm) {
      Object.assign(myLine, { annoType: isFilled ? "FilledFreeform" : 'ClosedFreeform' });
      Object.assign(frame1, { wireframe: myLine });
      Object.assign(frame1, { initPoint: { x: topLeftPoint.x, y: topLeftPoint.y } });
      if ($.isNumeric(topLeftPoint.x) && $.isNumeric(topLeftPoint.y) && $.isNumeric(bottomRightPoint.x) && $.isNumeric(bottomRightPoint.y)) {
        Object.assign(frame1, { actualSize: { width: Math.abs(bottomRightPoint.x - topLeftPoint.x), height: Math.abs(bottomRightPoint.y - topLeftPoint.y) } });
      }
      Object.assign(frame1, { annoInfo: annoInfo });
    } else {
      Object.assign(myLine, { annoType: 'OpenFreeform' });
      Object.assign(myLine, { initPoint: { x: topLeftPoint.x, y: topLeftPoint.y } });
      if ($.isNumeric(topLeftPoint.x) && $.isNumeric(topLeftPoint.y) && $.isNumeric(bottomRightPoint.x) && $.isNumeric(bottomRightPoint.y)) {
        Object.assign(myLine, { actualSize: { width: Math.abs(bottomRightPoint.x - topLeftPoint.x), height: Math.abs(bottomRightPoint.y - topLeftPoint.y) } });
      }
      Object.assign(myLine, { annoInfo: annoInfo });
    }
  }

  createAngle(anglePoints: any, offSetX: any = 0, offSetY: any = 0, annoInfo: any, currScene: any, currentOsd: any, threeJsObj: any) {
    let lineWidth = annoInfo.info.size;
    let lineColor = parseInt(annoInfo.info.color.replace('#', '0x'));

    let position = {
      posX0: anglePoints[0] + offSetX,
      posY0: anglePoints[1] + offSetY,

      posX1: anglePoints[2] + offSetX,
      posY1: anglePoints[3] + offSetY,

      posX2: anglePoints[4] + offSetX,
      posY2: anglePoints[5] + offSetY,
    }

    annoInfo.position = position;

    let initPoint = {
      x: Math.min(position.posX0, position.posX1, position.posX2),
      y: Math.max(position.posY0, position.posY1, position.posY2)
    };

    let endPoint = {
      x: Math.max(position.posX0, position.posX1, position.posX2),
      y: Math.min(position.posY0, position.posY1, position.posY2)
    };

    const geometry = new LineGeometry();
    geometry.setPositions([position.posX0, position.posY0, 0, position.posX1, position.posY1, 0, position.posX2, position.posY2, 0]);

    const material = new LineMaterial({
      color: lineColor,
      linewidth: lineWidth,
    });
    material.resolution.set(window.innerWidth, window.innerHeight);

    const myLine = new Line2(geometry, material);

    currScene.add(myLine);

    let degree_angle = this.get3PointsAngle(position.posX0, position.posY0, position.posX1, position.posY1, position.posX2, position.posY2);
    annoInfo.angle = degree_angle;

    Object.assign(myLine, { name: annoInfo.id + "_Line" });
    Object.assign(myLine, { annoType: 'Angle' });
    Object.assign(myLine, { initPoint: initPoint });
    Object.assign(myLine, { actualSize: { width: Math.abs(endPoint.x - initPoint.x), height: Math.abs(endPoint.y - initPoint.y) } });
    Object.assign(myLine, { annoInfo: annoInfo });

    let currZoom = parseFloat((currentOsd.viewport.viewportToImageZoom(currentOsd.viewport.getZoom()) * threeJsObj.schemaMaxZoomLevel).toFixed(2));
    const cirGeo0 = new THREE.CircleGeometry((lineWidth * this.circleGeoInt), 32);
    const cirMat0 = new THREE.MeshBasicMaterial({ color: lineColor });
    const circle0 = new THREE.Mesh(cirGeo0, cirMat0);
    circle0.position.set(position.posX0, position.posY0, 0);
    circle0.scale.set(1 / currZoom, 1 / currZoom, 1);
    currScene.add(circle0);
    Object.assign(circle0, { annoType: 'Angle' });
    Object.assign(circle0, { name: annoInfo.id + "_initDot" });
    threeJsObj.textArr.push(circle0);

    const cirGeo1 = new THREE.CircleGeometry((lineWidth * this.circleGeoInt), 32);
    const cirMat1 = new THREE.MeshBasicMaterial({ color: lineColor });
    const circle1 = new THREE.Mesh(cirGeo1, cirMat1);
    circle1.position.set(position.posX1, position.posY1, 0);
    circle1.scale.set(1 / currZoom, 1 / currZoom, 1);
    currScene.add(circle1);
    Object.assign(circle1, { annoType: 'Angle' });
    Object.assign(circle1, { name: annoInfo.id + "_midDot" });
    threeJsObj.textArr.push(circle1);

    const cirGeo2 = new THREE.CircleGeometry((lineWidth * this.circleGeoInt), 32);
    const cirMat2 = new THREE.MeshBasicMaterial({ color: lineColor });
    const circle2 = new THREE.Mesh(cirGeo2, cirMat2);
    circle2.position.set(position.posX2, position.posY2, 0);
    circle2.scale.set(1 / currZoom, 1 / currZoom, 1);
    currScene.add(circle2);
    Object.assign(circle2, { annoType: 'Angle' });
    Object.assign(circle2, { name: annoInfo.id + "_endDot" });
    threeJsObj.textArr.push(circle2);
  }

  createArrow(arrowPoints: any, offSetX: any = 0, offSetY: any = 0, annoInfo: any, currScene: any) {
    let lineWidth = annoInfo.info.size;
    let lineColor = parseInt(annoInfo.info.color.replace('#', '0x'));

    let pointX0 = arrowPoints[0] + offSetX;
    let pointY0 = arrowPoints[1] + offSetY;

    let pointX1 = arrowPoints[2] + offSetX;
    let pointY1 = arrowPoints[3] + offSetY;

    const dx = pointX1 - pointX0;
    const dy = pointY1 - pointY0;
    const headlen = Math.sqrt(Math.pow((dx), 2) + Math.pow((dy), 2)) * 0.1; // length of head in pixels
    const angle = Math.atan2(dy, dx);
    const headAngle = Math.PI / 4;

    let arrowHeadX0 = pointX1 - (headlen * Math.cos(angle - headAngle));
    let arrowHeadY0 = pointY1 - (headlen * Math.sin(angle - headAngle));
    let arrowHeadX1 = pointX1 - (headlen * Math.cos(angle + headAngle));
    let arrowHeadY1 = pointY1 - (headlen * Math.sin(angle + headAngle));

    let initPoint = {
      x: Math.min(pointX0, pointX1, arrowHeadX0, arrowHeadX1),
      y: Math.max(pointY0, pointY1, arrowHeadY0, arrowHeadY1)
    };

    let endPoint = {
      x: Math.max(pointX0, pointX1, arrowHeadX0, arrowHeadX1),
      y: Math.min(pointY0, pointY1, arrowHeadY0, arrowHeadY1)
    };

    const geometry = new LineGeometry();
    geometry.setPositions([pointX0, pointY0, 0, pointX1, pointY1, 0, arrowHeadX0, arrowHeadY0, 0, pointX1, pointY1, 0, arrowHeadX1, arrowHeadY1, 0]);

    const material = new LineMaterial({
      color: lineColor,
      linewidth: lineWidth, // px
    });
    material.resolution.set(window.innerWidth, window.innerHeight);

    const myLine = new Line2(geometry, material);

    currScene.add(myLine);
    Object.assign(myLine, { name: annoInfo.id + "_Line" });
    Object.assign(myLine, { annoType: 'Arrow' });
    Object.assign(myLine, { initPoint: initPoint });
    Object.assign(myLine, { actualSize: { width: Math.abs(endPoint.x - initPoint.x), height: Math.abs(endPoint.y - initPoint.y) } });
    Object.assign(myLine, { annoInfo: annoInfo });
    Object.assign(myLine, { arrowAngle: -(angle * 180 / Math.PI) });
  }

  createRuler(position: any, annoInfo: any, currScene: any, pixelToNanometer: any, currentOsd: any, threeJsObj: any) {
    let lineWidth = annoInfo.info.size;
    let lineColor = parseInt(annoInfo.info.color.replace('#', '0x'));
    annoInfo.length = this.getRealDistance(position, pixelToNanometer);

    const endLen = 50; // length of end lines

    var px = position.posY0 - position.posY1; // as vector at 90 deg to the line
    var py = position.posX1 - position.posX0;
    const len = endLen / Math.hypot(px, py);
    px *= len;  // make leng 10 pixels
    py *= len;

    const geometry = new LineGeometry();
    geometry.setPositions([
      // position.posX0 + px, position.posY0 + py, 0, // left side line top point
      // position.posX0 - px, position.posY0 - py, 0, // left side line bottom point

      position.posX0, position.posY0, 0, // line first point
      position.posX1, position.posY1, 0, // line second point

      // position.posX1 + px, position.posY1 + py, 0, // right side line top point
      // position.posX1 - px, position.posY1 - py, 0, // right side line bottom point
    ]);

    const angle = Math.atan2((position.posY1 - position.posY0), (position.posX1 - position.posX0));

    let initPoint = { x: 0, y: 0 };
    initPoint.x = Math.min(position.posX0 + px, position.posX0 - px, position.posX0, position.posX1 + px, position.posX1 - px, position.posX1);
    initPoint.y = Math.max(position.posY0 + py, position.posY0 - py, position.posY0, position.posY1 + py, position.posY1 - py, position.posY1);

    let endPoint = { x: 0, y: 0 };
    endPoint.x = Math.max(position.posX1 + px, position.posX1 - px, position.posX1, position.posX0 + px, position.posX0 - px, position.posX0);
    endPoint.y = Math.min(position.posY1 + py, position.posY1 - py, position.posY1, position.posY0 + py, position.posY0 - py, position.posY0);

    const material = new LineMaterial({
      color: lineColor,
      linewidth: lineWidth, // px
    });
    material.resolution.set(window.innerWidth, window.innerHeight);

    const myLine = new Line2(geometry, material);
    currScene.add(myLine);
    Object.assign(myLine, { name: annoInfo.id + "_Line" });
    Object.assign(myLine, { annoType: 'Ruler' });
    Object.assign(myLine, { initPoint: initPoint });
    Object.assign(myLine, { actualSize: { width: Math.abs(endPoint.x - initPoint.x), height: Math.abs(endPoint.y - initPoint.y) } });
    Object.assign(myLine, { annoInfo: annoInfo });

    /* let txt_mesh = this.createRulerText(position.posX0, position.posY0, position.posX1, position.posY1, rulerReading, lineColor, null, currScene, lineWidth, false, currentOsd, threeJsObj);
    Object.assign(txt_mesh, { annoType: 'Ruler' });
    Object.assign(myLine, { wireframe: txt_mesh });
    Object.assign(txt_mesh, { name: annoInfo.id + "_Mesh" });
    Object.assign(myLine, { arrowAngle: -(angle * 180 / Math.PI) });
    threeJsObj.textArr.push(txt_mesh); */

    let currZoom = parseFloat((currentOsd.viewport.viewportToImageZoom(currentOsd.viewport.getZoom()) * threeJsObj.schemaMaxZoomLevel).toFixed(2));
    const cirGeo1 = new THREE.CircleGeometry((lineWidth * this.circleGeoInt), 32);
    const cirMat1 = new THREE.MeshBasicMaterial({ color: lineColor });
    const circle1 = new THREE.Mesh(cirGeo1, cirMat1);
    circle1.position.set(position.posX0, position.posY0, 0);
    circle1.scale.set(1 / currZoom, 1 / currZoom, 1);
    currScene.add(circle1);
    Object.assign(circle1, { annoType: 'Ruler' });
    Object.assign(circle1, { name: annoInfo.id + "_initDot" });
    threeJsObj.textArr.push(circle1);

    const cirGeo2 = new THREE.CircleGeometry((lineWidth * this.circleGeoInt), 32);
    const cirMat2 = new THREE.MeshBasicMaterial({ color: lineColor });
    const circle2 = new THREE.Mesh(cirGeo2, cirMat2);
    circle2.position.set(position.posX1, position.posY1, 0);
    circle2.scale.set(1 / currZoom, 1 / currZoom, 1);
    currScene.add(circle2);
    Object.assign(circle2, { annoType: 'Ruler' });
    Object.assign(circle2, { name: annoInfo.id + "_endDot" });
    threeJsObj.textArr.push(circle2);
  }

  getAreaPerimeter(annoType: any, annoInfo: any) {

    this.newAnnoSaveInfo.area = { value: null, unit: null };
    this.newAnnoSaveInfo.perimeter = { value: null, unit: null };

    let pixelToNanometer = this.osdObj.pixelToNanoVal;

    if (annoType == "Rectangular") {
      annoInfo.width = Math.abs(annoInfo.drawingPoints[6] - annoInfo.drawingAnnoInitPos.x);
      annoInfo.height = Math.abs(annoInfo.drawingPoints[7] - annoInfo.drawingAnnoInitPos.y);

      this.newAnnoSaveInfo.area.value = (annoInfo.width * pixelToNanometer) * (annoInfo.height * pixelToNanometer);
      this.newAnnoSaveInfo.perimeter.value = 2 * ((annoInfo.width * pixelToNanometer) + (annoInfo.height * pixelToNanometer));
    } else if (annoType == "Circular") {
      this.newAnnoSaveInfo.area.value = Math.PI * Math.pow((annoInfo.radius * pixelToNanometer), 2);
      this.newAnnoSaveInfo.perimeter.value = 2 * Math.PI * (annoInfo.radius * pixelToNanometer);
    } else if (annoType == "Ellipse") {
      this.newAnnoSaveInfo.area.value = Math.PI * ((Math.abs(annoInfo.xRadius) * pixelToNanometer) / 2) * ((Math.abs(annoInfo.yRadius) * pixelToNanometer) / 2);
      this.newAnnoSaveInfo.perimeter.value = 2 * Math.PI * Math.sqrt((Math.pow((annoInfo.xRadius * pixelToNanometer), 2) + Math.pow((annoInfo.yRadius * pixelToNanometer), 2)) / 2);
    }

    if (this.newAnnoSaveInfo.area.value < Math.pow(10, 6)) {
      this.newAnnoSaveInfo.area.value = this.newAnnoSaveInfo.area.value.toFixed(2);
      this.newAnnoSaveInfo.area.unit = "nm²";
    } else if (this.newAnnoSaveInfo.area.value < Math.pow(10, 12)) {
      this.newAnnoSaveInfo.area.value = (this.newAnnoSaveInfo.area.value / 1000000).toFixed(2);
      this.newAnnoSaveInfo.area.unit = "μm²";
    } else if (this.newAnnoSaveInfo.area.value < Math.pow(10, 18)) {
      this.newAnnoSaveInfo.area.value = (this.newAnnoSaveInfo.area.value / Math.pow(10, 12)).toFixed(2);
      this.newAnnoSaveInfo.area.unit = "mm²";
    }

    if (this.newAnnoSaveInfo.perimeter.value < Math.pow(10, 3)) {
      this.newAnnoSaveInfo.perimeter.value = this.newAnnoSaveInfo.perimeter.value.toFixed(2);
      this.newAnnoSaveInfo.perimeter.unit = "nm";
    } else if (this.newAnnoSaveInfo.perimeter.value < Math.pow(10, 6)) {
      this.newAnnoSaveInfo.perimeter.value = (this.newAnnoSaveInfo.perimeter.value / 1000).toFixed(2);
      this.newAnnoSaveInfo.perimeter.unit = "μm";
    } else if (this.newAnnoSaveInfo.perimeter.value < Math.pow(10, 9)) {
      this.newAnnoSaveInfo.perimeter.value = (this.newAnnoSaveInfo.perimeter.value / Math.pow(10, 6)).toFixed(2);
      this.newAnnoSaveInfo.perimeter.unit = "mm";
    }
  }

  private createRulerTextOnEnd(points: any, rulerReading: string, lineColor: string, pos: any) {
    $(".annoValue").removeClass("dn");
    let leftPos = (this.newCreateAnnoInfo.drawingAnnoInitPos.pointX < pos.ViewportCoordinates.x) ? (pos.ViewportCoordinates.x + 10) : (this.newCreateAnnoInfo.drawingAnnoInitPos.pointX + 10);
    let topPos = (this.newCreateAnnoInfo.drawingAnnoInitPos.pointX < pos.ViewportCoordinates.x) ? (pos.ViewportCoordinates.y + 5) : (this.newCreateAnnoInfo.drawingAnnoInitPos.pointY + 5);
    $(".annoValue").css({
      "left": this.osdObj.element.offsetLeft + leftPos,
      "top": this.osdObj.element.offsetTop + topPos,
      // "color": lineColor,
      // "background-color": this.invertColor(lineColor, 70)
    }).html(rulerReading);
  }

  private createRulerTextOnEnd1(x0: number, y0: number, x1: number, y1: number, rulerReading: string, lineColor: number, existingMesh: any = null, currScene: any, lineWidth, isNewCreation: boolean, currentOsd: any, threeJsObj: any, existingTextBGMesh: any) {
    let textSize = (600 / window.devicePixelRatio);

    let textGeo = new TextGeometry(rulerReading, {
      font: this.textFont,
      size: textSize,
      height: 1,
    });
    textGeo.computeBoundingBox();

    let textWidth = (textGeo?.boundingBox?.max?.x);
    let textHeight = (textGeo?.boundingBox?.max?.y);

    let currZoom = parseFloat((currentOsd.viewport.viewportToImageZoom(currentOsd.viewport.getZoom()) * threeJsObj.schemaMaxZoomLevel).toFixed(2));
    let offsetPos = (500 / currZoom);
    let textPos = {
      x: (x0 < x1) ? (x1 + offsetPos) : (x0 + offsetPos),
      y: (x0 < x1) ? (y1 - offsetPos) : (y0 - offsetPos)
    }

    let txt_bg_mesh = existingTextBGMesh;
    let textBGoffsetPos = (250 / currZoom);
    let textBGPos = {
      x: (x0 < x1) ? (x1 + textBGoffsetPos) : (x0 + textBGoffsetPos),
      y: (x0 < x1) ? (y1 - textBGoffsetPos) : (y0 - textBGoffsetPos)
    }
    let arr = [
      new THREE.Vector2(0, 0),
      new THREE.Vector2(textWidth + (textBGoffsetPos * 2), 0),
      new THREE.Vector2(textWidth + (textBGoffsetPos * 2), textHeight),
      new THREE.Vector2(0, textHeight),
    ];
    let shape = new THREE.Shape(arr);
    let shapeGeom = new THREE.ShapeGeometry(shape);
    if (!existingTextBGMesh) {
      txt_bg_mesh = new THREE.Mesh(shapeGeom, new THREE.MeshPhongMaterial({
        color: 0x009900,
        emissive: 0x009900,
        opacity: 0.5,
        transparent: true,
      }));
      currScene.add(txt_bg_mesh);
    } else {
      txt_bg_mesh.geometry = shapeGeom;
    }

    let txt_mesh = existingMesh;
    if (!existingMesh) {
      var txt_mat = new THREE.MeshPhongMaterial({
        emissive: lineColor
      });
      txt_mesh = new THREE.Mesh(textGeo, txt_mat);
      currScene.add(txt_mesh);
    } else {
      txt_mesh.geometry = textGeo;
    }

    txt_mesh.position.set(textPos.x, textPos.y, 0);
    txt_mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
    txt_mesh.visible = isNewCreation;

    txt_bg_mesh.position.set(textBGPos.x, textBGPos.y, 0);
    txt_bg_mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
    txt_bg_mesh.visible = isNewCreation;

    return [txt_mesh, txt_bg_mesh];
  }

  private createRulerText(x0: number, y0: number, x1: number, y1: number, rulerReading: string, lineColor: number, existingMesh: any = null, currScene: any, lineWidth, isNewCreation: boolean, currentOsd: any, threeJsObj: any) {
    /* let mintextSize = 200, maxtextSize = 500;
    let distance = Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
    let size = (distance / 20), textSize;
    if (size < mintextSize) {
      textSize = mintextSize;
    } else if (size > maxtextSize) {
      textSize = maxtextSize;
    } else {
      textSize = size;
    } */

    let textSize = (600 / window.devicePixelRatio);
    let textAngle = Math.atan2((y0 - y1), (x0 - x1)) * 180 / Math.PI;
    textAngle = (textAngle < 0) ? textAngle + 180 : textAngle;
    textAngle = textAngle > 90 ? 180 + textAngle : textAngle;

    let textGeo = new TextGeometry(rulerReading, {
      font: this.textFont,
      size: textSize,
      height: 1,
    });
    textGeo.computeBoundingBox();

    let text_midPoint = {
      x: (x0 + x1) / 2,
      y: (y0 + y1) / 2
    }

    let txt_mesh = existingMesh;
    if (!existingMesh) {
      var txt_mat = new THREE.MeshPhongMaterial({
        emissive: lineColor
      });
      txt_mesh = new THREE.Mesh(textGeo, txt_mat);
    } else {
      txt_mesh.geometry = textGeo;
    }

    txt_mesh.annoAng = textAngle;
    txt_mesh.annoMidPoint = text_midPoint;
    let textWidth = (textGeo?.boundingBox?.max?.x);
    let textHeight = (textGeo?.boundingBox?.max?.y);
    let currZoom = parseFloat((currentOsd.viewport.viewportToImageZoom(currentOsd.viewport.getZoom()) * threeJsObj.schemaMaxZoomLevel).toFixed(2));

    /* let lineWidthX = ((textAngle > 0 && textAngle < 180) ? -lineWidth : lineWidth);
    let lineWidthY = ((textAngle > 90 && textAngle < 270) ? -lineWidth : lineWidth);
    txt_mesh.position.set((text_midPoint.x + (lineWidthX * 40)), (text_midPoint.y + (lineWidthY * 40)), 0); */

    /* let textX = ((textAngle > 0 && textAngle < 180) ? -textWidth : textWidth);
    let textY = ((textAngle > 90 && textAngle < 270) ? -textHeight : textHeight);
    txt_mesh.position.set((text_midPoint.x + textX), (text_midPoint.y + textY), 0); */

    let textOffsetLeft = Math.round((150 / currZoom) * (Math.cos((textAngle / 180) * Math.PI)));
    let textOffsetTop = Math.round((150 / currZoom) * (Math.sin((textAngle / 180) * Math.PI)));
    textOffsetTop = (textAngle > 0 && textAngle < 180) ? (textOffsetTop + (lineWidth * 20)) : (textOffsetTop - (lineWidth * 20));
    txt_mesh.position.set((text_midPoint.x - textOffsetLeft), (text_midPoint.y + textOffsetTop), 0);

    txt_mesh.rotation.z = ((Math.PI / 180) * textAngle);
    txt_mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
    txt_mesh.visible = isNewCreation;

    currScene.add(txt_mesh);
    return txt_mesh;
  }

  private getRealDistance(position: any, pixelToNanometer: number) {
    let xdiff = position.posX1 - position.posX0;
    let ydiff = position.posY1 - position.posY0;
    let canvasLength = Math.sqrt(xdiff * xdiff + ydiff * ydiff);
    let realDist = canvasLength * pixelToNanometer;
    let dist;
    if (realDist < Math.pow(10, 3)) {
      dist = realDist.toFixed(2);
      dist = dist.toString() + " nm";
    } else if (realDist < Math.pow(10, 6)) {
      dist = (realDist / 1000).toFixed(2);
      dist = dist.toString() + " μm";
    } else if (realDist < Math.pow(10, 9)) {
      dist = (realDist / Math.pow(10, 6)).toFixed(2);
      dist = dist.toString() + " mm";
    }

    return dist;
  };

  private get3PointsAngle(pointX0, pointY0, pointX1, pointY1, pointX2, pointY2) {
    let dx1 = pointX0 - pointX1;
    let dy1 = pointY0 - pointY1;
    let dx2 = pointX2 - pointX1;
    let dy2 = pointY2 - pointY1;

    let a1 = Math.atan2(dy1, dx1);
    let a2 = Math.atan2(dy2, dx2);
    let a = ((a2 - a1) * 180 / Math.PI + 360) % 360;
    let angle = Math.atan2(dx1 * dy2 - dy1 * dx2, dx1 * dx2 + dy1 * dy2);
    if (angle < 0) {
      angle = angle * -1;
    }
    let degree_angle = Math.ceil(angle * (180 / Math.PI));
    return degree_angle;
  }



  private showAngleTextOnMid(points: any, angleReading: any, lineColor: string, midPos: any, pos: any) {
    $(".annoValue").removeClass("dn");
    // let screenPos1 = this.toScreenXY(new THREE.Vector3(midPos.ViewportCoordinates.x, pos.posY1, 0), this.threeJsObj.camera, $(this.osdObj.container).find(".threeJsOverlaycanvas")[0]);
    // let screenPos2 = this.toScreenXY(new THREE.Vector3(pos.posX2, pos.posY2, 0), this.threeJsObj.camera, $(this.osdObj.container).find(".threeJsOverlaycanvas")[0]);

    let leftPos = ((pos.ViewportCoordinates.y > midPos.ViewportCoordinates.y) && (pos.ViewportCoordinates.x > midPos.ViewportCoordinates.x)) ? (midPos.ViewportCoordinates.x - $(".annoValue").width() - 10) : (midPos.ViewportCoordinates.x + 5);
    let topPos = (midPos.ViewportCoordinates.y + 10);
    let readingVal = angleReading + '°';

    $(".annoValue").html(readingVal).css({
      "left": this.osdObj.element.offsetLeft + leftPos,
      "top": this.osdObj.element.offsetTop + topPos,
      // "color": lineColor,
      // "background-color": this.invertColor(lineColor, 70)
    });
  }

  private showAngleText(line, lineColor, degree_angle, pointX0, pointY0, pointX1, pointY1, pointX2, pointY2, currScene, isNewCreation: boolean, currentOsd: any, threeJsObj: any) {
    // let textSize = parseFloat(Math.pow((line.actualSize.width * line.actualSize.height), 1 / 3).toFixed(2));
    let textSize = (500 / window.devicePixelRatio);;
    let textGeo = new TextGeometry(degree_angle + '°', {
      font: this.textFont,
      size: textSize,
      height: 1,
    });

    textGeo.computeBoundingBox();
    var txt_mat = new THREE.MeshPhongMaterial({
      color: lineColor,
      emissive: lineColor
    });

    var midPoint = {
      x: pointX0 + (pointX2 - pointX0) * 0.85,
      y: pointY0 + (pointY2 - pointY0) * 0.85
    }

    var txt_mesh = new THREE.Mesh(textGeo, txt_mat);
    var ang = Math.atan2((midPoint.y - pointY1), (midPoint.x - pointX1)) * 180 / Math.PI;
    ang = (ang < 0) ? ang + 360 : ang;
    txt_mesh.annoAng = ang;

    let textWidth = (textGeo?.boundingBox?.max?.x);
    let textHeight = (textGeo?.boundingBox?.max?.y);

    let textOffsetLeft = Math.round((textWidth + textWidth / 4) * (Math.cos((ang / 180) * Math.PI)));
    let textOffsetTop = Math.round((textHeight + textHeight / 4) * (Math.sin((ang / 180) * Math.PI)));

    var degTextPoint = {
      x: pointX1 + textOffsetLeft + ((ang > 180 && ang < 270) ? -(textWidth ? textWidth : 0) : 0),
      y: pointY1 + textOffsetTop + (((ang > 270 && ang < 360) && (pointY1 > pointY0)) ? -(textHeight ? textHeight : 0) : 0)
    };
    txt_mesh.position.set(degTextPoint.x, degTextPoint.y, 0);
    let currZoom = parseFloat((currentOsd.viewport.viewportToImageZoom(currentOsd.viewport.getZoom()) * threeJsObj.schemaMaxZoomLevel).toFixed(2));
    txt_mesh.scale.set(1 / currZoom, 1 / currZoom, 1);
    txt_mesh.visible = isNewCreation;

    currScene.add(txt_mesh);
    return txt_mesh;
  }

  private smoothEdge(pointsArr: any = [], isClosedFreeForm: boolean = false, offSetX: any = 0, offSetY: any = 0) {
    let curveArr = [];
    for (let a = 0; a < pointsArr.length; a++) {
      curveArr.push(new THREE.Vector2((pointsArr[a]["pointX"] + offSetX), (pointsArr[a]["pointY"]) + offSetY));
    }
    if (isClosedFreeForm)
      curveArr.push(new THREE.Vector2((pointsArr[0]["pointX"] + offSetX), (pointsArr[0]["pointY"]) + offSetY));

    var curve = new THREE.SplineCurve(curveArr);
    var points = curve.getPoints((pointsArr.length + 1) * 10);

    return points;
  }

  showAnnotation(annotationsList, annoBoard, osd, OpenSeadragon) {
    this.setOnlyAnnotations(this.allAnnotationList);
    for (var index in annotationsList) {
      let annotationObj: any,
        annotation = annotationsList[index];
      annotationObj = this.getAnnotationCurrentZoom(annotation, osd, OpenSeadragon);
      annotationObj.comment = annotation.comment;
      annotationObj.user = annotation.createdBy;
      annotationObj.colour = annotation.colour != null ? annotation.colour : "#00ff00";
      annotationObj.id = annotation.id;
      annotationObj.slideId = annotation.slideId;
      annotationObj.meetId = annotation.meetId;
      annotationObj.areaPerimeterFlag = annotation.areaPerimeterFlag;
      annotationObj.bookmarkFlag = annotation.bookmarkFlag;
      annotationObj.thickness = annotation.thickness.value;
      annotationObj.area = annotation.area
      annotationObj.perimeter = annotation.perimeter;

      if (annotationObj.type) {
        annotationObj.element = annoBoard.showAnnotation(annotationObj);
      }
    }
  }

  getAnnotationCurrentZoom(annotation, osd, OpenSeadragon) {
    let annotationType = annotation.type,
      annotationObj: any = {};
    switch (annotationType) {
      case "RECTANGLE":
        annotationObj.type = "Rectangular";
        //To Do: -1 multiplier needs to be reverted after h'bad demo
        var viewerPoint1 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[0].pointX, -1 * annotation.points[0].pointY)),
          viewerPoint2 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[1].pointX, -1 * annotation.points[1].pointY));
        annotationObj.x = viewerPoint1.x;
        annotationObj.y = viewerPoint1.y;
        annotationObj.width = viewerPoint2.x - viewerPoint1.x;
        annotationObj.height = viewerPoint2.y - viewerPoint1.y;
        break;
      case "CIRCLE":
        annotationObj.type = "Circular";
        var viewerPoint1 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[0].pointX, -1 * annotation.points[0].pointY)),
          viewerPoint2 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[1].pointX, -1 * annotation.points[1].pointY));
        annotationObj.centerX = viewerPoint1.x;
        annotationObj.centerY = viewerPoint1.y;
        annotationObj.radius = Math.sqrt((viewerPoint2.x - viewerPoint1.x) * (viewerPoint2.x - viewerPoint1.x) + (viewerPoint2.y - viewerPoint1.y) * (viewerPoint2.y - viewerPoint1.y));
        break;
      case "ELLIPSE":
        annotationObj.type = "Ellipse";
        var viewerPoint1 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[0].pointX, -1 * annotation.points[0].pointY)),
          viewerPoint2 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[1].pointX, -1 * annotation.points[1].pointY)),
          viewerPoint3 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[2].pointX, -1 * annotation.points[2].pointY));
        annotationObj.centerX = viewerPoint1.x;
        annotationObj.centerY = viewerPoint1.y;
        annotationObj.maxDiameter = (Math.sqrt((viewerPoint2.x - viewerPoint1.x) * (viewerPoint2.x - viewerPoint1.x) + (viewerPoint2.y - viewerPoint1.y) * (viewerPoint2.y - viewerPoint1.y))) * 2;
        annotationObj.minDiameter = (Math.sqrt((viewerPoint3.x - viewerPoint1.x) * (viewerPoint3.x - viewerPoint1.x) + (viewerPoint3.y - viewerPoint1.y) * (viewerPoint3.y - viewerPoint1.y))) * 2;
        // annotationObj.maxDiameter = viewerPoint2.x - viewerPoint1.x;
        // annotationObj.minDiameter = viewerPoint2.y - viewerPoint1.y;
        break;
      case "FREEFORMCLOSE":
      case "FREEFORMOPEN":
        annotationObj.type = annotationType === "FREEFORMCLOSE" ? "Freeform" : "OpenFreeform";
        annotationObj.points = [];
        for (var ptIndex in annotation.points) {
          var viewerPoint = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[ptIndex].pointX, -1 * annotation.points[ptIndex].pointY));
          annotationObj.points.push(viewerPoint);
        }
        break;
      case "RULER":
      case "ARROW":
        annotationObj.type = annotationType === "RULER" ? "Ruler" : "Arrow";
        var viewerPoint1 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[0].pointX, -1 * annotation.points[0].pointY)),
          viewerPoint2 = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[1].pointX, -1 * annotation.points[1].pointY));
        annotationObj.x1 = viewerPoint1.x;
        annotationObj.y1 = viewerPoint1.y;
        annotationObj.x2 = viewerPoint2.x;
        annotationObj.y2 = viewerPoint2.y;
        break;
      case "ANGLE":
        annotationObj.type = "Angle";
        annotationObj.points = [];
        for (var ptIndex in annotation.points) {
          var viewerPoint = osd.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(annotation.points[ptIndex].pointX, -1 * annotation.points[ptIndex].pointY));
          annotationObj.points.push(viewerPoint);
        }
        break;
    }
    return annotationObj;
  }

  /* setAllAnnotations(annotationObject: any) {
    //this.allAnnotationsSource.next(annotationObject);
    this.setOnlyAnnotations(this.allAnnotationList);
  } */

  setAnnoTextScale(currZoom, threeJsObj) {
    if (threeJsObj.lastAnimationZoom != currZoom) {
      threeJsObj.lastAnimationZoom = currZoom;
      for (let x = 0; x < threeJsObj.textArr.length; x++) {
        threeJsObj.textArr[x].scale.set(1 / currZoom, 1 / currZoom, 1);
      }
    }
  }

  addAnnotation(annotationObject: any) {
    // this.allAnnotationList.unshift(annotationObject);
    let annoObjFound = false;
    for (let i = 0; i < this.allAnnotationList.length; i++) {
      if (this.allAnnotationList[i].id == annotationObject.id) {
        this.allAnnotationList[i] = annotationObject;
        annoObjFound = true;
        break;
      }
    }
    if (!annoObjFound) {
      this.allAnnotationList.unshift(annotationObject);
    }
    this.setOnlyAnnotations(this.allAnnotationList);
  }

  deleteAnnotation(id: number) {
    let annoArray = this.removeByAttr(this.allAnnotationList, 'id', id);
    this.setOnlyAnnotations(this.allAnnotationList);
  }

  deleteAnnoSuccess(selectedAnnoId) {
    console.log("anno id: " + selectedAnnoId);
    let mesh = this.threeJsObj.scene.getObjectByName(selectedAnnoId + "_Mesh");
    if (mesh) {
      mesh.geometry.dispose();
      mesh.material.dispose();
      this.threeJsObj.scene.remove(mesh);

      if ((mesh.annoType == "Ruler") || (mesh.annoType == "Angle")) {
        for (let a = 0; a < this.threeJsObj.textArr.length; a++) {
          if (this.threeJsObj.textArr[a].name == mesh.name) {
            this.threeJsObj.textArr.splice(a, 1);
            break;
          }
        }
      }
    }

    let line = this.threeJsObj.scene.getObjectByName(selectedAnnoId + "_Line");
    if (line) {
      line.geometry.dispose();
      line.material.dispose();
      this.threeJsObj.scene.remove(line);
    }

    let initDot = this.threeJsObj.scene.getObjectByName(selectedAnnoId + "_initDot");
    if (initDot) {
      initDot.geometry.dispose();
      initDot.material.dispose();
      this.threeJsObj.scene.remove(initDot);
    }

    let midDot = this.threeJsObj.scene.getObjectByName(selectedAnnoId + "_midDot");
    if (midDot) {
      midDot.geometry.dispose();
      midDot.material.dispose();
      this.threeJsObj.scene.remove(midDot);
    }

    let endDot = this.threeJsObj.scene.getObjectByName(selectedAnnoId + "_endDot");
    if (endDot) {
      endDot.geometry.dispose();
      endDot.material.dispose();
      this.threeJsObj.scene.remove(endDot);
    }

    for (let i = this.threeJsObj.scene.children.length - 1; i >= 0; i--) {
      let obj = this.threeJsObj.scene.children[i];
      if ((obj.name == selectedAnnoId + "_Line") || (obj.name == selectedAnnoId + "_Mesh") || (obj.name == selectedAnnoId + "_initDot") || (obj.name == selectedAnnoId + "_midDot") || (obj.name == selectedAnnoId + "_endDot")) {
        this.threeJsObj.scene.children.splice(i, 1);
      }
    }

    this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
    this.threeJsObj.camera.updateMatrixWorld();

    $("#" + this.osdObj.id + "-annoHighlight").css({ "width": 0, "height": 0, "left": 0, "top": 0 }).hide();

    this.deleteAnnotation(selectedAnnoId);
  }

  saveAnnoSuccess(editedAnnoInfo) {
    for (let x = 0; x < this.threeJsObj.scene.children.length; x++) {
      if (this.threeJsObj.scene.children[x].annoInfo && (this.threeJsObj.scene.children[x].annoInfo.id == editedAnnoInfo.id)) {
        this.threeJsObj.scene.children[x].annoInfo = editedAnnoInfo;
      }
    }

    var changedColor = parseInt(editedAnnoInfo.colour.replace('#', '0x'));

    if ((editedAnnoInfo.info.type != "FREEFORMOPEN") && (editedAnnoInfo.info.type != "ARROW")) {
      let mesh = this.threeJsObj.scene.getObjectByName(editedAnnoInfo.id + "_Mesh");
      if (mesh) {
        mesh.material.color.set(changedColor);
        if (mesh.wireframe) {
          mesh.wireframe.material.color.set(changedColor);
        }
        if ((editedAnnoInfo.info.type == "FILLEDFREEFORM") || (editedAnnoInfo.info.type == "ANGLE")) {
          mesh.material.emissive.set(changedColor);
        }
      }
    }

    let line = this.threeJsObj.scene.getObjectByName(editedAnnoInfo.id + "_Line");
    line.material.color.set(changedColor);

    this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
    this.threeJsObj.camera.updateMatrixWorld();

    if (editedAnnoInfo.meetId) {
      for (let a = 0; a < this.allAnnotationList.length; a++) {
        if (this.allAnnotationList[a].id == editedAnnoInfo.id) {
          this.allAnnotationList[a] = { ...editedAnnoInfo };
          break;
        }
      }
    }

    this.setOnlyAnnotations(this.allAnnotationList);

    if (editedAnnoInfo.bookmarkFlag) {
      $(".annotationPopOver").find(".annoEditDiv .iconActions").find(".enableBookmarkToolTip").show();
    }
  }

  editAnnotation(annotationObject: any) {
    let annoArray = this.editByAttr(this.allAnnotationList, 'id', annotationObject.id, annotationObject);
    this.setOnlyAnnotations(annoArray);
  }

  private removeByAttr(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] == value)) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  private editByAttr(arr, attr, value, element) {
    let i = arr.length, index;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {

        arr[i].comment = element.comment;
        arr[i].areaPerimeterFlag = element.areaPerimeterFlag;
        arr[i].bookmarkFlag = element.bookmarkFlag;
        index = i;
      }
    }
    arr = this.array_move(arr, index, 0);
    return arr;
  }

  private array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  setOnlyAnnotations(annotationData: any) {
    this.allAnnotationList = annotationData;
    this.allAnnotationsSource.next(annotationData);
  }

  raycasterCallback(performRaycast: any, clientPointX: any, clientPointY: any) {
    if (!performRaycast || this.newCreateAnnoInfo.startAnnoDrawing) {
      return;
    }
    this.threeJsObj.raycaster.setFromCamera(this.threeJsObj.mouse, this.threeJsObj.camera);
    // var intersects = this.raycaster.intersectObjects(this.raycastingArr, false);
    var intersectsArr = this.threeJsObj.raycaster.intersectObjects(this.threeJsObj.scene.children, false);

    let intersects = intersectsArr.filter((obj: any) => {
      return (obj.object.visible && ((obj.object.annoType &&
        (obj.object.type == "Mesh" && obj.object.annoType != "Angle") &&
        (obj.object.type == "Mesh" && obj.object.annoType != "Ruler")) ||
        (obj.object.annoType == "OpenFreeform") || (obj.object.annoType == "Arrow") ||
        (obj.object.type == "Line2" && obj.object.annoType == "Ruler") || (obj.object.type == "Line2" && obj.object.annoType == "Angle")));
    });

    if (intersects.length > 0) {
      if ($(".annotationPopOver .annoEditDiv").is(":visible") || $(".createAnnoPopup").is(":visible")) {
        // this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.CLOSE_ANNOTATION_POPUP"));
        return;
      }

      let i: any = (intersects.length > 1) ? this.getMinAreaAnno(intersects) : 0;
      if (this.currentlyShowingAnnoId == intersects[i].object.annoInfo.id) {
        return;
      }

      /* if (this.currentlyShowingAnno && ((this.currentlyShowingAnno.annoType == "Ruler") || (this.currentlyShowingAnno.annoType == "Angle"))) {
        this.currentlyShowingAnno.wireframe.visible = false;
      } */
      if (this.currentlyShowingAnno && ((this.currentlyShowingAnno.annoType == "Ruler") || (this.currentlyShowingAnno.annoType == "Angle"))) {
        $(".annoValue").addClass("dn");
      }

      // let i = intersects.length - 1;
      this.currentlyShowingAnnoId = intersects[i].object.annoInfo.id;
      this.currentlyShowingAnno = intersects[i].object;
      let annoTypeArr = ['Rectangle', 'Circle', 'ClosedFreeform', 'OpenFreeform', 'FilledFreeform', 'Ellipse', 'Arrow', 'Angle', 'Ruler'];
      if (annoTypeArr.indexOf(intersects[i].object.annoType) < 0) {
        return;
      }
      if (intersects[i].object.initPoint) {

        let objSize = this.getCurrentZoomAnnotationSize(intersects[i].object);
        let objCoOrds = this.imgToViewportCoOrds(intersects[i].object.initPoint);

        let osdContentSize = { x: this.osdObj.canvas.offsetWidth, y: this.osdObj.canvas.offsetHeight, };
        if (!(objCoOrds.x < 0 && objCoOrds.y < 0 && (objSize.width + objCoOrds.x) > osdContentSize.x && (objSize.height + objCoOrds.y) > osdContentSize.y)) {
          $(".annotationPopOver .annoEditDiv").addClass("dn");
          $(".annotationPopOver .annoToolTip").show();

          let annoHighlightRotateAngle = this.osdObj.viewport.getRotation();
          $("#" + this.osdObj.id + "-annoHighlight").css({
            "width": objSize.width,
            "height": objSize.height,
            "left": (objCoOrds.x),
            "top": (objCoOrds.y),
            'border-radius': (intersects[i].object.annoType == 'Circle' || intersects[i].object.annoType == 'Ellipse') ? '50%' : '0%',
            "transform": "rotate(" + annoHighlightRotateAngle + "deg)",
            "transform-origin": "top left"
          }).show();
          // this.selectedAnnoInfoObj = intersects[i].object.annoInfo;
          this.selectedAnnoInfoSource.next(intersects[i].object.annoInfo);

          let viewerContentElem: any = $(".annotationPopOver").closest(".viewercontent");
          let annotationPopOverElem: any = $(".annotationPopOver");
          let posX = ((clientPointX + $(".annotationPopOver").width()) > viewerContentElem.width()) ? (viewerContentElem.width() - annotationPopOverElem.width()) : clientPointX;
          let posY = ((clientPointY + $(".annotationPopOver").height()) > viewerContentElem.height()) ? (viewerContentElem.height() - annotationPopOverElem.height()) : clientPointY;


          if ((this.currentlyShowingAnno.annoType == "Ruler") || (this.currentlyShowingAnno.annoType == "Angle")) {
            // let mesh = this.threeJsObj.scene.getObjectByName("rectScreenShot_Mesh");
            $(".annoValue").removeClass("dn");
            let pos = this.currentlyShowingAnno.annoInfo.position;
            let leftPos, topPos, readingVal;
            if (this.currentlyShowingAnno.annoType == "Angle") {
              let screenPos1 = this.toScreenXY(new THREE.Vector3(pos.posX1, pos.posY1, 0), this.threeJsObj.camera, $(this.osdObj.container).find(".threeJsOverlaycanvas")[0]);
              let screenPos2 = this.toScreenXY(new THREE.Vector3(pos.posX2, pos.posY2, 0), this.threeJsObj.camera, $(this.osdObj.container).find(".threeJsOverlaycanvas")[0]);

              let annoValElem: any = $(".annoValue");
              leftPos = ((screenPos2.y > screenPos1.y) && (screenPos2.x > screenPos1.x)) ? (screenPos1.x - annoValElem.width() - 10) : (screenPos1.x + 5);
              topPos = (screenPos1.y + 10);
              readingVal = this.currentlyShowingAnno.annoInfo.angle + '°';
            } else {
              let screenPos0 = this.toScreenXY(new THREE.Vector3(pos.posX0, pos.posY0, 0), this.threeJsObj.camera, $(this.osdObj.container).find(".threeJsOverlaycanvas")[0]);
              let screenPos1 = this.toScreenXY(new THREE.Vector3(pos.posX1, pos.posY1, 0), this.threeJsObj.camera, $(this.osdObj.container).find(".threeJsOverlaycanvas")[0]);
              leftPos = ((screenPos0.x < screenPos1.x) ? screenPos1.x : screenPos0.x) + 10;
              topPos = ((screenPos0.x < screenPos1.x) ? screenPos1.y : screenPos0.y) + 5;
              readingVal = this.currentlyShowingAnno.annoInfo.length;
            }

            $(".annoValue").html(readingVal).css({
              "left": this.osdObj.element.offsetLeft + leftPos,
              "top": this.osdObj.element.offsetTop + topPos,
              // "color": this.currentlyShowingAnno.annoInfo.colour,
              // "background-color": this.invertColor(this.currentlyShowingAnno.annoInfo.colour, 70)
            });

            /* if (this.currentlyShowingAnno.annoType == "Angle") {
              this.currentlyShowingAnno.wireframe.visible = true;
              let textGeo = this.currentlyShowingAnno.wireframe.geometry;
              textGeo.computeBoundingBox();

              let textWidth = (textGeo?.boundingBox?.max?.x) * this.currentlyShowingAnno.wireframe.scale.x;
              let textHeight = (textGeo?.boundingBox?.max?.y) * this.currentlyShowingAnno.wireframe.scale.y;

              let ang = this.currentlyShowingAnno.wireframe.annoAng;
              let textOffsetLeft = Math.round((textWidth + textWidth / 4) * (Math.cos((ang / 180) * Math.PI)));
              let textOffsetTop = Math.round((textHeight + textHeight / 4) * (Math.sin((ang / 180) * Math.PI)));

              let annoPoints = this.currentlyShowingAnno.wireframe.annoPoints;
              var degTextPoint = {
                x: annoPoints[2] + textOffsetLeft + ((ang > 180 && ang < 270) ? -(textWidth ? textWidth : 0) : 0),
                y: annoPoints[3] + textOffsetTop + (((ang > 270 && ang < 360) && (annoPoints[3] > annoPoints[1])) ? -(textHeight ? textHeight : 0) : 0)
              };
              this.currentlyShowingAnno.wireframe.position.set(degTextPoint.x, degTextPoint.y, 0);

            } else {
              let textWidth = (textGeo?.boundingBox?.max?.x) * this.currentlyShowingAnno.wireframe.scale.x;
              let textHeight = (textGeo?.boundingBox?.max?.y) * this.currentlyShowingAnno.wireframe.scale.y;
              let textAngle = this.currentlyShowingAnno.wireframe.annoAng;
              let text_midPoint = this.currentlyShowingAnno.wireframe.annoMidPoint;
  
              let textOffsetLeft = Math.round((textWidth / 2) * (Math.cos((textAngle / 180) * Math.PI)));
              let textOffsetTop = Math.round((textHeight / 2) * (Math.sin((textAngle / 180) * Math.PI)));
              textOffsetTop = (textAngle > 0 && textAngle < 180) ? (textOffsetTop + (this.currentlyShowingAnno.annoInfo.thickness.value * 20)) : (textOffsetTop - (this.currentlyShowingAnno.annoInfo.thickness.value * 20));
              this.currentlyShowingAnno.wireframe.position.set((text_midPoint.x - textOffsetLeft), (text_midPoint.y + textOffsetTop), 0);
            } */
          }

          $(".annotationPopOver").css({
            'left': posX,
            'top': posY
          }).show();

          setTimeout(() => {
            let annoTooltipIconsElem: any = $(".annoTooltipIcons");
            let annoTextElem: any = $(".annoText");
            $(".annotationPopOver").closest(".annoPopOverDiv").css({
              'width': (annoTooltipIconsElem.width() + annoTextElem.width()) + this.commonService.vwTOpx('1.25vw'),
              'height': (annoTooltipIconsElem.height() + this.commonService.vwTOpx('0.125vw'))
            });
          }, 50);
          // }
        } else {
          if ($('.annotationPopOver').length > 0) {
            $("#" + this.osdObj.id + "-annoHighlight").css({ "width": 0, "height": 0, "left": 0, "top": 0, /* 'transform': 'none' */ }).hide();
            $('.annotationPopOver').hide();
            /* if (this.currentlyShowingAnno && ((this.currentlyShowingAnno.annoType == "Ruler") || (this.currentlyShowingAnno.annoType == "Angle"))) {
              this.currentlyShowingAnno.wireframe.visible = false;
            } */
            if (this.currentlyShowingAnno && ((this.currentlyShowingAnno.annoType == "Ruler") || (this.currentlyShowingAnno.annoType == "Angle"))) {
              $(".annoValue").addClass("dn");
            }
            this.currentlyShowingAnnoId = null;
            this.currentlyShowingAnno = null;
          }
        }
      }
    } else {
      if ($(".annotationPopOver .annoEditDiv").is(":visible") || $(".createAnnoPopup").is(":visible")) {
        return;
      }
      // $("#" + this.osdObj.id + "-annoHighlight").css({ "width": 0, "height": 0, "left": 0, "top": 0 }).hide();
      $(".annotationPopOver .annoEditDiv").addClass("dn");
      $(".annotationPopOver .annoToolTip").show();
      if (!($('.annotationPopOver:hover').length > 0)) {
        $("#" + this.osdObj.id + "-annoHighlight").css({ "width": 0, "height": 0, "left": 0, "top": 0 }).hide();
        $('.annotationPopOver').hide();
        /* if (this.currentlyShowingAnno && ((this.currentlyShowingAnno.annoType == "Ruler") || (this.currentlyShowingAnno.annoType == "Angle"))) {
          this.currentlyShowingAnno.wireframe.visible = false;
        } */
        if (this.currentlyShowingAnno && ((this.currentlyShowingAnno.annoType == "Ruler") || (this.currentlyShowingAnno.annoType == "Angle"))) {
          $(".annoValue").addClass("dn");
        }
        this.currentlyShowingAnnoId = null;
        this.currentlyShowingAnno = null;
      }
    }
    this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
  }

  toScreenXY(position, camera, div) {
    var pos = position.clone();
    let projScreenMat = new THREE.Matrix4();
    projScreenMat.multiply(camera.projectionMatrix, camera.matrixWorldInverse);
    projScreenMat.multiplyVector3(pos);
    return {
      x: (pos.x + 1) * div.width / 2,
      y: (- pos.y + 1) * div.height / 2
    };
  }

  invertColor(hex, opacity: number = 100) {
    let padZero = function (str) {
      let len = 2;
      var zeros = new Array(len).join('0');
      return (zeros + str).slice(-len);
    }

    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }

    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }

    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);

    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b) + ((opacity < 100) ? opacity : '');
  }

  getMinAreaAnno(MeshArr) {
    let minArea, minAreaAnnoIndex;
    for (let x = 0; x < MeshArr.length; x++) {
      let currMeshArea = MeshArr[x].object.actualSize.width * MeshArr[x].object.actualSize.height;
      if (!minArea || currMeshArea < minArea) {
        minArea = currMeshArea;
        minAreaAnnoIndex = x;
      }
    }
    return minAreaAnnoIndex;
  }

  getCurrentZoomAnnotationSize(obj) {
    let currScaleLevel = this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom());
    var objSize = {
      width: obj.actualSize.width * currScaleLevel,
      height: obj.actualSize.height * currScaleLevel
    };
    return objSize;
  }

  imgToViewportCoOrds(pos) {
    let osdContentSize = this.osdObj.world._contentSize;
    let coOrdX = pos.x + (osdContentSize.x / 2);
    let coOrdY = Math.abs(pos.y - (osdContentSize.y / 2));

    let viewportCoOrds = this.osdObj.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(coOrdX, coOrdY));
    // let viewportCoOrds = this.osdObj.viewport.pixelFromPoint(this.osdObj.viewport.imageToViewportCoordinates(coOrdX, coOrdY));
    return viewportCoOrds;
  }

  setCurrentAnno(value) {
    this.currentAnnoSource.next(value);
  }
}
