import { CommonService } from '../../common.service';
import { GalleryService } from './../gallery.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.css']
})
export class GalleryDialogComponent implements OnInit {
  onClose = new EventEmitter();
  mapForm: FormGroup;
  uploadForm: FormGroup;
  studyListArr: any;
  cassetteArr: any;
  animalArr: any;
  stainArr: any;
  selectedSlides: any;
  enableUploadButton: boolean = false;
  selectedFileList: any;
  galleryFilter: any;
  discardImageComment:any='';
  unMapImageComment:any='';

  constructor(private formbuilder: FormBuilder, @Optional() private dialogRef: MatDialogRef<GalleryDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService, private galleryService: GalleryService,
    private commonService: CommonService) {
    dialogRef.disableClose = true;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
  ngOnInit() {
    this.mapForm = this.formbuilder.group({
      studyGuId: ['', Validators.required],
      cassetteId: ['', Validators.required],
      animalId: ['', Validators.required],
      stainId: ['', Validators.required],
      comment: ['']
    });

    this.uploadForm = this.formbuilder.group({
      studyGuId: ['']
    });
    this.galleryService.studyListST.subscribe((response: any) => {
      this.studyListArr = response;
      this.galleryFilter = JSON.parse(this.commonService.getGalleryFilters());
      if(this.galleryFilter.studyName){
        this.mapForm.controls['studyGuId'].setValue(this.galleryFilter.studyName);
        if(this.data.dialogParam != 'unMapImage' && this.data.dialogParam != 'discardImage') {
          this.studySelectionChanged({value:this.galleryFilter.studyName});
        }
      }
    });
  }
  get mapF() {
    return this.mapForm.controls;
  }
  get uploadF() {
    return this.uploadForm.controls;
  }
  studySelectionChanged(e) {
    this.galleryService.getCassetteData(e.value).subscribe((res: any) => {
      this.cassetteArr = res.data;
      this.mapForm.controls['cassetteId'].setValue('');
      this.mapForm.controls['stainId'].setValue('');
      this.mapForm.controls['animalId'].setValue('');
    });
  }
  uploadStudySelectionChanged(e) {
    /* this.galleryService.getCassetteData(e.value).subscribe((res: any) => {
      this.cassetteArr = res.data;
      this.uploadForm.controls['cassetteId'].setValue('');
    }); */
  }
  cassetteSelectionChanged(e) {
    let cassettesData = this.cassetteArr.filter(element => {
      return element.id == e.value;
    });
    this.stainArr = cassettesData[0].casseteStains;
    this.mapForm.controls['stainId'].setValue('');
    this.mapForm.controls['animalId'].setValue('');
  }

  stainSelectionChanged(e) {
    this.galleryService.getUnmappedAnimalData(this.mapForm.controls.studyGuId.value, e.value).subscribe((res: any) => {
      this.animalArr = res.data;
      this.mapForm.controls['animalId'].setValue('');
    });
  }
  closeDialog() {
    /* if (this.data.dialogParam == 'uploadImage') {
      this.commonService.uploadImages = [];
    } */
    this.dialogRef.close();
  }
  mapImageData() {
    this.onClose.emit(this.mapForm.value);
    this.dialogRef.close();
  }
  disacardImageData() {
    this.onClose.emit(this.discardImageComment);
    this.discardImageComment = ''
    this.dialogRef.close();
  }
  unMapImageData() {
    this.onClose.emit(this.unMapImageComment);
    this.unMapImageComment = '';
    this.dialogRef.close();
  }

  onFileSelect(e) {
    let filesList = e.target.files;
    if (filesList.length > 0) {
      this.enableUploadButton = true;
      for (let i = 0; i < filesList.length; i++) {
        filesList[i].status = 'Queued'
      }
      this.selectedFileList = filesList;
    }
  }

  uploadImageData() {
    this.enableUploadButton = false;
    this.dialogRef.close();
    this.commonService.uploadImage(this.selectedFileList, false);
  }

}
