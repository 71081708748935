import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from './../app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private config: any;
  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly SESSION_ID = 'SESSION_ID';
  private loggedUser: string;

  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router, private configService: AppConfigService, private dialog: MatDialog,) {
    this.config = configService.config;
  }

  login(user: { email: string, password: string }): Observable<string> {
    return this.http.post<any>(`${this.config.apiUrl}user/login`, user/*dataObj*/)
      .pipe(
        tap(tokens => {
          this.storeTokens(tokens);
          this.storeLoginData(tokens);
        }),
        mapTo("true"),
        catchError(error => {
          if ((error.error.status == 409) && (error.error.error == "Conflict")) {
            return of('resetPassword');
          } else {
            return of(error.error.status == 401 ? "invalid" : "valid");
          }
        }));
  }

  logout() {
    let logoutData = {
      "refreshToken": this.getRefreshToken(),
      "sessionId": this.getSessionId()
    }
    return this.http.post<any>(`${this.config.apiUrl}user/logout`, logoutData).pipe(
      tap(() => {
        this.doLogoutUser()
      }),
      mapTo(true),
      catchError(error => {
        this.doLogoutUser()
        //alert(error.error);
        return of(false);
      }));
  }

  logoutAPICall() {
    let logoutData = {
      "refreshToken": this.getRefreshToken(),
      "sessionId": this.getSessionId()
    }
    return this.http.post<any>(`${this.config.apiUrl}user/logout`, logoutData)
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  refreshToken() {
    this.storeAccessToken('');
    const requestData = {
      'refreshToken': this.getRefreshToken(),
      'sessionId': this.getSessionId()
    }
    return this.http.post(`${this.config.apiUrl}user/token/refresh`, requestData)
      .pipe(tap((tokens: any) => {
        this.storeTokens(tokens);
      }));
  }

  getAccessToken() {
    return this.cookieService.get(this.ACCESS_TOKEN);
  }

  getSessionId() {
    return this.cookieService.get(this.SESSION_ID);
  }

  doLogoutUser() {
    this.removeTokens();
    this.clearLocalStorage();
    this.closeAllOpenDialog();
  }

  closeAllOpenDialog() {
    if (this.dialog.openDialogs.length > 0) {
      this.dialog.closeAll();
      return false;
    }
    return true;
  }

  getRefreshToken() {
    return this.cookieService.get(this.REFRESH_TOKEN);
  }

  private storeAccessToken(access: string) {
    this.cookieService.set(this.ACCESS_TOKEN, access, 0, '/');
  }

  private storeSessionId(sessionId: string) {
    this.cookieService.set(this.SESSION_ID, sessionId, 0, '/');
  }

  private storeTokens(tokens: any) {
    this.cookieService.set(this.ACCESS_TOKEN, tokens./*access_token*/data.accessToken, 0, '/');
    this.cookieService.set(this.REFRESH_TOKEN, tokens./*refresh_token*/data.refreshToken, 0, '/');
    this.cookieService.set(this.SESSION_ID, tokens./*refresh_token*/data.sessionId, 0, '/');
  }

  private storeLoginData(resp: any) {
    localStorage.isfirstLogin = resp.data.isFirstTimeLogin ? resp.data.isFirstTimeLogin : false;
    localStorage.isSessionReplaced = resp.data.sessionReplaced ? resp.data.sessionReplaced : false;
    localStorage.chooseSession = resp.data.chooseSession ? resp.data.chooseSession : false;
  }

  removeTokens() {
    this.cookieService.delete(this.ACCESS_TOKEN, '/');
    this.cookieService.delete(this.REFRESH_TOKEN, '/');
    this.cookieService.delete(this.SESSION_ID, '/');
  }
  clearLocalStorage() {
    localStorage.clear();
  }

  activateSession() {
    return this.http.post(`${this.config.apiUrl}user/session/activate`, { sessionId: this.getSessionId() });
  }
}
