import { Observation } from './observation.model';
import { propArray, prop } from '@rxweb/reactive-form-validators';

export class SlideReview {
    private _summary: String;
    private _comments: String;
    private _userFk: String;
    private _isPeerReviewer: boolean;
    private _organ: String;
    private _intensity: String
    private _frequency: String
    private _roleFk: String;
    private _roi: Number;
    private _activeUser:boolean;

    @propArray(Observation)
    observations: Array<Observation>;

    @prop()
    set summary(value: String) {
        this._summary = value
    }

    get summary() {
        return this._summary;
    }

    @prop()
    set comments(value: String) {
        this._comments = value;
    }

    get comments() {
        return this._comments;
    }

    @prop()
    set userFk(value: String) {
        this._userFk = value;
    }

    get userFk() {
        return this._userFk;
    }

    @prop()
    set isPeerReviewer(value: boolean) {
        this._isPeerReviewer = value;
    }

    get isPeerReviewer() {
        return this._isPeerReviewer;
    }

    @prop()
    set organ(value:String) {
        this._organ = value;
    }

    get organ() {
        return this._organ;
    }

    @prop()
    set intensity(value:String) {
        this._intensity = value;
    }

    get intensity() {
        return this._intensity;
    }

    @prop()
    set frequency(value:String) {
        this._frequency = value;
    }

    get frequency() {
        return this._frequency;
    }

    @prop()
    set roleFk(value: String) {
        this._roleFk = value;
    }

    get roleFk() {
        return this._roleFk;
    }

    @prop()
    set roi(value: Number) {
        this._roi = value;
    }

    get roi() {
        return this._roi;
    }

    @prop()
    set activeUser(value:boolean){
        this._activeUser =value;
    }
    get activeUser(){
        return this._activeUser;
    }

   
}