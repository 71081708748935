import { AppConfigService } from './../app-config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 
@Injectable({
  providedIn: 'root'
})
export class StudyService {

  private config: any;
  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  /*Study Listing APIs*/
  getMyStudies() {
    return this.http.post(`${this.config.apiUrl}study/list?study=myStudy`, 'PATHOLOGIST');
  }
 
  getPeerStudies() {
    return this.http.post(`${this.config.apiUrl}study/list?study=peerStudy`, 'PATHOLOGIST');
  }
 
  getCreatedStudies() {
    return this.http.post(`${this.config.apiUrl}study/list?status=0`, 'STUDY_DIRECTOR');
  }
 
  getInProgressStudies() {
    return this.http.post(`${this.config.apiUrl}study/list?status=5`, 'STUDY_DIRECTOR');
  }
 
  getCompletedStudies() {
    return this.http.post(`${this.config.apiUrl}study/list?status=10`, 'STUDY_DIRECTOR');
  }
 
  getArchievedStudies() {
    return this.http.post(`${this.config.apiUrl}study/list?status=25`, 'STUDY_DIRECTOR');
  }

  getStudylisting(studyStatus:any, role:any){
    return this.http.post(`${this.config.apiUrl}study/list?status=${studyStatus}`, role);
  }
 
  getTechnicianStudies() {
    return this.http.post(`${this.config.apiUrl}study/list`, 'TECHNICIAN');
  }
 
  getAllSpecies() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/species`);
  }
 
  getPathologistStudyCount() {
    return this.http.get(`${this.config.apiUrl}study/count?director=false&pathologist=true`);
  }

  getStudyDirectorStudies(){
    return this.http.get(`${this.config.apiUrl}study/count?director=false&pathologist=true`);
  }
 
  getDirectorStudyCount() {
    return this.http.get(`${this.config.apiUrl}study/count?director=true&pathologist=false`);
  }

  deleteStudy(studyGuid) {
    return this.http.delete(`${this.config.apiUrl}study/${studyGuid}`);
  }
  /*Study Listing APIs End*/
 
  getConfiguration() {
    return this.http.get(`${this.config.apiUrl}study/field/configuration/latest`)
  }

  getAllPathologist() {
    return this.http.get(`${this.config.apiUrl}user/roles/PATHOLOGIST/users`);
  }
  getAllStudyDirector(){
    return this.http.get(`${this.config.apiUrl}user/roles/STUDY_DIRECTOR/users`);
  }

  getAllTechnician() {
    return this.http.get(`${this.config.apiUrl}user/roles/TECHNICIAN/users`);
  }
 
  getAllStudyDomains() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/study-domain`);
  }
 
  getAllStudyTypes() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/study-type`);
  }
 
  getAllTreatments() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/treatment`);
  }
 
  getAllRoutes() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/route`);
  }
 
  getAllStains() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/stain`);
  }
 
  getAllOrgans() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/organ`);
  }
 
  getAllDosageOptions() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/dosage`);
  }
 
  createStudy(studyData: any) {
    return this.http.post(`${this.config.apiUrl}study/create`, studyData);
  }
 
  addCassettes(studyId: String, cassettes: any) {
    return this.http.post(`${this.config.apiUrl}study/${studyId}/cassettes`, cassettes);
  }
 
  addDosages(studyId: String, dosages: any) {
    return this.http.post(`${this.config.apiUrl}study/${studyId}/dosages`, dosages)
  }

  getStudyDetails(studyGuid: any) {
    return this.http.get(`${this.config.apiUrl}study/${studyGuid}`);
    //return this.http.get(`http://172.28.43.142:9070/airadhi/study/${studyGuid}`);
  }

  getStudySlideDetails(studyGuid: any) {
    return this.http.get(`${this.config.apiUrl}study/${studyGuid}/allSlideDetails`);
    //return this.http.get(`${this.config.apiUrl}study/${studyGuid}/slideDetails?page=0&size=200`);
  }
  getStudySlideDetailsPagination(studyGuid: any) {
    return this.http.get(`${this.config.apiUrl}study/${studyGuid}/slideDetails`);
  }
  getIndividualSlideDetails(studyGuid: any, slideId: any) {
    return this.http.get(`${this.config.apiUrl}study/${studyGuid}/individualSlideDetails?slideId=${slideId}`);
  }
 
  editStudy(studyId: any, studyData: any) {
    return this.http.put(`${this.config.apiUrl}study/${studyId}`, studyData);
  }
 
  editCassettes(studyId: any, cassettesData: any) {
    return this.http.put(`${this.config.apiUrl}study/${studyId}/cassettes`, cassettesData);
  }
 
  editDosages(studyId: any, dosageData: any) {
    return this.http.put(`${this.config.apiUrl}study/${studyId}/dosages`, dosageData);
  }

  getAllStructureMappingTemaplates(isTCRStudy) {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/template/all?tcrTemplate=${isTCRStudy}`);
  }

  getTemplateStructure(structureId) {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/template?templateId=${structureId}`);
  }

  autoCreateStudy(studyArr, isTcrStudy) {
    if (isTcrStudy) {
      return this.http.post(`${this.config.apiUrl}study/upload?isTcrStudy=true`, studyArr);
    } else {
      return this.http.post(`${this.config.apiUrl}study/upload`, studyArr);
    }
    //return this.http.post(`http://172.28.43.142:9070/airadhi/study/upload`,studyArr);
  }
  uploadStudySlideCreateData(studyGuid, slideArr) {
    return this.http.post(`${this.config.apiUrl}slide-image/upload-create-image?studyGuid=${studyGuid}`, slideArr);
    // return this.http.post(`http://172.28.43.142:9070/airadhi/gallery/mapping/upload-image`, slideArr);
  }

  uploadStudySlideData(slideArr) {
    return this.http.post(`${this.config.apiUrl}study/slide-image/upload-image`, slideArr);
    // return this.http.post(`${this.config.apiUrl}gallery/mapping/map`, slideArr);
    // return this.http.post(`http://172.28.43.142:9070/airadhi/gallery/mapping/upload-image`, slideArr);
  }
 
  addLocatioMapping(mapData) {
    return this.http.post(`${this.config.apiUrl}slide-image/mapStudySlides`, mapData);
  }
 
  getFolderLocationMappingReport(studyGuid, groupby) {
    return this.http.get(`${this.config.apiUrl}study/slide-image?studyGuid=${studyGuid}&slideMappedGrouping=${groupby}`);
  }
 
  getStudyThumbnailGenerationStatus(studyArr) {
    return this.http.post(`${this.config.apiUrl}study/slide-image/getThumbnailGenStatus`, studyArr);
  }
 
  getStudyMappedLocation(studyGuid) {
    return this.http.get(`${this.config.apiUrl}study/slide-image/${studyGuid}/getFolderLocationsForStudy`);
  }
 
  checkIfStudyNoExists(studyNo){
    return this.http.get(`${this.config.apiUrl}study/isStudyNumberExist?studyNumber=${studyNo}`);
  }
 
  getInActiveStudyList() {
    return this.http.get(`${this.config.apiUrl}study/getInactiveUserStudyListing`);
  }
  getStudyDirectorsStudy(){
    return this.http.get(`${this.config.apiUrl}study/inactiveStudyDirectorStudies`);
  }
 
  getAdditionalPathologist() {
    return this.http.post(`${this.config.apiUrl}study/list?study=additionalPathologist`, 'PATHOLOGIST');
  }
 
  reassignUser(userObj:any){
    return this.http.post(`${this.config.apiUrl}study/reassign`, userObj);
  }

  changeStudyStatus(studyGuid:any, status:any){
    return this.http.put(`${this.config.apiUrl}study/${studyGuid}/updateStatus?status=${status}`, '');
  }
}
