import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AnnotationService } from './../annotation.service';
import { ImageViewerService } from './../image-viewer.service';
import { CommonService } from './../../common.service';
import { TranslateService } from '@ngx-translate/core';
import { DigitalSignatureService } from 'src/app/digital-signature/digital-signature.service';
declare const $: any;

@Component({
  selector: 'app-anno-comment-popup',
  templateUrl: './anno-comment-popup.component.html',
  styleUrls: ['./anno-comment-popup.component.css']
})
export class AnnoCommentPopupComponent implements OnInit {
  @Input() osdObj: any;
  @Input() threeJsObj: any;
  newAnnoSaveInfo: any = {};
  newCreateAnnoInfo: any = {};
  currentUser: any = {};
  selectedAnnoInfoObj: any = { info: { 'comment': '', 'color': '', 'changedColor': '', 'bookmarked': false } };
  isAnnoEditCommentBoxOpened: boolean = false;
  screenshotSetting: any = {
    'iswithAnnotation': false,
    'iswithScalebar': true,
    'fileName': null,
    'selectedResolutionValue': 100,
    'selectedDPIValue': 100,
    'defaultResolutionVal': '100',
    'defaultDPIVal': '100',
  }

  savedDsComment: any;
  newAnnoDsComment: any = '';
  currentSlideIdData: any = [];
  dsCongifData: any = [];
  showCreateDs: any;
  showEditDs: any;
  showDeleteDs: any;
  area:any;
  perimeter
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    setTimeout(() => {
      if (this.isAnnoEditCommentBoxOpened) {
        this.closeEditAnnotation();
      }
    }, 1000);
  }
  constructor(
    private dialog: MatDialog,
    private annotationService: AnnotationService,
    private viewerService: ImageViewerService,
    private commonService: CommonService,
    public translate: TranslateService,
    private digitalService: DigitalSignatureService
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    
    this.newCreateAnnoInfo = this.annotationService.newCreateAnnoInfo;
    this.newAnnoSaveInfo = this.annotationService.newAnnoSaveInfo;
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.annotationService.selectedAnnoInfo.subscribe((val) => {
      if (!$.isEmptyObject(val)) {
        this.selectedAnnoInfoObj = val;
        this.currentSlideIdData = JSON.parse(localStorage.getItem('slideIdDsData'));
        this.currentSlideIdData = this.currentSlideIdData?.filter((e) => e.slideId == this.selectedAnnoInfoObj?.slideId);
        if (this.currentSlideIdData != undefined && this.currentSlideIdData.length > 0) {
          this.savedDsComment = this.currentSlideIdData[0]?.dsComments;
          // console.log(this.currentSlideIdData)
        }
      }
    });

    this.viewerService.annoActions.subscribe((resObj: any) => {
      if (resObj.action == "delete") {
        this.selectedAnnoInfoObj = resObj.annoObj;
        let selectedAnnoId = this.selectedAnnoInfoObj.id;
        this.annotationService.deleteAnnoSuccess(selectedAnnoId);
      } else if (resObj.action == "deleteAll") {
        this.deleteAllAnnoSuccess();
      } else if (resObj.action == "show" || resObj.action == "hide") {
        this.showHideAnnotations(resObj.annoObj, resObj.action == "show" ? true : false);
      }
    });

    this.digitalService
      .getActionsByRole('All')
      .subscribe((data: any) => {
        this.dsCongifData = data;
        this.showCreateDs = this.dsCongifData.filter((e) => e.topic == 'Annotation' && e.actions == 'Create' && e.dvenabled);
        this.showEditDs = this.dsCongifData.filter((e) => e.topic == 'Annotation' && e.actions == 'Edit' && e.dvenabled);
        this.showDeleteDs = this.dsCongifData.filter((e) => e.topic == 'Annotation' && e.actions == 'Delete' && e.dvenabled);
      })
  }

  toggleAnnoEditDiv(ev, toggleVal: boolean = false) {
    if (toggleVal) {
      $(ev.currentTarget).closest(".annoToolTip").hide();
      $(ev.currentTarget).closest(".annotationPopOver").find(".annoEditDiv").removeClass("dn");

      $(ev.currentTarget).closest(".annotationPopOver").find(".annoEditDiv .editAnnotationIcons").find(".annoInfo").hide();
      $(ev.currentTarget).closest(".annotationPopOver").find(".annoEditDiv .editAnnotationIcons").find(".editAnnotation, .deleteAnnotation").show();

      let annoCommentTextElem = $(ev.currentTarget).closest(".annotationPopOver").find(".annoEditDiv").find("#annoCommentText");
      $(annoCommentTextElem).css("height", "auto");
      $(annoCommentTextElem).css("height", $(annoCommentTextElem).prop('scrollHeight') + 'px');
      $(".annotationPopOver").closest(".annoPopOverDiv").css({
        'width': ($(".annotationPopOver").width() + 3),
        'height': $(".annotationPopOver").height()
      });

      if (this.selectedAnnoInfoObj.bookmarkFlag) {
        $(".annotationPopOver").find(".annoEditDiv .iconActions").find(".enableBookmarkToolTip").show();
      } else {
        $(".annotationPopOver").find(".annoEditDiv .iconActions").find(".enableBookmarkToolTip").hide();
      }
      $(ev.currentTarget).closest(".annotationPopOver").find(".annoEditDiv #annoColor").prop('disabled', true);
      this.resetAnnoPopupPos();
    } else {
      $(ev.currentTarget).closest(".annotationPopOver").find(".annoEditDiv").addClass("dn");
      $(ev.currentTarget).closest(".annotationPopOver").find(".annoToolTip").show();
      $(".annotationPopOver").closest(".annoPopOverDiv").css({
        'width': '20vw',
        'height': ($(".annoTooltipIcons").height() + this.commonService.vwTOpx('0.125vw'))
      });

    }
    $(ev.currentTarget).closest(".annotationPopOver").find(".annoCommentDiv #annoCommentText").prop('readonly', true);
    $(ev.currentTarget).closest(".annotationPopOver").find(".dsCommentDiv #dsCommentTextEdit").prop('readonly', true);
  }

  resetAnnoPopupPos() {
    let childPos = $(".annotationPopOver").offset();
    let parentPos = $(".annotationPopOver").closest(".viewercontent").offset();
    let annoPopupPos = {
      top: childPos.top - (parentPos ? parentPos.top : 0),
      left: childPos.left - (parentPos ? parentPos.left : 0)
    }
    let posX = ((annoPopupPos.left + $(".annotationPopOver").width()) > $(".annotationPopOver").closest(".viewercontent").width()) ? ($(".annotationPopOver").closest(".viewercontent").width() - $(".annotationPopOver").width()) : annoPopupPos.left;
    let posY = ((annoPopupPos.top + $(".annotationPopOver").height()) > $(".annotationPopOver").closest(".viewercontent").height()) ? ($(".annotationPopOver").closest(".viewercontent").height() - $(".annotationPopOver").height()) : annoPopupPos.top;
    $(".annotationPopOver").css({ 'left': (posX) + 'px', 'top': posY + 'px' })

  }

  editAnnotationInfo(ev, annoInfoObj) {
    this.toggleAnnoEditDiv(ev, true);
    let editAnnoDiv = $(ev.currentTarget).closest(".annotationPopOver").find(".annoEditDiv");
    $(editAnnoDiv).find(".iconActions").find(".enableBookmarkToolTip").hide();
    $(editAnnoDiv).find(".iconActions").find(".editAnnotation, .deleteAnnotation, .areaPerimeterIcon, .enableBookmarkToolTip, .annoDetails").hide();
    $(editAnnoDiv).find(".iconActions").find(".enableBookmark, .saveAnnotation, .bookMarkIcon, .closeEditAnnotation").show();
    if (!annoInfoObj.areaPerimeterFlag) {
      $(editAnnoDiv).find(".editAnnotationIcons").find(".areaPerimeterIcon.hideAreaPerimeter").show();
    } else {
      $(editAnnoDiv).find(".editAnnotationIcons").find(".areaPerimeterIcon.showAreaPerimeter").show();
    }
    $(editAnnoDiv).find(".annoCommentDiv #annoCommentText").prop('readonly', false);
    $(editAnnoDiv).find(".dsCommentDiv #dsCommentTextEdit").prop('readonly', false);
    $(editAnnoDiv).find("#annoColor").prop('disabled', false);

    this.selectedAnnoInfoObj.info.changedColor = this.selectedAnnoInfoObj.info.color;
    this.showAreaPerimeter(annoInfoObj.areaPerimeterFlag);
    if (annoInfoObj.bookmarkFlag) {
      $(".annotationPopOver .bookMarkIcon").addClass("enableBookmark");
    } else {
      $(".annotationPopOver .bookMarkIcon").removeClass("enableBookmark");
    }
    this.osdObj.setMouseNavEnabled(false);
    this.isAnnoEditCommentBoxOpened = true;
    $('.disabledJoystickrotationNavigator,.disableTopBar, .disableRightBar').show();
  }

  showAreaPerimeter(action: boolean = false) {
    $(".areaPerimeterIcon").toggle();
    if (action) {
      $(".annoAreaParameterDiv").show();
    } else {
      $(".annoAreaParameterDiv").hide();
    }
    $(".annotationPopOver").height('auto');
    $(".annotationPopOver").closest(".annoPopOverDiv").css({
      'width': ($(".annotationPopOver").width()),
      'height': $(".annotationPopOver").height()
    });
  }

  toggleBookMarkIcon(ev) {
    if (!$(ev.currentTarget).hasClass("enableBookmark")) {
      $(".annotationPopOver .bookMarkIcon").addClass("enableBookmark");
    } else {
      $(".annotationPopOver .bookMarkIcon").removeClass("enableBookmark");
    }
  }

  deleteAnnotationData(ev, annoObj) {
    let selectedAnnoId = this.selectedAnnoInfoObj.id;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Delete Annotation",
      confirmMsg: this.translate.instant("GENERIC_MSG.CONSULTATION.DELETE_SINGLE_ANNOTATION"),
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    };

    let selectedSlideData = {
      slideId: this.selectedAnnoInfoObj.slideId,
      isDeleteEnable: this.showDeleteDs
    }

    localStorage.setItem('selectedSlideData', JSON.stringify(selectedSlideData));

    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe((result) => {
      if (result && result != 'btnClose') {
        this.annotationService.isPerformingTask = true;
        let deleteAnnotationObservable: any;
        if (annoObj.meetId) {
          deleteAnnotationObservable = this.viewerService.deleteDCAnnotation(annoObj, annoObj.id);
        } else {
          deleteAnnotationObservable = this.viewerService.deleteAnnotation(annoObj, annoObj.id);
        }
        deleteAnnotationObservable.subscribe((() => {
          if (annoObj.meetId) {
            annoObj.dcAction = 'delete';
            this.annotationService.setCurrentAnno(annoObj);
          }
          // this.annotationService.deleteAnnoSuccess(annoObj.annotationId);
          this.annotationService.deleteAnnoSuccess(annoObj.id);

          let deleteDsComment = localStorage.getItem('deleteDsComment')
          if ((this.currentSlideIdData != undefined) && (this.currentSlideIdData.length > 0) && (deleteDsComment != null)) {
            let changedData = {
              'dsComments': deleteDsComment,
              'dsToken': this.currentSlideIdData[0].dsToken,
              'slideId': this.currentSlideIdData[0].slideId
            }
            this.currentSlideIdData = [];
            this.currentSlideIdData = JSON.parse(localStorage.getItem('slideIdDsData'));
            const index = this.currentSlideIdData?.findIndex((e) => e.slideId == this.selectedAnnoInfoObj?.slideId);
            if (index != -1) {
              this.currentSlideIdData?.splice(index, 1);
              localStorage.removeItem('slideIdDsData');
            }
            this.currentSlideIdData.push(changedData);
            localStorage.setItem('slideIdDsData', JSON.stringify(this.currentSlideIdData));
            // console.log(this.currentSlideIdData)
            localStorage.removeItem('deleteDsComment');
            this.closeEditAnnotation();
          }
          this.closeEditAnnotation();
        }).bind(this));
      }
    });
  }

  saveAnnotationInfo(ev, annoObj) {
    console.warn(this.savedDsComment)
    if (this.currentSlideIdData != undefined && this.currentSlideIdData.length > 0) {
      let changedData = {
        'dsComments': this.savedDsComment,
        'dsToken': this.currentSlideIdData[0].dsToken,
        'slideId': this.currentSlideIdData[0].slideId
      }
      this.currentSlideIdData = [];
      this.currentSlideIdData = JSON.parse(localStorage.getItem('slideIdDsData'));
      const index = this.currentSlideIdData?.findIndex((e) => e.slideId == this.selectedAnnoInfoObj?.slideId);
      if (index != -1) {
        this.currentSlideIdData?.splice(index, 1);
        localStorage.removeItem('slideIdDsData');
      }
      this.currentSlideIdData.push(changedData);
      localStorage.setItem('slideIdDsData', JSON.stringify(this.currentSlideIdData));
      // console.log(this.currentSlideIdData)
    }
    // this.selectedAnnoInfoObj.comment = $(ev.currentTarget).closest(".annoEditDiv").find("#annoCommentText").val();
    this.selectedAnnoInfoObj.info.comment = $(ev.currentTarget).closest(".annoEditDiv").find("#annoCommentText").val();
    // this.selectedAnnoInfoObj.colour = $(ev.currentTarget).closest(".annoEditDiv").find("#annoColor").val();
    this.selectedAnnoInfoObj.info.bookmarked = $(ev.currentTarget).closest(".editAnnotationIcons").find(".bookMarkIcon").hasClass("enableBookmark");
    // this.selectedAnnoInfoObj.areaPerimeterFlag = $(ev.currentTarget).closest(".editAnnotationIcons").find(".areaPerimeterIcon:visible").data('visible');
    let editAnnoationObservable: any;
    let annoInfoObj = { ...this.selectedAnnoInfoObj };
    annoInfoObj.info.color = this.selectedAnnoInfoObj.info.changedColor;
    if (this.selectedAnnoInfoObj.meetId) {
      editAnnoationObservable = this.viewerService.editDCAnnotation(annoInfoObj.id, annoInfoObj);
    } else {
      editAnnoationObservable = this.viewerService.editAnnotation(annoInfoObj);
    }
    editAnnoationObservable.subscribe(() => {
      this.selectedAnnoInfoObj.colour = this.selectedAnnoInfoObj.info.changedColor;
      if (this.selectedAnnoInfoObj.meetId) {
        this.selectedAnnoInfoObj.dcAction = 'edit';
        this.annotationService.setCurrentAnno(this.selectedAnnoInfoObj);
      }
      this.annotationService.saveAnnoSuccess(this.selectedAnnoInfoObj);
      // localStorage.setItem('slideIdDsData', JSON.stringify(this.currentSlideIdData));
      this.closeEditAnnotation();
    });

  }

  closeEditAnnotation() {
    this.selectedAnnoInfoObj.info.changedColor = '';
    $(".annotationPopOver").find(".annoEditDiv .iconActions").find(".enableBookmark, .saveAnnotation, .bookMarkIcon, .areaPerimeterIcon, .closeEditAnnotation").hide();
    $(".annotationPopOver").find(".annoEditDiv .iconActions").find(".editAnnotation, .deleteAnnotation, .annoDetails").show();
    $(".annotationPopOver").find(".annoEditDiv").show();
    $(".annotationPopOver").find(".annoEditDiv #annoColor").prop('disabled', true);
    this.osdObj.setMouseNavEnabled(true);
    this.isAnnoEditCommentBoxOpened = false;
    $(".annotationPopOver").hide();
    $('.disabledJoystickrotationNavigator,.disableTopBar, .disableRightBar').hide();


  }

  editAnnoCPChange(ev) {
    // this.selectedAnnoInfoObj.colour = ev;
    this.selectedAnnoInfoObj.info.changedColor = ev;
  }

  toggleAnnoAreaPerimeter(ev) {
    if ($(ev.currentTarget).hasClass("fa-eye-slash")) {
      $(ev.currentTarget).removeClass("fa-eye-slash").addClass("fa-eye");
      this.annotationService.newCreateAnnoInfo.drawingAnnoAreaPerimeter = false;
      $(ev.currentTarget).closest(".createAnnoPopup").find(".createAnnoAreaParameterDiv").hide();
    } else {
      $(ev.currentTarget).removeClass("fa-eye").addClass("fa-eye-slash");
      this.annotationService.newCreateAnnoInfo.drawingAnnoAreaPerimeter = true;
      $(ev.currentTarget).closest(".createAnnoPopup").find(".createAnnoAreaParameterDiv").show();
    }
  }

  toggleAnnoBookmark(ev) {
    this.annotationService.newCreateAnnoInfo.drawingAnnoBookmark = !this.annotationService.newCreateAnnoInfo.drawingAnnoBookmark;
    if (this.annotationService.newCreateAnnoInfo.drawingAnnoBookmark) {
      $(ev.currentTarget).addClass("enableBookmark");
    } else {
      $(ev.currentTarget).removeClass("enableBookmark");
    }
  }

  saveNewlyCreatedAnno() {
    if (this.currentSlideIdData != undefined && this.currentSlideIdData.length > 0) {
      let changedData = {
        'dsComments': this.newAnnoDsComment != '' ? this.newAnnoDsComment : this.savedDsComment,
        'dsToken': this.currentSlideIdData[0].dsToken,
        'slideId': this.currentSlideIdData[0].slideId
      }
      this.currentSlideIdData = [];
      this.currentSlideIdData = JSON.parse(localStorage.getItem('slideIdDsData'));
      const index = this.currentSlideIdData?.findIndex((e) => e.slideId == this.selectedAnnoInfoObj?.slideId);
      if (index != -1) {
        this.currentSlideIdData?.splice(index, 1);
        localStorage.removeItem('slideIdDsData');
      }
      this.currentSlideIdData.push(changedData);
      localStorage.setItem('slideIdDsData', JSON.stringify(this.currentSlideIdData));
      // console.log(this.currentSlideIdData)
    }
    let area = this.annotationService.newAnnoSaveInfo?.area?.value;
     let perimeter =this.annotationService.newAnnoSaveInfo?.perimeter?.value;
       if(area && perimeter){
        this.newAnnoSaveInfo.info.geomInfo.area=area;
        this.newAnnoSaveInfo.info.geomInfo.perimeter=perimeter;
       }
    this.annotationService.newAnnoSaveInfo = this.newAnnoSaveInfo;
    this.annotationService.saveNewlyCreatedAnnotation();


  }
  getchangedDsValue(e: any) {
    this.newAnnoDsComment = e.target.value;
  }

  closeAnno() {
    this.annotationService.closeAnnotation(null, this.annotationService.newCreateAnnoInfo.isBatch);
  }

  newCreateAnnoCPChange(ev) {
    this.annotationService.newCreateAnnoInfo.drawingAnnoLineColor = ev;
    for (let i = 0; i < this.annotationService.newCreateAnnoInfo.drawingAnnoNameArr.length; i++) {
      let sceneChild = this.threeJsObj.scene.getObjectByName(this.annotationService.newCreateAnnoInfo.drawingAnnoNameArr[i]);
      if (sceneChild) {
        let isColorChanged = false;
        if (sceneChild.material.color) {
          isColorChanged = true;
          sceneChild.material.color.set(this.annotationService.newCreateAnnoInfo.drawingAnnoLineColor);
        }
        if (sceneChild.material.emissive) {
          isColorChanged = true;
          sceneChild.material.emissive.set(this.annotationService.newCreateAnnoInfo.drawingAnnoLineColor);
        }
        if (isColorChanged) {
          this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
          this.threeJsObj.camera.updateMatrixWorld();
        }
      }
    }
  }


  deleteAllAnnoSuccess() {
    for (var i = this.threeJsObj.scene.children.length - 1; i >= 0; i--) {
      let obj = this.threeJsObj.scene.children[i];
      this.threeJsObj.scene.remove(obj);
      obj.geometry.dispose();
      obj.material.dispose();
    }

    this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
    this.threeJsObj.camera.updateMatrixWorld();

    $("#" + this.osdObj.id + "-annoHighlight").css({ "width": 0, "height": 0, "left": 0, "top": 0 }).hide();
  }

  showHideAnnotations(annoData, showHideFlag) {
    let mesh = this.threeJsObj.scene.getObjectByName(annoData.id + "_Mesh");
    if (mesh) {
      mesh.visible = showHideFlag;
    }
    // }

    let line = this.threeJsObj.scene.getObjectByName(annoData.id + "_Line");
    if (line) {
      line.visible = showHideFlag;
    }

    let initDot = this.threeJsObj.scene.getObjectByName(annoData.id + "_initDot");
    if (initDot) {
      initDot.visible = showHideFlag;
    }

    let midDot = this.threeJsObj.scene.getObjectByName(annoData.id + "_midDot");
    if (midDot) {
      midDot.visible = showHideFlag;
    }

    let endDot = this.threeJsObj.scene.getObjectByName(annoData.id + "_endDot");
    if (endDot) {
      endDot.visible = showHideFlag;
    }

    this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
    this.threeJsObj.camera.updateMatrixWorld();
  }

  ssRadioBtnSelected(event, fieldName) {
    if (event.value == "custom") {
      $(".ss" + fieldName + " .customType").removeClass("dn");
      this.screenshotSetting["selected" + fieldName + "Value"] = 50;
    } else {
      $(".ss" + fieldName + " .customType").addClass("dn");
      this.screenshotSetting["selected" + fieldName + "Value"] = parseInt(event.value);
    }
  }

  saveScreenshot() {
    if (!this.screenshotSetting.fileName) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.ENTER_SCREENSHOT_FILENAME"));
      return;
    }
    if (!this.screenshotSetting.selectedResolutionValue) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.ENTER_SCREENSHOT_CUSTOM_RESOLUTION"));
      return;
    }
    if (!this.screenshotSetting.selectedDPIValue) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.ENTER_SCREENSHOT_CUSTOM_DPI"));
      return;
    }

    this.annotationService.createScreenShot(
      this.screenshotSetting.iswithAnnotation,
      this.screenshotSetting.iswithScalebar,
      (this.screenshotSetting.selectedResolutionValue / 100),
      (this.screenshotSetting.selectedDPIValue / 100),
      this.screenshotSetting.fileName);

    this.closeScreenshotPopup();
  }

  closeScreenshotPopup() {
    $("#screenShotDiv").addClass("dn");
    this.resetScreenShotSetting();
    this.annotationService.closeAnnotation(null, false);
    this.annotationService.resetNewCreateAnnotationInfo({});
  }

  resetScreenShotSetting() {
    this.screenshotSetting = {
      'iswithAnnotation': false,
      'iswithScalebar': true,
      'fileName': null,
      'selectedResolutionValue': 100,
      'selectedDPIValue': 100,
      'defaultResolutionVal': '100',
      'defaultDPIVal': '100',
    }
  }
}
