import { ConsultationComponent } from './consultation.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MeetingListComponent } from './meeting/meeting-list/meeting-list.component';
import { MeetingCreateComponent } from './meeting/meeting-create/meeting-create.component';
import { MeetingDetailsComponent } from './meeting/meeting-details/meeting-details.component';

const routes: Routes = [
  {
    path: '', component: ConsultationComponent, 
    children : [
      {path: '', component: MeetingListComponent},
      {path: 'listing', component: MeetingListComponent},
      {path: 'details/:meetingId', component: MeetingDetailsComponent},
      {path: 'create', component: MeetingCreateComponent},
      {path: 'edit/:meetingId', component: MeetingCreateComponent},
    ]
   },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsultationRoutingModule { }