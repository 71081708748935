import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Config } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  config: Config;

  constructor(private http: HttpBackend) { }

  loadAppConfig() {/* 
    return new Promise<void>((resolve, reject) => {
    return this.http.get('/assets/appConfig/appConfig.json')
      .toPromise()
      .then((data:any) => {
        AppConfigService.appConfig = data;
        resolve();
               
      }).catch((response: any) => {
         reject(`Could not load the config file`);
      });
    }); */

    return new HttpClient(this.http)
          .get<Config>('./assets/appConfig/appConfig.json')
          .toPromise()
          .then(config => {
            this.config = config;
          });
  }
}
