<div class="modal-header dialog-header">
    <div class="headerTitle">
        <div class="dialog-title">{{showData.headerTitle}}</div>
    </div>
    <div class="headerIcon float-right">
        <i class="fa fa-times" (click)="closeDialog()"></i>
    </div>
</div>
<div class="container dialog-container modal-body">
    <p>{{showData.message}}</p>
</div>
<div class="actions dialog-actions modal-footer">
    <button class="btn-default btn-success" [mat-dialog-close]="true" cdkFocusInitial>{{showData.OKButton}}</button>
</div>