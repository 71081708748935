<div class="dialog-header modal-header">
    <div class="headerTitle">
        <h5 class="dialog-title">Setting</h5>
    </div>
    <div class="headerIcon float-right">
        <i class="fa fa-times" (click)="closeDialog()"></i>
    </div>
</div>

<div class="dialog-body">
    <div class="tab">
        <button class="tablinks selected" (click)="openTab($event, '.audio')">Audio</button>
        <button class="tablinks dn" (click)="openTab($event, '.notification')">Notification</button>
        <button class="tablinks dn" (click)="openTab($event, '.security')">Security</button>
    </div>
        
    <div class="audio tabcontent selected">
        <div class="rowDiv">
            <div class="rowTitle">Microphone Setup</div>
            <div class="rowBody">
                <mat-form-field class="full-width gselect bgremove" style="width: 17vw;">
                    <mat-label class="labelbg">Microphone</mat-label>
                    <mat-select (selectionChange)='microphoneChange($event)' [value]="selectedMicrophone">
                        <mat-option *ngFor="let Microphone of mediaDevices.Microphones" [value]="Microphone">
                            {{ Microphone.deviceLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" class="btn-default testaudio dn"><i class="fa fa-microphone" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp; <span class="audiotexthover"> Test</span></button>
            </div>
        </div>
        <div class="rowDiv">
            <div class="rowTitle">Speakers Setup</div>
            <div class="rowBody">
                <mat-form-field class="full-width gselect bgremove" style="width: 17vw;">
                    <mat-label class="labelbg">Speaker</mat-label>
                    <mat-select (selectionChange)='speakerChange($event)' [value]="selectedSpeaker">                
                        <mat-option *ngFor="let Speaker of mediaDevices.Speakers" [value]="Speaker">
                            {{ Speaker.deviceLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" class="btn-default testaudio dn"><i class="fa fa-volume-up" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp; <span class="audiotexthover"> Test</span></button>
            </div>
        </div>
    </div>
        
    <div class="notification tabcontent">
        <h3>Notification</h3>
    </div>
        
    <div class="security tabcontent">
        <h3>Security</h3>
    </div>
</div>
