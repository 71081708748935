<div class="page-content">
  <div class="pageheader">
    <div class="pageheadtitle">
      <h4> Meeting
      </h4>
    </div>
    <div class="float-right">
      <button type="button" class="btn-default searchbtn" (click)="navigateToCreateConsultation()">Create</button>
    </div>
  </div>
  <div class="mdl-grid">
    <div class="col-sm-8 myStudybardiv">
      <div class="mdl-grid myStudybar">
        <div class="col-sm-2" (click)="getMeetingList('created')">
          <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'created'}">Hosted
            (<span>{{createdCount}}</span>)
          </h6>
        </div>
        <div class="col-sm-2 borderbefore" (click)="getMeetingList('newRequest')">
          <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'newRequest'}">Invites
            (<span>{{newRequestCount}}</span>)
          </h6>
        </div>
        <div class="col-sm-2 borderbefore" (click)="getMeetingList('completed')">
          <h6 class="tabliketitle" [ngClass]="{'active': activeTab == 'completed'}">Past
            (<span>{{completedCount}}</span>)
          </h6>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
    <div class="col-sm-4 filterinlineview">
      <div class="input-group" style="height: fit-content; justify-content: right;">
        <div class="input-group-prepend">
          <span class="input-group-text dropdown dropdown-small open" id="inputGroupPrepend">
            <a data-hover="dropdown" class="dropdown-toggle" aria-haspopup="true" aria-expanded="false"
              (click)="showHideFilter()" aria-label="filterdropdown"><span class="key"></span><span class="value">
              </span><b class="caret"></b></a>
          </span>
        </div>
        <ul class="dropdown-menu multiselectfilter">
          <form [formGroup]="searchForm">
            <li>
              <label for="consultationDate">Meeting Date</label>
              <input type="date" name="consultationDate" id="consultationDate" class="form-control"
                formControlName="consultationDate">
            </li>
            <li *ngIf="activeTab == 'newRequest'|| activeTab == 'completed'">
              <label for="host">Host</label>
              <mat-select multiple class="ghead_filter form-control" name="host" id="host" formControlName="host">
                <mat-option *ngFor="let host of participantsList" [value]="host">{{host.displayName}} ({{host.email}})
                </mat-option>
              </mat-select>
            </li>
            <li *ngIf="activeTab == 'created' || activeTab == 'completed'">
              <label for="participant">Participant</label>
              <mat-select multiple class="ghead_filter form-control" name="participant" id="participant"
                formControlName="participant">
                <mat-option *ngFor="let participant of participantsList" [value]="participant">
                  {{participant.displayName}} ({{participant.email}})
                </mat-option>
              </mat-select>
            </li>
            <li>
              <div class="filterbtn padding_tabs padding_tabs">
                <button type="button" (click)="onSearch()" class="btn-default searchbtn"
                  aria-label="searchbtn">Search</button>
                <button type="button" (click)="onCancel()" class="btn-default" aria-label="cancelbtn">Close</button>
                <button type="button" (click)="onReset()" class="btn-default" aria-label="resetbtn">Reset</button>
              </div>
            </li>
          </form>
        </ul>
        <div class="studySearchMainDiv">
          <input type="text" class="form-control tags" id="studysearch" placeholder="Search" />
        </div>
        <!-- <i class="fa fa-search searchicon"></i> -->
      </div>
    </div>
  </div>
  <mat-spinner style="margin:0 auto;" *ngIf="showLoader"></mat-spinner>
  <div *ngIf="noTableData && !showLoader" class="row" id="preview_hidden" style="margin: 9rem auto;width: fit-content;">
    <img src="../../../assets/images/viewer_icon/no_study_project.png" />
  </div>
  <div class="table-responsive">
    <table id="p_study_table" #dataTable class="hover row-border">
    </table>
  </div>
</div>