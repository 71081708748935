import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(public router: Router, private commonService: CommonService) { }

  ngOnInit() {
    if (!this.commonService.getGalleryFilters()) {
      let galleryFilter = {
        "selectedOpt": "study",
        "studyName": "",
        "isTCRStudy": false,
        "viewByG": "5",
        "viewByS": "folderLocation",
        "viewByU": "week",
        "stains": [],
        "organ": [],
        "animalNo": [],
        "control": [],
        "viewType": "list",
        "readReviewFlag": '',
        "quickFilter": "-2",
        "selectedAccordion": ''
      }
      this.commonService.setGalleryFilters(JSON.stringify(galleryFilter));
    }
  }
}
