<div class="dialog-header modal-header">
  <div class="headerTitle">
    <h5 class="dialog-title">Meeting Details</h5>
  </div>
  <div class="headerIcon float-right">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>
<div class="wrap">
  <div class="row meetdet_pop">
    <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
      <label>Title:</label>
    </div>
    <div class="col-md-9 col-sm-9 col-lg-9 col-xl-9">
      <p style="word-break: break-word;">{{meetingData.title}}</p>
    </div>
  </div>
  <div class="row meetdet_pop">
    <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
      <label>Description:</label>
    </div>
    <div class="col-md-9 col-sm-9 col-lg-9 col-xl-9">
      <p style="word-break: break-word;">{{meetingData.description}}</p>
    </div>
  </div>

  <div class="row meetdet_pop">
    <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
      <label>Date:</label>
    </div>
    <div class="col-md-9 col-sm-9 col-lg-9 col-xl-9">
      <p>{{meetingData.startTime  | date :  "longDate" }}</p>
    </div>
  </div>
  <div class="row meetdet_pop">
    <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
      <label>Time:</label>
    </div>
    <div class="col-md-9 col-sm-9 col-lg-9 col-xl-9">
      <p>{{meetingData.startTime  | date :  "shortTime" }}- {{meetingData.endTime  | date :  "shortTime" }}  ({{meetingData.duration}})</p>
    </div>
  </div>
  <!-- <div class="row meetdet_pop">
    <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
      <label>Timezone:</label>
    </div>
    <div class="col-md-9 col-sm-9 col-lg-9 col-xl-9">
      <p></p>
    </div>
  </div> -->
  <div class="row meetdet_pop">
    <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
      <label>Participants:</label>
    </div>
    <div class="col-md-9 col-sm-9 col-lg-9 col-xl-9">
      <div *ngFor="let user of meetingData.invitees">{{user.displayName}}({{user.emailId}}) </div>
    </div>
  </div>
  <div class="row meetdet_pop">
    <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
      <label>Images Added:</label>
    </div>
    <div class="col-md-9 col-sm-9 col-lg-9 col-xl-9">
      <p>{{slidearr.length}} Image(s)</p>
    </div>
  </div>
</div>
