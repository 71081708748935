import { ConsultationService } from './../../consultation/consultation.service';
import { AppConfigService } from './../../app-config.service';
import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { CommonService } from '../../common.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gallerygrid',
  templateUrl: './gallerygrid.component.html',
  styleUrls: ['./gallerygrid.component.css']
})
export class GallerygridComponent implements OnInit, OnDestroy {
  gridList
  preview
  galleryGrid: any = [];
  selectgroupSlideKey: String = '';
  selectedGroup: String;
  selectedSlides: any = [];
  selectedImageObjConsultation = {};
  selectedImageObjForThumbnail = {};
  thumbnailUrl: any;
  @Input() activeMode: string;
  @Input() dialogData: any;
  showLoader: boolean;
  openCollapseIndex = -1;
  galleryFilter: any;
  searchData = {};
  studyOrganObj = {};

  destroy$: Subject<boolean> = new Subject<boolean>();

  defaultImagePath = "../../../assets/images/viewer_icon/gallery_noimg.png";

  config: any;

  fetchingNewData: boolean = false;

  constructor(private galleryService: GalleryService, private commonService: CommonService, private router: Router, private eleRef: ElementRef,
    private configService: AppConfigService, private consultationService: ConsultationService) {
    this.config = configService.config;
  }

  ngOnInit() {
    this.galleryService.gridList.pipe(takeUntil(this.destroy$)).subscribe((gridList) => {
      this.gridList = gridList;
    });
    this.galleryService.preview.pipe(takeUntil(this.destroy$)).subscribe((preview) => {
      this.preview = preview;
    });
    this.galleryService.showLoader.pipe(takeUntil(this.destroy$)).subscribe((loader) => {
      this.showLoader = loader;
    });
    this.galleryService.selecteSlidesList.pipe(takeUntil(this.destroy$)).subscribe((slides) => {
      this.selectedSlides = slides;
    });
    this.galleryService.selecteSlidesListForThumbnail.pipe(takeUntil(this.destroy$)).subscribe((slidesObj) => {
      this.selectedImageObjForThumbnail = slidesObj;
    });
    this.galleryService.studyOrganObj.pipe(takeUntil(this.destroy$)).subscribe((organObj) => {
      this.studyOrganObj = organObj;
    });
    if (this.activeMode == 'consultation' || this.activeMode == 'consultation-viewer') {
      this.selectedImageObjConsultation = Object.assign({}, this.consultationService.getSelectedImageValue());
    }
    this.galleryService.galleryList.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.galleryFilter = JSON.parse(localStorage.galleryFilters);
      this.galleryGrid = value;

      if (!$.isEmptyObject(value)) {
        if (this.activeMode != 'scanned') {
          if (this.galleryFilter.selectedAccordion) {
            this.openCollapseIndex = Object.keys(value).indexOf(this.galleryFilter.selectedAccordion);
          } else {
            this.openCollapseIndex = ((this.galleryService.activeAccordian > (Object.keys(value).length - 1)) || this.galleryService.activeAccordian == -1) ? 0 : this.galleryService.activeAccordian;
          }
          // this.openCollapseIndex = ((this.galleryService.activeAccordian > (Object.keys(value).length - 1)) || this.galleryService.activeAccordian == -1) ? 0 : this.galleryService.activeAccordian;
          this.galleryService.selectgroupSlideKey.pipe(takeUntil(this.destroy$)).subscribe((key) => {
            this.selectgroupSlideKey = key;
            setTimeout(() => {
              $(".card-groupactive")[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
          });
          if (!this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]]?.alreadyrender) {
            this.getPageWiseData(Object.keys(this.galleryGrid)[this.openCollapseIndex], 1);
          } else {
            this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].selecteMessage = '';
            if (this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].slidesArr) {
              this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].slidesArr.forEach(element => {
                element.state = false;
                element.isDisabled = false;

                if (!element.hasOwnProperty("imgRotationDegree")) {
                  element.imgFormat = element.slidename.substring(((element.slidename.lastIndexOf(".") >= 0) ? element.slidename.lastIndexOf(".") : 0) + 1, element.slidename.length);
                  element.imgRotationDegree = this.config.thumbnailRotateDegree ? this.config.thumbnailRotateDegree : 0;
                  if (this.config.customImgFormatThumbnailRotateDegree && this.config.customImgFormatThumbnailRotateDegree.hasOwnProperty(element.imgFormat)) {
                    element.imgRotationDegree = this.config.customImgFormatThumbnailRotateDegree[element.imgFormat];
                  }
                }
              });
            }
          }

          setTimeout(() => {
            $(".gridscroll").animate({
              scrollTop: ($("#heading_" + this.openCollapseIndex).offset().top - $(".gridscroll").offset().top)
            }, 200);
          }, 100);
        }
      } else {
        // console.log("no data error handling in galleery list");
      }

      /* if (this.activeMode == 'consultation' || this.activeMode == 'consultation-viewer') {
        for (const key in this.galleryGrid) {
          let arrcount = 0;
          this.galleryGrid[key].slidesArr.forEach(element => {
            if (this.selectedImageObjConsultation[element.slidePk]) {
              element.state = true;
              element.isDisabled = true;
              arrcount++;
            } else {
              element.state = false;
              element.isDisabled = false;
            }
          });
          if (arrcount == this.galleryGrid[key].slidesArr.length) {
            this.galleryGrid[key].isDisabled = true;
          } else {
            this.galleryGrid[key].isDisabled = false;
          }
        }
      } */
    });
    this.galleryService.updateslideData.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        for (const key in this.galleryGrid) {
          this.galleryGrid[key].slidesArr.forEach(element => {
            if (this.selectedSlides.indexOf(element.slidePk) != -1) {
              if (value == 'READ' || value == 'UNREAD') {
                element.readStatus = value;
                element.state = false;
              } else if (value == 'LATER' || value == 'DONE') {
                element.reviewStatus = value;
                element.state = false;
              }
            }
          });
        }
        this.galleryService.setSelecteSlidesList([]);
        this.galleryService.setupdateslideData('');
      }
    });
    this.thumbnailUrl = `${this.config.thumbnailUrl}`;
  }

  setSelectedSlideList(slideD, checkedValue) {
    if (this.activeMode == 'consultation' || this.activeMode == 'consultation-viewer') {
      if (checkedValue) {
        if (!this.selectedImageObjConsultation[slideD.slidePk]) {
          this.selectedImageObjConsultation[slideD.slidePk] = slideD;
        }
      } else {
        delete this.selectedImageObjConsultation[slideD.slidePk];
      }
      this.galleryService.setSelectedAddImage(this.selectedImageObjConsultation);

    } else {
      if (checkedValue) {
        if (!this.selectedSlides.includes(slideD.slidePk)) {
          this.selectedSlides.push(slideD.slidePk);
        }
        if (!this.selectedImageObjForThumbnail[slideD.identifier]) {
          this.selectedImageObjForThumbnail[slideD.identifier] = slideD.physicalLoc;
        }
      } else {
        this.selectedSlides = this.selectedSlides.filter(e => e !== slideD.slidePk);
        delete this.selectedImageObjForThumbnail[slideD.identifier];
      }
      this.galleryService.setSelecteSlidesList(this.selectedSlides);
      this.galleryService.setSelectedSlidesListForThumbnail(this.selectedImageObjForThumbnail);
    }
  }

  setTitleDivWidth() {
    $(".prevTitleDiv").width($(".previewaction_btn").width() - ($(".previewaction_btn .leftBtn").width() + $(".previewaction_btn .rightBtn").width() + this.commonService.vwTOpx('2.2vw') + (2 * $(".previewimgdet .prev").width())));
    // $('body').scrollTo('#target')
    $(".card-groupactive")[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  groupChange(group, slideHeaderText) {
    if (this.galleryService.activeAccordian == group) {
      this.galleryService.activeAccordian = -1;
      this.openCollapseIndex = -1;
    } else {
      this.galleryService.activeAccordian = group;
      this.openCollapseIndex = group;
      if (!this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].alreadyrender) {
        this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].alreadyrender = true;
        this.getPageWiseData(Object.keys(this.galleryGrid)[this.openCollapseIndex], 1)
      }
    }

    this.galleryFilter.selectedAccordion = slideHeaderText;
    this.commonService.setGalleryFilters(JSON.stringify(this.galleryFilter));
  }

  getPageWiseData(searchby, pageNo) {
    this.galleryFilter = JSON.parse(this.commonService.getGalleryFilters());
    /* if (this.galleryFilter.viewByG == 3) {
      searchby = Object.keys(this.studyOrganObj).find(key => this.studyOrganObj[key] === searchby);
    } */
    this.searchData = {
      "mainFilter": [searchby],
      "subfilters": {
        "tissue": this.galleryFilter.organ,
        "cassetteStain": this.galleryFilter.stains,
        "subjectId": this.galleryFilter.animalNo,
        "tcrSlideControl": this.galleryFilter.control
      },
      "dosageControlGroup": false,
      "slidesMapped": this.galleryService.showOnlyMappedSlides,
      "viewByMore": this.galleryService.getReviewFilterString(this.galleryFilter.readReviewFlag)
    }
    this.galleryService.setSearchData(this.searchData);
    this.fetchingNewData = true;
    this.galleryService.getFilteredSlideData(this.galleryFilter.studyName, this.searchData, this.galleryService.getGroupByString(this.galleryFilter.viewByG), pageNo)
      .pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].alreadyrender = true;
        this.fetchingNewData = false;
        res.data.content.forEach(element => {
          element.state = $('.selectAll_' + this.openCollapseIndex).is(":checked");
          element.isDisabled = $('.selectAll_' + this.openCollapseIndex).is(":checked");

          if (!element.hasOwnProperty("imgRotationDegree")) {
            element.imgFormat = element.slidename.substring(((element.slidename.lastIndexOf(".") >= 0) ? element.slidename.lastIndexOf(".") : 0) + 1, element.slidename.length);
            element.imgRotationDegree = this.config.thumbnailRotateDegree ? this.config.thumbnailRotateDegree : 0;
            if (this.config.customImgFormatThumbnailRotateDegree && this.config.customImgFormatThumbnailRotateDegree.hasOwnProperty(element.imgFormat)) {
              element.imgRotationDegree = this.config.customImgFormatThumbnailRotateDegree[element.imgFormat];
            }
          }

          if (!this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].slidesArr) {
            this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].slidesArr = [];
            this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].slidesArr.push(element);
          } else {
            if (!this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].slidesArr.some(person => person.slidePk === element.slidePk)) {
              this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].slidesArr.push(element);
            }
          }

          if (!this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].pageInfo) {
            this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].pageInfo = {};
          }
          this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].pageInfo = res.data.pageInfo;
        });
        let arr = this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].slidesArr;
        arr = arr ? arr : [];
        this.galleryService.setGalleryDataArrlength(arr.length);
      });
  }

  openViewer(slideId: any, groupIn, selectedAccordionText) {
    this.commonService.setViewerOpenedFrom(this.activeMode);
    var slides = this.galleryGrid[Object.keys(this.galleryGrid)[groupIn]].slidesArr.map((value) => {
      return {
        'slidePk': value.slidePk,
        'identifier': value.identifier,
      }
    });
    this.commonService.setSelectedGroupSlides(JSON.stringify(slides));

    this.galleryFilter.selectedAccordion = selectedAccordionText;
    this.commonService.setGalleryFilters(JSON.stringify(this.galleryFilter));

    let dataObj = {
      "opened": true
    }
    this.galleryService.changeOpenStatus(slideId, dataObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
    });

    /* this.commonService.changePlace('image-viewer/' + slideId);
    this.router.navigate(['/dashboard/image-viewer/' + slideId]);
    localStorage.setItem('samples', JSON.stringify(slideId)); */

    this.router.navigate(['WebViewer/' + slideId]);
    this.galleryService.setSelecteSlidesList([]);
  }

  checkifKeyExists(key) {
    if (this.selectedImageObjConsultation[key] && this.selectedImageObjConsultation[key].added) {
      return true;
    } else {
      return false;
    }
  }

  returnZero() {
    return 0;
  }

  isOrganEmpty(group: any) {
    let count = 0;
    group.forEach((grp: any) => {
      if (this.checkifKeyExists(grp.slidePk)) {
        count++;
      }
    });

    if (count == group.length) {
      return false;
    } else {
      return true;
    }
  }

  checkIfImageMarked(imageKey) {
    this.selectedImageObjConsultation = Object.assign({}, this.galleryService.getSelectedAddImage());
    return this.selectedImageObjConsultation[imageKey];

  }

  openPreview(groupSlideKey) {
    this.galleryService.changePreviewSlide(groupSlideKey);
    this.galleryService.changePreview(true);
    setTimeout(this.setTitleDivWidth.bind(this), 200);
  }

  checkAll(ev, groupArr, searchKey) {
    groupArr.forEach((x, index) => {
      x.state = ev.target.checked;
      x.isDisabled = ev.target.checked;
      /* if (this.activeMode == "mapped") {
        if (x.unTouched) {
          this.setSelectedSlideList(index, x, ev.target.checked);
        }
      } else {
        this.setSelectedSlideList(index, x, ev.target.checked);
      } */
    })
    //if (ev.target.checked && this.activeMode != 'consultation') {
    this.galleryFilter = JSON.parse(localStorage.galleryFilters);
    let findData = this.galleryService.getSearchData();
    findData['mainFilter'] = findData['mainFilter'] ? [searchKey] : [];
    if (this.activeMode == 'consultation' || this.activeMode == 'consultation-viewer') {
      this.galleryService.getSlidesIdentifierInfo(this.galleryFilter.studyName, this.galleryService.getGroupByString(this.galleryFilter.viewByG), findData)
        .pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          res.forEach(element => {
            element.slidename = element.name;
            this.setSelectedSlideList(element, ev.target.checked);
          });
          if (ev.target.checked) {
            this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].selecteMessage = res.length + " slides selected";
          } else {
            this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].selecteMessage = '';
          }
        });
    } else {
      this.galleryService.getListOfAllSlideIdsInAccordion(this.galleryFilter.studyName, this.galleryService.getGroupByString(this.galleryFilter.viewByG), findData)
        .pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          res.forEach(element => {
            let resObj = {
              slidePk: element
            }
            this.setSelectedSlideList(resObj, ev.target.checked);
          });
          if (ev.target.checked) {
            this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].selecteMessage = res.length + " slides selected";
          } else {
            this.galleryGrid[Object.keys(this.galleryGrid)[this.openCollapseIndex]].selecteMessage = '';
          }
        });
    }
    /* } else if (!ev.target.checked) {
      this.selectedSlides = [];
      this.selectedImageObjForThumbnail = {};
      this.galleryService.setSelecteSlidesList(this.selectedSlides);
      this.galleryService.setSelectedSlidesListForThumbnail(this.selectedImageObjForThumbnail);
    } */
  }

  isAllChecked(groupArr) {
    let tempGroupArr = [];
    if (groupArr && groupArr.length > 0) {
      /* if (this.activeMode == "mapped") {
        tempGroupArr = groupArr.filter(e => {
          return e.unTouched
        })
      } else {
        tempGroupArr = groupArr;
      } */
      tempGroupArr = groupArr
    }
    return tempGroupArr.length > 0 ? tempGroupArr.every(_ => _.state) : false;
  }

  onTableScroll(e, collapsekey) {
    const tableViewHeight = e.target.offsetHeight // viewport
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit && !this.fetchingNewData && this.galleryGrid[collapsekey].pageInfo.more) {
      this.getPageWiseData(collapsekey, this.galleryGrid[collapsekey].pageInfo.pageNo + 1);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
