import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ImageViewerService } from 'src/app/image-viewer/image-viewer.service';
import { HttpClient, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  showData: any
  currentSlideIdData: any;
  savedDsComment: any;
  selectedSlideData: any;
  files: File[] = [];
  // constructor(@Optional() private dialogRef: MatDialogRef<ConfirmDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  // showData: any;
  constructor(@Optional() private dialogRef: MatDialogRef<ConfirmDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private imageViewerService: ImageViewerService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.files = [];
    /* this.showData = {
      'headerTitle': this.data.headerTitle ? this.data.headerTitle: 'Title',
      'confirmMsg': this.data.confirmMsg ? this.data.confirmMsg:'Are You Sure You want to delete this?',
      'cancelButtonText': this.data.cancelButtonText ? this.data.cancelButtonText:'Cancel',
      'confirmButtonText': this.data.confirmButtonText ? this.data.confirmButtonText:'Ok'
    } */
  }
  closeDialog(msg: any = 'btnClose') {
    this.files = [];
    this.dialogRef.close(msg);
  }

  confirmAction(){
    this.dialogRef.close(true);
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadAnnoFile() {
    let frmData = new FormData();
    frmData.append("file", this.files[0]);
    frmData.append("slideId", this.data.slideId);

    /* const req = new HttpRequest('POST', `http://172.28.43.137:9070/airadhi/slide-image/annotationV1/import`, frmData, {
      reportProgress: true,
      responseType: 'json'
    });

    this.http.request(req).subscribe((item: any) => {
      this.files = [];
      let x = 0;
    }); */

    this.imageViewerService.uploadAnnoFile(frmData).subscribe((item: any) => {
      this.closeDialog("success");
    }, (err: any) => {
      this.closeDialog("error");
    });
  }
  updateDsComment() {
    localStorage.setItem('deleteDsComment', this.savedDsComment);
    localStorage.removeItem('selectedSlideData');
  }
  onConfirm(){
    if(this.showData.headerTitle == "Edit User"){
      this.data.isAllowed =true;
      this.dialogRef.close(this.data);
    }
  }

}
