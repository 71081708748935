import { LoaderComponent } from './../../common/loader/loader.component';
import { AutoCreateDialogComponent } from './../auto-create-dialog/auto-create-dialog.component';
import { FileReaderService } from './../../FileReader.service';
import { StudyService } from './../study.service';
import { ConfirmDialogComponent } from '../../common/confirm-dialog/confirm-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DosagesModel } from "./../models/dosages.model";
import { Cassettes } from "./../models/Cassettes";
import { cassetteModel } from '../models/cassettes.model';
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormArray } from "@angular/forms";
import { Study } from "../models/study.model";
import { TranslateService } from '@ngx-translate/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Dosages } from '../models/Dosages';
import { formatDate } from '@angular/common';
import { CommonService } from '../../common.service';
import { Animals } from '../models/Animals.model';
import { DatePipe } from '@angular/common';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

declare const $: any;
@Component({
  selector: "app-study-create",
  templateUrl: "./study-create.component.html",
  styleUrls: ["./study-create.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class StudyCreateComponent implements OnInit {
  @ViewChild('cassettesFileInput') cassettesFileInput: any;
  @ViewChild('dosageInputFile') dosageInputFile: any;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  cols = 4;
  visible = true;
  selectable = true;
  removable = true;
  studyCreateType: any;
  studyFrom: FormGroup;
  cassettesFormGroup: FormGroup;
  CassetteObj: Cassettes;
  datepipe = new DatePipe('en-US');

  dosageFormGroup: FormGroup;
  dosageObj: Dosages;
  counterCassette = 1;
  counterDosage = 1;
  typeList = ["RANGE", "CUSTOM"];

  uploadedCassettes: any;
  uploadedDosages: any;
  studyId: String;

  pathologists: any;
  secondaryPathologists = [];
  technicians: any;
  studyDomains: any;
  studyTypes: any;
  species: any;
  stainsOptions = [];
  organOptions = [];
  treatments: any;
  routes: any;
  strains: any;
  dosageOptions: any;
  private dosageCodes = []; // private array just to check the value during excel upload
  isStudyEdit: boolean = false;
  isStudyDataReady: boolean = false; // used for spinner
  lengthCassettes: number = 0;
  lengthDosages: number = 0;
  slideMapArr = [];
  cassetteIdOrganObj = {};

  configId;
  isProjectChecked: boolean = false;
  isAccessionChecked: boolean = false;
  isSelectPeerReviewerChecked: boolean = false;
  isSelectPrimaryInvestigatorChecked: boolean = false;
  isTreatmentChecked: boolean = false;
  isStudyDomainChecked: boolean = false;
  isStudyTypeChecked: boolean = false;
  isDurationChecked: boolean = false;
  isSacrificeChecked: boolean = false;
  isRouteChecked: boolean = false;
  isCROChecked: boolean = false;
  isTestItemChecked: boolean = false;
  isAdditionalPathologist: boolean = false;
  isReviewSharable: boolean = false;

  autoCreateStudy: boolean = false;
  showSlideTab: boolean = false;

  slidesArr: any = [];
  isAutoCreateSaveClicked: boolean = false;
  slideSavingText: string = "Saving...";
  isDosageNextClicked: boolean = false;

  repaintAfterScroll;

  tissueSectionData: any = [
    { optionId: 'NONE', optionName: 'Unknown' },
    { optionId: 'RECUT', optionName: 'Recut' },
    { optionId: 'REEMBED', optionName: 'Re-embed' },
    { optionId: 'RETRIM', optionName: 'Retrim' },
  ];
  slideControl: any = [
    { optionId: '', optionName: 'Select' },
    { optionId: 'Positive Control', optionName: 'Positive Control' },
    { optionId: 'Negative Control', optionName: 'Negative Control' },
  ];
  slideSex: any = [
    { optionId: '', optionName: 'Not known' },
    { optionId: 'M', optionName: 'Male' },
    { optionId: 'F', optionName: 'Female' },
  ];
  slideFilterObj: any = { "uniqCombination": {} };
  tempSlideArrList: any = [];
  selectedAnimalInfo: any = [];
  studySlideMappingObj: any = {};

  slideColumnArr: any = [
    { columnId: 'seq', columnName: "#", type: "index", isStainIHCDisable: false, width: '5vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: false },
    { columnId: 'slideId', columnName: "Slide Id", type: "display", isStainIHCDisable: false, width: '10vw', isMandatory: true, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: false },
    { columnId: 'imageRefNo', columnName: "Image Name", type: "text", isStainIHCDisable: false, width: '12vw', isMandatory: true, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: false },
    { columnId: 'animalId', columnName: "Subject Id", type: "select", optionData: this.selectedAnimalInfo, isStainIHCDisable: false, width: '12vw', isMandatory: true, showTooltip: false, showInStudy: ['Normal'], isColumnHideable: false },
    { columnId: 'animalId', columnName: "Subject Id", type: "text", isStainIHCDisable: false, width: '12vw', isMandatory: true, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: false },
    { columnId: 'tissueId', columnName: "Tissue", type: "matChips", isStainIHCDisable: false, width: '25vw', isMandatory: true, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: false },
    { columnId: 'sex', columnName: "Sex", type: "select", optionData: this.slideSex, isStainIHCDisable: false, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'cassetteId', columnName: "Cassette Id", type: "display", isStainIHCDisable: false, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal'], isColumnHideable: true },
    { columnId: 'scanMagnification', columnName: "Magnification", type: "text", isStainIHCDisable: false, width: '10vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: true },
    { columnId: 'scanDate', columnName: "Scan Date", type: "date", isStainIHCDisable: false, width: '15vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: true },
    { columnId: 'instrument', columnName: "Instrument", type: "text", isStainIHCDisable: false, width: '10vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: true },
    { columnId: 'tissueSection', columnName: "Tissue Section", type: "select", optionData: this.tissueSectionData, isStainIHCDisable: false, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: true },
    { columnId: 'comments', columnName: "Comments", type: "text", isStainIHCDisable: false, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: true },
    { columnId: 'slideControl', columnName: "Control", type: "select", optionData: this.slideControl, isStainIHCDisable: false, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'stain', columnName: "Stain", type: "select", optionData: [], isStainIHCDisable: false, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: true },
    { columnId: 'stainDate', columnName: "Stain Date", type: "date", isStainIHCDisable: false, width: '15vw', isMandatory: false, showTooltip: false, showInStudy: ['Normal', 'TCR'], isColumnHideable: true },
    { columnId: 'IHC_ExperimentNo', columnName: "IHC Experiment No.", type: "text", isStainIHCDisable: true, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'targetAntigen', columnName: "Target Antigen", type: "text", isStainIHCDisable: true, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'primaryAntibodyId', columnName: "Primary Antibody Id", type: "text", isStainIHCDisable: true, width: '14vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'primaryAntibodyConcentration', columnName: "Primary Antibody Conc", type: "text", isStainIHCDisable: true, width: '14vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'primaryAntibodyConcentrationUnit', columnName: "Primary Conc Units", type: "text", isStainIHCDisable: true, width: '14vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'secondaryAntibodyId', columnName: "Secondary Antibody Id", type: "text", isStainIHCDisable: true, width: '15vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'detectionMethod', columnName: "Detection Method", type: "text", isStainIHCDisable: true, width: '13vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true },
    { columnId: 'chromogen', columnName: "Chromogen", type: "text", isStainIHCDisable: true, width: '12vw', isMandatory: false, showTooltip: false, showInStudy: ['TCR'], isColumnHideable: true }
  ];
  dtSlides: any;
  fieldsToDisable = [{ stains: [], organs: [] }]; // this array is used to disable stains during edit study
  loadColumnChooser = true;
  slideIdUniqueObj = {};
  studySavedResponse = {};
  dialogConfig = new MatDialogConfig();
  peerReviewers: any;

  cassetteCrationInProgress: boolean = false;
  dosageCrationInProgress: boolean = false;

  constructor(
    private formbuilder: RxFormBuilder,
    public translate: TranslateService,
    private studyService: StudyService,
    private dialog: MatDialog,
    private router: Router,
    public commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private fileReaderService: FileReaderService
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.getAllData();
  }

  ngOnInit() {
    this.studyCreateType = localStorage.studyCreationType;
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    // this.dialogConfig.id = 'loaderDialog';
    this.dialogConfig.disableClose = true;
    this.dialogConfig.data = {
      'Msg': "Fetching Data..."
    };
    let DialogRef = this.dialog.open(LoaderComponent, this.dialogConfig);
    // this.dialogConfig.id = null;

    $("#second, #third, #forth").hide();
    $("#first").show();
    let study = new Study();
    this.CassetteObj = new Cassettes();
    this.CassetteObj.rows = new Array<cassetteModel>();
    this.dosageObj = new Dosages();
    this.dosageObj.rows = new Array<DosagesModel>();
    this.activatedRoute.paramMap.subscribe((paramMap: any) => {
      if (paramMap.params.studyId) {
        this.isStudyEdit = true;
        this.studyId = paramMap.params.studyId;
        let studyData = this.getData(this.studyId);
        studyData.then((successData: any) => {
          let studyResponse = successData.data;
          this.studyCreateType = (studyResponse.isTCRStudy) ? "TCR" : "Normal";
          localStorage.studyCreationType = this.studyCreateType;

          study.studyNo = studyResponse.studyNo;
          study.studyTitle = studyResponse.studyTitle;
          study.projectNo = studyResponse.projectNo;
          study.accessionNo = studyResponse.accessionNo;
          study.studyDomain = studyResponse.studyDomain;
          study.treatment = studyResponse.treatment;
          study.sacrifice = studyResponse.sacrifice;
          study.studyType = studyResponse.studyType;
          study.species = studyResponse.species;
          // this.getStrains(study.species);
          study.strain = studyResponse.strain;
          study.route = studyResponse.route;
          study.fromDate = studyResponse.startDate ? new Date(studyResponse.startDate) : null;
          study.toDate = studyResponse.endDate ? new Date(studyResponse.endDate) : null;

          study.cro = studyResponse.cro;
          study.testItem = studyResponse.testItem;

          let secPatho = [];
          studyResponse.studyUsers.forEach((user: any) => {
            if (user.role == "Pathologist" && !user.reassigned) {
              if (!user.reassigned) {
                study.pathologist = user.email;
                // this.removePatho(user.email); // for secondary pathologist list no need to show primary pathologist
              }
            };
            if (user.role == "Peer Reviewer" && !user.reassigned) {
              study.peerReviewer = user.email;
            };
            if (user.role == "Primary Investigator" && !user.reassigned) {
              study.primaryInvestigator = user.email;
            };
            if (user.role == "Technician" && !user.reassigned) {
              study.technician = user.email;
            };
            if (user.role == "Additional Pathologist" && !user.reassigned) {
              secPatho.push(user.email);
            }
          });
          study.additionalPathologist = secPatho;
          study.reviewSharable = studyResponse.reviewSharable;
          if (studyResponse.cassettes.length == 0) {
            let cm = new cassetteModel();
            cm.rowType = "manuallyAdded";
            cm.newCassette = true;
            this.CassetteObj.rows.push(cm);
          } else {
            this.fieldsToDisable = [];
            this.lengthCassettes = studyResponse.cassettes.length;
            this.counterCassette = this.lengthCassettes;
            studyResponse.cassettes.forEach((cassette: any) => {
              let cm = new cassetteModel();
              cm.cassetteId = cassette.cassetteId;
              cm.stains = cassette.stains;
              cm.organs = cassette.organs;
              cm.rowType = "manuallyAdded";
              cm.newCassette = false;
              this.CassetteObj.rows.push(cm);
              this.fieldsToDisable.push({ stains: cassette.stains, organs: cassette.organs });
              this.cassetteIdOrganObj[cassette.cassetteId] = cassette.organs.join(";");
            });
          }
          if (studyResponse.dosages.length == 0) {
            let dm = new DosagesModel();
            dm.animals = new Animals();
            dm.animalSequenceType = this.typeList[0];
            dm.controlGroup = "No";
            dm.rowType = "manuallyAdded";
            dm.newDosage = true;
            this.dosageObj.rows.push(dm);
          } else {
            this.lengthDosages = studyResponse.dosages.length;
            this.counterDosage = this.lengthDosages;
            studyResponse.dosages.forEach((dosage: any) => {
              let dm = new DosagesModel();
              dm.animals = new Animals();
              dm.dosageCode = dosage.dosageCode;
              dm.animalSequenceType = dosage.animalSequenceType;
              dm.animals.maleStartIndex = dosage.animals.maleStartIndex ? dosage.animals.maleStartIndex : null;
              dm.animals.maleEndIndex = dosage.animals.maleEndIndex ? dosage.animals.maleEndIndex : null;
              dm.animals.femaleStartIndex = dosage.animals.femaleStartIndex ? dosage.animals.femaleStartIndex : null;
              dm.animals.femaleEndIndex = dosage.animals.femaleEndIndex ? dosage.animals.femaleEndIndex : null;
              dm.animals.maleList = (dosage.animals.maleList && dosage.animals.maleList[0] != "") ? dosage.animals.maleList : null;
              dm.animals.femaleList = (dosage.animals.femaleList && dosage.animals.femaleList[0] != "") ? dosage.animals.femaleList : null;
              dm.animals.notKnownList = (dosage.animals.notKnownList && dosage.animals.notKnownList[0] != "") ? dosage.animals.notKnownList : null;
              dm.controlGroup = dosage.controlGroup ? "Yes" : "No";
              dm.dose = dosage.dose;
              dm.dosageUnit = dosage.dosageUnit;
              dm.rowType = "manuallyAdded";
              dm.newDosage = false;
              this.dosageObj.rows.push(dm);
            });
          }

          if (studyResponse.slideCount > 0) {
            this.showSlideTab = true;
            this.slidesArr = [];
            let tempSlideArr = [];
            this.studyService.getStudySlideDetails(this.studyId).subscribe((res: any) => {
              studyResponse.imageDetailsModel = res.data.imageDetailsModel;
              studyResponse.imageDetailsModel.forEach((indvSlideObj: any) => {
                let slideData = {
                  slideId: indvSlideObj.imageModel.slideLabel ? indvSlideObj.imageModel.slideLabel : null,
                  imageRefNo: indvSlideObj.imageModel.slidename ? indvSlideObj.imageModel.slidename : null,
                  animalId: indvSlideObj.slideInfo.animalNo ? ($.isNumeric(indvSlideObj.slideInfo.animalNo) ? parseInt(indvSlideObj.slideInfo.animalNo) : ("" + indvSlideObj.slideInfo.animalNo)) : null,//
                  dosageGroup: indvSlideObj.imageModel.dosageGroup ? indvSlideObj.imageModel.dosageGroup : null,//
                  sex: indvSlideObj.imageModel.sex ? indvSlideObj.imageModel.sex : null,//
                  stain: indvSlideObj.slideInfo.stain ? indvSlideObj.slideInfo.stain : null,//
                  studyNo: indvSlideObj.imageModel.studyNo ? indvSlideObj.imageModel.studyNo : null,
                  cassetteId: indvSlideObj.slideInfo.cassetteCode ? indvSlideObj.slideInfo.cassetteCode : null,//
                  comments: indvSlideObj.imageModel.slideComments ? indvSlideObj.imageModel.slideComments : null,//

                  scanMagnification: (indvSlideObj.imageModel.scanner && indvSlideObj.imageModel.scanner.scanMagnification) ? indvSlideObj.imageModel.scanner.scanMagnification : null,
                  scanDate: indvSlideObj.imageModel.scanDateTime ? indvSlideObj.imageModel.scanDateTime : null,//
                  instrument: (indvSlideObj.imageModel.scanner && indvSlideObj.imageModel.scanner.instrument) ? indvSlideObj.imageModel.scanner.instrument : null,

                  tissueId: indvSlideObj.imageModel.tissueId ? indvSlideObj.imageModel.tissueId : null,
                  tissueSection: indvSlideObj.imageModel.tissueSectionCutting ? indvSlideObj.imageModel.tissueSectionCutting : "NONE",
                  scannerName: indvSlideObj.imageModel.scannerName ? indvSlideObj.imageModel.scannerName : null,//

                  IHC_ExperimentNo: indvSlideObj.ihcStainModel ? (indvSlideObj.ihcStainModel.ihcExperimentNumber ? indvSlideObj.ihcStainModel.ihcExperimentNumber : null) : null,
                  targetAntigen: indvSlideObj.ihcStainModel ? (indvSlideObj.ihcStainModel.targetAntigen ? indvSlideObj.ihcStainModel.targetAntigen : null) : null,
                  primaryAntibodyId: indvSlideObj.ihcStainModel ? (indvSlideObj.ihcStainModel.primaryAntibodyID ? indvSlideObj.ihcStainModel.primaryAntibodyID : null) : null,
                  primaryAntibodyConcentration: indvSlideObj.ihcStainModel ? (indvSlideObj.ihcStainModel.primaryAntibodyConcentration ? indvSlideObj.ihcStainModel.primaryAntibodyConcentration : null) : null,
                  primaryAntibodyConcentrationUnit: indvSlideObj.ihcStainModel ? (indvSlideObj.ihcStainModel.primaryAntibodyConcentrationUnit ? indvSlideObj.ihcStainModel.primaryAntibodyConcentrationUnit : null) : null,
                  secondaryAntibodyId: indvSlideObj.ihcStainModel ? (indvSlideObj.ihcStainModel.secondayAntibodyID ? indvSlideObj.ihcStainModel.secondayAntibodyID : null) : null,
                  detectionMethod: indvSlideObj.ihcStainModel ? (indvSlideObj.ihcStainModel.detectionMethod ? indvSlideObj.ihcStainModel.detectionMethod : null) : null,
                  chromogen: indvSlideObj.ihcStainModel ? (indvSlideObj.ihcStainModel.chromogen ? indvSlideObj.ihcStainModel.chromogen : null) : null,
                  slideControl: indvSlideObj.imageModel.slideControl ? indvSlideObj.imageModel.slideControl : "",
                  stainDate: indvSlideObj.imageModel.stainDate ? indvSlideObj.imageModel.stainDate : null,
                };
                if (this.studyCreateType == "TCR") {
                  slideData.tissueId = this.cassetteIdOrganObj[indvSlideObj.slideInfo.cassetteCode];
                }
                tempSlideArr.push(slideData);
              });
              this.slidesArr = tempSlideArr;
            });
          }

          this.studyFrom = this.formbuilder.formGroup(study);
          this.cassettesFormGroup = this.formbuilder.formGroup(this.CassetteObj);
          this.dosageFormGroup = this.formbuilder.formGroup(this.dosageObj);
          this.isStudyDataReady = true;
          setTimeout(() => {
            DialogRef.close();
          }, 0);
        });
      } else {
        this.isStudyEdit = false;
        this.isStudyDataReady = true;
        this.studyCreateType = localStorage.studyCreationType;
        setTimeout(() => {
          DialogRef.close();
        }, 0);
        this.studyFrom = this.formbuilder.formGroup(study);

        let cm = new cassetteModel();
        cm.rowType = "manuallyAdded";
        cm.newCassette = true;
        this.CassetteObj.rows.push(cm);
        this.fieldsToDisable.push({ stains: [], organs: [] });
        this.cassettesFormGroup = this.formbuilder.formGroup(this.CassetteObj);

        let dosageModel = new DosagesModel();
        dosageModel.animals = new Animals();
        dosageModel.animalSequenceType = this.typeList[0];
        dosageModel.controlGroup = "No";
        dosageModel.rowType = "manuallyAdded";
        dosageModel.newDosage = true;
        this.dosageObj.rows.push(dosageModel);
        this.dosageFormGroup = this.formbuilder.formGroup(this.dosageObj);

        if (localStorage.creationType == 'auto') {
          this.autoCreateDialog();
        }
      }

      // this.studyFrom?.controls['studyNo'].valueChanges.subscribe(value => {
      //   if (this.slideMapArr.length > 0) {
      //     this.slideMapArr.forEach((res: any) => {
      //       res.studyNo = value;
      //     });
      //   }
      // });

    });
  }

  private async getData(studyId: String) {
    return await (this.studyService.getStudyDetails(studyId)).toPromise()
  }

  private getAllData() {

    this.studyService.getConfiguration().subscribe((fieldData: any) => {
      this.configId = fieldData.data.configId;
      this.isProjectChecked = fieldData.data.projectNo;
      this.isAccessionChecked = fieldData.data.accessionNo;
      this.isSelectPeerReviewerChecked = fieldData.data.peerReviewer;
      this.isSelectPrimaryInvestigatorChecked = fieldData.data.primaryInvestigator;
      this.isTreatmentChecked = fieldData.data.treatment;
      this.isStudyDomainChecked = fieldData.data.studyDomain;
      this.isStudyTypeChecked = fieldData.data.studyType;
      this.isDurationChecked = fieldData.data.duration;
      this.isSacrificeChecked = fieldData.data.sacrifice;
      this.isRouteChecked = fieldData.data.routes;
      this.isCROChecked = fieldData.data.cro;
      this.isTestItemChecked = fieldData.data.testItem;
      this.isAdditionalPathologist = fieldData.data.additionalPathologist;
      // this.isReviewSharable = fieldData.data.reviewSharable;
    });

    this.studyService.getAllPathologist().subscribe((response: any) => {
      this.pathologists = this.commonService.sortByMultiKey(response.data, ['displayName']);
      this.peerReviewers = this.commonService.sortByMultiKey(response.data, ['displayName']);
      this.secondaryPathologists = this.commonService.sortByMultiKey(response.data, ['displayName']);
    });

    this.studyService.getAllTechnician().subscribe((response: any) => {
      this.technicians = this.commonService.sortByMultiKey(response.data, ['displayName']);
    });

    this.studyService.getAllStudyDomains().subscribe((response: any) => {
      this.studyDomains = response.data;
    });

    this.studyService.getAllStudyTypes().subscribe((response: any) => {
      this.studyTypes = response.data;
    });

    this.studyService.getAllSpecies().subscribe((response: any) => {
      this.species = response.data;
    });

    this.studyService.getAllTreatments().subscribe((response: any) => {
      this.treatments = response.data;
    });

    this.studyService.getAllRoutes().subscribe((response: any) => {
      this.routes = response.data;
    });

    this.studyService.getAllStains().subscribe((response: any) => {
      response.data.forEach((stains: any) => {
        this.stainsOptions.push(stains.stain)
      }); // this loop can be removed if we get response in noraml array and this is done to check stains while excel upload
    });

    this.studyService.getAllOrgans().subscribe((response: any) => {
      response.data.forEach((organs: any) => {
        this.organOptions.push(organs.organ);
      });// this loop can be removed if we get response in noraml array and this is done to check organs while excel upload
    });

    this.studyService.getAllDosageOptions().subscribe((response: any) => {
      this.dosageOptions = response.data;
      this.dosageOptions.forEach((dos: any) => {
        this.dosageCodes.push(dos.code);
      }); // this loop is just used to get the "dosage codes" from reponse as they are needed while excel upload, the response also contains description which is not needed while excel upload but need to show in select 
    });
  }

  get studyFrm() {
    return this.studyFrom.controls
  }

  private async getAllSpecies() {
    return await (this.studyService.getAllSpecies()).toPromise();
  }

  getStrains(specieId: String) {
    let promiseSpecies = new $.Deferred();
    if (this.species == undefined) {
      let sp = this.getAllSpecies();
      sp.then((success: any) => {
        this.species = success.data;
        promiseSpecies.resolve('');
      });
    } else {
      promiseSpecies.resolve('');
    }
    promiseSpecies.then(() => {
      this.species.forEach((specie: any) => {
        if (specie.species == specieId) {
          this.strains = specie.strainModels;
          if (this.strains.length == 0) {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.NO_STRAIN"))
          }
        }
      });
    });
  }

  get studyF() {
    return this.studyFrom.controls;
  }

  get cassettes() {
    return this.cassettesFormGroup.controls.rows;
  }

  get dosages() {
    return this.dosageFormGroup.controls.rows;
  }

  uploadExcel(event: any, param: String) {
    let fileUploaded = event.target.files[0];
    this.fileReaderService.readFile(fileUploaded, (fileData: any) => {
      if (param == 'Cassettes') {
        this.uploadedCassettes = fileData;
        if ((!this.uploadedCassettes[0].hasOwnProperty('Stain') && !this.uploadedCassettes[0].hasOwnProperty('Organs') && !this.uploadedCassettes[0].hasOwnProperty('Cassette Code')) || (Object.keys(this.uploadedCassettes[0]).length != 3)) {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.FILE_READER.VALID_FILE"));
          this.cassettesFileInput.nativeElement.value = '';
          return;
        }
        let cs = <FormArray>this.cassettesFormGroup.controls.rows;
        for (let i = this.cassettesFormGroup.controls.rows.value.length - 1; i > -1; i--) {
          let rowInfo = this.cassettesFormGroup.controls.rows.value[i];
          if (rowInfo.rowType == "excelUploaded") {
            cs.removeAt(i);
            this.counterCassette--;
          }
        }
        this.populateCassettes();
      } else {
        this.uploadedDosages = fileData;
        if (!this.uploadedDosages[0].hasOwnProperty('Dosage Name / Code') && !this.uploadedDosages[0].hasOwnProperty('Animal ID Input Mechanism')
          && !this.uploadedDosages[0].hasOwnProperty('Is Control')) {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.FILE_READER.VALID_FILE"));
          this.dosageInputFile.nativeElement.value = '';
          return;
        }
        let ds = <FormArray>this.dosageFormGroup.controls.rows;
        for (let i = this.dosageFormGroup.controls.rows.value.length - 1; i > -1; i--) {
          let rowInfo = this.dosageFormGroup.controls.rows.value[i];
          if (rowInfo.rowType == "excelUploaded") {
            ds.removeAt(i);
            this.counterDosage--;
          }
        }
        this.populateDosages();
      }
    });
  }

  addNewCassette() {
    if (this.isStudyEdit) {
      this.fieldsToDisable.push({ "stains": [], "organs": [] });
    }
    let rows = <FormArray>this.cassettesFormGroup.controls.rows;
    let cm = new cassetteModel();
    cm.rowType = "manuallyAdded";
    cm.newCassette = true;
    this.CassetteObj.rows.push(cm)
    rows.push(this.formbuilder.formGroup(cm));
    this.counterCassette++;
  }

  removeCassette(index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    dialogConfig.data = {
      headerTitle: "Confirm",
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.DELETE_SELECTED_CASSETTE"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.counterCassette--;
        let cs = <FormArray>this.cassettesFormGroup.controls.rows;
        this.cassettesFormGroup.markAsDirty();
        cs.removeAt(index);
      }
    });
  }

  remove(stain: string, param: String, index: number): void {
    if (param == "stain") {
      const i = this.cassettesFormGroup.value.rows[index].stains.indexOf(stain);
      if (i >= 0) {
        this.cassettesFormGroup.value.rows[index].stains.splice(i, 1);
        this.cassettesFormGroup.controls.rows['controls'][index].controls.stains.setValue(this.cassettesFormGroup.value.rows[index].stains);
        this.cassettesFormGroup.markAsDirty();
      }
    } else {
      const i = this.cassettesFormGroup.value.rows[index].organs.indexOf(stain);
      if (i >= 0) {
        this.cassettesFormGroup.value.rows[index].organs.splice(i, 1);
        this.cassettesFormGroup.controls.rows['controls'][index].controls.organs.setValue(this.cassettesFormGroup.value.rows[index].organs);
        this.cassettesFormGroup.markAsDirty();
      }
    }
  }

  addNewDosage() {
    let rows = <FormArray>this.dosageFormGroup.controls.rows;
    let dm = new DosagesModel();
    dm.animalSequenceType = this.typeList[0];
    dm.controlGroup = "No"
    dm.rowType = "manuallyAdded";
    dm.newDosage = true;
    dm.animals = new Animals();
    this.dosageObj.rows.push(dm)
    rows.push(this.formbuilder.formGroup(dm));
    this.counterDosage++;
  }

  removeDosage(index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    dialogConfig.data = {
      headerTitle: "Confirm",
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.DELETE_SELECTED_DOSSAGE"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        let ds = <FormArray>this.dosageFormGroup.controls.rows;
        ds.removeAt(index);
        this.dosageFormGroup.markAsDirty();
        this.counterDosage--;
      }
    });

  }

  private populateCassettes() {
    let isStainError = false;
    let isOrganError = false;
    if ((this.counterCassette == 1) && (this.cassettesFormGroup.value.rows[0].cassetteId == null)) {
      let cs = <FormArray>this.cassettesFormGroup.controls.rows;
      cs.removeAt(0);
      this.counterCassette = 0;
    }
    this.uploadedCassettes.forEach((cassette: any, index: any) => {
      let cm = new cassetteModel();
      cm.cassetteId = cassette['Cassette Code'];
      cm.rowType = "excelUploaded";
      cm.newCassette = true;
      let stains = cassette['Stain'].split(',').map(el => el.trim());
      let organs = cassette['Organs'].split(',').map(el => el.trim());
      if (stains.every(val => this.stainsOptions.includes(val))) {
        cm.stains = stains
        isStainError = false;
      } else {
        isStainError = true;
        this.uploadedCassettes.splice(index, this.uploadedCassettes.length);
        this.commonService.showToastMsg("Check Stain type in row " + (parseInt(index) + 1) + ": upload file again");
        return false;
      }

      if (organs.every(val => this.organOptions.includes(val))) {
        cm.organs = organs;
        isOrganError = false;
      } else {
        isOrganError = true;
        this.uploadedCassettes.splice(index, this.uploadedCassettes.length);
        this.commonService.showToastMsg("Check Tissue type in row " + (parseInt(index) + 1) + ": upload file again");
        return false;
      }

      if (!isOrganError && !isStainError) {
        this.CassetteObj.rows.push(cm)
        let rows = <FormArray>this.cassettesFormGroup.controls.rows;
        rows.push(this.formbuilder.formGroup(cm));
        this.counterCassette++;
      }
    });
    if (this.cassettesFormGroup.controls.rows.value.length == 0) {
      this.counterCassette = 1;
      this.addNewCassette();
    }
    this.cassettesFileInput.nativeElement.value = '';
  }

  private populateDosages() {
    let isError = false;
    if ((this.counterDosage == 1) && (this.dosageFormGroup.value.rows[0].dosageCode == null)) {
      let cs = <FormArray>this.dosageFormGroup.controls.rows;
      cs.removeAt(0);
      this.counterDosage = 0;
    }
    this.uploadedDosages.forEach((dosage: any, index: any) => {
      let dm = new DosagesModel();
      dm.animals = new Animals();
      if (this.dosageCodes.includes(dosage['Dosage Name / Code'])) {
        dm.dosageCode = dosage['Dosage Name / Code'];
        isError = false;
      } else {
        isError = true
        this.commonService.showToastMsg("PleCheck Dosage code in row " + (parseInt(index) + 1) + "; upload file again");
        return false;
      }

      dm.animalSequenceType = dosage['Animal ID Input Mechanism'].toUpperCase();
      dm.animals.maleStartIndex = dosage['Animal IDs From (Male)'];
      dm.animals.maleEndIndex = dosage['Animal IDs To (Male)'];
      dm.animals.femaleStartIndex = dosage['Animal IDs From (Female)'];
      dm.animals.femaleEndIndex = dosage['Animal IDs To (Female)'];
      dm.animals.maleList = dosage['Random Animal IDs (Male)'];
      dm.animals.femaleList = dosage['Random Animal IDs (Female)'];
      dm.controlGroup = dosage['Is Control'];
      dm.dose = dosage["Dose"];
      dm.dosageUnit = dosage["Dose Unit"];
      dm.rowType = "excelUploaded";
      dm.newDosage = true;

      if (!isError) {
        let rows = <FormArray>this.dosageFormGroup.controls.rows;
        this.dosageObj.rows.push(dm);
        rows.push(this.formbuilder.formGroup(dm));
        this.counterDosage++;
      }
    });
    if (this.dosageFormGroup.controls.rows.value.length == 0) {
      this.counterDosage = 1;
      this.addNewDosage();
    }
    this.dosageInputFile.nativeElement.value = '';
  }

  save(stage: String, action: String) {
    switch (stage) {
      case 'Stage1':
        if (this.studyFrom.valid) {
          this.studyFrom.value.configuration = this.configId;
          this.studyFrom.value.studyNo = ('' + this.studyFrom.value.studyNo).trim();
          this.studyFrom.value.studyTitle = this.studyFrom.value.studyTitle ? this.studyFrom.value.studyTitle.trim() : this.studyFrom.value.studyTitle;
          this.studyFrom.value.projectNo = this.studyFrom.value.projectNo ? this.studyFrom.value.projectNo.trim() : this.studyFrom.value.projectNo;
          this.studyFrom.value.accessionNo = this.studyFrom.value.accessionNo ? this.studyFrom.value.accessionNo.trim() : this.studyFrom.value.accessionNo;
          this.studyFrom.value.treatment = this.studyFrom.value.treatment ? this.studyFrom.value.treatment.trim() : this.studyFrom.value.treatment;
          this.studyFrom.value.sacrifice = this.studyFrom.value.sacrifice ? this.studyFrom.value.sacrifice.trim() : this.studyFrom.value.sacrifice;
          this.studyFrom.value.fromDate = this.studyFrom.value.fromDate ? formatDate(new Date(this.studyFrom.value.fromDate), 'yyyy-MM-dd', 'en_US') : '';
          this.studyFrom.value.toDate = this.studyFrom.value.toDate ? formatDate(new Date(this.studyFrom.value.toDate), 'yyyy-MM-dd', 'en_US') : '';
          this.studyFrom.value.cro = this.studyFrom.value.cro ? this.studyFrom.value.cro.trim() : this.studyFrom.value.cro;
          this.studyFrom.value.testItem = this.studyFrom.value.testItem ? this.studyFrom.value.testItem.trim() : this.studyFrom.value.testItem;
          var studyObj = JSON.parse(JSON.stringify(this.studyFrom.value));
          studyObj.slideUnmappedCount = this.slidesArr.length;
          this.studyService.createStudy(studyObj).subscribe((response: any) => {
            if (action == 'Next' && response.success) {
              this.cassetteCrationInProgress = true;
              this.goToSecondStage();
              this.studyId = response.data;
            } else if (action == 'Exit' && response.success) {
              this.commonService.showToastMsg(response.message)
              this.exitForm();
            }
          }, (error: any) => {
            this.commonService.showToastMsg(error.error.message);
          });
        } else {
          this.showErrorMessages('Study');
        }
        break;

      case 'Stage2':
        if (this.cassettesFormGroup.valid) {
          this.cassettesFormGroup.value.rows.forEach((v) => {
            if (v.rowType) delete v.rowType;
            if (v.initCassetteId) delete v.initCassetteId;
            if (v.newCassette) delete v.newCassette;
          });
          this.studyService.addCassettes(this.studyId, this.cassettesFormGroup.value.rows).subscribe((response: any) => {
            if (response.success) {
              this.uploadedCassettes = [];
              if (action == 'Next') {
                this.cassetteCrationInProgress = false;
                this.dosageCrationInProgress = true;
                this.fieldsToDisable = [];
                this.cassettesFormGroup.value.rows.forEach((v) => {
                  if (v.stains.length && v.organs.length) {
                    this.fieldsToDisable.push({ stains: v.stains, organs: v.organs });
                  }
                });
                this.gotoThirdStage();
              } else if (action == 'Exit') {
                this.commonService.showToastMsg(response.message);
                this.exitForm();
              }
            }
          }, (error: any) => {
            this.commonService.showToastMsg(error.error.message);
          });
        } else {
          this.showErrorMessages('Cassettes');
        }
        break;

      case 'Stage3':
        let formData = this.validateDosageForm();
        if (formData.isValid) {
          formData.data.forEach((v) => {
            if (v.rowType) delete v.rowType;
            if (v.newDosage) delete v.newDosage;
          });
          this.studyService.addDosages(this.studyId, formData.data).subscribe((response: any) => {
            if (response.success) {
              this.uploadedDosages = [];
              this.commonService.showToastMsg(response.message);
              this.dosageCrationInProgress = false;
              this.exitForm();
            }
          }, (error: any) => {
            this.commonService.showToastMsg(error.error.message);
          });
        } else {
          this.showErrorMessages('Dosages')
        }
        break;
      case 'Stage4':
        let slidesData = this.validateSlidesForm();
    }
  }

  editStudy(stage: String, action: String) {
    switch (stage) {
      case "Stage1":
        if (this.studyFrom.dirty) {
          if (this.studyFrom.valid) {
            this.studyFrom.value.configuration = this.configId;
            this.studyFrom.value.studyNo = this.studyFrom.value.studyNo.trim();
            this.studyFrom.value.studyTitle = this.studyFrom.value.studyTitle ? this.studyFrom.value.studyTitle.trim() : this.studyFrom.value.studyTitle;
            this.studyFrom.value.projectNo = this.studyFrom.value.projectNo ? this.studyFrom.value.projectNo.trim() : this.studyFrom.value.projectNo;
            this.studyFrom.value.accessionNo = this.studyFrom.value.accessionNo ? this.studyFrom.value.accessionNo.trim() : this.studyFrom.value.accessionNo;
            this.studyFrom.value.treatment = this.studyFrom.value.treatment ? this.studyFrom.value.treatment.trim() : this.studyFrom.value.treatment;
            this.studyFrom.value.sacrifice = this.studyFrom.value.sacrifice ? this.studyFrom.value.sacrifice.trim() : this.studyFrom.value.sacrifice;
            this.studyFrom.value.fromDate = this.studyFrom.value.fromDate ? formatDate(new Date(this.studyFrom.value.fromDate), 'yyyy-MM-dd', 'en_US') : '';
            this.studyFrom.value.toDate = this.studyFrom.value.toDate ? formatDate(new Date(this.studyFrom.value.toDate), 'yyyy-MM-dd', 'en_US') : '';
            this.studyFrom.value.cro = this.studyFrom.value.cro ? this.studyFrom.value.cro.trim() : this.studyFrom.value.cro;
            this.studyFrom.value.testItem = this.studyFrom.value.testItem ? this.studyFrom.value.testItem.trim() : this.studyFrom.value.testItem;

            var studyObj = JSON.parse(JSON.stringify(this.studyFrom.value));
            studyObj.slideUnmappedCount = this.slidesArr.length;

            this.studyService.editStudy(this.studyId, studyObj).subscribe((response: any) => {
              if (response.success) {
                if (action == "Exit") {
                  this.commonService.showToastMsg(response.message);
                  this.exitForm();
                } else {
                  if (this.studyCreateType == "TCR") {
                    this.studyEditSlideTab();
                  } else {
                    this.goToSecondStage();
                  }
                }
              }
            }, (error: any) => {
              this.commonService.showToastMsg(error.error.message);
            });
          } else {
            this.showErrorMessages('Study');
          }
        } else {
          if (action == "Exit") {
            this.exitForm();
          } else {
            if (this.studyCreateType == "TCR") {
              this.studyEditSlideTab();
            } else {
              this.goToSecondStage();
            }
          }
        }
        break;
      case "Stage2":
        if (this.cassettesFormGroup.dirty || (this.cassettesFormGroup.value.rows.length != this.lengthCassettes)) {
          if (this.cassettesFormGroup.valid) {
            this.cassettesFormGroup.value.rows.forEach((v) => {
              if (v.rowType) delete v.rowType;
              if (v.initCassetteId) delete v.initCassetteId;
              if (v.newCassette) delete v.newCassette;
            });
            this.studyService.editCassettes(this.studyId, this.cassettesFormGroup.value.rows).subscribe((response: any) => {
              if (response.success) {
                this.uploadedCassettes = [];
                if (action == "Exit") {
                  this.commonService.showToastMsg(response.message);
                  this.exitForm();
                } else {
                  this.gotoThirdStage();
                }
              }
            }, (error) => {
              this.commonService.showToastMsg(error.error.message);
            });
          } else {
            this.showErrorMessages('Cassettes')
          }
        } else {
          if (action == "Exit") {
            this.exitForm();
          } else {
            this.gotoThirdStage();
          }
        }
        break;
      case 'Stage3':
        let formData = this.validateDosageForm();
        if (formData.isValid) {
          formData.data.forEach((v) => {
            if (v.rowType) delete v.rowType;
            if (v.newDosage) delete v.newDosage;
          });
          if (this.dosageFormGroup.dirty || (this.dosageFormGroup.value.rows.length != this.lengthDosages)) {
            this.studyService.editDosages(this.studyId, formData.data).subscribe((response: any) => {
              if (response.success) {
                this.uploadedDosages = [];
                this.commonService.showToastMsg(response.message);
                if (action == "Next") {
                  this.gotoSlidesTab();
                } else {
                  this.exitForm();
                }
              }
            }, (error) => {
              this.commonService.showToastMsg(error.error.message);
            });
          } else {
            if (action == "Next") {
              this.gotoSlidesTab();
            } else {
              this.exitForm();
            }
          }
        } else {
          this.showErrorMessages('Dosages')
        }
        break;
      case 'Stage4':
        this.exitForm();
        break;
    }
  }

  private showErrorMessages(errorMessagesFor: String) {
    if (errorMessagesFor == 'Study') {
      Object.keys(this.studyF).forEach(control => {
        if (this.studyF[control].invalid) {
          this.studyF[control].markAsTouched();
        }
      });
    } else if (errorMessagesFor == 'Cassettes') {
      let rows = [];
      Object.keys(this.cassettes['controls']).forEach(index => {
        let errorFlag = false;
        if (this.cassettes['controls'][index].invalid) {
          if (this.cassettes['controls'][index].controls.cassetteId.invalid) {
            errorFlag = true;
            this.cassettes['controls'][index].controls.cassetteId.markAsTouched();
          }
          if (this.cassettes['controls'][index].controls.stains.invalid) {
            errorFlag = true;
            this.cassettes['controls'][index].controls.stains.markAsTouched();
          }
          if (this.cassettes['controls'][index].controls.organs.invalid) {
            errorFlag = true;
            this.cassettes['controls'][index].controls.organs.markAsTouched();
          }
          if (errorFlag) {
            rows.push(parseInt(index) + 1);
          }
        }
      });
      if (rows.length > 0) {
        this.commonService.showToastMsg("Error at row(s) " + rows.join(", "));
      }
    } else {
      let rows = [];
      Object.keys(this.dosages['controls']).forEach(index => {
        let errorFlag = false;
        if (this.dosages['controls'][index].controls.dosageCode.invalid) {
          errorFlag = true;
          this.dosages['controls'][index].controls.dosageCode.markAsTouched()
        }
        if (this.dosages['controls'][index].controls.dose.invalid) {
          errorFlag = true;
          this.dosages['controls'][index].controls.dose.markAsTouched()
        }
        if (this.dosages['controls'][index].controls.dosageUnit.invalid) {
          errorFlag = true;
          this.dosages['controls'][index].controls.dosageUnit.markAsTouched()
        }
        if (this.dosages['controls'][index].controls.animalSequenceType.value == 'RANGE') {
          if (this.dosages['controls'][index].controls.animals.controls.maleStartIndex.invalid) {
            errorFlag = true;
            this.dosages['controls'][index].controls.animals.controls.maleStartIndex.markAsTouched();
          }
          if (this.dosages['controls'][index].controls.animals.controls.maleEndIndex.invalid) {
            errorFlag = true;
            this.dosages['controls'][index].controls.animals.controls.maleEndIndex.markAsTouched()
          }
          if (this.dosages['controls'][index].controls.animals.controls.femaleStartIndex.invalid) {
            errorFlag = true;
            this.dosages['controls'][index].controls.animals.controls.femaleStartIndex.markAsTouched()
          }
          if (this.dosages['controls'][index].controls.animals.controls.femaleEndIndex.invalid) {
            errorFlag = true;
            this.dosages['controls'][index].controls.animals.controls.femaleEndIndex.markAsTouched()
          }
        } else if (this.dosages['controls'][index].controls.animalSequenceType.value == 'CUSTOM') {
          if (this.dosages['controls'][index].controls.animals.controls.maleList.invalid) {
            errorFlag = true;
            this.dosages['controls'][index].controls.animals.controls.maleList.markAsTouched()
          }
          if (this.dosages['controls'][index].controls.animals.controls.femaleList.invalid) {
            errorFlag = true;
            this.dosages['controls'][index].controls.animals.controls.femaleList.markAsTouched()
          }

          //if (this.autoCreateStudy || this.showSlideTab) {
          if (this.dosages['controls'][index].controls.animals.controls.notKnownList.invalid) {
            errorFlag = true;
            this.dosages['controls'][index].controls.animals.controls.notKnownList.markAsTouched()
          }
          //}
        }
        if (errorFlag) {
          rows.push(parseInt(index) + 1);
        }
      });
      if (rows.length > 0) {
        this.commonService.showToastMsg("Error at row(s) " + rows.join(", "));
      }
    }
  }

  private validateSlidesForm() {
    var validInfo = { isValid: true };
    // if ($("#dataSlidesTableBody").find("td .slideText").length > 0) {
    //   validInfo.isValid = false;
    //   this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.SLIDE_ID"));
    // }
    $.each(this.slidesArr, (i, slideInfo) => {
      if (slideInfo.slideTextBox) {
        validInfo.isValid = false;
        // this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.SLIDE_ID"));
        this.commonService.showToastMsg(this.translate.instant(this.studyCreateType != "TCR" ? "GENERIC_MSG.STUDY.SLIDE_ID" : "GENERIC_MSG.STUDY.TCR_SLIDE_ID"));
        return false;
      }

      if (!slideInfo.imageRefNo) {
        validInfo.isValid = false;
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.IMAGE_NAME"));
        return false;
      }

      if (!slideInfo.stain) {
        validInfo.isValid = false;
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.STAIN"));
        return false;
      }

      if (!this.checkAnimalIdExist(slideInfo.animalId)) {
        validInfo.isValid = false;
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.SUBJECT_ID"));
        return false;
      }
    });

    if (!validInfo.isValid) {
      return validInfo;
    }

    // this.checkDuplicateImageName();
    // if ($("#dataSlidesTableBody tbody tr td[data-columnid='imageRefNo'][imageduplicate='Yes']").length > 0) {
    //   validInfo.isValid = false;
    //   this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.IMAGE_NAME"));
    //   return validInfo;
    // }

    if (this.filterSlidesArr("imageRefNo", false, true, false) > 0) {
      validInfo.isValid = false;
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.IMAGE_NAME"));
      return validInfo;
    }

    // this.checkDuplicateRow();
    // if ($("#dataSlidesTableBody tbody tr[rowduplicate='Yes']").length > 0) {
    if (this.filterSlidesArr('uniqCombination', false, true, false) > 0) {
      validInfo.isValid = false;
      this.commonService.showToastMsg(this.translate.instant(this.studyCreateType != "TCR" ? "GENERIC_MSG.STUDY.SLIDE_DATA_EXIST" : "GENERIC_MSG.STUDY.TCR_SLIDE_DATA_EXIST"));
    }
    return validInfo;
  }

  private validateDosageForm() {
    let isValid: boolean = false;
    let isDosageCode = false, isIndex = true, isList = true;
    let dosages = [];
    let dosagesIds = [];
    let submittedRange = [];
    this.selectedAnimalInfo = [];
    for (let i = 0; i < this.dosageFormGroup.value.rows.length; i++) {
      let dosage = this.dosageFormGroup.value.rows[i];
      if (dosage.dosageCode != null) {
        if (dosagesIds.indexOf(dosage.dosageCode) < 0) {
          isDosageCode = true;
          dosagesIds.push(dosage.dosageCode);
        } else {
          isDosageCode = false;
        }
      } else {
        isDosageCode = false;
      }
      if (isDosageCode) {
        if (dosage.animalSequenceType == "RANGE") {
          if ((dosage.animals.maleStartIndex && dosage.animals.maleEndIndex) || (dosage.animals.femaleStartIndex && dosage.animals.femaleEndIndex)) {
            if (dosage.animals.maleStartIndex && dosage.animals.maleEndIndex && parseInt(dosage.animals.maleStartIndex) >= parseInt(dosage.animals.maleEndIndex)) {
              isIndex = false;
              this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.MALE_START_GREATER_END"));
            } else if (dosage.animals.femaleStartIndex && dosage.animals.femaleEndIndex && parseInt(dosage.animals.femaleStartIndex) >= parseInt(dosage.animals.femaleEndIndex)) {
              isIndex = false;
              this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.FEMALE_START_GREATER_END"));
            } else {
              dosage.animals.maleStartIndex = dosage.animals.maleStartIndex ? dosage.animals.maleStartIndex : null;
              dosage.animals.maleEndIndex = dosage.animals.maleEndIndex ? dosage.animals.maleEndIndex : null;
              dosage.animals.femaleStartIndex = dosage.animals.femaleStartIndex ? dosage.animals.femaleStartIndex : null;
              dosage.animals.femaleEndIndex = dosage.animals.femaleEndIndex ? dosage.animals.femaleEndIndex : null;
              let maleValue = (dosage.animals.maleStartIndex || dosage.animals.maleEndIndex) ? dosage.animals.maleStartIndex + "," + dosage.animals.maleEndIndex : '';
              let femaleValue = (dosage.animals.femaleStartIndex || dosage.animals.femaleEndIndex) ? dosage.animals.femaleStartIndex + "," + dosage.animals.femaleEndIndex : '';
              isIndex = this.validateValue(maleValue, femaleValue, submittedRange, 'RANGE', "", i);
              dosage.animals.maleList = null;
              dosage.animals.femaleList = null;
              dosage.animals.notKnownList = null;
            }

          } else {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.ENTER_VALUES"));
            isIndex = false;
          }
        }
        if (dosage.animalSequenceType == "CUSTOM") {
          if ((dosage.animals.maleList || dosage.animals.femaleList || dosage.animals.notKnownList)) {
            isList = this.validateValue(dosage.animals.maleList ? dosage.animals.maleList.toString() : "", dosage.animals.femaleList ? dosage.animals.femaleList.toString() : "", submittedRange, 'CUSTOM', dosage.animals.notKnownList ? dosage.animals.notKnownList.toString() : "", i);
            dosage.animals.maleList = (typeof dosage.animals.maleList == "string") ? dosage.animals.maleList.split(',').map(el => el.trim()) : (typeof dosage.animals.maleList == "number") ? [dosage.animals.maleList] : dosage.animals.maleList;
            dosage.animals.femaleList = (typeof dosage.animals.femaleList == "string") ? dosage.animals.femaleList.split(',').map(el => el.trim()) : (typeof dosage.animals.femaleList == "number") ? [dosage.animals.femaleList] : dosage.animals.femaleList;
            dosage.animals.notKnownList = (typeof dosage.animals.notKnownList == "string") ? dosage.animals.notKnownList.split(',').map(el => el.trim()) : (typeof dosage.animals.notKnownList == "number") ? [dosage.animals.notKnownList] : dosage.animals.notKnownList;
            dosage.animals.maleList = dosage.animals.maleList ? dosage.animals.maleList : [];
            dosage.animals.femaleList = dosage.animals.femaleList ? dosage.animals.femaleList : [];
            dosage.animals.notKnownList = dosage.animals.notKnownList ? dosage.animals.notKnownList : [];
            dosage.animals.maleStartIndex = null;
            dosage.animals.maleEndIndex = null;
            dosage.animals.femaleStartIndex = null;
            dosage.animals.femaleEndIndex = null;
          } else {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.ENTER_VALUES"));
            isList = false;
          }
        }
        dosage.controlGroup = (dosage.controlGroup == "Yes") || (dosage.controlGroup == true) ? true : false;
        dosages.push(dosage);
      }
      if (!(isList && isIndex && isDosageCode)) {
        break;
      }
    }

    if (isList && isIndex && isDosageCode) {
      isValid = true;
    }
    return { "isValid": isValid, "data": dosages };
  }

  private validateValue(maleValue: String, femaleValue: String, submittedRange: any, dosageType: String, notKnownValue: String, index: any) {
    var isRangeValid = true;
    if (dosageType == 'RANGE') {
      // if (!($.isNumeric(maleValue.split(",")[0]) && $.isNumeric(maleValue.split(",")[1])) || !($.isNumeric(femaleValue.split(",")[0]) && $.isNumeric(femaleValue.split(",")[1]))) {
      //   this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.VALID_NUMBER"));
      //   return false;
      // }
      var maleStartIndex = null, maleEndIndex = null, femaleStartIndex = null, femaleEndIndex = null;
      if (maleValue) {
        if (!($.isNumeric(maleValue.split(",")[0]) && $.isNumeric(maleValue.split(",")[1]))) {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.VALID_NUMBER") + " at row " + parseInt(index + 1));
          return false;
        }
        maleStartIndex = parseInt(maleValue.split(",")[0]);
        maleEndIndex = parseInt(maleValue.split(",")[1]);
      }
      if (femaleValue) {
        if (!($.isNumeric(femaleValue.split(",")[0]) && $.isNumeric(femaleValue.split(",")[1]))) {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.VALID_NUMBER") + " at row " + parseInt(index + 1));
          return false;
        }
        femaleStartIndex = parseInt(femaleValue.split(",")[0]);
        femaleEndIndex = parseInt(femaleValue.split(",")[1]);
      }

      if (maleValue && femaleValue) {
        if (femaleStartIndex <= maleStartIndex && maleStartIndex <= femaleEndIndex) {
          isRangeValid = false;
        } else if (femaleStartIndex <= maleEndIndex && maleEndIndex <= femaleEndIndex) {
          isRangeValid = false;
        } else if (maleStartIndex <= femaleStartIndex && femaleStartIndex <= maleEndIndex) {
          isRangeValid = false;
        } else if (maleStartIndex <= femaleEndIndex && femaleEndIndex <= maleEndIndex) {
          isRangeValid = false;
        }
      } else if (maleValue && !femaleValue) {
        if (maleStartIndex > maleEndIndex) {
          isRangeValid = false;
        }

      } else if (!maleValue && femaleValue) {
        if (femaleStartIndex > femaleEndIndex) {
          isRangeValid = false;
        }
      }
      if (!isRangeValid) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.UNIQUE_VALUES") + " at row " + parseInt(index + 1));
        return false;
      }
    } else {
      //var maleList = maleValue.trim().split(',').map((currVal) => { return $.isNumeric(currVal) ? parseInt(currVal.trim()) : currVal.trim() });
      //var femaleList = femaleValue.trim().split(',').map((currVal) => { return $.isNumeric(currVal) ? parseInt(currVal.trim()) : currVal.trim() });
      var maleList = [], femaleList = [], notKnownList = [];
      if (maleValue) {
        maleList = maleValue.split(',').map((currVal) => {
          if (!currVal.trim()) {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_COMMA") + " at row " + parseInt(index + 1));
            isRangeValid = false;
          }
          return $.isNumeric(currVal) ? parseInt(currVal.trim()) : currVal.trim()
        });
      }
      if (femaleValue) {
        femaleList = femaleValue.split(',').map((currVal) => {
          if (!currVal.trim()) {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_COMMA") + " at row " + parseInt(index + 1));
            isRangeValid = false;
          }
          return $.isNumeric(currVal) ? parseInt(currVal.trim()) : currVal.trim()
        });
      }
      if (notKnownValue) {
        notKnownList = notKnownValue.split(',').map((currVal) => {
          if (!currVal.trim()) {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_COMMA") + " at row " + parseInt(index + 1));
            isRangeValid = false;
          }
          return $.isNumeric(currVal) ? parseInt(currVal.trim()) : currVal.trim()
        });
      }
      if (!isRangeValid) {
        return false;
      }

      if ((maleList.length == 0) && (femaleList.length == 0) && (notKnownList.length == 0)) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.ENTER_VALUES") + " at row " + parseInt(index + 1));
        return false;
      }

      var hasDuplicateMaleList = maleList.some((val, i) => maleList.indexOf(val) !== i);
      if (hasDuplicateMaleList) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_DUPLICATE") + " at row " + parseInt(index + 1));
        return false;
      }
      var hasDuplicateFemaleList = femaleList.some((val, i) => femaleList.indexOf(val) !== i);
      if (hasDuplicateFemaleList) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_DUPLICATE") + " at row " + parseInt(index + 1));
        return false;
      }

      var hasDuplicateNotKnownList = notKnownList.some((val, i) => notKnownList.indexOf(val) !== i);
      if (hasDuplicateNotKnownList) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_DUPLICATE") + " at row " + parseInt(index + 1));
        return false;
      }

      var hasCommonValue = maleList.some(n => femaleList.some(h => h === n));
      if (hasCommonValue) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_DUPLICATE") + " at row " + parseInt(index + 1));
        return false;
      }

      hasCommonValue = notKnownList.some(n => femaleList.some(h => h === n));
      if (hasCommonValue) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_DUPLICATE") + " at row " + parseInt(index + 1));
        return false;
      }

      hasCommonValue = notKnownList.some(n => maleList.some(h => h === n));
      if (hasCommonValue) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.REMOVE_DUPLICATE") + " at row " + parseInt(index + 1));
        return false;
      }
      isRangeValid = true;
    }

    if (isRangeValid) {
      for (let x = 0; x < submittedRange.length; x++) {
        if (dosageType == "RANGE" && submittedRange[x].type == 'CUSTOM') {
          for (let i = 0; i < submittedRange[x].value.length; i++) {
            if ($.isNumeric(submittedRange[x].value[i]) && maleStartIndex && maleEndIndex && maleStartIndex <= submittedRange[x].value[i] && submittedRange[x].value[i] <= maleEndIndex) {
              isRangeValid = false;
            } else if ($.isNumeric(submittedRange[x].value[i]) && femaleStartIndex && femaleEndIndex && femaleStartIndex <= submittedRange[x].value[i] && submittedRange[x].value[i] <= femaleEndIndex) {
              isRangeValid = false;
            }
            if (!isRangeValid) {
              break;
            }
          }
        } else if (dosageType == "CUSTOM" && submittedRange[x].type == 'RANGE') {
          for (let i = 0; i < maleList.length; i++) {
            if ($.isNumeric(maleList[i]) && submittedRange[x].value[0] <= maleList[i] && maleList[i] <= submittedRange[x].value[1]) {
              isRangeValid = false;
            }
            if (!isRangeValid) {
              break;
            }
          }
          if (isRangeValid) {
            for (let j = 0; j < femaleList.length; j++) {
              if ($.isNumeric(femaleList[j]) && submittedRange[x].value[0] <= femaleList[j] && femaleList[j] <= submittedRange[x].value[1]) {
                isRangeValid = false;
              }
              if (!isRangeValid) {
                break;
              }
            }
            if (isRangeValid) {
              for (let k = 0; k < notKnownList.length; k++) {
                if ($.isNumeric(notKnownList[k]) && submittedRange[x].value[0] <= notKnownList[k] && notKnownList[k] <= submittedRange[x].value[1]) {
                  isRangeValid = false;
                }
                if (!isRangeValid) {
                  break;
                }
              }
            }
          }
        } else if (dosageType == "RANGE" && submittedRange[x].type == 'RANGE') {
          if (maleStartIndex && submittedRange[x].value[0] <= maleStartIndex && maleStartIndex <= submittedRange[x].value[1]) {
            isRangeValid = false;
          } else if (maleEndIndex && submittedRange[x].value[0] <= maleEndIndex && maleEndIndex <= submittedRange[x].value[1]) {
            isRangeValid = false;
          } else if (femaleStartIndex && submittedRange[x].value[0] <= femaleStartIndex && femaleStartIndex <= submittedRange[x].value[1]) {
            isRangeValid = false;
          } else if (femaleEndIndex && submittedRange[x].value[0] <= femaleEndIndex && femaleEndIndex <= submittedRange[x].value[1]) {
            isRangeValid = false;
          }
        } else if (dosageType == "CUSTOM" && submittedRange[x].type == 'CUSTOM') {
          for (let i = 0; i < maleList.length; i++) {
            if (submittedRange[x].value.indexOf(maleList[i]) >= 0) {
              isRangeValid = false;
            }
            if (!isRangeValid) {
              break;
            }
          }
          if (isRangeValid) {
            for (let j = 0; j < femaleList.length; j++) {
              if (submittedRange[x].value.indexOf(femaleList[j]) >= 0) {
                isRangeValid = false;
              }
              if (!isRangeValid) {
                break;
              }
            }
            if (isRangeValid) {
              for (let k = 0; k < notKnownList.length; k++) {
                /* if ($.isNumeric(notKnownList[k]) && submittedRange[x].value[0] <= notKnownList[k] && notKnownList[k] <= submittedRange[x].value[1]) {
                  isRangeValid = false;
                } */
                if (submittedRange[x].value.indexOf(notKnownList[k]) >= 0) {
                  isRangeValid = false;
                }
                if (!isRangeValid) {
                  break;
                }
              }
            }
          }
        }

        if (!isRangeValid) {
          break;
        }
      }

      if (isRangeValid) {
        let maleValArr: any = maleValue.split(",").map((currVal) => { return $.isNumeric(currVal.trim()) ? parseInt(currVal.trim()) : currVal.trim() });
        let femaleValArr: any = femaleValue.split(",").map((currVal) => { return $.isNumeric(currVal.trim()) ? parseInt(currVal.trim()) : currVal.trim() });
        let unknownValArr: any = notKnownValue.split(",").map((currVal) => { return $.isNumeric(currVal.trim()) ? parseInt(currVal.trim()) : currVal.trim() });
        submittedRange.push({ 'type': dosageType, 'value': maleValArr });
        submittedRange.push({ 'type': dosageType, 'value': femaleValArr });
        submittedRange.push({ 'type': dosageType, 'value': unknownValArr });

        if (dosageType == "RANGE") {
          let maleValRangeArr = (maleValArr.length == 2) ? Array(maleValArr[1] - maleValArr[0] + 1).fill(null).map((_, idx) => maleValArr[0] + idx) : [];
          let femaleValRangeArr = (femaleValArr.length == 2) ? Array(femaleValArr[1] - femaleValArr[0] + 1).fill(null).map((_, idx) => femaleValArr[0] + idx) : [];

          this.selectedAnimalInfo = this.selectedAnimalInfo.concat(maleValRangeArr);
          this.selectedAnimalInfo = this.selectedAnimalInfo.concat(femaleValRangeArr);
        } else {
          this.selectedAnimalInfo = this.selectedAnimalInfo.concat(maleValArr);
          this.selectedAnimalInfo = this.selectedAnimalInfo.concat(femaleValArr);
          this.selectedAnimalInfo = this.selectedAnimalInfo.concat(unknownValArr);
        }
      } else {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.UNIQUE_VALUES") + " at row " + parseInt(index + 1));
      }
    }
    // this.selectedAnimalInfo = $.extend(true, [], submittedRange);

    return isRangeValid;
  }

  cancleStudy() {
    if (this.studyF.studyNo.valid || this.studyF.studyTitle.valid ||
      this.studyF.pathologist.valid || this.studyF.technician.valid ||
      this.studyF.species.valid) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = "40%";
      dialogConfig.data = {
        headerTitle: "Confirm",
        confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.CANCEL_STUDY"),
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes"
      };
      const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogref.afterClosed().subscribe(result => {
        if (result && result != 'btnClose') {
          this.exitForm();
        }
      });
    } else {
      this.exitForm();
    }
  }

  exitForm() {
    this.commonService.changePlace("study");
    this.router.navigate(['/dashboard/study/STUDY_DIRECTOR'])
  }

  previous(stage: String) {
    if (!this.autoCreateStudy)
      this.isStudyEdit = true;
    switch (stage) {
      case 'Stage1': this.goToFirstStage(); break;
      case 'Stage2': this.goToSecondStage(); break;
      case 'Stage3': this.gotoThirdStage(); break;
    }
  }

  goToFirstStage() {
    $(".first").removeClass("tabVisited").addClass("activeTab")
    $(".first").nextAll().removeClass("activeTab tabVisited");
    $("#second, #third, #forth").hide();
    $("#first").show();
  }

  goToSecondStage() {
    $(".second").removeClass("tabVisited").addClass("activeTab");
    $(".second").nextAll().removeClass("activeTab");
    $(".second").prevAll().removeClass("activeTab").addClass("tabVisited");
    $("#first, #third, #forth").hide();
    $("#second").show();
  }

  gotoThirdStage() {
    $(".third").removeClass("tabVisited").addClass("activeTab");
    $(".third").nextAll().removeClass("activeTab");
    $(".third").prevAll().removeClass("activeTab").addClass("tabVisited");
    $("#first, #second, #forth").hide();
    $("#third").show();
  }

  gotoForthStage() {
    $(".forth").addClass("activeTab");
    $(".forth").prevAll().removeClass("activeTab").addClass("tabVisited");
    $("#first, #second, #third").hide();
    $("#forth").show();
  }

  autoCreateDialog() {
    // const dialogConfig = new MatDialogConfig();
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = "60%";
    this.dialogConfig.data = { "showAutoCreate": true }
    const dialogref = this.dialog.open(AutoCreateDialogComponent, this.dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        // this.dialogConfig.id = 'loaderDialog';
        // this.dialogConfig.data = {
        //   'Msg': "Fetching Data From Excel..."
        // };
        // let excelDataRef = this.dialog.open(LoaderComponent, this.dialogConfig);
        // this.dialogConfig.id = null;

        this.autoCreateStudy = true;
        this.goToFirstStage();
        this.CassetteObj.rows = new Array<cassetteModel>();
        this.dosageObj.rows = new Array<DosagesModel>();
        this.studyFrom.controls.studyNo.setValue(result.studyInfo.studyNo);
        this.studyFrom.controls.studyTitle.setValue(result.studyInfo.studyTitle);
        this.studyFrom.controls.projectNo.setValue(result.studyInfo.projectNo);
        this.studyFrom.controls.accessionNo.setValue(result.studyInfo.accessionNo);
        if (result.studyInfo.studyDomain && this.studyDomains.map(e => { return e.studyDomain }).includes(result.studyInfo.studyDomain)) {
          this.studyFrom.controls.studyDomain.setValue(result.studyInfo.studyDomain);
        } else {
          this.studyFrom.controls.studyDomain.setValue('');
        }
        if (result.studyInfo.treatment && this.treatments.map(e => { return e.treatment }).includes(result.studyInfo.treatment)) {
          this.studyFrom.controls.treatment.setValue(result.studyInfo.treatment);
        } else {
          this.studyFrom.controls.treatment.setValue('');
        }
        this.studyFrom.controls.sacrifice.setValue(result.studyInfo.sacrifice);
        if (result.studyInfo.studyType && this.studyTypes.map(e => { return e.studyType }).includes(result.studyInfo.studyType)) {
          this.studyFrom.controls.studyType.setValue(result.studyInfo.studyType);
        } else {
          this.studyFrom.controls.studyType.setValue('');
        }
        if (result.studyInfo.species) {
          let indexSpecies = this.species.map(e => { return e.species.toLowerCase() }).indexOf(result.studyInfo.species.toLowerCase());
          if (indexSpecies >= 0) {
            this.studyFrom.controls.species.setValue(this.species[indexSpecies].species);
          } else {
            this.studyFrom.controls.species.setValue('');
          }
          /* this.species.forEach((specie: any) => {
            if (specie.species == result.studyInfo.species) {
              this.strains = specie.strainModels;
            }
          }); */
        } else {
          this.studyFrom.controls.species.setValue('');
        }
        /* if (result.studyInfo.strain && this.strains && this.strains.map(e => { return e.strain }).includes(result.studyInfo.strain)) {
          this.studyFrom.controls.strain.setValue(result.studyInfo.strain);
        } else {
          this.studyFrom.controls.strain.setValue('');
        } */
        this.studyFrom.controls.strain.setValue(result.studyInfo.strain);
        if (result.studyInfo.route && this.routes.map(e => { return e.route }).includes(result.studyInfo.route)) {
          this.studyFrom.controls.route.setValue(result.studyInfo.route);
        } else {
          this.studyFrom.controls.route.setValue('');
        }
        this.studyFrom.controls.fromDate.setValue(result.studyInfo.fromDate ? new Date(result.studyInfo.fromDate) : null);
        this.studyFrom.controls.toDate.setValue(result.studyInfo.toDate ? new Date(result.studyInfo.toDate) : null);

        this.studyFrom.controls.cro.setValue(result.studyInfo.cro);
        this.studyFrom.controls.testItem.setValue(result.studyInfo.testItem);

        if (result.studyInfo.pathologist && this.pathologists) {
          if (this.pathologists.map(e => { return e.userName }).includes(result.studyInfo.pathologist)) {
            this.studyFrom.controls.pathologist.setValue(this.pathologists[this.pathologists.map(e => { return e.userName }).indexOf(result.studyInfo.pathologist)].userName);
          } else if (this.pathologists.map(e => { return e.displayName }).includes(result.studyInfo.pathologist)) {
            this.studyFrom.controls.pathologist.setValue(this.pathologists[this.pathologists.map(e => { return e.displayName }).indexOf(result.studyInfo.pathologist)].userName);
          } else if (this.pathologists.map(e => { return e.firstName }).includes(result.studyInfo.pathologist)) {
            this.studyFrom.controls.pathologist.setValue(this.pathologists[this.pathologists.map(e => { return e.firstName }).indexOf(result.studyInfo.pathologist)].userName);
          } else {
            this.studyFrom.controls.pathologist.setValue('');
          }
        } else {
          this.studyFrom.controls.pathologist.setValue('');
        }
        if (result.studyInfo.peerReviewer && this.pathologists) {
          if (this.pathologists.map(e => { return e.userName }).includes(result.studyInfo.peerReviewer)) {
            this.studyFrom.controls.peerReviewer.setValue(this.pathologists[this.pathologists.map(e => { return e.userName }).indexOf(result.studyInfo.peerReviewer)].userName);
          } else if (this.pathologists.map(e => { return e.displayName }).includes(result.studyInfo.peerReviewer)) {
            this.studyFrom.controls.peerReviewer.setValue(this.pathologists[this.pathologists.map(e => { return e.displayName }).indexOf(result.studyInfo.peerReviewer)].userName);
          } else if (this.pathologists.map(e => { return e.firstName }).includes(result.studyInfo.peerReviewer)) {
            this.studyFrom.controls.peerReviewer.setValue(this.pathologists[this.pathologists.map(e => { return e.firstName }).indexOf(result.studyInfo.peerReviewer)].userName);
          } else {
            this.studyFrom.controls.peerReviewer.setValue('');
          }
        } else {
          this.studyFrom.controls.peerReviewer.setValue('');
        }
        if (result.studyInfo.primaryInvestigator && this.pathologists) {
          if (this.pathologists.map(e => { return e.userName }).includes(result.studyInfo.primaryInvestigator)) {
            this.studyFrom.controls.primaryInvestigator.setValue(this.pathologists[this.pathologists.map(e => { return e.userName }).indexOf(result.studyInfo.primaryInvestigator)].userName);
          } else if (this.pathologists.map(e => { return e.displayName }).includes(result.studyInfo.primaryInvestigator)) {
            this.studyFrom.controls.primaryInvestigator.setValue(this.pathologists[this.pathologists.map(e => { return e.displayName }).indexOf(result.studyInfo.primaryInvestigator)].userName);
          } else if (this.pathologists.map(e => { return e.firstName }).includes(result.studyInfo.primaryInvestigator)) {
            this.studyFrom.controls.primaryInvestigator.setValue(this.pathologists[this.pathologists.map(e => { return e.firstName }).indexOf(result.studyInfo.primaryInvestigator)].userName);
          } else {
            this.studyFrom.controls.primaryInvestigator.setValue('');
          }
        } else {
          this.studyFrom.controls.primaryInvestigator.setValue('');
        }
        if (result.studyInfo.technician && this.technicians) {
          if (this.technicians.map(e => { return e.userName }).includes(result.studyInfo.technician)) {
            this.studyFrom.controls.technician.setValue(this.technicians[this.technicians.map(e => { return e.userName }).indexOf(result.studyInfo.technician)].userName);
          } else if (this.technicians.map(e => { return e.displayName }).includes(result.studyInfo.technician)) {
            this.studyFrom.controls.technician.setValue(this.technicians[this.technicians.map(e => { return e.displayName }).indexOf(result.studyInfo.technician)].userName);
          } else if (this.technicians.map(e => { return e.firstName }).includes(result.studyInfo.technician)) {
            this.studyFrom.controls.technician.setValue(this.technicians[this.technicians.map(e => { return e.firstName }).indexOf(result.studyInfo.technician)].userName);
          } else {
            this.studyFrom.controls.technician.setValue('');
          }
        } else {
          this.studyFrom.controls.technician.setValue('');
        }

        this.lengthCassettes = Object.keys(result.cassetteInfo).length;
        this.counterCassette = this.lengthCassettes;
        for (var key of Object.keys(result.cassetteInfo)) {
          let missingOrgans = [], missingStain = [];
          let cm = new cassetteModel();
          // cm.cassetteId = result.cassetteInfo[key].cassetteId != 'temp' ? result.cassetteInfo[key].cassetteId : '';
          // cm.initCassetteId = result.cassetteInfo[key].cassetteId != 'temp' ? result.cassetteInfo[key].cassetteId : '';
          cm.cassetteId = result.cassetteInfo[key].cassetteId;
          cm.initCassetteId = result.cassetteInfo[key].cassetteId;
          cm.newCassette = true;
          let lowerCaseStain = (this.stainsOptions.join("_#@_").toLowerCase()).split("_#@_");
          result.cassetteInfo[key].stains.forEach(element => {
            let indexStain = lowerCaseStain.indexOf(element.toLowerCase())
            if (indexStain >= 0) {
              cm.stains.push(this.stainsOptions[indexStain]);
            } else {
              missingStain.push(element);
            }
          });

          let lowerCaseOrgans = (this.organOptions.join("_#@_").toLowerCase()).split("_#@_");
          result.cassetteInfo[key].organs.forEach(element => {
            let indexOrgan = lowerCaseOrgans.indexOf(element.toLowerCase());
            if (indexOrgan >= 0) {
              cm.organs.push(this.organOptions[indexOrgan]);
            } else {
              missingOrgans.push(element)
            }
          });
          this.fieldsToDisable.push({ stains: result.cassetteInfo[key].stains, organs: result.cassetteInfo[key].organs });

          if (missingOrgans.length > 0 && missingStain.length > 0) {
            this.commonService.showToastMsg(missingStain.join(", ") + " are missing in stains & " + missingOrgans.join(", ") + " are missing in tissue", 12000);
          } else if (missingOrgans.length > 0 && missingStain.length == 0) {
            this.commonService.showToastMsg(missingOrgans.join(", ") + " are missing in tissue", 12000);
          } else if (missingOrgans.length == 0 && missingStain.length > 0) {
            this.commonService.showToastMsg(missingStain.join(", ") + " are missing in stains ", 12000);
          }

          cm.rowType = "manuallyAdded";
          this.CassetteObj.rows.push(cm);
        }

        this.lengthDosages = Object.keys(result.dosageInfo).length;
        this.counterDosage = this.lengthDosages;
        for (var key of Object.keys(result.dosageInfo)) {
          let dm = new DosagesModel();
          dm.animals = new Animals();
          let dosageCode = result.dosageInfo[key].dosageId != 'temp' ? result.dosageInfo[key].dosageId : '';
          if (dosageCode && this.dosageCodes.includes(dosageCode)) {
            dm.dosageCode = dosageCode;
          }
          dm.animalSequenceType = 'CUSTOM';
          dm.animals.maleList = (result.dosageInfo[key].maleAnimalIds && result.dosageInfo[key].maleAnimalIds.length > 0) ? result.dosageInfo[key].maleAnimalIds.filter((ele) => { return ele != "" }) : null;
          dm.animals.femaleList = (result.dosageInfo[key].femaleAnimalIds && result.dosageInfo[key].femaleAnimalIds.length > 0) ? result.dosageInfo[key].femaleAnimalIds.filter((ele) => { return ele != "" }) : null;
          dm.animals.notKnownList = (result.dosageInfo[key].notKnownAnimalIds && result.dosageInfo[key].notKnownAnimalIds.length > 0) ? result.dosageInfo[key].notKnownAnimalIds.filter((ele) => { return ele != "" }) : null;
          dm.dose = result.dosageInfo[key].dose;
          dm.dosageUnit = result.dosageInfo[key].doseUnit
          dm.controlGroup = result.dosageInfo[key].controlGroup ? result.dosageInfo[key].controlGroup : "No";
          dm.rowType = "manuallyAdded";
          dm.newDosage = true;
          this.dosageObj.rows.push(dm);
        }
        this.cassettesFormGroup = this.formbuilder.formGroup(this.CassetteObj);
        this.dosageFormGroup = this.formbuilder.formGroup(this.dosageObj);

        this.slidesArr = [];
        this.slideIdUniqueObj = {};
        if (result.slideInfo) {
          result.slideInfo.forEach(element => {
            let isDuplicateOrBlank = false;
            if (element.slideId && element.slideId.trim()) {
              if (!this.slideIdUniqueObj[element.slideId.trim()]) {
                this.slideIdUniqueObj[element.slideId.trim()] = { isUnique: true, count: 1 };
              } else {
                this.slideIdUniqueObj[element.slideId.trim()]["isUnique"] = false;
                this.slideIdUniqueObj[element.slideId.trim()]["count"]++;
                isDuplicateOrBlank = true;
              }
            } else {
              isDuplicateOrBlank = true;
            }

            let slideIndvObj = {
              imageRefNo: element.slideName ? element.slideName : null,
              slideId: element.slideId ? element.slideId.trim() : null,
              animalId: element.animalId ? ($.isNumeric(element.animalId) ? parseInt(element.animalId) : ("" + element.animalId)) : null,
              dosageGroup: element.dosageGroup ? element.dosageGroup : null,
              sex: element.sex ? element.sex : null,
              scanMagnification: element.scanMagnification ? element.scanMagnification : null,
              scanDate: element.scanDate ? new Date(element.scanDate).getTime() : null,
              instrument: element.instrument ? element.instrument : null,
              tissueId: element.tissueId ? element.tissueId : null,
              tissueSection: element.tissueSection ? element.tissueSection.toUpperCase() : "NONE",
              comments: element.comments ? element.comments : null,
              stain: element.stain ? element.stain : null,
              IHC_ExperimentNo: element.IHC_ExperimentNo ? element.IHC_ExperimentNo : null,
              targetAntigen: element.targetAntigen ? element.targetAntigen : null,
              primaryAntibodyId: element.primaryAntibodyId ? element.primaryAntibodyId : null,
              primaryAntibodyConcentration: element.primaryAntibodyConcentration ? element.primaryAntibodyConcentration : null,
              secondaryAntibodyId: element.secondaryAntibodyId ? element.secondaryAntibodyId : null,
              detectionMethod: element.detectionMethod ? element.detectionMethod : null,
              chromogen: element.chromogen ? element.chromogen : null,
              studyNo: element.studyNo ? element.studyNo : null,
              cassetteId: element.cassetteId ? element.cassetteId : null,
              scannerName: element.scannerName ? element.scannerName : null,
              slideControl: element.slideControl ? element.slideControl : "",
              primaryAntibodyConcentrationUnit: element.primaryAntibodyConcentrationUnit ? element.primaryAntibodyConcentrationUnit : null,
              stainDate: element.stainDate ? new Date(element.stainDate).getTime() : null,
              slideTextBox: (this.studyCreateType != 'TCR') ? (!(element.slideId && this.slideIdUniqueObj[element.slideId.trim()] && this.slideIdUniqueObj[element.slideId.trim()]["isUnique"])) : (!(element.slideId && this.slideIdUniqueObj[element.slideId.trim()]))
            };
            if (this.studyCreateType != "TCR") {
              slideIndvObj['uniqCombination'] = `${('' + slideIndvObj.animalId).trim()}_${slideIndvObj.stain.trim()}_${slideIndvObj.cassetteId.trim()}`;
            } else {
              slideIndvObj['uniqCombination'] = `${slideIndvObj.slideId.trim()}_${slideIndvObj.tissueSection.trim()}`;
            }
            slideIndvObj['uniqCombination'] = slideIndvObj['uniqCombination'];//.replace(/[^a-zA-Z0-9_]/g, "");

            this.slidesArr.push(slideIndvObj);

          });
        }
        setTimeout(() => {
          if (result.loaderDialog) {
            result.loaderDialog.close();
          }
        }, 0);
      } else {
        this.exitForm();
      }
    })
  }

  navigateNext(stage) {
    switch (stage) {
      case 'Stage1':
        if (this.studyFrom.valid) {
          this.studyService.checkIfStudyNoExists(this.studyF.studyNo.value).subscribe((res: any) => {
            if (res) {
              this.commonService.showToastMsg('Study with study no ' + this.studyF.studyNo.value + ' already exists')
            } else {
              if (this.studyCreateType == 'TCR') {
                this.gotoSlidesTab();
              } else {
                this.goToSecondStage();
              }
            }
          });
        } else {
          this.showErrorMessages('Study');
        }
        break;
      case 'Stage2':
        if (this.cassettesFormGroup.valid) {
          this.gotoThirdStage();
        } else {
          this.showErrorMessages('Cassettes');
        }
        break;
      case 'Stage3':
        this.isDosageNextClicked = true;

        setTimeout(() => {
          let formData = this.validateDosageForm();
          if (formData.isValid) {
            this.gotoSlidesTab();
          } else {
            this.isDosageNextClicked = false;
            this.showErrorMessages('Dosages')
          }
        }, 100);
        break;
      case 'Stage4':
        this.isAutoCreateSaveClicked = true;
        this.slideSavingText = "Verifying...";

        setTimeout(() => {
          let slidesData = this.validateSlidesForm();
          if (slidesData.isValid) {
            this.saveAutoStudyData();
          } else {
            this.isAutoCreateSaveClicked = false;
          }
        }, 100);
        break;
    }
  }

  saveAutoStudyData() {
    this.studyFrom.value.configuration = this.configId;
    this.studyFrom.value.studyNo = this.studyFrom.value.studyNo.toString().trim();
    this.studyFrom.value.studyTitle = this.studyFrom.value.studyTitle ? this.studyFrom.value.studyTitle.trim() : this.studyFrom.value.studyTitle;
    this.studyFrom.value.projectNo = this.studyFrom.value.projectNo ? this.studyFrom.value.projectNo.trim() : this.studyFrom.value.projectNo;
    this.studyFrom.value.accessionNo = this.studyFrom.value.accessionNo ? this.studyFrom.value.accessionNo.trim() : this.studyFrom.value.accessionNo;
    this.studyFrom.value.treatment = this.studyFrom.value.treatment ? this.studyFrom.value.treatment.trim() : this.studyFrom.value.treatment;
    this.studyFrom.value.sacrifice = this.studyFrom.value.sacrifice ? this.studyFrom.value.sacrifice.trim() : this.studyFrom.value.sacrifice;
    this.studyFrom.value.fromDate = this.studyFrom.value.fromDate ? formatDate(new Date(this.studyFrom.value.fromDate), 'yyyy-MM-dd', 'en_US') : '';
    this.studyFrom.value.toDate = this.studyFrom.value.toDate ? formatDate(new Date(this.studyFrom.value.toDate), 'yyyy-MM-dd', 'en_US') : '';
    this.studyFrom.value.cro = this.studyFrom.value.cro ? this.studyFrom.value.cro.trim() : this.studyFrom.value.cro;
    this.studyFrom.value.testItem = this.studyFrom.value.testItem ? this.studyFrom.value.testItem.trim() : this.studyFrom.value.testItem;

    this.studyFrom.value.peerReviewer = this.studyFrom.value.peerReviewer ? this.studyFrom.value.peerReviewer.trim() : '';
    this.studyFrom.value.primaryInvestigator = this.studyFrom.value.primaryInvestigator ? this.studyFrom.value.primaryInvestigator.trim() : '';
    this.studyFrom.value.studyDomain = this.studyFrom.value.studyDomain ? this.studyFrom.value.studyDomain.trim() : '';
    this.studyFrom.value.studyType = this.studyFrom.value.studyType ? this.studyFrom.value.studyType.trim() : '';
    this.studyFrom.value.strain = this.studyFrom.value.strain ? this.studyFrom.value.strain.trim() : '';
    this.studyFrom.value.route = this.studyFrom.value.route ? this.studyFrom.value.route.trim() : '';

    this.studySlideMappingObj["cassetteData"] = {};
    this.cassettesFormGroup.value.rows.forEach((v) => {
      if (v.rowType) delete v.rowType;
      if (v.initCassetteId) delete v.initCassetteId;
      if (v.newCassette) delete v.newCassette;

      this.studySlideMappingObj["cassetteData"][v.cassetteId] = {
        organs: v.organs,
        stains: v.stains
      }
    });

    this.studySlideMappingObj["DosageData"] = {};
    let formData = this.validateDosageForm();
    formData.data.forEach((v) => {
      if (v.rowType) delete v.rowType;
      if (v.newDosage) delete v.newDosage;

      if (v.animalSequenceType == "CUSTOM") {
        let animalsList = ['maleList', 'femaleList', 'notKnownList'];
        for (let z = 0; z < animalsList.length; z++) {
          for (let a = 0; a < v.animals[animalsList[z]].length; a++) {
            this.studySlideMappingObj["DosageData"][v.animals[animalsList[z]][a]] = {
              controlGroup: v.controlGroup,
              dosageCode: v.dosageCode,
              dosageUnit: v.dosageUnit,
              dose: v.dose,
              sex: (z == 0) ? 'M' : ((z == 1) ? 'F' : 'N')
            }
          }
        }
      } else {
        let animalIndex = [['maleStartIndex', 'maleEndIndex'], ['femaleStartIndex', 'femaleEndIndex']];
        for (let z = 0; z < animalIndex.length; z++) {
          for (let a = v.animals[animalIndex[z][0]]; a <= v.animals[animalIndex[z][1]]; a++) {
            this.studySlideMappingObj["DosageData"][a] = {
              controlGroup: v.controlGroup,
              dosageCode: v.dosageCode,
              dosageUnit: v.dosageUnit,
              dose: v.dose,
              sex: (z == 0) ? 'M' : 'F'
            }
          }
        }
      }
    });
    let studyArr = {
      "studyDetails": this.studyFrom.value,
      "cassetteOrgansStains": this.cassettesFormGroup.value.rows,
      "studyDosages": formData.data
    };

    studyArr.studyDetails["slideUnmappedCount"] = this.slidesArr.length;

    var slidesPayload = [];
    this.slidesArr.forEach((element, index) => {
      let indvSlidesData = {
        //"id":index,
        "slideInfo": {
          "studyNo": studyArr.studyDetails.studyNo,
          "cassetteCode": element.cassetteId,
          "imageName": element.imageRefNo,
          "animalNo": element.animalId,
          "stain": element.stain,
          "slideComments": element.comments ? element.comments : '',
          "scanDate": (element.scanDate) ? this.datepipe.transform(element.scanDate, 'yyyy-MM-dd') : null,
          "slideLabel": element.slideId,
          "tissueSection": element.tissueSection ? element.tissueSection : 'NONE',
          "slideControl": element.slideControl ? element.slideControl : 'Test',
          "stainDate": (element.stainDate) ? this.datepipe.transform(element.stainDate, 'yyyy-MM-dd') : null,
        },
        "scannerInfo": {
          "scnrPk": null,
          "ip": null,
          "location": null,
          "name": null, //element.scannerName,
          "scanMagnification": element.scanMagnification,
          "instrument": element.instrument
        },
        "ihcStainInfo": {
          "id": null,
          "ihcExperimentNumber": element.IHC_ExperimentNo ? element.IHC_ExperimentNo : null,
          "targetAntigen": element.targetAntigen ? element.targetAntigen : null,
          "primaryAntibodyID": element.primaryAntibodyId ? element.primaryAntibodyId : null,
          "primaryAntibodyConcentration": element.primaryAntibodyConcentration ? element.primaryAntibodyConcentration : null,
          "primaryAntibodyConcentrationUnit": element.primaryAntibodyConcentrationUnit ? element.primaryAntibodyConcentrationUnit : null,
          "secondayAntibodyID": element.secondaryAntibodyId ? element.secondaryAntibodyId : null,
          "detectionMethod": element.detectionMethod ? element.detectionMethod : null,
          "chromogen": element.chromogen ? element.chromogen : null
        }
      }

      if (!(element.IHC_ExperimentNo || element.targetAntigen || element.primaryAntibodyId || element.primaryAntibodyConcentration || element.primaryAntibodyConcentrationUnit || element.secondaryAntibodyId || element.detectionMethod || element.chromogen)) {
        indvSlidesData.ihcStainInfo = null;
      }
      slidesPayload.push(indvSlidesData);
    });
    //console.log(studyArr);
    this.slideSavingText = "Saving...";
    this.isAutoCreateSaveClicked = true;

    // this.isAutoCreateSaveClicked = false;
    // return;
    this.studyService.autoCreateStudy(studyArr, this.studyCreateType == 'TCR').subscribe((res: any) => {
      if (res.data.success) {
        this.studySavedResponse = {
          cassetteMap: res.data.cassetteMap,
          studyGUID: res.data.guid
        };
        this.studySavedResponse['dosageSubject'] = {};
        for (let i = 0; i < res.data.dosages.length; i++) {
          res.data.dosages[i].femaleIdList.forEach(elemFemaleId => {
            this.studySavedResponse['dosageSubject'][elemFemaleId.animalNo] = elemFemaleId.animalId;
          });
          res.data.dosages[i].maleIdList.forEach(elemMaleId => {
            this.studySavedResponse['dosageSubject'][elemMaleId.animalNo] = elemMaleId.animalId;
          });
          res.data.dosages[i].notKnownIdList.forEach(elemNotKnownId => {
            this.studySavedResponse['dosageSubject'][elemNotKnownId.animalNo] = elemNotKnownId.animalId;
          });
        }
        this.studyService.uploadStudySlideCreateData(res.data.guid, slidesPayload).subscribe((resp: any) => {
          this.studySavedResponse['slidesMappedData'] = [];
          resp.data.forEach(slideItem => {
            let slidesMappedItem = {
              slideFk: slideItem.slideFk,
              studyFk: this.studySavedResponse['studyGUID'],
              cstCodeFk: this.studySavedResponse['cassetteMap'][slideItem.slideInfo.cassetteCode]["cassetteId"],
              animalFk: this.studySavedResponse['dosageSubject'][slideItem.slideInfo.animalNo],
              cstStainFk: this.studySavedResponse['cassetteMap'][slideItem.slideInfo.cassetteCode]["cassettestainMap"][slideItem.slideInfo.stain],
              cassetteId: slideItem.slideInfo.cassetteCode,
              animalNo: slideItem.slideInfo.animalNo,
              stain: slideItem.slideInfo.stain,

              dosageCode: this.studySlideMappingObj.DosageData[slideItem.slideInfo.animalNo].dosageCode,
              sex: this.studySlideMappingObj.DosageData[slideItem.slideInfo.animalNo].sex,
              organs: this.studySlideMappingObj.cassetteData[slideItem.slideInfo.cassetteCode].organs,
              targetAntigen: slideItem.ihcStainInfo ? slideItem.ihcStainInfo.targetAntigen : null,
              slideControl: slideItem.slideInfo.slideControl,
              slidename: slideItem.slideInfo.imageName,
              dosageControlGroup: this.studySlideMappingObj.DosageData[slideItem.slideInfo.animalNo].controlGroup,
              identifier: slideItem.slideInfo.identifier
            };
            this.studySavedResponse['slidesMappedData'].push(slidesMappedItem);
          });

          this.studyService.uploadStudySlideData(this.studySavedResponse['slidesMappedData']).subscribe((result: any) => {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.CREATE_SUCCESS"));
            this.uploadedDosages = [];
            this.slidesArr = [];
            this.isAutoCreateSaveClicked = false;
            this.exitForm();
          }, (err) => {
            this.isAutoCreateSaveClicked = false;
            this.exitForm();
          });
        }, (err) => {
          this.isAutoCreateSaveClicked = false;
        });
      } else {
        for (let i = 0; i < this.cassettesFormGroup.value.rows.length; i++) {
          Object.assign(this.cassettesFormGroup.value.rows[i], this.cassettesFormGroup.getRawValue().rows[i]);
        }

        for (let i = 0; i < this.dosageFormGroup.value.rows.length; i++) {
          Object.assign(this.dosageFormGroup.value.rows[i], this.dosageFormGroup.getRawValue().rows[i])
        }
        this.isAutoCreateSaveClicked = false;
        this.commonService.showToastMsg(res.data.reason);
      }
    }, (err) => {
      this.isAutoCreateSaveClicked = false;
    })
  }

  studyEditSlideTab() {
    this.isDosageNextClicked = true;
    setTimeout(() => {
      let slideArrInterval = setInterval(() => {
        if (this.slidesArr.length > 0) {
          clearInterval(slideArrInterval);
          this.gotoSlidesTab('Edit');
        }
      }, 1000)
      //this.gotoSlidesTab('Edit');
    }, 100);
  }

  gotoSlidesTab(mode: string = "create") {
    this.selectedAnimalInfo = this.selectedAnimalInfo.filter(item => item);
    if (this.studyCreateType == "TCR") {
      this.dosageFormGroup.controls.rows['controls'][0].controls.dosageCode.setValue('TCR');
      this.selectedAnimalInfo = [];

      var animalSex = this.dosageFormGroup.value.rows[0].animals;
      for (let x = 0; x < this.slidesArr.length; x++) {
        let sex = "";
        if (animalSex.femaleList && animalSex.femaleList.indexOf(this.slidesArr[x].animalId) >= 0) {
          sex = "F";
        } else if (animalSex.maleList && animalSex.maleList.indexOf(this.slidesArr[x].animalId) >= 0) {
          sex = "M";
        }
        this.slidesArr[x]['sex'] = sex;
      }
    }
    this.cassettesFormGroup.value.rows.forEach((cassetteRowInfo, index) => {
      for (let x = 0; x < this.slidesArr.length; x++) {
        if (cassetteRowInfo.initCassetteId == this.slidesArr[x].cassetteId) {
          this.slidesArr[x].cassetteId = cassetteRowInfo.cassetteId;
          if (cassetteRowInfo.stains.indexOf(this.slidesArr[x].stain) < 0) {
            this.slidesArr[x].stain = (cassetteRowInfo.stains.length == 1) ? cassetteRowInfo.stains[0] : '';
            if (!this.slidesArr[x].stain || this.slidesArr[x].stain != "IHC") {
              this.slidesArr[x].IHC_ExperimentNo = null;
              this.slidesArr[x].targetAntigen = null;
              this.slidesArr[x].primaryAntibodyId = null;
              this.slidesArr[x].primaryAntibodyConcentration = null;
              this.slidesArr[x].primaryAntibodyConcentrationUnit = null;
              this.slidesArr[x].secondaryAntibodyId = null;
              this.slidesArr[x].detectionMethod = null;
              this.slidesArr[x].chromogen = null;
            }
          }
        }
        /* if (this.studyCreateType != "TCR") {
          this.slidesArr[x]['uniqCombination'] = `${this.slidesArr[x].animalId}_${this.slidesArr[x].stain}_${this.slidesArr[x].cassetteId}`;
        } else {
          this.slidesArr[x]['uniqCombination'] = `${this.slidesArr[x].slideId}_${this.slidesArr[x].tissueSection}`;
        }
        this.slidesArr[x]['uniqCombination'] = this.slidesArr[x]['uniqCombination'].replace(/[^a-zA-Z0-9_]/g, ""); */
        if (this.studyCreateType != "TCR") {
          this.slidesArr[x]['uniqCombination'] = `${('' + this.slidesArr[x].animalId).trim()}_${this.slidesArr[x].stain.trim()}_${this.slidesArr[x].cassetteId.trim()}`;
        } else {
          this.slidesArr[x]['uniqCombination'] = `${this.slidesArr[x].slideId.trim()}_${this.slidesArr[x].tissueSection.trim()}`;
        }
        this.slidesArr[x]['uniqCombination'] = this.slidesArr[x]['uniqCombination'];//.replace(/[^a-zA-Z0-9_]/g, "");

        this.slidesArr[x]["index"] = x;
        if ((mode == 'Edit') && (index == 0)) {
          this.selectedAnimalInfo.push(this.slidesArr[x].animalId);
        } else if ((this.studyCreateType == "TCR") && (index == 0)) {
          this.selectedAnimalInfo.push(this.slidesArr[x].animalId);
        }
      }
      this.cassettesFormGroup.controls.rows['controls'][index].controls.initCassetteId.setValue(cassetteRowInfo.cassetteId);
    });

    this.slideColumnArr[3].optionData = this.selectedAnimalInfo;
    this.tempSlideArrList = this.slidesArr;

    $("#forth").css({ "opacity": "0", "display": "block" });
    setTimeout(() => {
      this.isDosageNextClicked = false;
      $(".cdkVirtualScrollView").width($("#dataSlidesTableHeader").width());
      if (this.loadColumnChooser) {
        $('#slideColumnChooser').multiSelect({
          'noneText': 'Hide Columns',// Text to show when no option is selected
          'menuMinHeight': 100
        });

        $('#slideColumnChooser').on('change.multiselect', (() => {
          var hideColumns = $("#slideColumnChooser").val();
          $('#dataSlidesTableBody td, #dataSlidesTableHeader th').show();
          var hideColumnsSelector = [];
          for (let x = 0; x < hideColumns.length; x++) {
            let thIndex = $('#dataSlidesTableHeader th').index($('#dataSlidesTableHeader th[colid="' + hideColumns[x] + '"]')) + 1;
            hideColumnsSelector.push('#dataSlidesTableHeader th:nth-child(' + thIndex + '), #dataSlidesTableBody td:nth-child(' + thIndex + ')');
          }
          if (hideColumnsSelector.length > 0) {
            let selector = hideColumnsSelector.join(", ");
            $(selector).hide();
          }
          $(".cdkVirtualScrollView").width($("#dataSlidesTableHeader").width());
        }).bind(this));
      }
      this.loadColumnChooser = false;
      this.gotoForthStage();
      $("#forth").css({ "opacity": "1" });
      // this.checkDuplicateRow();

    }, 0);
  }

  nextBatchScroll() {
    if (this.repaintAfterScroll) {
      clearTimeout(this.repaintAfterScroll);
    }
    this.repaintAfterScroll = setTimeout(() => {
      var hideColumns = $("#slideColumnChooser").val();
      $('#dataSlidesTableBody td, #dataSlidesTableHeader th').show();
      var hideColumnsSelector = [];
      for (let x = 0; x < hideColumns.length; x++) {
        let thIndex = $('#dataSlidesTableHeader th').index($('#dataSlidesTableHeader th[colid="' + hideColumns[x] + '"]')) + 1;
        hideColumnsSelector.push('#dataSlidesTableHeader th:nth-child(' + thIndex + '), #dataSlidesTableBody td:nth-child(' + thIndex + ')');
        // hideColumnsSelector.push('#dataSlidesTableHeader th:nth-child(' + hideColumns[x] + '), #dataSlidesTableBody td:nth-child(' + hideColumns[x] + ')');
      }
      if (hideColumnsSelector.length > 0) {
        let selector = hideColumnsSelector.join(", ");
        $(selector).hide();
      }
      $(".cdkVirtualScrollView").width($("#dataSlidesTableHeader").width());
      // console.log("Next: Done");
    }, 100);
    // console.log("Next: Test");
  }

  getStainsOptions(cassetteIdData) {
    let cassetteStains = this.cassettesFormGroup.value.rows.filter((itemObj) => {
      return itemObj.cassetteId == cassetteIdData;
    });

    return (cassetteStains.length > 0) ? cassetteStains[0].stains : [];
  }

  checkAnimalIdExist(animalId: any) {
    return (this.selectedAnimalInfo.indexOf(isNaN(animalId) ? animalId : parseInt(animalId)) >= 0);
  }

  onFieldchanged(event, columnId, index) {
    if (columnId && (index >= 0)) {
      var valText: any = (event.target && event.target.value) ? ("" + event.target.value).trim() : (event.value) ? ("" + event.value).trim() : "";
      if (columnId == "animalId") {
        valText = ($.isNumeric(valText) ? parseInt(valText) : valText);
      }
      if ($(event.targetElement).hasClass("datePicker")) {
        let dateVal = event.target.value;
        let pipe = new DatePipe('en-US');

        valText = pipe.transform(dateVal, 'yyyy-MM-dd');
      }
      if (columnId == 'slideId') {
        if (this.studyCreateType != "TCR") {
          if (!valText || this.slideIdUniqueObj[valText]) {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.SLIDE_ID_EXIST"));
            return;
          } else {
            this.slideIdUniqueObj[valText] = { isUnique: true, count: 1 };
            this.slidesArr[index].slideTextBox = false;
          }
        } else {
          if (!valText) {
            this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.SLIDE_ID_EXIST"));
            return;
          } else {
            this.slideIdUniqueObj[valText] = { isUnique: true, count: 1 };
            this.slidesArr[index].slideTextBox = false;
          }
        }
      }

      if (this.studyCreateType != "TCR" && columnId == 'stain' && valText != "IHC") {
        $.each(this.slideColumnArr, (i, columnInfo) => {
          if (columnInfo.isStainIHCDisable) {
            this.slidesArr[index][columnInfo.columnId] = null;
          }
        });
      }

      if (this.studyCreateType == "TCR") {
        var animalObj = this.dosageFormGroup.value.rows[0].animals;
        if (columnId == 'animalId') {
          let animalSex = this.slidesArr[index]['sex'];
          var animalArr = [];

          animalArr = animalObj[(animalSex == "M") ? "maleList" : ((animalSex == "F") ? "femaleList" : "notKnownList")];
          animalArr[animalArr.map((x, i) => [i, x]).filter(x => x[1] == this.slidesArr[index][columnId])[0][0]] = valText;
          animalObj[(animalSex == "M") ? "maleList" : ((animalSex == "F") ? "femaleList" : "notKnownList")] = animalArr;
          this.dosageFormGroup.controls.rows["controls"][0].controls.animals.setValue(animalObj);
        }

        if (columnId == "sex") {
          let animalPrevSex = this.slidesArr[index][columnId];
          let animalSex = valText;
          let animalId = this.slidesArr[index]['animalId'];

          let animalIndex = animalObj[(animalPrevSex == "M") ? "maleList" : ((animalPrevSex == "F") ? "femaleList" : "notKnownList")].indexOf(animalId);
          animalObj[(animalPrevSex == "M") ? "maleList" : ((animalPrevSex == "F") ? "femaleList" : "notKnownList")].splice(animalIndex, 1);

          if (!animalObj[(animalSex == "M") ? "maleList" : ((animalSex == "F") ? "femaleList" : "notKnownList")]) {
            animalObj[(animalSex == "M") ? "maleList" : ((animalSex == "F") ? "femaleList" : "notKnownList")] = [];
          }
          animalObj[(animalSex == "M") ? "maleList" : ((animalSex == "F") ? "femaleList" : "notKnownList")].push(animalId);
          this.dosageFormGroup.controls.rows["controls"][0].controls.animals.setValue(animalObj);
        }
      }

      this.slidesArr[index][columnId] = valText;
      /* if (this.studyCreateType != "TCR") {
        this.slidesArr[index]["uniqCombination"] = `${this.slidesArr[index].animalId}_${this.slidesArr[index].stain}_${this.slidesArr[index].cassetteId}`;
      } else {
        this.slidesArr[index]["uniqCombination"] = `${this.slidesArr[index].slideId}_${this.slidesArr[index].tissueSection}`;
      }
      this.slidesArr[index]['uniqCombination'] = this.slidesArr[index]['uniqCombination'].replace(/[^a-zA-Z0-9_]/g, ""); */

      if (this.studyCreateType != "TCR") {
        this.slidesArr[index]['uniqCombination'] = `${('' + this.slidesArr[index].animalId).trim()}_${this.slidesArr[index].stain.trim()}_${this.slidesArr[index].cassetteId.trim()}`;
      } else {
        this.slidesArr[index]['uniqCombination'] = `${this.slidesArr[index].slideId.trim()}_${this.slidesArr[index].tissueSection.trim()}`;
      }
      this.slidesArr[index]['uniqCombination'] = this.slidesArr[index]['uniqCombination'];//.replace(/[^a-zA-Z0-9_]/g, "");
    }
  }

  filterSlidesArr(columnId, isBlank, isDuplicate, loadList) {
    let filteredArr = [];
    if (isBlank && !isDuplicate) {
      filteredArr = this.slidesArr.filter((item) => {
        if (columnId == "animalId") {
          return !this.checkAnimalIdExist(item[columnId]);
        } else {
          return !item[columnId];
        }
      });
    } else {
      if (isDuplicate) {
        this.slideFilterObj[columnId] = {};
        for (let x = 0; x < this.slidesArr.length; x++) {
          if (!this.slideFilterObj[columnId][this.slidesArr[x][columnId]]) {
            this.slideFilterObj[columnId][this.slidesArr[x][columnId]] = [this.slidesArr[x]];
          } else {
            this.slideFilterObj[columnId][this.slidesArr[x][columnId]].push(this.slidesArr[x]);
          }
        }

        var objKeyArr = Object.keys(this.slideFilterObj[columnId]);
        for (let y = 0; y < objKeyArr.length; y++) {
          if (this.slideFilterObj[columnId][objKeyArr[y]].length > 1) {
            for (let z = 0; z < this.slideFilterObj[columnId][objKeyArr[y]].length; z++) {
              filteredArr.push(this.slideFilterObj[columnId][objKeyArr[y]][z])
            }
          }
        }
      }

      if (isBlank) {
        var filteredIndexArr = filteredArr.map((item) => {
          return item.index;
        })
        this.slidesArr.forEach(itemElem => {
          if (!itemElem[columnId] && filteredIndexArr.indexOf(itemElem.index) < 0) {
            filteredArr.push(itemElem);
          }
        });
      }
    }

    if (loadList) {
      this.tempSlideArrList = filteredArr;
      this.virtualScroll.scrollToIndex(0);
    } else {
      return filteredArr.length;
    }
  }

  slideViewBySelection(event) {
    if (event.value == 'blankSubjectId') {
      // $("#dataSlidesTableBody").find("tr").hide();
      // $("#dataSlidesTableBody").find("td[data-columnid='animalId'][data-val='false']").closest("tr").show();
      this.filterSlidesArr('animalId', true, false, true);
    } else if (event.value == 'blankStain') {
      // $("#dataSlidesTableBody").find("tr").hide();
      // $("#dataSlidesTableBody").find("td[data-columnid='stain'][data-fieldval='']").closest("tr").show();
      this.filterSlidesArr("stain", true, false, true);
    } else if (event.value == 'blankDuplicateImageName') {
      // $("#dataSlidesTableBody").find("tr").hide();
      // $("#dataSlidesTableBody").find("td[data-columnid='imageRefNo'][data-fieldval='']").closest("tr").show();
      // this.checkDuplicateImageName();
      // $("#dataSlidesTableBody tbody tr td[data-columnid='imageRefNo'][imageduplicate='Yes']").closest("tr").show();
      this.filterSlidesArr("imageRefNo", true, true, true);
    } else if (event.value == 'blankDuplicateSlideId' || event.value == 'blankSlideId') {
      // $("#dataSlidesTableBody").find("tr").hide();
      // $("#dataSlidesTableBody").find("td .slideText").closest("tr").show();
      this.tempSlideArrList = this.slidesArr.filter((slideItemInfo) => {
        return slideItemInfo.slideTextBox;
      })
      this.virtualScroll.scrollToIndex(0);

    } else if (event.value == 'duplicateSlideData' || event.value == 'duplicateTCRSlideData') {
      // $("#dataSlidesTableBody").find("tr").hide();
      // this.checkDuplicateRow();
      // $("#dataSlidesTableBody tbody tr[rowduplicate='Yes']").show();
      this.filterSlidesArr('uniqCombination', false, true, true);
    } else if (event.value == 'all') {
      this.tempSlideArrList = this.slidesArr;
      // $("#dataSlidesTableBody").find("tr").show();

    }

    // if ($("#dataSlidesTableBody").find("tr:visible").length == 0) {
    if (this.tempSlideArrList.length == 0) {
      // $(".noDataRow").html("No " + ($(event.currentTarget).find("option[value='" + event.value + "']").html()).toLowerCase()).show();
      $(".noDataRow").html("No " + event.source.triggerValue.toLowerCase()).show();
    } else {
      $(".noDataRow").hide();
    }
  }

  checkDuplicateImageName() {
    $("#dataSlidesTableBody tbody tr td[data-columnid='imageRefNo']").attr("imageduplicate", "No");
    $.each($("#dataSlidesTableBody tbody tr td[data-columnid='imageRefNo']"), (i, elemItem) => {
      if ($("#dataSlidesTableBody tbody tr td[data-fieldval='" + $(elemItem).data("fieldval") + "']").length > 1) {
        $("#dataSlidesTableBody tbody tr td[data-fieldval='" + $(elemItem).data("fieldval") + "']").attr("imageduplicate", "Yes");
      }
    });
  }

  checkDuplicateRow(showBG: boolean = false) {
    $("#dataSlidesTableBody tbody tr").attr("rowduplicate", "No");
    $.each($("#dataSlidesTableBody tbody tr"), (i, elemItem) => {
      if ($("#dataSlidesTableBody tbody tr[data-uniqcomb='" + $(elemItem).attr("data-uniqcomb") + "']").length > 1) {
        $("#dataSlidesTableBody tbody tr[data-uniqcomb='" + $(elemItem).attr("data-uniqcomb") + "']").attr("rowduplicate", "Yes");
      }
    });
    if (showBG) {
      $("#dataSlidesTableBody tbody tr[rowduplicate='Yes']").css("background", "#eaeaea");
    }
  }

  addSlideData() {
    this.slidesArr.push(this.slidesArr.length + 1);
  }

  deleteSlideItemData(itemIndex) {
    this.slidesArr.splice(itemIndex, 1);
  }

  cassetIdChange(event) {
    var x = 0;
  }

  onCassetteStainChange(event: any, index: number) {
    if (event.value.indexOf('Unknown') >= 0) {
      this.cassettesFormGroup.controls.rows['controls'][index].controls.stains.setValue(['Unknown']);
    }
  }

  /* removePatho(pathologist) {
    let tempPath = [];
    this.pathologists.forEach((patho: any, index: number) => {
      if (patho.email != pathologist) {
        tempPath.push(patho);
      }
    });
    this.secondaryPathologists = tempPath.map((x) => x);
    if(this.isSelectPeerReviewerChecked) {

    }
  } */
}

