<div class="page-content">
  <div class="pageheader">
    <div class="pageheadtitle">
      <h4>Users</h4>
    </div>
    <div class="float-right">
      <button type="button" class="btn-default searchbtn" (click)="showCreateUserModal('Create','')">Create</button>
    </div>
  </div>
  <div class="mdl-grid">
    <div class="row myStudybar">
      <div class="col-md-2" (click)="rerender('Active')">
        <h6 class="tabliketitle" [ngClass]="{'active': (isAciveUser == 'Active')}">Active ({{activeUserCount}})</h6>
      </div>
      <div class="col-md-2 borderbefore" (click)="rerender('Inactive')">
        <h6 class="tabliketitle" [ngClass]="{'active': (isAciveUser == 'Inactive')}">Inactive ({{inactiveUserCount}})</h6>
      </div>
      <!-- <div class="col-md-2 borderbefore" (click)="rerender('LoggedIn')">
        <h6 class="tabliketitle" [ngClass]="{'active': (isAciveUser == 'LoggedIn')}">Logged In ({{loggedInUserCount}})</h6>
      </div> -->
      <div class="col-md-4"> </div>
      <div class="col-md-4 filterinlineview marginZero">
        <!--<button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split filterdrop" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onclick="showHideFilter()" aria-label="filterdropdown">
        </button>-->
        <div class="studySearchMainDiv">
          <input type="text" class="tags" id="userSearch" placeholder="Search" />
        </div>

        <!-- <i class="fa fa-search searchicon"></i> -->
        <!--Filter Dropdown-->
      </div>
    </div>
  </div>
  <div class="row tabledata">
    <div class="table-responsive">
      <table id="user_table" #dataTable class="hover row-border">
      </table>
    </div>
  </div>
</div>
