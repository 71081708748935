import { ConsultationViewerService } from './../../consultation-viewer.service';
//import { ChatService } from './chat.service';
import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, Input } from '@angular/core';
import { CommonService } from './../../../../common.service';
import { date, time } from '@rxweb/reactive-form-validators';
import { timestamp } from 'rxjs/operators';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  @ViewChild('chatMessages') private myScrollContainer: ElementRef;
  @Input() meetingDetails: any;
  currentChatArrowState: string = "open";

  message: String = '';
  messages: any = [];
  currentUser: any = {};
  constructor(private commonService: CommonService,
    private consultationViewerService: ConsultationViewerService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      $(".chatList").height($(".chatMainDiv").height() - $(".sendMsg").height() - $(".chatTitle").height() - this.commonService.vwTOpx('1vw'));
    }, 0);
    this.consultationViewerService.messagesInfocus.subscribe((message: any) => {
      if (message) {
        this.messages.push(message);
        if (!$('.chatArea').is(':visible')) {
          $(".chatTitle").addClass("highlightChatHeader");
        }
      }
    });
  }

  getDateTime(timeStamp) {
    let currDate = new Date(timeStamp);
    let hours = currDate.getHours();
    let minutes = currDate.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    let newHours = hours ? hours : 12; // the hour '0' should be '12'
    let newMinutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = newHours + ':' + newMinutes + ' ' + ampm;
    return strTime;
    // return currDate.getHours() + ":" + currDate.getMinutes();
  }

  addChat() {
    if (!this.message || this.message.trim() == '') {
      this.message = '';
      return;
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let msgObj = {
      'meetID': this.meetingDetails.meeting_pk,
      'msg': this.message,
      'clientID': this.currentUser.userName,
      'msgUserDispName': this.currentUser.displayName,
      'msgTime': (new Date()).getTime()
    }
    // this.chatService.sendMessage(msgObj);
    this.consultationViewerService.emitEvent('chat', msgObj)
    // this.messages.push(msgObj);
    this.message = '';
  }

  showHideChatBox() {
    this.currentChatArrowState = (this.currentChatArrowState == "open") ? "close" : "open";
    $(".chatArea").toggle();
    if (this.currentChatArrowState == "close") {
      $("app-chat").height("auto");
      $("app-participant-list").height($(".mainRightBar").height() - $("app-chat").height());
    } else {
      if ($("app-participant-list").hasClass("pinUnlockedDiv")) {
        $("app-participant-list").height("calc(60vh - 3.08vw)");
      } else {
        $("app-participant-list").height("60vh");
      }
      $("app-chat").height("40vh");
      $(".chatTitle").removeClass("highlightChatHeader");
    }
    $(".chatList").height($(".chatMainDiv").height() - $(".sendMsg").height() - $(".chatTitle").height() - this.commonService.vwTOpx('1vw'));
  }

  /* ngAfterViewChecked() {
    this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      console.log(this.myScrollContainer);
      this.myScrollContainer.nativeElement.scrollBottom = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  } */
}
