import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from './../app-config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConsultationService {

  private config: any;

  private imageInViewerSource = new BehaviorSubject('');
  imageInViewer = this.imageInViewerSource.asObservable();

  selectedImageObj = {};
  returnableObj = {};

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  getParticipantsList() {
    //return this.http.get(`${this.config.apiUrl}user/roles/PATHOLOGIST/users`);
    //return this.http.get(`${this.config.dcAiradhiUrl}user/get-all`);
    return this.http.get(`${this.config.dcAiradhiUrl}user/roles/PATHOLOGIST/users`);
  }
  
  getSlidesDetail(slidesArr: any) {
    return this.http.post(`${this.config.dcAiradhiUrl}slide-image/get-all`, slidesArr);
  }

  getHostList() {
    return this.http.get(`${this.config.apiUrl}user/roles/PATHOLOGIST/users`);
  }

  setSelectImageInViewer(value) {
    this.imageInViewerSource.next(value);
  }

  /* generateSelectedImageObj(operation, slideDetails){
    if(operation == 'add'){
      if(!this.selectedImageObj[slideDetails.slidePk]){
        this.selectedImageObj[slideDetails.slidePk] = slideDetails;
      }
    } else if(operation == 'remove'){
      //this.selectedImageObj[slideDetails.slidepk] = {};
      delete this.selectedImageObj[slideDetails.slidePk];
    } else if(operation == 'removeAll'){
      this.selectedImageObj = {};
    }
  } */

  setSelectedImageValueOnDialogOpen(objValue: any) {
    this.selectedImageObj = Object.assign({}, objValue);
  }

  getSelectedImageValue() {
    this.returnableObj = Object.assign({}, this.selectedImageObj);
    //this.selectedImageObj = {};
    return this.returnableObj;
  }

  calculateDuration(startDateTime, endDateTime) {
    let diff = new Date(endDateTime).getTime() - new Date(startDateTime).getTime();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    let minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0)
      hours = hours + 24;

    return /* (hours <= 9 ? "0" : "") +  */(hours > 0 ? " " + hours + (hours == 1 ? " Hour " : " Hours "):" ") + (minutes > 0 ? /* (minutes <= 9 ? "0" : "") +  */minutes + " Minutes " : "")
  }
}
