import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './../../../common.service';
import { GalleryService } from './../../../gallery/gallery.service';
import { MeetingDialogComponent } from './../../meeting/meeting-dialog/meeting-dialog.component';
import { AppConfigService } from './../../../app-config.service';
import { ConsultationService } from './../../../consultation/consultation.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConsultationViewerService } from './../consultation-viewer.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { MeetingDetailsComponent } from '../../meeting/meeting-details/meeting-details.component';
import { MeetingSettingsComponent } from '../../meeting/meeting-settings/meeting-settings.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AuthService } from '../../../auth/auth.service';
import { ConfirmDialogComponent } from './../../../common/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { AnnotationService } from '../../../image-viewer/annotation.service';
declare const screenfull;

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  @Input() imageViewerObj: any;
  meetingDetails: any;

  imageViewer: any
  meetingImages: any = {};
  meetingImages1: any = {};
  currentTopBarArrow: string = 'close';
  osdObj: any;
  isFullScreen: boolean = screenfull.isFullscreen;
  defaultImagePath = "../../../assets/images/viewer_icon/gallery_noimg.png";
  config: any;
  networkStatusObj: any = {
    startTime: null,
    endTime: null,
    networkStatusTimer: null
  };
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.tileNetworkStatus = "false";
  }
  thumbnailUrl: any;
  selectedImgArr = [];
  isHost: boolean = false;
  isPresenter: boolean = false;
  selectedNewHost: any;
  currentSlideId: any;
  currentUser: any;
  participantObj: any = {};
  panelList: any;
  isAccessRequested: any = false;
  openCollapseIndex = 0;
  imageArr = [];
  prevSlideId: any = '';
  nextSlideId: any = '';
  isparticipantChatPanelVisibe: boolean;
  accessRequestCount: any;
  presenterOnline: any;
  hostOnline: boolean = false;
  imageViewerArr: any = [];
  isSplitView: boolean = false;
  imagesForCompare = [];
  audioStatus: boolean = true;
  isImageNotAvailable = false;
  disableCompare: boolean = false;
  currentSlides = [];
  hostId: any;
  isViewerSynch: boolean = false;

  isTopLevelTile: boolean = false;
  fineTuneMode: boolean = false;
  fineTuneEnable: boolean = false;
  currentTileLevel: number = 0;
  enableFineTune: boolean = false;

  imageProfiles: Array<any> = [];
  threeJsObj: any;
  slideData: any;
  slideId: number;
  closeSlideArr = [];

  togglePanel(panel: any) {
    this.consultationViewerService.setActiveTool(panel);
  }

  constructor(private consultationViewerService: ConsultationViewerService,
    private dialog: MatDialog,
    private consultationService: ConsultationService,
    private configService: AppConfigService,
    private galleryService: GalleryService,
    private commonService: CommonService,
    public translate: TranslateService,
    private authService: AuthService,
    private annotationService: AnnotationService,
    private router: Router
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.config = configService.config;
    this.enableFineTune = this.config.enableFineTune;
    this.thumbnailUrl = `${this.config.thumbnailUrl}`;
    this.consultationViewerService.activeTool.subscribe((value) => {
      this.panelList = value
    });
    this.consultationViewerService.fineFocusMode.subscribe((value) => {
      this.fineTuneMode = value;
    });
    this.consultationViewerService.fineFocusEnable.subscribe((value) => {
      this.fineTuneEnable = value;
    });
  }

  ngOnInit() {
    // $(window).bind('beforeunload', (eventObject) => {
    //   if(window.performance.navigation.type != window.performance.navigation.TYPE_RELOAD ) {
    //     console.log("beforeunload");
    //     this.leaveMeeting()
    //   }
    // });


    this.isSplitView = (this.imageViewerObj.length > 1);
    this.consultationViewerService.meetingDetails.subscribe((details: any) => {
      if (details) {
        this.meetingDetails = details;
        this.consultationViewerService.setImageList(this.meetingDetails.slideIds);
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let isHost = (this.currentUser?.userName == this.meetingDetails.creator);
        this.consultationViewerService.setHost(isHost);
        this.isHost = isHost;
        this.hostId = this.meetingDetails.creator;
      }
    });

    this.consultationViewerService.synchronizeImage.subscribe((value) => {
      this.isViewerSynch = value;
    });

    this.consultationViewerService.imageViewerArr.subscribe((array) => {
      if (array.length > 0) {
        this.imageViewerArr = array;
        this.imageViewerArr.length > 1 ? this.isSplitView = true : this.isSplitView = false;
        this.isSplitView = (this.imageViewerArr.length > 1);
        this.imageViewerObj = this.imageViewerArr;
      }
    });

    this.consultationViewerService.viewerInfocus.subscribe((value) => {
      this.imageViewer = this.imageViewerObj[value];
      this.imageProfiles = this.imageViewer?.schemaObj?.imageProfiles?.imageProfile;
      this.osdObj = this.imageViewer?.osdObj;
      this.threeJsObj = this.imageViewer?.threeJsObj;
      this.slideData = this.imageViewer?.slideData;
      this.slideId = this.slideData?.slide?.slidePk;

      if (this.osdObj) {
        this.osdObj.addHandler('tile-drawn', this.checkTileLevel);
      }
    });

    this.consultationViewerService.isPresenter.subscribe((val: any) => {
      this.isPresenter = val;
      if (this.currentSlideId && this.selectedImgArr.includes(this.currentSlideId.toString()) && this.isHost && !this.isPresenter) {
        this.toggle(this.currentSlideId.toString(), { checked: false });
      }
    });
    this.consultationViewerService.imageList.subscribe((val: any) => {
      this.meetingImages = {};
      this.meetingImages1 = {};
      if (val && val.length > 0) {
        this.imageArr = [];
        val.forEach(dataVal => {
          if (dataVal.slideId.length > 0) {
            this.imageArr = [...this.imageArr, ...dataVal.slideId];
            this.currentSlideId = this.imageArr[0];
            let currentSlideIndex = this.imageArr.indexOf(this.currentSlideId.toString());
            this.prevSlideId = this.imageArr[currentSlideIndex - 1];
            this.nextSlideId = this.imageArr[currentSlideIndex + 1];
            this.consultationService.getSlidesDetail(dataVal.slideId).subscribe((res: any) => {
              res.data.forEach(element => {
                if (!this.meetingImages[dataVal.userName]) {
                  this.meetingImages[dataVal.userName] = {};
                  this.meetingImages1[dataVal.userName] = {};
                }
                if (!this.meetingImages[dataVal.userName][element.slidePk]) {
                  this.meetingImages[dataVal.userName][element.slidePk] = element;
                  this.meetingImages1[dataVal.userName][element.slidePk] = element;
                }
              });
              if (!this.meetingImages[this.currentUser.userName]) {
                this.meetingImages[this.currentUser.userName] = {};
                this.meetingImages1[this.currentUser.userName] = {};
              }
            });
          } else {
            if (!this.meetingImages[this.currentUser.userName]) {
              this.meetingImages[this.currentUser.userName] = {};
              this.meetingImages1[this.currentUser.userName] = {};
            }
          }
        });
      } else {
        if (this.currentUser && !this.meetingImages[this.currentUser.userName]) {
          this.meetingImages[this.currentUser.userName] = {};
          this.meetingImages1[this.currentUser.userName] = {};
        }
      }
    });

    this.consultationViewerService.selectedImage.subscribe((value: any) => {
      if (value.length > 1) {
        this.selectedImgArr = [];
        this.currentSlides = value;
        this.selectedImgArr = this.selectedImgArr.concat(value);
      } else {
        if (value.length) {
          this.selectedImgArr = [];
          this.currentSlideId = value[0];
          this.currentSlides = value;
          this.selectedImgArr.push(this.currentSlideId);
          let currentSlideIndex = this.imageArr.indexOf(this.currentSlideId.toString());
          this.prevSlideId = this.imageArr[currentSlideIndex - 1];
          this.nextSlideId = this.imageArr[currentSlideIndex + 1];
          // if (this.selectedImgArr.includes(this.currentSlideId.toString()) && this.isHost && !this.isPresenter) {
          //   this.toggle(this.currentSlideId.toString(), { checked: false });
          // }
        }
      }
    });

    this.consultationViewerService.mute.subscribe((value: any) => {
      this.audioStatus = value;
    });

    this.consultationViewerService.participantUserList.subscribe((participantData: any) => {
      if (!$.isEmptyObject(participantData)) {
        this.participantObj = participantData;
        this.hostOnline = this.participantObj[this.hostId].isJoined;
        this.isAccessRequested = this.participantObj[this.currentUser.userName].isAccessRequested;
        if (this.participantObj[this.currentUser.userName].isExternal) {
          delete this.meetingImages[this.currentUser.userName];
          delete this.meetingImages1[this.currentUser.userName];
        }
      }
    });

    this.consultationViewerService.participantChatPanelVisibe.subscribe((value: boolean) => {
      this.isparticipantChatPanelVisibe = value;
    });

    this.consultationViewerService.accessRequestCount.subscribe((value: any) => {
      this.accessRequestCount = value;
    });

    this.consultationViewerService.presenterOnline.subscribe((value: any) => {
      this.presenterOnline = value;
    });

    this.consultationViewerService.fineFocusMode.subscribe((value) => {
      this.fineTuneMode = value;
    });

    if (!localStorage.tileNetworkStatus || localStorage.tileNetworkStatus == "false") {
      this.checkTileServerStatus();
    }

  }

  checkTileLevel = (event) => {
    this.currentTileLevel = event.tile.level;
    if (!this.fineTuneMode) {
      this.isTopLevelTile = (event.tile.level == event.tiledImage.source.maxLevel);
    }
  }

  checkTileServerStatus() {
    if (this.networkStatusObj.networkStatusTimer && (location.pathname.indexOf("consultation-viewer") < 0)) {
      localStorage.tileNetworkStatus = "false";
      clearTimeout(this.networkStatusObj.networkStatusTimer);
      return;
    }
    localStorage.tileNetworkStatus = "true";
    this.networkStatusObj.startTime = (new Date()).getTime();
    this.consultationViewerService.getTileServerStatus().subscribe((response: any) => {
      this.networkStatusObj.endTime = (new Date()).getTime();

      let timeDiff = this.networkStatusObj.endTime - this.networkStatusObj.startTime;
      let imgInternetStatusSrc = "no-connection.png";
      let internetStatusTooltip = "Server Connectivity: Unavailable";
      if (timeDiff < this.config.tileNetworkStatusRange.high) {
        imgInternetStatusSrc = "high-connection.png";
        internetStatusTooltip = "Server Connectivity: Fast";
      } else if (timeDiff < this.config.tileNetworkStatusRange.mid) {
        imgInternetStatusSrc = "mid-connection.png";
        internetStatusTooltip = "Server Connectivity: Slow";
      } else {
        imgInternetStatusSrc = "low-connection.png";
        internetStatusTooltip = "Server Connectivity: Very Slow";
      }
      $("#imgInternetStatus").attr({ "src": "../../../assets/images/viewer_icon/" + imgInternetStatusSrc, "title": internetStatusTooltip });

      this.networkStatusObj.networkStatusTimer = setTimeout(() => {
        this.checkTileServerStatus();
      }, this.config.tileNetworkStatusTime);
    }, (err: any) => {
      $("#imgInternetStatus").attr("src", "../../../assets/images/viewer_icon/no-connection.png");
      this.networkStatusObj.networkStatusTimer = setTimeout(() => {
        this.checkTileServerStatus();
      }, this.config.tileNetworkStatusTime);
    });
  }

  returnZero() {
    return 0;
  }

  groupChange(group) {
    if (this.openCollapseIndex == group) {
      this.openCollapseIndex = -1
    } else {
      this.openCollapseIndex = group;
    }
  }

  openImage(imageId) {
    if (localStorage.isViewerInitialized == "false") {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.VIEWER_NOT_INITIALISED"));
      return;
    }
    localStorage.isViewerInitialized = "false";
    localStorage.clearResponseData = "true";

    this.consultationViewerService.setSelectedImage([imageId]);
    //this.togglePanel('Select_Image');
  }
  toggleTopBar() {
    this.currentTopBarArrow = this.currentTopBarArrow === 'open' ? 'close' : 'open';
    $(".top_view_bar").animate({
      marginTop: this.currentTopBarArrow === 'close' ? "0" : "-3.55vw"
    }, "fast");
  }

  onfullScreen() {
    if (this.osdObj) {
      this.osdObj.world.update();
      this.osdObj.raiseEvent("pre-full-screen");
    }

    if (screenfull.isEnabled) {
      screenfull.off('change', this.elementFullScreenChange);
      if (!this.isFullScreen) {
        $("app-header, .sidebar").hide();
        $($("app-image-viewer").closest(".contenttab")[0]).css("width", "100%");
        screenfull.request();
        this.isFullScreen = true;
      } else {
        $("app-header, .sidebar").show();
        $($("app-image-viewer").closest(".contenttab")[0]).css("width", "93%");
        screenfull.exit();
        this.isFullScreen = false;
      }
      screenfull.on('change', this.elementFullScreenChange);
    }

    if (this.osdObj && this.osdObj.buttons) {
      this.osdObj.buttons.emulateExit();
    }
    if (this.osdObj && this.osdObj.viewport) {
      this.osdObj.viewport.applyConstraints();
    }

  }
  elementFullScreenChange = () => {
    if ($("app-header, .sidebar:hidden").length > 0 && !screenfull.isFullscreen) {
      this.onfullScreen();
      this.isFullScreen = false;
    } else {
      this.isFullScreen = screenfull.isFullscreen;
    }
    setTimeout(() => {
      /* let headerHeight = $("app-header:visible").length > 0 ? $("app-header header").height() : 0;
      $($("app-image-viewer").closest(".contenttab")[0]).height(document.documentElement.clientHeight - headerHeight); */
      let headerHeight = $("app-header").is(":visible") ? 'inherit' : '100vh';
      $($("app-image-viewer").closest(".contenttab")[0]).height(headerHeight);
    }, 75);
    if (this.osdObj) {
      this.osdObj.raiseEvent("full-screen");
    }
  };

  openMeetingDetailDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "60%";
    dialogConfig.data = {
      showHeader: true,
      meetingId: this.meetingDetails.meeting_pk
    };
    this.dialog.open(MeetingDetailsComponent, dialogConfig);
  }

  openMeetingSettingsDialog() {
    const dialogSettingsConfig = new MatDialogConfig();
    dialogSettingsConfig.autoFocus = true;
    dialogSettingsConfig.width = "50%";
    dialogSettingsConfig.height = "60%";
    dialogSettingsConfig.data = {
      showHeader: true,
      meetingId: this.meetingDetails.meeting_pk
    };
    this.dialog.open(MeetingSettingsComponent, dialogSettingsConfig);
  }

  isChecked() {
    let newImgObj = {};
    for (const objkey in this.meetingImages[this.currentUser.userName]) {
      if (this.isPresenter) {
        newImgObj[objkey] = this.meetingImages[this.currentUser.userName][objkey];
      }
    }
    return (Object.keys(newImgObj).length > 0 && (this.selectedImgArr.length === Object.keys(newImgObj).length))
  }

  isIndeterminate() {
    return (this.selectedImgArr.length > 0 && !this.isChecked());
  }

  toggleAll(event: MatCheckboxChange) {

    if (event.checked) {
      for (const objkey in this.meetingImages[this.currentUser.userName]) {
        if ((this.isPresenter && !(this.currentSlides.includes(objkey)))) {
          this.selectedImgArr.push(objkey)
        }
      }
    } else {
      this.selectedImgArr.length = this.currentSlides.length;
    }
  }

  toggle(item, event) {
    if (event.checked) {
      this.selectedImgArr.push(item);
    } else {
      const index = this.selectedImgArr.indexOf(item);
      if (index >= 0) {
        this.selectedImgArr.splice(index, 1);
      }
    }

    this.disableCompare = (this.selectedImgArr.length + this.imagesForCompare.length) > 4;
  }

  exists(item) {
    return this.selectedImgArr.indexOf(item) > -1;
  };

  deleteSelectedImages() {
    let updateObj = Object.assign({}, this.meetingImages);

    let isSame = this.isSameArray(this.selectedImgArr, this.currentSlides);
    if (isSame) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.CANNOT_DELETE_ONE_IMAGE"))
    } else {
      this.selectedImgArr.forEach(slideId => {
        if (!this.currentSlides.includes(slideId)) {
          delete updateObj[this.currentUser.userName][slideId];
        }
        /* if (updateObj[this.currentUser.userName][slideId] && !(this.currentSlideId.includes(slideId))) {
          delete updateObj[this.currentUser.userName][slideId];
        } */
      });
      if (this.selectedImgArr.length > 0) {
        this.consultationViewerService.updateMeetingSlides(this.meetingDetails.meeting_pk, { slideIds: Object.keys(updateObj[this.currentUser.userName]) }).subscribe((res: any) => {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.SLIDES_DELETE"));
          this.selectedImgArr = [];
          this.meetingImages = Object.assign(updateObj);
          this.meetingImages1 = Object.assign({}, this.meetingImages);
          let meetingArr = [];
          for (const key in this.meetingImages) {
            meetingArr.push({ 'userName': key, 'slideId': Object.keys(this.meetingImages[key]) });
          }
          this.consultationViewerService.emitEvent('imagelist-change', {
            clientID: this.currentUser.userName,
            meetID: this.meetingDetails.meeting_pk,
            slideIDs: meetingArr
          });
          this.togglePanel('Select_Image');
          // if (Object.keys(this.meetingImages).length === 0) {
          //   this.openImage('')
          // } else if (!this.meetingImages[this.currentSlideId]) {
          //   this.openImage(Object.keys(this.meetingImages)[0]);
          // } else {

          // }
        });
      }
      else {
        alert('Please select atleast one image to delete');
      }
    }
  }

  isSameArray(array1, array2) {
    return $(array1).not(array2).length === 0 && $(array2).not(array1).length === 0;
  }

  openImageFromStudy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90%";
    dialogConfig.data = {
      paramValue: 'gallery'
    }
    this.consultationService.setSelectedImageValueOnDialogOpen(this.meetingImages[this.currentUser.userName])
    this.galleryService.setSelectedAddImage(this.meetingImages[this.currentUser.userName])
    const dialogref = this.dialog.open(MeetingDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe((res: any) => {
      //this.galleryService.setSelectedAddImage({});
      if (res != 'close') {
        let images = Object.assign({}, this.consultationService.getSelectedImageValue());
        this.consultationViewerService.updateMeetingSlides(this.meetingDetails.meeting_pk, { slideIds: Object.keys(images) }).subscribe((res: any) => {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.SLIDES_ADD"));
          for (const objkey in images) {
            if (!this.meetingImages[this.currentUser.userName][objkey]) {
              this.meetingImages[this.currentUser.userName][objkey] = images[objkey];
            }
          }
          let meetingArr = [];
          for (const key in this.meetingImages) {
            meetingArr.push({ 'userName': key, 'slideId': Object.keys(this.meetingImages[key]) });
          }
          this.consultationViewerService.emitEvent('imagelist-change', {
            clientID: this.currentUser.userName,
            meetID: this.meetingDetails.meeting_pk,
            slideIDs: meetingArr
          });
          // if (!this.currentSlideId) {
          //   this.openImage(Object.keys(this.meetingImages[this.currentUser.userName])[0]);
          // } else {
          //   this.togglePanel('Select_Image')
          // }
        });
      }
    })
  }

  leaveMeeting() {
    let meetingParam = localStorage.getItem('meetingLoginParam');
    this.consultationViewerService.emitEvent('leave', {
      clientID: this.currentUser.userName,
      meetID: this.meetingDetails.meeting_pk
    });

    localStorage.removeItem('microphoneDeviceId');
    localStorage.removeItem('speakerDeviceId');
    localStorage.removeItem('rightBarPinLocked');

    if (this.participantObj[this.currentUser.userName].isExternal) {
      this.router.navigate(['/login'], { queryParams: { id: meetingParam } });
      this.authService.clearLocalStorage();
      this.authService.removeTokens();
      sessionStorage.setItem('meetingLoginParam', meetingParam);
      setTimeout(() => {
        window.location.reload();
      }, 100)
    } else {
      if (meetingParam) {
        localStorage.setItem('meetingLoginParam', '');
        localStorage.setItem('userActiveRole', 'PATHOLOGIST');
        this.commonService.changePlace("consultation");
        this.router.navigate(["/dashboard/consultation/"]);
        setTimeout(() => {
          window.location.reload();
        }, 100)
      } else {
        window.open('', '_self').close();
      }
    }
  }

  endMeeting() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      headerTitle: "End Meeting",
      confirmMsg: this.translate.instant("GENERIC_MSG.CONSULTATION.END_CURRENT_MEETING"),
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    };
    let dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        let meetingParam = localStorage.getItem('meetingLoginParam');
        this.consultationViewerService.updateMeetingStatus(this.meetingDetails.meeting_pk, { "status": "COMPLETED" }).subscribe((res: any) => {
          window.opener.postMessage(true, '*');
          this.consultationViewerService.emitEvent('end-meet', {
            clientID: this.currentUser.userName,
            meetID: this.meetingDetails.meeting_pk
          });
          if (meetingParam) {
            localStorage.setItem('meetingLoginParam', '');
            this.commonService.changePlace("consultation");
            this.router.navigate(["/dashboard/consultation/"]);
          } else {
            window.open('', '_self').close();
          }
        });
      }
    });
  }

  requestAccess() {
    this.consultationViewerService.emitEvent('request-access', {
      clientID: this.currentUser.userName,
      meetID: this.meetingDetails.meeting_pk
    });
    this.participantObj[this.currentUser.userName].isAccessRequested = true;
    this.consultationViewerService.setParticipantUserList(this.participantObj);
  }

  endAccess() {
    if (this.participantObj[this.hostId].isJoined) {
      this.consultationViewerService.emitEvent('revoke-access', {
        clientID: this.currentUser.userName,
        meetID: this.meetingDetails.meeting_pk
      });
    } else {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.HOST_NOT_JOINED_REVOKE"));
    }
  }

  previousSlide() {
    if (localStorage.isViewerInitialized == "false") {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.VIEWER_NOT_INITIALISED"));
      return;
    }
    localStorage.isViewerInitialized = "false";
    localStorage.clearResponseData = "true";
    this.consultationViewerService.setSelectedImage([this.prevSlideId]);
  }

  nextSlide() {
    if (localStorage.isViewerInitialized == "false") {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.VIEWER_NOT_INITIALISED"));
      return;
    }
    localStorage.isViewerInitialized = "false";
    localStorage.clearResponseData = "true";
    this.consultationViewerService.setSelectedImage([this.nextSlideId]);
  }

  onSearch(searchVal) {
    if (searchVal) {
      for (const userImageskey in this.meetingImages1) {
        if (!(this.meetingImages[userImageskey])) {
          this.meetingImages[userImageskey] = {};
        }
        this.meetingImages[userImageskey] = Object.keys(this.meetingImages1[userImageskey])
          .filter(elem => this.meetingImages1[userImageskey][elem].slidename.toLowerCase().includes(searchVal.toString().toLowerCase()))
          .reduce((obj, key) => {
            obj[key] = this.meetingImages1[userImageskey][key];
            return obj;
          }, {});
      }
    } else {
      this.meetingImages = Object.assign({}, this.meetingImages1);
    }
  }

  showHideChatParticipant() {
    // sessionStorage.lastZoomLevel = this.osdObj.viewport.getZoom();
    this.consultationViewerService.setparticipantChatPanelVisibeValue(!this.isparticipantChatPanelVisibe);
    this.consultationViewerService.setAccessRequestCount(0);
  }

  drawScreenshots(shape: any) {
    let screenShotParams = {
      type: shape,
      thickness: 2,
      colour: '#282828',
      drawScreenShot: true,
    };
    if (shape != "FullScreenShot") {
      this.annotationService.resetNewCreateAnnotationInfo(screenShotParams, this.osdObj, this.threeJsObj);
    } else {
      this.annotationService.createFullScreenShot(screenShotParams, this.osdObj, this.threeJsObj);
    }
    // this.imageViewer.getScreenshotObj().drawScreenShotShape(shape, this.osdObj.scalebarInstance.scalebarId);
    this.togglePanel("Screenshot");
  }

  compareImgages() {
    if (this.currentSlides.length == 4) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.COMPARE_LIMIT_REACHED"));
    } else {
      if (localStorage.isViewerInitialized == "false") {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.VIEWER_NOT_INITIALISED"));
        return;
      }
      localStorage.isViewerInitialized = "false";
      localStorage.clearResponseData = "true";
      // setTimeout(() => {
      this.togglePanel('Select_Image');
      this.consultationViewerService.setSynchronization(false);
      this.consultationViewerService.emitEvent('select-viewer', {
        meetID: this.meetingDetails.meeting_pk,
        clientID: this.currentUser.userName,
        selectedIndex: 0
      });
      this.consultationViewerService.setSelectedImage(this.selectedImgArr);
      // }, 1000);
    }
  }

  onCloseViewer(paramVal) {
    if (screenfull.isFullscreen) {
      this.onfullScreen();
    }
    this.consultationViewerService.setActiveTool("");
    this.commonService.setSelectedGroupSlides(JSON.stringify([]));
    // setTimeout(() => {
    if (paramVal == "SELECTED") {
      let index: number = -1;
      let str: string = '';
      let slideDataTobeClosed = [];
      if (this.closeSlideArr.length > 0) {
        if (this.closeSlideArr.length == this.imageViewerObj.length) {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.CLOSE_ALL_IMAGES"));
          for (let i = 0; i < 4; i++) {
            $(".viewer[index='" + i + "'] #openseadragon_" + i + "-overlaycanvas").css({ "box-shadow": "none" });
          }
          this.closeSlideArr = [];
          return;
        } else {
          slideDataTobeClosed = this.imageViewerObj.filter((res: any) => {
            // return res.imageDisplayed != this.slideId
            if (this.closeSlideArr.includes(res.imageDisplayed)) {
              return true;
            } else {
              return false;
            }
          });
        }
      } else if (this.imageViewerObj.length > 1) {
        slideDataTobeClosed = this.imageViewerObj.filter((res: any) => {
          if (res.imageDisplayed == this.slideId) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        this.onCloseViewer("ALL");
        return;
      }
      slideDataTobeClosed.forEach(element => {
        index = this.imageViewerObj.indexOf(element);
        if (index !== -1) {
          this.imageViewerObj.splice(index, 1);
        }
      });
      this.currentSlides = [];
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.IMAGES_REMOVED"));
      this.imageViewerObj.forEach((value: any) => {
        str = str + value.imageDisplayed + ',';
        this.currentSlides.push(value.imageDisplayed.toString());
      });
      this.closeSlideArr = [];
      localStorage.setItem('samples', str);
      this.commonService.closeViewerArrChanged([]);
    }
    this.consultationViewerService.focusChanged(0);
    this.consultationViewerService.setSelectedImage(this.currentSlides);
    this.consultationViewerService.setSynchronization(false);
    this.consultationViewerService.emitEvent('select-viewer', {
      meetID: this.meetingDetails.meeting_pk,
      clientID: this.currentUser.userName,
      selectedIndex: 0
    });
    this.consultationViewerService.emitEvent('image-change', {
      meetID: this.meetingDetails.meeting_pk,
      clientID: this.currentUser.userName,
      images: this.currentSlides,
      // isSynchronized: this.isSynch,
      selectedViewerId: 0
    });
    // }, 2000);
  }

  muteUnmute(value) {
    this.consultationViewerService.setMuteStatus(value);
  }

  setFineTune(status: boolean = false) {
    $(".fineTune img").toggle();
    this.fineTuneMode = status;
    let newMinPixelRatio = status ? 0.15 : (this.config.tileMinPixelRatio ? this.config.tileMinPixelRatio : 0.5);

    this.osdObj.minPixelRatio = newMinPixelRatio;
    this.osdObj.world._items[0].minPixelRatio = newMinPixelRatio;
    this.osdObj.world.update();
    this.osdObj.world.resetItems();

    this.consultationViewerService.emitEvent('enableFineTune', {
      meetID: this.meetingDetails.meeting_pk,
      clientID: this.currentUser.userName,
      fineTuneStatus: status
    });
  }

  closeCheckboxChange(event, slidePK, selectedIndex) {
    let index = this.closeSlideArr.indexOf(slidePK);
    if (index == -1) {
      $(event.currentTarget).addClass("highlight");
      $(".viewer[index='" + selectedIndex + "'] #openseadragon_" + selectedIndex + "-overlaycanvas").css({ "box-shadow": "0px 0px 5px 3px #ea8823 inset" });
      this.closeSlideArr.push(slidePK);
    } else {
      $(".viewer[index='" + selectedIndex + "'] #openseadragon_" + selectedIndex + "-overlaycanvas").css({ "box-shadow": "none" });
      $(event.currentTarget).removeClass("highlight");
      this.closeSlideArr.splice(index, 1);
    }
  }
}
