import { AppConfigService } from './../../app-config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImageViewerTopBarService {

  private config: any;

  constructor(private http: HttpClient, private configService:AppConfigService) {
    this.config = configService.config;
   }

  getTileServerStatus(){
    return this.http.get(`${this.config.tileUrl}tiles/health`);
  }

  getAllGradings() {
    return this.http.get(`${this.config.apiUrl}slide-image/data/configuration/grading`);
  }

  getTargetOrgans(slideId: number) {
    return this.http.get(`${this.config.apiUrl}study/slide-image/${slideId}/organDetails`);
  } 

  saveSlideReview (slideReviewData: any, slideId, reviewSharable) {
    return this.http.post(`${this.config.apiUrl}slide-image/${slideId}/organ-review`, slideReviewData)
  }

  getAllSlideReviews(organ, slideId, role , reviewSharable, roi) {
    return this.http.get(`${this.config.apiUrl}slide-image/${slideId}/organ-review/get?organ=${organ}&role=${role}&reviewSharable=${reviewSharable}&roi=${roi}`)
  } 

  updateSlideReview(slideReviewData: any, organReviewPk: number, slideId) {
    return this.http.put(`${this.config.apiUrl}slide-image/${slideId}/organ-review/${organReviewPk}`, slideReviewData)
  }

  getFilterData(studyId,postData){
    return this.http.post(`${this.config.apiUrl}gallery/list?study=${studyId}`, postData);
  }

  /* getObsSuggestions(organ) {
    // will change api when obs suggestion Api comes
    return this.http.get(`${config.apiUrl}study/d977548f-de3f-43de-ad74-1b3f53b591c0/targetorgans`)
  } */ 

  getAllIntensities() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/intensity`);
  }

  getAllFrequencies() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/frequency`);
  }

  getAnalysisResult(slideId: number) {
    return this.http.get(`${this.config.apiUrl}slide-image/AiravatToAiradhi?slideId=${slideId}`)
 }
}
