import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  updatedActions: {} = {};
  openModal:boolean =  false;
  constructor() { }

  setUpatedActions(actions: {}){
    this.updatedActions = actions;
  }

  getUpdatedActions(){
    return this.updatedActions
  }

 get isEmpty(){
    return !Object.keys(this.updatedActions).some(key => this.updatedActions[key]?.length != 0);
  }

  set modalIsOpen (open:boolean){
    this.openModal = open
  }

  get modalIsOpen(){
    return this.openModal
  }

  get lengthList (){
    return Object.keys(this.updatedActions).reduce((prev, next)=> {
      prev += (this.updatedActions[next] || [])?.length
      return prev;
    } , 0)
  }
}
