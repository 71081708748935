import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputError'
})
export class InputErrorPipe implements PipeTransform {

 transform(value: any, arg1): string {
        let rvalue: string = '';
        if (value !== null) {
            if(arg1 == 'email') {
                if (value['required'] == true) {
                    rvalue = 'ERROR.EMAIL.REQUIRED';
                }
                if (value['email'] == true) {
                    rvalue = 'ERROR.EMAIL.EMAIL';
                }
            } else if (arg1 == 'password') {
                if (value['required'] == true) {
                    rvalue = 'ERROR.PASSWORD.REQUIRED';
                }
            } else if (arg1 == 'newPassword') {
                if (value['required'] == true) {
                    rvalue = 'ERROR.NEWPWD.REQUIRED';
                }
                if (value['lengthSeven'] == true) {
                    rvalue = 'ERROR.NEWPWD.LENGTHSEVEN';
                }
                if (value['character'] == true) {
                    rvalue = 'ERROR.NEWPWD.CHARACTER';
                }
            } else if (arg1 == 'rePassword') {
                if (value['required'] == true) {
                    rvalue = 'ERROR.CONFIRMPWD.REQUIRED';
                }
                if(value['mustMatch'] == true){
                    rvalue = 'ERROR.CONFIRMPWD.MUSTMATCH';
                }
            }
        }
        return rvalue;
    }
}