import { LoaderComponent } from './../../common/loader/loader.component';
import { ConfirmDialogComponent } from './../../common/confirm-dialog/confirm-dialog.component';
import { AppConfigService } from './../../app-config.service';
import { AnnotationService } from './../annotation.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from "./../../common.service";
import { FormBuilder, FormGroup, FormArray, FormControl } from "@angular/forms";
import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { ImageViewerService } from '../image-viewer.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Options } from "@angular-slider/ngx-slider";
declare var $: any;

@Component({
  selector: "app-image-viewer-side-bar",
  templateUrl: "./image-viewer-side-bar.component.html",
  styleUrls: ["./image-viewer-side-bar.component.css"]
})
export class ImageViewerSideBarComponent implements OnInit {
  // @Input() imageViewerObj: any;
  @Input() noImageFlag: boolean;
  imageViewer: any;
  osdObj: any;
  threeJsObj: any;
  openseadragon: any;
  isSideBarHidden: boolean = false;
  annoboard: any;
  selectedAnnotationShape: any = "Rectangular";
  selectedAnnotationColor: any = "#00ff00";
  selectedAnnotationThickness: any = 2;
  degrees = [0, 45, 90, 135, 180, 225, 270];
  selectedValue = this.degrees[0];
  easyViewReadTypeSelected = "zigzag";
  easyViewStartFrom = ["Current Position", "Top Left"];
  easyViewStartSelected = this.easyViewStartFrom[0];
  moveDirection: any = "xpositive";
  easyviewinterval: any = "";
  containerSizeX: any;
  containerSizeY: any;
  xshift: any;
  yshift: any;
  panlimit: any;
  speedInterval: any = 1800;
  easyViewSp: any = "medium";
  sliderRangeValue: any = 1;
  easyviewpopupshow: boolean = false;
  playpsauseeasyview: boolean = false;
  isMyAnnotationClicked: boolean = true;
  isBookmarkedAnnotationChecked: boolean = false;
  isBookmarkAnnoDisabled: boolean = false;
  isSplitView: boolean = false;
  filteredData: any;
  annotationFilterForm: FormGroup;
  sideBarHideTimer: any;
  batchAnnoboard: any;
  stopBtn: boolean = false;
  currentSideBarArrow: string = 'open';
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  stopAnnotationInProgress: boolean = false;
  lastEasyViewLocation = {
    lastCenterPos: {},
    lastZoomIndex: 1,
    pauseImmediately: false,
    easyViewNextStep: false,
  };
  annotationParams = {
    isBatch: false,
    type: this.selectedAnnotationShape,
    colour: this.selectedAnnotationColor,
    thickness: this.selectedAnnotationThickness,
    currentUser: '',
    roleFk: '',
    slideId: '',
    filledOpacityLevel: null,
    canvasInfo: {
      topLeftPos: { x: null, y: null },
      topRightPos: { x: null, y: null },
      bottomLeftPos: { x: null, y: null },
      bottomRightPos: { x: null, y: null },
      xPos: 0,
      yPos: 0,
      canvasDimension: { width: null, height: null }
    }
  };
  slideId: any; // not used yet
  isSync: boolean = false;

  color: any = '';
  panelList: any;
  easyViewSpeed: number = 1;

  sliderValue: number = 1;
  sliderOptions: Options = {
    showTicks: true,
    showTicksValues: false,
    animateOnMove: true,
    stepsArray: [
      { value: 1, legend: "Low" },
      { value: 2 },
      { value: 3, legend: "Medium" },
      { value: 4 },
      { value: 5, legend: "High" }
    ]
  };

  selectedViewerSchema: any;
  maxZoomLevel: number = 40;
  selectedROI: number = 1;

  magnifysliderValue: number = 1;
  magnifysliderOptions: Options = {
    showTicks: false,
    showTicksValues: false,
    animateOnMove: true,
    floor: 1,
    ceil: this.maxZoomLevel,
    step: 1,
    getLegend: (value: number): string => {
      return value + '<p>x</p>';
    }
  };

  imageFilters: any = {
    brightness: 0,
    gamma: 1,
    contrast: 1
  }

  isReviewShareable: boolean;
  currentUser: any;
  currentPathRole: string = ""; // current pathologist role could be pathologist, additional pathologist or peer reviewer

  quantificationResult: any;
  normality: string;
  isResultLoaded: boolean = false;
  config: any;
  imageViewerObj: any = [];

  togglePanel(panel: any) {
    if (!this.panelList.Annotation_Setting.isVisible) {
      if ($('.canvasAnnotation:visible').length > 0 || $('.canvasBatchAnnotation:visible').length) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.ANNOTATION_ONPROGRESS"));
        return;
      }
    }
    this.viewerService.setActiveTool(panel);
    if (this.easyviewinterval && !this.playpsauseeasyview) {
      this.pauseeasyview(true);
    }
  }

  constructor(
    private commonService: CommonService,
    private viewerService: ImageViewerService,
    private annotationService: AnnotationService,
    private configService: AppConfigService,
    private dialog: MatDialog,
    public translate: TranslateService) {
    this.config = this.configService.config;
    this.annotationParams.filledOpacityLevel = this.config.filledAnnoOpacityLevel;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.viewerService.activeTool.subscribe((value) => {
      this.panelList = value;
    });
  }

  ngOnInit() {
    this.viewerService.loadImgViewerArrResults.subscribe(resp => {
      this.imageViewerObj = resp;
      this.isSplitView = (this.imageViewerObj.length > 1);
    });

    // this.imageViewerObj.length > 1 ? this.isSplitView = true : this.isSplitView = false;
    this.commonService.viewerInfocus.subscribe(resp => {
      this.imageViewer = this.imageViewerObj[resp];
      this.annotationParams.slideId = this.imageViewer.slideData.slide.slidePk;
      this.slideId = this.imageViewer.slideData.slide.slidePk;
      this.osdObj = this.imageViewer.osdObj;
      this.threeJsObj = this.imageViewer.threeJsObj;
      this.openseadragon = this.imageViewer.getOpenseadragon();
      this.isReviewShareable = this.imageViewer.slideData?.study?.reviewSharable;

      this.selectedViewerSchema = this.imageViewer.getViewerSchema();
      this.maxZoomLevel = this.selectedViewerSchema.imageProfiles.imageProfile[this.selectedROI].resolution;
      const newOptions: Options = Object.assign({}, this.magnifysliderOptions);
      newOptions.ceil = this.maxZoomLevel;
      this.magnifysliderOptions = newOptions;
    });

    this.viewerService.currentRole.subscribe((role: string) => {
      if (role) {
        this.currentPathRole = role;
      }
    });

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.viewerService.rotationValue.subscribe((value) => {
      this.selectedValue = value
    });

    this.viewerService.rotationValue.subscribe((value) => {
      this.selectedValue = value
    });

    if (this.osdObj) {
      this.osdObj.addHandler("canvas-drag", (a) => {
        if (this.lastEasyViewLocation.pauseImmediately) {
          this.pauseeasyview(false);
        }
      });

      this.osdObj.addHandler("zoom", (a) => {
        if (this.lastEasyViewLocation.easyViewNextStep) {
          this.lastEasyViewLocation.easyViewNextStep = false;
          return;
        }

        if (this.sliderRangeValue == this.osdObj.viewport.getZoom(true).toFixed(2)) {
          return;
        }

        if (this.lastEasyViewLocation.pauseImmediately) {
          this.pauseeasyview(false);
        }
      });
    }
    $(".tool_sidebar").height(
      $(".tool_sidebar")
        .closest(".viewercontent")
        .height() - 40
    );
    this.imageViewer.sliderRangeValue.subscribe(value => {
      this.sliderRangeValue = value;
    });

    this.viewerService.stopAnno.subscribe((value) => {
      if (value) {
        this.annotationParams.isBatch = false;
        this.stopBtn = false;
      }
    });

    setTimeout(function () {
      $('#annColor').keydown(function (e) {
        e.preventDefault();
        return false;
      });
    }, 1000)

  }

  toggleSideBar() {
    this.viewerService.setActiveTool("")
    this.currentSideBarArrow = this.currentSideBarArrow === 'open' ? 'close' : 'open';
    $(".sideBarList").animate({
      marginLeft: this.currentSideBarArrow === 'close' ? "0" : "-2.5vw"
    }, "fast");
  }

  drawAnnotation() {
    /* if (this.osdObj?.viewport.getRotation() != 0) {
      this.selectedValue = 0;
      this.rotate();
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.IMAGE-VIEWER.SETTING_TO_ZERO_DEGREE"));
    } */

    $('.disabledJoystickrotationNavigator,.disableTopBar').show();
    $('#ViewerRotate').addClass('disableFunction');
    $('#ViewerEasyView').addClass('disableFunction');
    if (!this.isSplitView) {
      this.osdObj.navigator.update(this.osdObj.viewport);
    }

    this.annotationParams.roleFk = this.currentPathRole;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.annotationParams.currentUser = currentUser.userName;
    if (this.annotationParams.isBatch) {
      this.stopBtn = true;
    }
    this.viewerService.disableHotKey(true);
    // this.viewerService.setAnnoInfoToCreate(this.annotationParams);
    this.annotationService.resetNewCreateAnnotationInfo(this.annotationParams, this.osdObj, this.threeJsObj);

    this.togglePanel("Annotation_Setting");
  }

  drawAnnotation1() {
    // var showNavigatorTiming = 0;
    if (this.osdObj?.viewport.getRotation() != 0) {
      this.selectedValue = 0;
      this.rotate();
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.IMAGE-VIEWER.SETTING_TO_ZERO_DEGREE"));
    }

    this.annoboard = this.imageViewer.getAnnoboard();
    $('.disabledJoystickrotationNavigator,.disableTopBar').show();
    $('#ViewerRotate').addClass('disableFunction');
    $('#ViewerEasyView').addClass('disableFunction');
    if (!this.isSplitView) {
      this.osdObj.navigator.update(this.osdObj.viewport);
    }

    this.osdObj.setMouseNavEnabled(false);
    let actualCanvasWidth = (this.osdObj.viewport.getZoom(true) * this.osdObj.viewport.containerSize.x);
    let actualCanvasHeight = actualCanvasWidth / this.osdObj.viewport._contentAspectRatio;

    this.annotationParams.canvasInfo.topLeftPos = this.osdObj.viewport.imageToViewerElementCoordinates(new this.openseadragon.Point(this.osdObj.viewport.getBounds()));
    this.annotationParams.canvasInfo.topRightPos = new this.openseadragon.Point(this.annotationParams.canvasInfo.topLeftPos.x + actualCanvasWidth, this.annotationParams.canvasInfo.topLeftPos.y);
    this.annotationParams.canvasInfo.bottomLeftPos = new this.openseadragon.Point(this.annotationParams.canvasInfo.topLeftPos.x, this.annotationParams.canvasInfo.topLeftPos.y + actualCanvasHeight);
    this.annotationParams.canvasInfo.bottomRightPos = new this.openseadragon.Point(this.annotationParams.canvasInfo.topLeftPos.x + actualCanvasWidth, this.annotationParams.canvasInfo.topLeftPos.y + actualCanvasHeight);

    this.annotationParams.canvasInfo.canvasDimension.width = ((this.osdObj.viewport.containerSize.x - this.annotationParams.canvasInfo.topLeftPos.x) > actualCanvasWidth) ? (actualCanvasWidth + (this.annotationParams.canvasInfo.topLeftPos.x < 0 ? this.annotationParams.canvasInfo.topLeftPos.x : 0)) : (this.osdObj.viewport.containerSize.x - this.annotationParams.canvasInfo.topLeftPos.x);
    this.annotationParams.canvasInfo.canvasDimension.height = ((this.osdObj.viewport.containerSize.y - this.annotationParams.canvasInfo.topLeftPos.y) > actualCanvasHeight) ? (actualCanvasHeight + (this.annotationParams.canvasInfo.topLeftPos.y < 0 ? this.annotationParams.canvasInfo.topLeftPos.y : 0)) : (this.osdObj.viewport.containerSize.y - this.annotationParams.canvasInfo.topLeftPos.y);

    if (this.annotationParams.canvasInfo.canvasDimension.width > this.osdObj.viewport.containerSize.x) {
      this.annotationParams.canvasInfo.canvasDimension.width = this.osdObj.viewport.containerSize.x;
    }

    if (this.annotationParams.canvasInfo.canvasDimension.height > this.osdObj.viewport.containerSize.y) {
      this.annotationParams.canvasInfo.canvasDimension.height = this.osdObj.viewport.containerSize.y;
    }

    this.annotationParams.canvasInfo.xPos = (this.annotationParams.canvasInfo.topLeftPos.x > 0) ? this.annotationParams.canvasInfo.topLeftPos.x : 0;
    this.annotationParams.canvasInfo.yPos = (this.annotationParams.canvasInfo.topLeftPos.y > 0) ? this.annotationParams.canvasInfo.topLeftPos.y : 0;

    this.annotationParams.roleFk = this.currentPathRole;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.annotationParams.currentUser = currentUser.userName;
    if (this.annotationParams.isBatch) {
      this.stopBtn = true;
      this.batchAnnoboard = this.imageViewer.getBatchAnnoboard();
      this.batchAnnoboard.drawBatchAnnotations(this.annotationParams);
    } else {
      this.annoboard.drawAnnotation(this.annotationParams);
    }
    this.togglePanel("Annotation_Setting");
  }

  stopAnnotation() {
    // this.viewerService.setStopAnno(true);
    this.annotationService.stopBatchAnno(true);
    this.annotationParams.isBatch = false;
    this.stopBtn = false;
    /* if (this.stopAnnotationInProgress) {
      this.commonService.showToastMsg("Please wait...");
      return;
    }
    this.stopAnnotationInProgress = true;

    $('.disabledJoystickrotationNavigator,.disableTopBar').hide();
      $('#ViewerRotate').removeClass('disableFunction');
      $('#ViewerEasyView').removeClass('disableFunction');
      $('#batch_editor').remove();

      $.each($("#" + this.osdObj.id).find(".annotaion"), (index: number, element: any) => {
        this.osdObj.removeOverlay(element);
        $(element).remove();
      });

      this.batchAnnoboard.hideCanvasOverlay();

      setTimeout(() => {
        // this.annotationService.showAnnotation(response.data, this.annoboard, this.osdObj, this.openseadragon);
        this.osdObj.setMouseNavEnabled(true);
        this.stopAnnotationInProgress = false;
        this.stopBtn = false;
      }, 0); */

  }

  stopAnnotation1() {
    if (this.stopAnnotationInProgress) {
      this.commonService.showToastMsg("Please wait...");
      return;
    }
    this.stopAnnotationInProgress = true;
    this.viewerService.getAllAnnotation(this.slideId, this.osdObj._sequenceIndex, this.currentPathRole, this.isReviewShareable).subscribe((response: any) => {
      $('.disabledJoystickrotationNavigator,.disableTopBar').hide();
      $('#ViewerRotate').removeClass('disableFunction');
      $('#ViewerEasyView').removeClass('disableFunction');
      $('#batch_editor').remove();

      $.each($("#" + this.osdObj.id).find(".annotaion"), (index: number, element: any) => {
        this.osdObj.removeOverlay(element);
        $(element).remove();
      });
      // this.annoboard.removeAllAnnotations();
      this.batchAnnoboard.hideCanvasOverlay();

      setTimeout(() => {
        this.annotationService.showAnnotation(response.data, this.annoboard, this.osdObj, this.openseadragon);
        this.osdObj.setMouseNavEnabled(true);
        this.stopAnnotationInProgress = false;
        this.stopBtn = false;
      }, 0);
    }, () => {
      this.commonService.showToastMsg("Failed to fetch annotations. Please try again...");
      this.stopAnnotationInProgress = false;
    });
  }

  changeAnnotationShape(type: String, shape: String) {
    if (type == "Batch") {
      this.annotationParams.isBatch = true;
    } else {
      this.annotationParams.isBatch = false;
    }
    this.annotationParams.type = shape;
  }

  changeAnnotationColor(color) {
    this.annotationParams.colour = color;
  }

  changeAnnotationThickness(thickness) {
    this.annotationParams.thickness = thickness;
  }

  //  functions for rotation starts
  private positiveModulo(number: number, modulo: number) {
    var result = number % modulo;
    if (result < 0) {
      result += modulo;
    }
    return result;
  }

  rotate() {
    this.viewerService.setRotationValue(this.selectedValue);
  }

  rotateLeft() {
    if (this.osdObj.viewport) {
      var currRotation = this.osdObj.viewport.getRotation();

      if (this.osdObj.viewport.flipped) {
        currRotation = this.positiveModulo(currRotation + 45, 360);
      } else {
        currRotation = this.positiveModulo(currRotation - 45, 360);
      }
      this.viewerService.setRotationValue(currRotation);
    }
  }

  rotateRight() {
    if (this.osdObj.viewport) {
      var currRotation = this.osdObj.viewport.getRotation();

      if (this.osdObj.viewport.flipped) {
        currRotation = this.positiveModulo(currRotation - 45, 360);
      } else {
        currRotation = this.positiveModulo(currRotation + 45, 360);
      }
      this.viewerService.setRotationValue(currRotation);
    }
  }
  //  functions for rotation ends

  flipImage() {
    this.osdObj.viewport.toggleFlip();

    if (this.osdObj.viewport.getFlip()) {
      setTimeout(() => {
        $.each($(".annotaion"), function (i, itemElem) {
          $(itemElem).css({
            "left": ($(itemElem.parentElement).width() - ($(itemElem).width() + parseFloat($(itemElem).css("left")))),
            "transform": "scale(-1, 1)"
          });
        });
      }, 50);
    }
  }


  // easy view start

  selectEVReadType(type) {
    this.easyViewReadTypeSelected = type;
  }

  changeNgxSlider(event) {
    var x = 0;
  }

  setEasyViewSpeed(event) {
    // let speed = parseInt(event.target.value);
    let speed = parseInt(event.value);
    this.easyViewSpeed = speed;

    if (this.easyviewinterval) {
      this.pauseeasyview(true, true);
    }
    this.speedInterval = 1800;
    if (speed == 1) {
      this.easyViewSp = "low";
      this.speedInterval = 1800;
    } else if (speed == 2) {
      this.easyViewSp = "low";
      this.speedInterval = 1500;
    } else if (speed == 3) {
      this.easyViewSp = "medium";
      this.speedInterval = 1200;
    } else if (speed == 4) {
      this.easyViewSp = "medium";
      this.speedInterval = 900;
    } else if (speed == 5) {
      this.easyViewSp = "high";
      this.speedInterval = 600;
    }
  }

  changeEasyViewSpeed(event) {
    clearInterval(this.easyviewinterval);
    this.easyviewinterval = null;
    this.setEasyViewSpeed(event);
    var ctx = $("#markerCanvas")[0].getContext("2d");
    this.easyviewinterval = setInterval(
      () => {
        this.moveByDirections("", ctx)
      },
      this.speedInterval
    );
  }

  starteasyview() {
    var showNavigatorTiming = 0;
    if ($(this.osdObj.navigator.element).is(":hidden")) {
      showNavigatorTiming = 600;
      this.selectedValue = 0;
      this.rotate();
    }
    setTimeout(() => {
      if (!this.isSplitView) {
        this.osdObj.navigator.update(this.osdObj.viewport);
      }
      this.playpsauseeasyview = false;
      this.containerSizeX = this.osdObj.viewport.getContainerSize().x;
      this.containerSizeY = this.osdObj.viewport.getContainerSize().y;
      if (this.easyViewStartSelected == "Top Left") {
        this.osdObj.viewport.panTo(
          this.osdObj.viewport.deltaPointsFromPixels(
            new this.openseadragon.Point(
              this.containerSizeX / 2,
              this.containerSizeY / 2
            )
          ),
          false
        );
        setTimeout(() => {
          this.starteasyviewfn();
        }, 1000);
      } else {
        this.starteasyviewfn();
      }
    }, showNavigatorTiming);
  }

  starteasyviewfn() {
    this.togglePanel("Easy_View");
    this.easyviewpopupshow = true;
    this.lastEasyViewLocation.pauseImmediately = true;

    this.osdObj.addHandler("animation", this.animationHandler);

    //Navigator Canvas
    var canvasElem = $(this.osdObj.navigator.canvas).find("canvas");
    if ($(this.osdObj.navigator.canvas).find("#markerCanvas").length == 0) {
      $(this.osdObj.navigator.canvas).append(
        $(
          "<canvas id='markerCanvas' width='" +
          $(canvasElem).attr("width") +
          "' height='" +
          $(canvasElem).attr("height") +
          "' style='position:absolute;'></canvas>"
        )
      );
    }
    var ctx = $("#markerCanvas")[0].getContext("2d");
    ctx.fillStyle = "#636363";
    ctx.globalAlpha = 0.5;

    ctx.clearRect(
      0,
      0,
      $("#markerCanvas").attr("width"),
      $("#markerCanvas").attr("height")
    );
    ctx.fillRect(
      0,
      0,
      $("#markerCanvas").attr("width"),
      $("#markerCanvas").attr("height")
    );

    if (this.easyViewSp == "low") {
      this.xshift = 100;
      this.yshift = 100;
    } else if (this.easyViewSp == "medium") {
      this.xshift = 175;
      this.yshift = 175;
    } else if (this.easyViewSp == "high") {
      this.xshift = 250;
      this.yshift = 250;
    }
    this.panlimit = 1;

    if (this.easyViewReadTypeSelected == "zigzag") {
      this.moveDirection = "right";
    } else if (this.easyViewReadTypeSelected == "upndown") {
      this.moveDirection = "bottom";
    } else if (this.easyViewReadTypeSelected == "lefttoright") {
      this.moveDirection = "right";
    } else if (this.easyViewReadTypeSelected == "upndownC") {
      this.moveDirection = "bottom";
    }
    this.easyviewinterval = setInterval(
      () => {
        this.moveByDirections("", ctx)
      },
      this.speedInterval
    );
  }

  animationHandler = () => {
    var displayRegion = this.osdObj.navigator.displayRegion;
    var context = $("#markerCanvas")[0].getContext("2d");
    context.clearRect(
      displayRegion.offsetLeft,
      displayRegion.offsetTop,
      displayRegion.offsetWidth,
      displayRegion.offsetHeight
    );
  };

  moveByDirections(direction, context) {
    var delta = direction == "backward" ? -1 : 1;
    var bounds = this.osdObj.viewport.getBoundsNoRotate();
    var constrainedBounds = this.osdObj.viewport._applyBoundaryConstraints(
      bounds
    );

    switch (this.moveDirection) {
      case "right":
        if (this.osdObj.viewport.getBounds().getTopRight().x <= 1.02) {
          this.osdObj.viewport.panBy(
            this.osdObj.viewport.deltaPointsFromPixels(
              new this.openseadragon.Point(delta * this.xshift, 0)
            )
          );
        } else {
          if (
            this.osdObj.viewport.getBounds().getBottomLeft().y >
            this.osdObj.world.getHomeBounds().height
          ) {
            this.stopeasyview();
          } else {
            this.lastEasyViewLocation.easyViewNextStep = true;
            if (this.easyViewReadTypeSelected == "zigzag") {
              this.osdObj.removeHandler("animation", this.animationHandler);
              this.osdObj.viewport.panBy(
                this.osdObj.viewport.deltaPointsFromPixels(
                  new this.openseadragon.Point(
                    0,
                    delta * (this.containerSizeY * 0.65)
                  )
                ),
                true
              );
              this.moveDirection = "left";
              this.osdObj.addHandler("animation", this.animationHandler);
            } else if (this.easyViewReadTypeSelected == "lefttoright") {
              this.osdObj.removeHandler("animation", this.animationHandler);
              this.osdObj.viewport.panTo(
                new this.openseadragon.Point(
                  0,
                  this.osdObj.viewport.pointFromPixel(
                    new this.openseadragon.Point(
                      0,
                      delta * (this.containerSizeY * 1.25)
                    )
                  ).y
                ),
                true
              );
              this.osdObj.addHandler("animation", this.animationHandler);
            }
          }
        }
        break;
      case "left":
        var topLeftX = this.osdObj.viewport.getBounds().getTopLeft().x;
        if (topLeftX >= -0.02) {
          this.osdObj.viewport.panBy(
            this.osdObj.viewport.deltaPointsFromPixels(
              new this.openseadragon.Point(-delta * this.xshift, 0)
            )
          );
        } else {
          if (
            this.osdObj.viewport.getBounds().getBottomLeft().y >
            this.osdObj.world.getHomeBounds().height
          ) {
            this.stopeasyview();
          } else {
            this.lastEasyViewLocation.easyViewNextStep = true;
            this.osdObj.removeHandler("animation", this.animationHandler);
            this.osdObj.viewport.panBy(
              this.osdObj.viewport.deltaPointsFromPixels(
                new this.openseadragon.Point(
                  0,
                  delta * (this.containerSizeY * 0.65)
                )
              ),
              true
            );
            this.moveDirection = "right";
            this.osdObj.addHandler("animation", this.animationHandler);
          }
        }
        break;
      case "top":
        if (this.osdObj.viewport.getBounds().getTopRight().y >= 0) {
          this.osdObj.viewport.panBy(
            this.osdObj.viewport.deltaPointsFromPixels(
              new this.openseadragon.Point(0, -delta * this.yshift)
            )
          );
        } else {
          if (
            this.osdObj.viewport.getBounds().getBottomRight().x > 1.02 &&
            this.osdObj.viewport.getBounds().getBottomLeft().y >
            this.osdObj.world.getHomeBounds().height
          ) {
            this.stopeasyview();
          } else {
            this.lastEasyViewLocation.easyViewNextStep = true;
            if (this.easyViewReadTypeSelected == "upndownC") {
              if (this.osdObj.viewport.getBounds().getBottomRight().x > 1.02) {
                this.stopeasyview();
              } else {
                this.osdObj.viewport.panBy(
                  this.osdObj.viewport.deltaPointsFromPixels(
                    new this.openseadragon.Point(
                      delta * (this.containerSizeX * 0.94),
                      0
                    )
                  ),
                  true
                );
                this.moveDirection = "bottom";
              }
            }
          }
        }
        break;
      case "bottom":
        if (bounds.y == constrainedBounds.y) {
          this.osdObj.viewport.panBy(
            this.osdObj.viewport.deltaPointsFromPixels(
              new this.openseadragon.Point(0, delta * this.yshift)
            )
          );
        } else {
          if (
            this.osdObj.viewport.getBounds().getBottomRight().x > 1 &&
            this.osdObj.viewport.getBounds().getBottomLeft().y >
            this.osdObj.world.getHomeBounds().height
          ) {
            this.stopeasyview();
          } else {
            this.lastEasyViewLocation.easyViewNextStep = true;
            if (this.easyViewReadTypeSelected == "upndownC") {
              this.osdObj.viewport.panBy(
                this.osdObj.viewport.deltaPointsFromPixels(
                  new this.openseadragon.Point(
                    delta * (this.containerSizeX * 0.94),
                    0
                  )
                ),
                true
              );
              this.moveDirection = "top";
            } else {
              this.osdObj.removeHandler("animation", this.animationHandler);
              this.osdObj.viewport.panTo(
                new this.openseadragon.Point(
                  this.osdObj.viewport.pointFromPixel(
                    new this.openseadragon.Point(
                      delta * (this.containerSizeX * 1.35),
                      0
                    )
                  ).x,
                  0
                ),
                true
              );
              this.moveDirection = "bottom";
              this.osdObj.addHandler("animation", this.animationHandler);
            }
          }
        }
        break;
    }
  }

  backwardeasyview() {
    if (this.easyviewinterval) {
      this.pauseeasyview(true);
    }
    var ctx = $("#markerCanvas")[0].getContext("2d");
    this.moveByDirections("backward", ctx);
  }

  fordwardeasyview() {
    if (this.easyviewinterval) {
      this.pauseeasyview(true);
    }
    var ctx = $("#markerCanvas")[0].getContext("2d");
    this.moveByDirections("", ctx);
  }

  stopeasyview() {
    var ctx = $("#markerCanvas")[0].getContext("2d");
    ctx.clearRect(
      0,
      0,
      $("#markerCanvas").attr("width"),
      $("#markerCanvas").attr("height")
    );
    this.osdObj.removeHandler("animation", this.animationHandler);
    this.easyviewpopupshow = false;
    this.playpsauseeasyview = false;
    clearInterval(this.easyviewinterval);
    this.easyviewinterval = null;
  }

  pauseeasyview(clearRect, isPlayEasyView: boolean = false) {
    if (clearRect) {
      setTimeout(() => {
        var displayRegion = this.osdObj.navigator.displayRegion;
        var ctx = $("#markerCanvas")[0].getContext("2d");
        ctx.clearRect(
          displayRegion.offsetLeft,
          displayRegion.offsetTop,
          displayRegion.offsetWidth,
          displayRegion.offsetHeight
        );

        if (isPlayEasyView) {
          this.playeasyview();
        }
      }, (this.speedInterval + 100));
    }

    this.lastEasyViewLocation = {
      lastCenterPos: this.osdObj.viewport.getCenter(true),
      lastZoomIndex: this.osdObj.viewport.getZoom(true),
      pauseImmediately: false,
      easyViewNextStep: false
    };
    this.osdObj.removeHandler("animation", this.animationHandler);
    this.playpsauseeasyview = true;
    clearInterval(this.easyviewinterval);
    this.easyviewinterval = null;
  }

  playeasyview() {
    if (this.lastEasyViewLocation.lastZoomIndex) {
      this.imageViewer.zoomToIndex("number", this.lastEasyViewLocation.lastZoomIndex, true);
    }
    if (this.lastEasyViewLocation.lastCenterPos) {
      this.osdObj.viewport.panTo(this.lastEasyViewLocation.lastCenterPos, true);
    }
    this.playpsauseeasyview = false;
    var ctx = $("#markerCanvas")[0].getContext("2d");
    setTimeout(() => {
      this.lastEasyViewLocation.pauseImmediately = true;
    }, (this.speedInterval + 100));
    this.osdObj.addHandler("animation", this.animationHandler);
    this.easyviewinterval = setInterval(() => {
      this.moveByDirections("", ctx);
    }, this.speedInterval);
  }

  zoomToIndex = text => {
    this.imageViewer.zoomToIndex(text, this.sliderRangeValue);
  };

  resetEasyView() {
    $('#EVType').click();
    this.easyViewStartSelected = this.easyViewStartFrom[0];
    $('#eVSpeedmedium').click();
  }
  // easy view end

  synchronizeImage() {
    if (!this.isSync) {
      this.dialogConfig.width = "auto";
      this.dialogConfig.height = "auto";
      this.dialogConfig.data = {
        headerTitle: "Image Sync",
        confirmMsg: this.translate.instant("GENERIC_MSG.VIEWER.START_IMAGE_SYNC"),
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      };
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isSync = true;
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.IMAGE-VIEWER.SYNC_ON"));
        } else {//incase of cancel
          this.isSync = false;
        }
        this.commonService.setSynchronization(this.isSync);
      });
    } else {
      this.isSync = false;
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.IMAGE-VIEWER.SYNC_OFF"));
      this.commonService.setSynchronization(this.isSync);
    }
  }

  setImageAdjustment() {
    this.osdObj.setFilterOptions({
      filters: {
        processors: [
          this.openseadragon.Filters.BRIGHTNESS(parseInt(this.imageFilters.brightness)),
          this.openseadragon.Filters.CONTRAST(this.imageFilters.contrast),
          this.openseadragon.Filters.GAMMA(this.imageFilters.gamma),
        ]
      },
      loadMode: 'sync'
    });
  }

  resetFilters() {
    this.imageFilters.brightness = 0;
    this.imageFilters.contrast = 1;
    this.imageFilters.gamma = 1;
    this.setImageAdjustment();
  }

  getAnalysisResult() {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    this.dialogConfig.disableClose = true;
    this.dialogConfig.data = {
      'Msg': "Importing Airavat Results ... Please Wait..."
    };
    this.dialogRef = this.dialog.open(LoaderComponent, this.dialogConfig);
    this.viewerService.getAnalysisResult(this.slideId).subscribe((response: any) => {
      if (response) {
        this.quantificationResult = response.quantificationResult;
        this.viewerService.setquantificationResults(this.quantificationResult);
        response.data.forEach((annotation: any) => {
          annotation.points.push(annotation.points[0]);
        });
        this.viewerService.setImportedAnnoData(response.data);
      } else {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.CHECK_JSON_FORMAT"));
      }
      //this.annotationService.showAnnotation(response.data, this.imageViewer.getAnnoboard(), this.osdObj, OpenSeadragon);
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);

      this.isResultLoaded = true;
    }, (error: any) => {
      this.dialogRef.close();
    });
  }
  /* setmagnification(event) {
    console.log(event.target);
    // this.sliderRangeValue = event.target.value;
  } */
}
