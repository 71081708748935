<div class="modal-header dialog-header">
  <div class="headerTitle">
    <div class="dialog-title">{{showData.headerTitle}}</div>
  </div>
  <div class="headerIcon float-right">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>
<div class="container dialog-container modal-body">
  <p class="sessionMsg">{{showData.confirmMsg}}</p>
  <form [formGroup]="loginForm" (ngSubmit)="onLogin()" >
    <mat-form-field class="fullwidth gselect bgremove">
      <mat-label class="labelbg">Email</mat-label>
      <input matInput formControlName="email" id="exampleInputEmail1" aria-describedby="emailHelp">
    </mat-form-field>
    <div *ngIf="submitted && f.email.errors">
      <p class="errormsgs">{{f.email.errors | inputError:'email' | translate}}</p>
    </div>
    <mat-form-field class="fullwidth gselect bgremove">
      <mat-label class="labelbg">Password</mat-label>
      <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
    <div *ngIf="submitted && f.password.errors">
      <p class="errormsgs">{{f.password.errors | inputError:'password' | translate}}</p>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="loginServerError">
      {{loginServerError}}
    </div>
    <div class="actions dialog-actions modal-footer">
      <button type="button" class="btn-default" (click)="closeDialog()">Cancel</button>
      <button  class="btn-default searchbtn">Login</button>
    </div>
  </form>
</div>