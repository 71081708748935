<!-- note: some icons will be changed later -->
<div id="sideBar" class="tool_sidebar" *ngIf="!isSideBarHidden">
  <div class="sidebarMainDiv">
    <div class="sideBarList">
      <div *ngIf="!isPresenter" class="disabledSidebar"></div>
      <ul>
        <!-- <li data-toggle="tooltip" title="Move">
          <img src="../../../assets/images/viewer_icon/Move tool Icon - Primary.svg" alt="Move" aria-label="Move" />
        </li> -->
        <li data-toggle="tooltip" title="Image Quality Adjustment" id="imageAdjustTab"
          [ngClass]="{'img-viewer-icon-selected': panelList.Image_Filter.isVisible}">
          <div class="tabordion_sidebar">
            <section id="section_1">
              <input type="radio" name="sections" id="option_1">
              <label for="option_1" (click)="togglePanel('Image_Filter')">
                <img src="../../../assets/images/viewer_icon/Image Filter-Primary.svg" alt="Color Adjustment"
                  aria-label="Image Adjustment" />

              </label>
              <article *ngIf="panelList.Image_Filter.isVisible">
                <div class="row divsidepop_header">
                  <div class="col-md-10">
                    <p>Image Quality Adjustment</p>
                  </div>
                  <div class="col-md-2" (click)="togglePanel('Image_Filter')">
                    <i class="fa fa-times rightfloat"></i>
                  </div>
                </div>
                <div class="filterContent padding_tabs">
                  <div class="gammaPanel ">
                    <label>Gamma Correction</label>
                    <!--Range Slide Link: https://jsfiddle.net/jaggedsoftware/gqp9L0z5/-->
                    <div class="clearfix brightnessPanel">
                      <div class="float-left">
                        <img class="gammaicon" src="assets/images/viewer_icon/gamma-icon-29.jpg" alt="Gamma" />
                      </div>
                      <div class="float-right" style="width: 15vw;">
                        <div class="range-slider">
                          <input id="osd-filter-gamma" class="input-range" type="range" value="1" min="0" max="5"
                            step="0.1" [(ngModel)]="filters.gamma" (click)="setFilters()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="brightnessContrastPanel">
                    <label>Brightness</label>
                    <div class="clearfix brightnessPanel">
                      <div class="float-left">
                        <img class="brightnessIcon" src="assets/images/viewer_icon/Brightness-Primary.svg"
                          alt="Brightness" />
                      </div>
                      <div class="float-right" style="width: 15vw;">
                        <!--Range Slide Link: https://jsfiddle.net/jaggedsoftware/gqp9L0z5/-->
                        <div class="range-slider">
                          <input id="osd-filter-contrast" class="input-range" type="range" value="0" min="-255"
                            max="255" step="1" [(ngModel)]="filters.brightness" (change)="setFilters()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix brightnessContrastPanel">
                    <label> Contrast</label>
                    <div class="clearfix contrastPanel">
                      <div class="float-left">
                        <img class="contrastIcon" src="assets/images/viewer_icon/Contrast-Primary.svg" alt="Contrast" />
                      </div>
                      <div class="float-right" style="width: 15vw;">
                        <!--Range Slide Link: https://jsfiddle.net/jaggedsoftware/gqp9L0z5/-->
                        <div class="range-slider">
                          <input id="osd-filter-contrast" class="input-range" type="range" value="1" min="0" max="5"
                            step="0.1" [(ngModel)]="filters.contrast" (change)="setFilters()">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row filterbtn">
                  <div class="col-sm-3 col-md-3"></div>
                  <div class="col-sm-9 col-md-9 filterbtn">
                    <button id="resetFilters" type="button" class="btn searchbtn" aria-label="searchbtn"
                      (click)="resetFilters()">Reset</button>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </li>

        <li data-toggle="tooltip" title="Annotation Setting"
          [ngClass]="{'img-viewer-icon-selected': activeTool == 'Annotation_Setting'}">
          <div class="tabordion_sidebar">
            <section id="section_2">
              <input type="radio" name="sections" id="option_2" checked>
              <label for="option_2" (click)="togglePanel('Annotation_Setting')" *ngIf="!stopBtn">
                <img src="../../../assets/images/viewer_icon/Rectangle-Primary.svg" alt="Annotation Setting"
                  aria-label="Select Annotation" />
                <!-- <img src="../../../assets/images/viewer_icon/Stop-Primary.svg" />   -->
              </label>
              <label (click)="stopAnnotation(false)" *ngIf="stopBtn" title="Stop batch annotation">
                <i class="fa fa-stop-circle stopAnnoBtn" aria-hidden="true"></i>
              </label>
              <article [hidden]="!panelList.Annotation_Setting.isVisible">
                <div class="row divsidepop_header">
                  <div class="col-md-10">
                    <p>Annotation Setting</p>
                  </div>
                  <div class="col-md-2" (click)="togglePanel('Annotation_Setting')">
                    <i class="fa fa-times rightfloat"></i>
                  </div>
                </div>
                <div class="annoteicon padding_tabs">
                  <label>Normal Annotation:</label>
                  <ul [ngClass]="{'disableFunction': stopBtn}">
                    <li (click)="changeAnnotationShape('Regular','Rectangular')">
                      <div class="annoIconDiv"
                        [ngClass]="{'annotation-selected': annotationParams.type=='Rectangular' && !annotationParams.isBatch}">
                        <mat-icon svgIcon="rectAnnotation" title="Rectangle"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Regular','Circular')">
                      <div class="annoIconDiv"
                        [ngClass]="{'annotation-selected': annotationParams.type=='Circular' && !annotationParams.isBatch}">
                        <mat-icon svgIcon="circleAnnotation" title="Circle"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Regular','Ellipse')">
                      <div class="annoIconDiv" [ngClass]="{'annotation-selected': annotationParams.type=='Ellipse'}">
                        <mat-icon svgIcon="ellipseAnnotation" title="Ellipse"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Regular','OpenFreeform')">
                      <div class="annoIconDiv"
                        [ngClass]="{'annotation-selected': annotationParams.type=='OpenFreeform'}">
                        <mat-icon svgIcon="openFreeFormAnnotation" title="Freeform"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Regular','Freeform')">
                      <div class="annoIconDiv" [ngClass]="{'annotation-selected': annotationParams.type=='Freeform'}">
                        <mat-icon svgIcon="closedFreeFormAnnotation" title="Close Freeform"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Regular','FilledFreeform')">
                      <div class="annoIconDiv"
                        [ngClass]="{'annotation-selected': annotationParams.type=='FilledFreeform'}">
                        <mat-icon svgIcon="filledFreeFormAnnotation" title="Filled Freeform"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Regular','Angle')">
                      <div class="annoIconDiv" [ngClass]="{'annotation-selected': annotationParams.type=='Angle'}">
                        <mat-icon svgIcon="angleAnnotation" title="Angle"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Regular','Arrow')">
                      <div class="annoIconDiv" [ngClass]="{'annotation-selected': annotationParams.type=='Arrow'}">
                        <mat-icon svgIcon="arrowAnnotation" title="Arrow"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Regular','Ruler')">
                      <div class="annoIconDiv" [ngClass]="{'annotation-selected': annotationParams.type=='Ruler'}">
                        <mat-icon svgIcon="rulerAnnotation" title="Ruler"> </mat-icon>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="annoteicon padding_tabs">
                  <label>Batch Annotation:</label>
                  <ul [ngClass]="{'disableFunction': stopBtn}">
                    <!-- <li (click)="changeAnnotationShape('Batch','Rectangular')">
                      <img src="../../../assets/images/viewer_icon/Rectangle-Active.svg" alt="Batch Rectangle"
                        data-toggle="tooltip" data-placement="bottom" title="Batch Rectangle"
                        [ngClass]="{'annotation-selected': annotationParams.type=='Rectangular' && annotationParams.isBatch}" />
                    </li>
                    <li (click)="changeAnnotationShape('Batch','Circular')">
                      <img src="../../../assets/images/viewer_icon/Annotation Circle-Active.svg" alt="Batch Circle"
                        data-toggle="tooltip" data-placement="bottom" title="Batch Circle"
                        [ngClass]="{'annotation-selected': annotationParams.type=='Circular' && annotationParams.isBatch}" />
                    </li> -->

                    <li (click)="changeAnnotationShape('Batch','Rectangular')">
                      <div class="annoIconDiv"
                        [ngClass]="{'annotation-selected': annotationParams.type=='Rectangular' && annotationParams.isBatch}">
                        <mat-icon svgIcon="rectAnnotation" title="Batch Rectangle"> </mat-icon>
                      </div>
                    </li>
                    <li (click)="changeAnnotationShape('Batch','Circular')">
                      <div class="annoIconDiv"
                        [ngClass]="{'annotation-selected': annotationParams.type=='Circular' && annotationParams.isBatch}">
                        <mat-icon svgIcon="circleAnnotation" title="Batch Circle"> </mat-icon>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="padding_tabs" [ngClass]="{'disableFunction': stopBtn}">
                  <label>Select Colors:</label>
                  <div class="color1 reds" [ngClass]="{'colour-selected': annotationParams.colour=='#ff0000'}"
                    (click)="changeAnnotationColor('#ff0000')"></div>
                  <div class="color1 blues" [ngClass]="{'colour-selected': annotationParams.colour=='#0000ff'}"
                    (click)="changeAnnotationColor('#0000ff')"></div>
                  <div class="color1 greens" [ngClass]="{'colour-selected': annotationParams.colour=='#00ff00'}"
                    (click)="changeAnnotationColor('#00ff00')"></div>
                  <div class="color1 cyans" [ngClass]="{'colour-selected': annotationParams.colour=='#00ffff'}"
                    (click)="changeAnnotationColor('#00ffff')"></div>
                  <div class="color1 magentas" [ngClass]="{'colour-selected': annotationParams.colour=='#FF00FF'}"
                    (click)="changeAnnotationColor('#FF00FF')"></div>
                  <div class="color1 yellows" [ngClass]="{'colour-selected': annotationParams.colour=='#ffff00'}"
                    (click)="changeAnnotationColor('#ffff00')"></div>
                  <div id="colorSelector" title="Select Custom Color"
                    [ngClass]="{'colour-selected': annotationParams.colour== color}">
                    <input id="annColor" [(colorPicker)]="color" [style.background]="color"
                      (colorPickerChange)="changeAnnotationColor(color)" />
                  </div>
                </div>
                <div class="padding_tabs" [ngClass]="{'disableFunction': stopBtn}">
                  <label>Select Thickness:</label>
                  <div class="inlinebtns">
                    <button class="btn thincknessbtn"
                      [ngClass]="{'thincknessbtn-selected': annotationParams.thickness==1}"
                      (click)="changeAnnotationThickness(1)">1px</button>
                    <button class="btn thincknessbtn"
                      [ngClass]="{'thincknessbtn-selected': annotationParams.thickness=='2'}"
                      (click)="changeAnnotationThickness(2)">2px</button>
                    <button class="btn thincknessbtn"
                      [ngClass]="{'thincknessbtn-selected': annotationParams.thickness==4}"
                      (click)="changeAnnotationThickness(4)">4px</button>
                    <button class="btn thincknessbtn"
                      [ngClass]="{'thincknessbtn-selected': annotationParams.thickness==8}"
                      (click)="changeAnnotationThickness(8)">8px</button>
                    <button class="btn thincknessbtn"
                      [ngClass]="{'thincknessbtn-selected': annotationParams.thickness==10}"
                      (click)="changeAnnotationThickness(10)">10px</button>
                  </div>
                </div>
                <div class="row filterbtn">
                  <div class="col-sm-3 col-md-3"></div>
                  <div class="col-sm-9 col-md-9 filterbtn">
                    <!-- <button class="btn stopbtn btn-danger" (click)="stopAnnotation()" *ngIf="stopBtn">Stop</button> -->
                    <!-- <button class="btn btn-danger" *ngIf="stopBtn">Stop</button> -->
                    <button type="button" class="btn searchbtn" aria-label="searchbtn" (click)="drawAnnotation()"
                      *ngIf="!stopBtn">Apply</button>
                    <button type="button" class="btn btn-default closebtn" aria-label="cancelbtn"
                      (click)="togglePanel('Annotation_Setting')">Close</button>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </li>
        <li data-toggle="tooltip" title="Rotate" id="rotateTab"
          [ngClass]="{'img-viewer-icon-selected': activeTool == 'Rotation'}">

          <div class="tabordion_sidebar">
            <section id="section_4">
              <input type="radio" name="sections" id="option_4">
              <label for="option_4" (click)="togglePanel('Rotation')">
                <img src="../../../assets/images/viewer_icon/Image Transform-Primary.svg" alt="Rotate" />
              </label>
              <article *ngIf="panelList.Rotation.isVisible" style="width: 16vw;">
                <div class="row divsidepop_header">
                  <div class="col-md-10">
                    <p>Rotation</p>
                  </div>
                  <div class="col-md-2" (click)="togglePanel('Rotation')">
                    <i class="fa fa-times rightfloat"></i>
                  </div>
                </div>
                <div class="row" style="padding: 0.65vw;margin: 0px !important;">
                  <div class="col-md-4">
                    <label>Rotate:</label>
                  </div>
                  <!-- <div class="col-md-4">
                    <div class="dropdown">
                      <select class="degreeclass" (change)="rotate()" [(ngModel)]="selectedValue">
                        <option *ngFor="let deg of degrees" [value]="deg">
                          {{deg}}<span>&#176;</span></option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-md-8">
                    <img src="../../../assets/images/viewer_icon/Rotate Left-Primary.svg" (click)="rotateLeft()"
                      title="Rotate Left" title="Rotate Anticlockwise by 45 deg" style="width: 1.7vw;" />
                    <img src="../../../assets/images/viewer_icon/Rotate Right-Primary.svg" (click)="rotateRight()"
                      title="Rotate Right" title="Rotate Clockwise by 45 deg" style="width: 1.7vw; margin-left: 1vw;" />
                    <!-- <img src="../../../assets/images/viewer_icon/Rotate Left-Primary.svg" title="Rotate Left" />
                    <img src="../../../assets/images/viewer_icon/Rotate Right-Primary.svg" title="Rotate Right" /> -->
                  </div>
                  <!-- <div class="col-sm-3 dn">
                    {{selectedValue}}&#176;
                  </div> -->
                </div>
                <div class="row padding_tabs" style="display: none;">
                  <div class="col-md-4">
                    <label>Flip:</label>
                  </div>
                  <div class="col-md-6">
                    <!-- <img src="../../../assets/images/viewer_icon/Transform Horizontal-down-Primary.svg"
                      (click)="flipImage()" />
                    <img src="../../../assets/images/viewer_icon/Transform vertical - right-Primary.svg"
                      (click)="flipImage()" /> -->
                    <img src="../../../assets/images/viewer_icon/Transform Horizontal-down-Primary.svg" />
                    <img src="../../../assets/images/viewer_icon/Transform vertical - right-Primary.svg" />
                  </div>
                </div>
              </article>
            </section>
          </div>
        </li>

        <li data-toggle="tooltip" title="Easy View" id="easyViewTab" style="display: none;"
          [ngClass]="{'img-viewer-icon-selected': activeTool == 'Easy_View', 'disableFunction': isSplitView}">

          <div class="tabordion_sidebar">
            <section id="section_5">
              <input type="radio" name="sections" id="option_5">
              <label for="option_5" (click)="togglePanel('Easy_View')">
                <img src="../../../assets/images/viewer_icon/Auto Navigation-Side Bar-Primary.svg" alt="Easy View" />
              </label>
              <article *ngIf="panelList.Easy_View.isVisible">
                <div class="row divsidepop_header">
                  <div class="col-md-10">
                    <p>Auto Navigation</p>
                  </div>
                  <div class="col-md-2" (click)="togglePanel('Easy_View')">
                    <i class="fa fa-times rightfloat"></i>
                  </div>
                </div>
                <div class="row padding_tabs">
                  <div class="col-md-4">
                    <label>Read Type</label>
                  </div>
                  <div class="col-md-8">
                    <img src="../../../assets/images/viewer_icon/Auto Navigation 3-Primary.svg" title="zigzag"
                      (click)="selectEVReadType('zigzag')" id="EVType" *ngIf="easyViewReadTypeSelected != 'zigzag'" />
                    <img src="../../../assets/images/viewer_icon/Auto Navigation 3-Active.svg" title="zigzag"
                      *ngIf="easyViewReadTypeSelected== 'zigzag'" />
                    <img src="../../../assets/images/viewer_icon/Auto Navigation 4-Primary.svg" title="upndown"
                      (click)="selectEVReadType('upndown')" *ngIf="easyViewReadTypeSelected != 'upndown'" />
                    <img src="../../../assets/images/viewer_icon/Auto Navigation 4-Active.svg" title="upndown"
                      *ngIf="easyViewReadTypeSelected== 'upndown'" />
                    <img src="../../../assets/images/viewer_icon/Auto Navigation 1-Primary.svg" title="lefttoright"
                      (click)="selectEVReadType('lefttoright')" *ngIf="easyViewReadTypeSelected != 'lefttoright'" />
                    <img src="../../../assets/images/viewer_icon/Auto Navigation 1-Active.svg" title="lefttoright"
                      *ngIf="easyViewReadTypeSelected == 'lefttoright'" />
                    <img src="../../../assets/images/viewer_icon/Auto Navigation 2-Primary.svg"
                      title="upndownContinuous" (click)="selectEVReadType('upndownC')"
                      *ngIf="easyViewReadTypeSelected != 'upndownC'" />
                    <img src="../../../assets/images/viewer_icon/Auto Navigation 2-Active.svg" title="upndownContinuous"
                      *ngIf="easyViewReadTypeSelected == 'upndownC'" />
                  </div>
                </div>
                <div class="row padding_tabs">
                  <div class="col-md-4">
                    <label>Start From</label>
                  </div>
                  <div class="col-md-8">
                    <div class="dropdown">
                      <select class="degreeclass" [(ngModel)]="easyViewStartSelected">
                        <option *ngFor="let start of easyViewStartFrom" [value]="start">
                          {{start}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row padding_tabs">
                  <div class="col-md-4">
                    <label>Speed</label>
                  </div>
                  <div class="col-md-8">
                    <div class="magnifyslider" style="margin: 0vw 0vw 0vw -0.3vw">
                      <!-- <div class="d-inline">Low</div> -->
                      <div class="d-inline">
                        <input class="input-range" type="range" min="1" max="3" value="1" [(ngModel)]="easyViewSpeed"
                          (change)="setEasyViewSpeed($event)">
                      </div>
                      <!-- <div class="d-inline">High</div> -->
                    </div>
                    <div class="magnifyslider" style="margin: 0vw 0vw 0vw -0.3vw">
                      <div class="d-inline">Low</div>
                      <div class="d-inline">Medium</div>
                      <div class="d-inline">High</div>
                    </div>
                    <!-- <div class="speed_radio_1">
                      <div class="form-check form-check-inline">
                        <mat-radio-group aria-label="Select an option">
                          <mat-radio-button class="form-check-input" value="low" (click)="setEasyViewSpeed('low')" [checked]="easyViewSp == 'low'">Low
                          </mat-radio-button> &nbsp;
                          <mat-radio-button class="form-check-input" value="medium" [checked]="easyViewSp == 'medium'"
                            (click)="setEasyViewSpeed('medium')">Medium</mat-radio-button>&nbsp;
                          <mat-radio-button class="form-check-input" value="high" [checked]="easyViewSp == 'high'" (click)="setEasyViewSpeed('high')">
                            High</mat-radio-button>&nbsp;
                        </mat-radio-group>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="row padding_tabs">
                  <div class="col-md-4">
                    <label>Magnification</label>
                  </div>
                  <div class="col-md-8">
                    <div class="magnifyslider" style="margin: 0vw 0vw 0vw 0.7vw">
                      <input class="input-range" type="range" value="1" min="1" max="40" [(ngModel)]="sliderRangeValue"
                        (change)="zoomToIndex('slider')">
                    </div>
                    <div class="magnifyslider" style="margin: 0vw 0vw 0vw 0.7vw">
                      <div class="d-inline" style="padding: 0 !important;display: inline !important;">0x</div>
                      <div class="d-inline" id="result" *ngIf="sliderRangeValue">{{sliderRangeValue}}x</div>
                      <div class="d-inline" style="padding: 0 9vw !important;">40x</div>
                    </div>

                  </div>
                </div>
                <div class="row filterbtn">
                  <div class="col-sm-3 col-md-3"></div>
                  <div class="col-sm-9 col-md-9 filterbtn">
                    <button type="button" class="btn searchbtn" aria-label="searchbtn"
                      (click)="starteasyview()">Start</button>
                    <button type="button" class="btn btn-default closebtn" aria-label="cancelbtn"
                      (click)="resetEasyView()">Reset</button>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </li>

        <li class="syncicon" *ngIf="isSplitView" id="imageSynch" title="Image Synchronization"
          (click)="synchronizeImage()">
          <img *ngIf="!isSync" src="../../../assets/images/viewer_icon/refresh_white.svg" alt="Z-stack" />
          <img *ngIf="isSync" src="../../../assets/images/viewer_icon/refresh_active.svg" alt="Z-stack" />
        </li>
      </ul>
      <div class="sideBarArrow">
        <i _ngcontent-qoh-c5="" (click)="toggleSideBar()" class="fa fa-chevron-right rotateArrow"
          [ngClass]="currentSideBarArrow=='open' ? 'openArrow' : 'closeArrow'" aria-hidden="true"
          ng-reflect-klass="fa fa-chevron-right rotateArro" ng-reflect-ng-class="openArrow"></i>
      </div>
    </div>

    <div class="sideBarIcon" style="display: none;">
      <i class="fa fa-window-restore" aria-hidden="true"></i>
    </div>
  </div>
  <div class="easyviewbox" *ngIf="easyviewpopupshow">
    <p>Speed</p>
    <!-- <div class="speed_radio">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="low" value="low"
          [checked]="easyViewSp == 'low'" (click)="changeEasyViewSpeed('low')">
        <label class="form-check-label radio" for="low">low</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="medium" value="medium"
          [checked]="easyViewSp == 'medium'" (click)="changeEasyViewSpeed('medium')">
        <label class="form-check-label radio" for="medium"> medium</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="high" value="high"
          [checked]="easyViewSp == 'high'" (click)="changeEasyViewSpeed('high')">
        <label class="form-check-label radio" for="high"> high</label>
      </div>
    </div> -->
    <div class="col-12">
      <div class="magnifyslider" style="margin: 0vw 0vw 0vw -0.3vw">
        <div class="d-inline">
          <input class="input-range" type="range" min="1" max="3" value="1" [(ngModel)]="easyViewSpeed"
            (change)="setEasyViewSpeed($event)">
        </div>
      </div>
      <div class="magnifyslider" style="margin: 0vw 0vw 0vw -0.3vw">
        <div class="d-inline">Low</div>
        <div class="d-inline">Medium</div>
        <div class="d-inline">High</div>
      </div>
    </div>
    <div class="playbtns">
      <img src="../../../assets/images/viewer_icon/Backward-Primary.svg" title="backward"
        (click)="backwardeasyview()" />
      <img src="../../../assets/images/viewer_icon/Play-Primary.svg" title="play" [hidden]="!playpsauseeasyview"
        (click)="playeasyview()" />
      <img src="../../../assets/images/viewer_icon/Pause-Primary.svg" title="pause" [hidden]="playpsauseeasyview"
        (click)="pauseeasyview(true)" />
      <img src="../../../assets/images/viewer_icon/Stop-Primary.svg" title="stop" (click)="stopeasyview()" />
      <img src="../../../assets/images/viewer_icon/Forward-Primary.svg" title="forward" (click)="fordwardeasyview()" />
    </div>
  </div>
</div>