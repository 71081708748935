<div class="page-content">
  <div class="pageheader">
    <div class="pageheadtitle">
      <h4> Create
      </h4>
    </div>
  </div>
  <div class="cont">
    <div class="tab_data">
      <form [formGroup]="meetingForm">
        <div class="example-form">
          <div class="clearfix createconsdiv">
            <div class="float-left" style="width: 50%;">
              <mat-form-field class="example-full-width gselect">
                <mat-label class="labelbg">Title<span class="mandatory">*</span></mat-label>
                <input matInput formControlName="meetingTitle" maxlength="250" />
                <mat-error>{{meetingF.meetingTitle.invalid | inputError:'meetingTitle' | translate}}</mat-error>
              </mat-form-field>
              <mat-form-field class="example-full-width gselect">
                <mat-label class="labelbg"><span class="backgroundcolor_text">Description</span></mat-label>
                <textarea matInput maxlength="65535" style="height: 6.15vw;"
                  formControlName="meetingDescription"></textarea>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="float-right" style="width: 50%;">
              <mat-form-field class="example-full-width gselect">
                <mat-label class="labelbg">Date<span class="mandatory">*</span></mat-label>
                <input matInput [min]="minDate" [matDatepicker]="meetingDate" (dateChange)="dateTimeTimeZoneChangeFn()"
                  (focus)="meetingDate.open()" formControlName="meetingDate">
                <mat-datepicker-toggle matSuffix [for]="meetingDate"></mat-datepicker-toggle>
                <mat-datepicker #meetingDate></mat-datepicker>
              </mat-form-field>
              <div class="float-left" style="width: 30%;">
                <mat-form-field class="example-full-width gselect">
                  <mat-label class="labelbg">Start Time<span class="mandatory">*</span></mat-label>
                  <input matInput [ngxTimepicker]="startTime" [format]=24 readonly formControlName="meetingStartTime">
                  <ngx-material-timepicker #startTime [preventOverlayClick]="true"
                    (closed)="timeChanged(true)"></ngx-material-timepicker>
                </mat-form-field>
              </div>
              <div class="float-left" style="width: 10%;">
                <div class="dash">-</div>
              </div>
              <div class="float-left" style="width: 30%;">
                <mat-form-field class="example-full-width gselect">
                  <mat-label class="labelbg">End Time<span class="mandatory">*</span></mat-label>
                  <input matInput [ngxTimepicker]="endTime" [format]=24 readonly formControlName="meetingEndTime">
                  <ngx-material-timepicker #endTime [preventOverlayClick]="true"
                    (closed)="timeChanged(true)"></ngx-material-timepicker>
                </mat-form-field>
              </div>
              <div class="float-left" style="width: 30%;">
                <div class="duration" *ngIf="duration">( {{duration}} )</div>
              </div>
              <mat-form-field class="example-full-width gselect">
                <mat-label class="labelbg">TimeZone</mat-label>
                <mat-select class="backgroundcolor_text" #singleSelect (selectionChange)="dateTimeTimeZoneChangeFn()"
                  formControlName="meetingTimeZone">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="seach..." [formControl]="timeZoneFilterCtrl"
                      noEntriesFoundLabel="No matching timezone found">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let timezone of filteredTimeZoneArr" [value]="timezone.zone_pk">
                    {{timezone.zone_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="clearfix createconsdiv">
          </div>
          <div class="clearfix createconsdiv">
            <mat-form-field class="example-full-width gselect demo-chip-list">
              <mat-label class="labelbg">Add Participants</mat-label>
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let participant of participantList;let indx=index;" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(participant,indx)"
                  [ngClass]="{'invalidEmail': !participant.valid}">
                  {{participant.displayName ? participant.displayName : participant.email}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input #particpantInput [formControl]="participantCtrl" [matAutocomplete]="auto"
                  [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" maxlength="255" />
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let user of filteredParticipants | async" [value]="user">
                  <div class="participant-list-text">
                    <i class="fa fa-user-circle-o usericon" aria-hidden="true"></i>
                    <div class="displayDiv">
                      <div class="displayName">{{ user.displayName }}</div>
                      <div class="email">{{user.email}}</div>
                    </div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="clearfix createconsdiv">
            <div style="margin-left: 1vw;">
              <button type="button" class="btn-default searchbtn" (click)="openImageFromStudy()">Add Image</button>
            </div>
          </div>
          <div class="clearfix createconsdiv" *ngIf="(addedImages | json) != '{}'">
            <div class="outergrey">
              <div class="checkbox_delete">
                <div class="float-left">
                  <mat-checkbox aria-label="Select All" [checked]="isChecked()" [indeterminate]="isIndeterminate()"
                    (change)="$event ? toggleAll($event) : null">Select All</mat-checkbox>
                </div>
                <div class="float-right">
                  <i *ngIf="selectedImgArr?.length" class="fa fa-trash delicon" aria-hidden="true"
                    (click)="deleteSelectedImages()"></i>
                </div>
              </div>
              <div class="imagesets">
                <div class="fLeftCard" *ngFor="let image of addedImages | keyvalue">
                  <div class="card-group">
                    <div class="card imgcard">
                      <div class="griddetcard card-body">
                        <div class="griddet">
                          <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? toggle(image.key, $event) : null" [checked]="exists(image.key)">
                          </mat-checkbox>
                        </div>
                        <div class="">
                          <img [defaultImage]="defaultImagePath"
                            [lazyLoad]="thumbnailUrl+image.value.identifier+'_thumbnail.jpeg'"
                            class="img-responsive animal_img" alt="tissueimg">
                        </div>
                        <div title="{{image.value.slidename}}" class="truncate_slide_name">{{image.value.slidename}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="threebtn_study rightfloat" *ngIf="!showSavingButton">
            <button type="button" class="btn-default" (click)="cancelMeeting()">Cancel</button>
            <button type="button" class="btn-default" (click)="createMeeting('DRAFT')" *ngIf="showDraftButton">
              Save as Draft
            </button>
            <button type="button" class="btn-default searchbtn" (click)="createMeeting('NOT_STARTED')">
              Send Invite
            </button>
          </div>
          <div class="threebtn_study rightfloat" *ngIf="showSavingButton">
            <button class="btn-default searchbtn" type="button" disabled>
              <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw; width: 1vw; height: 1vw;"
                role="status" aria-hidden="true"></span>
              <span>Saving...</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>