import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from '../app-config.service';
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { StudyDetailsComponent } from "./../study/study-details/study-details.component";
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { GalleryService } from '../gallery/gallery.service';
import { ImageViewerTopBarService } from '../image-viewer/image-viewer-top-bar/image-viewer-top-bar.service';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { UsersService } from '../users/users.service';
import { ModalDigitalSignatureComponent } from '../digital-signature/modal-digital-signature/modal-digital-signature.component';

@Component({
  selector: 'app-web-viewer-frame',
  templateUrl: './web-viewer-frame.component.html',
  styleUrls: ['./web-viewer-frame.component.css']
})
export class WebViewerFrameComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  id: string = '';
  viewerSlides: any = [];

  constructor(private sanitizer: DomSanitizer,
    private appConfig: AppConfigService,
    private dialog: MatDialog,
    private router: Router,
    private topBarService: ImageViewerTopBarService,
    private galleryService: GalleryService,
    private usersService: UsersService,
    private commonService: CommonService) {
    const msgEvent = (event: any) => {
      if ((event?.data?.call == 'parentComponent') && event?.data?.value?.fetchSlideInfo) {
        this.sendDataToViewer();
      } else if (event?.data?.call == 'showStudyDetails') {
        this.openStudyDetailDialog(event?.data?.value?.slideGUID);
      } else if (event?.data?.call == 'unmapImage') {
        let unMapObj: any = event?.data?.value?.unMapObj;
        if (unMapObj) {
          this.galleryService.bulkUnmapImages(unMapObj).subscribe((res: any) => {
            this.commonService.showToastMsg(res.message);
            this.moveToGallery(msgEvent);
          });
        }
      } else if (event?.data?.call == 'compareImage') {
        let slidesPKArr: any = event?.data?.value?.slidesPKArr;
        this.commonService.changePlace('WebViewer/' + slidesPKArr.toString());
        this.router.navigate(['WebViewer/' + slidesPKArr.toString()]).then(() => {
          this.sendDataToViewer();
        });
      } else if (event?.data?.call == 'updateSlideReview') {
        this.topBarService.updateSlideReview(event?.data?.value?.slideReviewData, event?.data?.value?.organReviewPk, event?.data?.value?.slideId).subscribe((response: any) => {
          let iframe = document.getElementById('webViewerFrame');
          iframe['contentWindow'].postMessage({
            call: 'slideReviewSuccess', value: response.message
          }, this.appConfig.config.webViewerUrl);
        })
      } else if (event?.data?.call == 'saveSlideReview') {
        this.topBarService.saveSlideReview(event?.data?.value?.slideReviewData, event?.data?.value?.slideId, event?.data?.value?.isReviewShareable).subscribe((response: any) => {
          let iframe = document.getElementById('webViewerFrame');
          iframe['contentWindow'].postMessage({
            call: 'slideReviewSuccess', value: response.message
          }, this.appConfig.config.webViewerUrl);
        }, ()=>{
          let iframe = document.getElementById('webViewerFrame');
          iframe['contentWindow'].postMessage({
            call: 'slideReviewError'
          }, this.appConfig.config.webViewerUrl);
        });
      } else if (event?.data?.call == 'reLogin') {
        this.reLogin();
      } else if (event?.data?.call == 'fetchDSToken') {
        this.fetchDSToken();
      } else if (event?.data?.call == 'moveToGallery') {
        this.moveToGallery(msgEvent);
      }
    }
    window.addEventListener("message", msgEvent, false);
  }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.appConfig.config.webViewerUrl);
  }

  fetchDSToken() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    dialogConfig.data = {
      headerTitle: "Login",
      confirmMsg: "Session expired. Login again to continue",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ModalDigitalSignatureComponent, dialogConfig);
    return dialogref.afterClosed().subscribe((result: any) => {
      if (result != 'Cancel Request') {
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'fetchedDSToken', value: {
            dsToken: result.data.dsToken,
            dsComment: result.dsComment
          }
        }, this.appConfig.config.webViewerUrl);
      } else {
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'cancelledDSToken'
        }, this.appConfig.config.webViewerUrl);
      }
    });
  }

  reLogin() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = "50%";
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      headerTitle: "Login",
      confirmMsg: "Session expired. Login again to continue",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      isAccessTokenExpired: true
    };
    const dialogref = this.dialog.open(LoginDialogComponent, dialogConfig);
    return dialogref.afterClosed().subscribe((result: any) => {
      if (result) {
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'reLoginSuccess'
        }, this.appConfig.config.webViewerUrl);
      }
    });
  }

  moveToGallery(msgEvent: any) {
    window.removeEventListener("message", msgEvent, false);
    let openedFrom: any = this.commonService.getViewerOpenedFrom();
    openedFrom = (openedFrom !== 'mapped') ? 'gallery' : openedFrom;
    this.commonService.changePlace(openedFrom);
    this.router.navigate(["/dashboard/" + openedFrom]);
    localStorage.setItem('samples', '');
  }

  sendDataToViewer() {
    // let viewerSlidesIds = location.pathname.split("/")[2].split(",");
    let viewerSlidesIds = location.pathname.slice((location.pathname.lastIndexOf("/") + 1), location.pathname.length).split(",");
    this.viewerSlides = viewerSlidesIds.map((indvId) => {
      return { id: indvId }
    });
    let iframe = document.getElementById('webViewerFrame');
    if (iframe == null) return;
    let windowObj = {
      parentProj: 'AIRADHI',
      filledAnnoOpacityLevel: this.appConfig.config.filledAnnoOpacityLevel,
      viewerOpenedFrom: localStorage.viewerOpenedFrom ? localStorage.viewerOpenedFrom : 'gallery',
      activeRole: localStorage.userActiveRole,
      labelViewRotateDegree: this.commonService['config']['labelViewRotateDegree'],
      galleryFilter: JSON.parse(this.commonService.getGalleryFilters()),
      loginUrl: this.appConfig.config.parentLoginUrl,
      parentInfo: {},
      viewerBaseName: this.appConfig.config.viewerBaseName,
      urlParent: {
        "apiUrl": this.appConfig.config.apiUrl,
        "thumbnailUrl": this.appConfig.config.thumbnailUrl,
        "tileUrl": this.appConfig.config.tileUrl
      },
      viewerSlides: this.viewerSlides,
      lastParentAppUrl: '',
      currentUser: localStorage['currentUser'],
      dsComments: this.usersService.dsComment,
      isDsTokenStored: this.usersService.isDsTokenStored,
      anonymisedLabel: this.appConfig.config.anonymisedLabel,
      storedDsToken: this.usersService.getDsToken()
    }
    iframe['contentWindow'].postMessage({
      call: 'webViewerFrame', value: windowObj
    }, this.appConfig.config.webViewerUrl);
  }

  openStudyDetailDialog(slideGUID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = "90%";
    dialogConfig.data = {
      showHeader: true,
      studyGuid: slideGUID
    };
    this.dialog.open(StudyDetailsComponent, dialogConfig);
  }
}
