<div *ngIf="data.type != 'uploadAnnoFile'">
  <div class="modal-header dialog-header">
    <div class="headerTitle">
      <div class="dialog-title">{{data.headerTitle}}</div>
    </div>
    <div class="headerIcon float-right">
      <i class="fa fa-times" (click)="closeDialog()"></i>
    </div>
  </div>
  <div class="container dialog-container modal-body">
    <p>{{data.confirmMsg}}</p>
  </div>
  <div class="actions dialog-actions modal-footer">
    <button class="btn-default" (click)="closeDialog()">{{data.cancelButtonText}}</button>
    <!-- <button class="btn-default searchbtn" [mat-dialog-close]="true" cdkFocusInitial>{{data.confirmButtonText}}</button> -->
    <button class="btn-default searchbtn" (click)="confirmAction()" cdkFocusInitial>{{data.confirmButtonText}}</button>
  </div>
</div>

<!-- <div class="deleteAnno" *ngIf="((showData.headerTitle == 'Delete Annotation') && (currentSlideIdData?.length > 0)  && (selectedSlideData?.isDeleteEnable.length > 0))">
  <mat-form-field appearance="outline" class="commonMatFormField">
      <mat-label>Digital Signature Comment</mat-label>
      <textarea type="text" class="form-control dsCommentText annoCommentText" id="dsCommentTextCreate"
          [(ngModel)]="savedDsComment" (ngModelChange)="updateDsComment()" maxlength="250" rows="2" matInput></textarea>
  </mat-form-field>
</div>
<div class="actions dialog-actions modal-footer">
  <button class="btn-default" (click)="closeDialog()">{{showData.cancelButtonText}}</button>
  <button class="btn-default searchbtn" [mat-dialog-close]="true" (click)="onConfirm()"
    cdkFocusInitial>{{showData.confirmButtonText}}</button>
</div> -->

<div *ngIf="data.type == 'uploadAnnoFile'" class="uploadAnnoFileDialogMainDiv">
  <!-- <ngx-dropzone (change)="onSelect($event)" [multiple]="false" accept="application/json">
    <ngx-dropzone-label class="dropzoneLabel">Select / Drop file</ngx-dropzone-label>
    <ngx-dropzone-preview class="annoFilePreview" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
      <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone> -->
  <div class="uploadAnnobtnDiv">
    <button class="btn-default" (click)="closeDialog()">Close</button>
    <button class="btn-default uploadBtn" [class.searchbtn]="(files.length > 0)" [disabled]="(files.length == 0)"
      (click)="uploadAnnoFile()">Upload</button>
  </div>
</div>
