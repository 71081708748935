import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { GalleryComponent } from './gallery.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule} from 'ng-lazyload-image';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryRoutingModule } from './gallery-routing.module';
// import { DataTablesModule } from 'angular-datatables';
import { GalleryheaderComponent } from './galleryheader/galleryheader.component';
import { GallerylistComponent } from './gallerylist/gallerylist.component';
import { GallerygridComponent } from './gallerygrid/gallerygrid.component';
import { GallerypreviewComponent } from './gallerypreview/gallerypreview.component';
import { GalleryService } from './gallery.service';
import { AppMaterialModule } from '../app-material/app-material.module';
import { GalleryDialogComponent } from './gallery-dialog/gallery-dialog.component';
import { InputErrorPipe } from './models/input-error.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    GalleryComponent,
    GalleryheaderComponent,
    GallerylistComponent,
    GallerygridComponent,
    GallerypreviewComponent,
    GalleryDialogComponent,
    InputErrorPipe
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GalleryRoutingModule,
    // DataTablesModule,
    AppMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [GalleryService],
  exports: [GalleryComponent]
})
export class GalleryModule { }
