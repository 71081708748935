<div class="modal-header dialog-header">
    <div class="headerTitle">
        <div class="dialog-title">View Report</div>
    </div>
    <div class="headerIcon float-right">
        <i class="fa fa-times" (click)="closeDialog()"></i>
    </div>
</div>
<div class="dialog-container modal-body" style="overflow-y: hidden;">

    <div id="downloadReportDiv"></div>

    <div class="reportMappingDiv">
        <div class="reportMappingHeaderDiv">
            <label>Study No.</label>
            <div class="reportStudy">{{data.studyData.studyNo}}</div>
            <div class="reportStudy">
                <mat-form-field class="commonMatFormField" appearance="outline">
                    <mat-select (selectionChange)="imageSelectionChanged($event.value)" [(ngModel)]="selectedImageType"
                        [disabled]="disableSelect">
                        <mat-option [value]="0">All Images</mat-option>
                        <mat-option [value]="1">Mapped Images</mat-option>
                        <mat-option [value]="2">Unmapped Images</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="reportStudy">
                <!-- <mat-form-field class="full-width gselect">
            <input matInput  placeholder="Search Image Name" id="filterSearch" class="labelbg">
        </mat-form-field> -->
                <input type="text" id="filterSearch" placeholder="Search Image Name">
            </div>
        </div>
        <div class="pageBody" *ngIf="showLoader">
            <mat-spinner style="margin:0 auto;"></mat-spinner>
        </div>
        <div class="studyReport" *ngIf="!showLoader">
            <table id="dataSlidesTableHeader" class="hover row-border nowrap list_one_table" style="width:100%">
                <thead>
                    <tr>
                        <ng-container *ngFor="let reportHeaderArrItem of reportHeaderArr">
                            <th *ngIf="(reportHeaderArrItem.studyType.indexOf(studyType) >= 0)"
                                
                                scope="col">{{reportHeaderArrItem.name}}</th>
                        </ng-container>
                        <!-- <th style="min-width: 7vw" scope="col">Slide ID</th>
                <th style="min-width: 7vw" scope="col">Subject Id</th>
                <th style="min-width: 17vw" scope="col">Image Name</th>
                <th style="min-width: 10vw"scope="col">Cassette Id</th>
                <th style="min-width: 7vw" scope="col">Stain</th>
                <th style="min-width: 7vw" scope="col">Status</th> -->
                    </tr>
                </thead>
                <tbody></tbody>
            </table>
            <div class="slideTableBodyDiv">
                <!-- <table id="dataSlidesTableBody">
            </table> -->
                <table style="width: 100%;" class="hover row-border nowrap list_one_table">
                    <tbody class="slideData">
                        <tr *ngFor="let slide of reportData">
                            <ng-container *ngFor="let reportHeaderArrItem of reportHeaderArr">
                                <ng-container *ngIf="reportHeaderArrItem.code !='status'">
                                    <td *ngIf="(reportHeaderArrItem.studyType.indexOf(studyType) >= 0)"
                                      >
                                        <p 
                                            title="{{slide[reportHeaderArrItem.code]}}" class="textEllipsis cssTooltip">
                                            {{slide[reportHeaderArrItem.code]}}</p>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="reportHeaderArrItem.code =='status'">
                                    <td [ngStyle]="{'width.vw': reportHeaderArrItem.width[reportHeaderArrItem.studyType.indexOf(studyType)]}"
                                        *ngIf="slide.slidemapped">
                                        <p>Mapped</p>
                                    </td>
                                    <td [ngStyle]="{'min-width.vw': reportHeaderArrItem.width[reportHeaderArrItem.studyType.indexOf(studyType)]}"
                                        *ngIf="!slide.slidemapped">
                                        <p>Unmapped</p>
                                    </td>
                                </ng-container>
                            </ng-container>
                            <!-- <td style="min-width: 7vw"><p style="width: 7vw;" title="{{slide.slideLabel}}" class="textEllipsis cssTooltip">{{slide.slideLabel}}</p></td>
                        <td style="min-width: 7vw"><p style="width: 7vw;" title="{{slide.animalNumber}}" class="textEllipsis cssTooltip">{{slide.animalNumber}}</p></td>
                        <td style="min-width: 17vw"><p style="width: 17vw;" title="{{slide.fileName}}" class="textEllipsis cssTooltip">{{slide.fileName}}</p></td>
                        <td style="min-width: 10vw"><p style="width: 10vw;" title="{{slide.cassetteCode}}" class="textEllipsis cssTooltip">{{slide.cassetteCode}}</p></td>
                        <td style="min-width: 7vw"><p style="width: 7vw;" title="{{slide.stain}}" class="textEllipsis cssTooltip">{{slide.stain}}</p></td>
                        <td style="min-width: 7vw" *ngIf="slide.slidemapped"><p>Mapped</p></td>
                        <td style="min-width: 7vw" *ngIf="!slide.slidemapped"><p>Unmapped</p></td> -->
                        </tr>
                        <tr *ngIf="reportData.length == 0">
                            <td rowspan="3">No Records Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="pageBody" *ngIf="!showLoader">
        <table class="display list_one_table table table-hover" cellspacing="0" width="100%">
            <thead class="fullwidth">
                <tr>
                <th scope="col">Slide ID</th>
                <th scope="col">Subject Id</th>
                <th scope="col">Image Name</th>
                <th scope="col">Cassette Id</th>
                <th scope="col">Stain</th>
                <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody class="slideData" *ngIf="reportData.length > 0">
                <tr *ngFor="let slide of reportData">
                <td><p>{{slide.slideLabel}}</p></td>
                <td><p>{{slide.animalNumber}}</p></td>
                <td><p>{{slide.fileName}}</p></td>
                <td><p>{{slide.cassetteCode}}</p></td>
                <td><p>{{slide.stain}}</p></td>
                <td *ngIf="slide.slidemapped"><p>Mapped</p></td>
                <td *ngIf="!slide.slidemapped"><p>Unmapped</p></td>
                </tr>
            </tbody>
            <tbody *ngIf="reportData.length == 0">
                <tr>
                    <td rowspan="3">No Records Found!!!</td>
                </tr>
            </tbody>
        </table>
    </div> -->
    </div>
</div>
<div class="actions dialog-actions modal-footer">
    <button class="btn-default" (click)="closeDialog()">Cancel</button>
    <div class="btn-group dropup">
        <button type="button" class="btnDownloadAs btn btn-default dropdown-toggle"
            [disabled]="(reportData.length == 0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            [ngClass]="{'searchbtn': reportData.length > 0}">
            Download as
        </button>
        <div class="dropdown-menu">
            <!-- Dropdown menu links -->
            <button class="dropdown-item" type="button" (click)="downloadReportPdf()">Pdf</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" type="button" (click)="downloadReportCsv()">Excel</button>
        </div>
    </div>
    <!-- <button class="btn-default searchbtn" *ngIf="!isDownloadBtnClicked" [ngClass]="{ 'disablebtn_func' : showLoader}" (click)="downloadMappingReport()">Download</button>
    <button class="btn-default searchbtn" *ngIf="isDownloadBtnClicked" type="button" disabled>
        <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw; width: 1vw; height: 1vw;" role="status" aria-hidden="true"></span>
        <span>Downloading...</span>
      </button> -->
</div>