import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject, Optional, EventEmitter, Output } from "@angular/core";
import { GalleryService } from "./gallery.service";
import { Router } from "../../../node_modules/@angular/router";
import { CommonService } from '../common.service';

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.css"],
})
export class GalleryComponent implements OnInit {
  gridList;
  preview;
  activeMode: String = "gallery";
  onClose = new EventEmitter();

  constructor(
    private galleryService: GalleryService,
    private router: Router,
    @Optional() private dialogRef: MatDialogRef<GalleryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService
  ) {
    if (data && data.disableClose) {
      dialogRef.disableClose = data.disableClose;
    }
    /* if(data && data.selectedImage){
        this.galleryService.setSelecteSlidesList([data.selectedImage]);
      } */
    /* setTimeout(function () {
      if (data && data.selectedImage) {
       var a =  $("#checkbox_" + data.selectedImage).parents('.autoclick').find('.card-header');
       if(!(a.attr('id') == 'heading_0')) {
          a.click();
       }
        $("#checkbox_" + data.selectedImage).click();
      }
    }, 1000); */
  }

  ngOnInit() {
    if (this.activeMode == "gallery") {
      localStorage.tileNetworkStatus = "false";
    }
    this.galleryService.gridList.subscribe((gridList) => {
      this.gridList = gridList;
    });
    this.galleryService.preview.subscribe((preview) => {
      this.preview = preview;
    });
    let url = this.router.url;
    let urlarr = url.split("/");
    this.activeMode = urlarr[2];
    if (this.activeMode != "gallery" && this.activeMode != "image-viewer") {
      this.galleryService.switchGridList("grid");
    } else if (this.activeMode == "image-viewer") {
      this.galleryService.switchGridList("list");
    } else {
      let galleryFilter = JSON.parse(this.commonService.getGalleryFilters());
      this.galleryService.switchGridList(galleryFilter.viewType);
    }
    this.galleryService.changePreview(false);
    this.galleryService.changeshowLoader(true);
  }

  closeDialog() {
    this.galleryService.setSelecteSlidesList([]);
    this.galleryService.setSelectedSlidesListForThumbnail({});
    this.onClose.emit();
    this.dialogRef.close();
  }
}
