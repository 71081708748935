<div *ngIf="isFromSlideTrayView" [class.emptyAnnoManager]="isAnnotationManagerEmpty" class="annoManagerMainDiv">
  <div class="annotationHeader clearfix" *ngIf="!isAnnotationManagerEmpty">
    <div class="annoHeaderToggleGroup">
      <mat-icon class="annoHeaderToggleItem" [ngClass]="{'selected' : selectedHeaderFilterType == 'user'}"
        (click)="getUserOrType('user')">person</mat-icon>
      <mat-icon class="annoHeaderToggleItem" [ngClass]="{'selected' : selectedHeaderFilterType == 'type'}"
        (click)="getUserOrType('type')">format_shapes</mat-icon>
      <mat-icon class="annoHeaderToggleItem" [ngClass]="{'selected' : selectedHeaderFilterType == 'colour'}"
        (click)="getUserOrType('colour')">color_lens</mat-icon>
    </div>
    <div class="annotationsGroupTab">
      <ng-container *ngIf="selectedHeaderFilterType == 'colour'">
        <div class="headerColorGroup">
          <mat-tab-group class="colourTabGroup" (selectedTabChange)="getSelectedColourAnno($event)"
            [(selectedIndex)]="defaultSelectedColourTabIndex">
            <ng-container *ngFor="let annoColour of annotationManagerObj[selectedHeaderFilterType] | keyvalue">
              <mat-tab [label]="annoColour.key">
                <ng-template mat-tab-label>
                  <mat-icon class="colourPalletteGroupItem" [ngClass]="{'allColor': annoColour.key === '###'}"
                    [ngStyle]="{'color':annoColour.key}">stop</mat-icon>
                </ng-template>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedHeaderFilterType == 'type'">
        <div class="headerAnnoShapeGroup">
          <div (click)="showSelectedAnnoType('ALL')" [ngClass]="{'selectedAnno': currentSelectedAnnoType=='ALL'}">
            <mat-icon class="headerAnnoIcon" svgIcon="allShapeAnnotations"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['RECTANGLE']"
            (click)="showSelectedAnnoType('RECTANGLE')"
            [ngClass]="{'selectedAnno': currentSelectedAnnoType=='RECTANGLE'}">
            <mat-icon class="headerAnnoIcon" svgIcon="rectAnnotation"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['CIRCLE']"
            (click)="showSelectedAnnoType('CIRCLE')" [ngClass]="{'selectedAnno': currentSelectedAnnoType=='CIRCLE'}">
            <mat-icon class="headerAnnoIcon" svgIcon="circleAnnotation"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['ELLIPSE']"
            (click)="showSelectedAnnoType('ELLIPSE')" [ngClass]="{'selectedAnno': currentSelectedAnnoType=='ELLIPSE'}">
            <mat-icon class="headerAnnoIcon" svgIcon="ellipseAnnotation"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['FREEFORMOPEN']"
            (click)="showSelectedAnnoType('FREEFORMOPEN')"
            [ngClass]="{'selectedAnno': currentSelectedAnnoType=='FREEFORMOPEN'}">
            <mat-icon class="headerAnnoIcon" svgIcon="openFreeFormAnnotation"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['FREEFORMCLOSE']"
            (click)="showSelectedAnnoType('FREEFORMCLOSE')"
            [ngClass]="{'selectedAnno': currentSelectedAnnoType=='FREEFORMCLOSE'}">
            <mat-icon class="headerAnnoIcon" svgIcon="closedFreeFormAnnotation"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['FILLEDFREEFORM']"
            (click)="showSelectedAnnoType('FILLEDFREEFORM')"
            [ngClass]="{'selectedAnno': currentSelectedAnnoType=='FILLEDFREEFORM'}">
            <mat-icon class="headerAnnoIcon" svgIcon="filledFreeFormAnnotation"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['ANGLE']"
            (click)="showSelectedAnnoType('ANGLE')" [ngClass]="{'selectedAnno': currentSelectedAnnoType=='ANGLE'}">
            <mat-icon class="headerAnnoIcon" svgIcon="angleAnnotation"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['ARROW']"
            (click)="showSelectedAnnoType('ARROW')" [ngClass]="{'selectedAnno': currentSelectedAnnoType=='ARROW'}">
            <mat-icon class="headerAnnoIcon" svgIcon="arrowAnnotation"></mat-icon>
          </div>
          <div *ngIf="annotationManagerObj['type'] && annotationManagerObj['type']['RULER']"
            (click)="showSelectedAnnoType('RULER')" [ngClass]="{'selectedAnno': currentSelectedAnnoType=='RULER'}">
            <mat-icon class="headerAnnoIcon" svgIcon="rulerAnnotation"></mat-icon>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedHeaderFilterType == 'user'">
        <mat-tab-group class="userTabGroup" (selectedTabChange)="selectedAnnoUser($event)"
          [(selectedIndex)]="defaultSelectedUserTabIndex">
          <ng-container
            *ngFor="let annoUser of annotationManagerObj[selectedHeaderFilterType] | keyvalue; let i = index">
            <mat-tab [label]="annoUser.key" class="userItem"></mat-tab>
          </ng-container>
        </mat-tab-group>
      </ng-container>
    </div>
    <div class="headerFilter">
      <i class="fa fa-lg fa-cloud-download headerFilterIcon" (click)="downloadAnnotationFile()"
        title="Download Annotations"></i>
      <i class="fa fa-lg fa-cloud-upload headerFilterIcon" (click)="uploadAnnotationFile()"
        title="Upload Annotations"></i>
      <i [ngStyle]="{'color': isHeaderFilterViewAllSelected ? '#EA8823':'#5C5760'}"
        (click)="showHideSelectedAnnotation()" class="fa fa-eye fa-lg headerFilterIcon headerView"></i>
      <i [ngClass]="((selectedHeaderFilterType == 'user') && (currentSelectedUser != currentUser.userName))?'disabledField':'enabledField'"
        class="fa fa-trash-o fa-lg headerFilterIcon headerDelete"
        (click)="deleteAllAnnotations(((selectedHeaderFilterType == 'user') && (currentSelectedUser != currentUser.userName)))"></i>
      <i [ngStyle]="{'color': isHeaderFilterBookmarkSelected ? '#EA8823':'#5C5760'}" (click)="showBookmarkedAnno()"
        class="fa fa-bookmark fa-lg headerFilterIcon headerBookMark"></i>
    </div>
    <div class="annoImportExport dn">
      <i class="fa fa-upload fa-lg headerFilterIcon headerView"></i>
      <i class="fa fa-download fa-lg headerFilterIcon headerView"></i>
    </div>
  </div>
  <div class="annotationListView" *ngIf="!isAnnotationManagerEmpty">
    <div class="listView">
      <div class="listItem" [hidden]="isHeaderFilterBookmarkSelected && !filterAnnoData.info.bookmarked"
        *ngFor="let filterAnnoData of annotationDispArr; let i = index">
        <div class="annoIconAction">
          <ng-container *ngIf="selectedHeaderFilterType != 'user'">
            <div class="annoDisplayUser" [title]="filterAnnoData.info.creator"
              [innerHtml]="getDisplayUserText(filterAnnoData.info.creator)"></div>
          </ng-container>
          <ng-container>
            <mat-icon class="annoDisplayType {{filterAnnoData.info.type.toLowerCase()}}"
              [ngStyle]="{'fill': filterAnnoData.info.color, 'stroke': filterAnnoData.info.color}"
              [title]="filterAnnoData.info.type" svgIcon="{{annotationDisplayTypes[filterAnnoData.info.type]}}">
            </mat-icon>
          </ng-container>
          <div class="annote_action">
            <!-- <ng-container *ngIf="!filterAnnoData.isVisible">
              <span *ngIf="!filterAnnoData.isVisible">
                <i class="fa fa-eye fa-xs annotationViewIcon" (click)="viewAnnotaion(filterAnnoData.value)"></i>
              </span>
            </ng-container>
            <span *ngIf="filterAnnoData.isVisible">
              <i class="fa fa-eye fa-xs annotationHideIcon" (click)="hideAnnotaion(filterAnnoData.value)"></i>
            </span> -->
            <span>
              <i class="fa fa-eye fa-xs" [ngClass]="filterAnnoData.isVisible?'annotationHideIcon':'annotationViewIcon'"
                (click)="showHideAnnotaion(filterAnnoData, !filterAnnoData.isVisible)"></i>
            </span>

            <span *ngIf="(filterAnnoData.info.creator == currentUser.userName)">
              <i class="fa fa-trash-o fa-xs annotationDeleteIcon" aria-hidden="true"
                (click)="deleteAnnotaion(filterAnnoData, i)"></i>
            </span>
            <span *ngIf="filterAnnoData.info.bookmarked">
              <i class="fa fa-bookmark fa-xs bookmark_icon" style="color: #EA8823;"></i>
            </span>
          </div>
        </div>
        <div class="annoComment">
          <div class="listViewTitle">Comment</div>
          <div [title]="filterAnnoData.info.comment" class="multiLineElipsis listItemContent listItemComment"
            [innerHtml]="filterAnnoData.info.comment"> </div>
        </div>
        <div class="annoInfo">
          <div class="annoMetadata"
            *ngIf="(filterAnnoData.info.type == 'CIRCLE' || filterAnnoData.info.type == 'RECTANGLE' || filterAnnoData.info.type == 'ELLIPSE')">
            <div class="annoMetadataItem" *ngIf="filterAnnoData?.info?.geomInfo?.area">
              <div class="listViewTitle">Area : <span class="listItemContent"
                  [innerHTML]="filterAnnoData.info.geomInfo.area | number : '1.2-2'"></span></div>

            </div>
            <div class="annoMetadataItem" *ngIf="filterAnnoData?.info?.geomInfo?.perimeter">
              <div class="listViewTitle">Perimeter : <span class="listItemContent"
                  [innerHTML]="filterAnnoData.info.geomInfo.perimeter | number : '1.2-2'"></span>
              </div>

            </div>
          </div>
          <div *ngIf="filterAnnoData.info.type == 'RULER'">
            <div class="annoMetadataItem">
              <div class="listViewTitle">Length : <span class="listItemContent"
                  [innerHTML]="filterAnnoData.length"></span></div>
            </div>
          </div>
          <div *ngIf="filterAnnoData.info.type == 'ANGLE'">
            <div class="annoMetadataItem">
              <div class="listViewTitle">Degree : <span class="listItemContent"
                  [innerHtml]="filterAnnoData.angle + '&#176;'"></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="noAnnoMainDiv" *ngIf="isAnnotationManagerEmpty">
    <i class="fa fa-lg fa-cloud-upload headerFilterIcon noAnnoUploadIcon" (click)="uploadAnnotationFile()"
      title="Upload Annotations"></i>
    <div class="noAnnotationsLabel">No Annotations to display</div>
  </div>
</div>