import { ConfirmDialogComponent } from './../../../common/confirm-dialog/confirm-dialog.component';
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AnnotationService } from './../../../image-viewer/annotation.service';
import { ImageViewerService } from './../../../image-viewer/image-viewer.service';
import { ConsultationViewerService } from '../consultation-viewer.service';
import { CommonService } from 'src/app/common.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.css']
})
export class LeftSideBarComponent implements OnInit {
  @Input() imageViewerObj: any;
  @Input() meetingDetails: any;
  @ViewChild('markerCanvas', { static: false })
  myCanvas: ElementRef<HTMLCanvasElement>;
  context: CanvasRenderingContext2D;
  imageViewer: any;
  osdObj: any;
  threeJsObj: any;
  openseadragon: any;
  isSideBarHidden: boolean = false;
  stopAnnotationInProgress: boolean = false;
  annoboard: any;
  selectedAnnotationShape: any = "Rectangular";
  selectedAnnotationColor: any = "#00ff00";
  selectedAnnotationThickness: any = 2;
  degrees = [0, 45, 90, 135, 180, 225, 270];
  selectedValue = this.degrees[0];
  easyViewReadTypeSelected = "zigzag";
  easyViewStartFrom = ["Current Position", "Top Left"];
  easyViewStartSelected = this.easyViewStartFrom[0];
  moveDirection: any = "xpositive";
  easyviewinterval: any = "";
  containerSizeX: any;
  containerSizeY: any;
  xshift: any;
  yshift: any;
  panlimit: any;
  speedInterval: any = 200;
  easyViewSp: any = "medium";
  sliderRangeValue: number = 0;
  easyviewpopupshow: boolean = false;
  playpsauseeasyview: boolean = false;
  isMyAnnotationClicked: boolean = true;
  isBookmarkedAnnotationChecked: boolean = false;
  isBookmarkAnnoDisabled: boolean = false;
  isSplitView: boolean = false;
  filteredData: any;
  annotationFilterForm: FormGroup;
  sideBarHideTimer: any;
  batchAnnoboard: any;
  stopBtn: boolean = false;
  selectedViewerIndex: number;
  lastEasyViewLocation = {
    lastCenterPos: {},
    lastZoomIndex: 1,
    pauseImmediately: false,
    easyViewNextStep: false,
  };
  annotationParams = {
    isBatch: false,
    type: this.selectedAnnotationShape,
    colour: this.selectedAnnotationColor,
    thickness: this.selectedAnnotationThickness,
    currentUser: '',
    slideId: '',
    meetId: '',
    isExternal: false,
    canvasInfo: {
      topLeftPos: { x: null, y: null },
      topRightPos: { x: null, y: null },
      bottomLeftPos: { x: null, y: null },
      bottomRightPos: { x: null, y: null },
      xPos: 0,
      yPos: 0,
      canvasDimension: { width: null, height: null }
    }
  };
  slideId: any; // not used yet
  isSync: boolean = false;
  currentSideBarArrow: string = 'open';
  panelList: any;
  activeTool: String = '';
  color: any = '';
  isPresenter: boolean = false;
  currentUser: any;
  brightness: number = 0;
  gamma: number = 1;
  contrast: number = 1;
  easyViewSpeed: number = 1;
  imageViewerArr: any;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  filters = {
    brightness: 0,
    gamma: 1,
    contrast: 1
  }

  selectedViewerSchema: any;
  maxZoomLevel: number = 40;
  selectedROI: number = 1;
  isParticipantExternal = false;

  togglePanel(panel: any) {
    if (!this.panelList.Annotation_Setting.isVisible) {
      if ($('.canvasAnnotation:visible').length > 0 || $('.canvasBatchAnnotation:visible').length) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.ANNOTATION_ONPROGRESS"));
        return;
      }
    }
    this.consultationViewerService.setActiveTool(panel);
  }

  currentSlideId: any;
  allImageViewerFilters: any = {};
  constructor(
    private consultationViewerService: ConsultationViewerService,
    private imageViewerService: ImageViewerService,
    private annotationService: AnnotationService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private commonService: CommonService,
    public translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.consultationViewerService.activeTool.subscribe((value) => {
      this.panelList = value;
    });
  }

  ngOnInit() {
    /* this.consultationViewerService.imageViewerArr.subscribe((array) => {
      this.imageViewerArr = array;
    }); */

    this.imageViewerObj.length > 1 ? this.isSplitView = true : this.isSplitView = false;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.consultationViewerService.viewerInfocus.subscribe((value) => {
      this.imageViewer = this.imageViewerObj[value];
      this.osdObj = this.imageViewer?.osdObj;
      this.threeJsObj = this.imageViewer?.threeJsObj;
      this.openseadragon = this.imageViewer?.getOpenseadragon();
      this.annotationParams.slideId = this.imageViewer?.slideData.slide.slidePk;
      this.slideId = this.imageViewer?.slideData.slide.slidePk;
      this.selectedViewerSchema = this.imageViewer?.getViewerSchema();
      this.maxZoomLevel = this.selectedViewerSchema?.imageProfiles.imageProfile[this.selectedROI].resolution;

      if (this.osdObj) {
        this.osdObj.addHandler("canvas-drag", (a) => {
          if (this.lastEasyViewLocation.pauseImmediately) {
            this.pauseeasyview(false);
          }
        });

        this.osdObj.addHandler("zoom", (a) => {
          if (this.sliderRangeValue == this.osdObj.viewport.getZoom(true).toFixed(2)) {
            return;
          }
        });
      }
      $(".tool_sidebar").height(
        $(".tool_sidebar")
          .closest(".viewercontent")
          .height() - 40
      );
      this.imageViewer.sliderRangeValue.subscribe(value => {
        this.sliderRangeValue = value;
      });

      this.imageViewerService.stopAnno.subscribe((value) => {
        if (value) {
          this.annotationParams.isBatch = false;
          this.stopBtn = false;
        }
      });
      /* let currentImageFilter = this.allImageViewerFilters["slideId_" + this.slideId];
      if (currentImageFilter) {
        this.filters = {
          brightness: currentImageFilter.brightness,
          gamma: currentImageFilter.gamma,
          contrast: currentImageFilter.contrast
        }
      } else {
        this.filters = {
          brightness: 0,
          gamma: 1,
          contrast: 1
        }
      }*/
    });

    this.consultationViewerService.selectedImage.subscribe((value: any) => {
      this.currentSlideId = value;
    });

    this.consultationViewerService.allImageViewersFilter.subscribe((value: any) => {
      this.allImageViewerFilters = value;
      let currentImageFilter = this.allImageViewerFilters["slideId_" + this.slideId];
      if (currentImageFilter) {
        this.filters = {
          brightness: currentImageFilter.brightness,
          gamma: currentImageFilter.gamma,
          contrast: currentImageFilter.contrast
        }
      } else {
        this.filters = {
          brightness: 0,
          gamma: 1,
          contrast: 1
        }
      }
    });

    this.consultationViewerService.isPresenter.subscribe((val: any) => {
      this.isPresenter = val;
      if (!this.isPresenter) {
        if (this.annoboard) {
          this.annoboard.hideOverlay();
          // $('#annotation-editor,.editorElement').remove();
          // this.annoboard.destroyBoard();
        }
        if (this.batchAnnoboard) {
          this.stopAnnotation(true);
        }
      }
    });
    this.consultationViewerService.activeTool.subscribe((value) => {
      this.panelList = value
    });
    this.consultationViewerService.rotationValue.subscribe((value) => {
      this.selectedValue = value
    });
    this.consultationViewerService.zoomIndex.subscribe((zoom) => {
      this.sliderRangeValue = zoom;
    });
    this.consultationViewerService.synchronizeImage.subscribe((isSynchronized) => {
      this.isSync = isSynchronized
    });
    this.consultationViewerService.participantUserList.subscribe((participantData: any) => {
      if (!$.isEmptyObject(participantData)) {
        /* this.participantObj = participantData;
        if (this.participantObj[this.currentUser.userName].isExternal) {
          delete this.meetingImages[this.currentUser.userName];
          delete this.meetingImages1[this.currentUser.userName];
        } */
        this.isParticipantExternal = participantData[this.currentUser.userName]?.isExternal
      }
    });
  }

  drawAnnotation() {
    $('.disabledJoystickrotationNavigator,.disableTopBar').show();
    $('#ViewerRotate').addClass('disableFunction');
    $('#ViewerEasyView').addClass('disableFunction');
    if (!this.isSplitView) {
      this.osdObj.navigator.update(this.osdObj.viewport);
    }

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.annotationParams.currentUser = currentUser.userName;
    this.annotationParams.meetId = this.meetingDetails.meeting_pk;
    this.annotationParams.isExternal = this.isParticipantExternal;
    if (this.annotationParams.isBatch) {
      this.stopBtn = true;
    }
    //this.imageViewerService.disableHotKey(true);
    // this.viewerService.setAnnoInfoToCreate(this.annotationParams);
    this.annotationService.resetNewCreateAnnotationInfo(this.annotationParams, this.osdObj, this.threeJsObj);

    this.togglePanel("Annotation_Setting");
  }


  changeAnnotationShape(type: String, shape: String) {
    this.annotationParams.isBatch = (type == "Batch");
    this.annotationParams.type = shape;
  }

  stopAnnotation(isFromSetPresenter: boolean = false) {
    this.annotationService.stopBatchAnno(true);
    this.annotationParams.isBatch = false;
    this.stopBtn = false;
  }

  preStopAnnotation() {
    $('.disableTopBar, .disableRightBar, .viewerDisableMask').hide();
    if (this.isPresenter) {
      $(".disabledJoystickrotationNavigator").hide();
    } else {
      $(".disabledJoystickrotationNavigator").show();
    }
    $('#rotateTab,#imageAdjustTab,#easyViewTab,#imageSynch').removeClass('disableFunction');
    $('#batch_editor').remove();
  }

  postStopAnnotation() {
    this.osdObj.setMouseNavEnabled(this.isPresenter);
    if (this.osdObj.navigator) {
      this.osdObj.navigator.setMouseNavEnabled(this.isPresenter);
    }
    var osdIndex = $("#" + this.osdObj.id).attr("index");
    if (this.imageViewerArr <= 2) {
      if (this.osdObj.viewport.getRotation() > 0) {
        if ($("#osdnavArrowDiv_" + osdIndex).attr("arrow-state") === 'open') {
          $("#osdnavArrowDiv_" + osdIndex).trigger("click");
        }
        $("#osdnavArrowDiv_" + osdIndex).closest(".joystickRotationNavigatorToggle").hide();
      } else {
        $("#osdnavArrowDiv_" + osdIndex).closest(".joystickRotationNavigatorToggle").show();
        if ($("#osdnavArrowDiv_" + osdIndex).attr("arrow-state") === 'close') {
          $("#osdnavArrowDiv_" + osdIndex).trigger("click");
        }
      }
    }
  }

  changeAnnotationColor(color) {
    this.annotationParams.colour = color;
  }

  changeAnnotationThickness(thickness) {
    this.annotationParams.thickness = thickness;
  }

  //  functions for rotation starts
  private positiveModulo(number: number, modulo: number) {
    var result = number % modulo;
    if (result < 0) {
      result += modulo;
    }
    return result;
  }

  rotate() {
    this.consultationViewerService.setRotationValue(this.selectedValue);
  }

  rotateLeft() {
    if (this.osdObj.viewport) {
      var currRotation = this.osdObj.viewport.getRotation();

      if (this.osdObj.viewport.flipped) {
        currRotation = this.positiveModulo(currRotation + 45, 360);
      } else {
        currRotation = this.positiveModulo(currRotation - 45, 360);
      }
      this.consultationViewerService.setRotationValue(currRotation);
    }
  }

  rotateRight() {
    if (this.osdObj.viewport) {
      var currRotation = this.osdObj.viewport.getRotation();

      if (this.osdObj.viewport.flipped) {
        currRotation = this.positiveModulo(currRotation - 45, 360);
      } else {
        currRotation = this.positiveModulo(currRotation + 45, 360);
      }
      this.consultationViewerService.setRotationValue(currRotation);
    }
  }

  toggleSideBar() {
    this.consultationViewerService.setActiveTool("")
    this.currentSideBarArrow = this.currentSideBarArrow === 'open' ? 'close' : 'open';
    $(".sideBarList").animate({
      marginLeft: this.currentSideBarArrow === 'close' ? "0" : "-2.5vw"
    }, "fast");
  }

  setFilters() {
    console.log(this.filters);
    this.allImageViewerFilters["slideId_" + this.slideId] = this.filters;
    // this.consultationViewerService.setAllImageFilters(this.allImageViewerFilters);

    this.consultationViewerService.setImageFilters(this.filters);
    // this.osdObj.setFilterOptions({
    //   filters: {
    //     processors: [
    //       this.openseadragon.Filters.GAMMA(this.gamma),
    //       this.openseadragon.Filters.BRIGHTNESS(this.brightness),
    //       this.openseadragon.Filters.CONTRAST(this.contrast),
    //     ]
    //   },
    //   loadMode: 'sync'
    // });
    // this.consultationViewerService.emitEvent('image-adjustment', {
    //   meetID: this.meetingDetails.meeting_pk,
    //   brightness: this.brightness,
    //   gamma: this.gamma,
    //   contrast: this.contrast,
    //   clientID: this.currentUser.userName
    // });
  }

  resetFilters() {
    this.filters.brightness = 0;
    this.filters.contrast = 1;
    this.filters.gamma = 1;
    this.consultationViewerService.setImageFilters(this.filters);
    // this.brightness = 0;
    // this.gamma = 1;
    // this.contrast = 1
    // this.osdObj.imageFilterInstance.resetFilters()
  }

  // easy view start

  selectEVReadType(type) {
    this.easyViewReadTypeSelected = type;
  }

  setEasyViewSpeed(event) {
    let speed = parseInt(event.target.value);
    this.easyViewSpeed = speed;
    this.speedInterval = 200;
    if (speed == 1) {
      this.easyViewSp = "low";
    } else if (speed == 2) {
      this.easyViewSp = "medium";
    } else if (speed == 3) {
      this.easyViewSp = "high";
    }
    // this.easyViewSp = value;
  }

  changeEasyViewSpeed(event) {
    clearInterval(this.easyviewinterval);
    this.easyviewinterval = null;
    this.setEasyViewSpeed(event);
    // var ctx = $("#markerCanvas")[0].getContext("2d");
    this.easyviewinterval = setInterval(
      () => this.moveByDirections("", this.context),
      600 - this.speedInterval
    );
  }

  starteasyview() {
    var showNavigatorTiming = 0;
    if ($(this.osdObj.navigator.element).is(":hidden")) {
      showNavigatorTiming = 600;
      this.selectedValue = 0;
      this.rotate();
      $('#roundSlider').find(".rs-tooltip.rs-tooltip-text").trigger("click");
    }
    setTimeout(() => {
      if (!this.isSplitView) {
        this.osdObj.navigator.update(this.osdObj.viewport);
      }
      this.playpsauseeasyview = false;
      this.containerSizeX = this.osdObj.viewport.getContainerSize().x;
      this.containerSizeY = this.osdObj.viewport.getContainerSize().y;
      if (this.easyViewStartSelected == "Top Left") {
        this.osdObj.viewport.panTo(
          this.osdObj.viewport.deltaPointsFromPixels(
            new this.openseadragon.Point(
              this.containerSizeX / 2,
              this.containerSizeY / 2
            )
          ),
          false
        );
        setTimeout(() => {
          this.starteasyviewfn();
        }, 1000);
      } else {
        this.starteasyviewfn();
      }
    }, showNavigatorTiming);
  }

  starteasyviewfn() {
    this.togglePanel("Easy_View");
    this.easyviewpopupshow = true;
    this.lastEasyViewLocation.pauseImmediately = true;

    this.osdObj.addHandler("animation", this.animationHandler);

    //Navigator Canvas
    var canvasElem = $(this.osdObj.navigator.canvas).find("canvas");
    let canWidth = $(canvasElem).attr("width");
    let canHeight = $(canvasElem).attr("height");
    if ($(this.osdObj.navigator.canvas).find("#markerCanvas").length == 0) {
      let canvas = this.renderer.createElement('canvas')
      this.renderer.setProperty(canvas, 'id', 'markerCanvas');
      this.renderer.setAttribute(canvas, 'height', canHeight);
      this.renderer.setAttribute(canvas, 'width', canWidth);
      this.renderer.setStyle(canvas, 'position', 'absolute');
      this.context = canvas.getContext('2d')
      this.renderer.appendChild($(canvasElem).closest('.openseadragon-canvas')[0], canvas)

    }

    this.context.fillStyle = "#636363";
    this.context.globalAlpha = 0.5;

    this.context.clearRect(
      0,
      0,
      parseInt(canWidth),
      parseInt(canHeight)
    );
    this.context.fillRect(
      0,
      0,
      parseInt(canWidth),
      parseInt(canHeight)
    );

    if (this.easyViewSp == "low") {
      this.xshift = 100;
      this.yshift = 100;
    } else if (this.easyViewSp == "medium") {
      this.xshift = 175;
      this.yshift = 175;
    } else if (this.easyViewSp == "high") {
      this.xshift = 250;
      this.yshift = 250;
    }
    this.panlimit = 1;

    if (this.easyViewReadTypeSelected == "zigzag") {
      this.moveDirection = "right";
    } else if (this.easyViewReadTypeSelected == "upndown") {
      this.moveDirection = "bottom";
    } else if (this.easyViewReadTypeSelected == "lefttoright") {
      this.moveDirection = "right";
    } else if (this.easyViewReadTypeSelected == "upndownC") {
      this.moveDirection = "bottom";
    }
    this.easyviewinterval = setInterval(
      () => this.moveByDirections("", this.context),
      600 - this.speedInterval
    );

    this.consultationViewerService.emitEvent('easy-view', {
      meetID: this.meetingDetails.meeting_pk,
      isEasyView: true,
      clientID: this.currentUser.userName
    })
  }

  animationHandler = () => {
    var displayRegion = this.osdObj.navigator.displayRegion;
    this.consultationViewerService.setEasyViewStarted(true);
    // var context = $("#markerCanvas")[0].getContext("2d");
    this.context.clearRect(
      displayRegion.offsetLeft,
      displayRegion.offsetTop,
      displayRegion.offsetWidth,
      displayRegion.offsetHeight
    );
  };

  moveByDirections(direction, context) {
    var delta = direction == "backward" ? -1 : 1;
    var bounds = this.osdObj.viewport.getBoundsNoRotate();
    var constrainedBounds = this.osdObj.viewport._applyBoundaryConstraints(
      bounds
    );

    switch (this.moveDirection) {
      case "right":
        if (this.osdObj.viewport.getBounds().getTopRight().x <= 1.02) {
          this.osdObj.viewport.panBy(
            this.osdObj.viewport.deltaPointsFromPixels(
              new this.openseadragon.Point(delta * this.xshift, 0)
            )
          );
        } else {
          if (
            this.osdObj.viewport.getBounds().getBottomLeft().y >
            this.osdObj.world.getHomeBounds().height
          ) {
            this.stopeasyview();
          } else {
            this.lastEasyViewLocation.easyViewNextStep = true;
            if (this.easyViewReadTypeSelected == "zigzag") {
              this.osdObj.removeHandler("animation", this.animationHandler);
              this.osdObj.viewport.panBy(
                this.osdObj.viewport.deltaPointsFromPixels(
                  new this.openseadragon.Point(
                    0,
                    delta * (this.containerSizeY * 0.65)
                  )
                ),
                true
              );
              this.moveDirection = "left";
              this.osdObj.addHandler("animation", this.animationHandler);
            } else if (this.easyViewReadTypeSelected == "lefttoright") {
              this.osdObj.removeHandler("animation", this.animationHandler);
              this.osdObj.viewport.panTo(
                new this.openseadragon.Point(
                  0,
                  this.osdObj.viewport.pointFromPixel(
                    new this.openseadragon.Point(
                      0,
                      delta * (this.containerSizeY * 1.25)
                    )
                  ).y
                ),
                true
              );
              this.osdObj.addHandler("animation", this.animationHandler);
            }
          }
        }
        break;
      case "left":
        var topLeftX = this.osdObj.viewport.getBounds().getTopLeft().x;
        if (topLeftX >= -0.02) {
          this.osdObj.viewport.panBy(
            this.osdObj.viewport.deltaPointsFromPixels(
              new this.openseadragon.Point(-delta * this.xshift, 0)
            )
          );
        } else {
          if (
            this.osdObj.viewport.getBounds().getBottomLeft().y >
            this.osdObj.world.getHomeBounds().height
          ) {
            this.stopeasyview();
          } else {
            this.lastEasyViewLocation.easyViewNextStep = true;
            this.osdObj.removeHandler("animation", this.animationHandler);
            this.osdObj.viewport.panBy(
              this.osdObj.viewport.deltaPointsFromPixels(
                new this.openseadragon.Point(
                  0,
                  delta * (this.containerSizeY * 0.65)
                )
              ),
              true
            );
            this.moveDirection = "right";
            this.osdObj.addHandler("animation", this.animationHandler);
          }
        }
        break;
      case "top":
        if (this.osdObj.viewport.getBounds().getTopRight().y >= 0) {
          this.osdObj.viewport.panBy(
            this.osdObj.viewport.deltaPointsFromPixels(
              new this.openseadragon.Point(0, -delta * this.yshift)
            )
          );
        } else {
          if (
            this.osdObj.viewport.getBounds().getBottomRight().x > 1.02 &&
            this.osdObj.viewport.getBounds().getBottomLeft().y >
            this.osdObj.world.getHomeBounds().height
          ) {
            this.stopeasyview();
          } else {
            this.lastEasyViewLocation.easyViewNextStep = true;
            if (this.easyViewReadTypeSelected == "upndownC") {
              if (this.osdObj.viewport.getBounds().getBottomRight().x > 1.02) {
                this.stopeasyview();
              } else {
                this.osdObj.viewport.panBy(
                  this.osdObj.viewport.deltaPointsFromPixels(
                    new this.openseadragon.Point(
                      delta * (this.containerSizeX * 0.94),
                      0
                    )
                  ),
                  true
                );
                this.moveDirection = "bottom";
              }
            }
          }
        }
        break;
      case "bottom":
        if (bounds.y == constrainedBounds.y) {
          this.osdObj.viewport.panBy(
            this.osdObj.viewport.deltaPointsFromPixels(
              new this.openseadragon.Point(0, delta * this.yshift)
            )
          );
        } else {
          if (
            this.osdObj.viewport.getBounds().getBottomRight().x > 1 &&
            this.osdObj.viewport.getBounds().getBottomLeft().y >
            this.osdObj.world.getHomeBounds().height
          ) {
            this.stopeasyview();
          } else {
            this.lastEasyViewLocation.easyViewNextStep = true;
            if (this.easyViewReadTypeSelected == "upndownC") {
              this.osdObj.viewport.panBy(
                this.osdObj.viewport.deltaPointsFromPixels(
                  new this.openseadragon.Point(
                    delta * (this.containerSizeX * 0.94),
                    0
                  )
                ),
                true
              );
              this.moveDirection = "top";
            } else {
              this.osdObj.removeHandler("animation", this.animationHandler);
              this.osdObj.viewport.panTo(
                new this.openseadragon.Point(
                  this.osdObj.viewport.pointFromPixel(
                    new this.openseadragon.Point(
                      delta * (this.containerSizeX * 1.35),
                      0
                    )
                  ).x,
                  0
                ),
                true
              );
              this.moveDirection = "bottom";
              this.osdObj.addHandler("animation", this.animationHandler);
            }
          }
        }
        break;
    }
  }

  backwardeasyview() {
    if (this.easyviewinterval) {
      this.pauseeasyview(true);
    }
    // var ctx = $("#markerCanvas")[0].getContext("2d");
    this.moveByDirections("backward", this.context);
  }

  fordwardeasyview() {
    if (this.easyviewinterval) {
      this.pauseeasyview(true);
    }
    // var ctx = $("#markerCanvas")[0].getContext("2d");
    this.moveByDirections("", this.context);
  }

  stopeasyview() {
    // var ctx = $("#markerCanvas")[0].getContext("2d");
    this.context.clearRect(
      0,
      0,
      parseInt($("#markerCanvas").attr("width")),
      parseInt($("#markerCanvas").attr("height"))
    );
    this.osdObj.removeHandler("animation", this.animationHandler);
    this.easyviewpopupshow = false;
    this.playpsauseeasyview = false;
    clearInterval(this.easyviewinterval);
    this.easyviewinterval = null;
    this.consultationViewerService.setEasyViewStarted(false);
    this.consultationViewerService.emitEvent('isEasyView', {
      meetID: this.meetingDetails.meeting_pk,
      isEasyView: false,
      clientID: this.currentUser.userName
    })
  }

  pauseeasyview(clearRect) {
    if (clearRect) {
      setTimeout(() => {
        var displayRegion = this.osdObj.navigator.displayRegion;
        // var ctx = $("#markerCanvas")[0].getContext("2d");
        this.context.clearRect(
          displayRegion.offsetLeft,
          displayRegion.offsetTop,
          displayRegion.offsetWidth,
          displayRegion.offsetHeight
        );
      }, 700);
    }

    this.lastEasyViewLocation = {
      lastCenterPos: this.osdObj.viewport.getCenter(true),
      lastZoomIndex: this.osdObj.viewport.getZoom(true),
      pauseImmediately: false,
      easyViewNextStep: false
    };
    this.osdObj.removeHandler("animation", this.animationHandler);
    this.playpsauseeasyview = true;
    clearInterval(this.easyviewinterval);
    this.easyviewinterval = null;
  }

  playeasyview() {
    if (this.lastEasyViewLocation.lastZoomIndex) {
      this.consultationViewerService.zoomToIndex("number", this.lastEasyViewLocation.lastZoomIndex, true);
    }
    if (this.lastEasyViewLocation.lastCenterPos) {
      this.osdObj.viewport.panTo(this.lastEasyViewLocation.lastCenterPos, true);
    }
    this.playpsauseeasyview = false;
    // var ctx = $("#markerCanvas")[0].getContext("2d");
    setTimeout(() => {
      this.lastEasyViewLocation.pauseImmediately = true;
    }, 700);
    this.osdObj.addHandler("animation", this.animationHandler);
    this.easyviewinterval = setInterval(() => {
      this.moveByDirections("", this.context);
    }, 600 - this.speedInterval);
  }

  zoomToIndex = text => {
    this.consultationViewerService.zoomToIndex(text, this.sliderRangeValue);
  };

  resetEasyView() {
    $('#EVType').click();
    this.easyViewStartSelected = this.easyViewStartFrom[0];
    $('#eVSpeedmedium').click();
  }
  // easy view end

  synchronizeImage() {
    if (!this.isSync) {
      this.dialogConfig.width = "auto";
      this.dialogConfig.height = "auto";
      this.dialogConfig.data = {
        headerTitle: "Image Synchronization",
        confirmMsg: this.translate.instant("GENERIC_MSG.VIEWER.START_IMAGE_SYNC"),
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      };
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig);
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isSync = true;
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.IMAGE-VIEWER.SYNC_ON"));
        } else {//incase of cancel
          this.isSync = false;
        }
        this.consultationViewerService.setSynchronization(this.isSync);
      });
    } else {
      this.isSync = false;
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.IMAGE-VIEWER.SYNC_OFF"));
      this.consultationViewerService.setSynchronization(this.isSync);
    }

  }

  setImageAdjustment() {
    this.osdObj.setFilterOptions({
      filters: {
        processors: [
          this.openseadragon.Filters.BRIGHTNESS(this.filters.brightness),
          this.openseadragon.Filters.CONTRAST(this.filters.contrast),
          this.openseadragon.Filters.GAMMA(this.filters.gamma),
        ]
      },
      loadMode: 'sync'
    });
  }

}
