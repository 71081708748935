import { Renderer2, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CommonService } from './../common.service';
import { WebviewerWrapperService } from './models/WebviewerWrapper.service';
import { ImageViewer } from './models/ImageViewer.class';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageViewerService } from './image-viewer.service';
import { AnnotationService } from './annotation.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AppConfigService } from './../app-config.service';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';

import * as Stats from 'src/assets/js/stats.min.js';
// import { FontLoader, Mesh, Vector2 } from 'three';

declare const nipplejs: any;
declare const $: any;
declare const scalebar: any;
declare const OpenSeadragon: any;
// declare const THREE: any;
// declare const Line2: any;
// declare const LineMaterial: any;
// declare const LineGeometry: any;

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css'],
  animations: [
    trigger('slideJoystickRotationNavigator', [
      state('open', style({ right: '0px', })),
      state('close', style({ right: '-12vw', })),
      transition('close => open', [animate(600, style({ transform: 'translateX(-12vw)' }))]),
      transition('open => close', [animate(600, style({ transform: 'translateX(12vw)' }))]),
    ]),
  ]
})
export class ImageViewerComponent implements OnInit, AfterViewInit {

  @ViewChild('viewerContent') private viewerContent: ElementRef;
  openseadragonIDS: any; // to push dynamic openseadragin ids
  scalebarIDS: any; // to push dynamic scalebar ids
  imageStatusIds: any;

  selectedViewerIndex: number = 0;
  imageViewer: any;
  imageViewerArr: any = [];
  isSplitViewEnabled: boolean = false;
  joystickMove: any;
  osdObj: any;
  viewerScreens = 1;
  sliderRangeValue: number;
  currentJoystickRotationNavigatorState = 'open';
  currentArrowState = 'open';
  currentNavigatorArrowState = 'open';
  currentNavigatorArrowState1 = 'open';
  currentNavigatorArrowState2 = 'open';
  currentNavigatorArrowState3 = 'open';
  currentNavigatorArrowState4 = 'open';
  isViewerInitialized: boolean = false;
  isImageNotAvailable: boolean = false;
  zoomIndexClicked: string = null;
  openedFrom: any;
  showTopBar: boolean = false;
  showSideBar: boolean = false;
  showAnnotationManager: boolean = false;
  mySubscription: any;
  rotationValue: number;
  isSynch: boolean = false;
  synchronizationObs: any;
  addThreeJsNewAnnotationSubscribe: any;
  activeRole: String;
  multipleViewerIDs: any;
  slideIds: string[];
  activeImageViewer: String;

  selectedROI: number = -1;
  selectedViewerSchema: any;
  maxZoomLevel: number = 40;

  sliderMinPixelValue: number = 4;
  initZoomIndexArr: any = [2, 5, 10, 20, 40];
  finalZoomIndexArr: any = [];
  appConfig: any;
  currentViewerTileLevel: number = 0;
  fineTuneAppliedLevel: any = {};
  fineTuneNonAppliedLevel: any = {};
  fineTuneEnableCheckObj: any = {};

  isReviewShareable: boolean;
  studyPathologist: String = "";
  studyPeerReviewer: String = "";
  additionnalPathlogists: any = [];
  currentUser: any;
  slidesToCloseArr = [];
  threeJsObj: any = {};
  clientPointX: any;
  clientPointY: any;
  isPerformingTask: boolean = false;
  selectedAnnoUpdatedInfo: any = {};
  // filledAnnoOpacity: number = 0.4;
  textFont: any = null;
  // pixelToNanometer: number;
  prevHighlightedAnno: any;

  //drawAnnotation
  createAnnoInfoFromSideBar: any;

  allAnnotationArr = [];
  lastAnimationZoom: number = null;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    /* setTimeout(() => {
      $.each(this.imageViewerArr, (key, viewerObj) => {
        viewerObj.threeJsObj.threeOverlay.resize(viewerObj.threeJsObj);
        viewerObj.threeJsObj.threeOverlay.resizecanvas(viewerObj.threeJsObj);
        viewerObj.threeJsObj.renderer3.render(viewerObj.threeJsObj.scene, viewerObj.threeJsObj.camera);
        viewerObj.threeJsObj.camera.updateMatrixWorld();
        setTimeout(() => {
          this.isPerformingTask = false;
        }, 500);
      });
    }, 1000); */
  }

  // raycastingArr: any = [];
  drawAnnoScreenshot: any;
  clearResizeTimeout: any = null;
  updateZoomTimeOut: any = null;
  resetFineTuneTimeOut: any = null;
  goHomeTimeOut: any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private viewerService: ImageViewerService,
    private annotationService: AnnotationService,
    private webviewerWrapperService: WebviewerWrapperService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private configService: AppConfigService,
    public translate: TranslateService,
  ) {
    this.appConfig = this.configService.config;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit() {
    localStorage.isViewerInitialized = false;
    this.destroyOsdObjs();
    this.activeRole = localStorage.getItem('userActiveRole');
    this.openedFrom = this.commonService.getViewerOpenedFrom();
    if (this.isViewerInitialized && !this.isSplitViewEnabled && this.openedFrom != 'scanned') {
      this.showSideBar = true;
    }

    if (this.isViewerInitialized && !this.isSplitViewEnabled && this.openedFrom != 'scanned') {
      this.showAnnotationManager = true;
    }

    this.synchronizationObs = this.commonService.synchronizeImage.subscribe((value) => {
      this.isSynch = value;

      if (this.isSynch) {
        var rotation = this.osdObj.viewport.getRotation();
        this.setRotationToViewers(rotation);
      }

      if (this.updateZoomTimeOut) {
        clearTimeout(this.updateZoomTimeOut);
      }

      this.updateZoomTimeOut = setTimeout(() => {
        this.updateZoom(true);
      }, 500);
      /* this.setImageMinMaxZoomLevel((minZoom, MaxZoom) => {
        if (this.isSynch) {
          var rotation = this.osdObj.viewport.getRotation();
          this.setRotationToViewers(rotation);

          let currZoomVal = this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom(true) * this.maxZoomLevel);
          if (currZoomVal < minZoom) {
            let imageToViewPortZoomValue = this.osdObj.viewport.imageToViewportZoom(minZoom / this.maxZoomLevel);
            this.osdObj.viewport.zoomTo(imageToViewPortZoomValue);
          }
        }
        this.updateZoom();
      }); */
    });
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.createAnnoInfoFromSideBar = this.viewerService.annoInfoToCreate.subscribe((value) => {
      this.annotationService.resetNewCreateAnnotationInfo(value);
    });

    this.viewerService.stopAnno.subscribe((value: boolean) => {
      if (value) {
        this.annotationService.resetNewCreateAnnotationInfo({});
        this.osdObj.setMouseNavEnabled(true);
        this.osdObj.panHorizontal = this.osdObj.panVertical = true;
        this.threeJsObj.renderer3.domElement.style.cursor = "default";
        $('.disabledJoystickrotationNavigator,.disableTopBar').hide();
        $('#ViewerRotate').removeClass('disableFunction');
        $('#ViewerEasyView').removeClass('disableFunction');
      }
    });

    this.viewerService.loadAnnotation.subscribe((value: any) => {
      if (Object.keys(value).length) {
        this.loadAnnotations(value.slideId, value.roiIndex, value.role, value.reviewSharable, this.osdObj);
      }
    });

    this.viewerService.loadImportedAnnoResults.subscribe((value: any) => {
      if (value.length && this.osdObj) {
        this.loadAnalysisAnnotation(value);
      }
    });

    this.drawAnnoScreenshot = this.viewerService.drawScreenshot.subscribe((shape: string) => {
      if (shape) {
        // this.osdObj.raiseEvent("animation");
        let canvas = this.threeJsObj.renderer3.domElement;
        canvas.getContext('webgl', { preserveDrawingBuffer: true });
        this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
        this.threeJsObj.camera.updateMatrixWorld();
        var threeImg = new Image();
        threeImg.src = canvas.toDataURL('image/png');

        // let imageData = canvas.toDataURL('image/png');
        canvas.getContext('webgl', { preserveDrawingBuffer: false });
        /* setTimeout(() => {
          console.log(imageData);
        }, 100); */
        this.imageViewer.getScreenshotObj().drawScreenShotShape(shape, this.osdObj.scalebarInstance.scalebarId, threeImg);

/*         var link = document.createElement("a");
        link.href = threeImg.src; //function blocks CORS
        link.download = `test.png`;
        link.click();
 */      }
    });

    this.addThreeJsNewAnnotationSubscribe = this.commonService.addThreeJsAnnotation.subscribe((value) => {
      if (!$.isEmptyObject(value)) {
        let threeJsObj = this.imageViewerArr[Number(this.osdObj.id.replace("openseadragon_", ""))].threeJsObj;
        this.annotationService.threeJsAnnotationSetup([value], this.osdObj, threeJsObj);
        // this.threeJsAnnotationSetup([value], this.osdObj);

        setTimeout(() => {
          this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
          this.threeJsObj.camera.updateMatrixWorld();
          $(".annotaion").remove();
        }, 10);
      }
    });

    // this.viewerService.viewerTileLevel.subscribe((value) => {
    //   this.currentViewerTileLevel = value;
    // });

  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((paramMap: any) => {
      this.viewerService.setRotationValue(0);
      $('.viewercontent').children('.viewer').remove();
      this.openseadragonIDS = [];
      this.scalebarIDS = [];
      this.imageStatusIds = [];
      this.destroyOsdObjs();
      this.isViewerInitialized = false;
      this.isImageNotAvailable = false;
      this.imageViewer = "";
      this.imageViewerArr = [];
      this.isSynch = false;
      this.slideIds = paramMap.id.split(',');
      this.viewerService.setImportedAnnoData([]);
      this.viewerService.setquantificationResults({});
      this.viewerService.setActiveTool("");
      this.createView();
    });
  }

  createView() {
    // this.dialogRef = this.dialog.open(LoaderComponent, this.dialogConfig);
    $(".modalDCLoader").addClass("modalDCLoaderVisible");

    let noOfSlides = this.slideIds.length;
    this.viewerScreens = 0;
    this.isSplitViewEnabled = (noOfSlides > 1) ? true : false;
    for (let i = 0; i < noOfSlides; i++) {
      let container = this.renderer.createElement('div');
      let id = 'openseadragon_' + i;
      this.renderer.setProperty(container, 'id', id);
      this.renderer.setAttribute(container, 'index', i + "");
      this.renderer.setStyle(container, 'height', noOfSlides == 4 ? '50%' : '100%');
      this.renderer.setStyle(container, 'width', (noOfSlides == 4 || noOfSlides == 2) ? '50%' : noOfSlides == 3 ? '33.33%' : '100%');
      this.renderer.setStyle(container, 'position', 'relative');
      this.renderer.setStyle(container, 'padding', '2px');
      this.renderer.addClass(container, 'float-left');
      this.renderer.addClass(container, 'viewer');
      this.renderer.listen(container, 'click', this.selectedViewer.bind(this))
      this.openseadragonIDS.push(id);

      //fitToScreen Icon
      let fitToScreenParentElem = this.renderer.createElement('div');
      let fitToScreenParentElem_id = 'fitToScreenParent_' + i;
      fitToScreenParentElem.classList.add('fitToScreenParent');
      this.renderer.setProperty(fitToScreenParentElem, 'id', fitToScreenParentElem_id);
      // this.renderer.addClass(fitToScreenParentElem, 'fitToScreenParent');

      let fitToScreenIcon = this.renderer.createElement('span');
      fitToScreenIcon.classList.add('fitToScreenIcon');
      fitToScreenIcon.textContent = "F";
      this.renderer.appendChild(fitToScreenParentElem, fitToScreenIcon);

      this.renderer.appendChild(container, fitToScreenParentElem);

      //Zoom Status & Scalebar
      let imageStatusScalebarParentElem = this.renderer.createElement('div');
      let imageStatusScalebarParentElem_id = 'imageStatusScalebarParent_' + i;
      this.renderer.setProperty(imageStatusScalebarParentElem, 'id', imageStatusScalebarParentElem_id);
      this.renderer.addClass(imageStatusScalebarParentElem, 'imageStatusScalebarParent');

      let scalebars = this.renderer.createElement('div');
      let scalebar_id = 'scalebar_' + i;
      this.renderer.setProperty(scalebars, 'id', scalebar_id)
      this.renderer.addClass(scalebars, 'scalebar');
      this.scalebarIDS.push(scalebar_id);

      if (noOfSlides <= 2) {
        let scalebarArrow = this.renderer.createElement('div');
        this.renderer.addClass(scalebarArrow, 'joystickRotationNavigatorToggle');
        this.renderer.appendChild(imageStatusScalebarParentElem, scalebarArrow);

        let osdnavArrowDiv = this.renderer.createElement('div');
        this.renderer.setProperty(osdnavArrowDiv, 'id', ("osdnavArrowDiv_" + i))
        this.renderer.setAttribute(osdnavArrowDiv, 'arrow-state', 'open');
        this.renderer.setAttribute(osdnavArrowDiv, 'nav-id', '' + i);
        this.renderer.appendChild(scalebarArrow, osdnavArrowDiv);
        this.renderer.listen(osdnavArrowDiv, 'click', this.toggleImageNavigator.bind(this));

        let rotateArrow = this.renderer.createElement('i');
        rotateArrow.classList.add('fa', 'fa-chevron-down', 'rotateArrow', 'openArrow');
        this.renderer.appendChild(osdnavArrowDiv, rotateArrow);
      }

      if (noOfSlides > 1) {
        // this.renderer.setStyle(container, 'border', '1px solid #F47625');
        /*uncomment to add checkbox to close viewer
        let checkbox = this.renderer.createElement('input');
        this.renderer.setProperty(checkbox, 'type', "checkbox");
        this.renderer.addClass(checkbox, 'closeCheckbox');
        this.renderer.appendChild(imageStatusScalebarParentElem, checkbox);
        this.renderer.listen(checkbox, 'change', this.closeCheckboxChange.bind(this));
        */

        let imageStatus = this.renderer.createElement('div');
        let imageStatus_id = 'imageStatus_' + i;
        this.renderer.setProperty(imageStatus, 'id', imageStatus_id);
        this.renderer.addClass(imageStatus, 'imageStatus');
        this.renderer.addClass(imageStatus, 'clearfix');
        this.imageStatusIds.push(imageStatus_id);
        this.renderer.appendChild(imageStatusScalebarParentElem, imageStatus);

      }
      // this.renderer.setStyle(imageStatus,'width',(75 * ((screen.width > 1280) ? (screen.width / 1280) : 1)) + 'px')

      this.renderer.appendChild(this.viewerContent.nativeElement, container);
      this.renderer.appendChild(container, imageStatusScalebarParentElem);
      this.renderer.appendChild(imageStatusScalebarParentElem, scalebars);
      this.viewerScreens++;
    }
    this.seqImageViewer(0);
  }

  setImageMinMaxZoomLevel(osdObj: any = null) {
    if (osdObj) {
      osdObj.viewport.maxZoomLevel = osdObj.viewport.imageToViewportZoom(1);
      osdObj.viewport.minZoomLevel = osdObj.viewport.getHomeZoom();
    } else {
      for (let x = 0; x < this.imageViewerArr.length; x++) {
        this.imageViewerArr[x].osdObj.viewport.maxZoomLevel = this.imageViewerArr[x].osdObj.viewport.imageToViewportZoom(1);
        this.imageViewerArr[x].osdObj.viewport.minZoomLevel = this.imageViewerArr[x].osdObj.viewport.getHomeZoom();
      }
    }
  }

  setImageMinMaxZoomLevel_Old(callBack: any = null) {
    let minImgZoom = null, maxImgZoom = null;
    if (this.isSynch) {
      for (let x = 0; x < this.imageViewerArr.length; x++) {
        let minZoom = this.imageViewerArr[x].osdObj.viewport.getHomeZoom();
        let currMinImgZoom = (this.imageViewerArr[x].osdObj.viewport.viewportToImageZoom(minZoom) * this.maxZoomLevel);
        if (!minImgZoom || (minImgZoom < currMinImgZoom)) {
          minImgZoom = currMinImgZoom;
          // minViewportZoom = minZoom;
        }

        /* let maxZoom = this.imageViewerArr[x].osdObj.viewport.imageToViewportZoom(1);
        let currMaxImgZoom = (this.imageViewerArr[x].osdObj.viewport.viewportToImageZoom(maxZoom) * this.maxZoomLevel);
        if (!maxImgZoom || (maxImgZoom > currMaxImgZoom)) {
          maxImgZoom = currMaxImgZoom;
          // maxViewportZoom = maxZoom;
        } */
      }
      for (let y = 0; y < this.imageViewerArr.length; y++) {
        this.imageViewerArr[y].osdObj.viewport.minZoomLevel = this.imageViewerArr[y].osdObj.viewport.imageToViewportZoom(minImgZoom / this.maxZoomLevel);
        // this.imageViewerArr[y].osdObj.viewport.maxZoomLevel = this.imageViewerArr[y].osdObj.viewport.imageToViewportZoom(maxImgZoom / this.maxZoomLevel);
      }

    } else {
      for (let x = 0; x < this.imageViewerArr.length; x++) {
        this.imageViewerArr[x].osdObj.viewport.maxZoomLevel = this.imageViewerArr[x].osdObj.viewport.imageToViewportZoom(1);
        this.imageViewerArr[x].osdObj.viewport.minZoomLevel = this.imageViewerArr[x].osdObj.viewport.getHomeZoom();
      }
    }

    if (callBack) {
      callBack(minImgZoom, maxImgZoom);
    }
  }

  closeCheckboxChange = (event) => {
    let index = this.slidesToCloseArr.indexOf(this.slideIds[this.selectedViewerIndex]);
    if (event.target.checked) {
      if (index == -1) {
        this.slidesToCloseArr.push(this.slideIds[this.selectedViewerIndex]);
        this.commonService.closeViewerArrChanged(this.slidesToCloseArr);
      }
    } else {
      if (index > -1) {
        this.slidesToCloseArr.splice(index, 1);
        this.commonService.closeViewerArrChanged(this.slidesToCloseArr);
      }
    }
  }

  seqImageViewer(index: number) {
    this.getSlideDetails(this.slideIds[index]).then((successData: any) => {
      let viewer = new ImageViewer(successData.data, this.openseadragonIDS[index], this.webviewerWrapperService, (this.slideIds.length > 2),
        (imageData: any) => {
          // console.log(successData.data)
          if (!imageData.osd) {
            $('#' + this.openseadragonIDS[index]).prepend('<img id="theImg" src="assets/images/viewer_icon/default_img.svg" />');
            $('#' + this.openseadragonIDS[index]).find('img').css({
              "height": $('#' + this.openseadragonIDS[index]).height(),
              "width": $('#' + this.openseadragonIDS[index]).width()
            });
            // $('#'+this.openseadragonIDS[index]).removeClass('viewer');
            index = index + 1;
            if (index < this.slideIds.length) {
              this.seqImageViewer(index);
            } else {
              this.imageViewerArr.push(viewer);
              this.slideIds.length == 1 ? this.isImageNotAvailable = true : this.isImageNotAvailable = false;
              $(".modalDCLoader").removeClass("modalDCLoaderVisible");
              // this.dialogRef.close();
              localStorage.isViewerInitialized = true;
            }
          } else {
            //add handlers to all the image viewers
            imageData.osd.viewport.maxZoomLevel = imageData.osd.viewport.imageToViewportZoom(1);
            imageData.osd.viewport.minZoomLevel = imageData.osd.viewport.getHomeZoom();

            $(imageData.osd.canvas).css({ "opacity": 0 });
            imageData.osd.addHandler('pre-full-screen', () => {
              $(".imageStatusScalebarParent, .annotemanger_popup").hide();
            });

            imageData.osd.addHandler('canvas-key', (e) => {
              var x = 0;
              if (this.annotationService.newCreateAnnoInfo.initiateDrawing) {
                // if ([37, 38, 39, 40].indexOf(e.originalEvent.keyCode) >= 0) {
                e.preventDefault = true; // disable default keyboard controls
                e.preventVerticalPan = true; // disable vertical panning with arrows and W or S keys
                e.preventHorizontalPan = true; // disable horizontal panning with arrows and A or D keys
                // }
              } else {
                e.preventDefault = false; // enable default keyboard controls
                e.preventVerticalPan = false; // enable vertical panning with arrows and W or S keys
                e.preventHorizontalPan = false; // enable horizontal panning with arrows and A or D keys
              }

            });

            imageData.osd.addHandler('full-screen', () => {
              setTimeout(() => {
                this.resetScaleBarPos();
              }, 300);
            });

            imageData.osd.addHandler('canvas-drag', () => {
              this.isPerformingTask = true;
              $("#" + this.osdObj.id + "-annoHighlight").hide();
              if ($('.annotationPopOver').length > 0) {
                if (!$('.annotationPopOver').is(":hover")) {
                  $(".annotationPopOver").hide();
                }
              }
            });

            imageData.osd.addHandler('canvas-drag-end', (ev) => {
              var rect = $("#" + ev.eventSource.id + "-overlaycanvas")[0].getBoundingClientRect();
              this.threeJsObj.mouse.x = ((ev.position.x) / (rect.width)) * 2 - 1;
              this.threeJsObj.mouse.y = - ((ev.position.y) / (rect.height)) * 2 + 1;

              this.clientPointX = ev.position.x;
              this.clientPointY = ev.position.y;

              this.isPerformingTask = false;
              this.annotationService.raycasterCallback(ev.eventSource.id == this.osdObj.id, this.clientPointX, this.clientPointY);
            });

            imageData.osd.addHandler('animation-start', () => {
              this.isPerformingTask = true;
              $("#" + this.osdObj.id + "-annoHighlight").hide();
              if (!$('.annotationPopOver').is(":hover")) {
                $(".annotationPopOver").hide();
              }
            });
            imageData.osd.addHandler('animation-finish', (ev) => {
              if (ev.eventSource.id == this.activeImageViewer) {
                this.updateZoom(true);
              }

              this.isPerformingTask = false;
              this.annotationService.raycasterCallback(ev.eventSource.id == this.osdObj.id, this.clientPointX, this.clientPointY);
            });

            imageData.osd.addHandler('resize', (event) => {
              if (this.clearResizeTimeout) {
                clearTimeout(this.clearResizeTimeout);
              }

              this.clearResizeTimeout = setTimeout(() => {
                event.eventSource.viewport.maxZoomLevel = event.eventSource.viewport.imageToViewportZoom(1);
                event.eventSource.viewport.minZoomLevel = event.eventSource.viewport.getHomeZoom();

                if (event.eventSource.navigator) {
                  event.eventSource.navigator.forceRedraw();
                }

                this.resetScaleBarPos();
                if (this.threeJsObj.threeOverlay) {
                  this.threeJsObj.threeOverlay.resize();
                  this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
                  this.threeJsObj.camera.updateMatrixWorld();
                }
                console.log("clearResizeTimeout");
              }, 300);
            });

            imageData.osd.addHandler('zoom', (event: any) => {
              if (event.zoom.toFixed(2) == event.eventSource.viewport._oldZoom.toFixed(2)) {
                return;
              }
              this.resetViewerMinPixelRatio(this.osdObj, true);
              if (event.eventSource.id == this.activeImageViewer) {
                // this.updateZoom(false);
                if (!this.isSplitViewEnabled && this.appConfig.enableFineTune) {
                  if (this.resetFineTuneTimeOut) {
                    clearTimeout(this.resetFineTuneTimeOut);
                  }

                  this.resetFineTuneTimeOut = setTimeout(() => {
                    this.resetFineTune();
                  }, 500);
                  if (this.imageViewerArr.length == 1) {
                    this.checkFineTuneCanEnable();
                  }
                }
                if (event.eventSource.viewport.minZoomLevel > event.zoom) {
                  if (this.goHomeTimeOut) {
                    clearTimeout(this.goHomeTimeOut);
                  }
                  this.goHomeTimeOut = setTimeout(() => {
                    event.eventSource.viewport.goHome();
                  }, 500);
                } else {
                  this.viewerHandler(event, 'zoom');
                }
              } else {
                event.eventSource.setMouseNavEnabled(false);
              }
            }); // TODO

            imageData.osd.addHandler('pan', (event: any) => {
              this.isPerformingTask = true;
              $("#" + this.osdObj.id + "-annoHighlight").hide();
              if (!$('.annotationPopOver').is(":hover")) {
                $(".annotationPopOver").hide();
              }

              if (event.eventSource.id == this.activeImageViewer) {
                this.viewerHandler(event, 'pan');
                // this.updateZoom(false);
              } else {
                event.eventSource.setMouseNavEnabled(false);
              }
            }); // TODO

            imageData.osd.addHandler('animation', (event: any) => {
              $("#" + this.osdObj.id + "-annoHighlight").hide();
              if (this.isSynch) {
                $.each(this.imageViewerArr, (key, viewer) => {
                  let viewerObj = viewer.threeJsObj;

                  let currZoom = parseFloat((viewer.osdObj.viewport.viewportToImageZoom(viewer.osdObj.viewport.getZoom()) * viewerObj.schemaMaxZoomLevel).toFixed(2));
                  this.annotationService.setAnnoTextScale(currZoom, viewerObj);

                  viewerObj.renderer3.render(viewerObj.scene, viewerObj.camera);
                  viewerObj.camera.updateMatrixWorld();
                });
              } else {
                let currZoom = parseFloat((this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom()) * this.threeJsObj.schemaMaxZoomLevel).toFixed(2));
                this.annotationService.setAnnoTextScale(currZoom, this.threeJsObj);
                /* if (this.threeJsObj.lastAnimationZoom != currZoom) {
                  this.threeJsObj.lastAnimationZoom = currZoom;
                  for (let x = 0; x < this.threeJsObj.textArr.length; x++) {
                    this.threeJsObj.textArr[x].scale.set(1 / currZoom, 1 / currZoom, 1);
                  }
                } */

                let value = this.osdObj.viewport.getRotation();
                this.threeJsObj.camera.rotation.z = value * Math.PI / 180;
                this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
                this.threeJsObj.camera.updateMatrixWorld();
              }
            });

            $('#' + imageData.osd.id + '-annoHighlight').on("mouseenter mousemove", (e) => {
              if (!$(".annotationPopOver .annoEditDiv").is(":visible")) {
                $(".annotationPopOver .annoEditDiv").addClass("dn");
                $(".annotationPopOver .annoToolTip").show();
                $(".annotationPopOver").show();
                $(".annotationPopOver").closest(".annoPopOverDiv").css({
                  'width': ($(".annoTooltipIcons").width() + $(".annoText").width()) + this.commonService.vwTOpx('1.25vw'),
                  'height': ($(".annoTooltipIcons").height() + this.commonService.vwTOpx('0.125vw'))
                });
              }
            }).on("mouseleave", (e) => {
              if ($('.annotationPopOver').is(":hover")) {
                return;
              }
              if (!$(".annotationPopOver .annoEditDiv").is(":visible")) {
                $(".annotationPopOver").hide();
              }
            });

            this.annotationService.initiateInputHook(imageData);

            $("#" + imageData.osd.id + "-overlaycanvas").on('mousemove', (event) => {
              var rect = event.currentTarget.getBoundingClientRect();
              this.threeJsObj.mouse.x = ((event.clientX - rect.left) / (rect.width)) * 2 - 1;
              this.threeJsObj.mouse.y = - ((event.clientY - rect.top) / (rect.height)) * 2 + 1;

              let viewerContentPos = $(event.currentTarget).closest(".viewercontent")[0].getBoundingClientRect();
              this.clientPointX = event.clientX - viewerContentPos.left;
              this.clientPointY = event.clientY - viewerContentPos.top;

              if (!this.isPerformingTask) {
                this.annotationService.raycasterCallback(event.currentTarget.id == this.osdObj.id + "-overlaycanvas", this.clientPointX, this.clientPointY);
              }
            });

            imageData.osd.imagefilters({
              crossOriginPolicy: "Anonymous",
              showControl: 0
            });

            // this.pixelToNanometer = imageData.osd.pixelToNanoVal;
            // init scale bar
            imageData.osd.scalebar({
              scaleBarId: this.scalebarIDS[index],
              type: OpenSeadragon.ScalebarType.MICROSCOPY,
              pixelsPerMeter: Math.pow(10, 9) / (imageData.osd.pixelToNanoVal),
              stayInsideImage: false,
              color: "#EA8823",
              fontColor: "rgb(256, 256, 256)",
              backgroundColor: "transparent",
              minWidth: (30 * ((screen.width > 1280) ? (screen.width / 1280) : 1)) + 'px',
              fontSize: "10px",
              location: OpenSeadragon.ScalebarLocation.BOTTOM_RIGHT,
              barThickness: 2,
              showDot: (index == 0)
            });
            imageData.osd.scalebarInstance.refresh();

            var currentViewportZoom = imageData.osd.viewport.getZoom();
            let zoom = imageData.osd.viewport.viewportToImageZoom(currentViewportZoom);
            let currentZoomText = parseFloat((zoom * this.maxZoomLevel).toFixed(2));
            $('#' + this.imageStatusIds[index]).html('<span class="imageStatusZoom" style="font-size: 0.7vw !important">Zoom: ' + currentZoomText +
              'x</span> <span class="imageStatusRotation" style="font-size: 0.7vw !important">Rotation: ' + imageData.osd.viewport.getRotation() + ' &#176;</span>');

            $('#' + this.imageStatusIds[index] + " span").css({
              'padding': '0.2vw',
              'min-width': '5.5vw',
              'display': 'inline-block'
            });

            if ($('#' + this.imageStatusIds[index]).closest(".viewer").find(".navigator").length > 0) {
              $('#' + this.imageStatusIds[index]).css({
                // "bottom": this.commonService.vwTOpx('0.4vw') + 6 + $('#' + this.imageStatusIds[index]).next(".scalebar").height() + ($('#' + this.imageStatusIds[index]).parent(".viewer").find(".navigator").height()),
                "width": $('#' + this.imageStatusIds[index]).closest(".viewer").find(".navigator").width() + 4
              });
              $('#' + this.imageStatusIds[index] + " span").css({
                'width': '45%',
              });
            }

            if ($('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").next('.openseadragon-container').find('.navigator').length > 0) {
              $('#' + this.scalebarIDS[index]).css({
                'width': ($('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").next('.openseadragon-container').find('.navigator').width() + 4),
                'padding': '2px',
                'min-height': '1.5vw'
              });

              $('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").css({
                'bottom': ($('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").next('.openseadragon-container').find('.navigator').height() + 11),
              });
            } else {
              $('#' + this.scalebarIDS[index]).css({
                'width': ($('#' + this.imageStatusIds[index]).width() + 4),
                'padding': '2px',
                'min-height': '1.5vw'
              });

              $('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").css({
                'bottom': 0,
              });
            }
            this.imageViewerArr.push(viewer);
            // call all annotaions
            let currentViewer = this.imageViewerArr.filter((viewerOsd) => {
              return (viewerOsd.imageDisplayed == successData.data.slide.slidePk)
            });
            if (this.activeRole == "PATHOLOGIST") {
              let role = "";
              if (successData.data.study.additionalPathologist.includes(this.currentUser.userName)) {
                role = "ADDITIONAL_PATHOLOGIST";
              } else if (successData.data.study.peerReviewer == this.currentUser.userName) {
                role = 'PEER_REVIEWER'
              } else {
                role = localStorage.userActiveRole
              }
              this.viewerService.setCurrentRole(role);
              this.loadAnnotations(successData.data.slide.slidePk, imageData.osd._sequenceIndex, role, successData.data.study.reviewSharable, currentViewer[0].osdObj);
            }
            index = index + 1;
            if (index < this.slideIds.length) {
              this.seqImageViewer(index);
            } else {
              this.isViewerInitialized = true;
              localStorage.isViewerInitialized = true;

              this.viewerService.setImgViewerArr(this.imageViewerArr);

              var currentViewportZoom = this.imageViewerArr[0].osdObj.viewport.getZoom();
              let zoom = this.imageViewerArr[0].osdObj.viewport.viewportToImageZoom(currentViewportZoom);
              // let currentZoomText = parseFloat((zoom * this.maxZoomLevel).toFixed(2));
              this.sliderRangeValue = parseFloat((zoom * this.maxZoomLevel).toFixed(2));
              this.loadAfterOSD();
              if (this.slideIds.length > 1) { // no need to show dot in case of one viewer
                $(".viewer").css({ "border": "1px solid #848484", "padding": "2px" });
                if (this.imageViewerArr.length > 1) {
                  $('#' + this.openseadragonIDS[0]).css({ "border": "3px solid #F47625", "padding": "0px" });
                }
                $('#' + this.openseadragonIDS[0]).find('.scalebar .dot').show();
              }

              setTimeout(() => {
                if (this.imageViewerArr.length <= 2) {
                  for (let x = 0; x < this.imageViewerArr.length; x++) {
                    if (this.imageViewerArr[x].osdObj.navigator) {
                      this.imageViewerArr[x].osdObj.navigator.update(this.imageViewerArr[x].osdObj.viewport);
                      this.imageViewerArr[x].osdObj.navigator.forceRedraw();
                      setTimeout(() => {
                        this.resetViewerMinPixelRatio(this.imageViewerArr[x].osdObj, true);
                        setTimeout(() => {
                          $(this.imageViewerArr[x].osdObj.canvas).css({ "opacity": 1 });
                          if (x == (this.imageViewerArr.length - 1)) {
                            $(".modalDCLoader").removeClass("modalDCLoaderVisible");
                            // this.dialogRef.close();
                            localStorage.isViewerInitialized = true;
                          }
                        }, 500);
                      }, 500);
                    }
                  }
                } else {
                  for (let x = 0; x < this.imageViewerArr.length; x++) {
                    $(this.imageViewerArr[x].osdObj.canvas).css({ "opacity": 1 });
                  }
                  $(".modalDCLoader").removeClass("modalDCLoaderVisible");
                  // this.dialogRef.close();
                  localStorage.isViewerInitialized = true;
                }
              }, 1500);

              this.commonService.viewerInfocus.subscribe((value) => {
                this.selectedViewerIndex = value;
                this.imageViewer = this.imageViewerArr[value];
                this.osdObj = this.imageViewer.getOsdObject();
                this.selectedViewerSchema = this.imageViewer.getViewerSchema();
                this.maxZoomLevel = this.selectedViewerSchema.imageProfiles.imageProfile[(this.osdObj && this.osdObj._sequenceIndex) ? this.osdObj._sequenceIndex : 1].resolution;

                this.finalZoomIndexArr = this.initZoomIndexArr.filter((zoomIndex) => {
                  return zoomIndex <= this.maxZoomLevel;
                });

                if (this.finalZoomIndexArr[this.finalZoomIndexArr.length - 1] != this.maxZoomLevel) {
                  this.finalZoomIndexArr.push(this.finalZoomIndexArr);
                }

                for (let x = 0; x < this.imageViewerArr.length; x++) {
                  if (this.imageViewerArr[x] && this.imageViewerArr[x].osdObj) {
                    this.imageViewerArr[x].osdObj.setMouseNavEnabled(this.selectedViewerIndex == x);
                  }
                }
                // this.osdObj?.setMouseNavEnabled(true);
                this.activeImageViewer = this.openseadragonIDS[value];
                this.threeJsObj = this.imageViewerArr[this.selectedViewerIndex].threeJsObj;
                this.threeJsObj.schemaMaxZoomLevel = this.maxZoomLevel;
                this.annotationService.threeJsObj = this.imageViewerArr[this.selectedViewerIndex].threeJsObj;
                this.annotationService.osdObj = this.imageViewer.getOsdObject();

                setTimeout(() => {
                  $(".joystickRotationNavigator .zoomingIndexDiv .zoomingIndexItem").css("width", (100 / (this.finalZoomIndexArr.length + 1)) + "%");
                }, 0);
              });

              this.viewerService.rotationValue.subscribe(value => {
                this.setRotationValue(value);
                this.setRotationToViewers(value);
              });
            }
          }
        });// end viewer  
    });
  }

  deleteAllAnnoSuccess() {
    if (this.threeJsObj && this.threeJsObj.scene) {
      for (var i = this.threeJsObj.scene.children.length - 1; i >= 0; i--) {
        let obj = this.threeJsObj.scene.children[i];
        this.threeJsObj.scene.remove(obj);
        obj.geometry.dispose();
        obj.material.dispose();
      }

      this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
      this.threeJsObj.camera.updateMatrixWorld();

      $("#" + this.osdObj.id + "-annoHighlight").css({ "width": 0, "height": 0, "left": 0, "top": 0 }).hide();
    }
  }

  loadAnnotations(slidePk: number, roiIndex: number, role: string, reviewSharable: boolean, osdObj: any) {
    this.deleteAllAnnoSuccess();
    roiIndex = roiIndex - 1;
    this.viewerService.getAllAnnotation(slidePk, roiIndex, role, reviewSharable).subscribe((response: any) => {
      this.allAnnotationArr = response.data[roiIndex] ? response.data[roiIndex] : [];
      // this.allAnnotationArr = this.dummyAnnotationData.data[roiIndex];
      let osdObjIndex = Number(osdObj.id.replace("openseadragon_", ""));
      this.imageViewerArr[osdObjIndex].annotationsArr = this.allAnnotationArr;
      this.annotationService.resetNewCreateAnnotationInfo({});

      let threeJsObj = this.imageViewerArr[osdObjIndex].threeJsObj;
      this.annotationService.threeJsAnnotationSetup(this.allAnnotationArr, osdObj, threeJsObj);
      if (osdObjIndex == this.selectedViewerIndex) {
        this.annotationService.setOnlyAnnotations(this.allAnnotationArr);
      }
      setTimeout(() => {
        this.isPerformingTask = false;
      }, 500);
    });
  }

  loadAnalysisAnnotation(analysisAnnoData: any, annoboard: any = null) {
    let threeJsObj = this.imageViewerArr[Number(this.osdObj.id.replace("openseadragon_", ""))].threeJsObj;
    this.annotationService.threeJsAnnotationSetup(analysisAnnoData, this.osdObj, threeJsObj);
    // this.allAnnotationArr = this.allAnnotationArr.concat(analysisAnnoData);
    let osdObjIndex = Number(this.osdObj.id.replace("openseadragon_", ""));
    this.allAnnotationArr = this.imageViewerArr[osdObjIndex].annotationsArr.concat(analysisAnnoData);
    this.annotationService.setOnlyAnnotations(this.allAnnotationArr);
    setTimeout(() => {
      this.isPerformingTask = false;
    }, 500);
  }

  NavigatorRedraw() {
    this.osdObj.navigator?.update(this.osdObj.viewport);
    this.osdObj.navigator?.forceRedraw();
  }

  checkFineTuneCanEnable() {
    if (this.selectedROI != this.osdObj._sequenceIndex) {
      this.fineTuneEnableCheckObj = {};
      this.selectedROI = this.osdObj._sequenceIndex;
      this.fineTuneAppliedLevel = [];
      this.fineTuneNonAppliedLevel = [];
      this.fineTuneEnableCheckObj.selectedImageViewer = this.imageViewerArr[parseInt($("#" + this.osdObj.id).attr("index"))];
      this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI = this.fineTuneEnableCheckObj.selectedImageViewer.schemaImageProfilePerROI[this.osdObj._sequenceIndex];

      this.fineTuneEnableCheckObj.maxZoomAppliedLevel = (this.maxZoomLevel * 0.15);
      this.fineTuneEnableCheckObj.maxZoomNonAppliedLevel = (this.maxZoomLevel * this.appConfig.tileMinPixelRatio);
      for (var i = 0; i < this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI.length; i++) {
        /* var currZoomAppliedLevelObj = {
          "maxZoomLevel": (maxZoomAppliedLevel * currSchemaImageProfilePerROI[i]),
          "minZoomLevel": ((i < (currSchemaImageProfilePerROI.length - 1)) ? (maxZoomAppliedLevel * currSchemaImageProfilePerROI[i + 1]) : 0),
          "scaleLevel": currSchemaImageProfilePerROI[i],
          "prevLevelScaleLevel": ((i < (currSchemaImageProfilePerROI.length - 1)) ? currSchemaImageProfilePerROI[i + 1] : 0),
        } */
        this.fineTuneEnableCheckObj.currZoomAppliedLevelObj = {
          "maxZoomLevel": (i == 0) ? this.maxZoomLevel : (this.fineTuneEnableCheckObj.maxZoomAppliedLevel * this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i - 1]),
          "minZoomLevel": (this.fineTuneEnableCheckObj.maxZoomAppliedLevel * this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i]),
          "scaleLevel": this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i],
          "prevLevelScaleLevel": ((i < (this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI.length - 1)) ? this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i + 1] : 0),
        }
        this.fineTuneAppliedLevel.push(this.fineTuneEnableCheckObj.currZoomAppliedLevelObj);

        this.fineTuneEnableCheckObj.currZoomNonAppliedLevelObj = {
          "maxZoomLevel": (i == 0) ? this.maxZoomLevel : (this.fineTuneEnableCheckObj.maxZoomNonAppliedLevel * this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i - 1]),
          "minZoomLevel": (this.fineTuneEnableCheckObj.maxZoomNonAppliedLevel * this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i]),
          "scaleLevel": this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i],
          "prevLevelScaleLevel": ((i < (this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI.length - 1)) ? this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i + 1] : 0),
        }
        this.fineTuneNonAppliedLevel.push(this.fineTuneEnableCheckObj.currZoomNonAppliedLevelObj);
      }
    }

    // var CurrentNonAppliedTileLevel, CurrentAppliedTileLevel;
    this.fineTuneEnableCheckObj.currentScaleLevel = this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom());
    this.fineTuneEnableCheckObj.currentZoomLevel = (this.maxZoomLevel * this.fineTuneEnableCheckObj.currentScaleLevel);
    for (var i = 0; i < this.fineTuneNonAppliedLevel.length; i++) {
      var item1 = this.fineTuneNonAppliedLevel[i];
      // if (item1.prevLevelScaleLevel <= this.fineTuneEnableCheckObj.currentScaleLevel && this.fineTuneEnableCheckObj.currentScaleLevel < item1.scaleLevel) {
      if (item1.minZoomLevel <= this.fineTuneEnableCheckObj.currentZoomLevel && this.fineTuneEnableCheckObj.currentZoomLevel < item1.maxZoomLevel) {
        this.fineTuneEnableCheckObj.CurrentNonAppliedTileLevel = item1;
      }
    }
    for (var i = 0; i < this.fineTuneAppliedLevel.length; i++) {
      var item2 = this.fineTuneAppliedLevel[i];
      // if (item2.prevLevelScaleLevel <= this.fineTuneEnableCheckObj.currentScaleLevel && this.fineTuneEnableCheckObj.currentScaleLevel < item2.scaleLevel) {
      if (item2.minZoomLevel <= this.fineTuneEnableCheckObj.currentZoomLevel && this.fineTuneEnableCheckObj.currentZoomLevel < item2.maxZoomLevel) {
        this.fineTuneEnableCheckObj.CurrentAppliedTileLevel = item2;
      }
    }

    // var fineTuneEnable = ((CurrentNonAppliedTileLevel.maxZoomLevel > CurrentAppliedTileLevel.maxZoomLevel) && (CurrentNonAppliedTileLevel.minZoomLevel > CurrentAppliedTileLevel.minZoomLevel));
    // this.fineTuneEnableCheckObj.fineTuneEnable = !((this.fineTuneEnableCheckObj.currentZoomLevel < this.fineTuneEnableCheckObj.CurrentAppliedTileLevel.maxZoomLevel) &&
    // (this.fineTuneEnableCheckObj.currentZoomLevel > this.fineTuneEnableCheckObj.CurrentAppliedTileLevel.minZoomLevel));
    this.fineTuneEnableCheckObj.fineTuneEnable = (this.fineTuneEnableCheckObj.CurrentNonAppliedTileLevel?.scaleLevel != this.fineTuneEnableCheckObj.CurrentAppliedTileLevel?.scaleLevel);
    this.viewerService.setFineFocusEnable(this.fineTuneEnableCheckObj.fineTuneEnable);

  }

  resetViewerMinPixelRatio(osdObj: any, reset: boolean) {
    if (osdObj.world._items.length == 0) {
      return;
    }
    let newMinPixelRatio;
    let appConfigMinPixelRatio = ((this.imageViewerArr.length == 1) ? this.appConfig.tileMinPixelRatio : this.appConfig.tileMinPixelRatioForCompare);
    if (osdObj.world._items[0].minPixelRatio != appConfigMinPixelRatio) {
      newMinPixelRatio = appConfigMinPixelRatio;
    } else {
      return;
    }
    osdObj.minPixelRatio = newMinPixelRatio;
    osdObj.world._items[0].minPixelRatio = newMinPixelRatio;
    osdObj.world.update();
    if (reset) {
      osdObj.world.resetItems();
    }
  }

  resetFineTune() {
    if (!this.osdObj.world || !this.osdObj.world._items || this.osdObj.world._items.length == 0) {
      return;
    }
    let newMinPixelRatio;
    if ($(".fineTune #imgFineTuneOn").is(":visible")) {
      console.log("Fine tune icon visible");
      newMinPixelRatio = this.appConfig.tileMinPixelRatio;
      $(".fineTune img").toggle();
      this.viewerService.setFineFocusMode(false);
    } else {
      console.log("Fine tune icon not visible");
      if (this.osdObj.world._items[0].minPixelRatio != this.appConfig.tileMinPixelRatio) {
        newMinPixelRatio = this.appConfig.tileMinPixelRatio;
      } else {
        return;
      }
    }

    console.log("reset fine tune");
    this.osdObj.minPixelRatio = newMinPixelRatio;
    this.osdObj.world._items[0].minPixelRatio = newMinPixelRatio;
    this.osdObj.world.update();
    this.osdObj.world.resetItems();
  }

  changeMinPixelRatio(event: any, action: string) {
    if (action == "decrease" && this.sliderMinPixelValue > 1) {
      this.sliderMinPixelValue = this.sliderMinPixelValue - 1;
    }

    if (action == "increase" && this.sliderMinPixelValue < 10) {
      this.sliderMinPixelValue = this.sliderMinPixelValue + 1;
    }

    if (event.checked) {
      this.sliderMinPixelValue = 1;
    } else {
      this.sliderMinPixelValue = (this.appConfig.tileMinPixelRatio ? this.appConfig.tileMinPixelRatio : 0.5);
    }

    var sliderMinPixelRatio = this.sliderMinPixelValue / 10;
    this.osdObj.minPixelRatio = sliderMinPixelRatio;
    this.osdObj.world._items[0].minPixelRatio = sliderMinPixelRatio;
    this.osdObj.world.update();
    this.osdObj.world.resetItems();
  }

  selectedViewer(event: any) {
    var viewerCurrTarget = event.currentTarget;
    var currentOsdObjIndex = parseInt($('#' + this.osdObj.id).attr('index'));
    if ($(".disableTopBar").is(":visible") && $(viewerCurrTarget).hasClass('viewer')) {
      if (currentOsdObjIndex != parseInt($(viewerCurrTarget).attr('index'))) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.ANOTHER_VIEWER_SELECTION"));
      }
      return;
    }
    if ($(".annotationPopOver .annoEditDiv").is(":visible")) {
      return;
    }
    var osdObjId = $(viewerCurrTarget).attr("id");
    if ((this.osdObj.id != osdObjId) && $(viewerCurrTarget).hasClass('viewer')) {
      let index = parseInt($('#' + osdObjId).attr('index'));
      this.commonService.focusChanged(index)
      if (!this.isSynch) {
        var currentViewportZoom = this.imageViewerArr[index].osdObj.viewport.getZoom();
        let zoom = this.osdObj.viewport.viewportToImageZoom(currentViewportZoom);
        let currentZoomText = parseFloat((zoom * this.maxZoomLevel).toFixed(2));

        // let zoom = this.imageViewerArr[index].osdObj.viewport.getZoom();
        // this.sliderRangeValue = (currentZoomText > 1) ? currentZoomText : 1;
        this.sliderRangeValue = currentZoomText;


        let currentRotation = this.imageViewerArr[index].osdObj.viewport.degrees;
        this.setRoundSliderValue(currentRotation);
        // this.joystickNavZoomToIndex(event,zoom);
      }
      $('.scalebar .dot').hide();
      $(".viewer").css({ "border": "1px solid #848484", "padding": "2px" });
      if (this.imageViewerArr.length > 1) {
        $(viewerCurrTarget).css({ "border": "3px solid #F47625", "padding": "0px" });
      }
      $(viewerCurrTarget).find('.scalebar .dot').show();
      // $(viewerCurrTarget).css({ "border": "3px solid #F47625", "padding": "0px" }).find('.scalebar .dot').show();
    }
  }

  ngOnDestroy() {
    this.destroyOsdObjs();
    if (this.drawAnnoScreenshot) {
      this.drawAnnoScreenshot.unsubscribe();
    }
    this.viewerService.drawScreenShots('');

    if (this.synchronizationObs) {
      this.synchronizationObs.unsubscribe();
      this.commonService.setSynchronization(false);
    }
    if (this.addThreeJsNewAnnotationSubscribe) {
      this.addThreeJsNewAnnotationSubscribe.unsubscribe();
      this.commonService.threeJsNewAnnotationAdded({});
    }
    this.commonService.focusChanged(0);
    $(".modalDCLoader").removeClass("modalDCLoaderVisible");
    this.viewerService.setRotationValue(0);
    // this.dialogRef.close();
    localStorage.removeItem('slideIdDsData');
  }

  destroyOsdObjs() {
    for (let x = 0; x < this.imageViewerArr.length; x++) {
      if (this.imageViewerArr[x].osdObj) {
        this.imageViewerArr[x].osdObj.destroy();
        this.imageViewerArr[x].osdObj = null;
      }
    }
  }

  private async getSlideDetails(id) {
    return await (this.viewerService.getSlideDetails(id)).toPromise();
  }

  setRoundSliderValue(value) {
    $('#roundSlider').roundSlider('setValue', value);
  }

  private loadAfterOSD() {
    var options = {
      zone: document.getElementById('zone_joystick'),
      size: this.commonService.vwTOpx('3.3vw'),
      color: "#15447E",
      position: { left: '50%', top: '50%' },
      mode: 'static'
    };
    var manager = nipplejs.create(options);
    manager.on('move', this.moveByAngleMain);
    manager.on('end', this.stopMoving);

    manager[0].ui.back.style.background = "#fff";
    manager[0].ui.back.style.opacity = 0.9;
    manager[0].ui.back.style.boxShadow = "0px 0px 0.4vw 0.06vw #aaa";

    manager[0].ui.front.style.background = "rgb(255, 255, 255)";
    manager[0].ui.front.style.opacity = 1;
    manager[0].ui.front.style.boxShadow = "inset #aaa 0px 0px 0.4vw 0.06vw";
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);


    $(".viewerRotationer").html("<div id='roundSlider'></div>");
    $("#roundSlider").roundSlider({
      min: 0,
      max: 360,
      step: 1,
      value: 0,
      radius: this.commonService.vwTOpx('1.75vw'),
      width: this.commonService.vwTOpx('0.5vw'),
      handleSize: "+10",
      startAngle: 0,
      endAngle: "+360",
      animation: true,
      showTooltip: true,
      editableTooltip: false,
      readOnly: false,
      disabled: false,
      keyboardAction: true,
      mouseScrollAction: false,
      sliderType: "default",
      circleShape: "full",
      handleShape: "round",
      lineCap: "square",

      // events
      beforeCreate: null,
      create: (elem) => {
        $(elem.control).find(".rs-tooltip.rs-tooltip-text").html(elem.value + "<span>&#176;</span>");
        $(elem.control).find(".rs-inner, .rs-tooltip-text").click((e) => {
          $('#roundSlider').roundSlider('setValue', 0);
          this.setRotationToViewers(0);
          this.viewerService.setRotationValue(0);
          $('#roundSlider').find(".rs-tooltip.rs-tooltip-text").html("0<span>&#176;</span>");

          var selectedIndex = $(this.osdObj.navigator?.container).closest(".viewer").attr("index");
          if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'close') {
            $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
          }
        });
      },
      start: () => {

        var selectedIndex = $(this.osdObj.navigator?.container).closest(".viewer").attr("index");
        if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'open') {
          $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
        }
        $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").hide();
      },
      drag: (elem) => {
        $(elem.control).find(".rs-tooltip.rs-tooltip-text").html(elem.value + "<span>&#176;</span>");
        this.osdObj.viewport.setRotation(elem.value);
        this.setRotationToViewers(elem.value);
        /* if (elem.value == 0 || elem.value == 45 || elem.value == 90 || elem.value == 135 || elem.value == 180 || elem.value == 225 || elem.value == 270) {
          this.imageViewer.setRotationValue(elem.value);
        } */
        this.viewerService.setRotationValue(elem.value);
      },
      change: (elem) => {
        if (elem) {
          $(elem.control).find(".rs-tooltip.rs-tooltip-text").html(elem.value + "<span>&#176;</span>");
          this.osdObj.viewport.setRotation(elem.value);
          this.setRotationToViewers(elem.value);
          this.viewerService.setRotationValue(elem.value);

          var selectedIndex = $(this.osdObj.navigator?.container).closest(".viewer").attr("index");
          if (elem.value == 0) {
            if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'close') {
              $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").show();
              $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
            }
          } else {
            if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'open') {
              $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
            }
            $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").hide();
          }
        }
      },
      stop: (elem) => {
        $(elem.control).find(".rs-tooltip.rs-tooltip-text").html(elem.value + "<span>&#176;</span>");
        if (elem.value == 0) {
          if (!this.isSplitViewEnabled) {
            if (this.currentNavigatorArrowState === 'close') {
              $("#osdnavArrowDiv").closest(".joystickRotationNavigatorToggle").show();
              $("#osdnavArrowDiv").trigger("click");
            }
          } else {
            if (this.osdObj.id == "openseadragon1" || this.isSynch) {
              if (this.currentNavigatorArrowState1 === 'close') {
                $("#osdnavArrowDiv1").closest(".joystickRotationNavigatorToggle").show();
                $("#osdnavArrowDiv1").trigger("click");
              }
            }
            if (this.osdObj.id == "openseadragon2" || this.isSynch) {
              if (this.currentNavigatorArrowState2 === 'close') {
                $("#osdnavArrowDiv2").closest(".joystickRotationNavigatorToggle").show();
                $("#osdnavArrowDiv2").trigger("click");
              }
            }
            if (this.osdObj.id == "openseadragon3" || this.isSynch) {
              if (this.currentNavigatorArrowState3 === 'close') {
                $("#osdnavArrowDiv3").closest(".joystickRotationNavigatorToggle").show();
                $("#osdnavArrowDiv3").trigger("click");
              }
            }
            if (this.osdObj.id == "openseadragon4" || this.isSynch) {
              if (this.currentNavigatorArrowState4 === 'close') {
                $("#osdnavArrowDiv4").closest(".joystickRotationNavigatorToggle").show();
                $("#osdnavArrowDiv4").trigger("click");
              }
            }
          }
        }
      },
      tooltipFormat: null
    });
    if (this.imageViewer) {
      this.imageViewer.sliderRangeValue.subscribe(value => {
        this.sliderRangeValue = value;
      });
    }

  }

  private setRotationValue(value) {
    $('#roundSlider').roundSlider('setValue', value);
    if (value == 0) {
      if (!this.isSplitViewEnabled) {
        if (this.currentNavigatorArrowState === 'close') {
          $("#osdnavArrowDiv").closest(".joystickRotationNavigatorToggle").show();
          $("#osdnavArrowDiv").trigger("click");
        }
      } else {
        if (this.osdObj.id == "openseadragon1" || this.isSynch) {
          if (this.currentNavigatorArrowState1 === 'close') {
            $("#osdnavArrowDiv1").closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv1").trigger("click");
          }
        }
        if (this.osdObj.id == "openseadragon2" || this.isSynch) {
          if (this.currentNavigatorArrowState2 === 'close') {
            $("#osdnavArrowDiv2").closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv2").trigger("click");
          }
        }
        if (this.osdObj.id == "openseadragon3" || this.isSynch) {
          if (this.currentNavigatorArrowState3 === 'close') {
            $("#osdnavArrowDiv3").closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv3").trigger("click");
          }
        }
        if (this.osdObj.id == "openseadragon4" || this.isSynch) {
          if (this.currentNavigatorArrowState4 === 'close') {
            $("#osdnavArrowDiv4").closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv4").trigger("click");
          }
        }
      }
    } else {
      if (!this.isSplitViewEnabled) {
        if (this.currentNavigatorArrowState === 'open') {
          $("#osdnavArrowDiv").trigger("click");
        }
        $("#osdnavArrowDiv").closest(".joystickRotationNavigatorToggle").hide();
      } else {
        if (this.osdObj.id == "openseadragon1" || this.isSynch) {
          if (this.currentNavigatorArrowState1 === 'open') {
            $("#osdnavArrowDiv1").trigger("click");
          }
          $("#osdnavArrowDiv1").closest(".joystickRotationNavigatorToggle").hide();
        }
        if (this.osdObj.id == "openseadragon2" || this.isSynch) {
          if (this.currentNavigatorArrowState2 === 'open') {
            $("#osdnavArrowDiv2").trigger("click");
          }
          $("#osdnavArrowDiv2").closest(".joystickRotationNavigatorToggle").hide();
        }
        if (this.osdObj.id == "openseadragon3" || this.isSynch) {
          if (this.currentNavigatorArrowState3 === 'open') {
            $("#osdnavArrowDiv3").trigger("click");
          }
          $("#osdnavArrowDiv3").closest(".joystickRotationNavigatorToggle").hide();
        }
        if (this.osdObj.id == "openseadragon4" || this.isSynch) {
          if (this.currentNavigatorArrowState4 === 'open') {
            $("#osdnavArrowDiv4").trigger("click");
          }
          $("#osdnavArrowDiv4").closest(".joystickRotationNavigatorToggle").hide();
        }
      }
    }

    $('#roundSlider').find(".rs-tooltip.rs-tooltip-text").html(value + "<span>&#176;</span>");
  }

  setRotationToViewers(value: number) {
    if (this.isSynch) { // for multiple viewers and in synchronization
      this.imageViewerArr.forEach((viewer) => {
        viewer.osdObj.viewport.setRotation(value);
        this.rotateAnnotations(value, viewer.threeJsObj);
        $('#' + viewer.osdObj.id).find(".imageStatusRotation").html("Rotation: " + value + " &#176;");
      });
      this.setImageMinMaxZoomLevel();
    } else { // for single viewer or multiple viewer but not in synchronization
      if (!this.osdObj) {
        return;
      }
      this.osdObj.viewport.setRotation(value);
      this.rotateAnnotations(value, this.imageViewer.threeJsObj);
      $('#' + this.osdObj.id).find(".imageStatusRotation").html("Rotation: " + value + " &#176;");
      this.setImageMinMaxZoomLevel(this.osdObj);

      let homeZoom = parseFloat(this.osdObj.viewport.minZoomLevel.toFixed(2));
      let currZoom = parseFloat(this.osdObj.viewport.getZoom(true).toFixed(2));

      if (homeZoom == currZoom) {
        $("#" + this.osdObj.id).find(".fitToScreenParent").removeClass("dn");
      } else {
        $("#" + this.osdObj.id).find(".fitToScreenParent").addClass("dn");
      }

      if (currZoom < homeZoom) {
        this.osdObj.viewport.goHome();
      }
    }

  }

  rotateAnnotations(value, threeJsObj: any) {
    if (!threeJsObj) {
      return;
    }
    threeJsObj.camera.rotation.z = value * Math.PI / 180;
    threeJsObj.renderer3.render(threeJsObj.scene, threeJsObj.camera);
    threeJsObj.camera.updateMatrixWorld();
  }

  moveByAngleMain = (evt, nipple) => {
    this.moveByAngle(evt, nipple);
    clearInterval(this.joystickMove);
    this.joystickMove = setInterval(() => {
      this.moveByAngle(evt, nipple);
    }, 0);
  }
  moveByAngle = (evt, nipple) => {
    var angle = nipple.angle.degree;
    var distance = nipple.distance;
    var X = (distance * Math.cos(angle * (22 / 7) / 180));
    var Y = (distance * -Math.sin(angle * (22 / 7) / 180));

    this.osdObj.viewport.panBy(this.osdObj.viewport.deltaPointsFromPixels(new OpenSeadragon.Point(X, Y)));
    this.osdObj.viewport.applyConstraints();
  }
  stopMoving = () => {
    clearInterval(this.joystickMove);
  }
  joystickNavZoomToIndex = (event, text) => {
    // $(event.currentTarget).closest(".joystickRotationNavigator").find(".zoomingIndex").find("div").css("background-color", "transparent");
    // if (typeof text == 'number') {
    //   this.zoomIndexClicked = text + "x";
    //   $(".joystickRotationNavigator").find(".zoomingIndex").find("." + this.zoomIndexClicked).css("background-color", "#EA8823");
    // }
    this.imageViewer.zoomToIndex(text, this.sliderRangeValue, event);
    // this.checkFlip();
  }

  sliderRangeValueText(sliderVal: any) {
    let strSliderVal = sliderVal;
    if (typeof strSliderVal == 'number') {
      strSliderVal = strSliderVal.toFixed(2);
    }

    $(".joystickRotationNavigator").find(".zoomingIndexDiv").find(".zoomingIndexItem").css("background-color", "transparent");
    $(".joystickRotationNavigator").find(".zoomingIndexDiv").find("." + parseFloat(strSliderVal) + 'x').css("background-color", "#EA8823");

    return strSliderVal;
  }

  fitToScreen = (event) => {
    let homeZoom = this.osdObj.viewport.getHomeZoom();
    let currZoom = this.osdObj.viewport.getZoom();
    if (homeZoom.toFixed(2) == currZoom.toFixed(2)) {
      return;
    }
    $(event.currentTarget).closest(".joystickRotationNavigator").find(".zoomingIndexDiv").find(".zoomingIndexItem").css("background-color", "transparent");
    this.osdObj.viewport.goHome();
    $("#" + this.osdObj.id).find(".fitToScreenParent").removeClass("dn");
    /* this.setImageMinMaxZoomLevel((minZoom, MaxZoom) => {
      if (this.isSynch) {
        if (minZoom) {
          let imageToViewPortZoomValue = this.osdObj.viewport.imageToViewportZoom(minZoom / this.maxZoomLevel);
          this.osdObj.viewport.zoomTo(imageToViewPortZoomValue);
        }
      } else {
        this.osdObj.viewport.goHome();
        $("#" + this.osdObj.id).find(".fitToScreenParent").removeClass("dn");
      }
    }); */

    this.zoomIndexClicked = "fitToScreen";
    $(".joystickRotationNavigator").find(".zoomingIndexDiv").find("." + this.zoomIndexClicked).css("background-color", "#EA8823");
    // this.checkFlip();
  }
  checkFlip = () => {
    if (this.osdObj.viewport.getFlip()) {
      setTimeout(() => {
        $.each($(".annotaion"), function (i, itemElem) {
          $(itemElem).css({
            "left": ($(itemElem.parentElement).width() - ($(itemElem).width() + parseFloat($(itemElem).css("left")))),
            "transform": "scale(-1, 1)"
          });
        });
      }, 500);
    }
  }
  toggleJoystickRotationNavigator = () => {
    this.currentJoystickRotationNavigatorState = this.currentJoystickRotationNavigatorState === 'open' ? 'close' : 'open';
    this.currentArrowState = this.currentArrowState === 'open' ? 'close' : 'open';
  }

  toggleImageNavigatorOld = (event, navId) => {
    this["currentNavigatorArrowState" + navId] = this["currentNavigatorArrowState" + navId] === 'open' ? 'close' : 'open';
    var incTop = 0;
    if (this["currentNavigatorArrowState" + navId] === 'close') {
      incTop = ($(this["osdObj" + navId].navigator.element).height());
      this["osdObj" + navId].navigator.element.style.display = (this["currentNavigatorArrowState" + navId] === 'close') ? 'none' : 'inline-block';
    } else {
      incTop = -($(this["osdObj" + navId].navigator.element).height());
    }
    $(event.currentTarget).closest(".osdNavigator").animate({
      top: "+=" + incTop
    }, 500, () => {
      if (this["currentNavigatorArrowState" + navId] === 'open') {
        this["osdObj" + navId].navigator.element.style.display = 'inline-block';
      }
    })
  }

  toggleImageNavigator = (event) => {
    var currState = $(event.currentTarget).attr('arrow-state') === 'open' ? 'close' : 'open';
    $(event.currentTarget).attr('arrow-state', currState);
    var navId = $(event.currentTarget).attr('nav-id');
    var incBottom = 0;
    var currNavElem = this.imageViewerArr[navId].osdObj.navigator.element;
    if (currState === 'close') {
      incBottom = -($(currNavElem).height());
      currNavElem.style.display = (currState === 'close') ? 'none' : 'block';
    } else {
      incBottom = ($(currNavElem).height());
    }

    $(event.currentTarget).find("i").removeClass("openArrow").removeClass("closeArrow").addClass(currState == 'open' ? 'openArrow' : 'closeArrow')

    $(event.currentTarget).closest(".imageStatusScalebarParent").animate({
      bottom: "+=" + incBottom
    }, 500, () => {
      if (currState === 'open') {
        currNavElem.style.display = 'block';
      }
    })
  }

  resetScaleBarPos() {
    if (!this.scalebarIDS) {
      return;
    }
    for (let x = 0; x < this.scalebarIDS.length; x++) {
      if (this.imageStatusIds[x] && ($('#' + this.imageStatusIds[x]).closest(".imageStatusScalebarParent").next('.openseadragon-container').find(".navigator").length > 0)) {
        $('#' + this.imageStatusIds[x]).css({
          // "bottom": this.commonService.vwTOpx('0.4vw') + 6 + $('#' + this.imageStatusIds[x]).next(".scalebar").height() + ($('#' + this.imageStatusIds[x]).parent(".viewer").find(".navigator").height()),
          "width": $('#' + this.imageStatusIds[x]).closest(".imageStatusScalebarParent").next('.openseadragon-container').find(".navigator").width() + 4
        });
      }

      if ($('#' + this.scalebarIDS[x]).closest('.imageStatusScalebarParent').next('.openseadragon-container').find('.navigator').length > 0) {
        $('#' + this.scalebarIDS[x]).css({
          'width': ($('#' + this.scalebarIDS[x]).closest('.imageStatusScalebarParent').next('.openseadragon-container').find('.navigator').width() + 4),
          // 'bottom': ($('#' + this.scalebarIDS[x]).next('.openseadragon-container').find('.navigator').height() + 9),
          'padding': '2px'
        });

        var navigator = $('#' + this.scalebarIDS[x]).closest(".imageStatusScalebarParent").next('.openseadragon-container').find('.navigator:visible');
        $("#imageStatusScalebarParent_" + x).css({
          "bottom": (navigator.length > 0) ? ($(navigator).height() + 9) : 9
        });

        // $("#" + this.scalebarIDS[x] + " .scalebarMainDiv").width((this.imageViewerArr[x].osdObj.navigator.canvas.offsetWidth - 75) + 'px');
        if ($("#" + this.scalebarIDS[x] + " .scalebarMainDiv").length > 0) {
          $("#" + this.scalebarIDS[x] + " .scalebarMainDiv").width((this.imageViewerArr[x].osdObj.navigator.canvas.offsetWidth - this.commonService.vwTOpx("6vw")) + 'px');
        }
      } else {
        $('#' + this.scalebarIDS[x]).css({
          'width': ($('#' + this.imageStatusIds[x]).width() + 4),
          // 'bottom': 0,
          'padding': '2px'
        });
        $("#imageStatusScalebarParent_" + x).css({
          "bottom": 0
        });
      }

      $("#imageStatusScalebarParent_" + x).show();
    }

    if (this.scalebarIDS.length == 1) {
      if (!$(".annotemanger_popup").hasClass("hideAnnoManager")) {
        $(".annotemanger_popup").show();
      }
      if ($(".annotemanger_popup").hasClass("annoteManagerOpen")) {
        $('.annotemanger_popup').css("top", ($(".contenttab").height() - ($(".annotemanger_popup").height())));
      } else {
        $(".annotemanger_popup").css("top", $(".contenttab").height() - ($(".annotemanger_popup .headmanger").height() + this.commonService.vwTOpx('1.4vw')))
      }
    }
  }

  viewerResize = (osdObj, id) => {
    $(id).css({
      "top": ($(osdObj.element).height() - ($(osdObj.navigator.element).parent("div").height() + $(id).height())),
      "left": ($(osdObj.element).width() - $(osdObj.navigator.element).parent("div").width()),
      "width": $(osdObj.navigator.element).parent("div").width(),
      "height": $(id).height()
    });

    if (id == "#openseadragon2NavBar") {
      $(id).css({
        "left": (($(this.osdObj.element).width() + $(osdObj.element).width()) - $(osdObj.navigator.element).parent("div").width()),
      });
    }
  }

  // checkTileLevel = (event) => {
  //   if (!this.isSplitViewEnabled && this.appConfig?.enableFineTune) {
  //     if (this.currentViewerTileLevel != parseInt(localStorage.viewerTileLevel)) {
  //       this.viewerService.setTileLevel(parseInt(localStorage.viewerTileLevel));
  //     }
  //   }
  // }

  updateZoom = (furtherAction: boolean = false) => {
    // this.checkFlip();
    // this.checkTileLevel();
    if (!this.osdObj) {
      return;
    }
    var selectedOsdObj = this.osdObj;
    var currentViewportZoom = this.osdObj.viewport.getZoom(true);
    var selectedViewerZoom = parseFloat(this.osdObj.viewport.viewportToImageZoom(currentViewportZoom * this.maxZoomLevel).toFixed(2));

    if (this.isSynch) {
      let osdCoOrdinates = this.osdObj.viewport.getCenter();
      for (let a = 0; a < this.imageViewerArr.length; a++) {
        if (selectedOsdObj.id != this.imageViewerArr[a].osdObj.id) {
          let currMaxZoomLevel = this.imageViewerArr[a].schemaObj.imageProfiles.imageProfile[1].resolution;

          let currVPHomeZoom = this.imageViewerArr[a].osdObj.viewport.minZoomLevel;
          let currImgHomeZoom = parseFloat(this.imageViewerArr[a].osdObj.viewport.viewportToImageZoom(currVPHomeZoom * currMaxZoomLevel).toFixed(2));

          if (selectedViewerZoom >= currImgHomeZoom) {
            let imageToViewPortZoomValue = this.imageViewerArr[a].osdObj.viewport.imageToViewportZoom(selectedViewerZoom / this.maxZoomLevel);
            this.imageViewerArr[a].osdObj.viewport.zoomTo(imageToViewPortZoomValue);
            $('#' + this.imageViewerArr[a].osdObj.id).find(".imageStatusZoom").html("Zoom: " + selectedViewerZoom + "x");
            $("#" + this.imageViewerArr[a].osdObj.id).find(".fitToScreenParent").addClass("dn");
          } else {
            let currVPZoom = this.imageViewerArr[a].osdObj.viewport.getZoom(true);
            let currImgZoom = parseFloat(this.imageViewerArr[a].osdObj.viewport.viewportToImageZoom(currVPZoom * currMaxZoomLevel).toFixed(2));

            if (currImgZoom != currImgHomeZoom) {
              this.imageViewerArr[a].osdObj.viewport.goHome();
              $('#' + this.imageViewerArr[a].osdObj.id).find(".imageStatusZoom").html("Zoom: " + currImgHomeZoom + "x");
              $("#" + this.imageViewerArr[a].osdObj.id).find(".fitToScreenParent").removeClass("dn");
            }
          }
          this.imageViewerArr[a].osdObj.viewport.panTo(osdCoOrdinates);
        }
      }
    }
    let homeZoom = parseFloat(this.osdObj.viewport.getHomeZoom().toFixed(2));
    let currZoom = parseFloat(currentViewportZoom.toFixed(2));

    if (homeZoom == currZoom) {
      $("#" + this.osdObj.id).find(".fitToScreenParent").removeClass("dn");
    } else {
      $("#" + this.osdObj.id).find(".fitToScreenParent").addClass("dn");
    }

    if (!furtherAction) {
      return;
    }

    this.sliderRangeValue = (Math.round(selectedViewerZoom * 100) / 100);
    $(".joystickRotationNavigator").find(".zoomingIndexDiv").find(".zoomingIndexItem").css("background-color", "transparent");
    if (this.zoomIndexClicked) {
      $(".joystickRotationNavigator").find(".zoomingIndexDiv").find("." + this.zoomIndexClicked).css("background-color", "#EA8823");
      this.zoomIndexClicked = null;
    }
    if (this.imageViewer) {
      this.imageViewer.setZoomValue(this.sliderRangeValue.toFixed(2));
      $('#' + this.imageViewer.osdObj.id).find(".imageStatusZoom").html("Zoom: " + this.sliderRangeValue.toFixed(2) + "x");
    }
  }

  viewerHandler(event: any, action) {
    if (this.isSynch) {
      let currImgZoom;
      let currCoOrdinates = event.eventSource.viewport.getCenter();
      if (action == 'zoom') {
        let currentZoom = event.eventSource.viewport.getZoom();
        var currentZoomText = event.eventSource.viewport.viewportToImageZoom(currentZoom);
        currImgZoom = parseFloat((currentZoomText * this.maxZoomLevel).toFixed(2));
      }
      for (let a = 0; a < this.imageViewerArr.length; a++) {
        let imageViewer = this.imageViewerArr[a];
        if (event && (event.eventSource.id != imageViewer.osdObj.id)) {

          if (action == 'zoom') {
            let currMaxZoomLevel = imageViewer.schemaObj.imageProfiles.imageProfile[1].resolution;

            let currVPHomeZoom = imageViewer.osdObj.viewport.minZoomLevel;
            let currImgHomeZoom = parseFloat(imageViewer.osdObj.viewport.viewportToImageZoom(currVPHomeZoom * currMaxZoomLevel).toFixed(2));

            if (currImgZoom >= currImgHomeZoom) {
              let imageToViewPortZoomValue = imageViewer.osdObj.viewport.imageToViewportZoom(currImgZoom / this.maxZoomLevel);
              imageViewer.osdObj.viewport.zoomTo(imageToViewPortZoomValue);
              $('#' + imageViewer.osdObj.id).find(".imageStatusZoom").html("Zoom: " + currImgZoom + "x");
              $("#" + imageViewer.osdObj.id).find(".fitToScreenParent").addClass("dn");
            } else {
              let currVPZoom = imageViewer.osdObj.viewport.getZoom(true);
              let currImgZoom = parseFloat(imageViewer.osdObj.viewport.viewportToImageZoom(currVPZoom * currMaxZoomLevel).toFixed(2));

              if (currImgZoom != currImgHomeZoom) {
                imageViewer.osdObj.viewport.goHome();
                $('#' + imageViewer.osdObj.id).find(".imageStatusZoom").html("Zoom: " + currImgHomeZoom + "x");
                $("#" + imageViewer.osdObj.id).find(".fitToScreenParent").removeClass("dn");
              }
            }
          }

          imageViewer.osdObj.viewport.panTo(currCoOrdinates);
        }
      }
    }
  }
}
