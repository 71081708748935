import { AppConfigService } from './../../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  private config: any;
  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  getMeetingCount() {
    return this.http.get(`${this.config.dcUrl}common/count`);
  }

  getMeetingList(meetingType: any) {
    return this.http.get(`${this.config.dcUrl}meetings?listingStatus=${meetingType}`);
    // return this.http.get(`http://172.28.43.141:8200/meetings`);
  }

  createMeeting(meetingData: any) {
    //return this.http.post(`${this.config.apiUrl}meetings`,meetingData);
    return this.http.post(`${this.config.dcUrl}meetings`, meetingData);
  }

  getTimezoneList() {
    //return this.http.get(`${this.config.apiUrl}timezones`);
    return this.http.get(`${this.config.dcUrl}timezones`);
  }

  getMeetingDetail(meetingId: any) {
    //return this.http.get(`${this.config.apiUrl}timezones`);
    return this.http.get(`${this.config.dcUrl}meetings/${meetingId}`);
  }

  deleteMeeting(meetingId: any) {
    //return this.http.get(`${this.config.apiUrl}timezones`);
    return this.http.delete(`${this.config.dcUrl}meetings/${meetingId}`);
  }

  editMeeting(meetingId: any, meetingData: any) {
    //return this.http.get(`${this.config.apiUrl}timezones`);
    return this.http.patch(`${this.config.dcUrl}meetings/${meetingId}`, meetingData);
  }
}
