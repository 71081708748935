import { Icons } from './icons';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {

  }

  registerIcons() {
    this.load(Icons, '../assets/images/svg')
  }

  private load(icons: any, url: string) {
    Object.keys(icons).forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${url}/${icons[icon]}.svg`)
      )
    });
  }
}
