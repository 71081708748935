import { AnnotationService } from './../image-viewer/annotation.service';
import { ViewerHoverDirective } from './models/Hover.Directive.model';
import { RouterModule } from '@angular/router';
import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewerComponent } from './image-viewer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageViewerRoutingModule } from './image-viewer-routing.module';
import { ImageViewerTopBarComponent } from './image-viewer-top-bar/image-viewer-top-bar.component';
import { ImageViewerSideBarComponent } from './image-viewer-side-bar/image-viewer-side-bar.component';
import { ImageViewerTopBarService } from './image-viewer-top-bar/image-viewer-top-bar.service';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { AnnotationManagerComponent } from './annotation-manager/annotation-manager.component';
import { WebviewerWrapperService } from './models/WebviewerWrapper.service';
import { AppMaterialModule } from '../app-material/app-material.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { InputErrorPipe } from './models/input-error.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AnnoCommentPopupComponent } from './anno-comment-popup/anno-comment-popup.component';
import { ImageSlideTrayComponent } from './image-slide-tray/image-slide-tray.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatTabsModule } from '@angular/material/tabs';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { IconService } from './../icons-setting/Icons.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    ImageViewerComponent,
    ImageViewerTopBarComponent,
    ImageViewerSideBarComponent,
    AnnotationManagerComponent,
    ViewerHoverDirective,
    InputErrorPipe,
    AnnoCommentPopupComponent,
    ImageSlideTrayComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    OverlayModule,
    MatTabsModule,
    ReactiveFormsModule,
    ImageViewerRoutingModule,
    LazyLoadImageModule,
    RxReactiveFormsModule,
    ColorPickerModule,
    AppMaterialModule,
    MatSliderModule,
    NgxSliderModule,
    MatButtonToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CommonModule,
    ColorPickerModule,
    AnnoCommentPopupComponent,
    ImageSlideTrayComponent
  ],
  providers: [ImageViewerTopBarService, WebviewerWrapperService, AnnotationService, IconService]
})
export class ImageViewerModule { }
