import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from '../login/login.service';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router,
    private loginService: LoginService, private commonService: CommonService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.authService.isLoggedIn()) {
      if (route.queryParams.id) {
        if(localStorage.isCurrentUserExternal){
          localStorage.setItem('meetingLoginParam',route.queryParams.id);
        }
        let meetingData: any = {};
        this.loginService.getMeetingDetailForLogin(route.queryParams.id).subscribe((res: any) => {
          if (res.success) {
            if (res.data) {
              meetingData = res.data;
              if (meetingData.isExternal) {
                if (meetingData.emailId == JSON.parse(localStorage.getItem('currentUser')).userName) {
                  this.router.navigate(["/consultation/consultation-viewer/" + meetingData.meeting.meeting_pk]);
                } else {
                  alert("You already logged-in from some other user.")
                  this.router.navigate(['/dashboard']);
                }
              } else {
                this.loginService.checkValidMeetingUser(meetingData.meeting.meeting_pk).subscribe((res: any) => {
                  if (res.success) {
                    localStorage.setItem("meetingLoginParam", route.queryParams.id);
                    this.router.navigate(["/consultation/consultation-viewer/" + meetingData.meeting.meeting_pk]);
                  } else {
                    alert("You are not authorised user for this meeting link. Please select valid meeting");
                    // this.commonService.changePlace("consultation");
                    // this.router.navigate(["/dashboard/consultation/"]);
                    this.router.navigate(['/dashboard']);
                  }
                });
              }
            } else {
              this.router.navigate(['/dashboard']);
            }
          } else {
            alert(res.message);
            this.router.navigate(['/dashboard']);
          }
        });
      }
      this.router.navigate(['/dashboard']);
    }
    return !this.authService.isLoggedIn();
  }
}
