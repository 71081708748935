import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalSignatureComponent } from '../digital-signature/digital-signature.component';


const routes:Routes = [
  {
    path:'',
    component: DigitalSignatureComponent,
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DigitalSignatureRoutingModule { }
