<div *ngIf="isData" class="modalDigitalSign">
  <div class="dialog-header modal-header">
    <div class="headerTitle">
      <div class="dialog-title">Digital Signature</div>
    </div>
    <div class="headerIcon float-right">
      <i class="fa fa-times" (click)="close()"></i>
    </div>
  </div>
  <div class="userDatadiv">
    <div class="dsAuth">Authentication Required<span class="mandatory">*</span></div>
    <div class="currDateTime">{{currDateTime | date:'full'}}</div>
    <form [formGroup]="digitalFrom" (keypress)="onsave($event)">
      <div class="row" style="margin-top: 10px;">
        <div class="col-md-4">
          <label>Email<span class="mandatory"></span></label>
        </div>
        <div class="col-md-8">
          <mat-form-field class="example-full-width">
            <input type="email" matInput formControlName="email" disabled="true" [value]="this.currentUser.email"
              maxlength="25" (keydown.space)="$event.preventDefault();" />
          </mat-form-field>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;" *ngIf="isPassword">
        <div class="col-md-4">
          <label>Password<span class="mandatory">*</span></label>
        </div>
        <div class="col-md-8">
          <mat-form-field class="example-full-width">
            <input [type]="hide ? 'password' : 'text'" matInput formControlName="password" maxlength="25"
              (keydown.space)="$event.preventDefault();" />
            <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error>{{controls.password.errors && ' Password is required.'}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;" *ngIf="isOtp">
        <div class="col-md-4">
          <label>Otp<span class="mandatory">*</span></label>
        </div>
        <div class="col-md-8">
          <mat-form-field class="example-full-width">
            <input [type]="otpHide ? 'password' : 'text'" matInput formControlName="otp" maxlength="25"
              (keydown.space)="$event.preventDefault();" />
            <mat-icon matSuffix (click)="otpHide = !otpHide" [attr.aria-label]="'Hide otp'"
              [attr.aria-pressed]="otpHide">
              {{otpHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error>{{controls.password.errors && ' OTP is required.'}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-md-4">
          <label>Comment<span class="mandatory">*</span></label>
        </div>
        <div class="col-md-8">
          <!-- {{controls.dsComment.errors?.maxlength | json}} -->
          <mat-form-field class="example-full-width">
            <textarea cols="10" rows="3" matInput class="tesxt-area" formControlName="dsComment"
              required="true"></textarea>
            <mat-error>{{controls.dsComment.errors?.required && 'Comment is required.'}}</mat-error>
            <mat-error>{{controls.dsComment.errors?.maxlength && controls.dsComment.touched && controls.dsComment.dirty
              && ' Maximum 250 characters are allowed.'}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="actions dialog-actions modal-footer">
      <button type="button" class="btn-default" (click)="close()">Cancel</button>
      <button type="button" class="btn-default otp-button" (click)="generateOtp()" *ngIf="isOtp"
        [ngClass]="{ 'disablebtn_func' : !enableGenerateOtp}">Generate OTP</button>
      <button *ngIf="savingStatus" class="btn-default searchbtn" type="button" disabled>
        <span class="spinner-border spinner-border-sm spinner-class" role="status" aria-hidden="true"></span>
        <span>Saving</span>
      </button>
      <button type="button" class="btn-default searchbtn" (click)="onsave()"
        [ngClass]="{ 'disablebtn_func' : digitalFrom?.invalid}">Save</button>
    </div>
    <div class="timerDiv">
      <!-- <button type="button" class="btn-default otp-button" (click)="generateOtp()" *ngIf="!enableGenerateOtp && isOtp"
        [ngClass]="{ 'disablebtn_func' : !enableReGenrateOtp}">Re-Generate OTP</button> -->
      <p *ngIf="!enableGenerateOtp"><span class="dsAuth">You can re-generate otp after</span> {{timer}}</p>
    </div>
  </div>
</div>