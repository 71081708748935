import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css']
})
export class SuccessDialogComponent implements OnInit {

  showData: any
  constructor(@Optional() private dialogRef: MatDialogRef<SuccessDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.showData = {
      'headerTitle': 'Success',
      'message': this.data.message ? this.data.message:'Something Went Wrong On Server',
      'OKButton': 'OK',
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
