<div class="chatMainDiv">
    <div class="chatTitle"><span>Chat</span><i class="fa fa-chevron-down chatArrow" (click)="showHideChatBox()"
            [ngClass]="currentChatArrowState=='open' ? 'openArrow' : 'closeArrow'" aria-hidden="true"></i></div>
    <div class="chatArea">
        <div class="chatList" style="overflow: auto;" #chatMessages [scrollTop]="chatMessages.scrollHeight">
            <!-- <div class="chatItem"></div> -->
            <!-- <mat-list role="list"> -->
                <!-- <mat-list-item role="listitem" *ngFor="let message of messages"> -->
                    <!-- <mat-icon mat-list-icon>account_circle</mat-icon>{{message}} -->
                    <div class="msgDiv" *ngFor="let message of messages">
                        <div class="msgPositionDiv" [ngClass]="(currentUser.userName == message.clientID) ? 'currentUser' : 'otherUser'">
                            <div class="txtMsg">{{message.msg}}</div>
                            <div class="msgInfo">
                                <div class="msgUserId fLeft">{{(message.msgUserDispName ? message.msgUserDispName : message.clientID)}}</div>
                                <div class="msgTime fRight">{{getDateTime(message.msgTime)}}</div>
                            </div>
                        </div>
                    </div>
                <!-- </mat-list-item> -->
            <!-- </mat-list> -->
        </div>

        <div class="sendMsg" style="width: 100%; position: absolute; bottom: 0;">
            <!-- <textarea class="form-control" rows="1" placeholder="Message..." [(ngModel)]="message"
                    (keyup.enter)="addChat()"></textarea> -->
            <mat-form-field appearance="fill" style="width: inherit;height: 3.1vw;">
                <input matInput placeholder="Message..." maxlength="200"
                [(ngModel)]="message" (keyup.enter)="addChat()">
                <button mat-icon-button matSuffix>
                    <mat-icon mat-list-icon (click)="addChat()">send</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

</div>