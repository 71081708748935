import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private storedDsToken:any;
  private config:any;
  public isDsTokenStored:boolean=false;
  public dsComment:any;
  constructor(private http: HttpClient, private configService:AppConfigService) { 
    this.config = configService.config;
  }
  
  createUser(userData: any) {
    return this.http.post(`${this.config.apiUrl}user/create`,userData);
  }

  editUser(userData:any){
    return this.http.post(`${this.config.apiUrl}study/makeUserInactive`,userData);
  }
  updateUser(userData:any){
    return this.http.post(`${this.config.apiUrl}study/updateUser`,userData);
  }

  activateUser(emailId:any){
    return this.http.post(`${this.config.apiUrl}study/makeUserActive`,emailId);
  }

  getAllRoles() {  
    return this.http.get(`${this.config.apiUrl}user/roles/all`);
  }

  getAllUsers() {
    return this.http.get(`${this.config.apiUrl}user`);
  }
  setDsToken(dsToken:any){
    this.storedDsToken =dsToken;
  }
  getDsToken(){
    return this.storedDsToken;
  }
 
}
