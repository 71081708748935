<header class="loginHeader">
  <img src=" ../../assets/images/am_logo_2.svg" alt="AIRA Matrix" aria-label="AIRA Matrix" class="headerLogo" />

</header>
<div class="logbg loginMainDiv">
  <div class="loginbg" [ngClass]="{'resetPasswordMainDiv':isResetPassword}">

    <div *ngIf="meetingData?.meeting?.title" class="meetingTitle"><b>Title:</b>
      <!-- <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked </p></div> -->
      <p>{{meetingData?.meeting?.title}}</p>
    </div>
    <!--<img src="assets/images/am_tranparent_150_h.png" alt="Aira Matrix" align="middle" />-->
    <form [formGroup]="loginForm" (ngSubmit)="onLogin()" *ngIf="!isForgotPassword && !isResetPassword">
      <!--Email Address Field-->
      <img src="../../assets/images/AIRADHI_logo.png" class="airadhilogo">
      <mat-form-field class="form-element commonMatFormField myStudyDropDown mar_right" appearance="outline"
        *ngIf="meetingData?.isExternal">
        <mat-label class="labelbg">Display Name</mat-label>
        <input matInput formControlName="displayName" id="exampleInputDisplayName" aria-describedby="DisplayName">
      </mat-form-field>
      <mat-form-field class="form-element commonMatFormField myStudyDropDown mar_right" appearance="outline"
        *ngIf="!meetingData?.isExternal">
        <mat-label class="labelbg">Email</mat-label>
        <input matInput formControlName="email" id="exampleInputEmail1" aria-describedby="emailHelp">
      </mat-form-field>
      <!-- <div *ngIf="submitted && f.email.errors"> -->
      <div *ngIf="submitted && f.email.errors && !meetingData?.isExternal">
        <p class="errormsgs">{{f.email.errors | inputError:'email' | translate}}</p>
      </div>
      <!-- <p *ngIf="f.email.errors.required" class="errormsgs">Please enter an Email Address</p>
        <p *ngIf="f.email.errors.email" class="errormsgs">Please enter an Email Address with valid characters and try
          again</p>
      </div> -->
      <!--Password Field-->
      <mat-form-field class="form-element commonMatFormField myStudyDropDown labelplacement" appearance="outline">
        <mat-label class="labelbg">Password</mat-label>
        <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      <div *ngIf="submitted && f.password.errors">
        <p class="errormsgs">{{f.password.errors | inputError:'password' | translate}}</p>
      </div>
      <!--Login Button-->
      <button *ngIf="!meetingData?.meeting?.title" type="submit" class="btn-default btn-block loginbtn">Login</button>
      <button *ngIf="meetingData?.meeting?.title" type="submit" class="btn-default btn-block loginbtn">Join
        Meeting</button>
    </form>
    <!--Forgot Password-->
    <div class="form-group forgotpwd" *ngIf="!isForgotPassword && !isResetPassword && config.configForgetPassowrd">
      <span (click)="showForgetPassowrd()">Forgot Password?</span>
    </div>
    <form [formGroup]="formForgotPassword" *ngIf="isForgotPassword && !isResetPassword" class="formForgotPassword">
      <div class="clearfix">
        <i class="fa fa-times float-right closeFrgtPswd" (click)="closeForgetPassword()"></i>
        <span class="float-left font-weight-bold"> {{header}} </span>
      </div>
      <div style="display: inline-flex;">
        <img style="margin: 0vw 0.5vw;" src="../assets/images/viewer_icon/tickIcon.svg" *ngIf="showSuccess" />
        <p style="padding: 0.5vw 0vw;">{{message}}</p>
      </div>
      <mat-form-field class="form-element commonMatFormField myStudyDropDown recoveryEmail" appearance="outline"
        *ngIf="!isMailSend && !showSuccess">
        <mat-label class="labelbg">Email</mat-label>
        <input matInput formControlName="recoveryEmail" aria-describedby="emailHelp">
        <mat-error *ngIf="forgotPassFrm.recoveryEmail.errors && forgotPassFrm.recoveryEmail.dirty">
          {{forgotPassFrm.recoveryEmail.errors | inputError:'email' | translate}}</mat-error>
      </mat-form-field>

      <!-- <i class="fa fa-check-circle-o" aria-hidden="true" ></i> -->
      <!-- <div class="form-group forgotpwd" *ngIf="showSuccess">
        <span (click)="sendEmail()">Re-send Verification Code</span>
      </div> -->
      <mat-form-field class="form-element commonMatFormField myStudyDropDown recoveryEmail" appearance="outline"
        *ngIf="isMailSend && !isVerificationSend">
        <mat-label class="labelbg">Verification Code</mat-label>
        <input matInput formControlName="verficationCode">
        <mat-error *ngIf="forgotPassFrm.verficationCode.errors && forgotPassFrm.verficationCode.dirty">
          Enter Valid Verification Code</mat-error>
      </mat-form-field>

      <mat-form-field class="form-element commonMatFormField myStudyDropDown recoveryEmail marginBottom2vw"
        appearance="outline" *ngIf="isVerificationSend">
        <mat-label class="labelbg">Password</mat-label>
        <input matInput formControlName="newPassword" [type]="hideNewPass ? 'password' : 'text'"
          (keydown.space)="$event.preventDefault();">
        <mat-icon matSuffix (click)="hideNewPass = !hideNewPass" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideNewPass">
          {{hideNewPass ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="isVerificationSend && forgotPassFrm.newPassword.errors && forgotPassFrm.newPassword.dirty">
          {{forgotPassFrm.newPassword.errors | inputError:'newPassword' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field class="form-element commonMatFormField myStudyDropDown recoveryEmail marginBottom2vw"
        appearance="outline" *ngIf="isVerificationSend">
        <mat-label class="labelbg">Confirm Password</mat-label>
        <input matInput formControlName="rePassword" [type]="hideRePass ? 'password' : 'text'"
          (keydown.space)="$event.preventDefault();">
        <mat-icon matSuffix (click)="hideRePass = !hideRePass" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideRePass">
          {{hideRePass ? 'visibility_off' : 'visibility'}}</mat-icon>
        <!-- <mat-error
          *ngIf="isVerificationSend && forgotPassFrm.rePassword.errors?.required && forgotPassFrm.rePassword.dirty">
          Enter Password</mat-error> -->
        <mat-error *ngIf="isVerificationSend && forgotPassFrm.rePassword.errors && forgotPassFrm.rePassword.dirty">
          {{forgotPassFrm.rePassword.errors |
          inputError:'rePassword' | translate }}
        </mat-error>
      </mat-form-field>
      <button *ngIf="!isMailSend && !showSuccess && !showLoader" type="button" class="btn-default btn-block loginbtn"
        [ngClass]="{'disableFunction': forgotPassFrm.recoveryEmail.invalid}" (click)="sendEmail()">Send Verification
        Code</button>
      <button *ngIf="showLoader" class="btn-default btn-block loginbtn disableFunction" type="button" disabled>
        <span class="myspinner spinner-border spinner-border-sm" role="status" aria-hidden="true"
          style="margin-right: 0.5vw; width: 1vw; height: 1vw;"></span>
        Sending Mail...
      </button>
      <button *ngIf="showSuccess && !showLoader" type="button" class="btn-default btn-block loginbtn"
        (click)="proceed()">Proceed</button>
      <button *ngIf="isMailSend && !isVerificationSend" type="button" class="btn-default btn-block loginbtn"
        [ngClass]="{'disableFunction': forgotPassFrm.verficationCode.invalid}"
        (click)="sendVerificationCode()">Next</button>
      <button *ngIf="isVerificationSend" type="button" class="btn-default btn-block loginbtn"
        [ngClass]="{'disableFunction': forgotPassFrm.newPassword.invalid || forgotPassFrm.rePassword.invalid }"
        (click)="sendNewPassword()">Next</button>
      <div *ngIf="isVerificationSend">
        <div>Make sure your password:</div>
        <ol>
          <li>Is longer than 7 characters</li>
          <!-- <li>Is not significantly similar or contains similar characters from your username</li> -->
          <li>Includes numbers, special characters, upper case</li>
        </ol>
      </div>
      <div class="form-group forgotpwd" *ngIf="showSuccess">
        <span (click)="sendEmail()"><u>Re-send Verification Code</u></span>
      </div>

    </form>


    <form [formGroup]="formResetPassword" *ngIf="!isForgotPassword && isResetPassword" class="formResetPassword">
      <img src="../../assets/images/AIRADHI_logo.png" class="airadhilogo">
      <div class="txtResetPassword">Reset Password</div>

      <mat-form-field class="form-element commonMatFormField myStudyDropDown recoveryEmail marginBottom2vw"
        appearance="outline">
        <mat-label class="labelbg">Username</mat-label>
        <input matInput formControlName="userName" type="text" readonly>
      </mat-form-field>

      <mat-form-field class="form-element commonMatFormField myStudyDropDown recoveryEmail marginBottom2vw"
        appearance="outline">
        <mat-label class="labelbg">Password</mat-label>
        <input matInput formControlName="newPassword" [type]="hideNewPass ? 'password' : 'text'"
          (keydown.space)="$event.preventDefault();">
        <mat-icon matSuffix (click)="hideNewPass = !hideNewPass" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideNewPass">
          {{hideNewPass ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="resetPassFrm.newPassword.errors && resetPassFrm.newPassword.dirty">
          {{resetPassFrm.newPassword.errors | inputError:'newPassword' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field class="form-element commonMatFormField myStudyDropDown recoveryEmail marginBottom2vw"
        appearance="outline">
        <mat-label class="labelbg">Confirm Password</mat-label>
        <input matInput formControlName="rePassword" [type]="hideRePass ? 'password' : 'text'"
          (keydown.space)="$event.preventDefault();">
        <mat-icon matSuffix (click)="hideRePass = !hideRePass" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideRePass">
          {{hideRePass ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="resetPassFrm.rePassword.errors && resetPassFrm.rePassword.dirty">
          {{resetPassFrm.rePassword.errors |
          inputError:'rePassword' | translate }}
        </mat-error>
      </mat-form-field>
      <button type="button" class="btn-default btn-block loginbtn btnResetPassword"
        [ngClass]="{'disableFunction': resetPassFrm.newPassword.invalid || resetPassFrm.rePassword.invalid }"
        (click)="sendNewPassword(true)">Reset Password</button>
      <div>
        <div>Make sure your password:</div>
        <ol>
          <li>Is longer than 7 characters</li>
          <!-- <li>Is not significantly similar or contains similar characters from your username</li> -->
          <li>Includes numbers, special characters, upper case</li>
        </ol>
      </div>
    </form>
    <div class="alert alert-success" role="alert" *ngIf="loginSuccess">
      Login Successful.
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="loginServerError">
      {{loginServerError}}
    </div>
  </div>
</div>