import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeetingService } from '../meeting.service';
import { ConsultationService } from '../../consultation.service';

@Component({
  selector: 'app-meeting-details',
  templateUrl: './meeting-details.component.html',
  styleUrls: ['./meeting-details.component.css']
})
export class MeetingDetailsComponent implements OnInit {

  constructor(@Optional() private dialogRef: MatDialogRef<MeetingDetailsComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private meetingService: MeetingService, private consultationService: ConsultationService) { 
    dialogRef.disableClose = true;
   }
   meetingData: any = {
    title: '',
    description: '',
    startTime: '',
    endTime: '',
    invitees: [],
    slideIds : [],
    duration: ''
   };
   slidearr: any = [];

  ngOnInit() {
    this.meetingService.getMeetingDetail(this.data.meetingId).subscribe((result: any) => {
      this.meetingData = result.data;
      if (this.meetingData.slideIds.length > 0) {
        this.meetingData.slideIds.forEach(dataVal => {
          if(dataVal.slideId.length > 0) {
            this.slidearr = [...this.slidearr,...dataVal.slideId];
          }
        });
      }
      let startTime = new Date(0, 0, 0, new Date(this.meetingData.startTime).getHours(), new Date(this.meetingData.startTime).getMinutes(), 0);
      let endTime = new Date(0, 0, 0, new Date(this.meetingData.endTime).getHours(), new Date(this.meetingData.endTime).getMinutes(), 0);
      this.meetingData.duration = this.consultationService.calculateDuration(startTime, endTime);
    })
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
