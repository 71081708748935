import { AppConfigService } from './../app-config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private config: any;

  constructor(private http: HttpClient, private configService:AppConfigService) {
    this.config = configService.config;
   }

  getRolePlaces(role: any) {
    return this.http.get(`${this.config.apiUrl}user/roles/${role}/places`);
  }
}
