<div class="slideTrayMainDiv"
    [ngClass]="{'largeHeader' : isSlideTrayViewVisible, 'miniHeader' : !isSlideTrayViewVisible, 'onlyAnnoManager' : !showSlideTray}">
    <div class="toggleSlideTrayManagerDiv">
        <div class="headerTitle">
            <div class="slideTrayMiniHeaderLabel" (click)="toggleSlideTrayManager('Annotations Manager')"
                [style.border-right]="showSlideTray ? '0.125vw solid #EA8823':'none'">
                <span
                    [ngClass]="{'selectedBorderBottom':isSlideTrayViewVisible && (selectedPanelTab == 'Annotations Manager')}">Annotations</span>
            </div>
            <div *ngIf="showSlideTray" class="slideTrayMiniHeaderLabel"
                (click)="toggleSlideTrayManager('Slide Gallery')">
                <span
                    [ngClass]="{'selectedBorderBottom':isSlideTrayViewVisible && (selectedPanelTab == 'Slide Gallery')}">Slide
                    Gallery</span>
            </div>
            <mat-icon (click)="toggleSlideTrayManager()" class="slideTrayHeaderImgIcon rotateArrow">arrow_drop_down
            </mat-icon>
        </div>
    </div>
    <div *ngIf="isSlideTrayViewVisible" class="toggleSlideTrayManagerDiv">
        <div class="slideTrayBody">
            <!-- <div class="headerTitle clearfix">
                <div class="slideTrayLargeHeaderLabel float-left"
                    (click)="toggleSlideTrayManager('Annotations Manager')">Annotations</div>
                <div class="slideTrayLargeHeaderLabel float-left" (click)="toggleSlideTrayManager('Slide Gallery')"
                    style="margin-left: 0.9vw;">Slide Gallery</div>
            </div> -->
            <div class="annotationsContent" *ngIf="selectedPanelTab == 'Annotations Manager'">
                <app-annotation-manager [isFromSlideTrayView]="true">
                </app-annotation-manager>
            </div>
            <div class="slideGalleryContent" *ngIf="showSlideTray && (selectedPanelTab == 'Slide Gallery')">
                <div class="slideTrayParent">
                    <div class="slideTrayHeader">
                        <div class="slideTrayFilter">
                            <div class="headerFilterGroup">
                                <div class="filterDropDown">
                                    <mat-form-field
                                        class="full-width slideTraySelect headerViewField headerFilterDropdown labelview">
                                        <mat-label class="labelbg">View</mat-label>
                                        <mat-select [(ngModel)]="slideTrayHeaderDropdown.selectedViewValue"
                                            (selectionChange)="selectedViewOnChange($event)">
                                            <mat-option class="slideTrayDDOption"
                                                *ngFor="let viewOption of slideTrayHeaderDropdown.viewDDOptions"
                                                [value]="viewOption.optionId">{{viewOption.optionName}}
                                            </mat-option>
                                            <mat-option class="slideTrayDDOption"
                                                *ngIf="((starMarkedSlides | json) != '{}')" value="starMarkedSlide">
                                                Star Marked slides</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="slideTrayFilterIconMainDiv"
                                    *ngIf="slideTrayHeaderDropdown.selectedViewValue == 'custom'">
                                    <div class="slideTrayFilterIcon" [matMenuTriggerFor]="galleryFilterPopOver"
                                        [style.color]="(slideTrayFilter.stainsF && slideTrayFilter.stainsF.length > 0) || (slideTrayFilter.organsF && slideTrayFilter.organsF.length > 0) || (slideTrayFilter.animalsF && slideTrayFilter.animalsF.length > 0) || (slideTrayFilter.controlF && slideTrayFilter.controlF.length > 0)?'#EA8823':'#393939'">
                                        <i class="fa fa-filter filtericon" aria-hidden="true"></i>
                                        <i class="fa fa-caret-down arrowicon" aria-hidden="true"></i>
                                    </div>
                                    <mat-menu #galleryFilterPopOver="matMenu" yPosition="above"
                                        class="headerFilterPopOver" (click)="$event.stopPropagation();">
                                        <div class="headerFilterPopOverRowDiv" (click)="$event.stopPropagation();">
                                            <mat-form-field
                                                class="full-width slideTraySelect  headerStainFilterPopover headerFilterPopOverDropdown labelview">
                                                <mat-label class="labelbg">Stain</mat-label>
                                                <mat-select multiple [(ngModel)]="slideTrayFilter.stainsF"
                                                    (click)="$event.stopPropagation();">
                                                    <mat-option class="slideTrayDDOption"
                                                        *ngFor="let stain of slideTrayFilter.studyStainList"
                                                        [value]="stain.stain" [title]="stain.stain">
                                                        {{ stain.stain }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="headerFilterPopOverRowDiv" (click)="$event.stopPropagation();">
                                            <mat-form-field
                                                class="full-width slideTraySelect  headerTissueFilterPopover headerFilterPopOverDropdown labelview">
                                                <mat-label class="labelbg">Tissue</mat-label>
                                                <mat-select multiple [(ngModel)]="slideTrayFilter.organsF"
                                                    (click)="$event.stopPropagation();">
                                                    <mat-option class="slideTrayDDOption"
                                                        *ngFor="let organ of slideTrayFilter.studyOrganList"
                                                        [value]="organ.organ" [title]="organ.organ">
                                                        {{ organ.organ }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="headerFilterPopOverRowDiv" (click)="$event.stopPropagation();">
                                            <mat-form-field
                                                class="full-width slideTraySelect headerAnimalFilterPopover headerFilterPopOverDropdown labelview">
                                                <mat-label class="labelbg">Subject Id</mat-label>
                                                <mat-select multiple [(ngModel)]="slideTrayFilter.animalsF"
                                                    (click)="$event.stopPropagation();">
                                                    <mat-option class="slideTrayDDOption"
                                                        *ngFor="let animal of slideTrayFilter.studyAnimalList"
                                                        [value]="animal.animalNo" [title]="animal.animalNo">
                                                        {{ animal.animalNo }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="headerFilterPopOverRowDiv" *ngIf="galleryFilter?.isTCRStudy"
                                            (click)="$event.stopPropagation();">
                                            <mat-form-field
                                                class="full-width slideTraySelect  headerTypeFilterPopover headerFilterPopOverDropdown">
                                                <mat-label class="labelbg">Type</mat-label>
                                                <mat-select multiple [(ngModel)]="slideTrayFilter.controlF"
                                                    (click)="$event.stopPropagation();">
                                                    <mat-option class="slideTrayDDOption"
                                                        *ngFor="let control of slideTrayFilter.slideControl"
                                                        [value]="control.optionId">
                                                        {{ control.optionName }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="headerFilterPopOverRowDiv btnAction clearfix">
                                            <button type="button" class="btn-default" aria-label="resetbtn"
                                                (click)="resetSlideTrayFilter($event)">Reset</button>
                                            <button type="button" class="btn-default"
                                                aria-label="cancelbtn">Close</button>
                                            <button type="button" class="btn-default searchbtn" aria-label="applybtn"
                                                (click)="applySlideTrayCustomFilter($event)">Apply</button>

                                        </div>
                                    </mat-menu>
                                </div>
                                <div class="filterDropDown"
                                    *ngIf="slideTrayHeaderDropdown.selectedViewValue == 'custom'">
                                    <mat-form-field
                                        class="full-width slideTraySelect headerViewByField headerFilterDropdown labelview">
                                        <mat-label class="labelbg">View by</mat-label>
                                        <mat-select [(ngModel)]="slideTrayHeaderDropdown.selectedViewByValue"
                                            (selectionChange)="selectedViewByOnChange($event)">
                                            <ng-container *ngFor="let viewByOption of groupByObj | keyvalue;">
                                                <mat-option [value]="viewByOption.key" class="slideTrayDDOption"
                                                    *ngIf="((galleryFilter?.isTCRStudy == viewByOption.value.isTcr) || (!galleryFilter?.isTCRStudy == viewByOption.value.isTox))">
                                                    {{viewByOption.value.text}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="filterDropDown" style="margin-left: 1vw;"
                                    *ngIf="(slideTrayHeaderDropdown.selectedViewValue != 'starMarkedSlide')">
                                    <mat-form-field style="width: 12vw;"
                                        class="full-width slideTraySelect  headerAccGroupField headerFilterDropdown labelview">
                                        <mat-label class="labelbg">{{viewBySelectedLabel}}</mat-label>
                                        <mat-select [(ngModel)]="selectedTabHeader" #singleSelect
                                            (selectionChange)="slideSelectedGroupOnChange($event)">
                                            <mat-option class="slideTrayDDOption">
                                                <ngx-mat-select-search placeholderLabel="search..."
                                                    [formControl]="viewBySelectedLabelFilterCtrl"
                                                    [noEntriesFoundLabel]="'No matching '+viewBySelectedLabel+' found'">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option class="slideTrayDDOption" [value]="tabItem.attribute"
                                                [title]="tabItem.attribute"
                                                *ngFor="let tabItem of headerAccordArr; let i=index;">
                                                {{tabItem.attribute}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="slideTrayHeaderIcons">
                            <div class="slideNumber">
                                Showing <span class="slideDisplayText">{{getslideDisplayText().dispRange}}</span> of
                                {{getslideDisplayText().totalLength}}
                            </div>

                            <div class="slideActionIcons">
                                <div *ngIf="isSlideTrayViewVisible" class="rightRotateImg"
                                    [title]="'Rotate clockwise 90° to all '+(!showAllLabelImg?'thumbnail':'label')+' images'"
                                    (click)="rotateAllSlideImg($event, 'clockwise')">
                                    <img src="../../../assets/images/new_icons/rotation.svg" title="Rotation" />
                                </div>
                                <div *ngIf="isSlideTrayViewVisible" class="compareImg" title="Compare Images"
                                    (click)="compareAllSelectedSlideImg($event)">
                                    <img src="../../../assets/images/new_icons/compare.svg" title="Compare" />
                                </div>
                                <div *ngIf="isSlideTrayViewVisible" class="toggleImg"
                                    [title]="'Switch to '+(showAllLabelImg?'thumbnail':'label')+' view'"
                                    (click)="toggleAllSlideImg($event)">
                                    <img
                                        [src]="'../../../assets/images/viewer_icon/' + (showAllLabelImg ? 'Image Preview':'Slide Label') + ' Icon-Active.svg'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slideTray" (wheel)="onMouseWheel($event)">
                        <div *ngIf="!noSlidesToDisplay && ((slidesIndex.length > slidesToDisplay) && (slidesIndex[0] < 0))"
                            class="prevNavigation btnNavigation">
                            <button mat-raised-button (click)="moveTraySlide('prev', slidesToDisplay)">
                                <mat-icon>navigate_before</mat-icon>
                            </button>
                        </div>
                        <ng-container *ngFor="let slide of slidesGroupArr; let i = index">
                            <ng-container
                                *ngIf="((this.slidesIndex[i] >= -1 && this.slidesIndex[i] < slidesToDisplay))">
                                <div class="slideItem" [ngClass]="{'openedInViewer': slide.isOpenedInViewer}"
                                    [attr.index]="slidesIndex[i]" #menuTrigger="matMenuTrigger"
                                    [matMenuTriggerFor]="slideInfo">
                                    <!-- [ngStyle]="{left: 'calc('+ (2 + (2.15*(slidesIndex[i]))) +'% + '+ (slidesIndex[i])*5.5 +'vw)'}"> -->
                                    <mat-icon class="starIcon overlayStar" [class.selectedStar]="slide.isStarMarked"
                                        svgIcon="slideTrayBookmark"
                                        (click)="$event.stopPropagation();starMarkedSlideAction(slide)"></mat-icon>
                                    <div (click)="$event.stopPropagation();" class="slideTrayImg">
                                        <img *ngIf="slide.isLabel"
                                            [ngStyle]="{transform: 'rotate('+slide.imageLableRotate+'deg)'}"
                                            class="imgLable" (click)="openImgInViewer($event, slide)"
                                            [lazyLoad]="configService.config.thumbnailUrl+slide.identifier+'_label.jpeg'">
                                        <img *ngIf="!slide.isLabel"
                                            [ngStyle]="{transform: 'rotate('+slide.imageMacroRotate+'deg)'}"
                                            class="imgLable" (click)="openImgInViewer($event, slide)"
                                            [lazyLoad]="configService.config.thumbnailUrl+slide.identifier+'_macro.jpeg'">
                                        <!-- <mat-icon class="starIcon" [class.selectedStarIcon]="slide.isStarMarked">star</mat-icon> -->
                                    </div>
                                    <div (click)="$event.stopPropagation();" class="indvImgIconAction clearfix">
                                        <input class="selectSlide" type="checkbox"
                                            (change)="selectSlideAction($event, slide)" [checked]="slide.isSelected">
                                        <div class="slideTrayImgIcon eyeView"
                                            (click)="viewSlideInfo(slide, $event); menuTrigger.openMenu() ">
                                            <img src="../../../assets/images/new_icons/eye_black.svg"
                                                title="View Information" />
                                        </div>
                                        <div class="slideTrayImgIcon rightRotateImg"
                                            (click)="$event.stopPropagation(); rotateSlideImg(slide, $event)">
                                            <img src="../../../assets/images/new_icons/rotation.svg" title="Rotation" />
                                        </div>
                                        <div class="slideTrayImgIcon toggleImg"
                                            (click)="$event.stopPropagation(); toggleSlideImg(slide, $event)">
                                            <img [src]="'../../../assets/images/viewer_icon/' + (slide.isLabel ? 'Image Preview':'Slide Label') + ' Icon-Active.svg'"
                                                [title]="(slide.isLabel ? 'Label':'Slide')+ 'View'">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="!noSlidesToDisplay && ((slidesIndex.length > slidesToDisplay) && (slidesIndex[slidesIndex.length - 1] > slidesToDisplay))"
                            class="nextNavigation btnNavigation">
                            <button mat-raised-button (click)="moveTraySlide('next', slidesToDisplay)">
                                <mat-icon>navigate_next</mat-icon>
                            </button>
                        </div>
                        <ng-container *ngIf="noSlidesToDisplay">
                            <div class="noSlidesToDisplay">No slides to display</div>
                        </ng-container>
                    </div>
                </div>
                <mat-menu #slideInfo="matMenu" class="slideInfoMenu">
                    <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="selectedSlideTabIndex" class="tabheight"
                        mat-align-tabs="center" (selectedTabChange)="slideDetailsTabChange($event)"
                        (click)="$event.stopPropagation()">
                        <mat-tab label="Slide Details">
                            <div class="slideInfo">
                                <table *ngIf="selectedSlideDetails" class="table">
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Slide ID</td>
                                        <td class="itemText" [title]="selectedSlideDetails.slideLabel">
                                            {{selectedSlideDetails.slideLabel}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Image Name</td>
                                        <td class="itemText" [title]="selectedSlideDetails.slidename">
                                            {{selectedSlideDetails.slidename}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Subject Id</td>
                                        <td class="itemText" [title]="selectedSlideDetails.animalNo">
                                            {{selectedSlideDetails.animalNo}} </td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Tissue</td>
                                        <td class="itemText" [title]="selectedSlideDetails.organ.join(', ')">
                                            {{selectedSlideDetails.organ.join(', ')}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Dosage Group</td>
                                        <td class="itemText" [title]="selectedSlideDetails.dosageCode">
                                            {{selectedSlideDetails.dosageCode}} </td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Sex</td>
                                        <td class="itemText" [title]="selectedSlideDetails.sex">
                                            {{selectedSlideDetails.sex}} </td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Dose</td>
                                        <td class="itemText" [title]="selectedSlideDetails.dose">
                                            {{selectedSlideDetails.dose ? selectedSlideDetails.dose : "--"}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Dose Units</td>
                                        <td class="itemText" [title]="selectedSlideDetails.dosageUnit">
                                            {{selectedSlideDetails.dosageUnit ?
                                            selectedSlideDetails.dosageUnit : "--"}} </td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Scan Date</td>
                                        <td class="itemText" [title]="selectedSlideDetails.scanDateTime">
                                            {{selectedSlideDetails.scanDateTime ?
                                            (selectedSlideDetails.scanDateTime | date) : "--"}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Magnification</td>
                                        <td class="itemText" [title]="selectedSlideDetails.scanMagnification">
                                            {{selectedSlideDetails.scanMagnification ?
                                            selectedSlideDetails.scanMagnification : "--"}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Stain</td>
                                        <td class="itemText" [title]="selectedSlideDetails.stain">
                                            {{selectedSlideDetails.stain ? selectedSlideDetails.stain : "--"}} </td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Chromogen</td>
                                        <td class="itemText" [title]="selectedSlideDetails.chromogen">
                                            {{selectedSlideDetails.chromogen ?
                                            selectedSlideDetails.chromogen : "--"}} </td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">IHC Experiment Num</td>
                                        <td class="itemText" [title]="selectedSlideDetails.ihcExperimentNumber">
                                            {{selectedSlideDetails.ihcExperimentNumber ?
                                            selectedSlideDetails.ihcExperimentNumber : "--"}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Target Antigen</td>
                                        <td class="itemText" [title]="selectedSlideDetails.targetAntigen">
                                            {{selectedSlideDetails.targetAntigen ?
                                            selectedSlideDetails.targetAntigen : "--"}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Primary AntiBody Id</td>
                                        <td class="itemText" [title]="selectedSlideDetails.primaryAntibodyID">
                                            {{selectedSlideDetails.primaryAntibodyID ?
                                            selectedSlideDetails.primaryAntibodyID : "--"}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Secondary AntiBody Id</td>
                                        <td class="itemText" [title]="selectedSlideDetails.secondayAntibodyID">
                                            {{selectedSlideDetails.secondayAntibodyID ?
                                            selectedSlideDetails.secondayAntibodyID : "--"}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Detection Method</td>
                                        <td class="itemText" [title]="selectedSlideDetails.detectionMethod">
                                            {{selectedSlideDetails.detectionMethod ?
                                            selectedSlideDetails.detectionMethod : "--"}}</td>
                                    </tr>
                                    <tr class="slideDetailsItems">
                                        <td class="itemLabel">Comments</td>
                                        <td class="itemText" [title]="selectedSlideDetails.slideComments">
                                            {{selectedSlideDetails.slideComments ?
                                            selectedSlideDetails.slideComments : "--"}}</td>
                                    </tr>
                                </table>
                            </div>
                            <!--  <div *ngIf="selectedSlideDetails" class="slideInfo">
                                <mat-list role="list">
                                    <mat-list-item class="slideDetailsItems">
                                        <div mat-line class="itemLabel">Slide ID</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.slideLabel">
                                            {{selectedSlideDetails.slideLabel}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems">
                                        <div mat-line class="itemLabel">Image Name </div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.slidename">
                                            {{selectedSlideDetails.slidename}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems">
                                        <div mat-line class="itemLabel">Subject Id </div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.animalNo">
                                            {{selectedSlideDetails.animalNo}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems">
                                        <div mat-line class="itemLabel">Tissue</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.organ.join(', ')">
                                            {{selectedSlideDetails.organ.join(', ')}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="!galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Dosage Group</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.dosageCode">
                                            {{selectedSlideDetails.dosageCode}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems">
                                        <div mat-line class="itemLabel">Sex</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.sex">
                                            {{selectedSlideDetails.sex}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="!galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Dose</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.dose">
                                            {{selectedSlideDetails.dose ? selectedSlideDetails.dose
                                            : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="!galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Dose Units</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.dosageUnit">
                                            {{selectedSlideDetails.dosageUnit ?
                                            selectedSlideDetails.dosageUnit : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems">
                                        <div mat-line class="itemLabel">Scan Date</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.scanDateTime">
                                            {{selectedSlideDetails.scanDateTime ?
                                            (selectedSlideDetails.scanDateTime | date) : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems">
                                        <div mat-line class="itemLabel">Magnification</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.scanMagnification">
                                            {{selectedSlideDetails.scanMagnification ?
                                            selectedSlideDetails.scanMagnification : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems">
                                        <div mat-line class="itemLabel">Stain</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.stain">
                                            {{selectedSlideDetails.stain ?
                                            selectedSlideDetails.stain
                                            : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Chromogen</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.chromogen">
                                            {{selectedSlideDetails.chromogen ?
                                            selectedSlideDetails.chromogen : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">IHC Experiment Num</div>
                                        <div mat-line class="itemText"
                                            [title]="selectedSlideDetails.ihcExperimentNumber">
                                            {{selectedSlideDetails.ihcExperimentNumber ?
                                            selectedSlideDetails.ihcExperimentNumber : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Target Antigen</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.targetAntigen">
                                            {{selectedSlideDetails.targetAntigen ?
                                            selectedSlideDetails.targetAntigen : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Primary AntiBody Id</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.primaryAntibodyID">
                                            {{selectedSlideDetails.primaryAntibodyID ?
                                            selectedSlideDetails.primaryAntibodyID : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Secondary AntiBody Id</div>
                                        <div mat-line class="itemText"
                                            [title]="selectedSlideDetails.secondayAntibodyID">
                                            {{selectedSlideDetails.secondayAntibodyID ?
                                            selectedSlideDetails.secondayAntibodyID : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Detection Method</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.detectionMethod">
                                            {{selectedSlideDetails.detectionMethod ?
                                            selectedSlideDetails.detectionMethod : "--"}} </div>
                                    </mat-list-item>
                                    <mat-list-item class="slideDetailsItems" *ngIf="galleryFilter.isTCRStudy">
                                        <div mat-line class="itemLabel">Comments</div>
                                        <div mat-line class="itemText" [title]="selectedSlideDetails.slideComments">
                                            {{selectedSlideDetails.slideComments ?
                                            selectedSlideDetails.slideComments : "--"}} </div>
                                    </mat-list-item>
                                </mat-list>
                            </div> -->
                        </mat-tab>
                        <!-- <mat-tab label="Slide Reviews">
                            <div *ngIf="selectedSlideDetails" class="slideInfo slideReview">
                                <div class="clearfix headerRow">
                                    <mat-form-field class="full-width slideTraySelect  float-left targetTissueField">
                                        <mat-label class="labelbg">Target Tissue</mat-label>
                                        <mat-select [(ngModel)]="selectedTargetTissue">
                                            <mat-option class="slideTrayDDOption"
                                                *ngFor="let tissue of selectedSlideDetails.organ; let i=index;"
                                                [value]="tissue">{{tissue}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="allSlideReviewData"
                                        class="full-width slideTraySelect  float-left targetUser">
                                        <mat-label class="labelbg">User</mat-label>
                                        <mat-select [(ngModel)]="selectedUser"
                                            (selectionChange)="changeUserForSlideReview($event.value)">
                                            <mat-option class="slideTrayDDOption"
                                                *ngFor="let slideReview of allSlideReviewData; let j=index;"
                                                [value]="slideReview.userFk">{{slideReview.userFk}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="pathologistReviews">
                                    <ng-container
                                        *ngIf="(selectedSlideReviewData && selectedSlideReviewData.observations)">
                                        <fieldset class="pathologistReviewsFieldSet">
                                            <legend>
                                                <span class="pathoNameField">{{selectedSlideReviewData.userFk}}</span>
                                                <span class="summaryField">
                                                    ({{selectedSlideReviewData.summary}})</span>
                                            </legend>
                                            <div class="slideReviewBody">
                                                <div class="observationDiv">
                                                    <div class="reviewHeader">Observations</div>
                                                    <ul class="reviewObservations">
                                                        <li
                                                            *ngFor="let indvObservation of selectedSlideReviewData.observations">
                                                            <span
                                                                class="gradingCode">{{indvObservation.gradingCode}}</span>
                                                            -
                                                            <span
                                                                class="observationDesc">{{indvObservation.description}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="commentDiv">
                                                    <div class="reviewHeader">Comments</div>
                                                    <ul class="reviewComments">
                                                        <li><span
                                                                class="commentsDesc">{{selectedSlideReviewData.comments}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!(selectedSlideReviewData && selectedSlideReviewData.observations)">
                                        <div class="noDataDiv">No data to display</div>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-tab> -->
                    </mat-tab-group>
                </mat-menu>
            </div>
        </div>
    </div>
</div>