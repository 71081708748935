import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from "./../../common.service";
import { AppConfigService } from './../../app-config.service';
import { GalleryService } from '../../gallery/gallery.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-image-slide-tray',
  templateUrl: './image-slide-tray.component.html',
  styleUrls: ['./image-slide-tray.component.css']
})

export class ImageSlideTrayComponent implements OnInit {

  // @Input() imageViewerObj: any;
  @Input() showSlideTray: boolean;
  showTrayView: boolean = false;
  selectedPanelTab: string = 'Slide Gallery';
  selectedViewerSlideId: any = [];
  selectedViewerSlideIdIndex: number;
  selectedViewerSlideInfo: any;
  isSlideTrayViewVisible: boolean = false;
  slidesToDisplay: number = 7;
  slidesGroupArr: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  selectedSlides: any = {};
  starMarkedSlides: any = {};
  slidesIndex: any = [];
  mouseWheelTimeout: any = null;
  centerSlideIndex: number;
  isSlideDetailsOpened: boolean = false;
  selectedSlideDetails: any;
  galleryFilter: any;
  rotateAllImgVal: number = 0;
  showAllLabelImg: boolean = true;
  selectedSlideReviewData: any;
  allSlideReviewData: any;
  intensityObj: any;
  frequencyObj: any;
  selectedTargetTissue: string;
  selectedUser: string;
  selectedSlideTabIndex: number = 0;
  slideTrayFilter: any = {
    stainsF: [],
    organsF: [],
    animalsF: [],
    controlF: [],
    studyStainList: [],
    studyOrganList: [],
    studyAnimalList: [],
    slideControl: [
      { optionId: 'Positive', optionName: 'Positive Control Slides' },
      { optionId: 'Negative', optionName: 'Negative Control Slides' },
      { optionId: 'Test', optionName: 'Test Slides' },
    ]
  };
  slideTrayHeaderDropdown: any = {
    viewDDOptions: [{
      optionId: 'tissue',
      optionVal: '3',
      optionName: 'All Tissues'
    }, {
      optionId: 'subjectId',
      optionVal: '5',
      optionName: 'All Subject Ids'
    }, {
      optionId: 'custom',
      optionName: 'Custom'
    }],
    selectedViewValue: 'subjectId',
    // viewByDDOptions: ["Subject Id", "Tissue", "Dosage", "Cassette", "Sex"],
    selectedViewByValue: '5',
  }
  groupByObj: any = {
    0: { id: 'cassette', text: 'Cassette', isTcr: false, isTox: true },
    1: { id: 'dosage', text: 'Dosage', isTcr: false, isTox: true },
    2: { id: 'sex', text: 'Sex', isTcr: true, isTox: true },
    3: { id: 'tissue', text: 'Tissue', isTcr: true, isTox: true },
    5: { id: 'subjectId', text: 'Subject Id', isTcr: true, isTox: true },
    6: { id: 'targetAntigen', text: 'Target Antigen', isTcr: true, isTox: false },
  };

  filterHeaderAccordData: any = [];
  headerAccordArr: any = [];
  selectedTabHeader: string;
  viewBySelectedLabel: string = 'Subject Id';
  noSlidesToDisplay: boolean = false;
  public viewBySelectedLabelFilterCtrl: FormControl = new FormControl();

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private galleryService: GalleryService,
    public translate: TranslateService,
    public configService: AppConfigService,
    private router: Router
  ) {
    this.viewBySelectedLabelFilterCtrl.valueChanges.subscribe(() => {
      this.filterViewBySelectedLabel();
    });
  }

  ngOnInit(): void {
    this.selectedPanelTab = this.showSlideTray ? 'Slide Gallery' : 'Annotations Manager';
    this.activatedRoute.params.subscribe((paramMap: any) => {
      // localStorage.samples = paramMap.id;
      if (this.showSlideTray) {
        this.selectedViewerSlideId = paramMap.id.split(",").map(slideId => {
          return Number(slideId);
        });

        this.galleryFilter = JSON.parse(this.commonService.getGalleryFilters());
        this.slideTrayHeaderDropdown.selectedViewValue = "custom";
        this.slideTrayHeaderDropdown.selectedViewByValue = this.galleryFilter.viewByG;
        this.viewBySelectedLabel = this.groupByObj[this.galleryFilter.viewByG]['text'];
        this.selectedTabHeader = this.galleryFilter.selectedAccordion;

        this.slideTrayFilter.organsF = this.galleryFilter.organ ? this.galleryFilter.organ : [];
        this.slideTrayFilter.stainsF = this.galleryFilter.stains ? this.galleryFilter.stains : [];
        this.slideTrayFilter.animalsF = this.galleryFilter.animalNo ? this.galleryFilter.animalNo : [];
        this.slideTrayFilter.controlF = this.galleryFilter.control ? this.galleryFilter.control : [];

        this.getStudyAccordionInfo(this.getSlidesIdentifierInfo.bind(this));

        this.galleryService.getStudyStainList(this.galleryFilter.studyName).subscribe((res: any) => {
          this.slideTrayFilter.studyStainList = res.data;
        });

        this.galleryService.getstudyOrganList(this.galleryFilter.studyName).subscribe((res: any) => {
          this.slideTrayFilter.studyOrganList = res.data;
        });

        this.galleryService.getstudyAnimalList(this.galleryFilter.studyName).subscribe((res: any) => {
          this.slideTrayFilter.studyAnimalList = res.data;
        });
        // console.log("Activated route");
      }
    });
  }

  filterViewBySelectedLabel() {
    if (!this.filterHeaderAccordData) {
      return;
    }
    // get the search keyword
    let search = this.viewBySelectedLabelFilterCtrl.value;
    this.headerAccordArr = (search.trim()) ? (this.filterHeaderAccordData.filter(tabItem => tabItem.attribute.toLowerCase().indexOf(search.toLowerCase()) > -1)) : this.filterHeaderAccordData;
  }

  getSlidesPostData(filterData: any = {}) {
    return {
      // "mainFilter": (filterData.mainFilter ? filterData.mainFilter : []),
      "subfilters": {
        "tissue": (filterData.subfiltersTissue ? filterData.subfiltersTissue : []),
        "cassette": (filterData.subfiltersCassette ? filterData.subfiltersCassette : []),
        "cassetteStain": (filterData.subfiltersCassetteStain ? filterData.subfiltersCassetteStain : []),
        "subjectId": (filterData.subfiltersSubjectId ? filterData.subfiltersSubjectId : []),
        "tcrSlideControl": (filterData.subfiltersTCRSlideControl ? filterData.subfiltersTCRSlideControl : []),
        "dossage": (filterData.subfiltersDossage ? filterData.subfiltersDossage : []),
        "sex": (filterData.subfiltersSex ? filterData.subfiltersSex : [])
      },
      "dosageControlGroup": false,
      "slidesMapped": 1,
      "viewByMore": "SHOW_ALL"
    }
  }

  getStudyAccordionInfo(callBack) {
    let postDataObj = {
      // mainFilter: [this.selectedTabHeader],
      subfiltersSubjectId: this.slideTrayFilter.animalsF,
      subfiltersTissue: this.slideTrayFilter.organsF,
      subfiltersCassetteStain: this.slideTrayFilter.stainsF,
      subfiltersTCRSlideControl: this.slideTrayFilter.controlF,
    };
    let searchData = this.getSlidesPostData(postDataObj);
    let groupByVal = this.slideTrayHeaderDropdown.selectedViewValue;
    if (groupByVal == "custom") {
      groupByVal = this.groupByObj[this.slideTrayHeaderDropdown.selectedViewByValue]['id'];
    }
    this.galleryService.getSlideAccordionData(this.galleryFilter.studyName, groupByVal, searchData).subscribe((res: any) => {
      this.filterHeaderAccordData = res.data;
      this.headerAccordArr = this.filterHeaderAccordData;
      if (!this.selectedTabHeader) {
        this.selectedTabHeader = this.filterHeaderAccordData[0]?.attribute;
      }
      if (this.selectedTabHeader) {
        this.noSlidesToDisplay = false;
        if (callBack) {
          callBack();
        }
      } else {
        this.noSlidesToDisplay = true;
        this.slidesGroupArr = [];
      }
    });
  }

  getSlidesIdentifierInfo() {
    this.selectedViewerSlideIdIndex = null;
    let postDataObj = {
      subfiltersSubjectId: this.slideTrayFilter.animalsF,
      subfiltersTissue: this.slideTrayFilter.organsF,
      subfiltersCassetteStain: this.slideTrayFilter.stainsF,
      subfiltersDossage: [],
      subfiltersSex: [],
      subfiltersCassette: [],
      // mainFilter: [],
    };

    // let selectedTabHeaderVal = $.isNumeric(this.selectedTabHeader) ? Number(this.selectedTabHeader) : this.selectedTabHeader;
    let selectedTabHeaderVal = this.selectedTabHeader;
    if (this.slideTrayHeaderDropdown.selectedViewValue == 'custom') {
      if (this.slideTrayHeaderDropdown.selectedViewByValue == "5") {
        postDataObj.subfiltersSubjectId = [selectedTabHeaderVal];
      } else if (this.slideTrayHeaderDropdown.selectedViewByValue == "3") {
        postDataObj.subfiltersTissue = [selectedTabHeaderVal];
      } else if (this.slideTrayHeaderDropdown.selectedViewByValue == "0") {
        postDataObj.subfiltersCassette = [selectedTabHeaderVal];
      } else if (this.slideTrayHeaderDropdown.selectedViewByValue == "1") {
        postDataObj.subfiltersDossage = [selectedTabHeaderVal];
      } else if (this.slideTrayHeaderDropdown.selectedViewByValue == "2") {
        postDataObj.subfiltersSex = [selectedTabHeaderVal];
      }
      /* postDataObj.subfiltersSubjectId = this.slideTrayFilter.animalsF;
      postDataObj.subfiltersTissue = this.slideTrayFilter.organsF;
      postDataObj.subfiltersCassetteStain = this.slideTrayFilter.stainsF; */

    } else {
      if (this.slideTrayHeaderDropdown.selectedViewValue == 'subjectId') {
        postDataObj.subfiltersSubjectId = [selectedTabHeaderVal];
      } else if (this.slideTrayHeaderDropdown.selectedViewValue == 'tissue') {
        postDataObj.subfiltersTissue = [selectedTabHeaderVal];
      }
    }
    let searchData = this.getSlidesPostData(postDataObj);

    let orderByVal = this.slideTrayHeaderDropdown.selectedViewValue;
    if (orderByVal == "custom") {
      orderByVal = this.groupByObj[this.slideTrayHeaderDropdown.selectedViewByValue]['id'];
    }
    this.galleryService.getSlidesIdentifierInfo(this.galleryFilter.studyName, orderByVal, searchData).subscribe((res: any) => {
      this.slidesGroupArr = res;
      let slidesIndexArr = [];
      this.rotateAllImgVal = this.commonService['config']['labelViewRotateDegree'];

      // for (let a = this.slidesGroupArr.length - 1; a >= 0; a--) {
      for (let a = 0; a < this.slidesGroupArr.length; a++) {
        this.slidesGroupArr[a]["isLabel"] = true;
        this.slidesGroupArr[a]["imageLableRotate"] = this.selectedSlides.hasOwnProperty(this.slidesGroupArr[a]["slidePk"]) ? this.selectedSlides[this.slidesGroupArr[a]["slidePk"]]["imageLableRotate"] : this.rotateAllImgVal;
        this.slidesGroupArr[a]["imageMacroRotate"] = this.selectedSlides.hasOwnProperty(this.slidesGroupArr[a]["slidePk"]) ? this.selectedSlides[this.slidesGroupArr[a]["slidePk"]]["imageMacroRotate"] : this.rotateAllImgVal;
        this.slidesGroupArr[a]["isSelected"] = this.selectedSlides.hasOwnProperty(this.slidesGroupArr[a]["slidePk"]);
        this.slidesGroupArr[a]["isStarMarked"] = this.starMarkedSlides.hasOwnProperty(this.slidesGroupArr[a]["slidePk"]);
        this.slidesGroupArr[a]["isOpenedInViewer"] = (this.selectedViewerSlideId.indexOf(this.slidesGroupArr[a]["slidePk"]) >= 0);

        if (this.selectedSlides.hasOwnProperty(this.slidesGroupArr[a]["slidePk"])) {
          this.selectedSlides[this.slidesGroupArr[a]["slidePk"]] = this.slidesGroupArr[a];
        }

        if (this.starMarkedSlides.hasOwnProperty(this.slidesGroupArr[a]["slidePk"])) {
          this.starMarkedSlides[this.slidesGroupArr[a]["slidePk"]] = this.slidesGroupArr[a];
        }

        if (this.slidesGroupArr[a]["isOpenedInViewer"] && !$.isNumeric(this.selectedViewerSlideIdIndex)) {
          this.selectedViewerSlideIdIndex = a;
          this.selectedViewerSlideInfo = this.slidesGroupArr[a];
        }
        slidesIndexArr.push(a);
      }

      if (!$.isNumeric(this.selectedViewerSlideIdIndex)) {
        this.selectedViewerSlideIdIndex = 0;
      }

      this.slidesIndex = slidesIndexArr.map(num => {
        let currindexDiff = (slidesIndexArr.length - this.selectedViewerSlideIdIndex);
        return (num - ((slidesIndexArr.length > this.slidesToDisplay) ? (this.selectedViewerSlideIdIndex - (((this.selectedViewerSlideIdIndex > this.slidesToDisplay) && (currindexDiff < this.slidesToDisplay)) ? currindexDiff : 0)) : 0));
      });

      this.centerSlideIndex = (this.selectedViewerSlideIdIndex + 1);
    });
  }

  getslideDisplayText() {
    this.centerSlideIndex = this.slidesIndex.indexOf(1);
    this.centerSlideIndex = (this.centerSlideIndex < 0) ? this.slidesIndex.length : this.centerSlideIndex;
    return {
      dispRange: this.centerSlideIndex + " - " + (this.centerSlideIndex + ((this.slidesIndex.length - this.centerSlideIndex) > 6 ? 6 : (this.slidesIndex.length - this.centerSlideIndex))),
      totalLength: this.slidesIndex.length
    }
    // return `Showing <span class="slideDisplayText">${this.centerSlideIndex} - ${this.centerSlideIndex + ((this.slidesIndex.length - this.centerSlideIndex) > 6 ? 6 : (this.slidesIndex.length - this.centerSlideIndex))}</span> of ${this.slidesIndex.length}`
  }

  selectedViewOnChange(e) {
    if (e.value == "starMarkedSlide") {
      this.slidesGroupArr = Object.values(this.starMarkedSlides);
      this.centerSlideIndex = 0;
      this.slidesIndex = [...Array(this.slidesGroupArr.length).keys()];
    } else {
      this.selectedTabHeader = null;
      this.getStudyAccordionInfo(this.getSlidesIdentifierInfo.bind(this));
    }
  }

  selectedViewByOnChange(e) {
    this.viewBySelectedLabel = e.source.triggerValue;
    this.selectedTabHeader = null;
    this.getStudyAccordionInfo(this.getSlidesIdentifierInfo.bind(this));
  }

  slideSelectedGroupOnChange(e) {
    if (e?.tab?.textLabel) {
      this.selectedTabHeader = e.tab.textLabel;
    }
    this.getSlidesIdentifierInfo();
  }

  toggleSlideTrayManager(showedTab: any = null) {
    if (showedTab) {
      this.selectedPanelTab = showedTab;
      this.isSlideTrayViewVisible = true;
    } else {
      this.isSlideTrayViewVisible = !this.isSlideTrayViewVisible;
    }
  }

  toggleSlideTrayManager1(selectedIndex: any = null) {
    if ($.isNumeric(selectedIndex)) {
      // this.selectedPanelIndex = selectedIndex;
      this.isSlideTrayViewVisible = true;
    } else {
      this.isSlideTrayViewVisible = !this.isSlideTrayViewVisible;
    }
  }

  moveTraySlide(type: string, howMany: number = 1) {
    if (type == "prev" && (this.slidesIndex[0] < 0) && (this.slidesIndex[0] > -this.slidesToDisplay)) {
      howMany = Math.abs(this.slidesIndex[0]);
    }
    this.slidesIndex = this.slidesIndex.map((index) => {
      return (type == 'next') ? (index - howMany) : (index + howMany);
    });
    this.centerSlideIndex = this.slidesIndex.indexOf(1);
    this.centerSlideIndex = (this.centerSlideIndex < 0) ? this.slidesIndex.length : this.centerSlideIndex;
  }

  onMouseWheel(e) {
    let moveType = (e.deltaY > 0) ? 'next' : 'prev';
    if (((moveType == 'next') && (this.slidesIndex[this.slidesIndex.length - 1] < this.slidesToDisplay)) || ((moveType == 'prev') && (this.slidesIndex[0] >= 0))) {
      return;
    }
    if (!this.mouseWheelTimeout) {
      this.mouseWheelTimeout = setTimeout(() => {
        this.mouseWheelTimeout = null;
        this.moveTraySlide(moveType, this.slidesToDisplay);
      }, 300);
    }
  }

  toggleAllSlideImg(e) {
    e.stopPropagation();
    this.showAllLabelImg = !this.showAllLabelImg;
    for (let a = 0; a < this.slidesGroupArr.length; a++) {
      this.slidesGroupArr[a]["isLabel"] = this.showAllLabelImg;
    }
  }

  compareAllSelectedSlideImg(e) {
    e.stopPropagation();
    let slidesPKArr = Object.keys(this.selectedSlides);
    if ((slidesPKArr.length > 1)) {
      if ((slidesPKArr.length <= 4)) {

        this.commonService.changePlace('image-viewer/' + slidesPKArr.toString());
        this.router.navigate(['/dashboard/image-viewer/' + slidesPKArr.toString()]);
        localStorage.setItem('samples', (slidesPKArr).toString());
      } else {
        this.commonService.showToastMsg("Maximum 4 slides are allowed to view in Image Compare");
      }
    } else {
      this.commonService.showToastMsg("Select atleast 2 Images to view in Image Compare");
    }
    localStorage.removeItem('slideIdDsData');
  }

  toggleSlideImg(slide, event) {
    slide.isLabel = !slide.isLabel;
  }

  rotateAllSlideImg(e, rotateType) {
    e.stopPropagation();
    /* let slidesPKArr = Object.keys(this.selectedSlides);
    if (slidesPKArr.length > 0) {
      let rotationVal = ((rotateType == "clockwise") ? 90 : -90);
      for (let a = 0; a < slidesPKArr.length; a++) {
        this.selectedSlides[slidesPKArr[a]]["imageLableRotate"] += rotationVal;
        this.selectedSlides[slidesPKArr[a]]["imageMacroRotate"] += rotationVal;
      }
    } else { */
    for (let a = 0; a < this.slidesGroupArr.length; a++) {
      if (this.slidesGroupArr[a]["isLabel"]) {
        let labelRotationVal = this.slidesGroupArr[a]["imageLableRotate"] + ((rotateType == "clockwise") ? 90 : -90);
        this.slidesGroupArr[a]["imageLableRotate"] = (labelRotationVal >= 360) ? (labelRotationVal - 360) : labelRotationVal;
      } else {
        let macroRotationVal = this.slidesGroupArr[a]["imageMacroRotate"] + ((rotateType == "clockwise") ? 90 : -90);
        this.slidesGroupArr[a]["imageMacroRotate"] = (macroRotationVal >= 360) ? (macroRotationVal - 360) : macroRotationVal;
      }
      /* } */
    }
  }

  rotateSlideImg(slide, event) {
    if (slide.isLabel) {
      let labelRotateValue = slide["imageLableRotate"] + 90;
      slide["imageLableRotate"] = (labelRotateValue >= 360) ? (360 - labelRotateValue) : labelRotateValue;
    } else {
      let macroRotateValue = slide["imageMacroRotate"] + 90;
      slide["imageMacroRotate"] = (macroRotateValue >= 360) ? (360 - macroRotateValue) : macroRotateValue;
    }
  }

  showViewerSlideImg(e) {
    e.stopPropagation();
    if (this.slidesGroupArr.length <= this.slidesToDisplay) {
      return;
    }
    let moveSlideByNumber = (this.centerSlideIndex - this.selectedViewerSlideIdIndex - 1);
    let direction = (moveSlideByNumber > 0) ? "prev" : "next";
    let howMany = Math.abs(moveSlideByNumber);
    this.moveTraySlide(direction, howMany);
  }

  viewSlideInfo(slide, e) {
    e.stopPropagation();
    this.isSlideDetailsOpened = (slide.slidePk == this.selectedSlideDetails?.slidePk) ? !this.isSlideDetailsOpened : true;
    if (this.isSlideDetailsOpened) {
      this.selectedSlideTabIndex = 0;
      this.galleryService.getSelectedSlideDataForPreview(slide.slidePk).subscribe((res: any) => {
        this.selectedSlideDetails = res.data;
      })
    }
  }

  slideDetailsTabChange(e) {
    this.selectedTargetTissue = this.selectedSlideDetails.organ[0];
    if (e.index == 1) {
      this.galleryService.getSlideReview(this.selectedTargetTissue, this.selectedSlideDetails.slidePk, localStorage.userActiveRole).subscribe((res: any) => {
        this.allSlideReviewData = res.data;
        if (this.allSlideReviewData) {
          this.selectedSlideReviewData = res.data[0];
          this.selectedUser = this.selectedSlideReviewData.userFk;
        }
      });
      /* if (this.galleryFilter.isTCRStudy) {
        this.galleryService.getAllIntensities().subscribe((response: any) => {
          response.data.forEach(element => {
            this.intensityObj[element.intensity] = element.description;
          });
        });
 
        this.galleryService.getAllFrequencies().subscribe((response: any) => {
          response.data.forEach(element => {
            this.frequencyObj[element.frequency] = element.description;
          });
        });
      } */
    }
  }

  changeUserForSlideReview(userName) {
    for (let x = 0; x < this.allSlideReviewData.length; x++) {
      if (userName == this.allSlideReviewData[x].userFk) {
        this.selectedSlideReviewData = this.allSlideReviewData[x];
      }
    }
  }

  selectSlideAction(e, slide) {
    slide.isSelected = e.currentTarget.checked;
    if (slide.isSelected) {
      this.selectedSlides[slide.slidePk] = slide;
    } else {

      delete this.selectedSlides[slide.slidePk];
    }
  }

  starMarkedSlideAction(slide) {
    slide.isStarMarked = !slide.isStarMarked;
    if (slide.isStarMarked) {
      this.starMarkedSlides[slide.slidePk] = slide;
    } else {
      delete this.starMarkedSlides[slide.slidePk];
    }
  }

  openImgInViewer(e, slide) {
    e.stopPropagation();
    if (slide.isOpenedInViewer) {
      return;
    }
    console.log("open img");
    if (localStorage.isViewerInitialized == "false") {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.VIEWER_NOT_INITIALISED"));
      return;
    }
    localStorage.isViewerInitialized = "false";
    if (!slide.slidePk) {
      return;
    }
    let dataObj = {
      "opened": true
    }
    this.galleryService.changeOpenStatus(slide.slidePk, dataObj).subscribe((res: any) => {
    });
    this.commonService.changePlace('image-viewer/' + slide.slidePk);
    this.router.navigate(['/dashboard/image-viewer/' + slide.slidePk]);

    this.saveLatestAppliedHeaderFilter();
  }

  saveLatestAppliedHeaderFilter() {
    this.galleryFilter.organ = this.slideTrayFilter.organsF;
    this.galleryFilter.stains = this.slideTrayFilter.stainsF;
    this.galleryFilter.animalNo = this.slideTrayFilter.animalsF;
    this.galleryFilter.control = this.slideTrayFilter.controlF;

    this.galleryFilter.selectedAccordion = this.selectedTabHeader;
    if (this.slideTrayHeaderDropdown.selectedViewValue == "custom") {
      this.galleryFilter.viewByG = this.slideTrayHeaderDropdown.selectedViewByValue;
    } else if (this.slideTrayHeaderDropdown.selectedViewValue == "subjectId") {
      this.galleryFilter.viewByG = "5";
    } else if (this.slideTrayHeaderDropdown.selectedViewValue == "tissue") {
      this.galleryFilter.viewByG = "3";
    }
    this.commonService.setGalleryFilters(JSON.stringify(this.galleryFilter));
  }

  resetSlideTrayFilter(e) {
    e.stopPropagation();
    this.slideTrayFilter.stainsF = [];
    this.slideTrayFilter.organsF = [];
    this.slideTrayFilter.animalsF = [];
    this.slideTrayFilter.controlF = [];
    this.selectedTabHeader = null;
    this.getStudyAccordionInfo(this.getSlidesIdentifierInfo.bind(this));
  }

  applySlideTrayCustomFilter(e) {
    e.stopPropagation();
    this.selectedTabHeader = null;
    this.getStudyAccordionInfo(this.getSlidesIdentifierInfo.bind(this));
  }
}
