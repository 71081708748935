declare const OpenSeadragon: any;
import { BehaviorSubject } from 'rxjs';
import { WebviewerWrapperService } from './WebviewerWrapper.service';

export class ImageViewer {
	private osdObj: any;
	private threeJsObj: any;
	private screenBoard: any;
	private imageDisplayed: any;
	private slideData: any;
	private schemaObj: any;
	private schemaImageProfilePerROI: any;
	private annotationsArr: any = [];

	private sliderRangeValueSource = new BehaviorSubject(0);
	sliderRangeValue = this.sliderRangeValueSource.asObservable();
	sliderRangeObservable: any;
	pixelToNanoVal: number;

	constructor(slideData: any, elementId: String, wrapperService: WebviewerWrapperService, isSplitViewEnabled: boolean, callBack: any) {
		this.setSlideData(slideData);
		const settings = {
			elementId: elementId,
			isSplitView: isSplitViewEnabled,
			slideIds: [slideData.slide.slidePk],
			identifier: slideData.slide.identifier,
			slidename: slideData.slide.slidename,
			loadThreeJs: true,
			// navigatorId: navId,
			getOSDCallback: (osd: any, ssBoard: any, pixelToNanometer: number, schemaObj: any, schemaImageProfilePerROI: any = null, threeJsObj: any = null) => {
				if (!osd) {
					callBack({ osd: "", annoboard: "", openseadragon: "" });
					return
				}
				osd.pixelToNanoVal = pixelToNanometer;
				this.osdObj = osd;
				this.osdObj.imageName = slideData.slide.slidename;
				this.osdObj.navigator ? this.osdObj.navigator.element.style.zIndex = "8" : "";
				this.screenBoard = ssBoard;
				this.pixelToNanoVal = pixelToNanometer;
				this.schemaObj = schemaObj;
				this.schemaImageProfilePerROI = schemaImageProfilePerROI;
				this.threeJsObj = threeJsObj;
				this.annotationsArr = [];
				callBack({ osd: osd, schemaObj: schemaObj, schemaImageProfilePerROI: schemaImageProfilePerROI, threeJsObj: threeJsObj });
			},
			imageChangeHandler: ''
		}
		wrapperService.setViewerSettings(settings);
		this.imageDisplayed = slideData.slide.slidePk;

	}

	getOsdObject() {
		return this.osdObj;
	}

	getViewerSchema() {
		return this.schemaObj;
	}

	getSchemaImageProfilePerROI() {
		return this.schemaImageProfilePerROI;
	}

	getOpenseadragon() {
		return OpenSeadragon;
	}

	getScreenshotObj() {
		return this.screenBoard;
	}

	getImageDisplayed() {
		return this.imageDisplayed;
	}

	getZoomValue() {
		return this.sliderRangeValue;
	}

	setZoomValue(value) {
		this.sliderRangeValueSource.next(value);
	}

	zoomToIndex = (text, sliderRangeValue, speed) => {
		//this.setZoomValue(sliderRangeValue);
		let maxZoomLevel = this.schemaObj.imageProfiles.imageProfile[1].resolution;
		if (text == "increase") {
			if (sliderRangeValue < maxZoomLevel) {
				this.setZoomValue(++sliderRangeValue);
			}
		} else if (text == "decrease") {
			if (sliderRangeValue > 1) {
				this.setZoomValue(--sliderRangeValue);
			}
		} else if (typeof text == 'number') {
			sliderRangeValue = text;
			this.setZoomValue(text);
		} else {
			this.setZoomValue(sliderRangeValue);
		}
		//this.sliderRangeObservable = this.sliderRangeValue.subscribe(value => {
		// this.osdObj.viewport.zoomTo(sliderRangeValue, speed, false);
		let imageToViewPortZoomValue = this.osdObj.viewport.imageToViewportZoom(sliderRangeValue / maxZoomLevel);
		this.osdObj.viewport.zoomTo(imageToViewPortZoomValue);
		// this.sliderRangeObservable = null;
		//this.sliderRangeObservable.unsubscribe();
		//});

	}

	setSlideData(slideData: any) {
		this.slideData = slideData;
	}

	getSlideData() {
		return this.slideData;
	}
}