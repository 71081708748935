import { ChangePasswordComponent } from './../change-password/change-password.component';
import { AuthService } from './../auth/auth.service';
import { CommonService } from '../common.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() name: string;

  roles: any;
  userRole: any;
  currentUser: any;
  firstName: any;
  lastName: any;
  activeRole: String;
  initials: String;
  circleColor: String;
  colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];
  
  isBroswerZoomed: boolean = false;
  systemZoomed: boolean = false;
  zoomWarningMsg: String = '';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkBrowserZoom();
  }
  @HostListener('window:focus', ['$event'])
  onSystemResize(event: any) {
    this.checkBrowserZoom();
  }

  constructor(private commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog) {
    this.checkBrowserZoom();
  }

  ngOnInit() {
    if (!localStorage.getItem('userRoleList')) {
      this.authService.logout();
    }
    if (JSON.parse(localStorage.isfirstLogin)) {
      this.showChangePasswordDialog();
    }
    if (!JSON.parse(localStorage.currentUser) && JSON.parse(localStorage.chooseSession)) {
      this.authService.logout().subscribe((response) => {
        this.commonService.stopSessionActiveStatus();
        this.router.navigate(['/login']);
      });
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.firstName = this.currentUser.firstName;
    this.lastName = this.currentUser.lastName;
    this.initials = ((this.currentUser?.firstName?.charAt(0) || '') + (this.currentUser?.lastName?.charAt(0) || '')).toUpperCase();
    const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
    this.circleColor = this.colors[randomIndex];
    this.roles = JSON.parse(localStorage.getItem('userRoleList'));
    this.activeRole = localStorage.getItem('userActiveRole');

    if (!this.roles && !this.activeRole) {
      let meetingParam = localStorage.getItem('meetingLoginParam');
      if (meetingParam) {
        this.router.navigate(['/login'], { queryParams: { id: meetingParam } });
      } else {
        this.router.navigate(['/login']);
      }
      this.authService.clearLocalStorage();
      this.authService.removeTokens();
      sessionStorage.setItem('meetingLoginParam', meetingParam);
      setTimeout(() => {
        window.location.reload();
      }, 100);
      return;
    }

    if (this.activeRole) {
      this.userRole = this.activeRole;
    } else {
      this.userRole = this.roles[0].role;
    }
    this.commonService.changeRoleId(this.userRole);
  }

  checkBrowserZoom() {
    let zoom = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
    this.isBroswerZoomed = false;
    if ((zoom < 95) || (zoom > 105)) {
      this.isBroswerZoomed = true;
    }
    this.systemZoomed = (window.devicePixelRatio != 1);

    this.setZoomWarningMsg();
  }

  setZoomWarningMsg() {
    if (this.isBroswerZoomed && this.systemZoomed) {
      this.zoomWarningMsg = 'Please reset system display scale value & browser zoom level to 100% for better UI experience';
    } else if (this.isBroswerZoomed) {
      this.zoomWarningMsg = 'Please reset browser zoom level to 100% for better UI experience';
    } else if (this.systemZoomed) {
      this.zoomWarningMsg = 'Please set system display scale value to 100% for better UI experience';
    } else {
      this.zoomWarningMsg = '';
    }
  }

  changeRole() {
    this.commonService.changePlace('');
    this.commonService.changeRoleId(this.userRole);
    var galleryFilter = JSON.parse(this.commonService.getGalleryFilters());
    galleryFilter = {
      "selectedOpt": "study",
      "studyName": "",
      "isTCRStudy": false,
      "viewByG": "5",
      "viewByS": "folderLocation",
      "viewByU": "week",
      "stains": [],
      "organ": [],
      "animalNo": [],
      "control": [],
      "viewType": "list",
      "readReviewFlag": ''
    }
    this.commonService.setGalleryFilters(JSON.stringify(galleryFilter));
  }
  logout() {
    this.commonService.checkFileProgress(() => {
      this.authService.logout().subscribe((response) => {
        this.commonService.stopSessionActiveStatus();
        this.router.navigate(['/login']);
      });
    });
  }

  showChangePasswordDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "40%";
    const dialogref = this.dialog.open(ChangePasswordComponent, dialogConfig);
  }

}
