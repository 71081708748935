import { Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { AppConfigService } from './../../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultationViewerService {

  consultationTools = {
    Image_Filter: {
      isVisible: false
    },
    Annotation_Setting: {
      isVisible: false
    },
    Rotation: {
      isVisible: false
    },
    Select_Image: {
      isVisible: false
    },
    Leave_Meeting: {
      isVisible: false
    },
    Screenshot: {
      isVisible: false
    },
    Easy_View: {
      isVisible: false
    },
    Compare_Images: {
      isVisible: false
    },
    Close_Viewer: {
      isVisible: false
    }
  }

  imageFilters = {
    brightness: 0,
    gamma: 1,
    contrast: 1
  }

  private activeToolS = new BehaviorSubject(this.consultationTools);
  activeTool = this.activeToolS.asObservable();

  private socket;
  private imageSource = new BehaviorSubject([]);
  selectedImage = this.imageSource.asObservable();

  private messageSource = new BehaviorSubject(0);
  messagesInfocus = this.messageSource.asObservable();

  isPresenterSource = new BehaviorSubject(false);
  isPresenter = this.isPresenterSource.asObservable();

  isHost: boolean = false;
  hostId: any = '';

  participantUserListSource = new BehaviorSubject({});
  participantUserList = this.participantUserListSource.asObservable();

  imageListSource = new BehaviorSubject([]);
  imageList = this.imageListSource.asObservable();

  accessRequestCountSource = new BehaviorSubject(0);
  accessRequestCount = this.accessRequestCountSource.asObservable();

  private rotationInitialValue = new BehaviorSubject(0);
  rotationValue = this.rotationInitialValue.asObservable();

  private participantChatPanelVisibeSource = new BehaviorSubject(false);
  participantChatPanelVisibe = this.participantChatPanelVisibeSource.asObservable();

  private presenterOnlineSource = new BehaviorSubject(false);
  presenterOnline = this.presenterOnlineSource.asObservable();

  private easyViewStartedSource = new BehaviorSubject(false);
  easyViewStarted = this.easyViewStartedSource.asObservable();

  // private selectedImagesForComapareSource = new BehaviorSubject([]);
  // selectedImagesForComapare = this.selectedImagesForComapareSource.asObservable();

  private imageViewerArrSource = new BehaviorSubject([]);
  imageViewerArr = this.imageViewerArrSource.asObservable();

  private viewerSource = new BehaviorSubject(0);
  viewerInfocus = this.viewerSource.asObservable();

  private zoomIndexSource = new BehaviorSubject(0);
  zoomIndex = this.zoomIndexSource.asObservable();

  private muteSource = new BehaviorSubject(true);
  mute = this.muteSource.asObservable();

  private selectedMicSource = new BehaviorSubject({});
  selectedMic = this.selectedMicSource.asObservable();

  private meetingDetailsSource = new BehaviorSubject({});
  meetingDetails = this.meetingDetailsSource.asObservable();

  private synchronizeImageSource = new BehaviorSubject(false);
  synchronizeImage = this.synchronizeImageSource.asObservable();

  private imageFilterSource = new BehaviorSubject(this.imageFilters);
  imageFiltersValues = this.imageFilterSource.asObservable();

  private allImageViewersFilterSource = new BehaviorSubject({});
  allImageViewersFilter = this.allImageViewersFilterSource.asObservable();

  private fineFocusEnableValue = new BehaviorSubject(true);
  fineFocusEnable = this.fineFocusEnableValue.asObservable();

  private fineFocusModeValue = new BehaviorSubject(false);
  fineFocusMode = this.fineFocusModeValue.asObservable();

  private config: any;
  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  public initSocket(): void {
    this.socket = socketIo(this.config.dcWsUrl, {
      transports: ['websocket'],
      'reconnection': true,
      'reconnectionDelay': 1000,
      'reconnectionDelayMax': 5000,
      'reconnectionAttempts': 'Infinity'
    });
    console.log(this.socket)
  }

  public onEvent(event: any): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, (res: any) => observer.next(res));
    });
  }

  public emitEvent(eventname: any, data: any) {
    this.socket.emit(eventname, data);
  }

  setSelectedMic(value: any) {
    this.selectedMicSource.next(value);
  }


  // setSelectedMic2(value: string) {
  //   this.selectedMiceSoucrce2.next(value);
  // }

  setSelectedImage(value: any) {
    this.imageSource.next(value);
  }

  setMessages(value: any) {
    this.messageSource.next(value);
  }

  setPresenter(value: any) {
    this.isPresenterSource.next(value);
    localStorage.setItem('isPresenter', value);
  }

  setHost(value: any) {
    // this.isHostSource.next(value);
    this.isHost = value;
  }

  getHost() {
    return this.isHost;
  }

  setHostID(value: any) {
    // this.isHostSource.next(value);
    this.hostId = value;
  }

  getHostID() {
    return this.hostId;
  }

  setParticipantUserList(value: any) {
    this.participantUserListSource.next(value);
  }

  setImageList(value: any) {
    this.imageListSource.next(value)
  }

  getMeetingDetails(meetingId: number) {
    return this.http.get(`${this.config.dcUrl}meetings/${meetingId}`);
  }

  updateMeetingSlides(meetingId: number, slidesObj: any) {
    return this.http.patch(`${this.config.dcUrl}meetings/${meetingId}/slides`, slidesObj);
  }

  updateMeetingStatus(meetingId: number, statusObj: any) {
    return this.http.patch(`${this.config.dcUrl}meetings/${meetingId}/status`, statusObj);
  }

  addNewParticipants(meetingId: number, inviteesObj: any) {
    return this.http.patch(`${this.config.dcUrl}meetings/${meetingId}/participants`, inviteesObj);
  }

  setAccessRequestCount(value: any) {
    this.accessRequestCountSource.next(value);
  }

  setActiveTool(tool: any) {
    for (var p in this.consultationTools) {
      if (p === tool) {
        this.consultationTools[p].isVisible = !this.consultationTools[p].isVisible;
      } else {
        this.consultationTools[p].isVisible = false;
      }
    }
    this.activeToolS.next(this.consultationTools);
  }

  setRotationValue(value: number) {
    this.rotationInitialValue.next(value);
  }

  setparticipantChatPanelVisibeValue(value) {
    this.participantChatPanelVisibeSource.next(value);
  }

  setpresenterOnline(value) {
    this.presenterOnlineSource.next(value);
  }

  getTileServerStatus() {
    return this.http.get(`${this.config.tileUrl}tiles/health`);
  }

  setEasyViewStarted(value: boolean) {
    this.easyViewStartedSource.next(value);
  }

  // setImagesForCompare(value: any) {
  //   this.selectedImagesForComapareSource.next(value);
  // }

  setImageViewerArr(imgViewerArr: any) {
    this.imageViewerArrSource.next(imgViewerArr);
  }

  focusChanged(index) {
    this.viewerSource.next(index);
  }

  setZoomValue(value) {
    this.zoomIndexSource.next(value);
  }

  setMuteStatus(value) {
    this.muteSource.next(value);
  }

  zoomToIndex = (text, sliderRangeValue, speed = false) => {
    //this.setZoomValue(sliderRangeValue);
    if (text == "increase") {
      if (sliderRangeValue < 40) {
        this.setZoomValue(++sliderRangeValue);
      }
    } else if (text == "decrease") {
      if (sliderRangeValue > 1) {
        this.setZoomValue(--sliderRangeValue);
      }
    } else if (typeof text == 'number') {
      sliderRangeValue = text;
      this.setZoomValue(text);
    } else {
      this.setZoomValue(sliderRangeValue);
    }
    //this.sliderRangeObservable = this.sliderRangeValue.subscribe(value => {
    // this.osdObj.viewport.zoomTo(sliderRangeValue, speed, true);
    // this.sliderRangeObservable = null;
    //this.sliderRangeObservable.unsubscribe();
    //});

  }

  setMeetingDetails(details: any) {
    this.meetingDetailsSource.next(details);
  }

  setSynchronization(isSync: boolean) {
    this.synchronizeImageSource.next(isSync);
  }

  setImageFilters(filters: any) {
    this.imageFilters.brightness = filters.brightness;
    this.imageFilters.contrast = filters.contrast;
    this.imageFilters.gamma = filters.gamma;
    this.imageFilterSource.next(this.imageFilters);
  }

  setAllImageFilters(allImageFilters: any = {}) {
    this.allImageViewersFilterSource.next(allImageFilters);
  }

  setFineFocusEnable(value: boolean) {
    this.fineFocusEnableValue.next(value);
  }

  setFineFocusMode(value: boolean) {
    this.fineFocusModeValue.next(value);
  }

}
