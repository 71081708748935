import { AppConfigService } from './../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

//const api = 'http://172.28.43.142:9070/airadhi/';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private config:any;

  constructor(private http: HttpClient, private configService:AppConfigService) {
    this.config = configService.config;
   }

  /* Field Configuration starts here */
  getFieldConfiguration() {
    return this.http.get(`${this.config.apiUrl}study/field/configuration/latest`);
  }

  addFieldConfiguration(fieldData){
    return this.http.post(`${this.config.apiUrl}study/field/configuration`,fieldData);
  }

  /* Field Configuration ends here */

  /* Data Configuration starts here */

  /* Study Domain*/
  getAllStudyDomains() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/study-domain`);
  }

  addStudyDomain(studyDomainObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/study-domain`,studyDomainObj);
  }

  deleteStudyDomain(studyDomainId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/study-domain/${studyDomainId}`);
  }

  /* Study Type*/
  getAllStudyTypes() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/study-type`);
  }

  addStudyType(studyTypeObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/study-type`,studyTypeObj);
  }

  deleteStudyType(studyTypeId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/study-type/${studyTypeId}`);
  }

  /*Species*/
  getAllSpecies() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/species`);
  }

  addSpecies(speciesObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/species`,speciesObj);
  }

  deleteSpecies(speciesId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/species/${speciesId}`);
  }

  addStrain(speciesId,strainObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/species/${speciesId}/strain`,strainObj);
  }

  deleteStrain(speciesId,strainId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/species/${speciesId}/strain/${strainId}`);
  }

  /* Stain */
  getAllStains() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/stain`);
  }

  addStain(stainObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/stain`,stainObj);
  }

  deleteStain(stainId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/stain/${stainId}`);
  }

  /* Treatment */
  getAllTreatments() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/treatment`);
  }

  addTreatment(treatmentObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/treatment`,treatmentObj);
  }

  deleteTreatment(treatmentId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/treatment/${treatmentId}`);
  }

  /* Route */
  getAllRoutes() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/route`);
  }

  addRoute(routeObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/route`,routeObj);
  }

  deleteRoute(routeId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/route/${routeId}`);
  }

  /* Organ List */
  getAllOrgans() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/organ`);
  }

  addOrgan(organObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/organ`,organObj);
  }

  deleteOrgan(organId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/organ/${organId}`);
  }

  /* Dosage Definition */
  getAllDosages() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/dosage`);
  }

  addDosage(dosageObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/dosage`,dosageObj);
  }

  deleteDosage(dosageId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/dosage/${dosageId}`);
  }

  /* Severity Grading */
  getAllGradings() {
    return this.http.get(`${this.config.apiUrl}slide-image/data/configuration/grading`);
  }

  addGrading(gradingObj) {
    return this.http.post(`${this.config.apiUrl}slide-image/data/configuration/grading`,gradingObj);
  }

  deleteGrading(gradingId){
    return this.http.delete(`${this.config.apiUrl}slide-image/data/configuration/grading/${gradingId}`);
  }

  /* Intensity */
  getAllIntensities() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/intensity`);
  }

  addIntensity(intensityObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/intensity`,intensityObj);
  }

  deleteIntensity(intensityId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/intensity/${intensityId}`);
  }

  /* Frequency */
  getAllFrequencies() {
    return this.http.get(`${this.config.apiUrl}study/data/configuration/frequency`);
  }

  addFrequency(frequencyObj) {
    return this.http.post(`${this.config.apiUrl}study/data/configuration/frequency`,frequencyObj);
  }

  deleteFrequency(frequencyId){
    return this.http.delete(`${this.config.apiUrl}study/data/configuration/frequency/${frequencyId}`);
  }

  /* Folder Location */
  getAllFolderLocation() {
    return this.http.get(`${this.config.apiUrl}slide-image/data/configuration/imagefolder`);
  }

  getAllFolderLocationNonEmpty() {
    return this.http.get(`${this.config.apiUrl}slide-image/data/configuration/imagefolder/nonempty`);
  }

  addFolderLocation(folderLocationObj) {
    return this.http.post(`${this.config.apiUrl}slide-image/data/configuration/imagefolder`,folderLocationObj);
  }

  deleteFolderLocation(folderLocationId){
    return this.http.delete(`${this.config.apiUrl}slide-image/data/configuration/imagefolder/${folderLocationId}`);
  }

  /* Data Configuration ends here */
}
