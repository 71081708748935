<mat-spinner style="margin:0 auto;" *ngIf="showLoader"></mat-spinner>
<div *ngIf="(galleryGrid | json) == '{}' && !showLoader" class="row" id="preview_hidden"
    style="margin: 9rem auto;width: fit-content;">
    <img src="../../../assets/images/viewer_icon/no_gallery_data.png" />
</div>
<div class="gridscroll">
    <div class="accordion" id="accordionExample_l">
        <!-- autoclick class used to click div automatically -->
        <div *ngFor="let slide of galleryGrid | keyvalue: returnZero;let i = index">
            <div class="card autoclick">
                <div class="card-header accordianHeaderDiv" [ngClass]="{'collapsed': i!=openCollapseIndex}"
                    id="{{'heading_'+i}}" (click)="groupChange(i, slide.key)">
                    <i class="fa fa-angle-down rotate-icon"></i>
                    <input (click)="$event.stopPropagation();" id="{{'selectAll_'+i}}"
                        class="cloumnIcons {{'selectAll_'+i}}" name="selectAll" type="checkbox"
                        *ngIf="slide.value.alreadyrender && i==openCollapseIndex"
                        (change)="checkAll($event, slide.value.slidesArr, slide.key)"
                        [checked]="slide.value?.selecteMessage">
                    <span class="textSpan">{{slide.key}} </span>
                    <span class="selectedMsgText" *ngIf="slide.value?.selecteMessage">
                        ({{slide.value?.selecteMessage}})</span>
                </div>

                <div id="{{'collapse_'+i}}" class="collapse" [ngClass]="{'show': i==openCollapseIndex}"
                    data-parent="#accordionExample_l" (scroll)="onTableScroll($event,slide.key)">
                    <div class="card-body clearfix">
                        <div class="accordionExample1" *ngIf="slide.value.alreadyrender">
                            <div class="fLeftCard" *ngFor="let animal of slide.value.slidesArr;let j = index">
                                <div class="card-group card-groupactive"
                                    [ngClass]="{ 'card-groupactive' : (selectgroupSlideKey == i+'_'+j) && preview}">
                                    <div class="card imgcard"
                                        [ngClass]="{ 'imgcardactive' : (selectgroupSlideKey == i+'_'+j) && preview}">

                                        <div class="griddetcard card-body">
                                            <div class="griddet">
                                                <input aria-label="" #checkbox_{{animal.slidePk}} type="checkbox"
                                                    [checked]="((activeMode == 'consultation' ||activeMode == 'consultation-viewer') && checkIfImageMarked(animal.slidePk))"
                                                    class="form-check-input imgcheck"
                                                    id="{{'checkbox_'+animal.slidePk}}"
                                                    (click)="setSelectedSlideList(animal,$event.target.checked);"
                                                    [(ngModel)]="animal.state" [disabled]="animal.isDisabled">
                                                <!--*ngIf="activeMode != 'mapped' || (activeMode == 'mapped' && animal.unTouched)"-->
                                                <span title="{{animal.animalNo}}" class="animalid subjectIdEllipses"
                                                    *ngIf="activeMode == 'gallery' || activeMode == 'mapped'|| activeMode == 'image-viewer'">
                                                    <b>{{animal.animalNo}}</b></span>
                                                <span class="card-title"
                                                    *ngIf="activeMode == 'scanned' || activeMode == 'uploaded'">
                                                    <b>{{animal.slidePk}}</b></span>
                                                <span *ngIf="activeMode == 'gallery' || activeMode == 'mapped'">&#44;
                                                    &nbsp;</span> <span
                                                    *ngIf="!galleryFilter.isTCRStudy && (activeMode == 'gallery' || activeMode == 'mapped')"
                                                    data-toggle="tooltip"
                                                    title="Dosage Group">{{animal.dosageLevel}}&#44;&nbsp;</span>
                                                <span
                                                    *ngIf="galleryFilter.isTCRStudy && animal.slideControl!='Test' && (activeMode == 'gallery' || activeMode == 'mapped')"
                                                    data-toggle="tooltip"
                                                    title="Control">{{animal.slideControl}}&#44;&nbsp;</span>
                                                <span *ngIf="activeMode == 'gallery' || activeMode == 'mapped'"
                                                    data-toggle="tooltip" title="Sex">{{animal.sex}}</span>
                                            </div>
                                            <!-- <div class="gridImgMainDiv">
                                                <div class="dummyLableQR">
                                                    <img src="../../../assets/images/defaultLable_qr.png" />
                                                </div>
                                                <img (click)="openViewer(animal.slidePk, i, slide.key)"
                                                    [ngClass]="{'thumbnail-disabled' : activeMode == 'image-viewer' || activeMode == 'consultation' || activeMode == 'consultation-viewer'}"
                                                    [ngStyle]="{'transform': 'rotate('+animal.imgRotationDegree+'deg)'}"
                                                    [defaultImage]="defaultImagePath"
                                                    [lazyLoad]="thumbnailUrl+animal.identifier+'_thumbnail.jpeg'"
                                                    class="img-responsive animal_img" alt="tissueimg">
                                            </div> -->

                                            <div class="gridImgMainDiv" (click)="openViewer(animal.slidePk, i, slide.key)">
                                                <img [defaultImage]="defaultImagePath" *ngIf="!config.anonymisedLabel"
                                                    [ngStyle]="{'transform': 'rotate('+animal.imgRotationDegree+'deg)'}"
                                                    [lazyLoad]="thumbnailUrl+animal.identifier+'_label.jpeg'"
                                                    class="img-responsive animal_img labelImg" alt="tissueimg">
                                                <img *ngIf="config.anonymisedLabel"
                                                    class="img-responsive animal_img dummyQRCode"
                                                    [src]="'../../../assets/images/defaultLable_qr.png'" />
                                                <div class="topImgDiv">
                                                    <!-- [ngStyle]="{'transform': 'rotate('+animal.imgRotationDegree+'deg)'}" -->
                                                    <img [defaultImage]="defaultImagePath"
                                                        [lazyLoad]="thumbnailUrl+animal.identifier+'_macro.jpeg'"
                                                        class="img-responsive animal_img macroImg" alt="tissueimg">
                                                </div>
                                            </div>

                                            <div title="{{animal.slidename}}"
                                                [ngClass]="{'grayout' : (animal.opened),'boldrows' : (!animal.opened)}"
                                                class="truncate_slide_name">
                                                {{animal.slidename}}</div>
                                            <div class="icondisplay">
                                                <i (click)="openPreview(i+'_'+j);" class="fa fa-eye righteyeicon"
                                                    title="Show Preview"></i>

                                                <i *ngIf="((animal.readStatus == 'UNREAD') || (animal.readStatus == 'READ')) && activeMode != 'uploaded' && activeMode != 'mapped'"
                                                    [ngClass]="(animal.readStatus == 'UNREAD')?'readgrey':'readorange'"
                                                    [title]="(animal.readStatus == 'UNREAD')?'Unread':'Read'"
                                                    class="fa fa-circle"></i>

                                                <!-- <div *ngIf="animal.readStatus == 'UNREAD' && activeMode != 'uploaded' && activeMode != 'mapped'"
                                                    class="readgrey" title="Unread"><i class="fa fa-circle"></i></div>
                                                <div *ngIf="animal.readStatus == 'READ' && activeMode != 'uploaded' && activeMode != 'mapped'"
                                                    class="readorange" title="Read"><i class="fa fa-circle"></i></div> -->

                                                <i *ngIf="((animal.reviewStatus == 'LATER') || (animal.reviewStatus == 'DONE')) && activeMode != 'uploaded' && activeMode != 'mapped'"
                                                    [ngClass]="(animal.reviewStatus == 'DONE')?'readgrey':'readorange'"
                                                    [title]="(animal.reviewStatus == 'DONE')?'Review Done':'Review Later'"
                                                    class="reviewfont fa fa-sticky-note"></i>

                                                <!-- <div *ngIf="animal.reviewStatus == 'LATER' && activeMode != 'uploaded' && activeMode != 'mapped'"
                                                    class="readorange reviewfont" title="Review Later"><i
                                                        class="fa fa-sticky-note"></i>
                                                </div>
                                                <div *ngIf="animal.reviewStatus == 'DONE' && activeMode != 'uploaded' && activeMode != 'mapped'"
                                                    class="readgrey reviewfont" title="Review Done"><i
                                                        class="fa fa-sticky-note"></i></div> -->

                                                <i *ngIf="animal.reviewStatus != 'DONE' && animal.reviewStatus != 'LATER' && activeMode != 'uploaded' && activeMode != 'mapped'"
                                                    title="Not marked for review"
                                                    class="readgrey reviewfont fa fa-sticky-note-o"></i>

                                                <!-- <div *ngIf="animal.reviewStatus != 'DONE' && animal.reviewStatus != 'LATER' && activeMode != 'uploaded' && activeMode != 'mapped'"
                                                    class="readgrey reviewfont" title="Not marked for review"><i
                                                        class="fa fa-sticky-note-o"></i></div> -->
                                            </div>

                                        </div>
                                        <div class="dn griddetcard">
                                            <div class="shareoption rightfloat">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="loadingMessage"
                        *ngIf="slide.value && slide.value.pageInfo && slide.value.pageInfo.more">
                        <div class="loadMessage">
                            <span>Loading...</span>
                        </div>
                        <!-- <span *ngIf="!slide.value.pageInfo.more">No More data to load</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>