import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (!this.authService.isLoggedIn()) {
      this.authService.doLogoutUser();
      // alert("common.guard");
      this.router.navigate(['/login']);
    }
    return this.authService.isLoggedIn();
  }
}
