import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inputError'
})
export class InputErrorPipe implements PipeTransform {

    transform(value: any, arg1): string {
        let rvalue: string = '';
        if (value !== null) {
            switch (arg1) {
                case 'studyNo': return value['required'] == true ? rvalue = 'ERROR.STUDYNO.REQUIRED' : 'ERROR.STUDYNO.MAXLENGTH';
                case 'studyTitle': return value == true ? rvalue = 'ERROR.STUDYTITLE.REQUIRED' : null;
                case 'pathologists': return value == true ? rvalue = 'ERROR.PATHOLOGIST.REQUIRED' : null;
                case 'technician': return value == true ? rvalue = 'ERROR.TECHNICIAN.REQUIRED' : null;
                case 'species': return value == true ? rvalue = 'ERROR.SPECIES.REQUIRED' : null;
                case 'strain': return value == true ? rvalue = 'ERROR.STRAIN.REQUIRED' : null;
                case 'toDate' : return value == true ? rvalue = 'ERROR.TODATE.INVALID' : null;
                case 'cassetteId':
                    if (value.hasOwnProperty('required')) {
                        return rvalue = 'ERROR.CASSETTEID.REQUIRED';
                    }
                    if (value.hasOwnProperty('unique')) {
                        return rvalue = 'ERROR.CASSETTEID.UNIQUE';
                    }
                    if(value.hasOwnProperty('maxLength')){
                        return rvalue = 'ERROR.CASSETTEID.MAXLENGTH';
                    }
                case 'stains': return value == 0 ? rvalue = 'ERROR.STAINS.REQUIRED' : null;
                case 'organs': return value == 0 ? rvalue = 'ERROR.TARGETORGANS.REQUIRED' : null;
                case 'dosageCode':
                    if (value.hasOwnProperty('required')) {
                        return rvalue = 'ERROR.DOSAGEID.REQUIRED';
                    }
                    if (value.hasOwnProperty('unique')) {
                        return rvalue = 'ERROR.DOSAGEID.UNIQUE';
                    }
                case 'type' : return value == 0 ? rvalue = 'ERROR.TYPE.REQUIRED' : null;
                case 'range_male_from': return value == true ? rvalue = 'ERROR.RANGE_MALE_FROM.REQUIRED' : null;
                case 'range_male_to' : return value == true ? rvalue = 'ERROR.RANGE_MALE_TO.REQUIRED' : null;
                case 'range_female_from' : return value == true ? rvalue = 'ERROR.RANGE_FEMALE_FROM.REQUIRED' : null;
                case 'range_female_to': return value == true ? rvalue = 'ERROR.RANGE_FEMALE_TO.REQUIRED' : null;
                case 'custom_male' : return value == true ? rvalue = 'ERROR.CUSTOM_MALE.REQUIRED' : null; 
                case 'custom_female' : return value == true ? rvalue = 'ERROR.CUSTOM_FEMALE.REQUIRED' : null;
                case 'controlGroup' : return value == true ? rvalue = 'ERROR.CONTROLGROUP.REQUIRED' : null;            
            }
        }
    }
}