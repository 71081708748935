import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { ModalDigitalSignatureComponent } from 'src/app/digital-signature/modal-digital-signature/modal-digital-signature.component';
import { UsersService } from '../users/users.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private isGroupingAnnotation = true;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    slideDsTokenData: any = [];

    constructor(public authService: AuthService, private snackBar: MatSnackBar, private usersService: UsersService,
        private dialog: MatDialog,
        private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.url.includes('/schema.json') || !request.url.includes('public-access/') || !request.url.includes('forgetUserPassword?userId') || !request.url.includes('verifyCode') || !request.url.includes('resetPassword') || !request.url.includes('session/config')) {
            if (this.authService.getAccessToken()) {
                request = this.addToken(request, this.authService.getAccessToken());
            } else {
                request = this.setHeader(request);
            }
        }

        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                if (request.url.indexOf('/user/login') != -1) {
                    return throwError(error);
                } else if (error.error?.type == "DS_ERROR") {
                    this.isGroupingAnnotation = error.error?.annotationType == 'G' ? true : false;
                    if (request.url.includes('/slide-image/annotation') && this.isGroupingAnnotation && !request.url.includes('/slide-image/annotation?')) {
                        let slideIdDsData: any = JSON.parse(localStorage.getItem('slideIdDsData'));
                        let slideIdDsDataLocal: any = slideIdDsData != null ? slideIdDsData.filter((e) => e.slideId == (request.body != null ? request.body.slideId : request.url.split("slideId=")[1])) : '';

                        if (slideIdDsDataLocal.length >= 1) {
                            const req = request.clone({
                                setHeaders: {
                                    "X-DSHeader": `Bearer ${slideIdDsDataLocal[0].dsToken}`,
                                },
                                body: { ...request.body, dsComments: slideIdDsDataLocal[0].dsComments }
                            });
                            // return next.handle(req)
                            return next.handle(req).pipe(catchError(error => {
                                if (error instanceof HttpErrorResponse && error.status === 401) {
                                    // alert()
                                    const req = request.clone({
                                        setHeaders: {
                                            "X-DSHeader": `Bearer ${error.error.dsToken}`,
                                        },
                                        body: { ...request.body, dsComments: slideIdDsDataLocal[0].dsComments }
                                    });
                                    return next.handle(req);
                                };
                            }))
                        }
                    } else if (this.usersService.isDsTokenStored && request.url.includes('updateUser')) {
                        const req = request.clone({
                            setHeaders: {
                                "X-DSHeader": `Bearer ${this.usersService.getDsToken()}`,
                            },
                            body: { ...request.body, dsComments: this.usersService.dsComment }
                        });
                        // console.warn(req)
                        return next.handle(req)

                    } else {
                        return this.handleDskToken(request, next, error);
                    }
                }
                else {
                    return this.handle401Error(request, next);
                }
            } else {
                if (request.url.indexOf('/token/refresh') != -1) {
                    this.isRefreshing = false;
                } else if (request.url.includes('/generate-token') && (error.status === 403)) {
                    this.snackBar.open(error.error.message, '', {
                        duration: 5000,
                        verticalPosition: 'top'
                    });
                } else if (request.url.includes('/generate-otp')) {
                    if (error.status === 200) {
                        this.snackBar.open('Mail Sent Successfully.', '', {
                            duration: 5000,
                            verticalPosition: 'top'
                        });
                    } else {
                        this.snackBar.open('Error while generating otp.', '', {
                            duration: 5000,
                            verticalPosition: 'top'
                        });
                    }
                }
                if (error instanceof HttpErrorResponse && ((error.status === 500) || (error.status === 409))) {
                    this.snackBar.open(error.error.message, '', {
                        duration: 5000,
                        verticalPosition: 'top'
                    });
                }
                return throwError(error);
            }
        }));
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private setHeader(request: HttpRequest<any>) {
        /* if (this.authService.getRefreshToken()) {
            return request.clone({
                setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded; ' }
            });
        } else { */ //commented to resolve epl issue of logging out after 1hr
        return request.clone({
            setHeaders: { 'Content-Type': 'application/json; ', 'X-CSRF-TOKEN': '1234567' }
        });
        //}
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.data.accessToken);
                    return next.handle(this.addToken(request, token.data.accessToken));
                }),
                catchError((err: any) => {
                    this.isRefreshing = false;
                    if ((err.status === 400)) {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.width = "20%";
                        dialogConfig.data = {
                            headerTitle: "Login",
                            confirmMsg: "Session expired. Login again to continue",
                            cancelButtonText: "Cancel",
                            confirmButtonText: "Yes"
                        };
                        const dialogref = this.dialog.open(LoginDialogComponent, dialogConfig);
                        return dialogref.afterClosed().pipe(switchMap((result: any) => {
                            if (result) {
                                this.refreshTokenSubject.next(this.authService.getAccessToken());
                                return next.handle(this.addToken(request, this.authService.getAccessToken()));
                            } else {
                                this.authService.doLogoutUser();
                                let meetingParam = sessionStorage.getItem('meetingLoginParam');
                                if (meetingParam && meetingParam != "null") {
                                    this.router.navigate(['/login'], { queryParams: { id: meetingParam } });
                                } else {
                                    this.router.navigate(['/login']);
                                }
                                return throwError(err);
                            }
                        }));
                    } else {
                        return throwError(err);
                    }
                }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(access_token => {
                    return next.handle(this.addToken(request, access_token));
                }));
        }
    }
    private handleDskToken(
        request: HttpRequest<any>,
        next: HttpHandler,
        error: HttpErrorResponse
    ) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "30%";
        const dialogref = this.dialog.open(
            ModalDigitalSignatureComponent,
            dialogConfig
        );
        return dialogref.afterClosed().pipe(
            switchMap((result: any) => {
                if (result) {
                    if (result == "Cancel Request") {
                        if (request.url.includes('study-domain')) {
                            return EMPTY
                        }
                    } else {
                        if (request.url.includes('/slide-image/annotation') && this.isGroupingAnnotation && !request.url.includes('/slide-image/annotation?')) {
                            let slideDsToken: any = JSON.parse(localStorage.getItem('slideIdDsData'));
                            // let slideExistinToken: any = this.slideDsTokenData.filter((e) => e.slideId == (request.body != null ? request.body.slideId : request.url.split("slideId=")[1]))
                            if (slideDsToken != null) {
                                let slideExist: any = slideDsToken != null ? slideDsToken.filter((e) => e.slideId == (request.body != null ? request.body.slideId : request.url.split("slideId=")[1])) : '';
                                if (slideExist.length >= 1) {
                                } else {
                                    this.slideDsTokenData.push({ 'slideId': request.body != null ? request.body.slideId : request.url.split("slideId=")[1], 'dsToken': result.data.dsToken, 'dsComments': result.dsComment });
                                    localStorage.setItem('slideIdDsData', JSON.stringify(this.slideDsTokenData));
                                }
                            } else {
                                this.slideDsTokenData = [];
                                this.slideDsTokenData.push({ 'slideId': request.body != null ? request.body.slideId : request.url.split("slideId=")[1], 'dsToken': result.data.dsToken, 'dsComments': result.dsComment });
                                localStorage.setItem('slideIdDsData', JSON.stringify(this.slideDsTokenData));
                            }
                        } else if (request.url.includes('/study/updateUser')) {
                            this.usersService.setDsToken(result.data.dsToken);
                            this.usersService.dsComment = result.dsComment;
                            ;
                        }

                        const req = request.clone({
                            setHeaders: {
                                "X-DSHeader": `Bearer ${result.data.dsToken}`,
                            },
                            body: { ...request.body, dsComments: result.dsComment }
                        });
                        // console.warn(req)
                        return next.handle(req).pipe(catchError((error: any) => {
                            if (error instanceof HttpErrorResponse && ((error.status === 500) || (error.status === 409))) {
                                this.snackBar.open(error.error.message, '', {
                                    duration: 5000,
                                    verticalPosition: 'top'
                                });
                            }
                            return throwError(error);
                        }));
                    }
                }
                return throwError(result);
            }
            )
        );
    }
}