import { ConfirmDialogComponent } from './../../../common/confirm-dialog/confirm-dialog.component';
import { element } from 'protractor';
import { CommonService } from './../../../common.service';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MeetingService } from './../meeting.service';
import { GalleryService } from './../../../gallery/gallery.service';
import { AppConfigService } from './../../../app-config.service';
import { MeetingDialogComponent } from './../meeting-dialog/meeting-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { startWith, map, filter, switchMap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ConsultationService } from './../../consultation.service';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-meeting-create',
  templateUrl: './meeting-create.component.html',
  styleUrls: ['./meeting-create.component.css']
})
export class MeetingCreateComponent implements OnInit {

  thumbnailUrl: any;
  private config: any;

  cols = 2;
  public timeZoneFilterCtrl: FormControl = new FormControl();
  public filteredTimeZoneArr = [];
  addedImages = {};

  selectedImgArr = [];
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  defaultImagePath = "../../../assets/images/viewer_icon/gallery_noimg.png";

  @ViewChild('picker') picker: any;

  // public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public showSeconds = true;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;

  public dateControl = new FormControl(new Date());
  meetingForm: FormGroup;

  timeZoneArr = [];
  shotMonthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  meetingId: number;


  @ViewChild('particpantInput') particpantInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;

  separatorKeysCodes = [ENTER, COMMA];
  participantCtrl = new FormControl();
  userArr = [];
  filteredParticipants: Observable<any[]>;
  participantList = [];
  minDate = new Date();

  startDateTime = '';
  endDateTime = '';
  participantsInValid = false;
  duration = '';

  showSavingButton: boolean = false;
  showDraftButton: boolean = true;

  deletedInviteesArr = [];
  deletedExternalArr = [];

  dateTimeChanged: boolean = false;

  constructor(private consultationService: ConsultationService, private dialog: MatDialog,
    private configService: AppConfigService, private galleryService: GalleryService,
    private meetingService: MeetingService, private formbuilder: FormBuilder,
    private activatedRoute: ActivatedRoute, private commonService: CommonService,
    public translate: TranslateService, private router: Router) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.config = configService.config;
    this.thumbnailUrl = `${this.config.thumbnailUrl}`;
    this.filteredParticipants = this.participantCtrl.valueChanges.pipe(
      startWith(null),
      map((participant: string | null) => typeof (participant) == "string" ? this._filter(participant) : this.userArr.slice()));
    this.getInitialData();
  }

  ngOnInit() {
    //console.log(this.minDate);
    this.activatedRoute.paramMap.subscribe((paramMap: any) => {

      let initialStartTime, initialEndTime, initialStartTimeString, initialEndTimeString, d;
      d = new Date();
      if (new Date(d).getMinutes() >= 30) {
        initialStartTime = new Date(d).setHours(new Date(d).getHours() + 1);
        initialStartTime = new Date(initialStartTime).setMinutes(0);
      } else {
        initialStartTime = new Date(d).setMinutes(30);
      }
      initialEndTime = new Date(initialStartTime).setHours(new Date(initialStartTime).getHours() + 1);
      initialStartTimeString = new Date(initialStartTime).getHours() + ":" + new Date(initialStartTime).getMinutes();
      initialEndTimeString = new Date(initialEndTime).getHours() + ":" + new Date(initialEndTime).getMinutes();
      this.meetingForm = this.formbuilder.group({
        meetingTitle: ['', [Validators.required]],
        meetingDescription: [''],
        meetingDate: [new Date(), [Validators.required]],
        meetingStartTime: [initialStartTimeString, [Validators.required]],
        meetingEndTime: [initialEndTimeString, [Validators.required]],
        meetingTimeZone: [Intl.DateTimeFormat().resolvedOptions().timeZone]
      });
      this.timeChanged(false);
      if (paramMap.params.meetingId) {
        this.meetingId = paramMap.params.meetingId;
        let meetingData = this.getMeetingData(paramMap.params.meetingId);
        meetingData.then((resp: any) => {
          let res = resp.data;
          if (res.status != 'DRAFT') {
            this.showDraftButton = false;
          }
          this.meetingForm.controls.meetingTitle.setValue(res.title);
          this.meetingForm.controls.meetingDescription.setValue(res.description);
          this.meetingForm.controls.meetingDate.setValue(new Date(res.meetingDate));
          this.meetingForm.controls.meetingTimeZone.setValue(res.timezone.zone_pk);
          let startDate = this.commonService.convertTZ(res.startTime, res.timezone.zone_pk);
          this.meetingForm.controls.meetingStartTime.setValue(new Date(startDate).getHours() + ":" + new Date(startDate).getMinutes());
          let endDate = this.commonService.convertTZ(res.endTime, res.timezone.zone_pk);
          this.meetingForm.controls.meetingEndTime.setValue(new Date(endDate).getHours() + ":" + new Date(endDate).getMinutes());
          this.timeChanged(false);
          res.invitees.forEach(element => {
            let index = this.userArr.map(function (e) { return e.email; }).indexOf(element.emailId);
            element.valid = true;
            element.displayName = element.displayName;
            element.email = element.emailId;
            element.isAlreadySaved = true;
            this.participantList.push(element);
            this.userArr.splice(index, 1);
          });
          let slidearr = [];
          if (res.slideIds.length > 0) {
            res.slideIds.forEach(dataVal => {
              if (dataVal.slideId.length > 0) {
                slidearr = [...slidearr, ...dataVal.slideId];
              }
            });
            this.consultationService.getSlidesDetail(slidearr).subscribe((res: any) => {
              res.data.forEach(element => {
                if (!this.addedImages[element.slidePk]) {
                  this.addedImages[element.slidePk] = element;
                }
              });
            });
          }
        });
      }
    });
  }

  getInitialData() {
    this.meetingService.getTimezoneList().subscribe((timeZoneData: any) => {
      this.timeZoneArr = timeZoneData.data;
      this.filteredTimeZoneArr = this.timeZoneArr;
    })
    this.consultationService.getParticipantsList().subscribe((userData: any) => {
      this.userArr = userData.data.filter((userData: any) => {
        return userData.email != JSON.parse(localStorage.getItem('currentUser')).userName;
      });
    });
    this.timeZoneFilterCtrl.valueChanges.subscribe(() => {
      this.filterTimeZone();
    });

    this.participantCtrl.valueChanges.subscribe(() => {
      this.highlightFirstOption();
    })
  }
  private async getMeetingData(id: number) {
    return await this.meetingService.getMeetingDetail(id).toPromise();
  }
  get meetingF() {
    return this.meetingForm.controls;
  }

  dateTimeTimeZoneChangeFn() {
    this.dateTimeChanged = true;
  }

  timeChanged(timeChangedFlag) {
    this.dateTimeChanged = timeChangedFlag;
    if (this.meetingForm.value.meetingStartTime && this.meetingForm.value.meetingEndTime) {
      let start = this.meetingForm.value.meetingStartTime.split(":");
      let end = this.meetingForm.value.meetingEndTime.split(":");
      let startDate = new Date(0, 0, 0, start[0], start[1], 0);
      let endDate = new Date(0, 0, 0, end[0], end[1], 0);

      this.duration = this.consultationService.calculateDuration(startDate, endDate);

    }
  }

  highlightFirstOption(): void {
    this.matAutocomplete._keyManager.setFirstItemActive();
  }

  filterTimeZone() {
    if (!this.timeZoneArr) {
      return;
    }
    // get the search keyword
    let search = this.timeZoneFilterCtrl.value;
    if (search.trim()) {
      this.filteredTimeZoneArr = this.timeZoneArr.filter(time => time.zone_name.toLowerCase().indexOf(search) > -1)
    } else {
      this.filteredTimeZoneArr = this.timeZoneArr
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      let filteredData = this._filter(value.trim());
      if (filteredData.length > 0) {
        let index = this.userArr.indexOf(filteredData[0]);
        filteredData[0].valid = true;
        this.participantList.push(filteredData[0]);
        this.userArr.splice(index, 1);
        if (this.deletedInviteesArr.map(elem => elem.emailId).includes(filteredData[0].email)) {
          this.deletedInviteesArr.splice(this.deletedInviteesArr.map(elem => elem.emailId).indexOf(filteredData[0].email), 1)
        }
      } else {
        let index = this.participantList.map((res: any) => {
          return res.email
        }).indexOf(value.trim());
        if (index > -1) {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.PARTICIPANT_ALREADY_EXIST"));
        } else {
          this.participantList.push({
            email: value.trim(),
            isExternal: true,
            valid: this.checkifEmailValid(value.trim()),
            isAlreadySaved: this.deletedExternalArr.map(elem => elem.emailId).includes(value.trim())
          });
          if (this.deletedExternalArr.map(elem => elem.emailId).includes(value.trim())) {
            this.deletedExternalArr.splice(this.deletedExternalArr.map(elem => elem.emailId).indexOf(value.trim()), 1)
          }
        }
      }
      this.autocomplete.closePanel();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.participantCtrl.setValue(null);
  }

  remove(participant: any, index): void {
    this.participantList.splice(index, 1);
    if (!participant.isExternal) {
      this.userArr.push(participant);
      if (participant.isAlreadySaved) {
        this.deletedInviteesArr.push({
          "id": participant.id,
          "emailId": participant.email,
          "isExternal": participant.isExternal,
          "displayName": participant.displayName
        });
      }
    } else {
      if (participant.isAlreadySaved) {
        this.deletedExternalArr.push({
          "id": participant.id,
          "emailId": participant.email,
          "isExternal": participant.isExternal,
          "displayName": participant.displayName
        });
      }
    }
  }

  _filter(name: string) {
    return this.userArr.filter(userData =>
      userData.displayName.toLowerCase().indexOf(name.toLowerCase()) >= 0 || userData.email.toLowerCase().indexOf(name.toLowerCase()) >= 0)
  }
  checkifEmailValid(value) {
    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(mailformat)) {
      return true;
    }
    return false;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.userArr.indexOf(event.option.value);
    event.option.value.valid = true;
    this.participantList.push(event.option.value);
    this.particpantInput.nativeElement.value = '';
    this.participantCtrl.setValue(null);
    this.userArr.splice(index, 1);
    if (this.deletedInviteesArr.map(elem => elem.emailId).includes(event.option.value.email)) {
      this.deletedInviteesArr.splice(this.deletedInviteesArr.map(elem => elem.emailId).indexOf(event.option.value.email), 1)
    }
  }

  openImageFromStudy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    dialogConfig.data = {
      paramValue: 'gallery'
    }
    this.consultationService.setSelectedImageValueOnDialogOpen(this.addedImages)
    this.galleryService.setSelectedAddImage(this.addedImages)
    const dialogref = this.dialog.open(MeetingDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe((res: any) => {
      //this.galleryService.setSelectedAddImage({});
      if (res != 'close') {
        let images = Object.assign({}, this.consultationService.getSelectedImageValue());
        for (const objkey in images) {
          if (!this.addedImages[objkey]) {
            this.addedImages[objkey] = images[objkey];
            this.addedImages[objkey].added = true;
          }
        }
      }
    })
  }

  isChecked() {
    return Object.keys(this.addedImages).length > 0 && this.selectedImgArr.length === Object.keys(this.addedImages).length
  }

  isIndeterminate() {
    return (this.selectedImgArr.length > 0 && !this.isChecked());
  }

  toggleAll(event: MatCheckboxChange) {

    if (event.checked) {

      for (const objkey in this.addedImages) {
        // console.log('checked row', row);
        this.selectedImgArr.push(objkey)
      }

      // console.log('checked here');
    } else {
      // console.log('checked false');
      this.selectedImgArr.length = 0;
    }
  }

  toggle(item, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedImgArr.push(item);
    } else {
      const index = this.selectedImgArr.indexOf(item);
      if (index >= 0) {
        this.selectedImgArr.splice(index, 1);
      }
    }
  }

  exists(item) {
    return this.selectedImgArr.indexOf(item) > -1;
  };

  deleteSelectedImages() {
    this.selectedImgArr.forEach(slideId => {
      if (this.addedImages[slideId]) {
        delete this.addedImages[slideId];
      }
    });
    this.selectedImgArr = [];
  }

  calculateStartEndTime() {
    let start = this.meetingForm.value.meetingStartTime.split(":");
    let end = this.meetingForm.value.meetingEndTime.split(":");
    let mDate = new Date(this.meetingForm.value.meetingDate);
    let timeZoneOffset = this.getTimezoneValue(this.meetingForm.value.meetingTimeZone).offset.trim();
    this.startDateTime = this.getGlobatTime(new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate(), start[0], start[1], 0), timeZoneOffset);
    if ((start[0] > end[0]) || (start[0] == end[0] && start[1] > end[1])) {
      mDate.setDate(mDate.getDate() + 1);
    }
    this.endDateTime = this.getGlobatTime(new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate(), end[0], end[1], 0), timeZoneOffset);
  }

  getTimezoneValue(timezoneString) {
    let timeZoneVal = this.timeZoneArr.filter((tzData) => {
      return tzData.zone_pk == timezoneString
    });
    return timeZoneVal[0];
  }

  getGlobatTime(dateTime, offset) {
    let d = new Date(dateTime);
    let dateTimeString = this.shotMonthArr[d.getMonth()] + " " + ((d.getDate() > 9) ? d.getDate() : "0" + d.getDate()) + " " + d.getFullYear() + " " +
      d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + " " + offset;
    return new Date(dateTimeString).toISOString() //new Date('Feb 03 2021 20:00:00 GMT-0800').
  }

  createMeeting(statusParam) {
    if (this.meetingForm.valid) {
      let interArr = [], externalArr = [];
      if (this.meetingF.meetingStartTime.value == this.meetingF.meetingEndTime.value) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.SAME_START_END_TIME"));
        return;
      }
      this.participantList.forEach(element => {
        if (!element.valid) {
          this.participantsInValid = true;
          return;
        }
        if (element.isExternal) {
          externalArr.push({
            "emailId": element.email,
            "isExternal": element.isExternal
          });
        } else {
          interArr.push({
            "emailId": element.email,
            "isExternal": false,
            "displayName": element.displayName
          })
        }
      });
      if (this.participantsInValid) {
        this.participantsInValid = false;
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.PARTICIPANT_INVALID"));
        return;
      }
      this.showSavingButton = true;
      let dateFormat = new Date(this.meetingForm.value.meetingDate).getFullYear() + "-" +
        ((new Date(this.meetingForm.value.meetingDate).getMonth() < 9 ? "0" : "") + (new Date(this.meetingForm.value.meetingDate).getMonth() + 1)) + "-" +
        new Date(this.meetingForm.value.meetingDate).getDate();
      this.calculateStartEndTime();
      let createData = {
        "title": this.meetingForm.value.meetingTitle,
        "description": this.meetingForm.value.meetingDescription,
        "status": statusParam,
        "creator": JSON.parse(localStorage.getItem('currentUser')).userName,
        "creatorDisplayName": JSON.parse(localStorage.getItem('currentUser')).displayName,
        "timezone": this.meetingForm.value.meetingTimeZone,
        "meetingDate": dateFormat,
        "invitees": interArr,
        "externalUser": externalArr,
        "slideIds": Object.keys(this.addedImages),
        "startTime": this.startDateTime,
        "endTime": this.endDateTime,
        "deleteInvitees": [],
        "deleteExternalUser": [],
        "dateChange": false
      }
      if (this.meetingId) {
        externalArr = []; interArr = [];
        this.participantList.forEach(element => {
          if (element.isExternal && !element.isAlreadySaved) {
            externalArr.push({
              "emailId": element.email,
              "isExternal": element.isExternal
            });
          } else if (!element.isExternal && !element.isAlreadySaved) {
            interArr.push({
              "emailId": element.email,
              "isExternal": false,
              "displayName": element.displayName
            })
          }
        });
        createData.invitees = interArr;
        createData.externalUser = externalArr;
        createData.deleteInvitees = this.deletedInviteesArr;
        createData.deleteExternalUser = this.deletedExternalArr;
        createData.dateChange = this.dateTimeChanged;
        this.meetingService.editMeeting(this.meetingId, createData).subscribe((res: any) => {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.EDIT_SUCCESS"));
          this.commonService.changePlace("consultation");
          this.router.navigate(['/dashboard/consultation']);
          this.showSavingButton = false;
        }, (error: any) => {
          this.showSavingButton = false;
        });
      } else {
        this.meetingService.createMeeting(createData).subscribe((res: any) => {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.CREATION_SUCCESS"));
          this.commonService.changePlace("consultation");
          this.router.navigate(['/dashboard/consultation']);
          this.showSavingButton = false;
        }, (error: any) => {
          this.showSavingButton = false;
        });
      }
    } else {
      Object.keys(this.meetingF).forEach(control => {
        if (this.meetingF[control].invalid) {
          this.meetingF[control].markAsTouched();
        }
      });
    }
  }

  cancelMeeting() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    dialogConfig.data = {
      headerTitle: "Confirm",
      confirmMsg: this.translate.instant("GENERIC_MSG.CONSULTATION.LEAVE_PAGE"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.commonService.changePlace("consultation");
        this.router.navigate(['/dashboard/consultation']);
      }
    });
  }

}
