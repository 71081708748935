import { LoaderComponent } from './common/loader/loader.component';
import { FileReaderService } from './FileReader.service';
import { MeetingDialogComponent } from './consultation/meeting/meeting-dialog/meeting-dialog.component';
import { ConsultationModule } from './consultation/consultation.module';
import { GalleryDialogComponent } from './gallery/gallery-dialog/gallery-dialog.component';
import { UsersModule } from './users/users.module';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { DashboardModule } from "./dashboard/dashboard.module";
import { StudyModule } from "./study/study.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AuthModule } from "./auth/auth.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { LoginService } from "./login/login.service";
import { CommonService } from "./common.service";
import { ImageViewerModule } from "./image-viewer/image-viewer.module";
import { GalleryModule } from "./gallery/gallery.module";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmDialogComponent } from "./common/confirm-dialog/confirm-dialog.component";
import { AppMaterialModule } from './app-material/app-material.module';
import { InputErrorPipe } from './input-error.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WarningDialogComponent } from './common/warning-dialog/warning-dialog.component';
import { SuccessDialogComponent } from './common/success-dialog/success-dialog.component';
import { AppConfigService } from './app-config.service';
import { ChangePasswordModule } from './change-password/change-password.module';
import { FullScreenMessageComponent } from './common/full-screen-message/full-screen-message.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { IconService } from './icons-setting/Icons.service';
// import { NgxDropzoneModule } from 'ngx-dropzone';
import { AuditReviewComponent } from './audit-review/audit-review.component';
import { DigitalSignatureModule } from './digital-signature/digital-signature.module';
import { WebViewerFrameComponent } from './web-viewer-frame/web-viewer-frame.component';
import { CommonModule } from '@angular/common';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

/* export function appInitializerFn(appConfig: AppConfigService) {
  return (): Promise<any> => {
    return appConfig.loadAppConfig();
  }
} */

export const appInitializerFn = (configService: AppConfigService) => {
  return () => configService.loadAppConfig();
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConfirmDialogComponent,
    InputErrorPipe,
    WarningDialogComponent,
    SuccessDialogComponent,
    LoaderComponent,
    FullScreenMessageComponent,
    LoginDialogComponent,
    AuditReviewComponent,
    WebViewerFrameComponent
  ],
  imports: [
    BrowserModule,
    // NgxDropzoneModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthModule,
    StudyModule,
    DashboardModule,
    ImageViewerModule,
    GalleryModule,
    UsersModule,
    MatDialogModule,
    MatButtonToggleModule,
    BrowserAnimationsModule,
    ConsultationModule,
    AppMaterialModule,
    ChangePasswordModule,
    NgxSliderModule,
    CommonModule,
    DigitalSignatureModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppConfigService,
    LoginService,
    CookieService,
    CommonService,
    IconService,
    FileReaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    }
  ],
  bootstrap: [AppComponent],
  exports: [ConfirmDialogComponent, WarningDialogComponent, SuccessDialogComponent, LoaderComponent]
})
export class AppModule { }
