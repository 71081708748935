import { Component, OnInit, Input } from '@angular/core';
import { ConsultationViewerService } from '../consultation-viewer.service';

@Component({
  selector: 'app-right-side-bar',
  templateUrl: './right-side-bar.component.html',
  styleUrls: ['./right-side-bar.component.css']
})
export class RightSideBarComponent implements OnInit {
  @Input() meetingDetails: any;
  isRightBarVisible: boolean = false;

  constructor(private consultationViewerService: ConsultationViewerService) { }

  ngOnInit() {
    this.consultationViewerService.participantChatPanelVisibe.subscribe((value: boolean) => {
      this.isRightBarVisible = value;
      if (localStorage.rightBarPinLocked != 'true') {
        $(".rightBarPinStatus").removeClass("pinLocked").addClass("pinUnlocked");
        $('app-participant-list').removeClass("pinLockedDiv").addClass("pinUnlockedDiv");
        $('app-participant-list .participantMainDiv').removeClass("pinLockedMainDiv").addClass("pinUnlockedMainDiv");
      } else {
        $(".rightBarPinStatus").removeClass("pinUnlocked").addClass("pinLocked");
        $('app-participant-list').removeClass("pinUnlockedDiv").addClass("pinLockedDiv");
        $('app-participant-list .participantMainDiv').removeClass("pinUnlockedMainDiv").addClass("pinLockedMainDiv");
      }

      if ($('.mainRightBar').attr("isInitialized") != "false") {
        $('.mainRightBar').css({ visibility: 'visible' });
      } else {
        $('.mainRightBar').attr("isInitialized", "true");
      }
      var incRight = (!value) ? "-" + $(".mainRightBar").width() : $(".mainRightBar").width();
      $(".mainRightBar").css({
        "visibility": (this.isRightBarVisible ? 'visible' : 'hidden'),
        "height": ($(".rightBarPinStatus").hasClass("pinLocked")) ? "100vh" : "calc(100vh - 3.08vw)",
        "top": ($(".rightBarPinStatus").hasClass("pinLocked")) ? "0" : "3.08vw",
      });

      if (!this.isRightBarVisible) {
        $(".viewercontent.osdViewerMainDiv").width("100%");
      } else {
        if ($(".rightBarPinStatus").hasClass("pinLocked")) {
          $(".viewercontent.osdViewerMainDiv").width("80%");
        }
      }
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 50);

    });
  }
}
