import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { ModalDigitalSignatureComponent } from './modal-digital-signature/modal-digital-signature.component';
import { MatDividerModule } from '@angular/material/divider';
import { DigitalSignatureRoutingModule } from './digital-signature-routing/digital-signature-routing.module';
import { AppMaterialModule } from '../app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';




@NgModule({
  declarations: [
    DigitalSignatureComponent,
    ModalDigitalSignatureComponent
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    DigitalSignatureRoutingModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers:[{provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { disableToggleValue: false,  }}]
})
export class DigitalSignatureModule { }
