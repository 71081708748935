import { AppConfigService } from './../app-config.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageViewerService {

  panelList = {
    Slide_Overview: {
      isVisible: false
    },
    Slide_ROI: {
      isVisible: false
    },
    Slide_Share: {
      isVisible: false
    },
    Compare: {
      isVisible: false
    },
    Slide_Review: {
      isVisible: false
    },
    Screenshot: {
      isVisible: false
    },
    MapImage: {
      isVisible: false
    },
    DiscardImage: {
      isVisible: false
    },
    UnMappImage: {
      isVisible: false
    },
    Filter: {
      isVisible: false
    },
    Image_Filter: {
      isVisible: false
    },
    Annotation_Setting: {
      isVisible: false
    },
    Annotation_Filter: {
      isVisible: false
    },
    Rotation: {
      isVisible: false
    },
    Easy_View: {
      isVisible: false
    },
    Image_Analysis: {
      isVisible: false
    },
    Annotation_Manager: {
      isVisible: false
    },
    Close_Viewer: {
      isVisible: false
    }
  };

  private activeToolS = new BehaviorSubject(this.panelList);
  activeTool = this.activeToolS.asObservable();

  private rotationInitialValue = new BehaviorSubject(0);
  rotationValue = this.rotationInitialValue.asObservable();

  // private viewerTileLevelValue = new BehaviorSubject(0);
  // viewerTileLevel = this.viewerTileLevelValue.asObservable();

  private fineFocusModeValue = new BehaviorSubject(false);
  fineFocusMode = this.fineFocusModeValue.asObservable();

  private fineFocusEnableValue = new BehaviorSubject(true);
  fineFocusEnable = this.fineFocusEnableValue.asObservable();

  private currentRoleSource = new BehaviorSubject('');
  currentRole = this.currentRoleSource.asObservable();

  private annoInfoToCreateSource = new BehaviorSubject({});
  annoInfoToCreate = this.annoInfoToCreateSource.asObservable();

  private annoStopSource = new BehaviorSubject(false);
  stopAnno = this.annoStopSource.asObservable();

  private annoActionsSource = new BehaviorSubject({});
  annoActions = this.annoActionsSource.asObservable();

  private loadAnnotationSource = new BehaviorSubject({});
  loadAnnotation = this.loadAnnotationSource.asObservable();

  private quantificationResultsSrc = new BehaviorSubject({});
  quantificationResults = this.quantificationResultsSrc.asObservable();

  private loadImportedAnnoResultsSrc = new BehaviorSubject([]);
  loadImportedAnnoResults = this.loadImportedAnnoResultsSrc.asObservable();

  private loadImgViewerArr = new BehaviorSubject([]);
  loadImgViewerArrResults = this.loadImgViewerArr.asObservable();

  private disableHotKeysSrc = new BehaviorSubject(false);
  disableHotKeys = this.disableHotKeysSrc.asObservable();

  private drawScreenshotSource = new BehaviorSubject('');
  drawScreenshot = this.drawScreenshotSource.asObservable();

  private config: any;

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  addDCAnnotation(meetId, annotation) {
    return this.http.post(`${this.config.dcUrl}annotation/${meetId}`, annotation);
  }

  editDCAnnotation(annoId, annotation) {
    return this.http.patch(`${this.config.dcUrl}annotation`, annotation);
  }

  deleteDCAnnotation(annotation, id) {
    return this.http.delete(`${this.config.dcUrl}annotation/${annotation.meetId}/${id}`, annotation);
  }

  getAllDCAnnotation(meetId, slideId) {
    return this.http.get(`${this.config.dcUrl}annotation/${meetId}?slideId=` + slideId);
  }

  addAnnotation(annotation) {
    // return this.http.post(`${this.config.apiUrl}slide-image/annotation`, annotation);
    return this.http.post(`${this.config.apiUrl}slide-image/annotationV1`, annotation);
  }

  editAnnotation(annotation) {
    // return this.http.put(`${this.config.apiUrl}slide-image/annotation`, annotation);
    // return this.http.put(`${this.config.apiUrl}slide-image/annotationV1`, annotation);
    return this.http.put(`${this.config.apiUrl}slide-image/annotationV1/` + annotation.id, annotation);
  }

  deleteAnnotation(annotation, id) {
    // return this.http.delete(`${this.config.apiUrl}slide-image/annotation/` + id, annotation);
    return this.http.delete(`${this.config.apiUrl}slide-image/annotationV1/` + id, annotation);
    //return this.http.delete(`${this.config.apiUrl}slide-image/annotation/` + id + '?slideId=' + annotation.slideId, annotation);
  }

  /* getAllAnnotation(slideId, roi) {
    return this.http.get(`${this.config.apiUrl}slide-image/annotation?slideId=` + slideId+`&roi=`+roi);
  } */

  getAllAnnotation(slideId, roi, role, reviewSharable) {
    // return this.http.get(`${this.config.apiUrl}slide-image/annotation?slideId=` + slideId + `&roi=` + roi + `&role=` + role + `&reviewSharable=` + reviewSharable);
    return this.http.get(`${this.config.apiUrl}slide-image/annotationV1?slideId=` + slideId + `&roi=` + roi + `&role=` + role + `&reviewSharable=` + reviewSharable);
  }

  deleteAllAnnotation(slideId, roi) {
    // return this.http.delete(`${this.config.apiUrl}slide-image/annotation?slideId=` + slideId + `&roi=` + roi);
    return this.http.delete(`${this.config.apiUrl}slide-image/annotationV1?slideId=` + slideId + `&roi=` + roi);
  }

  deleteGroupAnnotation(slideId, roi, annotationIds) {
    return this.http.delete(`${this.config.apiUrl}slide-image/annotation/all?slideId=` + slideId + `&roi=` + roi + `&annotationIds=` + annotationIds);
  }

  addOrganReview(slideId, organReviewData) {
    return this.http.post(`${this.config.apiUrl}slide-image/` + slideId + `/organ-review`, organReviewData);
  }

  setActiveTool(tool: any) {
    for (var p in this.panelList) {
      if (p === tool) {
        this.panelList[p].isVisible = !this.panelList[p].isVisible;
      } else {
        this.panelList[p].isVisible = false;
      }
    }
    this.activeToolS.next(this.panelList);
  }

  getSlideDetails(slideId) {
    return this.http.get(`${this.config.apiUrl}gallery/slide-image/` + slideId);
  }

  setRotationValue(value: number) {
    this.rotationInitialValue.next(value);
  }

  // setTileLevel(value: number) {
  // 	this.viewerTileLevelValue.next(value);
  // }

  setFineFocusMode(value: boolean) {
    this.fineFocusModeValue.next(value);
  }

  setFineFocusEnable(value: boolean) {
    this.fineFocusEnableValue.next(value);
  }

  getAnalysisResult(slideId: number) {
    return this.http.get(`${this.config.apiUrl}slide-image/AiravatToAiradhi?slideId=${slideId}`)
  }

  setCurrentRole(value: string) {
    this.currentRoleSource.next(value);
  }

  setAnnoInfoToCreate(value: any) {
    this.annoInfoToCreateSource.next(value);
  }

  setStopAnno(value: boolean) {
    this.annoStopSource.next(value);
  }

  setAnnoActions(value: any) {
    this.annoActionsSource.next(value);
  }

  loadNewAnnotations(value: any) {
    this.loadAnnotationSource.next(value);
  }

  setquantificationResults(value: any) {
    this.quantificationResultsSrc.next(value);
  }

  setImportedAnnoData(value: any) {
    this.loadImportedAnnoResultsSrc.next(value);
  }

  setImgViewerArr(val: any) {
    this.loadImgViewerArr.next(val);
  }

  disableHotKey(value: boolean) {
    this.disableHotKeysSrc.next(value);
  }

  drawScreenShots(value: any) {
    this.drawScreenshotSource.next(value);
  }

  uploadAnnoFile(annoFileObj: any) {
    return this.http.request(new HttpRequest('POST', `${this.config.apiUrl}slide-image/annotationV1/import`, annoFileObj, {
      reportProgress: true,
      responseType: 'json'
    }));
  }

  downloadAnnoFile(slideId: any) {
    return this.http.get(`${this.config.apiUrl}slide-image/annotationV1/export?slideId=${slideId}`, { responseType: 'blob' })
  }

  uploadAnnoFile1(annoFileObj: any) {
    // return this.http.post(`${this.config.apiUrl}slide-image/annotationV1/import`, annoFileObj);
    return this.http.post(`http://localhost:9070/airadhi/slide-image/annotationV1/import`, annoFileObj);
  }
}
