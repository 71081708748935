import { AppConfigService } from './../../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
declare const OpenSeadragon: any;
import * as ScreenShotTool from '../../../assets/js/screenshot-tool/screenshot-tool';
// import { CookieService } from '../../../../node_modules/ngx-cookie-service';
import { CommonService } from '../../common.service';
import * as THREE from 'three';
import * as Stats from 'src/assets/js/stats.min.js';

@Injectable({
    providedIn: 'root'
})
export class WebviewerWrapperService {
    private screenShotboard = new ScreenShotTool(OpenSeadragon);
    private viewerSettings: any;
    private tileSize: number;
    private levelFolderMap = [];
    private arrOfSchemas = [];
    private levelFolderMapRoi = null;
    private slideId = null; // just initializing due to typescript compiling error, not using anywhere.
    private slidename = ''; // just initializing due to typescript compiling error, not using anywhere.
    private identifier = ''; // just initializing due to typescript compiling error, not using anywhere.
    private slideURI = "";
    accessToken: any; // just initializing due to typescript compiling error, not using anywhere.
    /* private threeJsObj = {
        threeOverlay: null,
        camera: null,
        scene: null,
        renderer3: null,
        raycaster: null,
        mouse: null,
        inputHook: null
    } */
    private defaultSettings = {
        elementId: '',
        slideIds: '',
        getOSDCallback: '',
        annotationHandlers: '',
        imageChangeHandler: '',
        OpenSeadragon: ''
    };

    private osdSettings = {
        id: "",
        // navigatorBorderColor: "#272727c7",
        navigatorBorderColor: "transparent",
        navigatorBackground: "#272727c7",
        useCanvas: true,
        navigatorId: "",
        minZoomLevel: 0.5,
        maxZoomLevel: 150,
        showNavigationControl: false,
        showSequenceControl: false,
        tileSources: [],
        initialPage: 1, //this flag is to display first ROI by default.
        tileSourcesReferenceStrip: [],
        roiImageMap: [],
        autoResize: true, // prevent changing of zooming index (increse/decrease) when viewport size changes.
        sequenceMode: true,
        showReferenceStrip: false,
        referenceStripSizeRatio: 0.3,
        showNavigator: true,
        navigatorMaintainSizeRatio: false,
        // navigatorSizeRatio: this.configService.config.navigatorSizeRatio ? this.configService.config.navigatorSizeRatio : 0.25,
        navigatorAutoResize: false,
        preserveImageSizeOnResize: true,
        zoomPerScroll: 1.5,
        loadTilesWithAjax: true,
        gestureSettingsMouse: {
            flickEnabled: true
        },
        // blendTime: 1,
        navigatorAutoFade: false,
        showFlipControl: true,
        maxZoomPixelRatio: 1,
        // minPixelRatio: this.configService.config.tileMinPixelRatio ? this.configService.config.tileMinPixelRatio : 0.5,
        minPixelRatio: 0.1,
        timeout: 50000,
        navigatorTop: "4px",
        navigatorLeft: "4px",
        navMinPixelRatio: 0.2,
        // navigatorPosition: "ABSOLUTE",
        navigatorPosition: "BOTTOM_RIGHT",
        toolbar: "toolBarDiv",
        navigatorHeight: "",
        navigatorWidth: "",
        zoomPerClick: 1, // this disables zoom on mouse click, to enable set it to 2;
        debugMode: this.configService.config.tileDebugModeOn ? this.configService.config.tileDebugModeOn : false,
        imageLoaderLimit: this.configService.config.tileRequestLimit ? this.configService.config.tileRequestLimit : 50,
        immediateRender: false,
        maxImageCacheCount: 1500,
        // placeholderFillStyle: "#ffffff",
        constrainDuringPan: true,
        crossOriginPolicy: 'Anonymous',
        animationTime: this.configService.config.zoomPanAnimationTime ? this.configService.config.zoomPanAnimationTime : 0.5,
        loadThreeJs: true,
        visibilityRatio: 1
        // defaultZoomLevel: 1,
    };

    private defScale: any = 1.0;
    private minNavigatorScale: number = 1;
    private minNavigatorLayer: any;
    private scaleLevelMap: any = {
        0.0009765625: 6,
        0.001953125: 7,
        0.00390625: 8,
        0.0078125: 9,
        0.015625: 10,
        0.03125: 11,
        0.0625: 12,
        0.125: 13,
        0.25: 14,
        0.5: 15,
        1.0: 16
    };
    private config: any;
    private schemaImageProfilePerROI: any = {};
    constructor(
        private httpClient: HttpClient,
        private configService: AppConfigService,
        // private cookieService: CookieService,
        private commonService: CommonService,
    ) {
        this.config = configService.config;
    }

    setViewerSettings(viewerSetting: any) {
        this.viewerSettings = viewerSetting;
        this.createOsdViewer();
    }

    private createOsdViewer() {
        this.viewerSettings = Object.assign(this.defaultSettings, this.viewerSettings);
        this.osdSettings.showNavigator = !this.viewerSettings.isSplitView;
        this.osdSettings.id = this.viewerSettings.elementId;
        let schemaJSON = this.getSchemaJSON(this.viewerSettings);
        schemaJSON.then((successData: any) => {
            this.arrOfSchemas[this.arrOfSchemas.length] = successData.slideImage;
            this.loadDataTilesSchema(this.viewerSettings.getOSDCallback);
        }, (errorData: any) => {
            this.imageNotFound(this.viewerSettings.getOSDCallback)
        });
    }

    private async getSchemaJSON(viewerSetting) {
        return await this.httpClient.get(`${this.configService.config.tileUrl}tiles/schema?query=${viewerSetting.identifier}`).toPromise();
    }

    private imageNotFound(callback) {
        callback("", "", "", "")
    }

    private loadDataTilesSchema(callBack) {
        this.tileSize = parseInt(this.arrOfSchemas[this.arrOfSchemas.length - 1].tileWidth);
        this.setLevelFolderMap();
        this.updateOsdSettings();
        let jsonObj = this.displaySlideImage();
        setTimeout(() => {
            callBack(jsonObj.osd, jsonObj.ssBoard, jsonObj.pixelToNanometer, this.arrOfSchemas[this.arrOfSchemas.length - 1], jsonObj.schemaImageProfilePerROI, jsonObj.threeJsObj);
        }, 100);
    }

    private setLevelFolderMap() {
        this.levelFolderMap = [];
        this.osdSettings.tileSources = [];
        this.osdSettings.tileSourcesReferenceStrip = [];
        this.osdSettings.roiImageMap = [];
        let currentImage = 0; //to add image into sequence mode, and support getTileURLs method to handle multiple images with custom tiles
        let ROIsNavheight = new Array();
        let ROIsNavWidth = new Array();
        let initialNavMinPixRatio = 0.6;
        let navWidLimit = 400;
        let slideDetails = this.arrOfSchemas[this.arrOfSchemas.length - 1],
            numOfROIs = parseInt(slideDetails.numOfROIs),
            levelFolderMapImage = [],
            // tileLevelPerROI = {},
            currentROI = 0,
            imageProfiles = slideDetails.imageProfiles.imageProfile;

        // for (let Index in imageProfiles) {
        for (let imageProfileIndex = 0; imageProfileIndex < imageProfiles.length; imageProfileIndex++) {
            // let imageProfileIndex = Index + 1;
            let tilesrc = {
                height: 512,
                width: 512,
                tileSize: 512,
                getTileUrl: this.getTileUrls,
                getOverlayUrl: this.getOverlayUrl,
                // getTileAjaxHeaders: this.getTileAjaxHeaders,
                roi: currentROI,
                image: currentImage,
                isSlideThumbnail: false,
                pixelToNanometer: imageProfiles[imageProfileIndex].pixelToNanometer,
                maxImgZoom: parseFloat(imageProfiles[imageProfileIndex].resolution),
                levelFolderMapRoi: null,
                slideId: this.viewerSettings.slideIds[0],
                slideURI: this.configService.config.tileUrl,
                identifier: this.viewerSettings.identifier,
                slidename: this.viewerSettings.slidename,
                // accessToken: this.cookieService.get("ACCESS_TOKEN")
            };

            let levelFolderMapRoi = {};
            let levelMargin = this.getScaleLevelMargin(parseInt(imageProfiles[imageProfileIndex].imageBounds.right), parseInt(imageProfiles[imageProfileIndex].imageBounds.bottom));
            let imageLayers = imageProfiles[imageProfileIndex].layers.layer;
            this.schemaImageProfilePerROI[imageProfileIndex] = [];

            for (let lIndex in imageLayers) {
                let layer = imageLayers[lIndex];
                let defaultLevel = 12;
                if (parseFloat(layer.scale) == this.defScale) { //  if(layer.scale == 1)
                    tilesrc.height = parseFloat(layer.height) / this.defScale;
                    tilesrc.width = parseFloat(layer.width) / this.defScale;
                    this.osdSettings.tileSources.push(tilesrc);
                    if (currentROI == 0) {
                        tilesrc.isSlideThumbnail = true;
                        this.osdSettings.tileSourcesReferenceStrip.push(tilesrc)
                    }
                }
                if (this.minNavigatorScale > layer.scale) {
                    this.minNavigatorScale = layer.scale;
                    this.minNavigatorLayer = layer;
                }
                this.schemaImageProfilePerROI[imageProfileIndex].push(layer.scale);
                let osdLevel = this.scaleLevelMap[parseFloat(layer.scale)];
                levelFolderMapRoi[osdLevel + levelMargin + 1] = layer.ifdNum;
            }
            ROIsNavheight.push(this.minNavigatorLayer.height * initialNavMinPixRatio);
            ROIsNavWidth.push(this.minNavigatorLayer.width * initialNavMinPixRatio);
            this.minNavigatorScale = 1.0;
            this.osdSettings.roiImageMap.push(currentImage);
            currentROI++;
            tilesrc.levelFolderMapRoi = levelFolderMapRoi;
            levelFolderMapImage.push(levelFolderMapRoi);
            currentImage++;
        }

        this.levelFolderMap = levelFolderMapImage;
        let maxH = ROIsNavheight[ROIsNavheight.length - 1];
        let maxW = ROIsNavWidth[ROIsNavWidth.length - 1];
        let wLimit = 200;//'300px';

        navWidLimit = Math.max.apply(null, [maxH, maxW, wLimit]);
        let layerROI = imageProfiles[1].layers.layer;

        let ViewerWidth = parseInt("" + $("#" + this.viewerSettings.elementId).width());
        let ViewerHeight = parseInt("" + $("#" + this.viewerSettings.elementId).height());
        let screenWidth = ((ViewerWidth < 1500) ? 1500 : ViewerWidth);
        let maxNavSize = { "w": Math.ceil(0.25 * screenWidth), "h": Math.ceil(0.25 * screenWidth) };
        let minNavSize = { "w": Math.ceil(0.15 * screenWidth), "h": Math.ceil(0.15 * screenWidth) };
        let width = minNavSize.w, height = minNavSize.h;
        height = Math.ceil((layerROI[layerROI.length - 1].height * minNavSize.h) / layerROI[layerROI.length - 1].width);
        let isConditionChecked = false;

        if (width > maxNavSize.w) {
            width = maxNavSize.w;
            height = Math.ceil((layerROI[layerROI.length - 1].height * width) / layerROI[layerROI.length - 1].width);
            isConditionChecked = true;
        } else if (width < minNavSize.w) {
            width = minNavSize.w;
            height = Math.ceil((layerROI[layerROI.length - 1].height * width) / layerROI[layerROI.length - 1].width);
            isConditionChecked = true;
        }

        if (height > maxNavSize.h) {
            height = maxNavSize.h;
            width = Math.ceil((layerROI[layerROI.length - 1].width * maxNavSize.h) / layerROI[layerROI.length - 1].height);
        } else if (height < minNavSize.h) {
            height = minNavSize.h;
            width = Math.ceil((layerROI[layerROI.length - 1].width * minNavSize.h) / layerROI[layerROI.length - 1].height);
        }

        if (height > (ViewerHeight * 0.25)) {
            height = (ViewerHeight * 0.25);
            width = Math.ceil((layerROI[layerROI.length - 1].width * height) / layerROI[layerROI.length - 1].height);
            if (width < minNavSize.w) {
                width = minNavSize.w;
            }
        }
        if (width > (ViewerWidth * 0.25)) {
            width = (ViewerWidth * 0.25);
            height = Math.ceil((layerROI[layerROI.length - 1].height * width) / layerROI[layerROI.length - 1].width);
            if (height < minNavSize.h) {
                height = minNavSize.h;
            }
        }
        this.osdSettings.navigatorHeight = height + "px";
        this.osdSettings.navigatorWidth = width + "px";

        return this.levelFolderMap;
    }

    private updateOsdSettings() {
        let refIndexStart = 0;
        let refIndexEnd = 0;
        let schemaArrayIndex = this.arrOfSchemas.length - 1;
        let schema = this.arrOfSchemas[schemaArrayIndex];

        refIndexStart = refIndexEnd;// schema.slideImage.slideDetails.numOfROIs
        refIndexEnd = parseInt(schema.numOfROIs) + refIndexStart;

        let tileSourceOsd = this.osdSettings.tileSources;

        for (let tileSourceOsdIndex in tileSourceOsd) {
            if (parseInt(tileSourceOsdIndex) >= refIndexStart && parseInt(tileSourceOsdIndex) < refIndexEnd)
                tileSourceOsd[tileSourceOsdIndex].tileSize = parseInt(schema.tileWidth);
        }

        let tileSourceReferenceStripOsd = this.osdSettings.tileSourcesReferenceStrip;
        tileSourceReferenceStripOsd[0].tileSize = parseInt(schema.tileWidth);
    }

    private isWebGLAvailable() {
        try {
            const canvas = document.createElement('canvas');
            return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
        } catch (e) {
            return false;
        }
    }

    private displaySlideImage() {
        let osd = new OpenSeadragon(this.osdSettings);
        let ssBoard = this.screenShotboard(osd);
        let pixelToNanometer = this.osdSettings.tileSources[0].pixelToNanometer;
        let schemaImageProfilePerROI = this.schemaImageProfilePerROI;
        let threeJsObj = null;
        if (this.osdSettings.loadThreeJs && osd) {
            if (this.isWebGLAvailable()) {
                threeJsObj = this.initiateThreeJs(osd);
            }
        }
        return {
            "osd": osd,
            "ssBoard": ssBoard,
            "pixelToNanometer": pixelToNanometer,
            "schemaImageProfilePerROI": schemaImageProfilePerROI,
            "threeJsObj": this.osdSettings.loadThreeJs ? threeJsObj : null
        };
    }

    private initiateThreeJs(osd) {
        if (!window["THREE"]) {
            window["THREE"] = THREE;
        }
        if (!window["Stats"]) {
            window["Stats"] = Stats;
        }

        let osdSeqIndex = osd._sequenceIndex ? osd._sequenceIndex : 1;
        let threeJsObj = {
            threeOverlay: null,
            camera: null,
            scene: null,
            renderer3: null,
            raycaster: null,
            mouse: null,
            inputHook: null,
            textArr: [],
            schemaMaxZoomLevel: this.arrOfSchemas[this.arrOfSchemas.length - 1].imageProfiles.imageProfile[osdSeqIndex].resolution
        }
        threeJsObj.threeOverlay = osd.threejsOverlay();
        threeJsObj.camera = threeJsObj.threeOverlay.camera();
        threeJsObj.scene = threeJsObj.threeOverlay.scene();
        threeJsObj.renderer3 = threeJsObj.threeOverlay.renderer();

        // Performance Monitor
        /* var stats = this.threeOverlay.stats($(".statusPanel"));
        $(stats.dom).find("canvas").css({ "margin-bottom": '0.5vw', 'width': '8vw', 'height': '5vw' }).show().wrap("<div></div>"); */

        // this.threeJsObj.threeOverlay.imagingHelper()._onOpen();
        threeJsObj.raycaster = new THREE.Raycaster();
        threeJsObj.mouse = new THREE.Vector2(-1, -1);
        threeJsObj.threeOverlay.imagingHelper();

        return threeJsObj;
    }

    private getTileUrls(level, x, y) {
        let ifdNum = this.levelFolderMapRoi[level];
        if (ifdNum != undefined) {
            return this.slideURI + "tiles/tile?query=" + this.identifier + ":" + ifdNum + ":" + y + ":" + x;
        }
        return '';
    }

    /* private getTileAjaxHeaders(level, x, y) {
        return {
            'Authorization': 'Bearer '+ this.accessToken
        }
    } */

    private getOverlayUrl(level, x, y) {
        let ifdNum = this.levelFolderMapRoi[level];
        if (ifdNum != undefined) {
            return this.config.tileUrl + "/markTile?query=" + this.viewerSettings.slideIds + ":" + ifdNum + ":" + y + ":" + x;
        }
        return '';
    }

    private getScaleLevelMargin(imgWd, imgHt) {
        let maxLevel = Math.ceil(Math.log(Math.max(imgWd, imgHt)) / Math.log(2));
        return maxLevel - 17;
    }

}
