import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './../../common/confirm-dialog/confirm-dialog.component';
import { AnnotationService } from './../annotation.service';
import { FileReaderService } from './../../FileReader.service';
import { ImageViewerService } from './../image-viewer.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
declare const $: any;

@Component({
  selector: 'app-annotation-manager',
  templateUrl: './annotation-manager.component.html',
  styleUrls: ['./annotation-manager.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AnnotationManagerComponent implements OnInit {
  // @Input() imageViewerObj: any;
  @Input() isFromSlideTrayView: boolean = false;
  /* @ViewChild('annotationHeader', { static: false }) annotationHeaderElem: ElementRef;
  @ViewChild('annoHeaderToggleGroup', { static: false }) annoHeaderToggleGroupelem: ElementRef;
  @ViewChild('headerFilter', { static: false }) headerFilterElem: ElementRef;
  @ViewChild('annotationsGroupTab', { static: false }) annotationsGroupTabElem: ElementRef; */
  annotationDispArr: any = [];
  panelList: any;
  imageViewer: any;
  allAnnotationData: any = [];
  lastAnnotationData: any = {};
  lastAnnotationLength = 0;
  isAnnotationManagerEmpty: boolean = false;
  annotationManagerObj: any = {};
  filteredAnnotationManagerData: any = {};
  selectedRadioValue: any = "type";
  selectedViewAnnoValue: any = "all";
  selectedQuickActionValue: any = "show";
  currentAnnotationManagerStateOpen = false;
  changeArrowClass = false;
  objDiffer: any;
  chnaged: boolean = false;
  osdObj: any;
  showHideValue = 0;
  openCollapseIndex = -1;
  lastAnnoSucscribe: any;
  isAnnoHeaderClicked: boolean = false;
  deleteAllAnnoIds: any;
  viewBy = [
    { val: 'type', displayVal: 'Type' },
    { val: 'user', displayVal: 'User' },
    /* {val:'random', displayVal:'Randomized Images'}, */
  ];
  viewAnnotations = [
    { val: 'all', displayVal: 'All' },
    { val: 'bookmarked', displayVal: 'Bookmarked' },
    /* { val: 'lastAnno', displayVal: 'Last Annotation' } */
  ];
  quickAtions = [
    { val: 'show', displayVal: 'Show' },
    { val: 'hide', displayVal: 'Hide' },
    { val: 'delete', displayVal: 'Delete' }
  ];
  annotationDisplayTypes: any = {
    "RECTANGLE": 'rectAnnotation',
    "CIRCLE": 'circleAnnotation',
    "ELLIPSE": 'ellipseAnnotation',
    "FREEFORMOPEN": 'openFreeFormAnnotation',
    "FREEFORMCLOSE": 'closedFreeFormAnnotation',
    "FILLEDFREEFORM": 'filledFreeFormAnnotation',
    "ANGLE": 'angleAnnotation',
    "ARROW": 'arrowAnnotation',
    "RULER": 'rulerAnnotation',
  };
  selectedAnnoColour: string = "###";

  annoArea = ['AIRADHI', 'DIGITAL COLLABORAION', 'AIRAVAT'];
  selectedAnnoArea: any = [];
  currentUser: any;

  //Annotations in Tray View Starts
  selectedHeaderFilterType: string = 'type';
  isHeaderBookmarkSelected: boolean = false;
  isHeaderAllViewSelected: boolean = true;
  currentSelectedAnnoType: string = 'ALL';
  currentSelectedUser: string;
  isHeaderFilterBookmarkSelected = false;
  isHeaderFilterViewAllSelected = true;
  defaultSelectedUserTabIndex: number = null;
  defaultSelectedColourTabIndex: number = null;
  appliedFilterState: any = { hiddenAnnoIds: [], globalBookMarkApplied: false };
  currentPathRole: any = '';
  imageViewerArr: any = [];
  //Annotations in Tray View Ends

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private imageViewerService: ImageViewerService,
    private fileReaderService: FileReaderService,
    private annotationService: AnnotationService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit() {
    this.imageViewerService.loadImgViewerArrResults.subscribe(resp => {
      this.imageViewerArr = resp;
      this.imageViewer = this.imageViewerArr[0];
    });
    this.selectedAnnoArea.push(this.annoArea[0]);
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentSelectedUser = this.currentUser.userName;

    this.commonService.viewerInfocus.pipe(takeUntil(this.destroyed$)).subscribe(resp => {
      this.showHideSelectedAnnotation(true);
      this.isHeaderFilterBookmarkSelected = false;

      this.imageViewer = this.imageViewerArr[resp];
      this.annotationService.setOnlyAnnotations(this.imageViewer.annotationsArr);
      this.currentSelectedAnnoType = "ALL";
    });

    this.imageViewerService.currentRole.subscribe((role: string) => {
      if (role) {
        this.currentPathRole = role;
      }
    });

    this.annotationService.allAnnotationsArr.pipe(takeUntil(this.destroyed$)).subscribe((allAnnotations: any) => {
      this.allAnnotationData = allAnnotations ? allAnnotations : [];
      this.annotationManagerObj = {};
      if (this.allAnnotationData.length > 0) {
        this.generateAnnotationObj(this.allAnnotationData);
        /* for (let i = 0; i < this.annotationManagerObj[this.selectedRadioValue].length; i++) {
          $('#table_' + i).DataTable({
          });
        } */
        if (this.isAnnotationManagerEmpty) {
          this.getUserOrType(this.selectedHeaderFilterType);
        }
        this.checkForAnnoGroup();
      }
      this.isAnnotationManagerEmpty = (this.allAnnotationData.length == 0);
    });

    /* this.lastAnnoSucscribe = this.annotationService.lastAnnotation.subscribe((value) => {
      this.lastAnnotationData = {};
      this.lastAnnotationLength = 0;
      if (value && (Object.keys(value).length > 0)) {
        this.lastAnnotationData = value;
        if (this.lastAnnotationData) {
          this.lastAnnotationLength = Object.keys(this.lastAnnotationData).length;
          if (this.lastAnnotationLength > 0) {
            this.lastAnnotationData.isVisible = true;
          }
        }
      }
    }); */

    if (this.imageViewer.osdObj) {
      this.osdObj = this.imageViewer.osdObj;
      $(".annotemanger_popup").css("top", $(this.osdObj.element).height() - this.commonService.vwTOpx('2.5vw'));
      $('.annotemanger_popup').css("width", this.commonService.vwTOpx('10vw'));
    }

    $('#annoSearch').on('keyup click', (e) => {
      let searchVal = $(e.currentTarget).val();
      if (searchVal) {
        let filteredArr = this.allAnnotationData.filter(elem => {
          return elem.comment.toLowerCase().includes(searchVal.toLowerCase())
        });
        this.annotationManagerObj = {};
        if (filteredArr.length > 0) {
          this.generateAnnotationObj(filteredArr);
        }
      } else {
        this.annotationService.setOnlyAnnotations(this.allAnnotationData);

      }
    });

    this.imageViewerService.activeTool.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.panelList = value;
      this.toggleAnnotationManager(false);
    });
  }

  ngOnDestroy() {
    if (this.lastAnnoSucscribe) {
      this.lastAnnoSucscribe.unsubscribe();
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  showBookmarkedData(/* e */) {
    let filteredArr = this.allAnnotationData.filter(elem => {
      return elem.bookmarkFlag == true;
    });
    this.annotationManagerObj = {};
    if (filteredArr.length > 0) {
      this.generateAnnotationObj(filteredArr);
    }
  }

  generateAnnotationObj(dataArr: any) {
    dataArr.forEach((element: any) => {
      if (element.info.type == 'FREEFORMCLOSE' && element.info.style == 'Fill') {
        element.info.type = 'FILLEDFREEFORM';
      }

      if (!this.annotationManagerObj["type"]) {
        this.annotationManagerObj["type"] = {};
      }
      if (!this.annotationManagerObj["user"]) {
        this.annotationManagerObj["user"] = {};
      }

      // added by abhishek for filter by colour
      if (!this.annotationManagerObj["colour"]) {
        this.annotationManagerObj["colour"] = {};
      }

      if (!this.annotationManagerObj["type"][element.info.type]) {
        this.annotationManagerObj["type"][element.info.type] = {};
      }
      if (!this.annotationManagerObj["type"]['ALL']) {
        this.annotationManagerObj["type"]['ALL'] = {};
      }
      if (!this.annotationManagerObj["user"][element.info.creator]) {
        this.annotationManagerObj["user"][element.info.creator] = {};
      }

      //Filter by colour - Nitish - Start
      if (!this.annotationManagerObj["colour"]["###"]) {
        this.annotationManagerObj["colour"]["###"] = {};
      }

      if (!this.annotationManagerObj["colour"]['###'][element.id]) {
        this.annotationManagerObj["colour"]['###'][element.id] = element;
      }

      if (!this.annotationManagerObj["colour"][element.info.color]) {
        this.annotationManagerObj["colour"][element.info.color] = {};
      }
      if (!this.annotationManagerObj["colour"][element.info.color][element.id]) {
        element.isVisible = true;
        this.annotationManagerObj["colour"][element.info.color][element.id] = element;
      }
      //Filter by colour - Nitish - End

      if (!this.annotationManagerObj["type"][element.info.type][element.id]) {
        this.annotationManagerObj["type"][element.info.type][element.id] = element;
      }

      if (!this.annotationManagerObj["type"]['ALL'][element.id]) {
        this.annotationManagerObj["type"]['ALL'][element.id] = element;
      }

      if (!this.annotationManagerObj["user"][element.info.creator][element.id]) {
        this.annotationManagerObj["user"][element.info.creator][element.id] = element;
      }

      this.annotationManagerObj["type"][element.info.type][element.id].isVisible = true;
      this.annotationManagerObj["user"][element.info.creator][element.id].isVisible = true;

      if (element.info.type == 'ANGLE') {
        let angle = this.calculateAngle(
          { x: element.points[4], y: element.points[5] },
          { x: element.points[2], y: element.points[3] },
          { x: element.points[0], y: element.points[1] },
        );
        this.annotationManagerObj["type"][element.info.type][element.id].angle = Math.round(angle);
        this.annotationManagerObj["user"][element.info.creator][element.id].angle = Math.round(angle);
      }

      if (element.info.type == 'RULER') {
        let rulerLength = this.getRulerLength(
          { x: element.points[0], y: element.points[1] },
          { x: element.points[2], y: element.points[3] },
        );
        this.annotationManagerObj["type"][element.info.type][element.id].length = rulerLength;
        this.annotationManagerObj["user"][element.info.creator][element.id].length = rulerLength;
      }
    });
  }

  changeViewBy(e) {
    this.selectedRadioValue = e.value;
  }

  toggleAnnotationManager(setAnnoManager: boolean) {
    if (setAnnoManager) {
      this.imageViewerService.setActiveTool("Annotation_Manager");
      return;
    }
    if (this.isAnnoHeaderClicked) {
      return;
    }
    this.isAnnoHeaderClicked = true;

    var animationDuration = 200;
    if (this.panelList.Annotation_Manager.isVisible) {
      $('.annotemanger_popup').animate({ width: this.commonService.vwTOpx('45vw') }, animationDuration, () => {
        $('.annotemanger_popup').animate({ top: ($(this.osdObj.element).height() - ($(".annotemanger_popup").height())) + 'px' }, animationDuration, () => {
          this.changeArrowClass = this.panelList.Annotation_Manager.isVisible;
          this.isAnnoHeaderClicked = false;
        });
      });
    } else {
      $('.annotemanger_popup').animate({ top: ($(this.osdObj.element).height() - this.commonService.vwTOpx('2.5vw')) + 'px' }, animationDuration, () => {
        $('.annotemanger_popup').animate({ width: this.commonService.vwTOpx('10vw') }, animationDuration, () => {
          this.changeArrowClass = this.panelList.Annotation_Manager.isVisible;
          this.isAnnoHeaderClicked = false;
        });
      });
    }

  }

  hideAllAnnotaions() {
    for (let key in this.annotationManagerObj) {
      for (let key1 in this.annotationManagerObj[key]) {
        for (let annoId in this.annotationManagerObj[key][key1]) {
          this.annotationManagerObj[key][key1][annoId].isVisible = false;
        }
      }
    }
    this.allAnnotationData.forEach(annoData => {
      this.imageViewerService.setAnnoActions({ action: "hide", annoObj: annoData })
    });
  }

  showAllAnnotaions() {
    for (let key in this.annotationManagerObj) {
      for (let key1 in this.annotationManagerObj[key]) {
        for (let annoId in this.annotationManagerObj[key][key1]) {
          this.annotationManagerObj[key][key1][annoId].isVisible = true;
        }
      }
    }
    /*let element = document.querySelectorAll('.annotaion.dn');
    for (var i = 0; i < element.length; i++) {
      element[i].classList.remove("dn");
    } */
    this.allAnnotationData.forEach(annoData => {
      this.imageViewerService.setAnnoActions({ action: "show", annoObj: annoData })
    });
  }

  showHideAnnotaion(annoData: any, isVisible: boolean = true) {
    this.annotationManagerObj["type"][annoData.info.type][annoData.id].isVisible = isVisible;
    this.annotationManagerObj["user"][annoData.info.creator][annoData.id].isVisible = isVisible;
    this.imageViewerService.setAnnoActions({ action: (isVisible ? 'show' : 'hide'), annoObj: annoData });
  }

  deleteAllAnnotations(isFieldSDisabled) {
    if (isFieldSDisabled) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Delete Annotations",
      confirmMsg: this.translate.instant("GENERIC_MSG.VIEWER.DELETE_ALL_ANNOTATIONS"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {

        let deleteAllInfo: any = [];
        if ((this.selectedHeaderFilterType == "user") ||
          ((this.selectedHeaderFilterType == 'type') && (this.currentSelectedAnnoType == 'ALL')) ||
          ((this.selectedHeaderFilterType == 'colour') && (this.selectedAnnoColour == "###"))) {
          deleteAllInfo = 'all';
        }

        let allSelectedAnnos = Object.values(this.annotationManagerObj[this.selectedHeaderFilterType][(this.selectedHeaderFilterType == 'type') ? this.currentSelectedAnnoType : ((this.selectedHeaderFilterType == 'user') ? this.currentSelectedUser : this.selectedAnnoColour)]);
        this.deleteAllAnnoIds = allSelectedAnnos.filter((elem: any) => {
          if ((deleteAllInfo != 'all') && (elem.info.creator == this.currentUser.email)) {
            deleteAllInfo.push(elem.id);
          }
          return (elem.info.creator == this.currentUser.email);
        });

        let deleteAnnoService;
        if (deleteAllInfo == 'all') {
          deleteAnnoService = this.imageViewerService.deleteAllAnnotation(this.imageViewer.slideData.slide.slidePk, (this.osdObj._sequenceIndex - 1));
        } else {
          deleteAnnoService = this.imageViewerService.deleteGroupAnnotation(this.imageViewer.slideData.slide.slidePk, (this.osdObj._sequenceIndex - 1), deleteAllInfo);
        }

        deleteAnnoService.pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
          this.selectedQuickActionValue = 'show';
          this.deleteAllAnnoIds.forEach(annoData => {
            if (annoData.meetId) {
              annoData.dcAction = 'delete';
              this.annotationService.setCurrentAnno(annoData);
            }
            this.annotationService.deleteAnnotation(annoData.id);
            this.imageViewerService.setAnnoActions({ action: "delete", annoObj: annoData });
            this.checkForAnnoGroup();
          });
        })
      }
    });
  }

  deleteAnnotaion(annoData, index) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Delete Annotation",
      confirmMsg: this.translate.instant("GENERIC_MSG.VIEWER.DELETE_SINGLE_ANNOTATION"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.openCollapseIndex = index;

        let deleteAnnotationObservable: any;
        if (annoData.meetId) {
          deleteAnnotationObservable = this.imageViewerService.deleteDCAnnotation(annoData, annoData.id);
        } else {
          deleteAnnotationObservable = this.imageViewerService.deleteAnnotation(annoData, annoData.id);
        }
        deleteAnnotationObservable.subscribe((() => {
          if (annoData.meetId) {
            annoData.dcAction = 'delete';
            this.annotationService.setCurrentAnno(annoData);
          }
          this.allAnnotationData = this.allAnnotationData.filter((element) => {
            return element.id != annoData.id;
          });
          this.annotationService.deleteAnnotation(annoData.id);
          this.imageViewerService.setAnnoActions({ action: "delete", annoObj: annoData });
          this.checkForAnnoGroup();
        }).bind(this));

        /* this.imageViewerService.deleteAnnotation(annoData, annoData.id).subscribe((response: any) => {
          this.allAnnotationData = this.allAnnotationData.filter((element) => {
            return element.id != annoData.id;
          });
          this.annotationService.deleteAnnotation(annoData.id);
          this.imageViewerService.setAnnoActions({ action: "delete", annoObj: annoData });
          this.checkForAnnoGroup();
        }); */
      }
    });
  }

  checkForAnnoGroup() {
    if (this.annotationManagerObj[this.selectedHeaderFilterType]) {
      /* if (this.selectedHeaderFilterType == 'user') {
        if (!this.annotationManagerObj[this.selectedHeaderFilterType][this.currentSelectedUser]) {
          this.getUserOrType(this.selectedHeaderFilterType);
        }
      } else if (this.selectedHeaderFilterType == 'type') {
        if (!this.annotationManagerObj[this.selectedHeaderFilterType][this.currentSelectedAnnoType]) {
          this.getUserOrType(this.selectedHeaderFilterType);
        }
      } else {
        if (!this.annotationManagerObj[this.selectedHeaderFilterType][this.selectedAnnoColour]) {
          this.getUserOrType(this.selectedHeaderFilterType);
        }
      } */
      /* if (!this.annotationManagerObj[this.selectedHeaderFilterType][(this.selectedHeaderFilterType == 'user') ? this.currentSelectedUser : (this.selectedHeaderFilterType == 'type' ? this.currentSelectedAnnoType : this.selectedAnnoColour)]) {
        this.getUserOrType(this.selectedHeaderFilterType);
      } */
      this.getUserOrType(this.selectedHeaderFilterType);
    }
  }

  viewAnnotaion(annoData) {
    this.showHideValue = 0;
    this.annotationManagerObj["type"][annoData.type][annoData.id].isVisible = true;
    this.annotationManagerObj["user"][annoData.createdBy][annoData.id].isVisible = true;
    this.imageViewerService.setAnnoActions({ action: "show", annoObj: annoData })
  }

  hideAnnotaion(annoData) {
    this.showHideValue = 0;
    this.annotationManagerObj["type"][annoData.type][annoData.id].isVisible = false;
    this.annotationManagerObj["user"][annoData.createdBy][annoData.id].isVisible = false;
    this.imageViewerService.setAnnoActions({ action: "hide", annoObj: annoData })
  }

  exportExcel() {
    this.fileReaderService.writeFile("csv", this.allAnnotationData, "Annotations");
  }
  calculateAngle(pt3, pt2, pt1) {
    let dx1 = pt1.x - pt2.x;
    let dy1 = pt1.y - pt2.y;
    let dx2 = pt3.x - pt2.x;
    let dy2 = pt3.y - pt2.y;
    let a1 = Math.atan2(dy1, dx1);
    let a2 = Math.atan2(dy2, dx2);
    let angle = Math.atan2(dx1 * dy2 - dy1 * dx2, dx1 * dx2 + dy1 * dy2);
    if (angle < 0) {
      angle = angle * -1;
    }
    return (angle * (180 / Math.PI));
  }

  getRulerLength = function (p1, p2) {
    let xdiff = p2.x - p1.x,
      ydiff = p2.y - p1.y,
      canvasLength = Math.sqrt(xdiff * xdiff + ydiff * ydiff),
      realDist = canvasLength * this.imageViewer.pixelToNanoVal,
      length = ''

    if (realDist < Math.pow(10, 3)) {
      length = realDist.toFixed(2) + " nm";
    } else if (realDist < Math.pow(10, 6)) {
      length = (realDist / 1000).toFixed(2) + " μm";
    } else if (realDist < Math.pow(10, 9)) {
      length = (realDist / Math.pow(10, 6)).toFixed(2) + " mm";
    }

    return length;
  };

  quickActionChange(e) {
    if (e.value == 'show') {
      this.showAllAnnotaions();
    } else if (e.value == 'hide') {
      this.hideAllAnnotaions();
    } else if (e.value == 'delete') {
      this.deleteAllAnnotations(false);
    }
  }

  viewAnnoValueChanged(e) {
    if (e.value == 'all') {
      if (this.allAnnotationData.length > 0) {
        this.generateAnnotationObj(this.allAnnotationData);
      }
    } else if (e.value == 'bookmarked') {
      this.showBookmarkedData();
    }
  }

  filterAccordianOpened() {
    $(".annoationDataDiv.radiodata").height("16vw");
  }

  filterAccordianClosed() {
    $(".annoationDataDiv.radiodata").height("24vw");
  }

  switchView(divElem) {
    divElem.stopPropagation();
    $(divElem.currentTarget).addClass("dn");
    if ($(divElem.currentTarget).hasClass("gridIcon")) {
      $(divElem.currentTarget).closest("div").find(".listIcon").removeClass("dn");
      $(divElem.currentTarget).closest("mat-expansion-panel").find(".listView").addClass("dn");
      $(divElem.currentTarget).closest("mat-expansion-panel").find(".gridView").removeClass("dn");
    } else {
      $(divElem.currentTarget).closest("div").find(".gridIcon").removeClass("dn");
      $(divElem.currentTarget).closest("mat-expansion-panel").find(".gridView").addClass("dn");
      $(divElem.currentTarget).closest("mat-expansion-panel").find(".listView").removeClass("dn");
    }
  }

  getAiradhiAnno() {

  }

  getDCAnno() {

  }

  setPlatform() {
    console.log(this.selectedAnnoArea)
  }

  showSelectedAnnoType(annoType) {
    this.showHideSelectedAnnotation(true);
    this.isHeaderFilterBookmarkSelected = false;
    this.currentSelectedAnnoType = annoType;
    this.annotationDispArr = Object.values(this.annotationManagerObj[this.selectedHeaderFilterType][this.selectedHeaderFilterType == 'type' ? this.currentSelectedAnnoType : (this.selectedHeaderFilterType == 'user' ? this.currentSelectedUser : this.selectedAnnoColour)]);
  }

  getUserOrType(value) {
    this.showHideSelectedAnnotation(true);
    this.isHeaderFilterBookmarkSelected = false;
    this.selectedHeaderFilterType = value;
    this.defaultSelectedUserTabIndex = 0;
    this.defaultSelectedColourTabIndex = 0;
    this.currentSelectedUser = Object.keys(this.annotationManagerObj['user']).sort()[0];
    this.currentSelectedAnnoType = Object.keys(this.annotationManagerObj['type']).sort()[0];
    this.selectedAnnoColour = Object.keys(this.annotationManagerObj['colour']).sort()[0];
    this.annotationDispArr = Object.values(this.annotationManagerObj[this.selectedHeaderFilterType][this.selectedHeaderFilterType == 'type' ? this.currentSelectedAnnoType : (this.selectedHeaderFilterType == 'user' ? this.currentSelectedUser : this.selectedAnnoColour)]);

    // this.getAnnotationsGroupTabWidth();
    /* if(this.selectedHeaderFilterType == "type"){
      this.currentSelectedAnnoType = Object.keys(this.annotationManagerObj[this.selectedHeaderFilterType])[0]
    }else{
      this.currentSelectedUser = Object.keys(this.annotationManagerObj[this.selectedHeaderFilterType])[0]
    } */
  }

  selectedAnnoUser(e) {
    // this.annotationsGroupTabElem.nativeElement.style.width = '100px';
    this.showHideSelectedAnnotation(true);
    this.isHeaderFilterBookmarkSelected = false;
    this.currentSelectedUser = e.tab.textLabel;
    this.annotationDispArr = Object.values(this.annotationManagerObj[this.selectedHeaderFilterType][this.selectedHeaderFilterType == 'type' ? this.currentSelectedAnnoType : (this.selectedHeaderFilterType == 'user' ? this.currentSelectedUser : this.selectedAnnoColour)]);
    /* setTimeout(() => {
      this.getAnnotationsGroupTabWidth();      
    }, 0); */
  }

  getSelectedColourAnno(e) {
    this.showHideSelectedAnnotation(true);
    this.isHeaderFilterBookmarkSelected = false;
    this.selectedAnnoColour = e.tab.textLabel;
    this.annotationDispArr = Object.values(this.annotationManagerObj[this.selectedHeaderFilterType][this.selectedHeaderFilterType == 'type' ? this.currentSelectedAnnoType : (this.selectedHeaderFilterType == 'user' ? this.currentSelectedUser : this.selectedAnnoColour)]);
  }

  showBookmarkedAnno() {
    this.isHeaderFilterBookmarkSelected = !this.isHeaderFilterBookmarkSelected;
  }

  showHideSelectedAnnotation(resetFilters: boolean = false) {
    if (resetFilters && (this.appliedFilterState.hiddenAnnoIds.length == 0) && !this.appliedFilterState.globalBookMarkApplied) {
      return;
    }
    this.isHeaderFilterViewAllSelected = (resetFilters) ? true : !this.isHeaderFilterViewAllSelected;
    let headerFilterType = (this.selectedHeaderFilterType == "type") ? this.currentSelectedAnnoType : ((this.selectedHeaderFilterType == "user") ? this.currentSelectedUser : this.selectedAnnoColour);
    for (let annoId in this.annotationManagerObj[this.selectedHeaderFilterType][headerFilterType]) {
      if (resetFilters) {
        let index = this.appliedFilterState.hiddenAnnoIds.indexOf(annoId)
        if (index >= 0) {
          this.appliedFilterState.hiddenAnnoIds.splice(index, 1);
        } else {
          continue;
        }
      } else {
        this.appliedFilterState.hiddenAnnoIds.push(annoId);
      }
      this.annotationManagerObj[this.selectedHeaderFilterType][headerFilterType][annoId].isVisible = this.isHeaderFilterViewAllSelected;
    }
    this.allAnnotationData.forEach(annoData => {
      if (((this.selectedHeaderFilterType == "type") && ((annoData.info.type == this.currentSelectedAnnoType) || (this.currentSelectedAnnoType == "ALL"))) ||
        ((this.selectedHeaderFilterType == "user") && (annoData.info.creator == this.currentSelectedUser)) ||
        ((this.selectedHeaderFilterType == "colour") && ((annoData.info.color == this.selectedAnnoColour) || (this.selectedAnnoColour == "###")))) {
        this.imageViewerService.setAnnoActions({ action: (this.isHeaderFilterViewAllSelected ? "show" : "hide"), annoObj: annoData })
      }
    });

    /* if (this.selectedHeaderFilterType == "type") {
      for (let annoId in this.annotationManagerObj[this.selectedHeaderFilterType][this.currentSelectedAnnoType]) {
        this.annotationManagerObj[this.selectedHeaderFilterType][this.currentSelectedAnnoType][annoId].isVisible = this.isHeaderFilterViewAllSelected;
      }
      this.allAnnotationData.forEach(annoData => {
        if ((annoData.type == this.currentSelectedAnnoType) || (this.currentSelectedAnnoType == "ALL")) {
          this.imageViewerService.setAnnoActions({ action: (this.isHeaderFilterViewAllSelected ? "show" : "hide"), annoObj: annoData })
        }
      });
    } else {
      for (let annoId in this.annotationManagerObj[this.selectedHeaderFilterType][this.currentSelectedUser]) {
        this.annotationManagerObj[this.selectedHeaderFilterType][this.currentSelectedUser][annoId].isVisible = this.isHeaderFilterViewAllSelected;
      }
      this.allAnnotationData.forEach(annoData => {
        if (annoData.createdBy == this.currentSelectedUser) {
          this.imageViewerService.setAnnoActions({ action: (this.isHeaderFilterViewAllSelected ? "show" : "hide"), annoObj: annoData })
        }
      });
    } */
  }

  /* getAnnotationsGroupTabWidth() {
    setTimeout(() => {
      this.annotationsGroupTabElem.nativeElement.style.width = (this.annotationHeaderElem.nativeElement.offsetWidth - (this.annoHeaderToggleGroupelem.nativeElement.offsetWidth + this.headerFilterElem.nativeElement.offsetWidth + 20)) + "px";
    }, 0);
  } */

  getDisplayUserText(userEmailId) {
    let userName = userEmailId.split("@")[0];
    let userChar = userName.split(".").map(word => { return word[0] }).join("");
    return userChar.toUpperCase();
  }

  uploadAnnotationFile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "20vw";
    dialogConfig.data = {
      type: 'uploadAnnoFile',
      slideId: this.imageViewer.imageDisplayed
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result == 'success' && result != 'btnClose') {
        this.imageViewerService.loadNewAnnotations({
          "slideId": this.imageViewer.imageDisplayed,
          "roiIndex": this.osdObj._sequenceIndex,
          "role": this.currentPathRole,
          "reviewSharable": this.imageViewer.slideData.study.reviewSharable
        });
      }
    });
  }

  downloadAnnotationFile() {
    let slideId = this.imageViewer.imageDisplayed;
    let slideName = this.imageViewer.slideData.slide.slidename;
    slideName = slideName.substr(0, slideName.lastIndexOf("."));
    this.imageViewerService.downloadAnnoFile(slideId).subscribe((data) => {
      var url = window.URL.createObjectURL(data);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.setAttribute('target', 'blank');
      a.href = url;
      a.download = slideName + 'AnnotDef.json';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
}

