export enum Icons {
    allShapeAnnotations = "allAnnotations",
    rectAnnotation = "rectangle",
    circleAnnotation = "circle",
    ellipseAnnotation = "ellipse",
    openFreeFormAnnotation = "openFreeForm",
    closedFreeFormAnnotation = "closedFreeForm",
    filledFreeFormAnnotation = "filledFreeForm",
    angleAnnotation = "angle",
    arrowAnnotation = "arrow",
    rulerAnnotation = "ruler",

    mappedIcon = "sideBar/mapped",
    projectIcon = "sideBar/project",
    reportIcon = "sideBar/report",
    scannedIcon = "sideBar/scanned",
    studyIcon = "sideBar/study",
    uploadedIcon = "sideBar/uploaded",
    usersIcon = "sideBar/users",
    configureIcon = "sideBar/configure",
    consultationIcon = "sideBar/consultation",
    dashboardIcon = "sideBar/dashboard",
    galleryIcon = "sideBar/gallery",
    logoutIcon = "sideBar/logout",
    auditIcon = "sideBar/report",
    dsIcon = "ds_icon",
    slideTrayBookmark = "slideTray/bookmark",
    digitalsignatureIcon = "sideBar/digitalSignature",
}