<div class="modal-header dialog-header">
  <div class="headerTitle">
    <div class="dialog-title">Auto-Create Study</div>
  </div>
  <div class="headerIcon float-right">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>
<div class="dialog-container modal-body">
  <div class="autoCreateDiv" *ngIf="data.showAutoCreate">
    <div class="clearfix clearfix_div">
      <div class="float-left" style="width:inherit;">
        <div class="form-group">
          <label>Select Structure<span class="mandatory">*</span></label>
          <!-- <select class="form-control inputHeight" (change)="templateSelectionChange($event)">
            <option *ngFor="let template of templateArr" [value]="template.id">{{template.name}}</option>
          </select> -->
          <mat-select class="form-control inputHeight" (selectionChange)="templateSelectionChange($event)">
            <mat-option *ngFor="let template of templateArr" [value]="template.id">
              {{ template.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="float-right createButtondiv">
        <button type="button" class="btn-default searchbtn" (click)="data.showAutoCreate = false"
          title="Create Study Mapping Structure"><i class="fa fa-plus"></i></button>
      </div>
    </div>
    <div class="form-group">
      <label>Select File<span class="mandatory">*</span></label>
      <div class="custom-file">
        <input type="file" class="custom-file-input" id="customFile" (change)="uploadStudyDataFile($event)"
          accept=".xlsx, .xls, .csv">
        <label class="custom-file-label inputHeight fileInputLabel" for="customFile">{{studylFileName}}</label>
      </div>
    </div>
    <div class="form-group">
      <label for="structureName">Please specify the row no. from where the content starts: </label>
      <input type="number" class="form-control inputHeight fileInputLabel" (change)="inputValueChanged($event)" [(ngModel)]='rowNo' min="2">
    </div>

    <div class="infoDiv" *ngIf="isStudyTypeTCR">
      <div class="orangeText float-left">NOTE:&nbsp;</div>
      <div>Tissue is mandatory to create TCR Study</div>
    </div>
  </div>
  <div class="createMappingStructureDiv" *ngIf="!data.showAutoCreate">
    <app-study-mapping-structure></app-study-mapping-structure>
  </div>
</div>
<div class="actions dialog-actions modal-footer" *ngIf="data.showAutoCreate && !isUploadClicked">
  <button class="btn-default" (click)="closeDialog()">Close</button>
  <button class="btn-default searchbtn" (click)="uploadStudyData()">Upload</button>
</div>
<div *ngIf="isUploadClicked" class="actions dialog-actions modal-footer">
  <button class="btn-default searchbtn" type="button" disabled>
    <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw; width: 1vw; height: 1vw;" role="status"
      aria-hidden="true"></span>
    <span>Uploading...</span>
  </button>
</div>
