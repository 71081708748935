<div class="view_wrap">
  <div class="top_view_bar">
    <div class="disableTopBar"></div>
    <div class="row leftside" style="margin: 0;">
      <div class="col-sm-5" style="padding: 0%;">
        <ul class="icon_inline lefttopbar">
          <li class="topbarlogo nohoverli">
            <img src="../../../assets/images/viewer_icon/logo_512.png" />
          </li>
          <li class="nohoverli">
            <p class="viewer_meetingnm">
              {{meetingDetails.title}}</p>
          </li>
          <li title="Meeting Details">
            <button type="button" class="btn infoicon" aria-label="Meetingdetail"
              (click)="openMeetingDetailDialog()"><img
                src="../../../assets/images/viewer_icon/Study Details Topbar-Primary.svg" alt="Meeting Details"
                aria-label="Meeting Details" /></button>
          </li>
          <!-- <li class="nohoverli">
            <p class="tileLevel">{{currentTileLevel}}</p>
          </li>
          <li>
            <p>
              {{currentUser.displayName}}
            </p>
          </li> -->
          <li title="Image Synchronization - On" *ngIf="isViewerSynch" style="cursor: default;" class="nohoverli">
            <button type="button" class="btn infoicon" aria-label="Image Synchronization - On" style="cursor: default;">
              <img style="padding: 0.65vw; cursor: default;" src="../../../assets/images/viewer_icon/refresh_active.svg"
                alt="Image Synchronization - On" aria-label="Image Synchronization - On" /></button>
          </li>
        </ul>
      </div>
      <div class="col-sm-2 middlecenter">
        <div class="row middletraverse">
          <ul>
            <li *ngIf="!isSplitView && isPresenter" class="navArrow" (click)="previousSlide()"
              [ngClass]="{'next-prev-dis' : !prevSlideId}">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </li>
            <li *ngIf="isPresenter" [ngClass]="{'img-viewer-icon-selected': panelList.Select_Image.isVisible}">
              <div class="tabordion">
                <section id="section1">
                  <input type="radio" name="sections" id="option1">
                  <label for="option1" (click)="togglePanel('Select_Image')" title="Images" class="middlecenter">
                    <i class="fa fa-picture-o imageicon"></i>
                  </label>
                  <div class="viewerTopContentDiv" [hidden]="!panelList.Select_Image.isVisible">
                    <div class="labeldiv">
                      <div class="row divpop_header">
                        <div class="col-sm-10">
                          <p style="text-align: left;">Images</p>
                        </div>
                        <div class="col-sm-2 closing">
                          <i class="fa fa-times rightfloat" (click)="togglePanel('Select_Image')"></i>
                        </div>
                        <!-- <div class="float-left headerText">Images</div>
                        <div class="float-right">
                          <i class="fa fa-times" (click)="togglePanel('Select_Image')"></i>
                        </div> -->
                      </div>
                      <!--Slide Details-->
                      <div class="topslidedetail">
                        <div class="checkbox_delete">
                          <div class="float-right">
                            <!-- <mat-form-field style="margin-right: 1vw;" class="float-left">
                              <input matInput placeholder="Search" id="filterSearch">
                            </mat-form-field> -->
                            <input type="text" placeholder="Search" class="imagesearchfilter"
                              (input)="onSearch($event.target.value)">
                            <button class="btn-default searchbtn" (click)="compareImgages()"
                              [ngClass]="{ 'disablebtn_func' : selectedImgArr.length > 4 || selectedImgArr.length < 2 || currentSlides.length == 4 }">Compare</button>
                          </div>
                        </div>
                        <div class="imagesets">
                          <!-- new code start -->
                          <mat-accordion>
                            <mat-expansion-panel
                              *ngFor="let userImageData of meetingImages | keyvalue: returnZero;let i = index"
                              [expanded]="i==openCollapseIndex">
                              <mat-expansion-panel-header>
                                <mat-panel-title class="panel-title">
                                  <div class="col-sm-10">
                                    <span class="namealign">{{participantObj[userImageData.key].displayName}}</span>
                                  </div>
                                  <div class="col-sm-2 float-right imageIconDiv">
                                    <i class="fa fa-plus" *ngIf="userImageData.key == this.currentUser.userName"
                                      (click)="openImageFromStudy()"></i>

                                  </div>
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div *ngIf="(userImageData.value | json) != '{}'">
                                <div class="row selectallcheck">
                                  <div class="col-sm-10">
                                    <mat-checkbox
                                      *ngIf="userImageData.key == this.currentUser.userName && (userImageData.value | json) != '{}'"
                                      (click)="$event.stopPropagation()" aria-label="Select All" [checked]="isChecked()"
                                      [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
                                      Select all
                                    </mat-checkbox>
                                  </div>
                                  <div class="col-sm-2 float-right imageIconDiv">
                                    <i class="fa fa-trash" *ngIf="userImageData.key == this.currentUser.userName"
                                      (click)="deleteSelectedImages()"></i>
                                  </div>
                                </div>

                                <div class="fLeftCard" style="display: inherit;"
                                  *ngFor="let image of userImageData.value | keyvalue">
                                  <div class="card-group"
                                    [ngClass]="{ 'card-groupactive' : currentSlides.includes(image.key)}">
                                    <div class="card imgcard"
                                      [ngClass]="{ 'imgcardactive' : currentSlides.includes(image.key)}">
                                      <div class="griddetcard card-body">
                                        <div class="griddet">
                                          <mat-checkbox [disabled]="currentSlides.includes(image.key)"
                                            (click)="$event.stopPropagation()"
                                            (change)="$event ? toggle(image.key, $event) : null"
                                            [checked]="currentSlides.includes(image.key) || exists(image.key)">
                                          </mat-checkbox>
                                        </div>
                                        <div [ngClass]="{ 'disableFunction' : currentSlides.includes(image.key)}">
                                          <img [defaultImage]="defaultImagePath"
                                            [lazyLoad]="thumbnailUrl+image.value.identifier+'_thumbnail.jpeg'"
                                            class="img-responsive animal_img cssTooltip" alt="tissueimg"
                                            (click)="openImage(image.key)">
                                        </div>
                                        <div title="{{image.value.slidename}}" class="truncate_slide_name grayout">
                                          {{image.value.slidename}}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="(userImageData.value | json) == '{}'">
                                <div>No images</div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                          <!-- new code end -->

                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </li>
            <li *ngIf="!isSplitView && isPresenter" class="navArrow" (click)="nextSlide()"
              [ngClass]="{'next-prev-dis' : !nextSlideId}">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-5 rightside">
        <ul class="icon_inline rightfloat">
          <li *ngIf="!audioStatus">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Unmute" (click)="muteUnmute(true)">
                  <div class="UnMuteIcon" title="Unmute">
                    <img src="../../../assets/images/viewer_icon/Microphone_mute_Top_Bar.svg" alt="Unmute"
                      aria-label="Unmute" />
                  </div>
                </label>
              </section>
            </div>
          </li>
          <li *ngIf="audioStatus">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Mute" (click)="muteUnmute(false)">
                  <div class="muteIcon" title="Mute">
                    <img src="../../../assets/images/viewer_icon/Microphone_unmute_Top_Bar.svg" alt="Mute"
                      aria-label="Mute" />
                  </div>
                </label>
              </section>
            </div>
          </li>
          <li *ngIf="!isHost && !isPresenter && !isAccessRequested && (presenterOnline || hostOnline)">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Request Control" (click)="requestAccess()">
                  <div class="request_icon" title="Request Control"></div>
                </label>
              </section>
            </div>
          </li>
          <li class="nohoverli" *ngIf="!isHost && isPresenter" title="End Control">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="End Control" (click)="endAccess()">
                  <div class="stop_icon"></div>
                  <!-- <img src="../../../assets/images/viewer_icon/StopControl-Icon.svg" alt="End Control"
                        aria-label="Request Control" *ngIf="!isHost && isPresenter" title="End Control" /> -->
                </label>
              </section>
            </div>
          </li>
          <li *ngIf="!isSplitView && enableFineTune">
            <p class="fineTune" *ngIf="!isTopLevelTile && fineTuneEnable">
              <img id="imgFineTuneOn" (click)="setFineTune(false)"
                src="../../../assets/images/viewer_icon/FineTuneOn.png" title="Fine Tuning On" alt="Fine Tuning On"
                aria-label="Fine Tuning On" style="display: none;" />
              <img id="imgFineTuneOff" (click)="setFineTune(true)"
                src="../../../assets/images/viewer_icon/FineTuneOff.png" title="Fine Tuning Off" alt="Fine Tuning Off"
                aria-label="Fine Tuning Off" />
            </p>
            <p class="fineTune" *ngIf="isTopLevelTile || !fineTuneEnable">
              <img id="imgFineTuneDisabled" src="../../../assets/images/viewer_icon/FineTuneOff.png"
                title="Fine Tuning Disabled" alt="Fine Tuning Disabled" style="opacity: 0.5;"
                aria-label="Fine Tuning Disabled" />
            </p>
          </li>
          <li class="leaveM nohoverli" title="End Meet" *ngIf="isHost">
            <div class="tabordion_ryt">
              <section>
                <label title="End Meet" (click)="endMeeting()">
                  <button type="button" class="btn-default">End Meet</button>
                </label>
              </section>
            </div>
          </li>
          <li class="leaveM nohoverli" title="Leave Meeting">
            <div class="tabordion_ryt">
              <section>
                <label title="Leave Meeting" (click)="leaveMeeting()">
                  <button type="button" class="btn-default searchbtn">
                    <!-- <i class="fa fa-phone rotate"></i> -->
                    Leave</button>
                </label>
              </section>
            </div>
          </li>
          <li title="Meeting Setting">
            <button type="button" class="btn infoicon" aria-label="Meetingsetting"
              (click)="openMeetingSettingsDialog()"><img class="settingicon"
                src="../../../assets/images/viewer_icon/settings.svg" alt="Meeting Settings"
                aria-label="Meeting Settings" /></button>
          </li>
          <li class="nohoverli">
            <!--  <p class="networkStatus"> -->
            <img id="imgInternetStatus" src="../../../assets/images/viewer_icon/no-connection.png"
              style="width: 1.5vw;margin: 0.25vw;padding: 0.3vw .1vw .1vw .1vw;vertical-align: text-top;margin-right: -.3vw;"
              title="Server Status" alt="Network Status" aria-label="Network Status" />
            <!-- </p> -->
            <span class="downdropdown" style="visibility: hidden;"></span>
          </li>
          <li *ngIf="!isImageNotAvailable" [ngClass]="{'img-viewer-icon-selected': panelList?.Screenshot.isVisible}">
            <div class="tabordion_ryt">
              <section id="section3">
                <input type="radio" name="sections" id="option5" checked>
                <label for="option5" (click)="togglePanel('Screenshot')" title="Screenshot">
                  <img src="../../../assets/images/viewer_icon/Screenshot-Primary.svg" alt="Screenshot"
                    aria-label="Screenshot" />

                </label>
                <article class="screenshotArticle" *ngIf="panelList?.Screenshot.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Screenshot Type</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('Screenshot')"></i>
                    </div>
                  </div>
                  <div class="divpop_body">
                    <button class="dropdown-item" type="button" (click)="drawScreenshots('FullScreenShot')">
                      <img src="../../../assets/images/viewer_icon/FullScreen Shot-Primary.svg"
                        aria-label="Rectangle Screenshot" />
                      &nbsp;&nbsp;&nbsp;Fullscreen
                    </button>
                    <button class="dropdown-item" type="button" (click)="drawScreenshots('Rectangular')">
                      <img src="../../../assets/images/viewer_icon/Rectangular Screenshot-Primary.svg"
                        aria-label="Full Screenshot">
                      &nbsp;&nbsp;&nbsp;Rectangle
                    </button>
                    <button class="dropdown-item" type="button" (click)="drawScreenshots('FilledFreeform')">
                      <img src="../../../assets/images/viewer_icon/Free Form Screenshot-Primary.svg"
                        aria-label="Freeform Screenshot" />
                      &nbsp;&nbsp;&nbsp;Freeform
                    </button>
                  </div>
                </article>
              </section>
            </div>
          </li>
          <li (click)="onfullScreen()">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Fullscreen">
                  <img src="../../../assets/images/viewer_icon/Expand-Primary.svg" alt="FullScreen"
                    aria-label="Full Screen" *ngIf="!isFullScreen" title="Fullscreen" />
                  <img src="../../../assets/images/viewer_icon/Restore-Primary.svg" alt="Close FullScreen"
                    aria-label="Close Fullscreen" *ngIf="isFullScreen" title="Close Fullscreen" />
                </label>
              </section>
            </div>
          </li>
          <li (click)="showHideChatParticipant()" [ngClass]="{'blinkParticipantChatIcon': accessRequestCount > 0}">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Participant List & Chat">
                  <img src="../../../assets/images/viewer_icon/ParticipantChatIcon.svg" alt="Participant List & Chat"
                    aria-label="Participant List & Chat" title="Participant List & Chat" style="width: 1.3vw;
                      padding-top: .5vw;" />
                </label>
              </section>
            </div>
          </li>
          <!-- <li (click)="onCloseViewer('SELECTED')" title="Close" *ngIf="imageViewerObj.length == 1 && isPresenter">
            <div class="tabordion_ryt">
              <section>
                <label for="option51" title="Close">
                  <img src="../../../assets/images/viewer_icon/Close button-Primary.svg" alt="Close Viewer"
                    aria-label="Close Viewer" />
                </label>
              </section>
            </div>
          </li> -->
          <li [ngClass]="{'img-viewer-icon-selected': panelList?.Close_Viewer.isVisible}"
            *ngIf="imageViewerObj.length > 1 && isPresenter">
            <div class="tabordion_ryt">
              <section id="section3">
                <input type="radio" name="sections" id="option51" checked>
                <label for="option51" (click)="togglePanel('Close_Viewer')" title="Close">
                  <!-- <img *ngIf="!isSplitView" src="../../../assets/images/viewer_icon/Close button-Primary.svg" alt="Close Viewer" aria-label="Close Viewer" /> -->
                  <img src="../../../assets/images/viewer_icon/removeSplitViewer.png" alt="Close Compare"
                    aria-label="Close Compare" />

                </label>
                <article class="closeViewerArticle" *ngIf="panelList?.Close_Viewer.isVisible">
                  <div class="row divpop_header">
                    <div class="col-sm-10">
                      <p>Close Viewers</p>
                    </div>
                    <div class="col-sm-2 closing">
                      <i class="fa fa-times rightfloat" (click)="togglePanel('Close_Viewer')"></i>
                    </div>
                  </div>
                  <div class="divpop_body">
                    <div class="gridParentDiv clearfix">
                      <div class="viewerMiniDiv"
                        [ngClass]="{'twoDivsViewer':imageViewerObj.length == 2,'threeDivsViewer':imageViewerObj.length == 3, 'fourDivsViewer': imageViewerObj.length == 4}"
                        *ngFor="let imgViewer of imageViewerObj; let i = index"
                        (click)="closeCheckboxChange($event, imgViewer.imageDisplayed, i)">
                        <div class="viewerMiniInnerDiv">{{i+1}}</div>
                      </div>
                    </div>
                    <div class="buttonDiv clearfix">
                      <!-- <div *ngIf="!closeSlideArr?.length" class="boldText closeViewerAction" (click)="onCloseViewer('ALL')">Remove All</div> -->
                      <div *ngIf="closeSlideArr?.length" class="boldText closeViewerAction"
                        (click)="onCloseViewer('SELECTED')">Remove</div>
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="topBarArrow">
      <i _ngcontent-qoh-c5="" (click)="toggleTopBar()" class="fa fa-chevron-down rotateArrow"
        [ngClass]="currentTopBarArrow=='open' ? 'openArrow' : 'closeArrow'" aria-hidden="true"
        ng-reflect-klass="fa fa-chevron-right rotateArro" ng-reflect-ng-class="openArrow"></i>
    </div>
  </div>
</div>