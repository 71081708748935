// import { Dosages } from './dosages.model';
// import { Cassettes } from './cassettes.model';
import { prop, propArray, propObject, required, minLength, minDate, maxLength } from '@rxweb/reactive-form-validators';

export class Study {
    private _studyNo:String;
    private _studyTitle: String;
    private _projectNo: String;
    private _accessionNo: String;
    private _pathologist : Array<String> = [];
    private _technician : String;
    private _peerReviewer : String;
    private _primaryInvestigator : String;
    private _studyDomain: String;
    private _treatment: String;
    private _studyType : String;
    private _fromDate: Date;
    private _toDate: Date;
    private _sacrifice: String;
    private _species: String;
    private _strain: String;
    private _route: String;
    private _cro:String;
    private _testItem: String;
    private _additionalPathologist : Array<String> = [];
    private _reviewSharable: boolean;
    // private _configuration: String;

    /* @minLength({value:1})
    @propArray(Cassettes)
    cassettes : Array<Cassettes>;

    @propArray(Dosages)
    dosages : Array<Dosages>; */
    
    @required()
    @maxLength({value:15})
    @prop()
    set studyNo (value:String){
        this._studyNo = value;
    }
    get studyNo () {
        return this._studyNo;
    }

    // @required()
    @prop()
    set studyTitle (value:String){
        this._studyTitle = value;
    }
    get studyTitle () {
     return this._studyTitle;
    }

    @prop()
    set projectNo(value:String){
        this._projectNo = value;
    }
    get projectNo() {
        return this._projectNo;
    }

    @prop()
    set accessionNo (value:String){
        this._accessionNo = value;
    }
    get accessionNo() {
        return this._accessionNo;
    }

    @required()
    @minLength({value:1})
    @prop()
    set pathologist(value:String[]){
        this._pathologist = value;
    }
    get pathologist () {
        return this._pathologist;
    }

    @required({message: 'true'})
    @prop()
    set technician(value:String){
        this._technician = value;
    }
    get technician() {
        return this._technician;
    }

    @prop()
    set peerReviewer(value:String){
        this._peerReviewer = value;
    }
    get peerReviewer() {
        return this._peerReviewer;
    }

    @prop()
    set primaryInvestigator (value:String){
        this._primaryInvestigator = value;
    }
    get primaryInvestigator () {
        return this._primaryInvestigator;
    }

    @prop()
    set studyDomain(value:String){
        this._studyDomain = value;
    }
    get studyDomain() {
        return this._studyDomain;
    }

    @prop()
    set treatment (value:String){
        this._treatment = value;
    }
    get treatment() {
        return this._treatment;
    }

    @prop()
    set studyType(value:String){
        this._studyType = value;
    }
    get studyType() {
        return this._studyType;
    }

    @prop()
    set fromDate(value:Date){
        this._fromDate = value;
    }
    get fromDate () {
        return this._fromDate;
    }

    @prop()
    set toDate(value: Date){
        this._toDate = value;
    }
    get toDate () {
        return this._toDate;
    }

    @prop()
    set sacrifice(value:String){
        this._sacrifice = value;
    }
    get sacrifice() {
        return this._sacrifice;
    }

    @required()
    @prop()
    set species(value:String){
        this._species = value;
    }
    get species() {
        return this._species;
    }

    // @required()
    @prop()
    set strain(value:String){
        this._strain = value;
    }
    get strain() {
        return this._strain;
    }
    
    @prop()
    set route (value:String){
        this._route = value;
    }
    get route() {
        return this._route;
    }

    @prop()
    set cro(value: String) {
        this._cro = value;
    }
    get cro() {
        return this._cro;
    }

    @prop() 
    set testItem(value: String) {
        this._testItem = value;
    }
    get testItem() {
        return this._testItem;
    }

    @prop()
    set additionalPathologist (value:any){
        this._additionalPathologist = value;
    }
    get additionalPathologist () {
        return this._additionalPathologist;
    }

    @prop()
    set reviewSharable (value:boolean){
        this._reviewSharable = value;
    }
    get reviewSharable () {
        return this._reviewSharable;
    }

/*     set configuration(value:String) {
        this._configuration = value;
    }

    get configuration() {
        return this._configuration;
    } */
}