<div class="viewercontent" #viewerContent>
    <div class="statusPanel"></div>
    <div id="toolBarDiv">
        <app-image-viewer-top-bar *ngIf="isViewerInitialized || isImageNotAvailable" [openedFrom]="openedFrom"
            [noImageFlag]="isImageNotAvailable" [viewerScreens]="viewerScreens">
        </app-image-viewer-top-bar>
        <app-image-viewer-side-bar
            *ngIf="isViewerInitialized && openedFrom != 'scanned' && openedFrom != 'uploaded' && openedFrom != 'mapped'"></app-image-viewer-side-bar>
        <!-- <ng-container
            *ngIf="isViewerInitialized && !isSplitViewEnabled && openedFrom != 'scanned' && openedFrom != 'uploaded' && openedFrom != 'mapped'">
            <app-annotation-manager [imageViewerObj]="imageViewerArr" [isFromSlideTrayView]="false"></app-annotation-manager>
        </ng-container> -->

        <div [hidden]="!isViewerInitialized" class="joystickRotationNavigator"
            [@slideJoystickRotationNavigator]=currentJoystickRotationNavigatorState>
            <div class="disabledJoystickrotationNavigator"></div>
            <div class="joystickRotationNavigatorToggle">
                <div (click)="toggleJoystickRotationNavigator()"><i class="fa fa-chevron-right rotateArrow"
                        [ngClass]="currentArrowState=='open' ? 'openArrow' : 'closeArrow'" aria-hidden="true"></i></div>
            </div>
            <div class="row">
                <div class="joyStickWrapper col-6">
                    <div id="zone_joystick"></div>
                </div>
                <div class="viewerRotationer col-6">
                    <!-- <div id="roundSlider"></div> -->
                </div>
            </div>
            <!-- <div class="row zoomingIndex">
                <div class="col-2 fitToScreen" (click)="fitToScreen($event)"><b>F</b></div>
                <div class="col-2 2x" (click)="joystickNavZoomToIndex($event, 2)">2x</div>
                <div class="col-2 5x" (click)="joystickNavZoomToIndex($event, 5)">5x</div>
                <div class="col-2 10x" (click)="joystickNavZoomToIndex($event, 10)">10x</div>
                <div class="col-2 20x" (click)="joystickNavZoomToIndex($event, 20)">20x</div>
                <div class="col-2 40x" (click)="joystickNavZoomToIndex($event, 40)">40x</div>
            </div> -->
            <div class="zoomingIndexDiv">
                <div class="zoomingIndexItem fitToScreen" (click)="fitToScreen($event)"><b>F</b></div>
                <div *ngFor="let zoom of finalZoomIndexArr" class="zoomingIndexItem {{zoom}}x"
                    (click)="joystickNavZoomToIndex($event, zoom)">{{zoom}}x</div>
            </div>
            <div class="sliderArea">
                <div class="rowLabel float-left">Zoom: </div>
                <div class="float-right">
                    <div class="row">
                        <div class="sliderText" (click)="joystickNavZoomToIndex($event, 'decrease')"><i
                                class="fa fa-minus" aria-hidden="true"></i></div>
                        <div class="range-slider">
                            <div><input class="input-range" type="range" min="1" max="{{maxZoomLevel}}"
                                    [(ngModel)]="sliderRangeValue" (input)="joystickNavZoomToIndex($event, 'slider')">
                            </div>
                            <div class="sliderValue">{{sliderRangeValueText(sliderRangeValue)}}x</div>
                        </div>
                        <div class="sliderText" (click)="joystickNavZoomToIndex($event, 'increase')"><i
                                class="fa fa-plus" aria-hidden="true"></i></div>
                    </div>
                </div>
            </div>
            <div class="sliderArea dn">
                <div class="rowLabel float-left">Clarity: </div>
                <div class="float-right">
                    <div class="row">
                        <div class="sliderText" (click)="changeMinPixelRatio($event, 'decrease')"><i class="fa fa-minus"
                                aria-hidden="true"></i></div>
                        <div class="range-slider">
                            <div><input class="input-range" type="range" min="1" max="5"
                                    [(ngModel)]="sliderMinPixelValue" (change)="changeMinPixelRatio($event, 'slider')">
                            </div>
                            <div class="sliderValue">{{sliderMinPixelValue}}</div>
                        </div>
                        <div class="sliderText" (click)="changeMinPixelRatio($event, 'increase')"><i class="fa fa-plus"
                                aria-hidden="true"></i></div>
                    </div>
                </div>
            </div>
            <div class="sliderArea dn">
                <div class="rowLabel float-left">Clarity: </div>
                <div class="float-left clarityToggle">
                    <mat-slide-toggle (change)="changeMinPixelRatio($event, 'toggle')"></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>
    <div style="position: absolute; z-index: 99999999999; display: none;">
        <button mat-raised-button color="warn" (click)="NavigatorRedraw()">Basic</button>
    </div>

    <div class="modalDCLoader">
        <div class="modalDCLoaderInner">
            <div id="DCLoaderDialog">
                <div style="margin: 2vw; width: 6vw; height: 6vw;">
                    <mat-spinner class="modalLoader"></mat-spinner>
                </div>
                <div class="DCLoaderMsg">Loading Image... <br> Please Wait...</div>
            </div>
        </div>
    </div>

    <app-anno-comment-popup [osdObj]="osdObj" [threeJsObj]="threeJsObj"></app-anno-comment-popup>
    <app-image-slide-tray *ngIf="isViewerInitialized && (openedFrom != 'mapped')"
        [showSlideTray]="true"></app-image-slide-tray>
</div>