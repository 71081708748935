import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inputError'
})
export class InputErrorPipe implements PipeTransform {

    transform(value: any, arg1): string {
        let rvalue: string = '';
        if (value !== null) {
            switch (arg1) {
                case 'mapStudy': return value == true ? rvalue = 'ERROR.MAPSTUDY.REQUIRED' : null;
                case 'mapCassette': return value == true ? rvalue = 'ERROR.MAPCASSETTE.REQUIRED' : null;
                case 'mapStain': return value == true ? rvalue = 'ERROR.MAPSTAIN.REQUIRED' : null;
                case 'mapAnimal': return value == true ? rvalue = 'ERROR.MAPANIMAL.REQUIRED' : null;
                case 'imageUpload': return value == true ? rvalue = 'ERROR.IMAGEUPLOAD.REQUIRED' : null;
            }
        }
    }
}