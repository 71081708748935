<div class="viewerBody">
  <div id="disconnectUserToast">You are disconnected from the meeting due to network problem</div>
  <app-right-side-bar [meetingDetails]="meetingDetails"></app-right-side-bar>
  <div class="timer" *ngIf="timer" title=""><img src="../assets/images/viewer_icon/timer.svg" /><span>{{timer}}</span>
  </div>
  <div class="viewercontent osdViewerMainDiv" #viewerContent (window:resize)="onResize($event)">
    <!-- <div *ngIf="!isPresenter && (imageViewerArr.length == 1)" class="participantCursor" [ngStyle]="{'top':cursorEvent.pageY, 'left': cursorEvent.pageX }"></div> -->
    <div *ngIf="!isPresenter" class="participantCursor"
      [ngStyle]="{'top':cursorEvent.pageY, 'left': cursorEvent.pageX }"></div>
    <div id="toolBarDiv">
      <!--*ngIf="isViewerInitialized"-->
      <app-top-bar [imageViewerObj]="imageViewerArr"></app-top-bar>
      <!-- <ng-container
        *ngIf="isViewerInitialized && !isSplitViewEnabled">
        <app-annotation-manager [imageViewerObj]="imageViewerArr"></app-annotation-manager>
      </ng-container> -->
      <div class="progress progressDiv" *ngIf="showWaitingLoader">
        <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw; width: 1vw; height: 1vw;"
          role="status" aria-hidden="true"></span>
        <span class="waitingProgress">Waiting for host to join...</span>
      </div>
      <div id="voiceCall" style="display: none;"></div>
      <app-left-side-bar *ngIf="isViewerInitialized && isPresenter && !isImageNotAvailable"
        [meetingDetails]="meetingDetails" [imageViewerObj]="imageViewerArr">
      </app-left-side-bar>
      <div [hidden]="!isViewerInitialized || isImageNotAvailable" class="joystickRotationNavigator" [@slideJoystickRotationNavigator]=currentJoystickRotationNavigatorState>
        <div class="joystickRotationNavigatorToggle">
          <div (click)="toggleJoystickRotationNavigator()"><i class="fa fa-chevron-right rotateArrow"
              [ngClass]="currentArrowState=='open' ? 'openArrow' : 'closeArrow'" aria-hidden="true"></i></div>
        </div>
        <div class="disabledJoystickrotationNavigator"></div>
        <div class="row">
          <div class="joyStickWrapper col-6">
            <div id="zone_joystick"></div>
          </div>
          <div class="viewerRotationer col-6">
            <div id="DCroundSlider"></div>
          </div>
        </div>
        <!-- <div class="row zoomingIndex">
          <div class="col-2 fitToScreen" (click)="fitToScreen($event)"><b>F</b></div>
          <div class="col-2 2x" (click)="joystickNavZoomToIndex($event, 2)">2x</div>
          <div class="col-2 5x" (click)="joystickNavZoomToIndex($event, 5)">5x</div>
          <div class="col-2 10x" (click)="joystickNavZoomToIndex($event, 10)">10x</div>
          <div class="col-2 20x" (click)="joystickNavZoomToIndex($event, 20)">20x</div>
          <div class="col-2 40x" (click)="joystickNavZoomToIndex($event, 40)">40x</div>
        </div> -->
        <div class="zoomingIndexDiv">
          <div class="zoomingIndexItem fitToScreen" (click)="fitToScreen($event)"><b>F</b></div>
          <div *ngFor="let zoom of finalZoomIndexArr" class="zoomingIndexItem {{zoom}}x"
            (click)="joystickNavZoomToIndex($event, zoom)">{{zoom}}x</div>
        </div>
        <div class="sliderArea">
          <div class="rowLabel float-left">Zoom: </div>
          <div class="float-right">
            <div class="row">
              <div class="sliderText" (click)="joystickNavZoomToIndex($event, 'decrease')"><i class="fa fa-minus"
                  aria-hidden="true"></i></div>
              <div class="range-slider">
                <div><input class="input-range" type="range" min="1" max="{{maxZoomLevel}}"
                    [(ngModel)]="sliderRangeValue" (input)="joystickNavZoomToIndex($event, 'slider')"></div>
                <div class="sliderValue">{{sliderRangeValue}}x</div>
              </div>
              <div class="sliderText" (click)="joystickNavZoomToIndex($event, 'increase')"><i class="fa fa-plus"
                  aria-hidden="true"></i></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="height: 100%;">(meetingDetails | json) != '{}' &&
        <div id="openseadragonNavBar" class="osdNavigator">
          <div class="joystickRotationNavigatorToggle" style="display: none;">
            <div id="osdnavArrowDiv" (click)="toggleImageNavigator($event, '')"><i
                class="fa fa-chevron-down rotateArrow"
                [ngClass]="currentNavigatorArrowState=='open' ? 'openArrow' : 'closeArrow'" aria-hidden="true"></i>
            </div>
          </div>

          <div id="openseadragonNavLebel"></div>
          <div id="openseadragonNavDiv"></div>
        </div>
      </div> -->
    </div>
  </div>

  <!-- Modal -->
  <!-- <div class="modal fade" id="DCLoaderModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div id="DCLoaderDialog">
            <div style="margin: 2vw;"><mat-spinner></mat-spinner></div>
            <div class="DCLoaderMsg">Loading Image... <br> Please Wait...</div>  
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="modalDCLoader">
    <div class="modalDCLoaderInner">
      <div id="DCLoaderDialog">
        <div style="margin: 2vw;">
          <mat-spinner></mat-spinner>
        </div>
        <div class="DCLoaderMsg">Loading Image... <br> Please Wait...</div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
  <app-anno-comment-popup [osdObj]="osdObj" [threeJsObj]="threeJsObj"></app-anno-comment-popup>
  <app-image-slide-tray [showSlideTray]="false"></app-image-slide-tray>

</div>
