<!-- <div class="mdl-layout mdl-js-layout mdl-layout--fixed-header" *ngIf="!router.url.includes('login')">
  <app-header></app-header>
  <main class="mdl-layout__content">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--1-col-desktop mdl-cell--2-col-tablet mdl-cell--4-col-phone sidebar">
        <app-sidebar></app-sidebar>
      </div>
      <div class="mdl-cell mdl-cell--11-col-desktop mdl-cell--10-col-tablet mdl-cell--8-col-phone compcontainer">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </main>
</div> -->

<router-outlet></router-outlet>
