import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../auth/auth.service';
import { LoaderComponent } from './../../common/loader/loader.component';
import { ConsultationWrapperService } from './models/consultation-wrapper.service';
import { ConsultationViewerService } from './consultation-viewer.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ImageViewer } from './../../image-viewer/models/ImageViewer.class';
import { Component, ElementRef, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ImageViewerService } from './../../image-viewer/image-viewer.service';
import { AnnotationService } from './../../image-viewer/annotation.service';
import { WebviewerWrapperService } from './../../image-viewer/models/WebviewerWrapper.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from './../../common.service';
import { AppConfigService } from '../../app-config.service';
import { even } from '@rxweb/reactive-form-validators';
import { parse } from 'path';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';

declare const nipplejs: any;
declare const $: any;
declare const scalebar: any;
declare const OpenSeadragon: any;

@Component({
  selector: 'app-consultation-viewer',
  templateUrl: './consultation-viewer.component.html',
  styleUrls: ['./consultation-viewer.component.css'],
  animations: [
    trigger('slideJoystickRotationNavigator', [
      state('open', style({ right: '0px', })),
      state('close', style({ right: '-12vw', })),
      transition('close => open', [animate(600, style({ transform: 'translateX(-12vw)' }))]),
      transition('open => close', [animate(600, style({ transform: 'translateX(12vw)' }))]),
    ]),
  ]
})
export class ConsultationViewerComponent implements OnInit, AfterViewInit {

  @ViewChild('viewerContent') private viewerContent: ElementRef;
  openseadragonIDS: any; // to push dynamic openseadragin ids
  scalebarIDS: any = []; // to push dynamic scalebar ids
  imageStatusIds: any = [];

  zoomLevel: any = 0;

  selectedViewerIndex: number = 0;
  imageViewer: any;
  imageViewerArr: any = [];
  isSplitViewEnabled: boolean = false;
  joystickMove: any;
  osdObj: any;
  viewerScreens = 1;
  sliderRangeValue: number;
  currentJoystickRotationNavigatorState = 'open';
  currentArrowState = 'open';
  currentNavigatorArrowState = 'open';
  currentNavigatorArrowState1 = 'open';
  currentNavigatorArrowState2 = 'open';
  currentNavigatorArrowState3 = 'open';
  currentNavigatorArrowState4 = 'open';
  isViewerInitialized: boolean = false;
  isImageNotAvailable: boolean = false;
  zoomIndexClicked: string = null;
  openedFrom: any;
  showTopBar: boolean = false;
  showSideBar: boolean = false;
  showAnnotationManager: boolean = false;
  mySubscription: any;
  rotationValue: number;
  isSynch: boolean = false;
  synchronizationObs: any;
  addThreeJsNewAnnotationSubscribe: any;
  activeRole: String;
  multipleViewerIDs: any;
  slideIds: string[] = [];
  activeImageViewer: String;

  selectedROI: number = -1;
  selectedViewerSchema: any;
  maxZoomLevel: number = 40;

  sliderMinPixelValue: number = 4;
  initZoomIndexArr: any = [2, 5, 10, 20, 40];
  finalZoomIndexArr: any = [];
  config: any;
  currentViewerTileLevel: number = 0;
  fineTuneAppliedLevel: any = {};
  fineTuneNonAppliedLevel: any = {};
  fineTuneEnableCheckObj: any = {};

  dialogConfig = new MatDialogConfig();
  imageLoaderRef: any;
  reloadParticipantObj: boolean = false;


  openSeaDragon: any;
  meetingDetails: any = {};
  isPresenter: boolean = false;
  currentSlideId: any;
  currentUser: any;
  slidesToCloseArr = [];
  threeJsObj: any = {};
  clientPointX: any;
  clientPointY: any;
  isPerformingTask: boolean = false;
  participantObj: any;

  participantStatus: any = {};
  participantStatusEmailId: any = [];
  dummyParticipantObj: any = {};

  meetSlideObj: any = {};
  hostId: any;
  presenterId: any;
  showWaitingLoader: boolean = false;
  timer: any = '';
  timerInterval: any = '';
  cursorEvent: any = {
    pageX: '0px',
    pageY: '0px'
  }
  selectedViewerindex: number;
  responseObj: any;
  mouseTrackerArr: any = [];
  // mediaConstraints = { audio: true, video: false };
  mediaConstraints = {};
  iceServers = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
      { urls: 'stun:stun2.l.google.com:19302' },
      { urls: 'stun:stun3.l.google.com:19302' },
      { urls: 'stun:stun4.l.google.com:19302' },
      // {
      //   urls: "turn:122.187.189.18:3478?transport=udp",
      //   username: "turn@airamatrix.in",
      //   credential: "password",
      // }
    ]
  }
  localStream: MediaStream;
  rtcPeerConnection: any = {} // Connection between the local device and the remote peer.
  remoteStream: any = {};
  isAnnotationInProg = false;
  isBrowserTabActive: boolean = true;
  imageViewerImageFilter: any = {};

  drawAnnoScreenshot: any;
  clearResizeTimeout: any = null;
  updateZoomTimeOut: any = null;
  resetFineTuneTimeOut: any = null;
  goHomeTimeOut: any = null;
  allAnnotationArr = [];
  recentlyLoaded: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private viewerService: ImageViewerService,
    private annotationService: AnnotationService,
    private webviewerWrapperService: WebviewerWrapperService,
    private consultationViewerService: ConsultationViewerService,
    private commonService: CommonService,
    private appConfig: AppConfigService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService,
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.config = this.appConfig.config;
    this.recentlyLoaded = true;
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(e) {
    if (!this.isPresenter) {
      if (e.target.visibilityState == "visible") {
        console.log("current tab visible");
        this.isBrowserTabActive = true;
        this.consultationViewerService.emitEvent('get-meet-status', {
          meetID: this.meetingDetails.meeting_pk, clientID: this.currentUser.userName
        });
      } else {
        this.isBrowserTabActive = false;
      }
    }
  }

  ngOnInit() {
    localStorage.isViewerInitialized = false;
    localStorage.clearResponseData = "false";
    $(".disableTopBar, .disableRightBar").show();
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    this.dialogConfig.disableClose = true;
    this.dialogConfig.data = {
      'Msg': "Loading Image... Please Wait..."
    };
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.isViewerInitialized && !this.isSplitViewEnabled && this.openedFrom != 'scanned') {
      this.showSideBar = true;
    }
    if (this.isViewerInitialized && !this.isSplitViewEnabled && this.openedFrom != 'scanned') {
      this.showAnnotationManager = true;
    }
    this.synchronizationObs = this.commonService.synchronizeImage.subscribe((value) => {
      this.isSynch = value;

      if (this.isSynch) {
        var rotation = this.osdObj.viewport.getRotation();
        this.setRotationToViewers(rotation);
      }

      if (this.updateZoomTimeOut) {
        clearTimeout(this.updateZoomTimeOut);
      }

      this.updateZoomTimeOut = setTimeout(() => {
        this.updateZoom(true);
      }, 500);
    });

    this.viewerService.stopAnno.subscribe((value: boolean) => {
      if (value) {
        this.annotationService.resetNewCreateAnnotationInfo({});
        this.osdObj.setMouseNavEnabled(true);
        this.osdObj.panHorizontal = this.osdObj.panVertical = true;
        this.threeJsObj.renderer3.domElement.style.cursor = "default";
        $('.disabledJoystickrotationNavigator,.disableTopBar').hide();
        $('#ViewerRotate').removeClass('disableFunction');
        $('#ViewerEasyView').removeClass('disableFunction');
      }
    });

    this.viewerService.loadAnnotation.subscribe((value: any) => {
      if (Object.keys(value).length) {
        this.loadAnnotations(value.slideId, this.osdObj);
      }
    });

    this.drawAnnoScreenshot = this.viewerService.drawScreenshot.subscribe((shape: string) => {
      if (shape) {
        let canvas = this.threeJsObj.renderer3.domElement;
        canvas.getContext('webgl', { preserveDrawingBuffer: true });
        this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
        this.threeJsObj.camera.updateMatrixWorld();
        var threeImg = new Image();
        threeImg.src = canvas.toDataURL('image/png');
        canvas.getContext('webgl', { preserveDrawingBuffer: false });
        this.imageViewer.getScreenshotObj().drawScreenShotShape(shape, this.osdObj.scalebarInstance.scalebarId, threeImg);
      }
    });

    this.addThreeJsNewAnnotationSubscribe = this.commonService.addThreeJsAnnotation.subscribe((value) => {
      if (!$.isEmptyObject(value)) {
        let threeJsObj = this.imageViewerArr[Number(this.osdObj.id.replace("openseadragon_", ""))].threeJsObj;
        this.annotationService.threeJsAnnotationSetup([value], this.osdObj, threeJsObj);

        setTimeout(() => {
          this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
          this.threeJsObj.camera.updateMatrixWorld();
          $(".annotaion").remove();
        }, 10);
      }
    });

    this.iceServers["iceServers"] = [...this.iceServers["iceServers"], ...this.config.localStunServer];

    this.consultationViewerService.participantUserList.subscribe((participantData: any) => {
      this.participantObj = participantData;
      let participantArr = Object.values(participantData);
      if (this.isPresenter || this.consultationViewerService.getHost()) {
        if (this.recentlyLoaded) {
          this.recentlyLoaded = false;
        } else {
          return;
        }
        setTimeout((() => {
          for (let i in participantArr) {
            if (participantArr[i]["isAccessRequested"]) {
              this.consultationViewerService.setparticipantChatPanelVisibeValue(true);
              // this.consultationViewerService.setAccessRequestCount(1);
              break;
            }
          }
        }).bind(this), 500);
      }

    });

    $("#osdDiv").css({ "height": (document.documentElement.clientHeight - $(".row.top_view_bar").height()), "margin-top": $(".row.top_view_bar").height() });
    this.activatedRoute.paramMap.subscribe((paramMap: any) => {
      let id = paramMap.params.meetingId;
      let details = this.getMeetingDetails(id);
      details.then((successmsgs: any) => {
        this.meetingDetails = successmsgs.data;
        this.consultationViewerService.setMeetingDetails(this.meetingDetails);
        this.initIoConnection();
        this.consultationonEvent();

        let isHost = this.consultationViewerService.getHost();

        /* if (isHost && this.meetingDetails.status == 'NOT_STARTED') {
          this.consultationViewerService.updateMeetingStatus(this.meetingDetails.meeting_pk, { "status": "IN_PROGRESS" }).subscribe((res: any) => {
          });
        } */ // moved to meeting listing page

        let participantUser = {};
        participantUser[this.meetingDetails.creator] = {
          'displayName': this.meetingDetails.creatorDisplayName,
          'emailId': this.meetingDetails.creator,
          'isPresenter': false,
          'isHost': true,
          'isExternal': false,
          'isJoined': (this.currentUser.userName == this.meetingDetails.creator),
          'isAccessRequested': false,
          'isCurrentUser': (this.currentUser.userName == this.meetingDetails.creator),
          // 'mute': 'no',
          // 'audio': 0
        };
        this.hostId = this.meetingDetails.creator;
        this.consultationViewerService.setHostID(this.hostId);

        this.meetingDetails.invitees.forEach(element => {
          participantUser[element.emailId] = {
            'displayName': element.displayName,
            'emailId': element.emailId,
            'isPresenter': false,
            'isHost': false,
            'isExternal': element.isExternal,
            'isJoined': false,
            'isAccessRequested': false,
            'isCurrentUser': (this.currentUser.userName == element.emailId),
            // 'mute': 'no',
            // 'audio': 0
          };
          if (this.currentUser.userName == element.emailId) {
            localStorage.isCurrentUserExternal = element.isExternal;
          }
          this.updateMicIcons(element.emailId);
        });
        this.consultationViewerService.setParticipantUserList(participantUser);
      });
    });

    this.annotationService.currentAnno.subscribe((value: any) => {
      if (this.meetingDetails && this.meetingDetails.meeting_pk) {
        this.consultationViewerService.emitEvent('annotations', {
          meetID: this.meetingDetails.meeting_pk, annoObj: value, clientID: this.currentUser.userName
        });
      }
    });

    this.consultationViewerService.easyViewStarted.subscribe((value: boolean) => {
      if (value) {
        this.updateZoom();
      }
    });

    this.consultationViewerService.zoomIndex.subscribe((value: number) => {
      if (value && this.osdObj) {
        // this.sliderRangeValue = value;
        let imageZoom = this.osdObj.viewport.imageToViewportZoom(value / this.maxZoomLevel);
        this.osdObj.viewport.zoomTo(imageZoom);
        $('#' + this.osdObj.id).find(".imageStatusZoom").html("Zoom: " + this.sliderRangeValue + "x");
        // let zoom = this.osdObj.viewport.viewportToImageZoom(value);
        // // this.sliderRangeValue = parseFloat((zoom * this.maxZoomLevel).toFixed(2));
        // this.osdObj.viewport.zoomTo(value);
      }
    });

    this.consultationViewerService.rotationValue.subscribe(value => {
      if ($.isNumeric(value)) {
        this.setRotationToViewers(value);
        $('#DCroundSlider').roundSlider('setValue', value);
        $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html(value + "<span>&#176;</span>");
        if (this.isPresenter) {
          this.updateZoom();
        }
      }
    });

    this.consultationViewerService.selectedMic.subscribe((value: any) => {
      // console.log(this);
      // console.log("Mic Device Id: " + value);
      if (this.localStream && !$.isEmptyObject(this.rtcPeerConnection)) {
        //await this.setLocalStream();
        var audioTrack = this.localStream.getAudioTracks();
        audioTrack.forEach(track => {
          track.stop();
        });

        var audioSource = value.deviceId;
        var newmediaConstraints = { audio: { deviceId: audioSource ? { exact: audioSource } : undefined }, video: false };

        navigator.mediaDevices.getUserMedia(newmediaConstraints).then((newStream) => {
          var audioTrack = newStream.getAudioTracks()[0];
          var allParticipants = Object.keys(this.rtcPeerConnection);
          for (let x = 0; x < allParticipants.length; x++) {
            var sender = this.rtcPeerConnection[allParticipants[x]].getSenders().find(function (s) {
              return s.track.kind == audioTrack.kind;
            });
            sender.replaceTrack(audioTrack);
          }
          this.localStream = newStream;
          // this.createLocalAudioContext(this.localStream);

        }).catch((err) => {
          console.log(JSON.stringify(err))
        });
      }
    });

  }

  ngAfterViewInit() {
    this.consultationViewerService.selectedImage.subscribe((value: any) => {
      if (value.length > 0) {
        // this.imageLoaderRef = this.dialog.open(LoaderComponent, this.dialogConfig);
        // $("#DCLoaderPopup").show();
        // $('#DCLoaderModal').modal('show');
        if (this.isPresenter) {
          if (localStorage.clearResponseData == "true") {
            localStorage.clearResponseData = "false";
            this.responseObj = '';
            this.selectedViewerindex = 0;
            this.currentSlideId = value[0];
            this.imageViewerImageFilter = {};
            this.consultationViewerService.setAllImageFilters({});
            this.consultationViewerService.emitEvent('image-change', {
              meetID: this.meetingDetails.meeting_pk,
              clientID: this.currentUser.userName,
              images: value,
              // isSynchronized: this.isSynch,
              selectedViewerId: 0
            });
          }
        }

        this.consultationViewerService.setRotationValue(0);
        $('.viewercontent').children('.viewer').remove();
        this.openseadragonIDS = [];
        this.scalebarIDS = [];
        this.imageStatusIds = [];
        this.destroyOsdObjs();
        this.isViewerInitialized = false;
        this.isImageNotAvailable = false;
        this.imageViewer = "";
        this.imageViewerArr = [];
        this.isSynch = false;
        this.slideIds = value;
        this.consultationViewerService.setActiveTool("");
        this.createView();
      }
    });

    this.consultationViewerService.synchronizeImage.subscribe((value) => {
      this.isSynch = value;

      if (this.isPresenter) {
        this.consultationViewerService.emitEvent('synchImages', {
          meetID: this.meetingDetails.meeting_pk,
          clientID: this.currentUser.userName,
          isSynchronized: this.isSynch
        });
      }
      if (this.osdObj) {
        var rotation = this.osdObj.viewport.getRotation();
        this.setRotationToViewers(rotation);
        this.updateZoom();

        if (rotation == 0) {
          if (this.isSynch) {
            if (this.slideIds.length == 2) {
              for (let x = 0; x < this.slideIds.length; x++) {
                if ($("#osdnavArrowDiv_" + x).attr("arrow-state") === 'close') {
                  $("#osdnavArrowDiv_" + x).closest(".joystickRotationNavigatorToggle").show();
                  setTimeout(() => {
                    $("#osdnavArrowDiv_" + x).trigger("click");
                  }, 0);
                }
              }
            }
          } else {
            if ($("#osdnavArrowDiv_" + this.selectedViewerindex).attr("arrow-state") === 'close') {
              $("#osdnavArrowDiv_" + this.selectedViewerindex).closest(".joystickRotationNavigatorToggle").show();
              setTimeout(() => {
                $("#osdnavArrowDiv_" + this.selectedViewerindex).trigger("click");
              }, 0);
            }
          }
        } else {
          if (this.isSynch) {
            if (this.slideIds.length == 2) {
              for (let x = 0; x < this.slideIds.length; x++) {
                if ($("#osdnavArrowDiv_" + x).attr("arrow-state") === 'open') {
                  setTimeout(() => {
                    $("#osdnavArrowDiv_" + x).trigger("click");
                  }, 0);
                }
                $("#osdnavArrowDiv_" + x).closest(".joystickRotationNavigatorToggle").hide();
              }
            }
          } else {
            if ($("#osdnavArrowDiv_" + this.selectedViewerindex).attr("arrow-state") === 'open') {
              setTimeout(() => {
                $("#osdnavArrowDiv_" + this.selectedViewerindex).trigger("click");
              }, 0);
            }
            $("#osdnavArrowDiv_" + this.selectedViewerindex).closest(".joystickRotationNavigatorToggle").hide();
          }
        }
      }
    });

    this.consultationViewerService.mute.subscribe((value) => {
      let muteStatus = false;
      if (this.localStream) {
        this.localStream.getAudioTracks()[0].enabled = value;
        if (this.localStream.getAudioTracks()[0].enabled) {
          // this.participantObj[this.currentUser.userName].mute = 'no';
          muteStatus = false;
          $(".participanList .participantItem[participant-emailid='" + this.currentUser.userName + "']").attr("mute", "no");
        } else {
          // this.participantObj[this.currentUser.userName].mute = 'yes';
          muteStatus = true;
          $(".participanList .participantItem[participant-emailid='" + this.currentUser.userName + "']").attr("mute", "yes");
        }
        this.updateMicIcons(this.currentUser.userName);
        this.consultationViewerService.setParticipantUserList(this.participantObj);
        this.consultationViewerService.emitEvent('mute', {
          'meetID': this.meetingDetails.meeting_pk,
          'clientID': this.currentUser.userName,
          'status': muteStatus
        });
      }
    });

    this.consultationViewerService.imageFiltersValues.subscribe((filters: any) => {
      if (this.osdObj) {
        this.osdObj.setFilterOptions({
          filters: {
            processors: [
              OpenSeadragon.Filters.GAMMA(filters.gamma),
              OpenSeadragon.Filters.BRIGHTNESS(filters.brightness),
              OpenSeadragon.Filters.CONTRAST(filters.contrast),
            ]
          },
          loadMode: 'sync'
        });

        /*let selectedSlideId = this.slideIds[$("#" + this.osdObj.id).attr("index")];
        if (selectedSlideId) {
          if (!this.imageViewerImageFilter[selectedSlideId]) {
            let newObj = {};
            newObj[selectedSlideId] = filters;
            Object.assign(this.imageViewerImageFilter, newObj);
          } else {
            this.imageViewerImageFilter[selectedSlideId] = filters;
          }
          this.consultationViewerService.setAllImageFilters(this.imageViewerImageFilter);
        } */

        this.consultationViewerService.emitEvent('image-adjustment', {
          meetID: this.meetingDetails.meeting_pk,
          brightness: filters.brightness,
          gamma: filters.gamma,
          contrast: filters.contrast,
          clientID: this.currentUser.userName
        });
      }
    });
  }

  createView() {
    $(".modalDCLoader").addClass("modalDCLoaderVisible");

    let noOfSlides = this.slideIds.length;
    this.viewerScreens = 0;
    this.isSplitViewEnabled = (noOfSlides > 1) ? true : false;
    for (let i = 0; i < noOfSlides; i++) {
      let container = this.renderer.createElement('div');
      let id = 'openseadragon_' + i;
      this.renderer.setProperty(container, 'id', id);
      this.renderer.setAttribute(container, 'index', i + "");
      this.renderer.setStyle(container, 'height', noOfSlides == 4 ? '50%' : '100%');
      this.renderer.setStyle(container, 'width', (noOfSlides == 4 || noOfSlides == 2) ? '50%' : noOfSlides == 3 ? '33.33%' : '100%');
      this.renderer.setStyle(container, 'position', 'relative');
      this.renderer.setStyle(container, 'padding', '2px');
      this.renderer.addClass(container, 'float-left');
      this.renderer.addClass(container, 'viewer');
      this.renderer.listen(container, 'click', this.selectedViewer.bind(this))
      this.openseadragonIDS.push(id);

      //fitToScreen Icon
      let fitToScreenParentElem = this.renderer.createElement('div');
      let fitToScreenParentElem_id = 'fitToScreenParent_' + i;
      fitToScreenParentElem.classList.add('fitToScreenParent');
      this.renderer.setProperty(fitToScreenParentElem, 'id', fitToScreenParentElem_id);
      // this.renderer.addClass(fitToScreenParentElem, 'fitToScreenParent');

      let fitToScreenIcon = this.renderer.createElement('span');
      fitToScreenIcon.classList.add('fitToScreenIcon');
      fitToScreenIcon.textContent = "F";
      this.renderer.appendChild(fitToScreenParentElem, fitToScreenIcon);

      this.renderer.appendChild(container, fitToScreenParentElem);

      //Zoom Status & Scalebar
      let imageStatusScalebarParentElem = this.renderer.createElement('div');
      let imageStatusScalebarParentElem_id = 'imageStatusScalebarParent_' + i;
      this.renderer.setProperty(imageStatusScalebarParentElem, 'id', imageStatusScalebarParentElem_id);
      this.renderer.addClass(imageStatusScalebarParentElem, 'imageStatusScalebarParent');

      let scalebars = this.renderer.createElement('div');
      let scalebar_id = 'scalebar_' + i;
      this.renderer.setProperty(scalebars, 'id', scalebar_id)
      this.renderer.addClass(scalebars, 'scalebar');
      this.scalebarIDS.push(scalebar_id);

      if (noOfSlides <= 2) {
        let scalebarArrow = this.renderer.createElement('div');
        this.renderer.addClass(scalebarArrow, 'joystickRotationNavigatorToggle');
        this.renderer.appendChild(imageStatusScalebarParentElem, scalebarArrow);

        let osdnavArrowDiv = this.renderer.createElement('div');
        this.renderer.setProperty(osdnavArrowDiv, 'id', ("osdnavArrowDiv_" + i))
        this.renderer.setAttribute(osdnavArrowDiv, 'arrow-state', 'open');
        this.renderer.setAttribute(osdnavArrowDiv, 'nav-id', '' + i);
        this.renderer.appendChild(scalebarArrow, osdnavArrowDiv);
        this.renderer.listen(osdnavArrowDiv, 'click', this.toggleImageNavigator.bind(this));

        let rotateArrow = this.renderer.createElement('i');
        rotateArrow.classList.add('fa', 'fa-chevron-down', 'rotateArrow', 'openArrow');
        this.renderer.appendChild(osdnavArrowDiv, rotateArrow);
      }

      // if (noOfSlides > 1) {
      // this.renderer.setStyle(container, 'border', '1px solid #F47625');
      let imageStatus = this.renderer.createElement('div');
      let imageStatus_id = 'imageStatus_' + i;
      this.renderer.setProperty(imageStatus, 'id', imageStatus_id);
      this.renderer.addClass(imageStatus, 'imageStatus');
      this.imageStatusIds.push(imageStatus_id);
      this.renderer.appendChild(imageStatusScalebarParentElem, imageStatus);
      // }
      // this.renderer.setStyle(imageStatus,'width',(75 * ((screen.width > 1280) ? (screen.width / 1280) : 1)) + 'px')

      this.renderer.appendChild(this.viewerContent.nativeElement, container);
      this.renderer.appendChild(container, imageStatusScalebarParentElem);
      this.renderer.appendChild(imageStatusScalebarParentElem, scalebars);
      this.viewerScreens++;
    }
    this.seqImageViewer(0);
  }

  setImageMinMaxZoomLevel(osdObj: any = null) {
    if (osdObj) {
      osdObj.viewport.maxZoomLevel = osdObj.viewport.imageToViewportZoom(1);
      osdObj.viewport.minZoomLevel = osdObj.viewport.getHomeZoom();
    } else {
      for (let x = 0; x < this.imageViewerArr.length; x++) {
        this.imageViewerArr[x].osdObj.viewport.maxZoomLevel = this.imageViewerArr[x].osdObj.viewport.imageToViewportZoom(1);
        this.imageViewerArr[x].osdObj.viewport.minZoomLevel = this.imageViewerArr[x].osdObj.viewport.getHomeZoom();
      }
    }
  }

  /* setImageMinMaxZoomLevel_Old(callBack: any = null) {
    let minImgZoom = null, maxImgZoom = null;
    if (this.isSynch) {
      for (let x = 0; x < this.imageViewerArr.length; x++) {
        let minZoom = this.imageViewerArr[x].osdObj.viewport.getHomeZoom();
        let currMinImgZoom = (this.imageViewerArr[x].osdObj.viewport.viewportToImageZoom(minZoom) * this.maxZoomLevel);
        if (!minImgZoom || (minImgZoom < currMinImgZoom)) {
          minImgZoom = currMinImgZoom;
          // minViewportZoom = minZoom;
        }

        let maxZoom = this.imageViewerArr[x].osdObj.viewport.imageToViewportZoom(1);
        let currMaxImgZoom = (this.imageViewerArr[x].osdObj.viewport.viewportToImageZoom(maxZoom) * this.maxZoomLevel);
        if (!maxImgZoom || (maxImgZoom > currMaxImgZoom)) {
          maxImgZoom = currMaxImgZoom;
          // maxViewportZoom = maxZoom;
        }
      }
      for (let y = 0; y < this.imageViewerArr.length; y++) {
        this.imageViewerArr[y].osdObj.viewport.minZoomLevel = this.imageViewerArr[y].osdObj.viewport.imageToViewportZoom(minImgZoom / this.maxZoomLevel);
        // this.imageViewerArr[y].osdObj.viewport.maxZoomLevel = this.imageViewerArr[y].osdObj.viewport.imageToViewportZoom(maxImgZoom / this.maxZoomLevel);
      }

    } else {
      for (let x = 0; x < this.imageViewerArr.length; x++) {
        this.imageViewerArr[x].osdObj.viewport.maxZoomLevel = this.imageViewerArr[x].osdObj.viewport.imageToViewportZoom(1);
        this.imageViewerArr[x].osdObj.viewport.minZoomLevel = this.imageViewerArr[x].osdObj.viewport.getHomeZoom();
      }
    }

    if (callBack) {
      callBack(minImgZoom, maxImgZoom);
    }
  } */

  closeCheckboxChange = (event) => {
    let index = this.slidesToCloseArr.indexOf(this.slideIds[this.selectedViewerIndex]);
    if (event.target.checked) {
      if (index == -1) {
        this.slidesToCloseArr.push(this.slideIds[this.selectedViewerIndex]);
        this.commonService.closeViewerArrChanged(this.slidesToCloseArr);
      }
    } else {
      if (index > -1) {
        this.slidesToCloseArr.splice(index, 1);
        this.commonService.closeViewerArrChanged(this.slidesToCloseArr);
      }
    }
  }

  seqImageViewer(index: number) {
    if (this.slideIds[index]) {
      this.getSlideDetails(this.slideIds[index]).then((successData: any) => {
        let viewer = new ImageViewer(successData.data, this.openseadragonIDS[index], this.webviewerWrapperService, (this.slideIds.length > 2),
          (imageData: any) => {
            if (!imageData.osd) {
              $('#' + this.openseadragonIDS[index]).prepend('<img id="theImg" src="assets/images/viewer_icon/default_img.svg" />');
              $('#' + this.openseadragonIDS[index]).find('img#theImg').css({
                "height": $('#' + this.openseadragonIDS[index]).height(),
                "width": $('#' + this.openseadragonIDS[index]).width()
              });

              index = index + 1;
              if (index < this.slideIds.length) {
                this.seqImageViewer(index);
              } else {
                this.imageViewerArr.push(viewer);
                this.slideIds.length == 1 ? this.isImageNotAvailable = true : this.isImageNotAvailable = false;
                $(".modalDCLoader").removeClass("modalDCLoaderVisible");
                localStorage.isViewerInitialized = true;
              }
            } else {
              imageData.osd.viewport.maxZoomLevel = imageData.osd.viewport.imageToViewportZoom(1);
              imageData.osd.viewport.minZoomLevel = imageData.osd.viewport.getHomeZoom();

              $(imageData.osd.canvas).css({ "opacity": 0 });
              imageData.osd.addHandler('pre-full-screen', () => {
                $(".imageStatusScalebarParent, .annotemanger_popup").hide();
              });

              imageData.osd.addHandler('canvas-key', (e) => {
                var x = 0;
                if (this.annotationService.newCreateAnnoInfo.initiateDrawing) {
                  // if ([37, 38, 39, 40].indexOf(e.originalEvent.keyCode) >= 0) {
                  e.preventDefault = true; // disable default keyboard controls
                  e.preventVerticalPan = true; // disable vertical panning with arrows and W or S keys
                  e.preventHorizontalPan = true; // disable horizontal panning with arrows and A or D keys
                  // }
                } else {
                  e.preventDefault = false; // enable default keyboard controls
                  e.preventVerticalPan = false; // enable vertical panning with arrows and W or S keys
                  e.preventHorizontalPan = false; // enable horizontal panning with arrows and A or D keys
                }
              });

              imageData.osd.addHandler('full-screen', () => {
                setTimeout(() => {
                  this.resetScaleBarPos();
                }, 300);
              });

              imageData.osd.addHandler('canvas-drag', () => {
                this.isPerformingTask = true;
                /* $("#" + this.osdObj.id + "-annoHighlight").hide();
                if ($('.annotationPopOver').length > 0) {
                  if (!$('.annotationPopOver').is(":hover")) {
                    $(".annotationPopOver").hide();
                  }
                } */
              });

              imageData.osd.addHandler('canvas-drag-end', (ev) => {
                var rect = $("#" + ev.eventSource.id + "-overlaycanvas")[0].getBoundingClientRect();
                this.threeJsObj.mouse.x = ((ev.position.x) / (rect.width)) * 2 - 1;
                this.threeJsObj.mouse.y = - ((ev.position.y) / (rect.height)) * 2 + 1;

                this.clientPointX = ev.position.x;
                this.clientPointY = ev.position.y;

                this.isPerformingTask = false;
                this.annotationService.raycasterCallback(ev.eventSource.id == this.osdObj.id, this.clientPointX, this.clientPointY);
              });

              imageData.osd.addHandler('animation-start', () => {
                this.isPerformingTask = true;
                /* $("#" + this.osdObj.id + "-annoHighlight").hide();
                if (!$('.annotationPopOver').is(":hover")) {
                  $(".annotationPopOver").hide();
                } */
              });

              imageData.osd.addHandler('animation-finish', (ev) => {
                if (ev.eventSource.id == this.activeImageViewer) {
                  this.updateZoom(true);
                }

                this.isPerformingTask = false;
                if (this.osdObj) {
                  this.annotationService.raycasterCallback(ev.eventSource.id == this.osdObj.id, this.clientPointX, this.clientPointY);
                }
              });

              imageData.osd.addHandler('resize', (event) => {
                if (this.clearResizeTimeout) {
                  clearTimeout(this.clearResizeTimeout);
                }

                this.clearResizeTimeout = setTimeout(() => {
                  event.eventSource.viewport.maxZoomLevel = event.eventSource.viewport.imageToViewportZoom(1);
                  event.eventSource.viewport.minZoomLevel = event.eventSource.viewport.getHomeZoom();

                  if (event.eventSource.navigator) {
                    event.eventSource.navigator.forceRedraw();
                  }

                  this.resetScaleBarPos();
                  if (this.threeJsObj.threeOverlay) {
                    this.threeJsObj.threeOverlay.resize();
                    this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
                    this.threeJsObj.camera.updateMatrixWorld();
                  }
                  console.log("clearResizeTimeout");
                }, 300);
              });

              imageData.osd.addHandler('zoom', (event: any) => {
                if (event.zoom.toFixed(2) == event.eventSource.viewport._oldZoom.toFixed(2)) {
                  return;
                }
                // this.resetViewerMinPixelRatio(this.osdObj, true);
                if (event.eventSource.id == this.activeImageViewer) {
                  // this.updateZoom(false);
                  if (!this.isSplitViewEnabled && this.config.enableFineTune) {
                    if (this.resetFineTuneTimeOut) {
                      clearTimeout(this.resetFineTuneTimeOut);
                    }

                    this.resetFineTuneTimeOut = setTimeout(() => {
                      this.resetFineTune();
                    }, 500);
                    /* if (this.imageViewerArr.length == 1) {
                      this.checkFineTuneCanEnable();
                    } */
                  }
                  if (event.eventSource.viewport.minZoomLevel > event.zoom) {
                    if (this.goHomeTimeOut) {
                      clearTimeout(this.goHomeTimeOut);
                    }
                    this.goHomeTimeOut = setTimeout(() => {
                      event.eventSource.viewport.goHome();
                    }, 500);
                  } else {
                    this.viewerHandler(event, 'zoom');
                  }
                } else {
                  event.eventSource.setMouseNavEnabled(false);
                }
              });

              imageData.osd.addHandler('pan', (event: any) => {
                this.isPerformingTask = true;
                /* $("#" + this.osdObj.id + "-annoHighlight").hide();
                if ($('.annotationPopOver').length > 0) {
                  if (!$('.annotationPopOver').is(":hover")) {
                    $(".annotationPopOver").hide();
                  }
                } */

                if (event.eventSource.id == this.activeImageViewer) {
                  this.viewerHandler(event, 'pan');
                  // this.updateZoom(false);
                } else {
                  event.eventSource.setMouseNavEnabled(false);
                }
              });

              imageData.osd.addHandler('animation', (event: any) => {
                if (this.osdObj) {
                  $("#" + this.osdObj.id + "-annoHighlight").hide();
                  if (this.isSynch) {
                    $.each(this.imageViewerArr, (key, viewer) => {
                      let viewerObj = viewer.threeJsObj;

                      let currZoom = parseFloat((viewer.osdObj.viewport.viewportToImageZoom(viewer.osdObj.viewport.getZoom()) * viewerObj.schemaMaxZoomLevel).toFixed(2));
                      this.annotationService.setAnnoTextScale(currZoom, viewerObj);

                      viewerObj.renderer3.render(viewerObj.scene, viewerObj.camera);
                      viewerObj.camera.updateMatrixWorld();
                    });
                  } else {
                    let currZoom = parseFloat((this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom()) * this.threeJsObj.schemaMaxZoomLevel).toFixed(2));
                    this.annotationService.setAnnoTextScale(currZoom, this.threeJsObj);

                    let value = this.osdObj.viewport.getRotation();
                    this.threeJsObj.camera.rotation.z = value * Math.PI / 180;
                    this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
                    this.threeJsObj.camera.updateMatrixWorld();
                  }
                }
              });

              $('#' + imageData.osd.id + '-annoHighlight').on("mouseenter mousemove", (e) => {
                /* if (!$(".annotationPopOver .annoEditDiv").is(":visible")) {
                  $(".annotationPopOver .annoEditDiv").addClass("dn");
                  $(".annotationPopOver .annoToolTip").show();
                  $(".annotationPopOver").show();
                  $(".annotationPopOver").closest(".annoPopOverDiv").css({
                    'width': ($(".annoTooltipIcons").width() + $(".annoText").width()) + this.commonService.vwTOpx('1.25vw'),
                    'height': ($(".annoTooltipIcons").height() + this.commonService.vwTOpx('0.125vw'))
                  });
                } */
              }).on("mouseleave", (e) => {
                /* if ($('.annotationPopOver').is(":hover")) {
                  return;
                }
                if (!$(".annotationPopOver .annoEditDiv").is(":visible")) {
                  $(".annotationPopOver").hide();
                } */
              });

              this.annotationService.initiateInputHook(imageData);

              $("#" + imageData.osd.id + "-overlaycanvas").on('mousemove', (event) => {
                var rect = event.currentTarget.getBoundingClientRect();
                this.threeJsObj.mouse.x = ((event.clientX - rect.left) / (rect.width)) * 2 - 1;
                this.threeJsObj.mouse.y = - ((event.clientY - rect.top) / (rect.height)) * 2 + 1;

                let viewerContentPos = $(event.currentTarget).closest(".viewercontent")[0].getBoundingClientRect();
                this.clientPointX = event.clientX - viewerContentPos.left;
                this.clientPointY = event.clientY - viewerContentPos.top;

                if (!this.isPerformingTask) {
                  this.annotationService.raycasterCallback(event.currentTarget.id == this.osdObj.id + "-overlaycanvas", this.clientPointX, this.clientPointY);
                }
              });

              if (this.isPresenter) {
                imageData.osd.setMouseNavEnabled(true);
                imageData.osd.navigator?.innerTracker?.setTracking(true);
              } else {
                imageData.osd.setMouseNavEnabled(false);
                imageData.osd.navigator?.innerTracker?.setTracking(false);
              }

              imageData.osd.imagefilters({
                crossOriginPolicy: "Anonymous",
                showControl: 0
              });

              // init scale bar
              if (this.scalebarIDS[index]) { // patch work. need to find out why the index is +1 than slideIds.length
                imageData.osd.scalebar({
                  scaleBarId: this.scalebarIDS[index],
                  type: OpenSeadragon.ScalebarType.MICROSCOPY,
                  pixelsPerMeter: Math.pow(10, 9) / (imageData.osd.pixelToNanoVal),
                  stayInsideImage: false,
                  color: "#EA8823",
                  fontColor: "rgb(256, 256, 256)",
                  backgroundColor: "transparent",
                  minWidth: (25 * ((screen.width > 1280) ? (screen.width / 1280) : 1)) + 'px',
                  fontSize: "10px",
                  location: OpenSeadragon.ScalebarLocation.BOTTOM_RIGHT,
                  barThickness: 2,
                  showDot: (index == 0)
                });
                imageData.osd.scalebarInstance.refresh();
              }

              var currentViewportZoom = imageData.osd.viewport.getZoom();
              let zoom = imageData.osd.viewport.viewportToImageZoom(currentViewportZoom);
              console.log(zoom)
              let currentZoomText = parseFloat((zoom * this.maxZoomLevel).toFixed(2));

              $('#' + this.imageStatusIds[index]).html('<span class="imageStatusZoom" style="font-size: 0.6vw !important">Zoom: ' + currentZoomText +
                'x</span> <span class="imageStatusRotation" style="font-size: 0.6vw !important">Rotation: ' + imageData.osd.viewport.getRotation() + '&#176;</span>');

              $('#' + this.imageStatusIds[index] + " span").css({
                'padding': '0.2vw',
                'min-width': '5.5vw',
                'display': 'inline-block'
              });

              if ($('#' + this.imageStatusIds[index]).closest(".viewer").find(".navigator").length > 0) {
                $('#' + this.imageStatusIds[index]).css({
                  // "bottom": this.commonService.vwTOpx('0.4vw') + 6 + $('#' + this.imageStatusIds[index]).next(".scalebar").height() + ($('#' + this.imageStatusIds[index]).parent(".viewer").find(".navigator").height()),
                  "width": $('#' + this.imageStatusIds[index]).closest(".viewer").find(".navigator").width() + 4
                });
                $('#' + this.imageStatusIds[index] + " span").css({
                  'width': '45%',
                });
              }

              if ($('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").next('.openseadragon-container').find('.navigator').length > 0) {
                $('#' + this.scalebarIDS[index]).css({
                  'width': ($('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").next('.openseadragon-container').find('.navigator').width() + 4),
                  // 'bottom': ($('#' + this.scalebarIDS[index]).next('.openseadragon-container').find('.navigator').height() + 9),
                  'padding': '2px'
                });
                $('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").css({
                  'bottom': ($('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").next('.openseadragon-container').find('.navigator').height() + 9),
                });
              } else {
                $('#' + this.scalebarIDS[index]).css({
                  'width': ($('#' + this.imageStatusIds[index]).width() + 4),
                  // 'bottom': 0,
                  'padding': '2px'
                });
                $('#' + this.scalebarIDS[index]).parent(".imageStatusScalebarParent").css({
                  'bottom': 0,
                });
              }
              this.imageViewerArr.push(viewer);
              let currentViewer = this.imageViewerArr.filter((viewerOsd) => {
                return (viewerOsd.imageDisplayed == successData.data.slide.slidePk)
              });
              this.loadAnnotations(Number(this.slideIds[index]), currentViewer[0].osdObj);
              // this.setMouseTracker(imageData.osd)
              // this.mouseTrackerArr.push(mouseTracker);
              index = index + 1;
              if (index < this.slideIds.length) {
                this.seqImageViewer(index);
              } else {
                this.isViewerInitialized = true;
                localStorage.isViewerInitialized = true;
                var currentViewportZoom = this.imageViewerArr[0].osdObj.viewport.getZoom();
                let zoom1 = this.imageViewerArr[0].osdObj.viewport.viewportToImageZoom(currentViewportZoom);
                // let currentZoomText = parseFloat((zoom * this.maxZoomLevel).toFixed(2));
                // this.sliderRangeValue = parseFloat((zoom1 * this.maxZoomLevel).toFixed(2));
                console.log("zoom1 : " + zoom1)
                this.loadAfterOSD();
                this.updateResponseObj();

                setTimeout(() => {
                  if (this.imageViewerArr.length <= 2) {
                    for (let x = 0; x < this.imageViewerArr.length; x++) {
                      if (this.imageViewerArr[x].osdObj.navigator) {
                        this.imageViewerArr[x].osdObj.navigator.update(this.imageViewerArr[x].osdObj.viewport);
                        this.imageViewerArr[x].osdObj.navigator.forceRedraw();
                        // setTimeout(() => {
                        // this.resetViewerMinPixelRatio(this.imageViewerArr[x].osdObj, true);
                        setTimeout(() => {
                          $(this.imageViewerArr[x].osdObj.canvas).css({ "opacity": 1 });
                          if (x == (this.imageViewerArr.length - 1)) {
                            $(".modalDCLoader").removeClass("modalDCLoaderVisible");
                            // this.dialogRef.close();
                            localStorage.isViewerInitialized = true;
                            this.consultationViewerService.setImageViewerArr(this.imageViewerArr);
                            this.consultationViewerService.focusChanged(this.selectedViewerIndex);
                          }
                        }, 500);
                        // }, 500);
                      }
                    }
                  } else {
                    for (let x = 0; x < this.imageViewerArr.length; x++) {
                      $(this.imageViewerArr[x].osdObj.canvas).css({ "opacity": 1 });
                    }
                    $(".modalDCLoader").removeClass("modalDCLoaderVisible");
                    // this.dialogRef.close();
                    localStorage.isViewerInitialized = true;
                    this.consultationViewerService.setImageViewerArr(this.imageViewerArr);
                    this.consultationViewerService.focusChanged(this.selectedViewerIndex);
                  }
                }, 1500);

                this.consultationViewerService.viewerInfocus.subscribe((value) => {
                  this.setCssForSelectedViewer(value);
                  this.selectedViewerIndex = value;
                  this.imageViewer = this.imageViewerArr[value];
                  //this.osdObj = this.imageViewer.getOsdObject();
                  this.osdObj = this.imageViewer.osdObj;
                  this.selectedViewerSchema = this.imageViewer.getViewerSchema();
                  this.setMouseTracker(this.osdObj)
                  this.maxZoomLevel = this.selectedViewerSchema.imageProfiles.imageProfile[(this.osdObj && this.osdObj._sequenceIndex) ? this.osdObj._sequenceIndex : 1].resolution;
                  this.finalZoomIndexArr = this.initZoomIndexArr.filter((zoomIndex) => {
                    return zoomIndex <= this.maxZoomLevel;
                  });

                  if (this.finalZoomIndexArr[this.finalZoomIndexArr.length - 1] != this.maxZoomLevel) {
                    this.finalZoomIndexArr.push(this.finalZoomIndexArr);
                  }

                  for (let x = 0; x < this.imageViewerArr.length; x++) {
                    if (this.imageViewerArr[x] && this.imageViewerArr[x].osdObj) {
                      this.imageViewerArr[x].osdObj.setMouseNavEnabled(this.selectedViewerIndex == x);
                    }
                  }
                  // this.osdObj?.setMouseNavEnabled(true);
                  this.activeImageViewer = this.openseadragonIDS[value];
                  this.threeJsObj = this.imageViewer.threeJsObj;
                  this.threeJsObj.schemaMaxZoomLevel = this.maxZoomLevel;
                  this.annotationService.threeJsObj = this.imageViewerArr[this.selectedViewerIndex].threeJsObj;
                  this.annotationService.osdObj = this.imageViewer.getOsdObject();

                  setTimeout(() => {
                    $(".joystickRotationNavigator .zoomingIndexDiv .zoomingIndexItem").css("width", (100 / (this.finalZoomIndexArr.length + 1)) + "%");
                  }, 0);
                });

                // this.viewerService.rotationValue.subscribe(value => {
                //   // this.setRotationValue(value);
                //   this.setRotationToViewers(value);
                // });

                if (this.isPresenter) {
                  setTimeout(() => {
                    this.isViewerInitialized = true;
                    localStorage.isViewerInitialized = true;
                    $(".modalDCLoader").removeClass("modalDCLoaderVisible");
                  }, 3000);
                } else {
                  this.isViewerInitialized = true;
                  localStorage.isViewerInitialized = true;
                  $(".modalDCLoader").removeClass("modalDCLoaderVisible");
                }
              }
              //});

            }
          });// end image viewer callback
      });// end get slide details
    }
  }

  deleteAllAnnoSuccess() {
    if (this.threeJsObj && this.threeJsObj.scene) {
      for (var i = this.threeJsObj.scene.children.length - 1; i >= 0; i--) {
        let obj = this.threeJsObj.scene.children[i];
        this.threeJsObj.scene.remove(obj);
        obj.geometry.dispose();
        obj.material.dispose();
      }

      this.threeJsObj.renderer3.render(this.threeJsObj.scene, this.threeJsObj.camera);
      this.threeJsObj.camera.updateMatrixWorld();

      $("#" + this.osdObj.id + "-annoHighlight").css({ "width": 0, "height": 0, "left": 0, "top": 0 }).hide();
    }
  }

  loadAnnotations(slidePk: number, osdObj: any) {
    this.deleteAllAnnoSuccess();
    this.viewerService.getAllDCAnnotation(this.meetingDetails.meeting_pk, slidePk).subscribe((response: any) => {
      response.data.forEach(element => {
        element.slideId = slidePk;
        element.meetId = this.meetingDetails.meeting_pk;
      });
      this.allAnnotationArr = response.data;
      let osdObjIndex = Number(osdObj.id.replace("openseadragon_", ""));
      this.imageViewerArr[osdObjIndex].annotationsArr = response.data;
      this.annotationService.resetNewCreateAnnotationInfo({});

      let threeJsObj = this.imageViewerArr[Number(osdObj.id.replace("openseadragon_", ""))].threeJsObj;
      this.annotationService.threeJsAnnotationSetup(response.data, osdObj, threeJsObj);
      this.annotationService.setOnlyAnnotations(response.data);
      setTimeout(() => {
        this.isPerformingTask = false;
      }, 500);
    });
  }

  NavigatorRedraw() {
    this.osdObj.navigator?.update(this.osdObj.viewport);
    this.osdObj.navigator?.forceRedraw();
  }

  setMouseTracker(osdObj) {
    let isMouseInOsd;
    new OpenSeadragon.MouseTracker({
      // element: this.openseadragonIDS[this.selectedViewerIndex],
      element: osdObj.id,
      enterHandler: (ev) => {
        isMouseInOsd = this.isPresenter && true;
      },
      leaveHandler: (ev) => {
        isMouseInOsd = false;
      },
      moveHandler: (ev) => {
        if (isMouseInOsd && this.isPresenter && this.meetingDetails.meeting_pk && (ev.eventSource.element.id == osdObj.id)) {
          //changes done for Remote Consulting start
          var webPoint = ev.position;
          // Convert that to viewport coordinates, the lingua franca of OpenSeadragon coordinates.
          var viewportPoint = osdObj.viewport.pointFromPixel(webPoint);

          // Convert from viewport coordinates to image coordinates.
          var imagePoint = osdObj.viewport.viewportToImageCoordinates(viewportPoint);

          this.consultationViewerService.emitEvent('mouse-events', {
            meetID: this.meetingDetails.meeting_pk,
            clientID: this.currentUser.userName,
            mouseX: imagePoint.x,
            mouseY: imagePoint.y
          });
        }
      }
    });
  }

  /* fine tune functions start */
  resetViewerMinPixelRatio(osdObj: any, reset: boolean) {
    if (!osdObj || osdObj.world._items.length == 0) {
      return;
    }
    let newMinPixelRatio;
    let appConfigMinPixelRatio = ((this.imageViewerArr.length == 1) ? this.config.tileMinPixelRatio : this.config.tileMinPixelRatioForCompare);
    if (osdObj.world._items[0].minPixelRatio != appConfigMinPixelRatio) {
      newMinPixelRatio = appConfigMinPixelRatio;
    } else {
      return;
    }
    osdObj.minPixelRatio = newMinPixelRatio;
    osdObj.world._items[0].minPixelRatio = newMinPixelRatio;
    osdObj.world.update();
    if (reset) {
      osdObj.world.resetItems();
    }
  }

  resetFineTune() {
    if (!this.osdObj.world || !this.osdObj.world._items || this.osdObj.world._items.length == 0) {
      return;
    }
    let newMinPixelRatio;
    if ($(".fineTune #imgFineTuneOn:visible").length > 0) {
      console.log("Fine tune icon visible");
      newMinPixelRatio = this.config.tileMinPixelRatio;
      $(".fineTune img").toggle();
      this.consultationViewerService.setFineFocusMode(false);
    } else {
      console.log("Fine tune icon not visible");
      if (this.osdObj.world._items[0].minPixelRatio != this.config.tileMinPixelRatio) {
        newMinPixelRatio = this.config.tileMinPixelRatio;
      } else {
        return;
      }
    }

    console.log("reset fine tune");
    this.osdObj.minPixelRatio = newMinPixelRatio;
    this.osdObj.world._items[0].minPixelRatio = newMinPixelRatio;
    this.osdObj.world.update();
    this.osdObj.world.resetItems();
  }

  checkFineTuneCanEnable() {
    if (this.selectedROI != this.osdObj._sequenceIndex) {
      this.fineTuneEnableCheckObj = {};
      this.selectedROI = this.osdObj._sequenceIndex;
      this.fineTuneAppliedLevel = [];
      this.fineTuneNonAppliedLevel = [];
      this.fineTuneEnableCheckObj.selectedImageViewer = this.imageViewerArr[parseInt($("#" + this.osdObj.id).attr("index"))];
      this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI = this.fineTuneEnableCheckObj.selectedImageViewer.schemaImageProfilePerROI[this.osdObj._sequenceIndex];

      this.fineTuneEnableCheckObj.maxZoomAppliedLevel = (this.maxZoomLevel * 0.15);
      this.fineTuneEnableCheckObj.maxZoomNonAppliedLevel = (this.maxZoomLevel * this.config.tileMinPixelRatio);
      for (var i = 0; i < this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI.length; i++) {
        /* var currZoomAppliedLevelObj = {
          "maxZoomLevel": (maxZoomAppliedLevel * currSchemaImageProfilePerROI[i]),
          "minZoomLevel": ((i < (currSchemaImageProfilePerROI.length - 1)) ? (maxZoomAppliedLevel * currSchemaImageProfilePerROI[i + 1]) : 0),
          "scaleLevel": currSchemaImageProfilePerROI[i],
          "prevLevelScaleLevel": ((i < (currSchemaImageProfilePerROI.length - 1)) ? currSchemaImageProfilePerROI[i + 1] : 0),
        } */
        this.fineTuneEnableCheckObj.currZoomAppliedLevelObj = {
          "maxZoomLevel": (i == 0) ? this.maxZoomLevel : (this.fineTuneEnableCheckObj.maxZoomAppliedLevel * this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i - 1]),
          "minZoomLevel": (this.fineTuneEnableCheckObj.maxZoomAppliedLevel * this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i]),
          "scaleLevel": this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i],
          "prevLevelScaleLevel": ((i < (this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI.length - 1)) ? this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i + 1] : 0),
        }
        this.fineTuneAppliedLevel.push(this.fineTuneEnableCheckObj.currZoomAppliedLevelObj);

        this.fineTuneEnableCheckObj.currZoomNonAppliedLevelObj = {
          "maxZoomLevel": (i == 0) ? this.maxZoomLevel : (this.fineTuneEnableCheckObj.maxZoomNonAppliedLevel * this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i - 1]),
          "minZoomLevel": (this.fineTuneEnableCheckObj.maxZoomNonAppliedLevel * this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i]),
          "scaleLevel": this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i],
          "prevLevelScaleLevel": ((i < (this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI.length - 1)) ? this.fineTuneEnableCheckObj.currSchemaImageProfilePerROI[i + 1] : 0),
        }
        this.fineTuneNonAppliedLevel.push(this.fineTuneEnableCheckObj.currZoomNonAppliedLevelObj);
      }
    }

    // var CurrentNonAppliedTileLevel, CurrentAppliedTileLevel;
    this.fineTuneEnableCheckObj.currentScaleLevel = this.osdObj.viewport.viewportToImageZoom(this.osdObj.viewport.getZoom());
    this.fineTuneEnableCheckObj.currentZoomLevel = (this.maxZoomLevel * this.fineTuneEnableCheckObj.currentScaleLevel);
    for (var i = 0; i < this.fineTuneNonAppliedLevel.length; i++) {
      var item1 = this.fineTuneNonAppliedLevel[i];
      // if (item1.prevLevelScaleLevel <= this.fineTuneEnableCheckObj.currentScaleLevel && this.fineTuneEnableCheckObj.currentScaleLevel < item1.scaleLevel) {
      if (item1.minZoomLevel <= this.fineTuneEnableCheckObj.currentZoomLevel && this.fineTuneEnableCheckObj.currentZoomLevel < item1.maxZoomLevel) {
        this.fineTuneEnableCheckObj.CurrentNonAppliedTileLevel = item1;
      }
    }
    for (var i = 0; i < this.fineTuneAppliedLevel.length; i++) {
      var item2 = this.fineTuneAppliedLevel[i];
      // if (item2.prevLevelScaleLevel <= this.fineTuneEnableCheckObj.currentScaleLevel && this.fineTuneEnableCheckObj.currentScaleLevel < item2.scaleLevel) {
      if (item2.minZoomLevel <= this.fineTuneEnableCheckObj.currentZoomLevel && this.fineTuneEnableCheckObj.currentZoomLevel < item2.maxZoomLevel) {
        this.fineTuneEnableCheckObj.CurrentAppliedTileLevel = item2;
      }
    }

    // var fineTuneEnable = ((CurrentNonAppliedTileLevel.maxZoomLevel > CurrentAppliedTileLevel.maxZoomLevel) && (CurrentNonAppliedTileLevel.minZoomLevel > CurrentAppliedTileLevel.minZoomLevel));
    // this.fineTuneEnableCheckObj.fineTuneEnable = !((this.fineTuneEnableCheckObj.currentZoomLevel < this.fineTuneEnableCheckObj.CurrentAppliedTileLevel.maxZoomLevel) &&
    // (this.fineTuneEnableCheckObj.currentZoomLevel > this.fineTuneEnableCheckObj.CurrentAppliedTileLevel.minZoomLevel));
    this.fineTuneEnableCheckObj.fineTuneEnable = (this.fineTuneEnableCheckObj.CurrentNonAppliedTileLevel.scaleLevel != this.fineTuneEnableCheckObj.CurrentAppliedTileLevel.scaleLevel);
    this.consultationViewerService.setFineFocusEnable(this.fineTuneEnableCheckObj.fineTuneEnable);

  }

  changeMinPixelRatio(event: any, action: string) {
    if (action == "decrease" && this.sliderMinPixelValue > 1) {
      this.sliderMinPixelValue = this.sliderMinPixelValue - 1;
    }

    if (action == "increase" && this.sliderMinPixelValue < 10) {
      this.sliderMinPixelValue = this.sliderMinPixelValue + 1;
    }

    if (event.checked) {
      this.sliderMinPixelValue = 1;
    } else {
      this.sliderMinPixelValue = (this.config.tileMinPixelRatio ? this.config.tileMinPixelRatio : 0.5);
    }

    var sliderMinPixelRatio = this.sliderMinPixelValue / 10;
    this.osdObj.minPixelRatio = sliderMinPixelRatio;
    this.osdObj.world._items[0].minPixelRatio = sliderMinPixelRatio;
    this.osdObj.world.update();
    this.osdObj.world.resetItems();
  }

  onResize(event) {
    // console.log('innerWidth: '+event.target.innerWidth);
    // this.zoomLevel = this.osdObj.viewport.getZoom(true);
    this.osdObj?.world?.update();
    this.resetScaleBarPos();

    if ($(".chatList").length > 0) {
      $(".chatList").height($(".chatMainDiv").height() - $(".sendMsg").height() - $(".chatTitle").height() - this.commonService.vwTOpx('1vw'));
    }

    if ($("app-chat").find(".chatArrow").hasClass("closeArrow")) {
      $("app-participant-list").height($(".mainRightBar").height() - $("app-chat").height());
    } else {
      $("app-participant-list").height($("app-participant-list").hasClass("pinUnlockedDiv") ? "calc(60vh - 3.08vw)" : "60vh");
    }

    // setTimeout(() => {
    //   this.osdObj.viewport.zoomTo(this.zoomLevel);      
    // }, 200);
  }

  resetScaleBarPos() {
    if (!this.scalebarIDS) {
      return;
    }
    for (let x = 0; x < this.scalebarIDS.length; x++) {
      if (this.imageStatusIds[x] && ($('#' + this.imageStatusIds[x]).closest(".imageStatusScalebarParent").next('.openseadragon-container').find(".navigator").length > 0)) {
        $('#' + this.imageStatusIds[x]).css({
          // "bottom": this.commonService.vwTOpx('0.4vw') + 6 + $('#' + this.imageStatusIds[x]).next(".scalebar").height() + ($('#' + this.imageStatusIds[x]).parent(".viewer").find(".navigator").height()),
          "width": $('#' + this.imageStatusIds[x]).closest(".imageStatusScalebarParent").next('.openseadragon-container').find(".navigator").width() + 4
        });
      }

      if ($('#' + this.scalebarIDS[x]).closest('.imageStatusScalebarParent').next('.openseadragon-container').find('.navigator').length > 0) {
        $('#' + this.scalebarIDS[x]).css({
          'width': ($('#' + this.scalebarIDS[x]).closest('.imageStatusScalebarParent').next('.openseadragon-container').find('.navigator').width() + 4),
          // 'bottom': ($('#' + this.scalebarIDS[x]).next('.openseadragon-container').find('.navigator').height() + 9),
          'padding': '2px'
        });

        var navigator = $('#' + this.scalebarIDS[x]).closest(".imageStatusScalebarParent").next('.openseadragon-container').find('.navigator:visible');
        $("#imageStatusScalebarParent_" + x).css({
          "bottom": (navigator.length > 0) ? ($(navigator).height() + 9) : 9
        });
        if (this.imageViewerArr[x]?.osdObj?.navigator?.canvas) {
          $("#" + this.scalebarIDS[x] + " .scalebarMainDiv").width((this.imageViewerArr[x].osdObj.navigator.canvas.offsetWidth - 100) + 'px');
        }
      } else {
        $('#' + this.scalebarIDS[x]).css({
          'width': ($('#' + this.imageStatusIds[x]).width() + 4),
          // 'bottom': 0,
          'padding': '2px'
        });
        $("#imageStatusScalebarParent_" + x).css({
          "bottom": 0
        });
      }

      $("#imageStatusScalebarParent_" + x).show();
    }

    if (this.scalebarIDS.length == 1) {
      if (!$(".annotemanger_popup").hasClass("hideAnnoManager")) {
        $(".annotemanger_popup").show();
      }
      if ($(".annotemanger_popup").hasClass("annoteManagerOpen")) {
        $('.annotemanger_popup').css("top", ($(".contenttab").height() - ($(".annotemanger_popup").height())));
      } else {
        $(".annotemanger_popup").css("top", $(".contenttab").height() - ($(".annotemanger_popup .headmanger").height() + this.commonService.vwTOpx('1.4vw')))
      }
    }
  }

  selectedViewer(event: any) {
    /* var viewerCurrTarget = event.currentTarget;
    var currentOsdObjIndex = parseInt($('#' + this.osdObj.id).attr('index'));
    if ($(".disableTopBar:visible").length > 0 && $(viewerCurrTarget).hasClass('viewer')) {
      if (currentOsdObjIndex != parseInt($(viewerCurrTarget).attr('index'))) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.ANOTHER_VIEWER_SELECTION"));
      }
      return;
    }
    if ($(".annotationPopOver .annoEditDiv:visible").length > 0) {
      return;
    }
    if (this.isPresenter) {
      var osdObjId = $(viewerCurrTarget).attr("id");
      if ((this.osdObj.id != osdObjId) && $(viewerCurrTarget).hasClass('viewer')) {
        let index = parseInt($('#' + osdObjId).attr('index'));
        this.consultationViewerService.focusChanged(index)
        if (!this.isSynch) {
          var currentViewportZoom = this.imageViewerArr[index].osdObj.viewport.getZoom();
          let zoom = this.osdObj.viewport.viewportToImageZoom(currentViewportZoom);
          let currentZoomText = parseFloat((zoom * this.maxZoomLevel).toFixed(2));

          // let zoom = this.imageViewerArr[index].osdObj.viewport.getZoom();
          // // this.sliderRangeValue = (currentZoomText > 1) ? currentZoomText : 1;
          // this.sliderRangeValue = currentZoomText;


          let currentRotation = this.imageViewerArr[index].osdObj.viewport.degrees;
          this.setRoundSliderValue(currentRotation);
          // this.joystickNavZoomToIndex(event,zoom);
        }
        $('.scalebar .dot').hide();
        $(".viewer").css({ "border": "1px solid #848484", "padding": "2px" });
        if (this.imageViewerArr.length > 1) {
          $(viewerCurrTarget).css({ "border": "3px solid #F47625", "padding": "0px" });
        }
        $(viewerCurrTarget).find('.scalebar .dot').show();
      }
    } */
    if (this.isPresenter) {
      let currentViewer = event.currentTarget;
      let index: number;
      if ($(currentViewer).attr('id') != this.osdObj.id) {
        index = Number($(currentViewer).attr('index'));
        this.selectedViewerIndex = index;
        var currentViewportZoom = this.imageViewerArr[index].osdObj.viewport.getZoom();
        let currMaxZoomLevel = this.imageViewerArr[index].schemaObj.imageProfiles.imageProfile[1].resolution;
        let zoom = this.imageViewerArr[index].osdObj.viewport.viewportToImageZoom(currentViewportZoom * currMaxZoomLevel);
        this.updateImageZoomStatus(zoom);

        let currentRotation = this.imageViewerArr[index].osdObj.viewport.degrees;
        this.updateImamgeRotationStatus(currentRotation)

        this.consultationViewerService.focusChanged(index);
        if ($.isNumeric(index)) {
          this.consultationViewerService.emitEvent('select-viewer', {
            meetID: this.meetingDetails.meeting_pk,
            clientID: this.currentUser.userName,
            slideID: parseInt(this.currentSlideId),
            selectedIndex: this.selectedViewerIndex
          });
        }
      }
    }
  }

  private setCssForSelectedViewer(index) { // private function to show css over selected image
    $('.scalebar .dot').hide();
    $(".viewer").css({ "border": "1px solid #848484", "padding": "2px" });
    if (this.imageViewerArr.length > 1) {
      $('#' + this.openseadragonIDS[index]).css({ "border": "3px solid #F47625", "padding": "0px" });
    }
    $('#' + this.scalebarIDS[index]).find('.dot').show()
  }

  private updateImageZoomStatus(zoom: number) { // private funciton to update image zoom status on status bar on navigator
    $('#' + this.imageStatusIds[this.selectedViewerIndex]).find('.imageStatusZoom').html("Zoom: " + zoom.toFixed(2) + "x");
    this.sliderRangeValue = Number(zoom.toFixed(2));
  }

  private updateImamgeRotationStatus(rotation: number) { // private funciton to update image rotation status on status bar on navigator
    $('#' + this.imageStatusIds[this.selectedViewerIndex]).find('.imageStatusRotation').html("Rotation: " + rotation + "&#176;");
    $('#DCroundSlider').roundSlider('setValue', rotation);
    $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html(rotation + "<span>&#176;</span>");
  }

  setRoundSliderValue(value) {
    $('#DCroundSlider').roundSlider('setValue', value);
    $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html(value + "<span>&#176;</span>");
  }

  clearData() {
    $('.viewercontent').children('.viewer').remove();
    $(".joystickRotationNavigator").find(".zoomingIndex").find("div").css("background-color", "transparent");
    this.openseadragonIDS = [];
    this.scalebarIDS = [];
    this.imageStatusIds = [];
    this.destroyOsdObjs();
    this.isViewerInitialized = false;
    localStorage.isViewerInitialized = false;
    this.isImageNotAvailable = false;
    this.imageViewer = "";
    this.imageViewerArr = [];
    this.isSynch = false;
    this.slideIds = [];
    this.selectedViewerindex = 0;
    this.consultationViewerService.setRotationValue(0);
    this.consultationViewerService.setActiveTool("");
    this.consultationViewerService.setSynchronization(false);
  }

  ngOnDestroy() {
    this.destroyOsdObjs();
    if (this.drawAnnoScreenshot) {
      this.drawAnnoScreenshot.unsubscribe();
    }
    this.viewerService.drawScreenShots('');

    if (this.synchronizationObs) {
      this.synchronizationObs.unsubscribe();
      this.commonService.setSynchronization(false);
    }
    if (this.addThreeJsNewAnnotationSubscribe) {
      this.addThreeJsNewAnnotationSubscribe.unsubscribe();
      this.commonService.threeJsNewAnnotationAdded({});
    }
    this.commonService.focusChanged(0);
    $(".modalDCLoader").removeClass("modalDCLoaderVisible");
    this.consultationViewerService.setRotationValue(0);
  }

  updateZoom = (furtherAction: boolean = false) => {
    // this.checkFlip();
    // this.checkTileLevel();
    if (!this.osdObj || (this.imageViewerArr.length == 0)) {
      return;
    }
    this.selectedViewerindex = Number($('#' + this.osdObj.id).attr('index'));
    var selectedOsdObj = this.osdObj;
    var currentViewportZoom = this.osdObj.viewport.getZoom(true);
    var selectedViewerZoom = parseFloat(this.osdObj.viewport.viewportToImageZoom(currentViewportZoom * this.maxZoomLevel).toFixed(2));
    this.updateImageZoomStatus(selectedViewerZoom);
    if (this.isSynch) {
      let osdCoOrdinates = this.osdObj.viewport.getCenter();
      for (let a = 0; a < this.imageViewerArr.length; a++) {
        if (selectedOsdObj.id != this.imageViewerArr[a].osdObj.id) {
          let currMaxZoomLevel = this.imageViewerArr[a].schemaObj.imageProfiles.imageProfile[1].resolution;

          let currVPHomeZoom = this.imageViewerArr[a].osdObj.viewport.minZoomLevel;
          let currImgHomeZoom = parseFloat(this.imageViewerArr[a].osdObj.viewport.viewportToImageZoom(currVPHomeZoom * currMaxZoomLevel).toFixed(2));

          if (selectedViewerZoom >= currImgHomeZoom) {
            let imageToViewPortZoomValue = this.imageViewerArr[a].osdObj.viewport.imageToViewportZoom(selectedViewerZoom / this.maxZoomLevel);
            this.imageViewerArr[a].osdObj.viewport.zoomTo(imageToViewPortZoomValue);
            $('#' + this.imageViewerArr[a].osdObj.id).find(".imageStatusZoom").html("Zoom: " + selectedViewerZoom + "x");
            $("#" + this.imageViewerArr[a].osdObj.id).find(".fitToScreenParent").addClass("dn");
          } else {
            let currVPZoom = this.imageViewerArr[a].osdObj.viewport.getZoom(true);
            let currImgZoom = parseFloat(this.imageViewerArr[a].osdObj.viewport.viewportToImageZoom(currVPZoom * currMaxZoomLevel).toFixed(2));

            if (currImgZoom != currImgHomeZoom) {
              this.imageViewerArr[a].osdObj.viewport.goHome();
              $('#' + this.imageViewerArr[a].osdObj.id).find(".imageStatusZoom").html("Zoom: " + currImgHomeZoom + "x");
              $("#" + this.imageViewerArr[a].osdObj.id).find(".fitToScreenParent").removeClass("dn");
            }
          }
          this.imageViewerArr[a].osdObj.viewport.panTo(osdCoOrdinates);
        }
      }
    }
    let homeZoom = parseFloat(this.osdObj.viewport.getHomeZoom().toFixed(2));
    let currZoom = parseFloat(currentViewportZoom.toFixed(2));

    if (homeZoom == currZoom) {
      $("#" + this.osdObj.id).find(".fitToScreenParent").removeClass("dn");
    } else {
      $("#" + this.osdObj.id).find(".fitToScreenParent").addClass("dn");
    }

    if (this.isPresenter) {
      this.consultationViewerService.emitEvent('image-events', {
        meetID: this.meetingDetails.meeting_pk,
        clientID: this.currentUser.userName,
        // slideID: parseInt(this.currentSlideId),
        // zoomValue: zoom.toFixed(2),
        zoomValue: selectedViewerZoom,
        coordinates: selectedOsdObj.viewport.getCenter(),
        rotationValue: selectedOsdObj.viewport.getRotation(),
        viewerIndex: this.selectedViewerindex
        // selectedViewerindex: this.selectedViewerindex
      });
    } /* else {
      selectedOsdObj?.setMouseNavEnabled(false);
      selectedOsdObj.navigator?.innerTracker?.setTracking(false);
    } */

    if (!furtherAction) {
      return;
    }

    // this.sliderRangeValue = (Math.round(selectedViewerZoom));
    $(".joystickRotationNavigator").find(".zoomingIndexDiv").find(".zoomingIndexItem").css("background-color", "transparent");
    if (this.zoomIndexClicked) {
      $(".joystickRotationNavigator").find(".zoomingIndexDiv").find("." + this.zoomIndexClicked).css("background-color", "#EA8823");
      this.zoomIndexClicked = null;
    }
    /* if (this.imageViewer) {
      this.consultationViewerService.setZoomValue(this.sliderRangeValue.toFixed(2));
      $('#' + this.imageViewer.osdObj.id).find(".imageStatusZoom").html("Zoom: " + this.sliderRangeValue.toFixed(2) + "x");
    } */


  }

  viewerResize = (osdObj, id) => {
    $(id).css({
      "top": ($(osdObj.element).height() - ($(osdObj.navigator.element).parent("div").height() + $(id).height())),
      "left": ($(osdObj.element).width() - $(osdObj.navigator.element).parent("div").width()),
      "width": $(osdObj.navigator.element).parent("div").width(),
      "height": $(id).height()
    });

    if (id == "#openseadragon2NavBar") {
      $(id).css({
        "left": (($(this.osdObj.element).width() + $(osdObj.element).width()) - $(osdObj.navigator.element).parent("div").width()),
      });
    }
  }

  fitToScreen = (event) => {
    let homeZoom = this.osdObj.viewport.getHomeZoom();
    let currZoom = this.osdObj.viewport.getZoom();
    if (homeZoom.toFixed(2) == currZoom.toFixed(2)) {
      return;
    }
    $(event.currentTarget).closest(".joystickRotationNavigator").find(".zoomingIndexDiv").find(".zoomingIndexItem").css("background-color", "transparent");
    this.osdObj.viewport.goHome();
    $("#" + this.osdObj.id).find(".fitToScreenParent").removeClass("dn");
    this.zoomIndexClicked = "fitToScreen";
    $(".joystickRotationNavigator").find(".zoomingIndexDiv").find("." + this.zoomIndexClicked).css("background-color", "#EA8823");
  }

  joystickNavZoomToIndex = (event, text) => {
    //$(event.currentTarget).closest(".joystickRotationNavigator").find(".zoomingIndex").find("div").css("background-color", "transparent");
    // if (typeof text == 'number') {
    //   this.zoomIndexClicked = text + "x";
    //   $(".joystickRotationNavigator").find(".zoomingIndex").find("." + this.zoomIndexClicked).css("background-color", "#EA8823");
    // }
    this.consultationViewerService.zoomToIndex(text, this.sliderRangeValue, event);
  }

  sliderRangeValueText(sliderVal: any) {
    console.log(sliderVal)
    let strSliderVal = sliderVal;
    if (typeof strSliderVal == 'number') {
      strSliderVal = strSliderVal.toFixed(2);
    }

    $(".joystickRotationNavigator").find(".zoomingIndex").find("div").css("background-color", "transparent");
    $(".joystickRotationNavigator").find(".zoomingIndex").find("." + parseFloat(strSliderVal) + 'x').css("background-color", "#EA8823");

    return strSliderVal;
  }

  moveByAngleMain = (evt, nipple) => {
    this.moveByAngle(evt, nipple);
    clearInterval(this.joystickMove);
    this.joystickMove = setInterval(() => {
      this.moveByAngle(evt, nipple);
    }, 0);
  }
  moveByAngle = (evt, nipple) => {
    var angle = nipple.angle.degree;
    var distance = nipple.distance;
    var X = (distance * Math.cos(angle * (22 / 7) / 180));
    var Y = (distance * -Math.sin(angle * (22 / 7) / 180));

    if (this.osdObj && this.osdObj.viewport) {
      this.osdObj.viewport.panBy(this.osdObj.viewport.deltaPointsFromPixels(new OpenSeadragon.Point(X, Y)));
      this.osdObj.viewport.applyConstraints();
    }
  }

  stopMoving = () => {
    clearInterval(this.joystickMove);
  }

  /* private setRotationValue(value) {
    $('#DCroundSlider').roundSlider('setValue', value);
    if (value == 0) {
      if (!this.isSplitViewEnabled) {
        if (this.currentNavigatorArrowState === 'close') {
          $("#osdnavArrowDiv").closest(".joystickRotationNavigatorToggle").show();
          $("#osdnavArrowDiv").trigger("click");
        }
      } else {
        if (this.osdObj.id == "openseadragon1" || this.isSynch) {
          if (this.currentNavigatorArrowState1 === 'close') {
            $("#osdnavArrowDiv1").closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv1").trigger("click");
          }
        }
        if (this.osdObj.id == "openseadragon2" || this.isSynch) {
          if (this.currentNavigatorArrowState2 === 'close') {
            $("#osdnavArrowDiv2").closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv2").trigger("click");
          }
        }
        if (this.osdObj.id == "openseadragon3" || this.isSynch) {
          if (this.currentNavigatorArrowState3 === 'close') {
            $("#osdnavArrowDiv3").closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv3").trigger("click");
          }
        }
        if (this.osdObj.id == "openseadragon4" || this.isSynch) {
          if (this.currentNavigatorArrowState4 === 'close') {
            $("#osdnavArrowDiv4").closest(".joystickRotationNavigatorToggle").show();
            $("#osdnavArrowDiv4").trigger("click");
          }
        }
      }
    } else {
      if (!this.isSplitViewEnabled) {
        if (this.currentNavigatorArrowState === 'open') {
          $("#osdnavArrowDiv").trigger("click");
        }
        $("#osdnavArrowDiv").closest(".joystickRotationNavigatorToggle").hide();
      } else {
        if (this.osdObj.id == "openseadragon1" || this.isSynch) {
          if (this.currentNavigatorArrowState1 === 'open') {
            $("#osdnavArrowDiv1").trigger("click");
          }
          $("#osdnavArrowDiv1").closest(".joystickRotationNavigatorToggle").hide();
        }
        if (this.osdObj.id == "openseadragon2" || this.isSynch) {
          if (this.currentNavigatorArrowState2 === 'open') {
            $("#osdnavArrowDiv2").trigger("click");
          }
          $("#osdnavArrowDiv2").closest(".joystickRotationNavigatorToggle").hide();
        }
        if (this.osdObj.id == "openseadragon3" || this.isSynch) {
          if (this.currentNavigatorArrowState3 === 'open') {
            $("#osdnavArrowDiv3").trigger("click");
          }
          $("#osdnavArrowDiv3").closest(".joystickRotationNavigatorToggle").hide();
        }
        if (this.osdObj.id == "openseadragon4" || this.isSynch) {
          if (this.currentNavigatorArrowState4 === 'open') {
            $("#osdnavArrowDiv4").trigger("click");
          }
          $("#osdnavArrowDiv4").closest(".joystickRotationNavigatorToggle").hide();
        }
      }
    }

    $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html(value + "<span>&#176;</span>");
  } */

  setRotationToViewers(value: number) {
    if (this.isSynch) { // for multiple viewers and in synchronization
      this.imageViewerArr.forEach((viewer) => {
        viewer.osdObj.viewport.setRotation(value);
        this.rotateAnnotations(value, viewer.threeJsObj);
        this.updateImamgeRotationStatus(value)
        // $('#' + viewer.osdObj.id).find(".imageStatusRotation").html("Rotation: " + value + " &#176;");
      });
      this.setImageMinMaxZoomLevel();
    } else { // for single viewer or multiple viewer but not in synchronization
      if (!this.osdObj) {
        return;
      }
      this.osdObj.viewport.getRotation() != value ? this.osdObj.viewport.setRotation(value) : '';
      this.rotateAnnotations(value, this.imageViewer.threeJsObj);
      this.updateImamgeRotationStatus(value)
      // $('#' + this.osdObj.id).find(".imageStatusRotation").html("Rotation: " + value + " &#176;");
      this.setImageMinMaxZoomLevel(this.osdObj);

      let homeZoom = parseFloat(this.osdObj.viewport.minZoomLevel.toFixed(2));
      let currZoom = parseFloat(this.osdObj.viewport.getZoom(true).toFixed(2));

      if (homeZoom == currZoom) {
        $("#" + this.osdObj.id).find(".fitToScreenParent").removeClass("dn");
      } else {
        $("#" + this.osdObj.id).find(".fitToScreenParent").addClass("dn");
      }

      if (currZoom < homeZoom) {
        this.osdObj.viewport.goHome();
      }
    }
    // $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html(value + "<span>&#176;</span>");
  }

  rotateAnnotations(value, threeJsObj: any) {
    if (!threeJsObj) {
      return;
    }
    threeJsObj.camera.rotation.z = value * Math.PI / 180;
    threeJsObj.renderer3.render(threeJsObj.scene, threeJsObj.camera);
    threeJsObj.camera.updateMatrixWorld();
  }

  private loadAfterOSD() {
    var options = {
      zone: document.getElementById('zone_joystick'),
      size: ((this.commonService.vwTOpx("3.5") > 55) ? this.commonService.vwTOpx("3.5") : 55),
      color: "#15447E",
      position: { left: '50%', top: '50%' },
      mode: 'static'
    };
    var manager = nipplejs.create(options);
    manager.on('move', this.moveByAngleMain);
    manager.on('end', this.stopMoving);

    manager[0].ui.back.style.background = "#fff";
    manager[0].ui.back.style.opacity = 0.9;
    manager[0].ui.back.style.boxShadow = "0px 0px 7px 1px #aaa";

    manager[0].ui.front.style.background = "rgb(255, 255, 255)";
    manager[0].ui.front.style.opacity = 1;
    manager[0].ui.front.style.boxShadow = "inset #aaa 0px 0px 7px 1px";
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);


    //var roundSliderVal = (this.responseObj && this.responseObj != "" && this.responseObj[this.selectedViewerindex].rotationValue) ? this.responseObj[this.selectedViewerindex].rotationValue : 0;
    $("#DCroundSlider").roundSlider({
      min: 0,
      max: 360,
      step: 1,
      value: 0,
      radius: this.commonService.vwTOpx('1.75vw'),
      width: this.commonService.vwTOpx('0.5vw'),
      handleSize: "+10",
      startAngle: 0,
      endAngle: "+360",
      animation: true,
      showTooltip: true,
      editableTooltip: false,
      readOnly: false,
      disabled: false,
      keyboardAction: true,
      mouseScrollAction: false,
      sliderType: "default",
      circleShape: "full",
      handleShape: "round",
      lineCap: "square",

      create: (elem) => {
        // $(elem.control).find(".rs-tooltip.rs-tooltip-text").html(elem.value + "<span>&#176;</span>");
        // $('#DCroundSlider').find(".rs-inner, .rs-tooltip-text").click((e) => {
        //   console.log(e);
        //   $('#DCroundSlider').roundSlider('setValue', 0);
        //   this.setRotationToViewers(0);
        //   this.consultationViewerService.setRotationValue(0);
        //   $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html("0<span>&#176;</span>");

        //   var selectedIndex = $(this.osdObj.navigator?.container).closest(".viewer").attr("index");
        //   if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'close') {
        //     $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").show();
        //     $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
        //   }
        // });
      },
      start: () => {
        var selectedIndex = $(this.osdObj.navigator?.container).closest(".viewer").attr("index");
        if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'open') {
          $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
        }
        $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").hide();
      },
      drag: (elem) => {
        $(elem.control).find(".rs-tooltip.rs-tooltip-text").html(elem.value + "<span>&#176;</span>");
        this.setRotationToViewers(elem.value);
        this.consultationViewerService.setRotationValue(elem.value);
        if (this.isPresenter) {
          this.updateZoom();
        }
        /* if (elem.value == 0 || elem.value == 45 || elem.value == 90 || elem.value == 135 || elem.value == 180 || elem.value == 225 || elem.value == 270) {
          this.consultationViewerService.setRotationValue(elem.value);
        } */
      },
      change: (elem) => {
        if (elem) {
          $(elem.control).find(".rs-tooltip.rs-tooltip-text").html(elem.value + "<span>&#176;</span>");
          this.osdObj.viewport.setRotation(elem.value);
          this.setRotationToViewers(elem.value);
          this.consultationViewerService.setRotationValue(elem.value);
          if (this.isPresenter) {
            this.updateZoom();
          }
          var selectedIndex = $(this.osdObj.navigator?.container).closest(".viewer").attr("index");
          if (elem.value == 0) {
            if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'close') {
              $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").show();
              $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
            }
          } else {
            if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'open') {
              $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
            }
            $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").hide();
          }
        }
      },
      stop: (elem) => {
        $(elem.control).find(".rs-tooltip.rs-tooltip-text").html(elem.value + "<span>&#176;</span>");
        if (elem.value == 0) {
          if (!this.isSplitViewEnabled) {
            if (this.currentNavigatorArrowState === 'close') {
              $("#osdnavArrowDiv").closest(".joystickRotationNavigatorToggle").show();
              $("#osdnavArrowDiv").trigger("click");
            }
          } else {
            if (this.osdObj.id == "openseadragon1" || this.isSynch) {
              if (this.currentNavigatorArrowState1 === 'close') {
                $("#osdnavArrowDiv1").closest(".joystickRotationNavigatorToggle").show();
                $("#osdnavArrowDiv1").trigger("click");
              }
            }
            if (this.osdObj.id == "openseadragon2" || this.isSynch) {
              if (this.currentNavigatorArrowState2 === 'close') {
                $("#osdnavArrowDiv2").closest(".joystickRotationNavigatorToggle").show();
                $("#osdnavArrowDiv2").trigger("click");
              }
            }
            if (this.osdObj.id == "openseadragon3" || this.isSynch) {
              if (this.currentNavigatorArrowState3 === 'close') {
                $("#osdnavArrowDiv3").closest(".joystickRotationNavigatorToggle").show();
                $("#osdnavArrowDiv3").trigger("click");
              }
            }
            if (this.osdObj.id == "openseadragon4" || this.isSynch) {
              if (this.currentNavigatorArrowState4 === 'close') {
                $("#osdnavArrowDiv4").closest(".joystickRotationNavigatorToggle").show();
                $("#osdnavArrowDiv4").trigger("click");
              }
            }
          }
        }
        if (this.isPresenter) {
          this.updateZoom();
        }
      },
      tooltipFormat: null
    });

    setTimeout((() => {
      $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html(this.osdObj.viewport.getRotation() + "<span>&#176;</span>");
      $('#DCroundSlider').find(".rs-inner, .rs-tooltip-text").click((e) => {
        $('#DCroundSlider').roundSlider('setValue', 0);
        $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html(0 + "<span>&#176;</span>");
        this.setRotationToViewers(0);
        this.consultationViewerService.setRotationValue(0);
        $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html("0<span>&#176;</span>");

        var selectedIndex = $(this.osdObj.navigator?.container).closest(".viewer").attr("index");
        if ($("#osdnavArrowDiv_" + selectedIndex).attr("arrow-state") === 'close') {
          $("#osdnavArrowDiv_" + selectedIndex).closest(".joystickRotationNavigatorToggle").show();
          $("#osdnavArrowDiv_" + selectedIndex).trigger("click");
        }
      });
    }).bind(this), 100);

    /* if (this.imageViewer) {
      this.consultationViewerService.sliderRangeValue.subscribe(value => {
        // this.sliderRangeValue = value;
      });
    } */


    /* if (this.isPresenter) {
      this.updateZoom();
      if (this.osdObj) {
        this.osdObj.setMouseNavEnabled(true);
        //this.osdObj.navigator?.innerTracker?.setTracking(true);
      }
      // this.consultationViewerService.emitEvent('load-slide-image', { data: { "slideId": this.currentSlideId, "roi": 1 } });
    } else {
      if (this.osdObj) {
        this.osdObj.setMouseNavEnabled(false);
        this.osdObj.navigator?.innerTracker?.setTracking(false);
      }
      this.getRecentImageInfo();
    } */
  }

  getRecentImageInfo() {
    this.consultationViewerService.emitEvent('get-image-event', {
      'meetID': this.meetingDetails.meeting_pk,
      'clientID': this.currentUser.userName,
    });
  }

  private async getMeetingDetails(meetingId) {
    return await (this.consultationViewerService.getMeetingDetails(meetingId)).toPromise();
  }
  private async getSlideDetails(id) {
    return await (this.viewerService.getSlideDetails(id)).toPromise();
  }

  destroyOsdObjs() {
    for (let x = 0; x < this.imageViewerArr.length; x++) {
      if (this.imageViewerArr[x].osdObj) {
        this.imageViewerArr[x].osdObj.destroy();
        this.imageViewerArr[x].osdObj = null;
      }
    }
  }

  toggleJoystickRotationNavigator = () => {
    this.currentJoystickRotationNavigatorState = this.currentJoystickRotationNavigatorState === 'open' ? 'close' : 'open';
    this.currentArrowState = this.currentArrowState === 'open' ? 'close' : 'open';
  }

  /* toggleImageNavigatorOld = (event, navId) => {
    this["currentNavigatorArrowState" + navId] = this["currentNavigatorArrowState" + navId] === 'open' ? 'close' : 'open';
    var incTop = 0;
    if (this["currentNavigatorArrowState" + navId] === 'close') {
      incTop = ($(this["osdObj" + navId].navigator.element).height());
      this["osdObj" + navId].navigator.element.style.display = (this["currentNavigatorArrowState" + navId] === 'close') ? 'none' : 'inline-block';
    } else {
      incTop = -($(this["osdObj" + navId].navigator.element).height());
    }
    $(event.currentTarget).closest(".osdNavigator").animate({
      top: "+=" + incTop
    }, 500, () => {
      if (this["currentNavigatorArrowState" + navId] === 'open') {
        this["osdObj" + navId].navigator.element.style.display = 'inline-block';
      }
    })
  } */

  toggleImageNavigator = (event) => {
    event.stopImmediatePropagation();
    var currState = $(event.currentTarget).attr('arrow-state') === 'open' ? 'close' : 'open';
    $(event.currentTarget).attr('arrow-state', currState);
    var navId = $(event.currentTarget).attr('nav-id');
    var incBottom = 0;
    var currNavElem = this.imageViewerArr[navId].osdObj.navigator.element;
    if (currState === 'close') {
      incBottom = -($(currNavElem).height());
      currNavElem.style.display = (currState === 'close') ? 'none' : 'block';
    } else {
      incBottom = ($(currNavElem).height());
    }

    $(event.currentTarget).find("i").removeClass("openArrow").removeClass("closeArrow").addClass(currState == 'open' ? 'openArrow' : 'closeArrow')

    $(event.currentTarget).closest(".imageStatusScalebarParent").animate({
      bottom: "+=" + incBottom
    }, 500, () => {
      if (currState === 'open') {
        currNavElem.style.display = 'block';
      } else {
        currNavElem.style.display = 'none';
      }
    })
  }

  private initIoConnection(): void {
    this.consultationViewerService.initSocket();
  }

  private consultationonEvent() {
    this.consultationViewerService.onEvent('connect').subscribe(async () => {
      await this.setLocalStream();
      console.log("user Connected");
      $(".viewerBody").find("#disconnectUserToast").hide();

      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let isExternalFlag = false;
      this.meetingDetails.invitees.forEach(element => {
        if (element.emailId == this.currentUser.userName) {
          isExternalFlag = element.isExternal;
        }
        return;
      });
      this.consultationViewerService.emitEvent('join', {
        'meetID': this.meetingDetails.meeting_pk,
        'clientID': this.currentUser.userName,
        'displayName': this.currentUser.displayName,
        'isExternal': isExternalFlag
      });
    });

    this.consultationViewerService.onEvent('image-events').subscribe((res: any) => {
      if (!this.isBrowserTabActive) {
        return;
      }
      if (!this.imageViewerArr[res.viewerIndex]) {
        return;
      }
      var viewerOsdObj = this.imageViewerArr[res.viewerIndex].osdObj;
      var viewerIndex = res.viewerIndex;
      if (viewerOsdObj) {
        if (this.selectedViewerindex == res.viewerIndex) {
          this.consultationViewerService.zoomToIndex("number", res.zoomValue, true);
        } else {
          let zoom = viewerOsdObj.viewport.imageToViewportZoom(res.zoomValue);
          viewerOsdObj.viewport.zoomTo(zoom);
          $('#' + viewerOsdObj.id).find(".imageStatusZoom").html("Zoom: " + res.zoomValue + "x");
        }
        viewerOsdObj.viewport.panTo(res.coordinates);
        // if ($.isNumeric(res.rotationValue) && (viewerOsdObj.viewport.getRotation() != res.rotationValue)) {
        if ($.isNumeric(res.rotationValue)) {
          // viewerOsdObj.viewport.setRotation(res.rotationValue);
          this.consultationViewerService.setRotationValue(res.rotationValue);
          if ($.isNumeric(res.rotationValue) && res.rotationValue > 0) {
            if (this.isSynch) {
              if (this.slideIds.length == 2) {
                for (let x = 0; x < this.slideIds.length; x++) {
                  if ($("#osdnavArrowDiv_" + x).attr("arrow-state") === 'open') {
                    setTimeout(() => {
                      $("#osdnavArrowDiv_" + x).trigger("click");
                    }, 0);
                  }
                  $("#osdnavArrowDiv_" + x).closest(".joystickRotationNavigatorToggle").hide();
                }
              }
            } else {
              if ($("#osdnavArrowDiv_" + viewerIndex).attr("arrow-state") === 'open') {
                setTimeout(() => {
                  $("#osdnavArrowDiv_" + viewerIndex).trigger("click");
                }, 0);
              }
              $("#osdnavArrowDiv_" + viewerIndex).closest(".joystickRotationNavigatorToggle").hide();
            }
          } else {
            if (this.isSynch) {
              if (this.slideIds.length == 2) {
                for (let x = 0; x < this.slideIds.length; x++) {
                  if ($("#osdnavArrowDiv_" + x).attr("arrow-state") === 'close') {
                    $("#osdnavArrowDiv_" + x).closest(".joystickRotationNavigatorToggle").show();
                    setTimeout(() => {
                      $("#osdnavArrowDiv_" + x).trigger("click");
                    }, 0);
                  }
                }
              }
            } else {
              if ($("#osdnavArrowDiv_" + viewerIndex).attr("arrow-state") === 'close') {
                $("#osdnavArrowDiv_" + viewerIndex).closest(".joystickRotationNavigatorToggle").show();
                setTimeout(() => {
                  $("#osdnavArrowDiv_" + viewerIndex).trigger("click");
                }, 0);
              }
            }
          }
        }

        if (viewerOsdObj.innerTracker && viewerOsdObj.isMouseNavEnabled()) {
          viewerOsdObj.setMouseNavEnabled(false);
        }
        if (this.imageViewerArr?.length < 3) {
          viewerOsdObj.navigator?.innerTracker?.setTracking(false);
        }
      }
    });

    this.consultationViewerService.onEvent('get-image-event').subscribe((res: any) => {
      if (res.imageStatus) {
        this.responseObj = res.imageStatus;
        this.updateResponseObj();
      }
    });

    this.consultationViewerService.onEvent('meet-status').subscribe((res: any) => {
      $('.disabledJoystickrotationNavigator').show()
      $.each(this.participantObj, (emailId, participantObj) => {
        participantObj.isPresenter = false;
        participantObj.isJoined = false;
        participantObj.isAccessRequested = false;
      });

      if (this.presenterId != res.presenterID) {
        this.presenterId = res.presenterID;
        this.isPresenter = (res.presenterID == this.currentUser.userName);
        this.consultationViewerService.setPresenter(this.isPresenter);
      }
      this.participantObj[res.presenterID].isPresenter = true;
      if (this.isPresenter) {
        this.consultationViewerService.setActiveTool("");
        $('.disabledJoystickrotationNavigator').hide();
      }

      res.onlineUsers.forEach(element => {
        this.participantObj[element].isJoined = true;
      });
      this.consultationViewerService.setpresenterOnline(this.participantObj[res.presenterID].isJoined);

      if (this.isPresenter || this.hostId) {
        res.accessRequests.forEach(element => {
          this.participantObj[element].isAccessRequested = true;
        });
      }

      this.consultationViewerService.setParticipantUserList(this.participantObj);

      setTimeout(() => {
        $(".participanList .participantItem").attr("mute", "no");
        res.mutedUsers.forEach(element => {
          $(".participanList .participantItem[participant-emailid='" + element + "']").attr("mute", "yes");
        });

        $.each(this.participantObj, (emailId, objInfo) => {
          this.updateMicIcons(emailId);
        });
      }, 0);

      let tempArray = res.imageStatus.map((item) => { return item.slideID });
      if ((this.slideIds.length == 0) || (this.slideIds.join("-") != tempArray.join("-"))) {
        console.log("loadImageViewer")
        this.loadImageViewer(res);
      } else {
        console.log("not loadImageViewer ")
        this.responseObj = res.imageStatus;
      }
      // this.consultationViewerService.setSelectedImage(res.imageStatus.length > 0 ? res.imageStatus.map((item) => { return item.slideID }) :
      // (this.isPresenter && hostData && hostData.slideId.length > 0) ? [hostData.slideId[0]] : []);
      // this.selectedViewerindex = res.selectedViewerId;

      // if (res.isSynchronized) {
      // this.isSynch = res.isSynchronized;
      if (this.isSynch != res.isSynchronized) {
        this.consultationViewerService.setSynchronization(res.isSynchronized);
      }
      // }

      if (res.timer) {
        this.setTimer(res.timer);
      } else {
        this.timer = '';
      }
      //setTimeout(() => {
      if ($.isNumeric(res.selectedViewerId)) {
        this.selectedViewerindex = res.selectedViewerId;
        this.consultationViewerService.focusChanged(res.selectedViewerId);
      }
      // this.updateResponseObj();

      this.showWaitingLoader = !res.timer && !this.participantObj[this.hostId].isJoined && !this.participantObj[this.presenterId].isJoined;
      $(".disableTopBar, .disableRightBar").hide();
      $(".viewer .viewerDisableMask").hide();
      //}, 50);
    });

    this.consultationViewerService.onEvent('meet-status-check').subscribe((res: any) => {
      // return;
      if (this.presenterId != res.presenterID) {
        this.presenterId = res.presenterID;
        this.participantObj[res.presenterID].isPresenter = true;
      }

      if (!this.isPresenter) {
        if (res.selectedViewerId && $.isNumeric(res.selectedViewerId) && (this.selectedViewerindex != res.selectedViewerId)) {
          this.selectedViewerindex = res.selectedViewerId;
          this.consultationViewerService.focusChanged(res.selectedViewerId);
        }

        if (this.isSynch != res.isSynchronized) {
          this.consultationViewerService.setSynchronization(res.isSynchronized);
        }

        let tempArray = res.imageStatus.map((item) => { return item.slideID });
        if (this.slideIds.join("-") != tempArray.join("-")) {
          this.loadImageViewer(res);
        } else {
          this.responseObj = res.imageStatus;
          this.updateResponseObj();
        }
      }

      this.reloadParticipantObj = false;
      res.onlineUsers.forEach(element => {
        if (!this.participantObj[element].isJoined) {
          this.participantObj[element].isJoined = true;
          this.reloadParticipantObj = true;
        }
      });
      this.consultationViewerService.setpresenterOnline(this.participantObj[res.presenterID].isJoined);
      if (this.isPresenter || this.hostId) {
        res.accessRequests.forEach(element => {
          if (!this.participantObj[element].isAccessRequested) {
            this.participantObj[element].isAccessRequested = true;
            this.reloadParticipantObj = true;
          }
        });
      }
      if (this.reloadParticipantObj) {
        this.consultationViewerService.setParticipantUserList(this.participantObj);
      }

      setTimeout(() => {
        $(".participanList .participantItem").attr("mute", "no");
        res.mutedUsers.forEach(element => {
          $(".participanList .participantItem[participant-emailid='" + element + "']").attr("mute", "yes");
        });

        $.each(this.participantObj, (emailId, objInfo) => {
          this.updateMicIcons(emailId);
        });
      }, 0);

    });

    this.consultationViewerService.onEvent('join-status').subscribe((res: any) => {
      this.participantObj[res.clientID].isJoined = true;
      if (this.participantObj[res.clientID].isExternal) {
        this.participantObj[res.clientID].displayName = res.displayName;
      }
      if (res.clientID == this.hostId) {
        this.showWaitingLoader = !this.participantObj[res.clientID].isJoined;
      }
      this.consultationViewerService.setParticipantUserList(this.participantObj);
      this.commonService.showToastMsg(((this.participantObj[res.clientID].displayName) ? (this.participantObj[res.clientID].displayName) : (this.participantObj[res.clientID].emailId)) + " joined");
      if (this.timerInterval && (this.hostId == res.clientID || this.presenterId == res.clientID)) {
        this.timer = '';
        clearInterval(this.timerInterval);
      }
      if (this.participantObj[res.clientID].isPresenter) {
        this.consultationViewerService.setpresenterOnline(true);
      }
      // voice call code start
      const peerConnection = new RTCPeerConnection(this.iceServers);
      this.rtcPeerConnection[res.clientID] = peerConnection;
      this.addLocalTracks(peerConnection);
      peerConnection.ontrack = (event) => {
        this.setRemoteStream(event, res.clientID);
      }
      peerConnection.onicecandidate = (event) => {
        this.sendIceCandidate(event, res.clientID);
      };
      peerConnection.oniceconnectionstatechange = (event) => {
        if (peerConnection.iceConnectionState === "failed" ||
          peerConnection.iceConnectionState === "disconnected" ||
          peerConnection.iceConnectionState === "closed") {
          // Handle the failure
          console.log("peerConnection " + peerConnection.iceConnectionState);
          console.log(event);
        }

        if (peerConnection.iceConnectionState === "failed") {
          if (peerConnection["restartIce"]) {
            peerConnection["restartIce"]();
          } else {
            this.createOffer(peerConnection, res.clientID, true);
          }
        }

        if (peerConnection.iceConnectionState === "closed") {
          this.createOffer(peerConnection, res.clientID, true);
        }
      };

      this.createOffer(peerConnection, res.clientID, false);
      // voice call code end
    });

    this.consultationViewerService.onEvent('request-access').subscribe((res: any) => {
      this.participantObj[res.clientID].isAccessRequested = true;
      this.consultationViewerService.setParticipantUserList(this.participantObj);
      this.consultationViewerService.setAccessRequestCount(1);
    });

    this.consultationViewerService.onEvent('presenter-status').subscribe((res: any) => {
      // this.isSynch = false;
      this.presenterId = res.presenterID;
      this.consultationViewerService.setAccessRequestCount(0);
      $.each(this.participantObj, (emailId, participantObj) => {
        participantObj.isPresenter = false;
        participantObj.isAccessRequested = false;
      });
      this.participantObj[res.presenterID].isPresenter = true;
      // this.participantObj[res.presenterID].isRA = false;
      // this.isPresenter = (this.currentUser.userName == res.presenterID);
      if (this.currentUser.userName == res.presenterID) {
        this.isPresenter = true;
        console.log(this.isPresenter)
        // this.isSynch = false;
        this.consultationViewerService.setSynchronization(false);
        // $('.disabledJoystickrotationNavigator').hide();
        this.consultationViewerService.setActiveTool("");
      } else {
        this.isPresenter = false;
        // $('.disabledJoystickrotationNavigator').show();
      }
      this.consultationViewerService.setPresenter(this.isPresenter);
      this.consultationViewerService.setpresenterOnline(this.participantObj[res.presenterID].isJoined);
      if (this.participantObj[res.presenterID].displayName) {
        this.commonService.showToastMsg(this.participantObj[res.presenterID].displayName + " is now presenting")
      } else {
        this.commonService.showToastMsg(res.presenterID + " is now presenting")
      }
      // this.commonService.showToastMsg( ? this.participantObj[res.presenterID].displayName : res.presenterID  + " is now presenting");
      this.consultationViewerService.setParticipantUserList(this.participantObj);
      setTimeout(() => {
        if (this.isPresenter) {
          if (this.osdObj) {
            this.osdObj.setMouseNavEnabled(true);
            this.osdObj.navigator?.innerTracker?.setTracking(true);
          }
          $('.disabledJoystickrotationNavigator').hide();
        } else {
          if (this.osdObj) {
            this.osdObj.setMouseNavEnabled(false);
            this.osdObj.navigator?.innerTracker?.setTracking(false);
          }
          $('.disabledJoystickrotationNavigator').show();
        }
      }, 50);
    });

    this.consultationViewerService.onEvent('leave-status').subscribe((res: any) => {
      if (!this.participantObj[res.clientID].isHost) {
        this.participantObj[res.clientID].isAccessRequested = false;
      }
      this.participantObj[res.clientID].isJoined = false;
      $(".participanList .participantItem[participant-emailid='" + res.clientID + "']").attr("mute", "no");
      this.updateMicIcons(res.clientID);

      this.commonService.showToastMsg(((this.participantObj[res.clientID].displayName) ? (this.participantObj[res.clientID].displayName) : (this.participantObj[res.clientID].emailId)) + " has left the meeting");
      this.consultationViewerService.setParticipantUserList(this.participantObj);
      if (this.participantObj[res.clientID].isPresenter) {
        this.consultationViewerService.setpresenterOnline(false);
      }
      delete this.remoteStream[res.clientID];
      let audioContainer = document.getElementById('remoteAudio_' + res.clientID);
      if (audioContainer) {
        document.getElementById('voiceCall').removeChild(audioContainer);
      }
    });

    this.consultationViewerService.onEvent('image-change').subscribe((imageData: any) => {
      if (!this.isBrowserTabActive) {
        return;
      }
      if (this.slideIds.join("-") != imageData.images.join("-")) {
        this.responseObj = '';
        this.consultationViewerService.setSelectedImage(imageData.images);
      }
    });

    this.consultationViewerService.onEvent('imagelist-change').subscribe((imageData: any) => {
      this.consultationViewerService.setImageList(imageData);
    });

    this.consultationViewerService.onEvent('participantlist-change').subscribe((participantData: any) => {
      this.dummyParticipantObj = this.participantObj;

      participantData.forEach(element => {
        this.dummyParticipantObj[element.emailId] = {
          'displayName': element.displayName,
          'emailId': element.emailId,
          'isPresenter': false,
          'isHost': false,
          'isExternal': element.isExternal,
          'isJoined': false,
          'isCurrentUser': (this.currentUser.userName == element.emailId),
          // 'mute': 'no',
          // 'audio': 0
        };
        // this.updateMicIcons(element.emailId);
      });

      this.participantStatus = {};
      for (let x = 0; x < $(".participanList .participantItem").length; x++) {
        this.participantStatus[$($(".participanList .participantItem")[x]).attr("participant-emailid")] = {
          "mute": $($(".participanList .participantItem")[x]).attr("mute"),
          "audio": $($(".participanList .participantItem")[x]).attr("audio")
        }
      }

      this.participantStatusEmailId = Object.keys(this.dummyParticipantObj);
      this.participantObj = this.dummyParticipantObj;
      this.consultationViewerService.setParticipantUserList(this.participantObj);

      setTimeout(() => {
        for (let y = 0; y < this.participantStatusEmailId.length; y++) {
          if (this.participantStatus[this.participantStatusEmailId[y]]) {
            $(".participanList .participantItem[participant-emailid='" + this.participantStatusEmailId[y] + "']").attr("mute", this.participantStatus[this.participantStatusEmailId[y]]["mute"]);
            $(".participanList .participantItem[participant-emailid='" + this.participantStatusEmailId[y] + "']").attr("audio", this.participantStatus[this.participantStatusEmailId[y]]["audio"]);
          }
          this.updateMicIcons(this.participantStatusEmailId[y]);
        }
      }, 50);
    });

    this.consultationViewerService.onEvent('chat').subscribe((message: any) => {
      this.consultationViewerService.setMessages(message);
    });

    this.consultationViewerService.onEvent('annotations').subscribe((annotationObject: any) => {
      if (!this.isPresenter) {
        if (annotationObject.annoObj.dcAction == "create") {
          this.annotationService.threeJsAnnotationSetup([annotationObject.annoObj], this.osdObj, this.threeJsObj);
          this.annotationService.addAnnotation(annotationObject.annoObj);
        } else if (annotationObject.annoObj.dcAction == "edit") {
          this.annotationService.saveAnnoSuccess(annotationObject.annoObj)
        } else if (annotationObject.annoObj.dcAction == "delete") {
          this.annotationService.deleteAnnoSuccess(annotationObject.annoObj.annotationId)
        }
      }
    });

    this.consultationViewerService.onEvent('multiple-login').subscribe((res: any) => {
      //if (localStorage.isCurrentUserExternal == "true") {
      sessionStorage.msgComponentText = "Meeting disconnected, rejoin the meet";
      this.commonService.changePlace("/message");
      this.router.navigate(["/message"]);
      setTimeout(() => {
        window.location.reload();
      }, 100);
      //} else {
      //window.open('', '_self').close();
      //alert("Meeting disconnected as you joined from another device/browser");
      //}
    });

    this.consultationViewerService.onEvent('disconnect').subscribe((res: any) => {
      console.log("User Disconnected : " + res);
      this.consultationViewerService.setMuteStatus(false);
      $(".participanList .participantItem[participant-emailid='" + this.currentUser.userName + "']").attr("mute", "no");
      $(".viewerBody").find("#disconnectUserToast").show();
    });

    this.consultationViewerService.onEvent('reconnect').subscribe((res: any) => {
      console.log("User Reconnected : " + res);
    });

    this.consultationViewerService.onEvent('meet-finished').subscribe((res: any) => {
      let meetingParam = localStorage.getItem('meetingLoginParam');
      localStorage.removeItem('microphoneDeviceId');
      localStorage.removeItem('speakerDeviceId');
      localStorage.removeItem('rightBarPinLocked');
      if (localStorage.isCurrentUserExternal == "true") {
        sessionStorage.msgComponentText = "Meeting ended by the Host";
        this.commonService.changePlace("/message");
        this.router.navigate(["/message"]);
        this.authService.clearLocalStorage();
        this.authService.removeTokens();
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        alert("Meeting ended by the Host");
        if (meetingParam) {
          localStorage.setItem('meetingLoginParam', '');
          localStorage.setItem('userActiveRole', 'PATHOLOGIST');
          this.commonService.changePlace("consultation");
          this.router.navigate(["/dashboard/consultation/"]);
        } else {
          window.open('', '_self').close();
        }
      }
    });

    this.consultationViewerService.onEvent('mouse-events').subscribe((res: any) => {
      if (!this.isBrowserTabActive) {
        return;
      }
      this.cursorEvent.pageX = 0;
      this.cursorEvent.pageY = 0;
      var imagePoint = new OpenSeadragon.Point(res.mouseX, res.mouseY);
      var mousePosTarget = this.osdObj?.viewport?.imageToViewerElementCoordinates(imagePoint);
      if (this.osdObj && this.osdObj?.container) {
        let viewerOffset = $(this.osdObj.container).closest(".viewer").offset();
        this.cursorEvent.pageX = (viewerOffset.left + mousePosTarget.x) + 'px';
        this.cursorEvent.pageY = (viewerOffset.top + mousePosTarget.y) + 'px';
      }
    });

    this.consultationViewerService.onEvent('timer').subscribe((res: any) => {
      if (res.timer) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.HOST_DISCONNECTED"));
        this.setTimer(res.timer);
      } else {
        this.timer = '';
      }
    });

    this.consultationViewerService.onEvent('image-adjustment').subscribe((response: any) => {
      if (!this.isBrowserTabActive) {
        return;
      }
      if (this.osdObj) {
        this.osdObj.setFilterOptions({
          filters: {
            processors: [
              OpenSeadragon.Filters.BRIGHTNESS(response.brightness),
              OpenSeadragon.Filters.GAMMA(response.gamma),
              OpenSeadragon.Filters.CONTRAST(response.contrast)
            ]
          },
          loadMode: 'sync'
        });
      }
    });

    this.consultationViewerService.onEvent('easy-view').subscribe((response: any) => {
      if (!this.isBrowserTabActive) {
        return;
      }
      if (response.isEasyView) {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.EASY_VIEW_START"));
      } else {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.EASY_VIEW_STOP"));
      }
    });

    this.consultationViewerService.onEvent('select-viewer').subscribe((response) => {
      // if (!this.isBrowserTabActive) {
      //   return;
      // }
      this.selectedViewerindex = response.selectedIndex;
      this.consultationViewerService.focusChanged(response.selectedIndex);
      if (this.osdObj && this.osdObj.viewport) {
        let currentDeg = this.osdObj.viewport.getRotation();
        if (currentDeg != $("#DCroundSlider").roundSlider("getValue")) {
          $("#DCroundSlider").roundSlider('setValue', currentDeg);
          $('#DCroundSlider').find(".rs-tooltip.rs-tooltip-text").html(currentDeg + "<span>&#176;</span>");
        }
      }
    });

    this.consultationViewerService.onEvent('synchImages').subscribe((response: any) => {
      if (!this.isBrowserTabActive) {
        return;
      }
      // this.isSynch = response.isSynchronized;
      if (this.isSynch != response.isSynchronized) {
        this.commonService.showToastMsg("Synchronization " + ((response.isSynchronized) ? "started" : "stopped"));
        this.consultationViewerService.setSynchronization(response.isSynchronized);
      }

    });

    // voice call events
    this.consultationViewerService.onEvent('voice-offer').subscribe((res: any) => {
      const peerConnection = new RTCPeerConnection(this.iceServers);
      this.rtcPeerConnection[res.clientID] = peerConnection;
      this.addLocalTracks(peerConnection);
      peerConnection.ontrack = (event) => {
        this.setRemoteStream(event, res.clientID);
      }
      peerConnection.onicecandidate = (event) => {
        this.sendIceCandidate(event, res.clientID);
      };
      peerConnection.setRemoteDescription(new RTCSessionDescription(res.sdp))
      this.createAnswer(peerConnection, res.clientID);
    });

    this.consultationViewerService.onEvent('voice-answer').subscribe((res: any) => {
      this.rtcPeerConnection[res.clientID].setRemoteDescription(new RTCSessionDescription(res.sdp));
    });

    this.consultationViewerService.onEvent('voice-candidate').subscribe((res: any) => {
      // ICE candidate configuration.
      var candidate = new RTCIceCandidate({
        sdpMLineIndex: res.label,
        candidate: res.candidate,
      })
      this.rtcPeerConnection[res.clientID].addIceCandidate(candidate);
    });

    this.consultationViewerService.onEvent('mute').subscribe((res: any) => {
      if (this.remoteStream[res.clientID]) {
        this.remoteStream[res.clientID].getAudioTracks()[0].enabled = !res.status;
        if (this.remoteStream[res.clientID].getAudioTracks()[0].enabled) {
          // this.participantObj[res.clientID].mute = 'no';
          $(".participanList .participantItem[participant-emailid='" + res.clientID + "']").attr("mute", "no");
        } else {
          // this.participantObj[res.clientID].mute = 'yes';
          $(".participanList .participantItem[participant-emailid='" + res.clientID + "']").attr("mute", "yes");
        }
        this.updateMicIcons(res.clientID);
        // this.consultationViewerService.setParticipantUserList(this.participantObj);
      }
    });

    this.consultationViewerService.onEvent('mute-status').subscribe((res: any) => {
      if (this.remoteStream[res.clientID]) {
        this.remoteStream[res.clientID].getAudioTracks()[0].enabled = !res.status;
        if (this.remoteStream[res.clientID].getAudioTracks()[0].enabled) {
          // this.participantObj[res.clientID].mute = 'no';
          $(".participanList .participantItem[participant-emailid='" + res.clientID + "']").attr("mute", "no");
        } else {
          // this.participantObj[res.clientID].mute = 'yes';
          $(".participanList .participantItem[participant-emailid='" + res.clientID + "']").attr("mute", "yes");
        }
        this.updateMicIcons(res.clientID);
      }
    });

    this.consultationViewerService.onEvent('enableFineTune').subscribe((res: any) => {
      if (this.osdObj) {
        // this.fineTuneMode = status;
        let newMinPixelRatio = res.fineTuneStatus ? 0.15 : (this.config.tileMinPixelRatio ? this.config.tileMinPixelRatio : 0.5);

        this.osdObj.minPixelRatio = newMinPixelRatio;
        this.osdObj.world._items[0].minPixelRatio = newMinPixelRatio;
        this.osdObj.world.update();
        this.osdObj.world.resetItems();
      }
    })
  }

  setTimer(inTime) {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    // let timediff = new Date().getTime() - inTime;
    if (inTime) {
      // timediff = this.config.dcTimer - timediff;
      inTime = Math.floor(inTime / 1000);
      this.timerInterval = setInterval(() => {
        inTime = inTime - 1;
        var minutes = Math.floor(inTime / 60);
        var seconds = inTime - (minutes * 60);
        this.timer = minutes + "m " + seconds + "s ";
        if (inTime < 0) {
          clearInterval(this.timerInterval);
          if (localStorage.isCurrentUserExternal == "true" || localStorage.meetingLoginParam) {
            sessionStorage.msgComponentText = "Meeting ended as Host/presenter left the meeting";
            this.commonService.changePlace("/message");
            this.router.navigate(["/message"]);
            setTimeout(() => {
              window.location.reload();
            }, 100);
          } else {
            window.open('', '_self').close();
          }
        }
      }, 1000);
    }
  }

  loadImageViewer(res) {
    let hostData = this.meetingDetails.slideIds.find((res: any) => {
      return res.userName == this.meetingDetails.creator
    });

    if (this.isPresenter && (!res.imageStatus || (res.imageStatus.length == 0))) {
      this.responseObj = "";
      localStorage.clearResponseData = "true";
      if (hostData.slideId[0]) {
        this.consultationViewerService.setSelectedImage([hostData.slideId[0]]);
      } else {
        this.consultationViewerService.setSelectedImage([]);
        $('.viewercontent').append('<img id="theImg" src="assets/images/viewer_icon/default_img.svg" />');
        $('#theImg').css({
          height: "50%",
          width: "50%",
          position: "absolute",
          top: "25%",
          left: "25%",
        })
      }
    } else {
      if (res.imageStatus) {
        this.responseObj = res.imageStatus;
        let tempArray = res.imageStatus.map((item) => { return item.slideID });
        if (tempArray.length == 1 && tempArray[0] == null) {
          this.consultationViewerService.setSelectedImage([]);
          $('.viewercontent').append('<img id="theImg" src="assets/images/viewer_icon/default_img.svg" />');
          $('#theImg').css({
            height: "50%",
            width: "50%",
            position: "absolute",
            top: "25%",
            left: "25%",
          });
        } else {
          this.consultationViewerService.setSelectedImage(tempArray);
        }
      }
    }
  }

  updateResponseObj() {
    if (!this.responseObj || this.responseObj.length == 0) {
      return;
    }
    var allImageFiltersObj = {};
    this.updateImagePosition(0, allImageFiltersObj, () => {
      this.consultationViewerService.setAllImageFilters(allImageFiltersObj);
    });
  }

  updateImagePosition(abc, allImageFiltersObj, callback) {
    if (abc == this.responseObj.length) {
      callback();
      return;
    }
    if (this.imageViewerArr[abc] && this.imageViewerArr[abc].osdObj) {
      if ($.isNumeric(this.responseObj[abc].zoomValue) && parseFloat(this.responseObj[abc].zoomValue) > 0) {
        // let zoom = this.imageViewerArr[abc].osdObj.viewport.imageToViewportZoom((this.maxZoomLevel / this.responseObj[abc].zoomValue));
        /* let zoom = this.imageViewerArr[abc].osdObj.viewport.viewportToImageZoom(this.responseObj[abc].zoomValue);
        let valueTodisplay = parseFloat((zoom * this.maxZoomLevel).toFixed(2));
        // let valueTodisplay = parseFloat((this.responseObj[abc].zoomValue).toFixed(2));
        if (this.selectedViewerindex == abc) {
          // this.sliderRangeValue = parseFloat((zoom * this.maxZoomLevel).toFixed(2));
          // // this.sliderRangeValue = parseFloat((this.responseObj[abc].zoomValue).toFixed(2));
        } */
        let resp_zoom = this.responseObj[abc].zoomValue;
        let zoom = this.imageViewerArr[abc].osdObj.viewport.imageToViewportZoom(resp_zoom / this.maxZoomLevel);
        this.imageViewerArr[abc].osdObj.viewport.zoomTo(zoom);
        if (this.selectedViewerindex == abc) {
          this.sliderRangeValue = Number((resp_zoom).toFixed(2));
        }
        this.updateImageZoomStatus(resp_zoom);
        // $('#' + this.imageViewerArr[abc].osdObj.id).find(".imageStatusZoom").html("Zoom: " + (zoom).toFixed(2) + "x");

      } else {
        this.imageViewerArr[abc]?.osdObj.viewport.goHome();
        let currentOsdZoomValue = this.imageViewerArr[abc]?.osdObj.viewport.getZoom(true);
        // if (this.selectedViewerindex == abc) {
        // this.sliderRangeValue = currentOsdZoomValue;
        // }
        $('#' + this.imageViewerArr[abc].osdObj.id).find(".imageStatusZoom").html("Zoom: " + parseFloat(currentOsdZoomValue).toFixed(2) + "x");
      }

      if (this.responseObj[abc].coordinates)
        this.imageViewerArr[abc].osdObj.viewport.panTo(this.responseObj[abc].coordinates);

      if ($.isNumeric(this.responseObj[abc].rotationValue)) {
        if (this.responseObj[abc].rotationValue != this.imageViewerArr[abc].osdObj.viewport.getRotation()) {
          this.imageViewerArr[abc].osdObj?.viewport?.setRotation(this.responseObj[abc].rotationValue);
        }
        if (abc == this.selectedViewerindex) {
          this.consultationViewerService.setRotationValue(this.responseObj[abc].rotationValue);
        }
        this.updateImamgeRotationStatus(this.responseObj[abc].rotationValue)
        // $('#' + this.imageViewerArr[abc].osdObj.id).find(".imageStatusRotation").html("Rotation: " + this.responseObj[abc].rotationValue + " &#176;");
        if (this.responseObj.length <= 2) {
          if (this.responseObj[abc].rotationValue > 0) {
            if ($("#osdnavArrowDiv_" + abc).attr("arrow-state") === 'open') {
              setTimeout(() => {
                $("#osdnavArrowDiv_" + abc).trigger("click");
              }, 0);
            }
            $("#osdnavArrowDiv_" + abc).closest(".joystickRotationNavigatorToggle").hide();
          } else {
            if ($("#osdnavArrowDiv_" + abc).attr("arrow-state") === 'close') {
              setTimeout(() => {
                $("#osdnavArrowDiv_" + abc).trigger("click");
              }, 0);
            }
            $("#osdnavArrowDiv_" + abc).closest(".joystickRotationNavigatorToggle").show();
          }
        }
      }

      this.imageViewerArr[abc].osdObj.setFilterOptions({
        filters: {
          processors: [
            OpenSeadragon.Filters.BRIGHTNESS($.isNumeric(this.responseObj[abc].brightness) ? this.responseObj[abc].brightness : 0),
            OpenSeadragon.Filters.GAMMA($.isNumeric(this.responseObj[abc].gamma) ? this.responseObj[abc].gamma : 1),
            OpenSeadragon.Filters.CONTRAST($.isNumeric(this.responseObj[abc].contrast) ? this.responseObj[abc].contrast : 1)
          ]
        },
        loadMode: 'sync'
      });

      allImageFiltersObj["slideId_" + this.slideIds[abc]] = {
        brightness: ($.isNumeric(this.responseObj[abc].brightness) ? this.responseObj[abc].brightness : 0),
        gamma: ($.isNumeric(this.responseObj[abc].gamma) ? this.responseObj[abc].gamma : 1),
        contrast: ($.isNumeric(this.responseObj[abc].contrast) ? this.responseObj[abc].contrast : 0)
      }

      if (this.isPresenter) {
        this.imageViewerArr[abc].osdObj.setMouseNavEnabled(true);
        this.imageViewerArr[abc].osdObj.navigator?.innerTracker?.setTracking(true);
      } else {
        this.imageViewerArr[abc].osdObj.setMouseNavEnabled(false);
        this.imageViewerArr[abc].osdObj.navigator?.innerTracker?.setTracking(false);
      }
    }

    // setTimeout(() => {
    abc = abc + 1;
    // console.log("abc: " + abc);
    this.updateImagePosition(abc, allImageFiltersObj, callback);
    // }, 50);
  }

  viewerHandler(event: any, action) {
    if (this.isSynch) {
      let currImgZoom;
      let currCoOrdinates = event.eventSource.viewport.getCenter();
      if (action == 'zoom') {
        let currentZoom = event.eventSource.viewport.getZoom();
        var currentZoomText = event.eventSource.viewport.viewportToImageZoom(currentZoom);
        currImgZoom = parseFloat((currentZoomText * this.maxZoomLevel).toFixed(2));
      }
      for (let a = 0; a < this.imageViewerArr.length; a++) {
        let imageViewer = this.imageViewerArr[a];
        if (event && (event.eventSource.id != imageViewer.osdObj.id)) {

          if (action == 'zoom') {
            let currMaxZoomLevel = imageViewer.schemaObj.imageProfiles.imageProfile[1].resolution;

            let currVPHomeZoom = imageViewer.osdObj.viewport.minZoomLevel;
            let currImgHomeZoom = parseFloat(imageViewer.osdObj.viewport.viewportToImageZoom(currVPHomeZoom * currMaxZoomLevel).toFixed(2));

            if (currImgZoom >= currImgHomeZoom) {
              let imageToViewPortZoomValue = imageViewer.osdObj.viewport.imageToViewportZoom(currImgZoom / this.maxZoomLevel);
              imageViewer.osdObj.viewport.zoomTo(imageToViewPortZoomValue);
              $('#' + imageViewer.osdObj.id).find(".imageStatusZoom").html("Zoom: " + currImgZoom + "x");
              $("#" + imageViewer.osdObj.id).find(".fitToScreenParent").addClass("dn");
            } else {
              let currVPZoom = imageViewer.osdObj.viewport.getZoom(true);
              let currImgZoom = parseFloat(imageViewer.osdObj.viewport.viewportToImageZoom(currVPZoom * currMaxZoomLevel).toFixed(2));

              if (currImgZoom != currImgHomeZoom) {
                imageViewer.osdObj.viewport.goHome();
                $('#' + imageViewer.osdObj.id).find(".imageStatusZoom").html("Zoom: " + currImgHomeZoom + "x");
                $("#" + imageViewer.osdObj.id).find(".fitToScreenParent").removeClass("dn");
              }
            }
          }

          imageViewer.osdObj.viewport.panTo(currCoOrdinates);
        }
      }
    }
  }

  async setLocalStream() {
    let stream;
    try {
      if (localStorage.microphoneDeviceId) {
        this.mediaConstraints = { audio: { deviceId: localStorage.microphoneDeviceId ? { exact: localStorage.microphoneDeviceId } : undefined }, video: false };
      } else {
        this.mediaConstraints = { audio: true, video: false };
      }
      stream = await navigator.mediaDevices.getUserMedia(this.mediaConstraints);
    } catch (error) {
      //console.error('Could not get user media', error)
      // alert("Kindly allow microphone access and join again");
    }
    this.localStream = stream;
    if (this.localStream) {
      this.createLocalAudioContext(this.localStream);
    }

  }

  createLocalAudioContext(stream) {
    let audioContext = new AudioContext();
    let analyser = audioContext.createAnalyser();
    let microphone = audioContext.createMediaStreamSource(stream);
    let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess = () => {
      var array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      var values = 0;

      var length = array.length;
      for (var i = 0; i < length; i++) {
        values += (array[i]);
      }
      var average = values / length;
      let previousAudioValue = parseFloat($(".participanList .participantItem[participant-emailid='" + this.currentUser.userName + "']").attr("audio"));
      if ((average >= 1 && previousAudioValue == 0) || (previousAudioValue >= 1 && average == 0)) {
        $(".participanList .participantItem[participant-emailid='" + this.currentUser.userName + "']").attr("audio", ((average >= 0 && average < this.config.dcVoiceVloume) ? 0 : average));
        this.updateMicIcons(this.currentUser.userName);
      }
    }
  }

  updateMicIcons(emailId) {
    let participantElem = $(".participanList .participantItem[participant-emailid='" + emailId + "']");
    if ($(participantElem).attr("mute") == 'no' && $(participantElem).attr("audio") == "0") {
      $(participantElem).find(".micicons .micUnmute").show();
    } else {
      $(participantElem).find(".micicons .micUnmute").hide();
    }

    if ($(participantElem).attr("mute") == 'yes') {
      $(participantElem).find(".micicons .micMute").show();
    } else {
      $(participantElem).find(".micicons .micMute").hide();
    }

    if ($(participantElem).attr("mute") == 'no' && $(participantElem).attr("audio") != "0") {
      $(participantElem).find(".micicons .voiceIcon").show();
    } else {
      $(participantElem).find(".micicons .voiceIcon").hide();
    }
  }

  addLocalTracks(rtcPeerConnection) {
    if (this.localStream) {
      this.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        rtcPeerConnection.addTrack(track, this.localStream);
      });
    }

    // if (localStorage.microphoneDeviceId) {
    //   setTimeout(() => {
    //     var audioSource = localStorage.microphoneDeviceId;
    //     var newmediaConstraints = { audio: { deviceId: audioSource ? { exact: audioSource } : undefined }, video: false };

    //     navigator.mediaDevices.getUserMedia(newmediaConstraints).then((newStream) => {
    //       var audioTrack = newStream.getAudioTracks()[0];

    //       var sender = rtcPeerConnection.getSenders().find(function (s) {
    //         return s.track.kind == audioTrack.kind;
    //       });
    //       sender.replaceTrack(audioTrack);

    //     }).catch((err) => {
    //       console.log("addLocalTrack: "+JSON.stringify(err));
    //     });
    //   }, 0);
    // }
  }

  sendIceCandidate(event, clientID) {
    if (event.candidate) {
      this.consultationViewerService.emitEvent('voice-candidate', {
        'meetID': this.meetingDetails.meeting_pk,
        'clientID': this.currentUser.userName,
        'label': event.candidate.sdpMLineIndex,
        'candidate': event.candidate.candidate,
        'to': clientID
      })
    }
  }

  setRemoteStream(event, userId) {
    this.remoteStream[userId] = event.streams[0];
    this.consultationViewerService.emitEvent('mute-status', {
      'meetID': this.meetingDetails.meeting_pk,
      'clientID': userId
    });
    if ($(".participanList .participantItem[participant-emailid='" + userId + "']").attr("mute") == 'yes') {
      this.remoteStream[userId].getAudioTracks()[0].enabled = !this.remoteStream[userId].getAudioTracks()[0].enabled;
    }
    if (this.remoteStream[userId].getAudioTracks()[0].enabled) {
      // this.participantObj[userId].mute = 'no';
      $(".participanList .participantItem[participant-emailid='" + userId + "']").attr("mute", "no");
    } else {
      // this.participantObj[userId].mute = 'yes';
      $(".participanList .participantItem[participant-emailid='" + userId + "']").attr("mute", "yes");
    }
    this.updateMicIcons(userId);
    this.consultationViewerService.setParticipantUserList(this.participantObj);
    var audioElement = document.createElement('audio');
    audioElement.setAttribute('autoplay', 'true');
    audioElement.setAttribute('muted', 'false');
    audioElement.setAttribute("class", "userAudio");
    audioElement.srcObject = event.streams[0];
    console.log(userId);
    let remoteAudioContainer = document.getElementById('remoteAudio_' + userId);
    if (remoteAudioContainer) {
      document.getElementById('voiceCall').removeChild(remoteAudioContainer);
    }
    var audioContainer = document.createElement('div');
    audioContainer.setAttribute('id', 'remoteAudio_' + userId);
    audioContainer.appendChild(audioElement);
    if (localStorage.speakerDeviceId) {
      audioElement["setSinkId"](localStorage.speakerDeviceId)
        .then(() => {
          console.log(`Success, audio output device attached: ${localStorage.speakerDeviceId} to element.`);
        })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
        });
    }

    document.getElementById('voiceCall').appendChild(audioContainer);
    let audioContext = new AudioContext();
    let analyser = audioContext.createAnalyser();
    let microphone = audioContext.createMediaStreamSource(event.streams[0]);
    let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess = () => {
      var array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      var values = 0;

      var length = array.length;
      for (var i = 0; i < length; i++) {
        values += (array[i]);
      }
      var average = values / length;
      // let previousAudioValue = this.participantObj[userId].audio;
      let previousAudioValue = $(".participanList .participantItem[participant-emailid='" + userId + "']").attr("audio");
      if ((average >= 1 && previousAudioValue == 0) || (previousAudioValue >= 1 && average == 0)) {
        // this.participantObj[userId].audio = average;
        $(".participanList .participantItem[participant-emailid='" + userId + "']").attr("audio", average);
        this.updateMicIcons(userId);
        this.consultationViewerService.setParticipantUserList(this.participantObj);
      }
    }
  }

  async createOffer(rtcPeerConnection, clientID, isIceRestart: boolean = false) {
    let sessionDescription
    try {
      if (isIceRestart) {
        sessionDescription = await rtcPeerConnection.createOffer({ iceRestart: true });
      } else {
        sessionDescription = await rtcPeerConnection.createOffer();
      }
      await rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error)
    }

    this.consultationViewerService.emitEvent('voice-offer', {
      'meetID': this.meetingDetails.meeting_pk,
      'clientID': this.currentUser.userName,
      'sdp': sessionDescription,
      'to': clientID
    });
  }

  async createAnswer(rtcPeerConnection, clientID) {
    let sessionDescription
    try {
      sessionDescription = await rtcPeerConnection.createAnswer()
      await rtcPeerConnection.setLocalDescription(sessionDescription)
    } catch (error) {
      console.error(error)
    }

    this.consultationViewerService.emitEvent('voice-answer', {
      'meetID': this.meetingDetails.meeting_pk,
      'clientID': this.currentUser.userName,
      'sdp': sessionDescription,
      'to': clientID
    });
  }
}