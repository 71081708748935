import { ConsultationViewerService } from './../../consultation-viewer/consultation-viewer.service';
import { MeetingService } from './../meeting.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConsultationService } from '../../consultation.service';
import { FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { CommonService } from '../../../common.service';
import { ConfirmDialogComponent } from "./../../../common/confirm-dialog/confirm-dialog.component";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { MeetingDetailsComponent } from '../meeting-details/meeting-details.component';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.css']
})
export class MeetingListComponent implements OnInit {

  constructor(
    private consultationService: ConsultationService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private dialog: MatDialog,
    public router: Router,
    private meetingService: MeetingService,
    private datepipe: DatePipe,
    private consultationViewerService: ConsultationViewerService,
    public translate: TranslateService
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
  showLoader: boolean = false;
  dataTable: any;
  @ViewChild("dataTable", { static: true }) table;
  dtOptions: any;
  selectedParam: any;
  activeTab: any;
  observable: any;
  tableData = [];
  noTableData: boolean = false;
  searchForm: any;
  createdCount: any = 0;
  newRequestCount: any = 0;
  completedCount: any = 0;
  hostList = [];
  participantsList = [];
  tagsInput: any;
  searchItems: any = {};
  filterData = [];
  tagsInputarr: any = [];

  ngOnInit() {
    this.getMeetingList('created');
    this.searchForm = this.formBuilder.group({
      consultationDate: [""],
      host: [""],
      participant: [""]
    });
    this.getParticipantsList();
    setTimeout(() => {
      $("#studysearch").on("keyup click", e => {
        let table = this.dataTable.DataTable();
        table.search($(e.currentTarget).val()).draw();
      });
    }, 50);
  }

  getMeetingList(paramValue) {
    this.clearFilterFields();
    this.meetingService.getMeetingCount().subscribe((response: any) => {
      if (response.success) {
        this.newRequestCount = response.data.newRequest;
        this.createdCount = response.data.created;
        this.completedCount = response.data.completed;
      }
      if (this.tagsInput) {
        this.tagsInput.tagsinput("removeAll");
      }
      this.showLoader = true;
      this.selectedParam = paramValue;
      if (this.dataTable) {
        this.dataTable.DataTable().destroy();
        this.dataTable.empty();
      }
      if (paramValue == "created") {
        this.activeTab = "created";
        this.observable = this.meetingService.getMeetingList('CREATED');
      } else if (paramValue == "newRequest") {
        this.activeTab = "newRequest";
        this.observable = this.meetingService.getMeetingList('NEW_REQUEST');
      } else if (paramValue == "completed") {
        this.activeTab = "completed";
        this.observable = this.meetingService.getMeetingList('COMPLETED');
      }
      this.observable.subscribe((response: any) => {
        this.tableData = response.data;
        // setTimeout(function () {
        this.getTable(this.tableData);
        // }.bind(this), 500);
      }, err => { }, () => {
        if (this.tableData.length > 0) {
          this.noTableData = false
          this.dataTable = $(this.table.nativeElement);
          this.dataTable.DataTable(this.dtOptions);
          setTimeout(
            function () {
              this.attachTableEvvents();
            }.bind(this)
            , 100);
        } else {
          this.noTableData = true;
          this.dataTable = '';
        }
        this.showLoader = false;
      });
    });
  }

  getTable(tableData) {
    var tableWidth = $(".page-content").width();
    var columnWidth = {
      "initColumn": (tableWidth * 1 / 100) + 'px',
      "title": (tableWidth * 11 / 100) + 'px',
      "date": (tableWidth * 11 / 100) + 'px',
      "time": (tableWidth * 13 / 100) + 'px',
      "host": (tableWidth * 20 / 100) + 'px',
      "participant": (this.activeTab != 'created' ? (tableWidth * 21 / 100) + 'px' : (this.activeTab != 'newRequest' ? (tableWidth * 36 / 100) + 'px' : (tableWidth * 46 / 100) + 'px')),
      "status": (this.activeTab == 'created' ? (tableWidth * 13 / 100) + 'px' : "0px"),
      "action": (this.activeTab != 'completed' ? (tableWidth * 10 / 100) + 'px' : "0px"),
    };

    this.dtOptions = {
      "bDestroy": true,
      data: tableData,
      autoWidth: false,
      responsive: true,
      paging: false,
      info: false,
      scrollY: "calc(100vh - 19vw)",
      scrollCollapse: true,
      order: [[2, "desc"]],
      columns: [
        {
          title: "    ",
          data: "",
          width: columnWidth.initColumn,
          bSearchable: false,
          bSortable: false,
          render: (data, type, row, meta) => {
            /* if (this.activeTab == 'newRequest' || this.activeTab == 'completed' || (this.activeTab == 'created' && row.status == 'IN_PROGRESS')) {
              return "<a class='imgicontable detailConsultationIcon'><i class='fa fa-info-circle imageicon' title='Detail'></i></a>";
            } else  */
            if ((row.status == 'DRAFT' || row.status == 'NOT_STARTED') && this.activeTab == 'created') {
              return "<a class='imgicontable deleteConsultationIcon'><i class='fa fa-trash imageicon' title='Delete'></i></a>&nbsp;" +
                "<a class='imgicontable editConsultationIcon'><i class='fa fa-edit imageicon' title='Edit'></i></a>&nbsp;";
            } else {
              return ''
            }
          }
        },
        {
          title: "Title", data: "title", width: columnWidth.title,
          render: (data) => {
            return (
              '<div style="width: ' + columnWidth.title + '" class="textEllipsis orangeText detailConsultationIcon" title="' + data + '">' + data + '</div>'
            );
          }
        },
        {
          title: "Date", data: "startTime", width: columnWidth.date,
          render: (data) => {
            return (
              "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMddHHmmss') + "</span>" + this.datepipe.transform(data, 'longDate')
            );
          }
        },
        {
          title: "Time", data: "", width: columnWidth.time,
          bSortable: false,
          render: (data, type, full, meta) => {
            return (
              '<span title="' + this.datepipe.transform(full.startTime, 'shortTime') + '- ' + this.datepipe.transform(full.endTime, 'shortTime') + '">' + this.datepipe.transform(full.startTime, 'shortTime') + '- ' + this.datepipe.transform(full.endTime, 'shortTime') + '</span>'
            );
          }
        },
        {
          title: "Host", data: "creatorDisplayName", width: columnWidth.host,
          visible: this.activeTab == 'newRequest' || this.activeTab == 'completed',
          render: function (data) {
            return (
              '<div style="width: ' + columnWidth.host + '" class="textEllipsis" title="' + data + '">' + data + '</div>'
            );
          }
        },
        {
          title: "Participants",
          data: "invitees",
          width: columnWidth.participant,
          bSortable: false,
          render: function (data) {
            let invitees = []
            for (let i = 0; i < data.length; i++) {
              if (data[i].displayName) {
                invitees.push(data[i].displayName.trim());
              } else {
                invitees.push(data[i].emailId.trim());
              }
            }
            return '<div style="width: ' + columnWidth.participant + '" class="textEllipsis" title="' + invitees.join(", ") + '">' + invitees.join(", ") + '</div>';
          }
        },
        {
          title: "Status", width: columnWidth.status,
          visible: this.activeTab == 'created',
          data: "status",
          render: (data) => {
            if (this.activeTab == 'newRequest') {
              return (
                '<span title="Join">Join</span>'
              );
            } else if (data == 'NOT_STARTED') {
              return (
                '<span title="Scheduled">Scheduled</span>'
              );
            } else if (data == 'DRAFT') {
              return (
                '<span title="Draft">Draft</span>'
              );
            } else if (data == 'IN_PROGRESS') {
              return (
                '<span title="Ongoing Consultation">Ongoing Consultation</span>'
              );
            } else {
              return ''
            }
          }
        },
        {
          title: "Action", width: columnWidth.action,
          bSearchable: false,
          bSortable: false,
          data: '',
          visible: this.activeTab != 'completed',
          render: (data, type, row, meta) => {
            if (row.status == 'DRAFT') {
              return (
                ''
              );
            } else if (row.status == 'IN_PROGRESS' || (this.activeTab == 'newRequest' && row.status == 'NOT_STARTED')) {
              return (
                '<a class="joinMeeting" style="color: #f47625;font-weight: 600;cursor: pointer;text-decoration: none;">Join</a>'
              );
            } else if (this.activeTab == 'created' && row.status == 'NOT_STARTED') {
              return (
                '<a class="joinMeeting" style="color: #f47625;font-weight: 600;cursor: pointer;text-decoration: none;">Start</a>'
              );
            } else {
              return '';
            }
          }
        }
      ]
    };
  }

  attachTableEvvents() {
    $("#p_study_table").off();
    $("#p_study_table").on("click", ".deleteConsultationIcon", e => {
      let table = this.dataTable.DataTable();
      var data = table.row($(e.currentTarget).parents("tr")).data();
      this.showDeleteConsultationModel(data);
    });
    $("#p_study_table").on("click", ".editConsultationIcon", e => {
      let table = this.dataTable.DataTable();
      var data = table.row($(e.currentTarget).parents("tr")).data();
      this.commonService.changePlace("consultation/edit/" + data.meeting_pk);
      this.router.navigate(["/dashboard/consultation/edit/" + data.meeting_pk]);
    });

    $("#p_study_table").on("click", ".detailConsultationIcon", e => {
      let table = this.dataTable.DataTable();
      var data = table.row($(e.currentTarget).parents("tr")).data();
      this.showDetailConsultationModel(data);
    });

    $("#p_study_table").on("click", ".joinMeeting", (e) => {
      let data = this.dataTable.DataTable().row($(e.currentTarget).parents("tr")).data();
      this.openViewer(data.meeting_pk);
      if (this.activeTab == 'created') {
        this.consultationViewerService.updateMeetingStatus(data.meeting_pk, { "status": "IN_PROGRESS" }).subscribe((res: any) => {
          this.getMeetingList(this.activeTab);
        });
      }
    });
  }

  showDetailConsultationModel(consultationData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "60%";
    dialogConfig.data = {
      showHeader: true,
      meetingId: consultationData.meeting_pk
    };
    this.dialog.open(MeetingDetailsComponent, dialogConfig);
  }

  showDeleteConsultationModel(meetingData) {
    const dialogConfig = new MatDialogConfig();
    let msg = '';
    if (meetingData.status == 'DRAFT') {
      msg = this.translate.instant("GENERIC_MSG.CONSULTATION.DELETE_THIS_DRAFT");
    } else {
      msg = this.translate.instant("GENERIC_MSG.CONSULTATION.DELETE_THIS_MEETING");
    }
    dialogConfig.autoFocus = true;
    dialogConfig.width = "31%";
    dialogConfig.data = {
      headerTitle: "Delete Consultation",
      confirmMsg: msg,
      cancelButtonText: "Cancel",
      confirmButtonText: "OK"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.meetingService.deleteMeeting(meetingData.meeting_pk).subscribe((res: any) => {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.MEETING_DELETE_SUCCESSFULLY"));
          this.getMeetingList('created');
        });
      }
    });
  }

  getParticipantsList() {
    this.consultationService.getParticipantsList().subscribe((userData: any) => {
      this.participantsList = userData.data;
    });
  }

  clearFilterFields() {
    this.searchForm = this.formBuilder.group({
      consultationDate: [""],
      host: [""],
      participant: [""]
    });
  }

  onSearch() {
    this.tagsInput.tagsinput("removeAll");
    for (var prop in this.searchForm.value) {
      if (
        this.searchForm.value.hasOwnProperty(prop) &&
        this.searchForm.value[prop]
      ) {
        if (prop == 'host' || prop == 'participant') {
          for (let i = 0; i < this.searchForm.value[prop].length; i++) {
            var innerObj = {};
            innerObj["criteria"] = prop + '_' + i;
            innerObj["value"] = this.searchForm.value[prop][i].displayName;
            this.tagsInput.tagsinput("add", innerObj);
            let obj = {};
            obj["criteria"] = innerObj["criteria"];
            obj["value"] = this.searchForm.value[prop][i];
            this.tagsInputarr.push(obj);
          };
        } else {
          var innerObj = {};
          innerObj["criteria"] = prop;
          innerObj["value"] = this.searchForm.value[prop];
          this.tagsInput.tagsinput("add", innerObj);
        }
      }
    }
    if (this.tagsInput.tagsinput("items").length == 0) {
      this.tagsInput.tagsinput("destroy");
    }
    this.generateFilterDataTable();
    this.clearFilterFields();
    $(".multiselectfilter").toggle();
  }

  onCancel() {
    if ($(".tags").tagsinput("items").length == 0) {
      $(".tags").tagsinput("destroy");
    }
    this.clearFilterFields();
    $(".multiselectfilter").toggle();
  }

  onReset() {
    this.clearFilterFields();
    this.tagsInput.tagsinput("removeAll");
    this.generateFilterDataTable();
  }

  showHideFilter() {
    if ($(".multiselectfilter").is(":visible")) {
      $(".multiselectfilter").hide();
      this.clearFilterFields();
      if (this.tagsInput.tagsinput("items").length == 0) {
        this.tagsInput.tagsinput("destroy");
      }
    } else {
      $(".multiselectfilter").show();
      this.tagsInput = $(".tags");
      if ($('.bootstrap-tagsinput').length > 0) {
        $('.bootstrap-tagsinput').css('display', 'block');
        $('#studysearch').css('display', 'none');
      } else {
        this.tagsInput.tagsinput({
          itemValue: "criteria",
          itemText: "value",
          maxChars: 100,
          trimValue: false
        });
      }
      this.setData();
      // this.generateFilterDataTable();
      this.tagsInput.on("itemRemoved", event => {
        if (this.tagsInput.tagsinput("items").length == 0) {
          $('.bootstrap-tagsinput').css('display', 'none');
          $('#studysearch').css('display', 'block');
        }
        this.setData();
        this.generateFilterDataTable();
      });
    }
  }

  setData() {
    this.searchItems = {
      "host": [],
      "participant": [],
      "consulatationDate": ''
    };
    let tagArr = $(".tags").tagsinput("items");
    for (let i = 0; i < tagArr.length; i++) {
      if (tagArr[i].criteria.includes("host")) {
        let obj = {};
        this.tagsInputarr.forEach((elem) => {
          if (elem.criteria == tagArr[i].criteria) {
            obj = elem.value;
          }
        })
        this.searchItems["host"].push(obj);
      } else if (tagArr[i].criteria.includes("participant")) {
        let obj = {};
        this.tagsInputarr.forEach((elem) => {
          if (elem.criteria == tagArr[i].criteria) {
            obj = elem.value;
          }
        })
        this.searchItems["participant"].push(obj);
      } else {
        this.searchItems[tagArr[i].criteria] = tagArr[i].value;
      }
    }
    this.searchForm.setValue({
      host: this.searchItems.host ? this.searchItems.host : [""],
      participant: this.searchItems.participant ? this.searchItems.participant : [""],
      consultationDate: this.searchItems.consultationDate
        ? this.searchItems.consultationDate
        : ""
    });
  }

  generateFilterDataTable() {
    this.showLoader = true;
    if (this.dataTable) {
      this.dataTable.DataTable().destroy();
      this.dataTable.empty();
    }

    let formValues = this.searchForm.value;

    if (formValues.consultationDate && formValues.host.length > 0 && formValues.participant.length > 0) {
      this.filterData = this.tableData.filter(elem => {
        let consultationDate = this.datepipe.transform(elem.startTime, 'yyyy-MM-dd');
        return consultationDate == formValues.consultationDate &&
          elem.invitees.some((val) =>
            formValues.participant.find(elem1 => {
              return elem1.email === val.emailId;
            })
          ) &&
          formValues.host.find(elem1 => {
            return elem1.email === elem.creator;
          })
      });
    } else if (formValues.consultationDate && formValues.host.length > 0) {
      this.filterData = this.tableData.filter(elem => {
        let consultationDate = this.datepipe.transform(elem.startTime, 'yyyy-MM-dd');
        return consultationDate == formValues.consultationDate &&
          formValues.host.find(elem1 => {
            return elem1.email === elem.creator;
          })
      });
    } else if (formValues.consultationDate && formValues.participant.length > 0) {
      this.filterData = this.tableData.filter(elem => {
        let consultationDate = this.datepipe.transform(elem.startTime, 'yyyy-MM-dd');
        return consultationDate == formValues.consultationDate &&
          elem.invitees.some((val) =>
            formValues.participant.find(elem1 => {
              return elem1.email === val.emailId;
            })
          )
      });
    } else if (formValues.consultationDate) {
      this.filterData = this.tableData.filter(elem => {
        let consultationDate = this.datepipe.transform(elem.startTime, 'yyyy-MM-dd');
        return consultationDate == formValues.consultationDate
      });
    } else if (formValues.host.length > 0) {
      this.filterData = this.tableData.filter(elem => {
        return formValues.host.find(elem1 => {
          return elem1.email === elem.creator;
        })
      });
    } else if (formValues.participant.length > 0) {
      this.filterData = this.tableData.filter(elem => {
        return elem.invitees.some((val) =>
          formValues.participant.find(elem1 => {
            return elem1.email === val.emailId;
          })
        )
      });
    } else {
      this.filterData = this.tableData;
    }

    setTimeout(function () {

      this.getTable(this.filterData);

      if (this.filterData.length > 0) {
        this.noTableData = false
        this.dataTable = $(this.table.nativeElement);
        this.dataTable.DataTable(this.dtOptions);
        setTimeout(
          function () {
            this.attachTableEvvents();
          }.bind(this)
          , 100);
      } else {
        this.noTableData = true;
        this.dataTable = '';
      }
      this.showLoader = false;
    }.bind(this), 500);

  }

  navigateToCreateConsultation() {
    this.commonService.changePlace("consultation/create");
    this.router.navigate(["/dashboard/consultation/create"]);
  }

  openViewer(meetingId: any) {
    window.open("consultation/consultation-viewer/" + meetingId, '_blank');
    window.addEventListener('message', this.receiveMessage.bind(this));
    return false;
  }
  private receiveMessage(event) {
    if (event.data) {
      //this.getMeetingList(this.activeTab);
      //this.ngOnInit();
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }
}
