import { prop, required, unique, propObject } from '@rxweb/reactive-form-validators';
import { Animals } from './Animals.model';

export class DosagesModel {
    private _controlGroup: String;
    private _dosageCode : String;
    private _animalSequenceType : String;
    private _animals: Animals;
    private _dose: String;
    private _dosageUnit: String;

    @prop()
    public rowType: String;

    @prop()
    public newDosage: Boolean;

    @unique()
    @required()
    @prop() 
    set dosageCode (value:String) {
        this._dosageCode = value
    }
    get dosageCode () {
        return this._dosageCode;
    }

    @required()
    @prop()
    set animalSequenceType (value: String) {
        this._animalSequenceType = value;
    }
    get animalSequenceType() {
        return this._animalSequenceType;
    }

    @required()
    @prop()
    set controlGroup(value: String){
        this._controlGroup = value;
    }
    get controlGroup() {
        return this._controlGroup;
    }

    @propObject(Animals)
    set animals(value: Animals) {
        this._animals = value;
    }

    get animals() {
        return this._animals;
    }

    @prop()
    set dose (value: String) {
        this._dose = value;
    }
    get dose() {
        return this._dose;
    }

    @prop()
    set dosageUnit(value: String) {
        this._dosageUnit = value;
    }
    get dosageUnit() {
        return this._dosageUnit;
    }
}