<div class="annotationPopOver">
    <div class="annoToolTip">
        <div class="annoTooltipIcons"
            [ngStyle]="{'min-width': (selectedAnnoInfoObj?.info?.creator == currentUser.userName) ? '3.5vw': '0vw'}">
            <i class="fa fa-info-circle annoInfo" (click)="toggleAnnoEditDiv($event, true)"></i>
            <i [hidden]="!selectedAnnoInfoObj?.info?.bookmarked" class="fa fa-bookmark annoInfo enableBookmarkToolTip"
                aria-hidden="true"></i>
            <i class="fa fa-pencil annoInfo" *ngIf=" (selectedAnnoInfoObj?.info?.creator == currentUser.userName)"
                (click)="editAnnotationInfo($event, selectedAnnoInfoObj)" aria-hidden="true"></i>
            <i class="fa fa-trash-o annoInfo" *ngIf=" (selectedAnnoInfoObj?.info?.creator == currentUser.userName)"
                (click)="deleteAnnotationData($event, selectedAnnoInfoObj)" aria-hidden="true"></i>
        </div>
        <div class="annoText">{{selectedAnnoInfoObj?.info?.comment}}</div>
    </div>



    <div class="annoEditDiv dn">
        <div class="iconActions clearfix">
            <div class="float-left">
                <i class="fa fa-info-circle annoInfo annoDetails" (click)="toggleAnnoEditDiv($event, false)"></i>
                <i [hidden]="!selectedAnnoInfoObj?.info?.bookmarked"
                    class="fa fa-bookmark annoInfo enableBookmarkToolTip" aria-hidden="true"></i>
            </div>
            <div class="float-right editAnnotationIcons">
                <i *ngIf="selectedAnnoInfoObj?.showAreaPerimeter"
                    class="fa fa-eye annoInfo areaPerimeterIcon showAreaPerimeter" data-visible="false"
                    (click)="showAreaPerimeter(true)" aria-hidden="true"></i>
                <i *ngIf="selectedAnnoInfoObj?.showAreaPerimeter"
                    class="fa fa-eye-slash annoInfo areaPerimeterIcon hideAreaPerimeter" data-visible="true"
                    (click)="showAreaPerimeter(false)" aria-hidden="true"></i>
                <i class="fa fa-bookmark annoInfo bookMarkIcon" (click)="toggleBookMarkIcon($event)"
                    aria-hidden="true"></i>
                <i class="fa fa-floppy-o annoInfo saveAnnotation"
                    (click)="saveAnnotationInfo($event, selectedAnnoInfoObj)" aria-hidden="true"></i>
                <i class="fa fa-pencil annoInfo editAnnotation"
                    *ngIf=" (selectedAnnoInfoObj?.info?.creator == currentUser.userName)"
                    (click)="editAnnotationInfo($event, selectedAnnoInfoObj)" aria-hidden="true"></i>
                <i class="fa fa-trash-o annoInfo deleteAnnotation"
                    *ngIf=" (selectedAnnoInfoObj?.info?.creator == currentUser.userName)"
                    (click)="deleteAnnotationData($event, selectedAnnoInfoObj)" aria-hidden="true"></i>
                <i class="fa fa-times annoInfo closeEditAnnotation" aria-hidden="true"
                    (click)="closeEditAnnotation()"></i>
            </div>
        </div>
        <div class="annoCreatedBy"><span class="annoCreatedByTitle">Created By :
            </span><span>{{selectedAnnoInfoObj?.info?.creator}}</span></div>
        <div class="annoCommentDiv">
            <textarea class="form-control" id="annoCommentText" rows="2" [value]="selectedAnnoInfoObj?.info?.comment"
                [readonly]="true" maxlength="1000"></textarea>
        </div>
        <div *ngIf="selectedAnnoInfoObj?.showAreaPerimeter"
            [ngStyle]="{'display': (selectedAnnoInfoObj?.areaPerimeterFlag) ? 'block' : 'none' }"
            class="annoAreaParameterDiv clearfix">
            <div class="areaInfo float-left"><span><b>Area: </b></span><span
                    [innerHTML]="selectedAnnoInfoObj?.area?.value + selectedAnnoInfoObj?.area?.unit"></span></div>
            <div class="perimeterInfo float-right"><span><b>Perimeter: </b></span><span
                    [innerHTML]="selectedAnnoInfoObj?.perimeter?.value + selectedAnnoInfoObj?.perimeter?.unit"></span>
            </div>
        </div>
        <div *ngIf="isAnnoEditCommentBoxOpened" class="annoColorDiv">
            <div class="float-left">
                <div *ngFor="let itemColor of newCreateAnnoInfo.createAnnoPredefinedColorArr"
                    [style.backgroundColor]="itemColor" class="colorPallette"
                    [ngClass]="{'selectedPallette': itemColor == selectedAnnoInfoObj.info.changedColor}"
                    (click)="editAnnoCPChange(itemColor)"></div>
                <div class="colorPallette annoColorPicker"
                    [ngClass]="{'selectedPallette': (newCreateAnnoInfo.createAnnoPredefinedColorArr.indexOf(selectedAnnoInfoObj.info.color) < 0)}">
                    <input id="annoColor" class="colorPallette" matInput [colorPicker]="selectedAnnoInfoObj.info.color"
                        [cpOutputFormat]="'hex'" (colorPickerChange)="editAnnoCPChange($event)" readonly />
                </div>
            </div>
        </div>
        <div class="dsCommentDiv" *ngIf="((currentSlideIdData?.length > 0) && (showEditDs?.length > 0))">
            <mat-form-field appearance="outline" class="commonMatFormField">
                <mat-label>Digital Signature Comment</mat-label>
                <textarea type="text" class="form-control dsCommentText annoCommentText" id="dsCommentTextEdit"
                    [(ngModel)]="savedDsComment" maxlength="250" rows="2" [readonly]="true" matInput></textarea>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="createAnnoPopup">
    <div class="iconActions clearfix">
        <div class="float-right createAnnotationIcons">
            <i *ngIf="newCreateAnnoInfo.showAreaPerimeterVal"
                class="fa fa-eye-slash createAnnoInfo areaPerimeterIcon createAnnoHideAreaPerimeter"
                (click)="toggleAnnoAreaPerimeter($event)" data-visible="true" aria-hidden="true"></i>
            <i class="fa fa-bookmark createAnnoInfo bookMarkIcon"
                [ngClass]="{'enableBookmark': newCreateAnnoInfo.drawingAnnoBookmark}" aria-hidden="true"
                (click)="toggleAnnoBookmark($event)"></i>
            <i class="fa fa-floppy-o createAnnoInfo saveAnnotation" aria-hidden="true"
                (click)="saveNewlyCreatedAnno()"></i>
            <i class="fa fa-times createAnnoInfo closeAnnotation" aria-hidden="true" (click)="closeAnno()"></i>
        </div>
    </div>
    <div class="createAnnoCreatedBy"><span class="createAnnoCreatedByTitle">Created By :
        </span><span>{{currentUser.userName}}</span></div>
    <div class="createAnnoCommentDiv">
        <textarea class="form-control" id="createAnnoCommentText" [(ngModel)]="newAnnoSaveInfo.comment" rows="2"
            maxlength="1000"></textarea>
    </div>
    <div *ngIf="newCreateAnnoInfo.showAreaPerimeterVal" class="createAnnoAreaParameterDiv clearfix">
        <div class="areaInfo float-left"><span><b>Area: </b></span><span class="areaValue">{{newAnnoSaveInfo.area?.value
                + newAnnoSaveInfo.area?.unit}}</span></div>
        <div class="perimeterInfo float-right"><span><b>Perimeter: </b></span><span
                class="perimeterValue">{{newAnnoSaveInfo?.perimeter?.value + newAnnoSaveInfo?.perimeter?.unit}}</span></div>
    </div>
    <div class="createAnnoColorDiv">
        <div class="float-left">
            <div *ngFor="let itemColor of newCreateAnnoInfo.createAnnoPredefinedColorArr"
                [style.backgroundColor]="itemColor" class="colorPallette"
                [ngClass]="{'selectedPallette': itemColor == newCreateAnnoInfo.drawingAnnoLineColor}"
                (click)="newCreateAnnoCPChange(itemColor)"></div>
            <div class="colorPallette annoColorPicker"
                [ngClass]="{'selectedPallette': (newCreateAnnoInfo.createAnnoPredefinedColorArr.indexOf(newCreateAnnoInfo.drawingAnnoLineColor) < 0)}">
                <input id="createAnnoColor" matInput [colorPicker]="newCreateAnnoInfo.drawingAnnoLineColor"
                    [cpOutputFormat]="'hex'" (colorPickerChange)="newCreateAnnoCPChange($event)" readonly />
            </div>
        </div>
    </div>
    <div class="dsCommentDiv" *ngIf="((currentSlideIdData?.length > 0) && (showCreateDs?.length > 0))">
        <mat-form-field appearance="outline" class="commonMatFormField">
            <mat-label>Digital Signature Comment</mat-label>
            <textarea type="text" class="form-control dsCommentText annoCommentText" id="dsCommentTextCreate"
                (change)="getchangedDsValue($event)" maxlength="250" rows="2" matInput></textarea>
        </mat-form-field>
    </div>
</div>

<div id="screenShotDiv" class="dn">
    <div class="screenShotPopupMainPopup">
        <div class="screenShotPopupHeader clearfix">
            <span class="screenShotPopupTitle float-left font-weight-bold">Screenshot</span>
            <i class="fa fa-times closeSSPopup float-right" (click)="closeScreenshotPopup()"></i>
        </div>
        <div class="screenShotPopupBody">
            <div class="ssRow clearfix">
                <div class="float-left">
                    <mat-label class="toggleBtnTitle">With Annotation</mat-label>
                    <mat-slide-toggle [(ngModel)]="screenshotSetting.iswithAnnotation"></mat-slide-toggle>
                </div>
                <div class="float-right">
                    <mat-label class="toggleBtnTitle">With Scalebar</mat-label>
                    <mat-slide-toggle [(ngModel)]="screenshotSetting.iswithScalebar"></mat-slide-toggle>
                </div>
            </div>
            <div class="ssRow clearfix">
                <mat-label class="toggleBtnTitle float-left">Filename</mat-label>
                <input type="text" class="screenshotInputText screenShotFilename float-right"
                    placeholder="Enter Filename" [(ngModel)]="screenshotSetting.fileName">
            </div>
            <div class="ssRow clearfix ssResolution">
                <mat-label class="toggleBtnTitle radioBtnTitle float-left">Resolution</mat-label>
                <div class="float-right" style="width: 80%;">
                    <mat-radio-group aria-label="Select a Resolution"
                        [(ngModel)]="screenshotSetting.defaultResolutionVal"
                        (change)="ssRadioBtnSelected($event, 'Resolution')">
                        <mat-radio-button class="ssRadioBtn" value="80">Low</mat-radio-button>
                        <mat-radio-button class="ssRadioBtn" value="90">Medium</mat-radio-button>
                        <mat-radio-button class="ssRadioBtn" value="100">High</mat-radio-button>
                        <mat-radio-button class="ssRadioBtn" value="custom">custom</mat-radio-button>
                    </mat-radio-group>
                    <span class="customType dn"><input type="number" class="screenshotInputText customField"
                            [(ngModel)]="screenshotSetting.selectedResolutionValue" min="10" max="100" value="50"><span
                            class="customPercentage">%</span></span>
                </div>
            </div>
            <div class="ssRow clearfix ssDPI">
                <mat-label class="toggleBtnTitle radioBtnTitle float-left">DPI</mat-label>
                <div class="float-right" style="width: 80%;">
                    <mat-radio-group aria-label="Select a DPI" [(ngModel)]="screenshotSetting.defaultDPIVal"
                        (change)="ssRadioBtnSelected($event, 'DPI')">
                        <mat-radio-button class="ssRadioBtn" value="80">Low</mat-radio-button>
                        <mat-radio-button class="ssRadioBtn" value="90">Medium</mat-radio-button>
                        <mat-radio-button class="ssRadioBtn" value="100">High</mat-radio-button>
                        <mat-radio-button class="ssRadioBtn" value="custom">custom</mat-radio-button>
                    </mat-radio-group>
                    <span class="customType dn"><input type="number" class="screenshotInputText customField"
                            [(ngModel)]="screenshotSetting.selectedDPIValue" min="10" max="100" value="50"><span
                            class="customPercentage">%</span></span>
                </div>
            </div>
            <div class="ssBtmRow clearfix">
                <div class="float-right">
                    <button type="button" class="btn btn-default ssBtn" (click)="closeScreenshotPopup()"
                        aria-label="cancelbtn">Cancel</button>
                    <button type="button" class="btn orangebtn savebtn ssBtn" (click)="saveScreenshot()"
                        aria-label="searchbtn">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="annoValue dn"></div>