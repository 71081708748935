import { unique, prop } from '@rxweb/reactive-form-validators';

export class Observation {
    private _observationPk: number = null;
    private _gradingCode: String;
    @unique()
    description: String;

    @prop()
    set gradingCode(value: String) {
        this._gradingCode = value
    }

    get gradingCode() {
        return this._gradingCode;
    }
    @prop()
    set observationPk(value: number) {
        this._observationPk = value
    }

    get observationPk() {
        return this._observationPk;
    }
}