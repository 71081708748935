<div class="participantMainDiv">
  <div class="participantHeader">
    <div class="participantTitle">{{participantTitleText}}
      <i class="fa fa-times closeIcon" (click)="closeDialog()"></i>
      <i class="fa fa-thumb-tack rightBarPinStatus pinUnlocked" (click)="pinLockToggle($event)" aria-hidden="true"></i>
      <img class="AddParticipantHeaderIcon"
        *ngIf="isHost && (participantTitleText == 'Participant List') && !isMeetScheduleDateExceed"
        src="../../assets/images/viewer_icon/add_prticipant.svg" (click)="inviteParticipants()">
    </div>
  </div>
  <div [hidden]="participantTitleText != 'Participant List'" class="participanList">
    <div *ngFor="let participant of participantObj | keyvalue">
      <div class="participantItem hoveraccessicon" *ngIf="participant.value.isJoined"
        [ngClass]="participant.value.isAccessRequested && (isHost || isPresenter)? 'activeblink' : ''"
        [attr.participant-emailid]="participant.value.emailId" mute="no" audio="0">
        <div class="leftfloat">
          <div class="networkStatus itemData"><span [ngClass]="{'online': participant.value.isJoined}"></span></div>
          <div class="participantName itemData" [class.currentUser]="participant.value.isCurrentUser">
            {{(participant.value.displayName)?(participant.value.displayName) : (participant.value.emailId)}}</div>
          <div class="participantInfo">
            <span class="participantInfoSpan" *ngIf="participant.value.isHost"> - Host</span>
            <span class="participantInfoSpan" *ngIf="participant.value.isExternal"> - Guest</span>
          </div>
        </div>
        <div class="rightfloat participant_righticon">
          <div class="">
            <div class="participantAccessImg presenterIcon" title="Presenter" *ngIf="(participant.value.isPresenter)">
            </div>
            <div class="participantAccessImg grantIcon" title="Make Presenter"
              (click)="grantAccess(participant.value.displayName, participant.value.emailId)"
              *ngIf="(isPresenter && participant.value.emailId != currentUser.userName) || (isHost && !participant.value.isPresenter)">
            </div>
          </div>
          <div class="micicons">
            <!-- <div class="muteIcon" *ngIf="participant.value.mute == 'no' && participant.value.audio == 0"></div>
            <div class="unMuteIcon" *ngIf="participant.value.mute == 'yes'"></div>
            <div class="voiceIcon" *ngIf="participant.value.audio && participant.value.mute == 'no'"></div> -->

            <!-- <img src="../../../../../assets/images/viewer_icon/Microphone_unmute_participant_list.svg" class="micUnmute" *ngIf="participant.value.mute == 'no' && participant.value.audio == 0" />
           <img src="../../../../../assets/images/viewer_icon/Microphone_mute_Participant_List.svg" class="micMute" *ngIf="participant.value.mute == 'yes'"/>
           <img src="../../../../../assets/images/viewer_icon/voiceIcon.gif" class="voiceIcon" *ngIf="participant.value.audio && participant.value.mute == 'no'"/> -->

            <!-- <img src="../../../../../assets/images/viewer_icon/Microphone_unmute_participant_list.svg" class="micUnmute"
              style="display: none;" />
            <img src="../../../../../assets/images/viewer_icon/Microphone_mute_Participant_List.svg" class="micMute"
              style="display: none;" />
            <img src="../../../../../assets/images/viewer_icon/voiceIcon.gif" class="voiceIcon"
              style="display: none;" /> -->

            <img src="../../../../../assets/images/viewer_icon/participantMicUnMute.png" class="micUnmute"
              style="display: none;" />
            <img src="../../../../../assets/images/viewer_icon/participantMicMute.png" class="micMute"
              style="display: none;" />
            <img src="../../../../../assets/images/viewer_icon/participantVoice.gif" class="voiceIcon"
              style="display: none;" />
          </div>
        </div><!-- DEmo end -->
      </div>
    </div>
  </div>
  <!-- {{isHost}} {{participantTitleText}} -->
  <!-- <div *ngIf="isHost && participantTitleText == 'Participant List'" class="AddParticipantIcon">
    <img src="../../assets/images/viewer_icon/AddParticipant.png" (click)="inviteParticipants()">
  </div> -->
  <div [hidden]="participantTitleText != 'Invite Participant'" class="inviteParticipants">
    <div class="AddParticipantDiv">
      <!-- <span>Add Participant</span> -->
      <div class="addParticipantTag">
        <mat-form-field class="example-full-width gselect demo-chip-list addParticipantFormField">
          <div class="addParticipantMaxHeightDiv">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let participant of participantList;let indx=index;" [selectable]="selectable"
                [removable]="removable" (removed)="remove(participant,indx)"
                [ngClass]="{'invalidEmail': !participant.valid,'alreadyEmail': participant.alreadyExist}">
                {{participant.displayName}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="Add Participants" #particpantInput [formControl]="participantCtrl"
                [matAutocomplete]="auto" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)" (focusout)="participantInputfocusOut($event)"
                (focusin)="participantInputfocusIn($event)" />
            </mat-chip-list>
          </div>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let user of filteredParticipants | async" [value]="user">
              <div class="participant-list-text">
                <i class="fa fa-user-circle-o usericon" aria-hidden="true"></i>
                <div class="displayDiv">
                  <div class="displayName">{{ user.displayName }}</div>
                  <div class="email">{{user.email}}</div>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="participantTextEntered" class="participantTextError">Press enter to validate Email id</div>
      </div>
    </div>
    <div class="addParticipantBtn">
      <button type="button" *ngIf="!isInviteParticipantClicked" class="btn-default float-right"
        (click)="cancelAddParticipant()">Cancel</button>
      <button type="button" *ngIf="!isInviteParticipantClicked" class="btn-default searchbtn float-right"
        (click)="inviteParticipantsServiceCall()"
        [ngClass]="{'disableFunction': participantList.length == 0}">Invite</button>
      <button *ngIf="isInviteParticipantClicked" class="btn-default searchbtn float-right" type="button" disabled>
        <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw; width: 1vw; height: 1vw;"
          role="status" aria-hidden="true"></span>
        <span>Inviting...</span>
      </button>
    </div>
  </div>
</div>