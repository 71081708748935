const html2canvas = require("html2canvas");

(function () {

    if (typeof window.Event === "function") return false;

    function Event(event, params) {
        //params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent('Event');
        evt.initEvent(event, true, false);
        //evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
        return evt;
    }

    Event.prototype = window.Event.prototype;

    window.Event = Event;
})();

function ScreenShotTool(OpenSeadragon) {
    "use strict";
    //sstViewerObj = {};
    ScreenShotTool.OSD = OpenSeadragon;

    return function (id, canvasDimensions) {
        return new ScreenShotTool.ScreenBoard(id, canvasDimensions);
    };
}


(function (ss) {
    "use strict";

    ss.ScreenBoard = function (parentElemParam, canvasDimensions) {
        //sstViewerObj.parentElemParam = parentElemParam;
        var selfBoard = this,
            canvasElement,
            onAnnotationCreated = typeof Event === "function" ? new Event('onAnnotationCreated') : window.event,
            onAnnotationDeleted = typeof Event === "function" ? new Event('onAnnotationDeleted') : window.event,
            onAnnotationEdited = typeof Event === "function" ? new Event('onAnnotationEdited') : window.event;
        this.allAnnotations = [];
        this.pixelToNanometer = 0;

        function getDimensions(baseElement) {
            //To Do: In case of OpenSeadragon, we should fetch proper image height width and top bottom positions here
            return baseElement.getClientRects()[0];
        }

        function createCanvasOverlay(element) {
            var canvasElem = document.createElement("canvas"),
                baseElementId = element.getAttribute("id");
            canvasElem.id = "screenshot-overlay-" + selfBoard.id;
            //To Do: Check if below line is necessary
            canvasElem.style.position = "absolute";
            canvasElem.style.top = "0px";
            canvasElem.style.left = "0px";
            canvasElem.style.width = "100%";
            canvasElem.style.height = "100%";
            canvasElem.style.zIndex = "999999998";
            canvasElem.style.cursor = "crosshair";
            return canvasElem;
        }

        if (typeof parentElemParam === "string") {
            this.id = parentElemParam;
            this.parentElem = document.getElementById(this.id);
        } else {
            this.boardType = "OpenSeadragon";
            this.parentElem = parentElemParam.element;
            this.osdViewer = parentElemParam;
            this.id = this.parentElem.getAttribute("id");
        }

        this.canvasDimensions = canvasDimensions;

        if (!this.canvasDimensions) {
            this.canvasDimensions = getDimensions(this.parentElem);
        }

        canvasElement = createCanvasOverlay(this.parentElem);
        //To Do: Check if this is no harmful ever
        this.parentElem.style.position = "relative";
        this.parentElem.appendChild(canvasElement);

        this.overlayElem = canvasElement;

        this.actions = new ss.actions(this.overlayElem);
        this.actions.attachActions();
        //To Do: should hideOverlay belong to this or should that be private function?
        this.hideOverlay();

        this.annotations = [];
        this.implementedAction = null;
    };

    ss.ScreenBoard.prototype.drawScreenShotShape = function (annotationType, scalebar, threeImage) {
        this.showOverlay();
        console.log(scalebar);
        switch (annotationType) {
            case "Rectangular":
                this.implementedAction = new ss.rectangularAction(scalebar, threeImage);
                break;
            case "Freeform":
                this.implementedAction = new ss.closedFreeformAction(scalebar, threeImage);
                break;
            case "FullScreenShot":
                this.implementedAction = new ss.fullScreenShotAction(scalebar, threeImage);
                break;
        }
        // this.implementedAction.marker = isMarker;
        this.implementedAction.id = this.id;
        this.actions.setBehavior(this.implementedAction);
        if (this.implementedAction.type == "FullScreenShot") {
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("mouseup", false, true);
            let element = document.querySelector('#screenshot-overlay-' + this.implementedAction.id);
            element.dispatchEvent(evt);
        }
    };

    ss.ScreenBoard.prototype.getOverlay = function () {
        return this.overlayElem;
    };

    ss.ScreenBoard.prototype.showOverlay = function () {
        var context = this.overlayElem.getContext("2d");
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        this.overlayElem.style.display = "block";
    };

    //To Do: should hideOverlay belong to this or should that be private function?
    ss.ScreenBoard.prototype.hideOverlay = function () {
        this.overlayElem.style.display = "none";
    };
}(ScreenShotTool));


(function (ss) {
    "use strict";

    var hasTouch = !!window.hasOwnProperty('ontouchstart'),
        ACTIONS = {
            DOWN: hasTouch ? "touchstart" : "mousedown",
            MOVE: hasTouch ? "touchmove" : "mousemove",
            UP: hasTouch ? "touchend" : "mouseup",
            LEAVE: hasTouch ? "touchleave" : "mouseleave"
        };

    ss.actions = function (overlayElem) {
        this.baseElement = overlayElem;
        this.isActionStarted = false;
        this.isActionCompleted = false;
        this.implementedAction = null;
    };

    ss.actions.prototype.attachActions = function () {
        var selfActions = this;

        function getRelativePoints(x, y) {
            var parentPos = selfActions.baseElement.getClientRects()[0];
            return {
                x: x - parentPos.left,
                y: y - parentPos.top
            };
        }

        this.mousedownListener = function (event) {
            selfActions.isActionStarted = true;
            selfActions.isActionCompleted = false;
            selfActions.baseElement.width = parseFloat(selfActions.baseElement.getClientRects()[0].width);
            selfActions.baseElement.height = parseFloat(selfActions.baseElement.getClientRects()[0].height);
            if (window.getSelection) {
                if (window.getSelection().empty) { // Chrome
                    window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) { // Firefox
                    window.getSelection().removeAllRanges();
                }
            } else if (document.selection) { // IE?
                document.selection.empty();
            }
        };

        this.mousemoveListener = function (event) {
            if (selfActions.isActionStarted && !selfActions.isActionCompleted) {
                if (!event.x || !event.y) {
                    event.x = event.touches[0].clientX;
                    event.y = event.touches[0].clientY;
                }
                selfActions.implementedAction.actionChangeBehavior(selfActions.baseElement, getRelativePoints(event.x, event.y));
            }
        };

        this.mouseupListener = function (event) {
            selfActions.isActionStarted = false;
            selfActions.isActionCompleted = true;
            if (!event.x || !event.y) {
                event.x = 600;
                event.y = 600;
            }
            selfActions.implementedAction.actionCompleteBehavior(selfActions.baseElement, getRelativePoints(event.x, event.y));
            if (!selfActions.implementedAction.marker) {
                var editorPos = {
                    x: event.x,
                    y: event.y
                };
                ss.Editor("onDrawn", editorPos, selfActions.implementedAction, selfActions.baseElement);

            } else {
                if (selfActions.implementedAction && selfActions.implementedAction.onSave) {
                    selfActions.implementedAction.onSave('');
                    var onAnnotationSave = typeof Event === "function" ? new Event('onAnnotationSave') : window.Event;
                    onAnnotationSave.annotation = selfActions.implementedAction;
                    selfActions.baseElement.parentNode.dispatchEvent(onAnnotationSave);
                }
            }
        };

        this.mouseleaveListener = function (event) {
            if (selfActions.isActionStarted && !selfActions.isActionCompleted) {
                selfActions.mouseupListener(event);
                if (window.getSelection) {
                    if (window.getSelection().empty) { // Chrome
                        window.getSelection().empty();
                    } else if (window.getSelection().removeAllRanges) { // Firefox
                        window.getSelection().removeAllRanges();
                    }
                } else if (document.selection) { // IE?
                    document.selection.empty();
                }
            }
        };

        this.baseElement.addEventListener(ACTIONS.DOWN, this.mousedownListener);
        this.baseElement.addEventListener(ACTIONS.MOVE, this.mousemoveListener);
        this.baseElement.addEventListener(ACTIONS.UP, this.mouseupListener);
        this.baseElement.addEventListener(ACTIONS.LEAVE, this.mouseleaveListener);
    };

    ss.actions.prototype.detachActions = function () {
        this.baseElement.removeEventListener(ACTIONS.DOWN, this.mousedownListener);
        this.baseElement.removeEventListener(ACTIONS.MOVE, this.mousemoveListener);
        this.baseElement.removeEventListener(ACTIONS.UP, this.mouseupListener);
        this.baseElement.removeEventListener(ACTIONS.LEAVE, this.mouseleaveListener);
    };

    ss.actions.prototype.setBehavior = function (implementedAction) {
        //To Do: validate allowed if implementedAction is among the allowed ones
        this.implementedAction = implementedAction;
    };

}(ScreenShotTool));

(function (ss) {
    "use strict";

    var editorHTML = '<div data-backdrop="static" data-keyboard="false" class="modal fade screenshot-editor-field" id="screenshot-editor" tabindex="-1" role="dialog" aria-labelledby="screenshotTitle" aria-hidden="true">' +
        '<div class="modal-dialog modal-dialog-centered popupwidth" role="document">' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '<div class="col-md-6">' +
        '<p class="modal-title dialog-title" id="screenshotTitle">Screenshot</p>' +
        '</div>' +
        '<div class="col-md-6">' +
        '<button id="screenshot-editor-button-close" type="button" class="close" data-dismiss="modal" style="font-size: 1.5vw">×</button>' +
        '</div>' +
        '</div>' +
        '<div class="modal-body">' +
        // row for radio for annotations
        '<div class="row padding_tabs">' +
        '<div class="col-md-3">' +
        '<label>Select</label>' +
        '</div>' +
        '<div class="col-md-9">' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="screenshotType" id="withAnno" value="1">' +
        '<label class="form-check-label radio" for="withAnno">With Annotation &nbsp;</label>' +
        '</div>' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="screenshotType" id="withoutAnno" value="2" checked>' +
        '<label class="form-check-label radio" for="withoutAnno">Without Annotation</label>' +
        '</div>' +
        '</div>' +
        '</div>' +
        // row for radio for annotations ends
        // row for radio for Scalebar
        '<div class="row padding_tabs">' +
        '<div class="col-md-3">' +
        '<label>Scalebar Select</label>' +
        '</div>' +
        '<div class="col-md-9">' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="scalebar" id="withScalebar" value="true" checked>' +
        '<label class="form-check-label radio" for="withScalebar">With Scalebar &nbsp;</label>' +
        '</div>' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="scalebar" id="withoutScalebar" value="false">' +
        '<label class="form-check-label radio" for="withoutScalebar">Without Scalebar &nbsp;</label>' +
        '</div>' +
        '</div>' +
        '</div>' +
        // row for radio for Scalebar ends
        // row for input filename
        '<div class="row padding_tabs">' +
        '<div class="col-md-3">' +
        '<label>Filename</label>' +
        '</div>' +
        '<div class="col-md-9 filenm">' +
        '<input type="text" class="ng-pristine ng-valid ng-touched" name="screenshotFileName" placeholder="Enter Filename" class="filenm" maxlength="200">' +
        '</div>' +
        '</div>' +
        // row for input filename ends 
        // row for resolutions
        '<div class="row padding_tabs">' +
        '<div class="col-md-3">' +
        '<label>Resolution</label>' +
        '</div>' +
        '<div class="col-md-9 resolutionMain">' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="resolutionType" id="resolutionLow" value="0.8">' +
        '<label class="form-check-label radio" for="resolutionLow"> Low &nbsp;</label>' +
        '</div>' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="resolutionType" id="resolutionMid" value="0.9">' +
        '<label class="form-check-label radio" for="resolutionMid"> Medium &nbsp;</label>' +
        '</div>' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="resolutionType" id="resolutionHigh" value="1" checked>' +
        '<label class="form-check-label radio" for="resolutionHigh"> High &nbsp;</label>' +
        '</div><br/>' +
        '<div class="form-check form-check-inline" style="margin-top: .8vw;">' +
        '<input class="form-check-input" type="radio" name="resolutionType" id="resolutionCustom" value="0.5" >' +
        '<label class="form-check-label radio" for="resolutionCustom"> Custom</label>' +
        '<input type="number" id="customResolutionType" style="width: 50px;     padding: .2vw .3vw;margin:0px 2px 0px 5px; display:none;" min="10" max="100" value="50"><span id="customResolutionPercentage" style="display:none;">%</span>' +
        '</div>' +
        '</div>' +
        '</div>' +
        // row for resolutions ends 
        // row for DPI
        '<div class="row padding_tabs">' +
        '<div class="col-md-3">' +
        '<label>DPI</label>' +
        '</div>' +
        '<div class="col-md-9 dpiMain">' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="dpiType" id="dpiLow" value="0.8">' +
        '<label class="form-check-label radio" for="dpiLow"> Low &nbsp;</label>' +
        '</div>' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="dpiType" id="dpiMid" value="0.9" checked>' +
        '<label class="form-check-label radio" for="dpiMid"> Medium &nbsp;</label>' +
        '</div>' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" type="radio" name="dpiType" id="dpiHigh" value="1" checked>' +
        '<label class="form-check-label radio" for="dpiHigh"> High &nbsp;</label>' +
        '</div><br/>' +
        '<div class="form-check form-check-inline" style="margin-top: .8vw;">' +
        '<input class="form-check-input" type="radio" name="dpiType" id="dpiCustom" value="0.5" >' +
        '<label class="form-check-label radio" for="dpiCustom"> Custom</label>' +
        '<input type="number" id="customDpiType" style="width: 50px; margin:0px 2px 0px 5px;     padding: .2vw .3vw;display:none;" min="10" max="100" value="50"><span id="customDpiPercentage" style="display:none;">%</span>' +
        '</div>' +
        '</div>' +
        '</div>' +
        // row for DPI ends
        '</div>' +
        '<div class="modal-footer">' +
        '<div class="filterbtn" style="margin-right: 1vw;margin-bottom: .5vw;">' +
        '<button id="screenshot-editor-button-save" type="button" class="btn orangebtn savebtn" style="padding: .4vw 1.5vw;">Save</button>' +
        '<button id="screenshot-editor-button-cancel" type="button" class="btn btn-default" style="padding: .46vw 1.5vw;" data-dismiss="modal">Close</button>' +

        '</div>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>',
        editorElement = document.createElement("div")
    ss.Editor = function (userAction, position, implementedAction, baseElement) {
        var isMarker = implementedAction.marker;

        editorElement.innerHTML = editorHTML;
        document.body.appendChild(editorElement);

        return new Promise(function (resolve, reject) {
            editorElement.style.width = document.documentElement.scrollWidth + "px";
            editorElement.style.height = document.documentElement.scrollHeight + "px";
            editorElement.style.top = "0";
            editorElement.style.left = "0";
            editorElement.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
            document.getElementById("screenshot-editor").style.display = "block";
            $('#screenshot-editor').modal('show');

            $('input[type=radio][name=resolutionType]').change(function (e) {
                if (this.id == "resolutionCustom") {
                    $(this).closest(".resolutionMain").find("#customResolutionType").css("display", "inline-block");
                    $(this).closest(".resolutionMain").find("#customResolutionPercentage").css("display", "inline-block");
                    $(this).attr("value", "0.5");
                } else {
                    $(this).closest(".resolutionMain").find("#customResolutionType").css("display", "none").val("50");
                    $(this).closest(".resolutionMain").find("#customResolutionPercentage").css("display", "none");
                    $(this).closest(".resolutionMain").find("#resolutionCustom").attr("value", "0.5");
                }
            });

            $('input[type=radio][name=dpiType]').change(function (e) {
                if (this.id == "dpiCustom") {
                    $(this).closest(".dpiMain").find("#customDpiType").css("display", "inline-block");
                    $(this).closest(".dpiMain").find("#customDpiPercentage").css("display", "inline-block");
                    $(this).attr("value", "0.5");
                } else {
                    $(this).closest(".dpiMain").find("#customDpiType").css("display", "none").val("50");
                    $(this).closest(".dpiMain").find("#customDpiPercentage").css("display", "none");
                    $(this).closest(".dpiMain").find("#dpiCustom").attr("value", "0.5");
                }
            });

            $('#customDpiType, #customResolutionType').on('change', function (e) {
                if (e.keyCode !== 46 /*keycode for delete*/ && e.keyCode !== 8 /*keycode for backspace*/) {
                    if ($(this).val() > 100) {
                        e.preventDefault();
                        $(this).val(100);
                    } else if ($(this).val() < 10) {
                        e.preventDefault();
                        $(this).val(10);
                    }
                }
                var customValue = ($(this).val() != "") ? ($(this).val() / 100) : "";
                if (this.id == "customDpiType") {
                    $(this).closest(".dpiMain").find("#dpiCustom").val(customValue);
                } else {
                    $(this).closest(".resolutionMain").find("#resolutionCustom").val(customValue);
                }
            });

            var onSave = function () {
                var comment = document.querySelectorAll("input[name=screenshotType]:checked")[0].value;
                var isScalebar = document.querySelectorAll("input[name=scalebar]:checked")[0].value;
                var resolutionValue = document.querySelectorAll("input[name=resolutionType]:checked")[0].value;
                var dpiValue = document.querySelectorAll("input[name=dpiType]:checked")[0].value;
                var fileName = document.querySelectorAll("input[name=screenshotFileName]")[0].value;
                if (!fileName || fileName.trim() == "") {
                    if ($("body").find("#fileNameToast").length == 0) {
                        $("body").append('<div id="fileNameToast" style="display:none; background-color: #333; color: #fff; text-align: center; padding: 16px;' +
                            'border-radius: 2px; position: fixed; z-index: 1050; left: 50%; width: 650px; margin-left: -325px; bottom: 30px; font-size: 17px;' +
                            '">Please enter FileName for screenshot </div>');
                    }
                    document.querySelectorAll("input[name=screenshotFileName]")[0].focus();
                    $("body").find("#fileNameToast").show();
                    setTimeout(function () {
                        $("body").find("#fileNameToast").hide();
                    }, 3000);
                    return;
                }

                $('#screenshot-editor').modal('hide');
                fileName = fileName ? fileName : "screenshot";
                if (implementedAction.type == "Rectangular") {
                    if (comment == 1) {
                        //html2canvas(document.querySelector("#" + implementedAction.id + " .openseadragon-canvas")).then(canvas => {
                        let devicePixelRatio = window.devicePixelRatio;
                        let canvas = $("#" + implementedAction.id + " .openseadragon-canvas").children('canvas')[0];
                        var canvas1 = document.createElement("canvas");
                        canvas1.width = implementedAction.width * parseFloat(resolutionValue);
                        canvas1.height = implementedAction.height * parseFloat(resolutionValue);
                        var ctx1 = canvas1.getContext("2d");

                        var sx = ((implementedAction.x + parseFloat(window.scrollX.toFixed(2))) * devicePixelRatio);
                        var sy = ((implementedAction.y + parseFloat(window.scrollY.toFixed(2))) * devicePixelRatio);
                        var sWidth = (implementedAction.width * devicePixelRatio);
                        var sHeight = (implementedAction.height * devicePixelRatio);
                        var InitCordX = 0;
                        var InitCordY = 0;
                        var desiredWidth = implementedAction.width * parseFloat(resolutionValue);
                        var desiredHeight = implementedAction.height * parseFloat(resolutionValue);

                        ctx1.drawImage(canvas, sx, sy, sWidth, sHeight, InitCordX, InitCordY, desiredWidth, desiredHeight);

                        implementedAction.threeImage.width = canvas.width;
                        implementedAction.threeImage.height = canvas.height;
                        ctx1.drawImage(implementedAction.threeImage, implementedAction.x, implementedAction.y, implementedAction.width, implementedAction.height, InitCordX, InitCordY, desiredWidth, desiredHeight);

                        if (isScalebar == "true") {
                            let scalbar = createScalebar();
                            ctx1.drawImage(scalbar, desiredWidth - scalbar.width, desiredHeight - scalbar.height, scalbar.width, scalbar.height);
                        }

                        if (parseFloat(dpiValue) < 1) {
                            downScaleCanvas(canvas1, parseFloat(dpiValue));
                        }

                        var link = document.createElement("a");
                        link.href = canvas1.toDataURL('image/jpeg'); //function blocks CORS
                        link.download = `${fileName}.jpeg`;
                        link.click();
                        editorElement.remove();
                        baseElement.style.display = "none";
                        //});
                    } else {
                        var canvas = document.querySelector("#" + implementedAction.id + " canvas");
                        var canvas1 = document.createElement("canvas");
                        var ctx1 = canvas1.getContext("2d");
                        canvas1.width = implementedAction.width * parseFloat(resolutionValue);
                        canvas1.height = implementedAction.height * parseFloat(resolutionValue);
                        var sx = ((implementedAction.x + parseFloat(window.scrollX.toFixed(2))) * window.devicePixelRatio);
                        var sy = ((implementedAction.y + parseFloat(window.scrollY.toFixed(2))) * window.devicePixelRatio);
                        var sWidth = (implementedAction.width * window.devicePixelRatio);
                        var sHeight = (implementedAction.height * window.devicePixelRatio);
                        var InitCordX = 0;
                        var InitCordY = 0;
                        var desiredWidth = (implementedAction.width * parseFloat(resolutionValue));
                        var desiredHeight = (implementedAction.height * parseFloat(resolutionValue));

                        ctx1.drawImage(canvas, sx, sy, sWidth, sHeight, InitCordX, InitCordY, desiredWidth, desiredHeight);

                        if (isScalebar == "true") {
                            let scalbar = createScalebar();
                            ctx1.drawImage(scalbar, desiredWidth - scalbar.width, desiredHeight - scalbar.height, scalbar.width, scalbar.height);
                        }

                        if (parseFloat(dpiValue) < 1) {
                            downScaleCanvas(canvas1, parseFloat(dpiValue));
                        }

                        var link = document.createElement("a");
                        link.href = canvas1.toDataURL('image/jpeg'); //function blocks CORS
                        link.download = `${fileName}.jpeg`;
                        link.click();
                        editorElement.remove();
                        baseElement.style.display = "none";
                        //return resolve("saved");
                    }
                } else if (implementedAction.type == "Freeform") {
                    if (comment == 1) {
                        // html2canvas(document.querySelector("#" + implementedAction.id + " .openseadragon-canvas")).then(canvas => {
                        let canvas = $("#" + implementedAction.id + " .openseadragon-canvas").children('canvas')[0];

                        var canvas1 = document.createElement("canvas");
                        canvas1.width = canvas.width;
                        canvas1.height = canvas.height;
                        var ctx1 = canvas1.getContext("2d");
                        ctx1.fillStyle = '#fff';
                        ctx1.fillRect(0, 0, canvas1.width, canvas1.height);
                        ctx1.beginPath();
                        ctx1.moveTo(((implementedAction.points[0].x + parseFloat(window.scrollX.toFixed(2))) * window.devicePixelRatio), ((implementedAction.points[0].y + parseFloat(window.scrollY.toFixed(2))) * window.devicePixelRatio));
                        for (var i = 1; i < implementedAction.points.length; i++) {
                            ctx1.lineTo(((implementedAction.points[i].x + parseFloat(window.scrollX.toFixed(2))) * window.devicePixelRatio), ((implementedAction.points[i].y + parseFloat(window.scrollY.toFixed(2))) * window.devicePixelRatio));
                        }
                        //added comment for test.
                        ctx1.closePath();
                        ctx1.clip();
                        ctx1.drawImage(canvas, 0, 0);
                        //ctx1.restore();
                        
                        ctx1.drawImage(implementedAction.threeImage, 0, 0);

                        var canvas2 = document.createElement("canvas");
                        canvas2.width = implementedAction.actionBoundaries.width * parseFloat(resolutionValue);
                        canvas2.height = implementedAction.actionBoundaries.height * parseFloat(resolutionValue);
                        var ctx2 = canvas2.getContext("2d");

                        var sx = ((implementedAction.actionBoundaries.x + parseFloat(window.scrollX.toFixed(2))) * window.devicePixelRatio);
                        var sy = ((implementedAction.actionBoundaries.y + parseFloat(window.scrollY.toFixed(2))) * window.devicePixelRatio);
                        var sWidth = (implementedAction.actionBoundaries.width * window.devicePixelRatio);
                        var sHeight = (implementedAction.actionBoundaries.height * window.devicePixelRatio);
                        var InitCordX = 0;
                        var InitCordY = 0;
                        var desiredWidth = implementedAction.actionBoundaries.width * parseFloat(resolutionValue);
                        var desiredHeight = implementedAction.actionBoundaries.height * parseFloat(resolutionValue);
                        ctx2.drawImage(canvas1, sx, sy, sWidth, sHeight, InitCordX, InitCordY, desiredWidth, desiredHeight);

                        if (isScalebar == "true") {
                            let scalbar = createScalebar();
                            ctx2.drawImage(scalbar, desiredWidth - scalbar.width, desiredHeight - scalbar.height, scalbar.width, scalbar.height);
                        }

                        if (parseFloat(dpiValue) < 1) {
                            downScaleCanvas(canvas2, parseFloat(dpiValue));
                        }

                        var link = document.createElement("a");
                        link.href = canvas2.toDataURL('image/jpeg'); //function blocks CORS
                        link.download = `${fileName}.jpeg`;
                        link.click();
                        editorElement.remove();
                        baseElement.style.display = "none";
                        // });
                    } else {
                        var canvas = document.querySelector("#" + implementedAction.id + " canvas");
                        var canvas1 = document.createElement("canvas");
                        canvas1.width = canvas.width;
                        canvas1.height = canvas.height;
                        var ctx1 = canvas1.getContext("2d");
                        //ctx1.putImageData(imageData, 0, 0);
                        ctx1.fillStyle = '#fff';
                        ctx1.fillRect(0, 0, canvas1.width, canvas1.height);
                        ctx1.beginPath();
                        ctx1.moveTo(((implementedAction.points[0].x + parseFloat(window.scrollX.toFixed(2))) * window.devicePixelRatio), ((implementedAction.points[0].y + parseFloat(window.scrollY.toFixed(2))) * window.devicePixelRatio));
                        for (var i = 1; i < implementedAction.points.length; i++) {
                            ctx1.lineTo(((implementedAction.points[i].x + parseFloat(window.scrollX.toFixed(2))) * window.devicePixelRatio), ((implementedAction.points[i].y + parseFloat(window.scrollY.toFixed(2))) * window.devicePixelRatio));
                        }
                        //added comment for test.
                        ctx1.closePath();
                        ctx1.clip();
                        ctx1.drawImage(canvas, 0, 0);
                        //ctx1.restore();

                        var canvas2 = document.createElement("canvas");
                        canvas2.width = implementedAction.actionBoundaries.width * parseFloat(resolutionValue);
                        canvas2.height = implementedAction.actionBoundaries.height * parseFloat(resolutionValue);
                        var ctx2 = canvas2.getContext("2d");

                        var sx = ((implementedAction.actionBoundaries.x + parseFloat(window.scrollX.toFixed(2))) * window.devicePixelRatio);
                        var sy = ((implementedAction.actionBoundaries.y + parseFloat(window.scrollY.toFixed(2))) * window.devicePixelRatio);
                        var sWidth = (implementedAction.actionBoundaries.width * window.devicePixelRatio);
                        var sHeight = (implementedAction.actionBoundaries.height * window.devicePixelRatio);
                        var InitCordX = 0;
                        var InitCordY = 0;
                        var desiredWidth = implementedAction.actionBoundaries.width * parseFloat(resolutionValue);
                        var desiredHeight = implementedAction.actionBoundaries.height * parseFloat(resolutionValue);
                        ctx2.drawImage(canvas1, sx, sy, sWidth, sHeight, InitCordX, InitCordY, desiredWidth, desiredHeight);

                        if (isScalebar == "true") {
                            let scalbar = createScalebar();
                            ctx2.drawImage(scalbar, desiredWidth - scalbar.width, desiredHeight - scalbar.height, scalbar.width, scalbar.height);
                        }

                        if (parseFloat(dpiValue) < 1) {
                            downScaleCanvas(canvas2, parseFloat(dpiValue));
                        }

                        var link = document.createElement("a");
                        link.href = canvas2.toDataURL('image/jpeg'); //function blocks CORS
                        link.download = `${fileName}.jpeg`;
                        link.click();
                        editorElement.remove();
                        baseElement.style.display = "none";
                        //return resolve("saved");
                    }
                } else {
                    if (comment == 1) {
                        // html2canvas(document.querySelector("#" + implementedAction.id + " .openseadragon-canvas")).then(canvas => {
                        let canvas = $("#" + implementedAction.id + " .openseadragon-canvas").children('canvas')[0];
                        implementedAction.threeImage.width = canvas.width;
                        implementedAction.threeImage.height = canvas.height;
                        var context = canvas.getContext('2d')
                        context.drawImage(implementedAction.threeImage, 0, 0, canvas.width, canvas.height);

                        if (isScalebar == "true") {
                            let scalbar = createScalebar();
                            context.drawImage(scalbar, canvas.width - scalbar.width, canvas.height - scalbar.height, scalbar.width, scalbar.height);
                        }
                        var link = document.createElement("a");
                        link.href = canvas.toDataURL('image/jpeg'); //function blocks CORS
                        link.download = `${fileName}.jpeg`;
                        link.click();
                        editorElement.remove();
                        baseElement.style.display = "none";
                        // });
                    } else {
                        var canvas = document.querySelector("#" + implementedAction.id + " canvas");
                        if (isScalebar == "true") {
                            var context = canvas.getContext('2d')
                            let scalbar = createScalebar();
                            context.drawImage(scalbar, canvas.width - scalbar.width, canvas.height - scalbar.height, scalbar.width, scalbar.height);
                        }
                        var link = document.createElement("a");
                        link.href = canvas.toDataURL('image/jpeg'); //function blocks CORS
                        link.download = `${fileName}.jpeg`;
                        link.click();
                        editorElement.remove();
                        baseElement.style.display = "none";
                    }
                }
                //To Do: emit onAnnotationCreated event

            },
                onCancel = function () {
                    //do some common code
                    $('#screenshot-editor').modal('hide');
                    editorElement.remove();
                    baseElement.style.display = "none";
                };
            document.getElementById("screenshot-editor-button-save").addEventListener("click", onSave);
            document.getElementById("screenshot-editor-button-cancel").addEventListener("click", onCancel);
            document.getElementById("screenshot-editor-button-close").addEventListener("click", onCancel);
        });

        function createScalebar() {
            var scalbarText = $("#" + implementedAction.sacalBar).find('.scalebarDivText');
            var scalebarLine = $("#" + implementedAction.sacalBar).find('.scalebarMainDiv .scaleBarLine');
            var sacalValue = $(scalbarText).text();

            var scalebarCanvas = document.createElement('canvas');
            scalebarCanvas.width = ($(scalebarLine).width() + 20);
            scalebarCanvas.height = ($(scalbarText).height() + 20);
            var scalbarContext = scalebarCanvas.getContext("2d");
            scalbarContext.font = "0.75vw serif";
            scalbarContext.strokeStyle = "#F47625";
            scalbarContext.fillStyle = "#F47625";
            scalbarContext.lineWidth = 3;
            scalbarContext.fillText(sacalValue, ($(scalebarLine).width() / 4), 10)
            // main line
            scalbarContext.beginPath();
            scalbarContext.moveTo(0, ($(scalbarText).height() + 2));
            scalbarContext.lineTo($(scalebarLine).width(), ($(scalbarText).height() + 2));
            scalbarContext.stroke();
            // end main line 
            // left side line
            scalbarContext.beginPath();
            scalbarContext.moveTo(0, ($(scalbarText).height() - 4));
            scalbarContext.lineTo(0, ($(scalbarText).height() + 8));
            scalbarContext.stroke();
            // left side line end 
            // right side line
            scalbarContext.beginPath();
            scalbarContext.moveTo($(scalebarLine).width(), ($(scalbarText).height() - 4));
            scalbarContext.lineTo($(scalebarLine).width(), ($(scalbarText).height() + 8));
            scalbarContext.stroke();
            // right side line end 
            return scalebarCanvas;
        }
    };
}(ScreenShotTool));

(function (ss) {
    "use strict";
    var actionCompleted = true;

    ss.rectangularAction = function (scalebar, threeImage) {
        this.type = "Rectangular";
        this.color = "#00ff00";
        this.sacalBar = scalebar;
        this.threeImage = threeImage;
        this.element = null;
    };

    ss.rectangularAction.prototype.actionChangeBehavior = function (baseElement, point) {
        var context = baseElement.getContext("2d");
        context.strokeStyle = this.color;
        context.lineWidth = 2;
        context.fillStyle = "rgba(0,0,0,0.3)";
        if (actionCompleted) {
            context.beginPath();
            this.x = point.x;
            this.y = point.y;
            this.width = 1;
            this.height = 1;
            actionCompleted = false;
        } else {
            this.width = point.x - this.x;
            this.height = point.y - this.y;
            context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            context.beginPath();
            context.fillRect(this.x, this.y, this.width, this.height);
            context.stroke();
        }
    };

    ss.rectangularAction.prototype.actionCompleteBehavior = function (baseElement, point) {
        var context = baseElement.getContext("2d");
        context.closePath();
        this.points = [];
        actionCompleted = true;
    };

    ss.rectangularAction.prototype.showAnnotation = function (baseElement) {
        var element = document.createElement("div");
        element.setAttribute("id", "rectangular-screenshot");

        //set height and width of the canvas element
        element.style.width = Math.abs(this.width) + "px";
        element.style.height = Math.abs(this.height) + "px";
        element.style.zIndex = "2";
        //element.style.border = "1px solid black";// + this.color;
        element.style.backgroundColor = "rgba(0,0,0,0.3)"; // + this.color;

        this.size = Math.abs(this.width) * Math.abs(this.height);

        //set reference to this.freeformElement
        this.element = element;

        this.element.style.position = "absolute";
        this.element.style.top = this.height > 0 ? this.y + "px" : this.y + this.height + "px";
        this.element.style.left = this.width > 0 ? this.x + "px" : this.x + this.width + "px";

        baseElement.appendChild(this.sacalBar);
        baseElement.appendChild(this.element);
    };

}(ScreenShotTool));


(function (ss) {
    "use strict";
    var actionCompleted = true;

    function updateActionBoundaries(point, boundaries) {
        if (point.x < boundaries.x) {
            boundaries.width += boundaries.x - point.x;
            boundaries.x = point.x;
        } else if (point.x > (boundaries.x + boundaries.width)) {
            boundaries.width = point.x - boundaries.x;
        }
        if (point.y < boundaries.y) {
            boundaries.height += boundaries.y - point.y;
            boundaries.y = point.y;
        } else if (point.y > (boundaries.y + boundaries.height)) {
            boundaries.height = point.y - boundaries.y;
        }
    }

    function getActionBoundaries(points) {
        var pointIndex,
            actionBoundaries = {
                x: points[0].x,
                y: points[0].y,
                width: 0,
                height: 0
            },
            x2 = points[0].x,
            y2 = points[0].y;

        for (pointIndex = 1; pointIndex < points.length; pointIndex = pointIndex + 1) {
            //To Do: See if there is any optimum way of doing this
            var point = points[pointIndex];
            if (point.x < actionBoundaries.x) {
                actionBoundaries.x = point.x;
            } else if (point.x > x2) {
                x2 = point.x;
            }
            actionBoundaries.width = x2 - actionBoundaries.x;
            if (point.y < actionBoundaries.y) {
                actionBoundaries.y = point.y;
            } else if (point.y > y2) {
                y2 = point.y;
            }
            actionBoundaries.height = y2 - actionBoundaries.y;
        }
        return actionBoundaries;
    }
    ss.closedFreeformAction = function (scalebar, threeImage) {
        this.type = "Freeform";
        this.color = "#00ff00";
        this.lineWidth = 3;
        this.points = [];
        this.comment = "";
        this.sacalBar = scalebar;
        this.threeImage = threeImage;
        this.actionBoundaries = (this.points && this.points.length > 0 ? getActionBoundaries(this.points) : {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        });
        this.element = null;
        this.fillElement = null;
        this.tempLayer = null;
    };

    ss.closedFreeformAction.prototype.actionChangeBehavior = function (baseElement, point) {
        var context = baseElement.getContext("2d"),
            tempLayerContext = this.tempLayer ? this.tempLayer.getContext("2d") : null;

        context.strokeStyle = this.color;
        context.lineWidth = 2;
        context.fillStyle = "rgba(0,0,0,0.3)";
        if (tempLayerContext) {
            tempLayerContext.strokeStyle = this.color;
            tempLayerContext.lineWidth = context.lineWidth;
        }
        if (actionCompleted) {
            this.tempLayer = baseElement.cloneNode(true);
            this.tempLayer.style.zIndex = "2";
            this.tempLayer.style.position = "absolute";
            baseElement.parentNode.appendChild(this.tempLayer);

            this.actionBoundaries.x = point.x;
            this.actionBoundaries.y = point.y;
            context.beginPath();
            context.moveTo(point.x, point.y);
            this.points.push(point);
            actionCompleted = false;
        } else {
            context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            context.lineTo(point.x, point.y);
            // context.stroke();      
            context.fillStyle = "rgba(0,0,0,0.3)";
            context.fill();
            this.points.push(point);
            updateActionBoundaries(point, this.actionBoundaries);

            tempLayerContext.clearRect(0, 0, context.canvas.width, context.canvas.height);
            tempLayerContext.beginPath();
            tempLayerContext.moveTo(this.points[0].x, this.points[0].y);
            tempLayerContext.lineTo(point.x, point.y);
        }
    };

    ss.closedFreeformAction.prototype.actionCompleteBehavior = function (baseElement, point) {
        var context = baseElement.getContext("2d");
        context.lineTo(point.x, point.y);
        context.lineTo(this.points[0].x, this.points[0].y);
        context.closePath();
        this.points.push(point);
        this.points.push(this.points[0]);
        updateActionBoundaries(point, this.actionBoundaries);
        this.tempLayer.parentNode.removeChild(this.tempLayer);
        actionCompleted = true;
    };

    ss.closedFreeformAction.prototype.showAnnotation = function (points, baseElement) {
        var element = document.createElement("div"),
            point1 = points[0],
            ptIndex;

        element.setAttribute("id", "closed-freeform-annotation");

        points = this.points; // = zipPoints(points, 0.15);

        //if this.actionBoundaries not there get them from points and set them
        if (!this.actionBoundaries || this.actionBoundaries.width === 0) {
            this.actionBoundaries = getActionBoundaries(points);
        }

        element.style.width = this.actionBoundaries.width + "px";
        element.style.height = this.actionBoundaries.height + "px";
        element.style.zIndex = "2";

        this.size = this.actionBoundaries.width * this.actionBoundaries.height;

        for (ptIndex = 1; ptIndex < points.length; ptIndex = ptIndex + 1) {
            var point2 = points[ptIndex],
                lineElem = document.createElement("hr"),
                xdiff = point2.x - point1.x,
                ydiff = point2.y - point1.y,
                length = Math.sqrt(xdiff * xdiff + ydiff * ydiff),
                angle = Math.atan2((point2.y - point1.y), (point2.x - point1.x)) * 180 / Math.PI,
                scaleParam = "";
            lineElem.style.borderColor = "rgba(0,0,0,0.3)"; //this.color;
            lineElem.style.borderWidth = this.lineWidth + "px";
            lineElem.style.width = length * 100 / (this.actionBoundaries.width) + "%";
            lineElem.style.height = "0px";
            lineElem.style.backgroundColor = "rgba(0,0,0,0.3)";
            lineElem.style.position = "absolute";
            lineElem.style.top = (point1.y - this.actionBoundaries.y) * 100 / this.actionBoundaries.height + "%";
            lineElem.style.left = (point1.x - this.actionBoundaries.x) * 100 / this.actionBoundaries.width + "%";
            lineElem.style.margin = "0px";
            lineElem.style.transformOrigin = "left center";
            lineElem.setAttribute("noshade", "");
            lineElem.style.transform = "rotate(" + angle + "deg)" + scaleParam;
            element.appendChild(lineElem);
            point1 = points[ptIndex];
        }

        //set reference to this.element
        this.element = element;

        this.element.style.position = "absolute";
        this.element.style.top = this.actionBoundaries.y + "px";
        this.element.style.left = this.actionBoundaries.x + "px";

        baseElement.appendChild(this.element);
    };

}(ScreenShotTool));

(function (ss) {
    "use strict";
    var actionCompleted = true;
    ss.fullScreenShotAction = function (scalebar, threeImage) {
        this.type = "FullScreenShot";
        this.color = "#00ff00";
        this.lineWidth = 2;
        this.sacalBar = scalebar;
        this.threeImage = threeImage;
    };

    ss.fullScreenShotAction.prototype.actionCompleteBehavior = function () {
        actionCompleted = true;
    };

}(ScreenShotTool));

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // expose as amd module
        define([], factory);
    } else if (typeof module === 'object' && module.exports) {
        // expose as commonjs module
        module.exports = factory();
    } else {
        root.ScreenShotTool = factory();
    }
}(this, function () {
    return ScreenShotTool;
}));


function downScaleCanvas(cv, scale) {
    if (!(scale < 1) || !(scale > 0)) throw ('scale must be a positive number <1 ');
    var sqScale = scale * scale; // square scale = area of source pixel within target
    var sw = cv.width; // source image width
    var sh = cv.height; // source image height
    var tw = Math.floor(sw * scale); // target image width
    var th = Math.floor(sh * scale); // target image height
    var sx = 0,
        sy = 0,
        sIndex = 0; // source x,y, index within source array
    var tx = 0,
        ty = 0,
        yIndex = 0,
        tIndex = 0; // target x,y, x,y index within target array
    var tX = 0,
        tY = 0; // rounded tx, ty
    var w = 0,
        nw = 0,
        wx = 0,
        nwx = 0,
        wy = 0,
        nwy = 0; // weight / next weight x / y
    // weight is weight of current source point within target.
    // next weight is weight of current source point within next target's point.
    var crossX = false; // does scaled px cross its current px right border ?
    var crossY = false; // does scaled px cross its current px bottom border ?
    var sBuffer = cv.getContext('2d').
        getImageData(0, 0, sw, sh).data; // source buffer 8 bit rgba
    var tBuffer = new Float32Array(3 * tw * th); // target buffer Float32 rgb
    var sR = 0,
        sG = 0,
        sB = 0; // source's current point r,g,b
    /* untested !
    var sA = 0;  //source alpha  */

    for (sy = 0; sy < sh; sy++) {
        ty = sy * scale; // y src position within target
        tY = 0 | ty; // rounded : target pixel's y
        yIndex = 3 * tY * tw; // line index within target array
        crossY = (tY != (0 | ty + scale));
        if (crossY) { // if pixel is crossing botton target pixel
            wy = (tY + 1 - ty); // weight of point within target pixel
            nwy = (ty + scale - tY - 1); // ... within y+1 target pixel
        }
        for (sx = 0; sx < sw; sx++, sIndex += 4) {
            tx = sx * scale; // x src position within target
            tX = 0 | tx; // rounded : target pixel's x
            tIndex = yIndex + tX * 3; // target pixel index within target array
            crossX = (tX != (0 | tx + scale));
            if (crossX) { // if pixel is crossing target pixel's right
                wx = (tX + 1 - tx); // weight of point within target pixel
                nwx = (tx + scale - tX - 1); // ... within x+1 target pixel
            }
            sR = sBuffer[sIndex]; // retrieving r,g,b for curr src px.
            sG = sBuffer[sIndex + 1];
            sB = sBuffer[sIndex + 2];

            /* !! untested : handling alpha !!
               sA = sBuffer[sIndex + 3];
               if (!sA) continue;
               if (sA != 0xFF) {
                   sR = (sR * sA) >> 8;  // or use /256 instead ??
                   sG = (sG * sA) >> 8;
                   sB = (sB * sA) >> 8;
               }
            */
            if (!crossX && !crossY) { // pixel does not cross
                // just add components weighted by squared scale.
                tBuffer[tIndex] += sR * sqScale;
                tBuffer[tIndex + 1] += sG * sqScale;
                tBuffer[tIndex + 2] += sB * sqScale;
            } else if (crossX && !crossY) { // cross on X only
                w = wx * scale;
                // add weighted component for current px
                tBuffer[tIndex] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                // add weighted component for next (tX+1) px                
                nw = nwx * scale
                tBuffer[tIndex + 3] += sR * nw;
                tBuffer[tIndex + 4] += sG * nw;
                tBuffer[tIndex + 5] += sB * nw;
            } else if (crossY && !crossX) { // cross on Y only
                w = wy * scale;
                // add weighted component for current px
                tBuffer[tIndex] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                // add weighted component for next (tY+1) px                
                nw = nwy * scale
                tBuffer[tIndex + 3 * tw] += sR * nw;
                tBuffer[tIndex + 3 * tw + 1] += sG * nw;
                tBuffer[tIndex + 3 * tw + 2] += sB * nw;
            } else { // crosses both x and y : four target points involved
                // add weighted component for current px
                w = wx * wy;
                tBuffer[tIndex] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                // for tX + 1; tY px
                nw = nwx * wy;
                tBuffer[tIndex + 3] += sR * nw;
                tBuffer[tIndex + 4] += sG * nw;
                tBuffer[tIndex + 5] += sB * nw;
                // for tX ; tY + 1 px
                nw = wx * nwy;
                tBuffer[tIndex + 3 * tw] += sR * nw;
                tBuffer[tIndex + 3 * tw + 1] += sG * nw;
                tBuffer[tIndex + 3 * tw + 2] += sB * nw;
                // for tX + 1 ; tY +1 px
                nw = nwx * nwy;
                tBuffer[tIndex + 3 * tw + 3] += sR * nw;
                tBuffer[tIndex + 3 * tw + 4] += sG * nw;
                tBuffer[tIndex + 3 * tw + 5] += sB * nw;
            }
        } // end for sx 
    } // end for sy

    // create result canvas
    var resCV = document.createElement('canvas');
    resCV.width = tw;
    resCV.height = th;
    var resCtx = resCV.getContext('2d');
    var imgRes = resCtx.getImageData(0, 0, tw, th);
    var tByteBuffer = imgRes.data;
    // convert float32 array into a UInt8Clamped Array
    var pxIndex = 0; //  
    for (sIndex = 0, tIndex = 0; pxIndex < tw * th; sIndex += 3, tIndex += 4, pxIndex++) {
        tByteBuffer[tIndex] = Math.ceil(tBuffer[sIndex]);
        tByteBuffer[tIndex + 1] = Math.ceil(tBuffer[sIndex + 1]);
        tByteBuffer[tIndex + 2] = Math.ceil(tBuffer[sIndex + 2]);
        tByteBuffer[tIndex + 3] = 255;
    }
    // writing result to canvas.
    resCtx.putImageData(imgRes, 0, 0);
    return resCV;
}