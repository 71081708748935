import { GalleryModule } from './../gallery/gallery.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ConsultationService } from './consultation.service';
import { ConsultationRoutingModule } from './consultation-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultationComponent } from './consultation.component';
import { MeetingComponent } from './meeting/meeting.component';
import { MeetingCreateComponent } from './meeting/meeting-create/meeting-create.component';
import { MeetingDetailsComponent } from './meeting/meeting-details/meeting-details.component';
import { MeetingListComponent } from './meeting/meeting-list/meeting-list.component';
import { ConsultationViewerComponent } from './consultation-viewer/consultation-viewer.component';
import { LeftSideBarComponent } from './consultation-viewer/left-side-bar/left-side-bar.component';
import { RightSideBarComponent } from './consultation-viewer/right-side-bar/right-side-bar.component';
import { TopBarComponent } from './consultation-viewer/top-bar/top-bar.component';
import { ParticipantListComponent } from './consultation-viewer/right-side-bar/participant-list/participant-list.component';
import { ChatComponent } from './consultation-viewer/right-side-bar/chat/chat.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule } from '@angular/forms';
import { MeetingDialogComponent } from './meeting/meeting-dialog/meeting-dialog.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InputErrorPipe } from './models/input-error.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MeetingSettingsComponent } from './meeting/meeting-settings/meeting-settings.component';
import { AnnotationManagerComponent } from './consultation-viewer/annotation-manager/annotation-manager.component';
import { ImageViewerModule } from '../image-viewer/image-viewer.module'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [ConsultationComponent,
    MeetingComponent,
    MeetingCreateComponent,
    MeetingDetailsComponent,
    MeetingListComponent,
    ConsultationViewerComponent,
    LeftSideBarComponent,
    RightSideBarComponent,
    TopBarComponent,
    ParticipantListComponent,
    ChatComponent,
    MeetingDialogComponent,
    InputErrorPipe,
    MeetingSettingsComponent,
    AnnotationManagerComponent,
  ],
  imports: [
    CommonModule,
    ConsultationRoutingModule,
    AppMaterialModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    GalleryModule,
    LazyLoadImageModule,
    ImageViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [ConsultationService],
})
export class ConsultationModule { }
