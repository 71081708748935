<div class="galleryDialog" [ngClass]="{'dn' : data.paramValue=='viewer'}">
  <div class="row dialog-header modal-header">
    <div class="col-md-6">
      <p class="dialog-title">Gallery</p>
    </div>
    <div class="col-md-6">
      <i class="fa fa-times rightfloat" (click)="closeDialog()"></i>
    </div>
  </div>
  <app-gallery></app-gallery>
</div>
<div class="viewerDialog" *ngIf="data.paramValue=='viewer'">
  <div class="viewerheader row dialog-header modal-header">
    <div class="col-md-6">
      <button type="button" class="btn orangebtn" data-dismiss="modal" (click)="addImageToMeeting()">Add Image</button>
    </div>
    <div class="col-md-6">
      <i class="fa fa-times rightfloat" (click)="openGalleryDialog()"></i>
    </div>
  </div>
  <div id="toolBarDiv"></div>
  <div style="height: 80vh;">
    <div id="openseadragonMainDiv_1">
        <!-- <div id="openseadragonNavBar"><div id="openseadragonNavLebel"></div><div id="openseadragonNavDiv"></div></div> -->
        <div id="openseadragon_1" class="viewer"></div>
    </div>
</div>
</div>
