import { FormGroup,FormControl } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function LengthValidator(control: FormControl) {
  const lengthSeven = (control.value)?.trim().length < 7;
  const isValid = !lengthSeven;
  return isValid ? null : { 'lengthSeven': true };
}

export function CharacterValidator(control: FormControl) {
  let hasNumber = /\d/.test(control.value);
  let hasUpper = /[A-Z]/.test(control.value);
  //let hasLower = /[a-z]/.test(control.value);
  let hasSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value);
  // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
  const isValid = hasNumber && hasUpper /* && hasLower */ && hasSpecialCharacter;
  return isValid ? null : { 'character': true };
}