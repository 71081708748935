import { StudyCreateComponent } from './study-create/study-create.component';
import { StudyListingComponent } from './study-listing/study-listing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudyComponent } from './study.component';
import { StudyDetailsComponent } from './study-details/study-details.component';

const routes: Routes = [
  {
    path: '', component: StudyComponent, 
    children : [
      {path: '', component: StudyListingComponent},
      {path: 'listing', component: StudyListingComponent},
      {path: 'details/:studyGuId', component: StudyDetailsComponent},
      {path: 'edit/:studyId', component: StudyCreateComponent},
      {path: 'create', component: StudyCreateComponent},
    ]
   },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudyRoutingModule { }