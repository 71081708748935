import { Component, OnInit, Optional, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AppConfigService } from "src/app/app-config.service";
import { DigitalSignatureService } from "../digital-signature.service";
import { Action } from "../digital-signature/digital-signature.component";
import { SharedDataService } from "../shared-data.service";

@Component({
  selector: "app-modal-digital-signature",
  templateUrl: "./modal-digital-signature.component.html",
  styleUrls: ["./modal-digital-signature.component.less"],
  providers: [MatDialogModule],
  encapsulation: ViewEncapsulation.None
})
export class ModalDigitalSignatureComponent implements OnInit {
  isPassword: boolean = false;
  hide = true;
  otpHide = true;
  savingStatus = false;
  digitalFrom: FormGroup;
  isOtp: boolean = false;
  currentUser: { email: string } = null;
  actions: Action[] = [];
  selectedActions = {};
  isData = false;
  timeLeft: number;
  interval: any = 0;
  enableGenerateOtp: boolean = true;
  enableReGenrateOtp: boolean = false;
  timer: any;
  currDateTime: Date;
  setDateTimeInterval: any;
  constructor(
    @Optional() private dialogRef: MatDialogRef<ModalDigitalSignatureComponent>,
    private digitalService: DigitalSignatureService,
    private sharedService: SharedDataService,
    private formbuilder: FormBuilder,
    public translate: TranslateService,
    private configService: AppConfigService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.timeLeft = this.configService.config.generateDsOtpTimer;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currDateTime = new Date();
    this.incrDateTime();
    this.digitalService
      .getActionsByRole("All")
      .subscribe((data: Action[]) => {
        // console.log(data)
        let otparray: any = data.filter(e => e.actions == 'Otp' && e.roles == 'Settings');
        let passwordarray: any = data.filter(e => e.actions == 'Password' && e.roles == 'Settings');
        this.isOtp = otparray[0].dvenabled;
        this.isPassword = passwordarray[0].dvenabled;
        this.isData = true;
        this.createForm();
      });
  }

  ngOnDestroy() {
    if (this.setDateTimeInterval) {
      clearInterval(this.setDateTimeInterval);
    }
  }

  incrDateTime() {
    this.setDateTimeInterval = setInterval(() => {
      this.currDateTime = new Date();
    }, 1000);
  }

  createForm() {
    this.digitalFrom = this.formbuilder.group({
      email: [this.currentUser.email],
      otp: ["", this.isOtp ? [Validators.required] : null],
      password: ["", this.isPassword ? [Validators.required] : null],
      dsComment: ["", [Validators.required, Validators.maxLength(250)]]
    });
    this.digitalFrom.controls.email.disable();
  }

  close() {
    this.dialogRef.close("Cancel Request");
  }

  get controls() {
    return this.digitalFrom.controls;
  }

  onsave(event = null) {
    if (this.digitalFrom.invalid) {
      return;
    }
    else {
      if (event && event.keyCode != 13) {
        return;
      }
      const otp = this.digitalFrom.controls['otp'].value;
      const password = this.digitalFrom.controls['password'].value;
      const dsComment = this.digitalFrom.controls['dsComment'].value;
      this.digitalService.generateToken(this.currentUser.email, password, otp).subscribe((data) => {
        if (data) {
          const result = {
            dsComment,
            data
          }
          this.dialogRef.close(result);
        }
      })
    }
  }

  generateOtp() {
    this.digitalService.generateOtp().subscribe((data) => { });
    clearInterval(this.interval);
    this.setOtpTimeOut(this.timeLeft);
  }

  setOtpTimeOut(timeLeft: any) {
    this.interval = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft--;
        var hours = (timeLeft / 60);
        var rhours: any = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes: any = Math.round(minutes);
        this.timer = (rhours.toString().length == 1 ? '0' + rhours : rhours) + ':' + (rminutes.toString().length == 1 ? '0' + rminutes : rminutes);
        this.enableGenerateOtp = false;
        // this.enableReGenrateOtp = false;
      } else if (timeLeft == 0) {
        this.enableGenerateOtp = true;
        return;
      }
    }, 1000)
  }
}
