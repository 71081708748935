import { Component, OnInit, Optional, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConsultationViewerService } from './../../consultation-viewer/consultation-viewer.service';


@Component({
  selector: 'app-meeting-settings',
  templateUrl: './meeting-settings.component.html',
  styleUrls: ['./meeting-settings.component.css']
})
export class MeetingSettingsComponent implements OnInit {
  mediaDevices: any = {
    'Microphones': [],
    'Speakers': [],
    'Cameras': [],
  };
  selectedMicrophone: any = {};
  selectedSpeaker: any = {};

  constructor(@Optional() private dialogRef: MatDialogRef<MeetingSettingsComponent>,
    private consultationViewerService: ConsultationViewerService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    $(".tabcontent.selected").show();
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      for (var i = 0; i !== deviceInfos.length; ++i) {
        var deviceInfo = deviceInfos[i];
        if (deviceInfo.kind === 'audioinput') {
          let deviceObj = {
            deviceLabel: (deviceInfo.label || 'Microphone ' + (this.mediaDevices["Microphones"].length + 1)),
            deviceInfo: deviceInfo
          };
          this.mediaDevices["Microphones"].push(deviceObj);
          if (localStorage.microphoneDeviceId == deviceInfo.deviceId) {
            this.selectedMicrophone = deviceObj;
          } else if (deviceInfo.deviceId == 'default') {
            this.selectedMicrophone = deviceObj;
          }
        } else if (deviceInfo.kind === 'audiooutput') {
          let deviceObj = {
            deviceLabel: (deviceInfo.label || 'Speaker ' + (this.mediaDevices["Speakers"].length + 1)),
            deviceInfo: deviceInfo
          };
          this.mediaDevices["Speakers"].push(deviceObj);
          if (localStorage.speakerDeviceId == deviceInfo.deviceId) {
            this.selectedSpeaker = deviceObj;
          } else if (deviceInfo.deviceId == 'default') {
            this.selectedSpeaker = deviceObj;
          }
        } else if (deviceInfo.kind === 'videoinput') {
          let deviceObj = {
            deviceLabel: deviceInfo.label || 'Camera ' + (this.mediaDevices["Cameras"].length + 1),
            deviceInfo: deviceInfo
          }
          this.mediaDevices["Cameras"].push(deviceObj);
        }
      }
    }).catch((err) => {
      var y = 0;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openTab(event: any, tabClass: string) {
    $(".tablinks.selected").removeClass("selected");
    $(event.currentTarget).addClass("selected");

    $(".tabcontent").hide();
    $(".tabcontent" + tabClass).show();
  }

  microphoneChange(event) {
    localStorage.microphoneDeviceId = event.value.deviceInfo.deviceId;
    this.consultationViewerService.setSelectedMic(event.value.deviceInfo);
    // this.consultationViewerService.setSelectedMic2(event.value.deviceInfo.deviceId);
  }

  speakerChange(event) {
    if (event.value && (typeof event.value.deviceInfo.deviceId !== 'undefined')) {
      localStorage.speakerDeviceId = event.value.deviceInfo.deviceId;

      var audioElemArr = document.getElementsByClassName("userAudio");
      for (let x = 0; x < audioElemArr.length; x++) {
        audioElemArr[x]["setSinkId"](event.value.deviceInfo.deviceId)
          .then(() => {
            console.log(`Success, audio output device attached: ${event.value.deviceLabel} to element.`);
          })
          .catch(error => {
            let errorMessage = error;
            if (error.name === 'SecurityError') {
              errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            console.error(errorMessage);
            // Jump back to first output device in the list as it's the default.
          });
      }
    } else {
      console.warn('Browser does not support output device selection.');
    }
  }

}
