import { required, prop } from '@rxweb/reactive-form-validators';

export class Animals {
    private _maleStartIndex: number;
    private _maleEndIndex: number;
    private _femaleStartIndex: number;
    private _femaleEndIndex: number;
    private _maleList: String;
    private _femaleList: String;
    private _notKnownList: String;

    // @required()
    @prop()
    set maleStartIndex(value: number) {
        this._maleStartIndex = value;
    }
    get maleStartIndex() {
        return this._maleStartIndex
    }

    // @required()
    @prop()
    set maleEndIndex(value: number) {
        this._maleEndIndex = value;
    }
    get maleEndIndex() {
        return this._maleEndIndex;
    }

    // @required()
    @prop()
    set femaleStartIndex(value: number) {
        this._femaleStartIndex = value;
    }
    get femaleStartIndex() {
        return this._femaleStartIndex;
    }

    // @required()
    @prop()
    set femaleEndIndex (value: number) {
        this._femaleEndIndex = value;
    }
    get femaleEndIndex() {
        return this._femaleEndIndex;
    }

    // @required()
    @prop()
    set maleList(value: String) {
        this._maleList = value;
    }
    get maleList() {
        return this._maleList;
    }
    
    // @required()
    @prop()
    set femaleList(value: String) {
        this._femaleList = value;
    }
    get femaleList() {
        return this._femaleList;
    }

    @prop()
    set notKnownList(value: String) {
        this._notKnownList = value;
    }
    get notKnownList() {
        return this._notKnownList;
    }
}