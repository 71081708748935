import { AppConfigService } from './../../app-config.service';
import { GalleryService } from './../../gallery/gallery.service';
import { Observation } from "./Utilities/observation.model";
import { SlideReview } from "./Utilities/slideReview.model";
import { ImageViewerTopBarService } from "./image-viewer-top-bar.service";
import { GalleryComponent } from "./../../gallery/gallery.component";
import { StudyDetailsComponent } from "./../../study/study-details/study-details.component";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Input, SimpleChanges, OnChanges, HostListener } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from "@angular/forms";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { CommonService } from "./../../common.service";
import { ImageViewerService } from '../image-viewer.service';
import { TranslateService } from '@ngx-translate/core';
import { AnnotationService } from '../annotation.service';
import { LoaderComponent } from 'src/app/common/loader/loader.component';
declare const screenfull;
declare var $: any;
declare const OpenSeadragon: any;

@Component({
  selector: "app-image-viewer-top-bar",
  templateUrl: "./image-viewer-top-bar.component.html",
  styleUrls: ["./image-viewer-top-bar.component.css"]
})
export class ImageViewerTopBarComponent implements OnInit {
  // @Input() imageViewerObj: any;
  @Input() openedFrom: any;
  @Input() noImageFlag: boolean;
  @Input() viewerScreens: any;
  imageViewer: any;
  osdObj: any;
  threeJsObj: any;
  openseadragon: any;
  isFullScreen: boolean = screenfull.isFullscreen;
  gradings: any;
  organs: any;
  intensityArr = [];
  frequencyArr = [];
  slideReiviewOrgan: String = "1";
  SlideReviewFrom: FormGroup;
  allSlideReviews: any;
  slideReviewArr = [];
  organReviewPk: number;
  topBarHideTimer: any;
  currentUser: any;
  isEditReview: boolean = false;
  isSplitView: boolean = false;
  slideData: any;
  slideId: number;
  isSlideReviewLoaded: boolean = false;
  currentTopBarArrow: string = 'close';
  unmapFlag: boolean = false;
  activeRole: String = localStorage.getItem("userActiveRole");
  quantificationResult: any;
  normality: string;

  panelList: any;
  mapForm: FormGroup;
  studyListArr: any;
  cassetteArr: any;
  animalArr: any;
  stainArr: any;
  thumbnailUrl: any;
  prevSlideId: any = '';
  nextSlideId: any = '';
  galleryFilters: any;
  discardImageComment: any = '';
  unMapImageComment: any = '';
  currentImageIndex: any = 0;
  prevRoi: boolean = false;
  nextRoi: boolean = false;
  totalROIs: any = '';
  currentActiveROI: any;
  activeROIIndex: any;
  imageProfiles: Array<any> = [];
  trayData: Array<any> = [];
  trayDataF: Array<any> = [];
  enableFineTune: boolean = false;

  organIdArr: Array<any> = [];
  config: any;
  networkStatusObj: any = {
    startTime: null,
    endTime: null,
    networkStatusTimer: null
  };
  studyCreateType: String = "Normal";
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  isResultLoaded: boolean = false;
  isTopLevelTile: boolean = false;
  fineTuneMode: boolean = false;
  fineTuneEnable: boolean = false;
  currentTileLevel: number = 0;
  isReviewShareable: boolean;
  currentPathRole: string = ""; // current pathologist role could be pathologist, additional pathologist or peer reviewer
  closeSlideArr = [];

  slidesToCloseArr = []
  studyPathologist: String = "";
  studyPeerReviewer: String = "";
  additionnalPathlogists: any = [];
  // annotationDrawInfo: any;N
  disablePrevNaxtBtn: boolean = false;
  disableSlideRevieweBtn: boolean = false;
  imageViewerObj: any = [];

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.tileNetworkStatus = "false";
  }

  @HostListener('document:keypress', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.panelList['Slide_Review'].isVisible && !$('#screenshot-editor').is(":visible") && !this.isSplitView) {
      if (!this.disablePrevNaxtBtn) {
        if (event.shiftKey && event.key == "P") {
          $('#prevBtn').trigger("click")
        }

        if (event.shiftKey && event.key == "N") {
          $('#nextBtn').trigger("click")
        }
      }
    }
  }


  constructor(
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: RxFormBuilder,
    private imageViewerTopBarService: ImageViewerTopBarService,
    private commonService: CommonService,
    private imageViewerService: ImageViewerService,
    private frmbuilder: FormBuilder,
    private galleryService: GalleryService,
    public translate: TranslateService,
    private configService: AppConfigService,
    private annotationService: AnnotationService) {
    this.config = this.configService.config;
    this.enableFineTune = this.config.enableFineTune;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.imageViewerService.activeTool.subscribe((value) => {
      this.panelList = value
    });

    this.imageViewerService.fineFocusMode.subscribe((value) => {
      this.fineTuneMode = value;
    });

    this.imageViewerService.fineFocusEnable.subscribe((value) => {
      this.fineTuneEnable = value;
    });
    this.imageViewerService.disableHotKeys.subscribe((value: any) => {
      this.disablePrevNaxtBtn = value;
    });
  }

  checkTileLevel = (event) => {
    this.currentTileLevel = event.tile.level;
    if (!this.fineTuneMode) {
      this.isTopLevelTile = (event.tile.level == event.tiledImage.source.maxLevel);
    }
  }

  ngOnInit() {
    this.galleryFilters = JSON.parse(this.commonService.getGalleryFilters());
    // this.imageViewerObj.length > 1 ? this.isSplitView = true : this.isSplitView = false;
    this.imageViewerService.loadImgViewerArrResults.subscribe(resp => {
      this.imageViewerObj = resp;
      this.isSplitView = (this.imageViewerObj.length > 1);
    });
    this.commonService.viewerInfocus.subscribe(resp => {
      this.imageViewer = this.imageViewerObj[resp];
      this.totalROIs = this.imageViewer?.schemaObj?.numOfROIs ? (this.imageViewer.schemaObj.numOfROIs - 1) : 0;
      this.imageProfiles = this.imageViewer?.schemaObj?.imageProfiles?.imageProfile;
      this.osdObj = this.imageViewer?.osdObj;
      this.threeJsObj = this.imageViewer?.threeJsObj;
      this.slideData = this.imageViewer?.slideData;
      this.slideId = this.slideData?.slide?.slidePk;
      this.isReviewShareable = this.slideData?.study?.reviewSharable;

      if (this.osdObj) {
        this.osdObj.addHandler('tile-drawn', this.checkTileLevel);
      }
    });

    if (!this.isSplitView) {
      let slideListArr: any = JSON.parse(this.commonService.getSelectedGroupSlides());
      if (slideListArr) {
        let slideList = slideListArr.map((value) => { return value.slidePk });
        let currentSlideIndex = slideList.indexOf(this.slideId);
        this.prevSlideId = slideList[currentSlideIndex - 1];
        this.nextSlideId = slideList[currentSlideIndex + 1];
      }
    }

    this.thumbnailUrl = `${this.config.thumbnailUrl}`;
    if (this.openedFrom == 'scanned' || this.openedFrom == 'uploaded' || this.openedFrom == 'mapped') {
      this.mapForm = this.frmbuilder.group({
        studyGuId: ['', Validators.required],
        cassetteId: ['', Validators.required],
        animalId: ['', Validators.required],
        stainId: ['', Validators.required],
        comment: ['']
      });
      this.galleryService.getMyStudiesT(this.openedFrom).subscribe((response: any) => {
        this.studyListArr = response.data;
        // if (this.galleryFilters.studyName) {
        //   this.mapForm.controls['studyGuId'].setValue(this.galleryFilters.studyName);
        //   this.studySelectionChanged({ value: this.galleryFilters.studyName });
        // }

        if (this.openedFrom == 'mapped') {
          this.galleryService.checkUnmapFlag(this.galleryFilters.studyName).subscribe((res: any) => {
            this.unmapFlag = res.success;
          });
        }
      });
    }

    if (this.osdObj) {
      this.currentImageIndex = this.osdObj._sequenceIndex;
      this.currentActiveROI = this.currentImageIndex
      //this.totalROIs = this.osdObj.tileSources.length;
      if (this.currentImageIndex > 1) {
        this.prevRoi = true;
      } else {
        this.prevRoi = false;
      }
      if (this.currentImageIndex < this.totalROIs) {
        this.nextRoi = true;
      } else {
        this.nextRoi = false;
      }
      if (screenfull.isFullscreen) {
        this.osdObj.raiseEvent("pre-full-screen");
        setTimeout(() => {
          this.osdObj.raiseEvent("full-screen");
        }, 75);
      }
    }

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (this.activeRole == "PATHOLOGIST") {
      this.imageViewerTopBarService.getAllGradings().subscribe((response: any) => {
        this.gradings = response.data;
      });

      this.imageViewerTopBarService.getAllIntensities().subscribe((response: any) => {
        this.intensityArr = response.data;
      });

      this.imageViewerTopBarService.getAllFrequencies().subscribe((response: any) => {
        this.frequencyArr = response.data;
      });

      this.imageViewerTopBarService.getTargetOrgans(this.slideId).subscribe((response: any) => {
        this.organs = []; this.organIdArr = [];
        response.data.forEach((organData: any) => {
          if (!this.organIdArr.includes(organData.organName)) {
            this.organs.push(organData.organName);
            this.organIdArr.push(organData.organId);
          }
        });
      });
      this.imageViewerService.currentRole.subscribe((role: string) => {
        if (role) {
          this.currentPathRole = role;
        }
      });
    }

    $('#filterSearch').on('keyup click', (e) => {
      let searchVal = $(e.currentTarget).val();
      if (searchVal) {
        this.trayDataF = this.trayData.filter(elem => {
          return elem.animalId.toLowerCase().includes(searchVal.toLowerCase())
        });
      } else {
        this.trayDataF = [...this.trayData];
      }
    });
    if (!localStorage.tileNetworkStatus || localStorage.tileNetworkStatus == "false") {
      this.checkTileServerStatus();
    }

    this.imageViewerService.quantificationResults.subscribe((value: any) => {
      if (value && Object.keys(value).length) {
        this.quantificationResult = value;
        this.quantificationResult[0].FeatureName.includes('Not WNL') ? this.normality = 'NOT_WNL' : this.normality = 'WNL'
      } else {
        this.quantificationResult = {};
      }
    });

    /* this.imageViewerService.viewerTileLevel.subscribe((value) => {
      // this.isTopLevelTile = (value == this.osdObj.world._items[0].source.maxLevel);
    }); */
  }

  get mapF() {
    return this.mapForm.controls;
  }

  checkTileServerStatus() {
    if (this.networkStatusObj.networkStatusTimer && (location.pathname && location.pathname.indexOf("image-viewer") < 0)) {
      localStorage.tileNetworkStatus = "false";
      clearTimeout(this.networkStatusObj.networkStatusTimer);
      return;
    }
    localStorage.tileNetworkStatus = "true";
    this.networkStatusObj.startTime = (new Date()).getTime();
    this.imageViewerTopBarService.getTileServerStatus().subscribe((response: any) => {
      this.networkStatusObj.endTime = (new Date()).getTime();

      let timeDiff = this.networkStatusObj.endTime - this.networkStatusObj.startTime;
      let imgInternetStatusSrc = "no-connection.png";
      let internetStatusTooltip = "Server Connectivity: Unavailable";
      if (timeDiff < this.config.tileNetworkStatusRange.high) {
        imgInternetStatusSrc = "high-connection.png";
        internetStatusTooltip = "Server Connectivity: Fast";
      } else if (timeDiff < this.config.tileNetworkStatusRange.mid) {
        imgInternetStatusSrc = "mid-connection.png";
        internetStatusTooltip = "Server Connectivity: Slow";
      } else {
        imgInternetStatusSrc = "low-connection.png";
        internetStatusTooltip = "Server Connectivity: Very Slow";
      }
      $("#imgInternetStatus").attr({ "src": "../../../assets/images/viewer_icon/" + imgInternetStatusSrc, "title": internetStatusTooltip });

      this.networkStatusObj.networkStatusTimer = setTimeout(() => {
        this.checkTileServerStatus();
      }, this.config.tileNetworkStatusTime);
    }, (err: any) => {
      $("#imgInternetStatus").attr("src", "../../../assets/images/viewer_icon/no-connection.png");
      this.networkStatusObj.networkStatusTimer = setTimeout(() => {
        this.checkTileServerStatus();
      }, this.config.tileNetworkStatusTime);
    });
  }

  studySelectionChanged(e) {
    this.galleryService.getCassetteData(e.value).subscribe((res: any) => {
      this.cassetteArr = res.data;
      this.mapForm.controls['cassetteId'].setValue('');
      this.mapForm.controls['stainId'].setValue('');
      this.mapForm.controls['animalId'].setValue('');
    });
  }
  cassetteSelectionChanged(e) {
    let cassettesData = this.cassetteArr.filter(element => {
      return element.id == e.value;
    });
    this.stainArr = cassettesData[0].casseteStains;
    this.mapForm.controls['stainId'].setValue('');
    this.mapForm.controls['animalId'].setValue('');
  }

  stainSelectionChanged(e) {
    this.galleryService.getUnmappedAnimalData(this.mapForm.controls.studyGuId.value, e.value).subscribe((res: any) => {
      this.animalArr = res.data;
      this.mapForm.controls['animalId'].setValue('');
    });
  }

  mapImage() {
    this.togglePanel('MapImage');
    let formValue = this.mapForm.value;
    let mapArr = [{
      "studySlideMappingModel": {
        "studyFk": formValue.studyGuId,
        "cstCodeFk": formValue.cassetteId,
        "slideFk": this.slideData.slide.slidePk,
        "animalFk": formValue.animalId,
        "cstStainFk": formValue.stainId
      },
      "slideComment": formValue.comment
    }];
    this.galleryService.mapImageToStudy(mapArr).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      this.commonService.changePlace(this.openedFrom);
      this.router.navigate(['/dashboard/' + this.openedFrom])
    });
  }

  discardImage() {
    this.togglePanel('DiscardImage');
    let discardObj = {
      "slides": [this.slideData.slide.slidePk],
      "comment": this.discardImageComment
    }
    this.galleryService.discardImage(discardObj).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      this.commonService.changePlace(this.openedFrom);
      this.router.navigate(['/dashboard/' + this.openedFrom])
    });
  }
  unMapImage() {
    this.togglePanel('UnMappImage');
    let unMapArr = [
      {
        "studySlideMappingModel": {
          "studyFk": null,
          "cstCodeFk": null,
          "slideFk": this.slideData.slide.slidePk,
          "animalFk": null,
          "cstStainFk": null
        },
        "slideComment": this.unMapImageComment
      }
    ];
    this.galleryService.unMapImage(unMapArr).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      this.commonService.changePlace(this.openedFrom);
      this.router.navigate(['/dashboard/' + this.openedFrom])
    });
  }

  reMapImage() {
    let formValue = this.mapForm.value;
    let unMapArr = [
      {
        "studySlideMappingModel": {
          "studyFk": null,
          "cstCodeFk": null,
          "slideFk": this.slideData.slide.slidePk,
          "animalFk": null,
          "cstStainFk": null
        },
        "slideComment": formValue.comment
      }
    ];
    this.galleryService.unMapImage(unMapArr).subscribe((res: any) => {
      let mapArr = [{
        "studySlideMappingModel": {
          "studyFk": formValue.studyGuId,
          "cstCodeFk": formValue.cassetteId,
          "slideFk": this.slideData.slide.slidePk,
          "animalFk": formValue.animalId,
          "cstStainFk": formValue.stainId
        },
        "slideComment": formValue.comment
      }];
      this.galleryService.mapImageToStudy(mapArr).subscribe((res: any) => {
        this.commonService.showToastMsg(res.message);
        this.commonService.changePlace(this.openedFrom);
        this.router.navigate(['/dashboard/' + this.openedFrom]);
      });
    });
  }

  // open modal functions
  openStudyDetailDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90%";
    dialogConfig.data = {
      showHeader: true,
      studyGuid: this.slideData.study.guid
    };
    this.dialog.open(StudyDetailsComponent, dialogConfig);
  }

  openGalleryModal(paramVal) {
    if (this.openedFrom == 'gallery') {
      var imageDisplayed = this.imageViewer.getImageDisplayed();
      this.galleryService.activeAccordian = 0;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = "90%";
      dialogConfig.data = { showHeader: true, dialogType: paramVal, imageDisplayed: imageDisplayed, selectedImage: this.slideData.slide.slidePk, organArr: this.organs, animalId: this.slideData.animal.animalId, studyTypeTCR: this.slideData.study.isTCRStudy, disableClose: true };
      const dialogref = this.dialog.open(GalleryComponent, dialogConfig);
      dialogref.componentInstance.onClose.subscribe(result => {
        this.commonService.setGalleryFilters(JSON.stringify(this.galleryFilters));
      });
    }
  }

  // pannel functions

  togglePanel(panel: any) {
    this.imageViewerService.setActiveTool(panel);
    if (panel == "Slide_Review" && this.panelList[panel].isVisible) {
      // this.getAllSlideReview(this.organs[0]);
      this.selectOrgan(this.organs[0]);
    }

    if (panel == "Close_Viewer" && this.panelList[panel].isVisible) {
      $("#" + this.osdObj.id).css({ "border": "1px solid #848484", "padding": "2px" }).find('.scalebar .dot').hide();
    } else {
      if ($(".viewer").length > 1) {
        $("#" + this.osdObj.id).css({ "border": "3px solid #F47625", "padding": "0px" }).find('.scalebar .dot').show();
      } else {
        $("#" + this.osdObj.id).css({ "border": "1px solid #848484", "padding": "2px" }).find('.scalebar .dot').show();
      }
      $(".viewer .threeJsOverlaycanvas").css({ "box-shadow": "none" });

    }

    if (panel == "Slide_ROI" && this.panelList[panel].isVisible) {
      this.activeROIIndex = this.currentActiveROI - 1;
    } else {
      this.slideReviewArr = [];
      this.closeSlideArr = [];
    }
  }

  onfullScreen() {
    if (this.osdObj) {
      this.osdObj.world.update();
      this.osdObj.raiseEvent("pre-full-screen");
    }

    if (screenfull.isEnabled) {
      screenfull.off('change', this.elementFullScreenChange);
      if (!this.isFullScreen) {
        $("app-header, .sidebar").hide();
        $($("app-image-viewer").closest(".contenttab")[0]).css("width", "100%");
        screenfull.request();
        this.isFullScreen = true;
      } else {
        $("app-header, .sidebar").show();
        $($("app-image-viewer").closest(".contenttab")[0]).css("width", "93%");
        screenfull.exit();
        this.isFullScreen = false;
      }
      screenfull.on('change', this.elementFullScreenChange);
    }

    if (this.osdObj && this.osdObj.buttons) {
      this.osdObj.buttons.emulateExit();
    }
    if (this.osdObj && this.osdObj.viewport) {
      this.osdObj.viewport.applyConstraints();
    }

  }

  elementFullScreenChange = () => {
    if ($("app-header, .sidebar").is(":hidden") && !screenfull.isFullscreen) {
      this.onfullScreen();
      this.isFullScreen = false;
    } else {
      this.isFullScreen = screenfull.isFullscreen;
    }
    setTimeout(() => {
      /* let headerHeight = $("app-header").is(":visible") ? $("app-header header").height() : 0;
      $($("app-image-viewer").closest(".contenttab")[0]).height(document.documentElement.clientHeight - headerHeight); */
      // let headerHeight = $("app-header").is(":visible") ? $(".dashBoardBody").height() : '100vh';
      let headerHeight = $("app-header").is(":visible") ? 'inherit' : '100vh';
      $($("app-image-viewer").closest(".contenttab")[0]).height(headerHeight);
    }, 75);
    if (this.osdObj) {
      this.osdObj.raiseEvent("full-screen");
    }
  };

  onCloseViewer(paramVal) {
    let timeoutVal = 0;
    if (screenfull.isFullscreen) {
      this.onfullScreen();
      timeoutVal = 250;
    }
    setTimeout(() => {
      this.imageViewerService.setActiveTool("");
      this.commonService.setSelectedGroupSlides(JSON.stringify([]));
      if (paramVal == "SELECTED") {
        let index: number = -1;
        let str: string = '';
        let slideDataTobeClosed = [];
        if (this.closeSlideArr.length > 0) {
          if (this.closeSlideArr.length == this.imageViewerObj.length) {
            this.onCloseViewer("ALL");
            return;
          } else {
            slideDataTobeClosed = this.imageViewerObj.filter((res: any) => {
              // return res.imageDisplayed != this.slideId
              if (this.closeSlideArr.includes(res.imageDisplayed)) {
                return true;
              } else {
                return false;
              }
            });
          }
        } else if (this.imageViewerObj.length > 1) {
          slideDataTobeClosed = this.imageViewerObj.filter((res: any) => {
            if (res.imageDisplayed == this.slideId) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          this.onCloseViewer("ALL");
          return;
        }
        slideDataTobeClosed.forEach(element => {
          index = this.imageViewerObj.indexOf(element);
          if (index !== -1) {
            this.imageViewerObj.splice(index, 1);
          }
        });
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.VIEWER.IMAGES_REMOVED"));
        this.imageViewerObj.forEach((value: any) => {
          str = str + value.imageDisplayed + ',';
        });
        this.closeSlideArr = [];
        str = str.replace(/,\s*$/, "");
        this.commonService.changePlace('image-viewer/' + str);
        this.router.navigate(["/dashboard/image-viewer/" + str]);
        localStorage.setItem('samples', str);
        this.commonService.closeViewerArrChanged([]);
      } else if (paramVal == "NOTSELECTED") {
        this.commonService.changePlace('image-viewer/' + this.slideId);
        this.router.navigate(["/dashboard/image-viewer/" + this.slideId]);
        localStorage.setItem('samples', "" + this.slideId);
      } else {
        this.closeSlideArr = [];
        this.openedFrom = (this.openedFrom !== 'mapped') ? 'gallery' : this.openedFrom;
        this.commonService.changePlace(this.openedFrom);
        this.router.navigate(["/dashboard/" + this.openedFrom]);
        localStorage.setItem('samples', '');
      }
      this.commonService.focusChanged(0);
      localStorage.removeItem('slideIdDsData');
    }, timeoutVal);
  }

  drawScreenshots(shape: any) {
    // this.imageViewerService.drawScreenShots(shape);
    let screenShotParams = {
      type: shape,
      thickness: 2,
      colour: '#282828',
      drawScreenShot: true,
    };
    if (shape != "FullScreenShot") {
      this.annotationService.resetNewCreateAnnotationInfo(screenShotParams, this.osdObj, this.threeJsObj);
    } else {
      this.annotationService.createFullScreenShot(screenShotParams, this.osdObj, this.threeJsObj);
    }
    // this.imageViewer.getScreenshotObj().drawScreenShotShape(shape, this.osdObj.scalebarInstance.scalebarId);
    this.togglePanel("Screenshot");
  }

  addObservation(userRegObj: any) {
    let obs = <FormArray>userRegObj.controls.observations;
    obs.push(this.formBuilder.formGroup(Observation));
  }

  saveSlideReview(review: any) {
    var slideReviewData = review.value;
    let isDataCorrect = true;

    if (!this.slideData.study.isTCRStudy) {
      this.disableSlideRevieweBtn = true;
      if (slideReviewData.observations) {
        slideReviewData.observations.forEach((obs: any, index) => {
          if (obs?.description?.trim() == "") {
            this.commonService.showToastMsg("Enter a valid description at row: " + parseInt(index + 1));
            isDataCorrect = false;
            this.disableSlideRevieweBtn = false;
            return false;
          }
          if (!obs?.gradingCode) {
            this.commonService.showToastMsg("Select the severity grading at row: " + parseInt(index + 1));
            isDataCorrect = false;
            this.disableSlideRevieweBtn = false;
            return false;
          }
        });
        if (slideReviewData.observations.length == 1 && !slideReviewData.observations[0].description && !slideReviewData.observations[0].gradingCode) {
          slideReviewData.observations = null;
        }
      }
    } else {
      slideReviewData.observations = null;
    }
    if (slideReviewData.observations || slideReviewData.comments || slideReviewData.summary) {
      if (this.isEditReview && isDataCorrect) {
        slideReviewData.comments = slideReviewData.comments?.trim();
        slideReviewData.roleFk = slideReviewData.roleFk.toUpperCase();
        this.imageViewerTopBarService.updateSlideReview(slideReviewData, this.organReviewPk, this.slideId).subscribe((response: any) => {
          if (response.success) {
            this.panelList['Slide_Review'].isVisible = false;
            this.disableSlideRevieweBtn = false;
            this.isEditReview = true;
            this.commonService.showToastMsg(response.message);
          }
        });
      } else {
        if (isDataCorrect) {
          slideReviewData.comments = slideReviewData.comments?.trim();
          slideReviewData.roleFk = slideReviewData.roleFk.toUpperCase();
          this.imageViewerTopBarService.saveSlideReview(slideReviewData, this.slideId, this.isReviewShareable).subscribe((response: any) => {
            if (response.success) {
              this.panelList['Slide_Review'].isVisible = false;
              this.disableSlideRevieweBtn = false;
              this.isEditReview = true;
              this.commonService.showToastMsg(response.message);
            }
          });
        }
      }
    } else {
      this.disableSlideRevieweBtn = false;
      this.commonService.showToastMsg("Please add some observations and grading for saving.")
    }
  }

  removeObservation(review: any, index: number) {
    let obs = <FormArray>review.controls.observations;
    obs.removeAt(index);
  }

  selectOrgan(organ: String) {
    this.getAllSlideReview(organ);
  }

  getAllSlideReview(organ: any) {
    this.slideReviewArr = [];
    this.isEditReview = false;
    let role = "";
    if (this.additionnalPathlogists.includes(this.currentUser.userName)) {
      role = "ADDITIONAL_PATHOLOGIST";
    } else if (this.studyPeerReviewer == this.currentUser.userName) {
      role = 'PEER_REVIEWER'
    } else {
      role = localStorage.userActiveRole
    }
    this.imageViewerTopBarService.getAllSlideReviews(organ, this.slideId, this.currentPathRole, this.isReviewShareable, this.currentActiveROI).subscribe((response: any) => {
      if (response.success) {
        // add a blank form for current user
        let mySlideReview = new SlideReview();
        mySlideReview.observations = [new Observation()];
        mySlideReview.userFk = this.currentUser.userName;
        
        mySlideReview.organ = organ;
        mySlideReview.roi = this.currentActiveROI;
        mySlideReview.roleFk = this.commonService.camelCase(this.currentPathRole);
        this.allSlideReviews = response.data;
        let found = this.allSlideReviews.some((val: any) => { return val.userFk == this.currentUser.userName })
        if (!found) {
          var slideReviewForm = this.formBuilder.formGroup(SlideReview, mySlideReview);
          this.slideReviewArr.push(slideReviewForm);
        }
        this.allSlideReviews.forEach((slideReview: any) => {
          if (slideReview.userFk) {
            if (!slideReview.observations.length) {
              slideReview.observations = [new Observation()];
            }
            var slideReviewForm = this.formBuilder.formGroup(SlideReview, slideReview,);
            // slideReviewForm =slideReview.activeUser;
            if (slideReview.userFk == this.currentUser.userName) {
              this.organReviewPk = slideReview.organReviewPk;
              this.isEditReview = true;
            } else {
              slideReviewForm.disable();
            }
            this.slideReviewArr.push(slideReviewForm);
          }
        });
      }
      this.isSlideReviewLoaded = true;
    });
  }

  toggleTopBar() {
    this.imageViewerService.setActiveTool("");
    this.currentTopBarArrow = this.currentTopBarArrow === 'open' ? 'close' : 'open';
    $(".top_view_bar").animate({
      marginTop: this.currentTopBarArrow === 'close' ? "0" : "-3.55vw"
    }, "fast");
  }

  previousSlide() {
    if (localStorage.isViewerInitialized == "false") {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.VIEWER_NOT_INITIALISED"));
      return;
    }
    localStorage.isViewerInitialized = "false";
    if (this.prevSlideId == undefined) {
      return;
    }
    let dataObj = {
      "opened": true
    }
    this.galleryService.changeOpenStatus(this.prevSlideId, dataObj).subscribe((res: any) => {
    });
    this.commonService.changePlace('image-viewer/' + this.prevSlideId);
    this.router.navigate(['/dashboard/image-viewer/' + this.prevSlideId]);
    setTimeout(() => {
      if (screenfull.isFullscreen) {
        $("app-header, .sidebar").hide();
        $("app-image-viewer").closest(".contenttab").css("width", "100%");
        setTimeout(() => {
          /* let headerHeight = $("app-header").is(":visible") ? $("app-header").height() : 0;
          $($("app-image-viewer").closest(".contenttab")[0]).height(document.documentElement.clientHeight - headerHeight); */
          let headerHeight = $("app-header").is(":visible") ? 'inherit' : '100vh';
          $($("app-image-viewer").closest(".contenttab")[0]).height(headerHeight);
        }, 75);
      }
    }, 75);
    localStorage.removeItem('slideIdDsData');
  }

  nextSlide() {
    if (localStorage.isViewerInitialized == "false") {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.VIEWER_NOT_INITIALISED"));
      return;
    }
    localStorage.isViewerInitialized = "false";
    if (this.nextSlideId == undefined) {
      return;
    }
    let dataObj = {
      "opened": true
    }
    this.galleryService.changeOpenStatus(this.nextSlideId, dataObj).subscribe((res: any) => {
    });
    this.commonService.changePlace('image-viewer/' + this.nextSlideId);
    this.router.navigate(['/dashboard/image-viewer/' + this.nextSlideId]);
    setTimeout(() => {
      if (screenfull.isFullscreen) {
        $("app-header, .sidebar").hide();
        $("app-image-viewer").closest(".contenttab").css("width", "100%");
        setTimeout(() => {
          /* let headerHeight = $("app-header").is(":visible") ? $("app-header").height() : 0;
          $($("app-image-viewer").closest(".contenttab")[0]).height(document.documentElement.clientHeight - headerHeight); */
          let headerHeight = $("app-header").is(":visible") ? 'inherit' : '100vh';
          $($("app-image-viewer").closest(".contenttab")[0]).height(headerHeight);
        }, 75);
      }
    }, 75);
    localStorage.removeItem('slideIdDsData');
  }

  onPrevious() {
    this.currentImageIndex = this.osdObj.roiImageMap[this.osdObj._sequenceIndex];
    var previous = this.osdObj._sequenceIndex - 1;

    if (this.osdObj.navPrevNextWrap && previous < 0) {
      previous += this.osdObj.tileSources.length;
    } else if (!(previous < 0) && this.osdObj.roiImageMap[previous] < this.currentImageIndex) {
      this.nextRoi = true;
      if (previous > 1) {
        this.prevRoi = true;
      } else {
        this.prevRoi = false;
      }
      //return;
    }
    this.nextRoi = true;
    if (previous > 1) {
      this.prevRoi = true;
    } else {
      this.prevRoi = false;
    }
    this.currentActiveROI = previous;
    this.osdObj.goToPage(previous);

    setTimeout(() => {
      this.osdObj.viewport.maxZoomLevel = this.osdObj.viewport.imageToViewportZoom(1);
      this.osdObj.viewport.minZoomLevel = this.osdObj.viewport.getHomeZoom();
    }, 500);

    if (this.activeRole == "PATHOLOGIST") {
      this.imageViewerService.loadNewAnnotations({
        "slideId": this.slideId,
        "roiIndex": previous,
        "role": this.currentPathRole,
        "reviewSharable": this.isReviewShareable
      });
      /* this.imageViewerService.getAllAnnotation(this.slideId, previous, this.currentPathRole, this.isReviewShareable).subscribe((response: any) => {
        this.annotationService.showAnnotation(response.data, this.imageViewer.annoborad, this.osdObj, OpenSeadragon);
      }); */
    }
  };

  onNext() {
    this.currentImageIndex = this.osdObj.roiImageMap[this.osdObj._sequenceIndex];
    var next = this.osdObj._sequenceIndex + 1;

    if (this.osdObj.navPrevNextWrap && next >= this.osdObj.tileSources.length) {
      next = 0;
    } else if (!(next >= this.osdObj.tileSources.length) && this.osdObj.roiImageMap[next] > this.currentImageIndex) {
      this.prevRoi = true;
      if (next < this.osdObj.tileSources.length - 1) {
        this.nextRoi = true;
      } else {
        this.nextRoi = false;
      }
      //return;
    }
    this.prevRoi = true;
    if (next < this.osdObj.tileSources.length - 1) {
      this.nextRoi = true;
    } else {
      this.nextRoi = false;
    }
    this.currentActiveROI = next;
    this.osdObj.goToPage(next);

    setTimeout(() => {
      this.osdObj.viewport.maxZoomLevel = this.osdObj.viewport.imageToViewportZoom(1);
      this.osdObj.viewport.minZoomLevel = this.osdObj.viewport.getHomeZoom();
    }, 500);

    if (this.activeRole == "PATHOLOGIST") {
      this.imageViewerService.loadNewAnnotations({
        "slideId": this.slideId,
        "roiIndex": next,
        "role": this.currentPathRole,
        "reviewSharable": this.isReviewShareable
      });
      /* this.imageViewerService.getAllAnnotation(this.slideId, next, this.currentPathRole, this.isReviewShareable).subscribe((response: any) => {
        this.annotationService.showAnnotation(response.data, this.imageViewer.annoborad, this.osdObj, OpenSeadragon);
      }); */
    }
  };

  getFilterData(type) {
    this.trayData = [];
    this.trayDataF = [];
    let postData = {};
    if (type == 'animal') {
      postData = { "groupBy": "0", "filters": { "animals": [], "stains": [], "organs": [3] } };
    } else {
      postData = { "groupBy": "0", "filters": { "animals": [this.slideData.animal.animalId], "stains": [], "organs": [] } };
    }
    this.imageViewerTopBarService.getFilterData(this.slideData.study.guid, postData).subscribe((res: any) => {
      if (res.success && res.data.length > 0) {
        res.data.forEach(element => {
          element.group.forEach(slide => {
            if (type == 'animal') {
              this.trayData.push({
                'animalId': slide.animal.animalNo,
                'slideId': slide.slide.slidePk
              });
            } else {
              this.trayData.push({
                'animalId': slide.cassetteOrgan.organ,
                'slideId': slide.slide.slidePk
              });
            }
            this.trayDataF = [...this.trayData];
          });
        });
      }
    });
  }

  trayTOViewer(slideId) {
    this.commonService.changePlace('image-viewer/' + slideId);
    this.router.navigate(['/dashboard/image-viewer/' + slideId]);
    var tempThis = this;
    setTimeout(() => {
      if (screenfull.isFullscreen) {
        $("app-header, .sidebar").hide();
        $("app-image-viewer").closest(".contenttab").css("width", "100%");
        setTimeout(() => {
          /* let headerHeight = $("app-header").is(":visible") ? $("app-header").height() : 0;
          $($("app-image-viewer").closest(".contenttab")[0]).height(document.documentElement.clientHeight - headerHeight); */
          let headerHeight = $("app-header").is(":visible") ? 'inherit' : '100vh';
          $($("app-image-viewer").closest(".contenttab")[0]).height(headerHeight);
        }, 75);
      }
    }, 75);
  }

  changeReadStatus(status) {
    let dataObj = {
      "slideIds": [this.slideId],
      "status": status
    }
    this.galleryService.changeReadStatus(dataObj).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      this.slideData.slide.readStatus = status;
    });
  }

  changeReviewStatus(status) {
    let dataObj = {
      "slideIds": [this.slideId],
      "status": status
    }
    this.galleryService.changeReviewStatus(dataObj).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      this.slideData.slide.reviewStatus = status;
    });
  }

  getAnalysisResult() {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    this.dialogConfig.disableClose = true;
    this.dialogConfig.data = {
      'Msg': "Importing Airavat Results ... Please Wait..."
    };
    this.dialogRef = this.dialog.open(LoaderComponent, this.dialogConfig);
    this.imageViewerTopBarService.getAnalysisResult(this.slideId).subscribe((response: any) => {
      this.quantificationResult = response.quantificationResult;
      this.quantificationResult[0].FeatureName.includes('Not WNL') ? this.normality = 'NOT_WNL' : this.normality = 'WNL'
      response.data.forEach((annotation: any) => {
        annotation.points.push(annotation.points[0]);
      });
      this.annotationService.showAnnotation(response.data, this.imageViewer.getAnnoboard(), this.osdObj, OpenSeadragon);
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
      this.isResultLoaded = true;
    }, (error: any) => {
      this.dialogRef.close();
    });
  }

  setFineTune(status: boolean = false) {
    $(".fineTune img").toggle();
    this.fineTuneMode = status;
    let newMinPixelRatio = status ? 0.15 : (this.config.tileMinPixelRatio ? this.config.tileMinPixelRatio : 0.5);

    this.osdObj.minPixelRatio = newMinPixelRatio;
    this.osdObj.world._items[0].minPixelRatio = newMinPixelRatio;
    this.osdObj.world.update();
    this.osdObj.world.resetItems();
  }

  closeCheckboxChange(event, slidePK, selectedIndex) {
    let index = this.closeSlideArr.indexOf(slidePK);
    if (index == -1) {
      $(event.currentTarget).addClass("highlight");
      $(".viewer[index='" + selectedIndex + "'] #openseadragon_" + selectedIndex + "-overlaycanvas").css({ "box-shadow": "0px 0px 5px 3px #ea8823 inset" });
      this.closeSlideArr.push(slidePK);
    } else {
      $(".viewer[index='" + selectedIndex + "'] #openseadragon_" + selectedIndex + "-overlaycanvas").css({ "box-shadow": "none" });
      $(event.currentTarget).removeClass("highlight");
      this.closeSlideArr.splice(index, 1);
    }
  }
}
