<!--Navigation to Study List-->
<div class="pageheader" *ngIf="!(data && data.showHeader)">
    <div class="pageheadtitle">
        <h4> Study
        </h4>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 p_gallerydetdownload">
        <a *ngIf="activeRole=='STUDY_DIRECTOR'" class="imgicontable" (click)="editStudy()"><i
                class="fa fa-edit deletestudy_deticon faicon"></i></a>
        <a *ngIf="activeRole=='STUDY_DIRECTOR'" class="imgicontable" (click)="deleteStudy()"><i
                class="fa fa-trash deletestudy_deticon faicon" style="display: none;"></i></a>
        <a *ngIf="activeRole=='PATHOLOGIST'" class='imgicontable' (click)="openGallery()" style="display: none;"><i
                class='fa fa-picture-o openGalleryIcon faicon' title='Open Gallery'></i></a>
        <button type="button" *ngIf="!isDownloadBtnClicked" class="btn orangebtn" (click)="downloadReport()"
            style="display: none;">Download Report</button>

        <button class="btn orangebtn" *ngIf="isDownloadBtnClicked" type="button" disabled>
            <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw; width: 1vw; height: 1vw;"
                role="status" aria-hidden="true"></span>
            <span>Downloading...</span>
        </button>
    </div>
</div>

<div id="downloadReportDiv"></div>

<div class="StudyDetailsMain">
    <div class="row sutitle_det" *ngIf="!(data && data.showHeader)">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 studydet_titlenm">
            <p class="studydet_title"><a (click)="navigateBackToStudyListing()"><i class="fa fa-chevron-left"></i>
                    Details</a>
            </p>
        </div>
    </div>
    <div class="dialog-header modal-header" *ngIf="data && data.showHeader">
        <div class="headerTitle">
            <div class="dialog-title">Details</div>
        </div>
        <div class="headerIcon float-right">
            <i class="fa fa-times" (click)="closeDialog()"></i>
        </div>
    </div>
    <div class="p_studydetail">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>Study Details</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row panelRow">
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyNo">
                    <label class="p_studydet_subtitle">Study No. : </label><span> {{studyDetailData.studyNo}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyTitle">
                    <label class="p_studydet_subtitle">Study Title :</label><span> {{studyDetailData.studyTitle}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.projectNo">
                    <label class="p_studydet_subtitle">Project No. : </label><span> {{studyDetailData.projectNo}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.accessionNo">
                    <label class="p_studydet_subtitle">Accession No. : </label>
                    <span> {{studyDetailData.accessionNo}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.treatment">
                    <label class="p_studydet_subtitle">Treatment : </label>
                    <span> {{studyDetailData.treatment}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyDomain">
                    <label class="p_studydet_subtitle">Study Domain : </label>
                    <span> {{studyDetailData.studyDomain}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyType">
                    <label class="p_studydet_subtitle">Study Type : </label>
                    <span> {{studyDetailData.studyType}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.startDate && studyDetailData.endDate">
                    <label class="p_studydet_subtitle">Duration : </label>
                    <span> {{studyDetailData.startDate | date}} To {{studyDetailData.endDate | date}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.route">
                    <label class="p_studydet_subtitle">Route : </label>
                    <span> {{studyDetailData.route}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyDirector">
                    <label class="p_studydet_subtitle">Created By : </label>
                    <span> {{studyDetailData.studyDirector}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.pathologist">
                    <div *ngIf="studyDetailData.reAssignedpathologistArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let primPath of studyDetailData.reAssignedpathologistArr">
                                <span> {{primPath}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Study Pathologist : </label>
                    <span> {{studyDetailData.pathologist}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.technician">
                    <div *ngIf="studyDetailData.reAssignedTechnicianArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let tech of studyDetailData.reAssignedTechnicianArr">
                                <span> {{tech}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Technician : </label>
                    <span> {{studyDetailData.technician}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.peerReviewer">
                    <div *ngIf="studyDetailData.reAssignedPeerReviewerArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let peer of studyDetailData.reAssignedPeerReviewerArr">
                                <span> {{peer}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Peer Reviewer : </label>
                    <span> {{studyDetailData.peerReviewer}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.primaryInvestigator">
                    <div *ngIf="studyDetailData.reAssignedPrimaryInvestigatorArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let primaryinvest of studyDetailData.reAssignedPrimaryInvestigatorArr">
                                <span> {{primaryinvest}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Primary Investigator :</label>
                    <span> {{studyDetailData.primaryInvestigator}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.sacrifice">
                    <label class="p_studydet_subtitle">Sacrifice : </label>
                    <span> {{studyDetailData.sacrifice}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.species">
                    <label class="p_studydet_subtitle">Species : </label>
                    <span> {{studyDetailData.species}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.strain">
                    <label class="p_studydet_subtitle">Strain : </label>
                    <span> {{studyDetailData.strain}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.cro">
                    <label class="p_studydet_subtitle">CRO : </label>
                    <span> {{studyDetailData.cro}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.testItem">
                    <label class="p_studydet_subtitle">TestItem : </label>
                    <span> {{studyDetailData.testItem}}</span>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.additionalPathologist.length">
                    <div *ngIf="studyDetailData.reAssignedAdditionalPathologistArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let addPath of studyDetailData.reAssignedAdditionalPathologistArr">
                                <span> {{addPath}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Additional Pathologists : </label>
                    <ul>
                        <li *ngFor="let path of studyDetailData.additionalPathologist">
                            <span> {{path}}</span>
                        </li>
                    </ul>
                </div>
                <div class="col-md-4 mtop_1 mbot_1" *ngIf="studyDetailData.additionalPathologist.length">
                    <label class="p_studydet_subtitle">Share Annotations & Reviews? : </label>
                    <span> {{ studyDetailData.reviewSharable ? 'Yes' : 'No' }}</span>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" *ngIf="!isTCRStudy">
            <mat-expansion-panel-header>
                <mat-panel-title>Cassette Details</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panelRow">
                <table id="p_cassettedet" #dataTable class="display"></table>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" *ngIf="!isTCRStudy">
            <mat-expansion-panel-header>
                <mat-panel-title>Dosage Details</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panelRow">
                <table id="p_dosagedet" #dataTable class="display"></table>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>Slide Details</mat-panel-title>
                <i *ngIf="showSlideDataLoader" title="Loading..." class="slideLoader fa fa-spinner fa-spin"></i>
            </mat-expansion-panel-header>
            <!-- <div class="panelRow">
                <div class='slideInfo clearfix' *ngFor="let indvSlide of slidesArr">
                    <div class="float-left slideData xsDiv" *ngIf="indvSlide.imageModel.slideLabel">
                        <div class="dataKey">Slide Id </div>
                        <div class="dataVal">{{indvSlide.imageModel.slideLabel}}</div>
                    </div>
                    <div class="float-left slideData mDiv">
                        <div class="dataKey">Image Ref #. </div>
                        <div class="dataVal">{{indvSlide.slideInfo.imageName}}</div>
                    </div>
                    <div class="float-left slideData xsDiv">
                        <div class="dataKey">Subject Id </div>
                        <div class="dataVal">{{indvSlide.slideInfo.animalNo}}</div>
                    </div>
                    <div class="float-left slideData xsDiv">
                        <div class="dataKey">Cassette Id </div>
                        <div class="dataVal">{{indvSlide.slideInfo.cassetteCode}}</div>
                    </div>
                    <div class="float-left slideData xsDiv">
                        <div class="dataKey">Stain </div>
                        <div class="dataVal">{{indvSlide.slideInfo.stain}}</div>
                    </div>
                    <div class="float-left slideData sDiv" *ngIf="indvSlide.slideInfo.tissueSection">
                        <div class="dataKey">Tissue Section </div>
                        <div class="dataVal">{{indvSlide.imageModel.tissueSectionCutting}}</div>
                    </div>
                    <div class="float-left slideData sDiv" *ngIf="indvSlide.imageModel.scanner.scanMagnification">
                        <div class="dataKey">Scan Magnification </div>
                        <div class="dataVal">{{indvSlide.imageModel.scanner.scanMagnification}}</div>
                    </div>
                    <div class="float-left slideData xsDiv" *ngIf="indvSlide.imageModel.scanner.instrument">
                        <div class="dataKey">Instrument </div>
                        <div class="dataVal">{{indvSlide.imageModel.scanner.instrument}}</div>
                    </div>
                    <div class="float-left slideData xsDiv" *ngIf="indvSlide.imageModel.slideComments">
                        <div class="dataKey">Comments </div>
                        <div class="dataVal">{{indvSlide.imageModel.slideComments}}</div>
                    </div>
                </div>
            </div> -->
            <div class="panelRow">
                <table id="p_slidedet" #dataTable class="display"></table>
            </div>
            <!-- <div class="testTable">
                <table datatable [dtOptions]="dtOptions" class="row-border hover">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>Slide Id</th>
                        <th>Image Name</th>
                        <th>Subject Id</th>
                        <th>Cassette Id</th>
                        <th>Stain</th>
                        <th>Stain Date</th>
                        <th>Tissue Section</th>
                        <th>Slide Control</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="studyDetailData.imageDetailsModel?.length != 0">
                      <tr *ngFor="let studyDetail of studyDetailData.imageDetailsModel">
                        <td><div class="indexRow"></div></td>
                        <td><div class="rightArrow"></div></td>
                        <td>{{ studyDetail.imageModel.slideLabel }}</td>
                        <td>{{ studyDetail.slideInfo.imageName }}</td>
                        <td>{{ studyDetail.slideInfo.animalNo }}</td>
                        <td>{{ studyDetail.slideInfo.cassetteCode }}</td>
                        <td>{{ studyDetail.slideInfo.stain }}</td>
                        <td>{{ studyDetail.slideInfo.stainDate }}</td>
                        <td>{{ studyDetail.imageModel.tissueSectionCutting }}</td>
                        <td>{{ studyDetail.imageModel.slideControl }}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="studyDetailData.imageDetailsModel?.length == 0">
                      <tr>
                        <td colspan="3" class="no-data-available">No data!</td>
                      </tr>
                    </tbody>
                  </table>
            </div> -->
        </mat-expansion-panel>
    </div>
</div>