import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../common.service';
import { StudyService } from './../study.service';
import { StudyMappingStructureService } from './../../study-mapping-structure/study-mapping-structure.service';
import { FileReaderService } from './../../FileReader.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { element } from 'protractor';
import { file } from '@rxweb/reactive-form-validators';
import { Study } from "../models/study.model";
import { LoaderComponent } from './../../common/loader/loader.component';

@Component({
  selector: 'app-auto-create-dialog',
  templateUrl: './auto-create-dialog.component.html',
  styleUrls: ['./auto-create-dialog.component.css']
})
export class AutoCreateDialogComponent implements OnInit {

  studylFileName: String = 'Select File';
  file: any;
  FieldMappingArr = [];
  rowNo: number = 2;
  fileData = [];
  templateArr = [];
  selectedTemplateId: number = 0;
  dialogConfig = new MatDialogConfig();
  isUploadClicked: boolean = false;
  isStudyTypeTCR: boolean = false;
  tcrNoTissueCount: number = 0;

  constructor(@Optional() private dialogRef: MatDialogRef<AutoCreateDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private fileReaderService: FileReaderService,
    private studyMappingStructureService: StudyMappingStructureService,
    private studyService: StudyService,
    private commonService: CommonService,
    private dialog: MatDialog,
    public translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.isStudyTypeTCR = (localStorage.studyCreationType == 'TCR');
    this.studyMappingStructureService.getShowStudyMappingStructureValue().subscribe((value) => {
      this.data.showAutoCreate = !value;
      if (this.data.showAutoCreate) {
        this.studyService.getAllStructureMappingTemaplates(this.isStudyTypeTCR).subscribe((res: any) => {
          this.templateArr = res.data;
          //this.selectedTemplateId = this.templateArr[0].id
        });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  templateSelectionChange(e) {
    this.selectedTemplateId = e.value;
  }

  uploadStudyDataFile(e) {
    this.studylFileName = e.target.files[0].name;
    this.file = e.target.files[0];
    /* this.fileReaderService.readFile(e.target.files[0], (fileData: any) => {
      this.fileData = fileData;
    }, this.rowNo); */
  }

  uploadStudyData() {
    if (this.file) {
      this.isUploadClicked = true;
      this.studyService.getTemplateStructure(this.selectedTemplateId).subscribe((res: any) => {
        this.fileReaderService.readFile(this.file, (fileData: any) => {
          this.fileData = fileData.slice((this.rowNo && (this.rowNo > 2)) ? (this.rowNo - 2) : 0, fileData.length);
          let obj = this.fieldMappingObj(res.data.headerInfo.labelConfigModels);
          this.fileData.forEach((element) => {
            var keys = Object.keys(element);
            for (const key of keys) {
              if (obj[key] && key != obj[key]) {
                element[obj[key]] = element[key];
                delete element[key];
              }
            }
          });
          this.dialogRef.close(this.generateDataObj(this.fileData));
        });
      });
    } else {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.UPLOAD_FILE"));
    }
  }

  fieldMappingObj(mappingArr) {
    let mappingObj = {};
    mappingArr.forEach(element => {
      if (element.clientColumn && !mappingObj[element.clientColumn]) {
        mappingObj[element.clientColumn] = element.code;
      }
    });

    return mappingObj;
  }

  generateDataObj(fileData) {
    this.dialogConfig.width = "auto";
    this.dialogConfig.height = "auto";
    this.dialogConfig.id = 'loaderDialog';
    this.dialogConfig.disableClose = true;
    this.dialogConfig.data = {
      'Msg': "Fetching Data From Excel..."
    };
    let excelDataRef = this.dialog.open(LoaderComponent, this.dialogConfig);
    this.dialogConfig.id = null;



    let dataObj = {
      studyInfo: new Study(),
      cassetteInfo: {},
      dosageInfo: {},
      slideInfo: [],
      loaderDialog: excelDataRef
    };
    fileData.forEach((element, i) => {
      if (i == 0) {
        dataObj.studyInfo.studyNo = element.studyNo;
        dataObj.studyInfo.studyTitle = element.studyTitle;
        dataObj.studyInfo.projectNo = element.projectNo;
        dataObj.studyInfo.accessionNo = element.accessionNo;
        dataObj.studyInfo.studyDomain = element.studyDomain;
        dataObj.studyInfo.treatment = element.treatment;
        dataObj.studyInfo.sacrifice = element.sacrifice;
        dataObj.studyInfo.studyType = element.studyType;
        dataObj.studyInfo.species = element.species ? element.species.toString().trim() : null;
        dataObj.studyInfo.strain = element.strain;
        dataObj.studyInfo.route = element.route;
        dataObj.studyInfo.fromDate = element.startDate;
        dataObj.studyInfo.toDate = element.endDate;
        dataObj.studyInfo.pathologist = element.pathologist;
        dataObj.studyInfo.peerReviewer = element.peerReviewer;
        dataObj.studyInfo.primaryInvestigator = element.primaryInvestigator;
        dataObj.studyInfo.technician = element.technician;
        dataObj.studyInfo.cro = element.cro;
        dataObj.studyInfo.testItem = element.testItem;
      }
      // if (localStorage.studyCreationType == 'TCR') {
      //   element.cassette = 'TCR Cassette'
      // } else {
      element.cassette = element.cassette ? element.cassette : 'cassette1';
      if (element.cassette == 'cassette1') {
        if (element.stain && element.tissues) {
          element.cassette = element.stain?.toString().trim() + "_" + element.tissues?.toString().trim();
        } else if (element.stain) {
          element.cassette = element.stain?.toString().trim()
        } else if (element.tissues) {
          element.cassette = element.tissues?.toString().trim();
        }
      }
      // }
      element.dosage = element.dosage ? element.dosage : 'temp';

      if (!dataObj.cassetteInfo[element.cassette]) {
        dataObj.cassetteInfo[element.cassette] = {
          cassetteId: element.cassette,
          stains: [],
          organs: []
        }
      }

      if (element.stain) {
        let stainArr = element.stain.split(';');
        stainArr.forEach(stainElem => {
          if (!dataObj.cassetteInfo[element.cassette].stains.includes(stainElem.toString().trim())) {
            dataObj.cassetteInfo[element.cassette].stains.push(stainElem.toString().trim());
          }
        });
      }
      if (element.tissues) {
        let organArr = element.tissues.split(';');
        organArr.forEach(organElem => {
          if (!dataObj.cassetteInfo[element.cassette].organs.includes(organElem.toString().trim())) {
            dataObj.cassetteInfo[element.cassette].organs.push(organElem.toString().trim());
          }
        });
      }

      if (!dataObj.dosageInfo[element.dosage]) {
        dataObj.dosageInfo[element.dosage] = {
          dosageId: element.dosage,
          maleAnimalIds: [],
          femaleAnimalIds: [],
          notKnownAnimalIds: [],
          dose: element.dose,
          doseUnit: element.doseUnits,
          controlGroup: element.controlGroup
        }
      }

      if (element.subjectId) {
        let animalArr = element.subjectId.split(';');
        if (element.sex) {
          if ((element.sex).toLowerCase() == 'm' || (element.sex).toLowerCase() == 'male') {
            animalArr.forEach(maleElem => {
              if (!dataObj.dosageInfo[element.dosage].maleAnimalIds.includes(maleElem.toString().trim())) {
                dataObj.dosageInfo[element.dosage].maleAnimalIds.push(maleElem.toString().trim());
              }
            });
          } else if ((element.sex).toLowerCase() == 'f' || (element.sex).toLowerCase() == 'female') {
            animalArr.forEach(femaleElem => {
              if (!dataObj.dosageInfo[element.dosage].femaleAnimalIds.includes(femaleElem.toString().trim())) {
                dataObj.dosageInfo[element.dosage].femaleAnimalIds.push(femaleElem.toString().trim());
              }
            });
          } else {
            animalArr.forEach(animalElm => {
              if (!dataObj.dosageInfo[element.dosage].notKnownAnimalIds.includes(animalElm.toString().trim())) {
                dataObj.dosageInfo[element.dosage].notKnownAnimalIds.push(animalElm.toString().trim());
              }
            });
          }
        } else {
          animalArr.forEach(animalElm => {
            if (!dataObj.dosageInfo[element.dosage].notKnownAnimalIds.includes(animalElm.toString().trim())) {
              dataObj.dosageInfo[element.dosage].notKnownAnimalIds.push(animalElm.toString().trim());
            }
          });
        }
      }

      let indvSlideInfo = {
        slideName: element.imageName ? element.imageName.toString().trim().slice(0, 250) : null,
        slideId: element.slideId ? element.slideId.toString().trim().slice(0, 250) : null,
        studyNo: element.studyNo ? element.studyNo.toString().trim().slice(0, 15) : null,
        cassetteId: element.cassette?.toString().trim(),
        stain: element.stain?.toString().trim(),
        animalId: element.subjectId?.toString().trim(),
        dosageGroup: element.dosage?.toString().trim(),
        sex: element.sex?.toString().trim(),
        scanMagnification: element.scanMagnification?.toString().trim(),
        scanDate: element.scanDate,
        instrument: element.instrument ? element.instrument.toString().trim().slice(0, 250) : null,
        tissueId: element.tissues?.toString().trim(),
        tissueSection: element.tissueSection?.toString().trim(),
        comments: element.comment ? element.comment.toString().trim().slice(0, 255) : null,
        IHC_ExperimentNo: element.ihcExperimentNumber ? element.ihcExperimentNumber.toString().trim().slice(0, 250) : null,
        targetAntigen: element.targetAntigen ? element.targetAntigen.toString().trim().slice(0, 250) : null,
        primaryAntibodyId: element.primaryAntibodyID ? element.primaryAntibodyID.toString().trim().slice(0, 250) : null,
        primaryAntibodyConcentration: element.primaryAntibodyConcentration ? element.primaryAntibodyConcentration.toString().trim().slice(0, 250) : null,
        secondaryAntibodyId: element.secondayAntibodyID ? element.secondayAntibodyID.toString().trim().slice(0, 250) : null,
        detectionMethod: element.detectionMethod ? element.detectionMethod.toString().trim().slice(0, 250) : null,
        chromogen: element.chromogen ? element.chromogen.toString().trim().slice(0, 250) : null,
        slideControl: element.slideControl ? element.slideControl.toString().trim().slice(0, 250) : null,
        primaryAntibodyConcentrationUnit: element.primaryAntibodyConcentrationUnit ? element.primaryAntibodyConcentrationUnit.toString().trim().slice(0, 250) : null,
        stainDate: element.stainDate
      }

      if (!(localStorage.studyCreationType == 'TCR' && !element.tissues)) {
        dataObj.slideInfo.push(indvSlideInfo);
      }
      else {
        this.tcrNoTissueCount++;
      }
    });

    if (this.tcrNoTissueCount > 0) {
      if (this.tcrNoTissueCount == fileData.length) {
        if (localStorage.studyCreationType == 'TCR') {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.NOTISSUESINSLIDEDATA"));
        } else {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.NOSLIDEDATA"));
        }
      } else {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.NOTISSUECOUNT") + this.tcrNoTissueCount);
      }
    }

    // setTimeout(() => {
    //   excelDataRef.close();
    // }, 0);
    return dataObj;
  }

  inputValueChanged(e){
    if (e.target.value == '') {
      e.target.value = 2
    }
  }

}
