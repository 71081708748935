import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-screen-message',
  templateUrl: './full-screen-message.component.html',
  styleUrls: ['./full-screen-message.component.css']
})
export class FullScreenMessageComponent implements OnInit {

  msgText: string = "";
  constructor() { }

  ngOnInit(): void {
    this.msgText = sessionStorage.msgComponentText;
  }

}
