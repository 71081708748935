<div class="modal-header dialog-header">
  <div class="headerTitle">
    <div class="dialog-title" *ngIf="data.locationMapping == 'start'">Start Mapping</div>
    <div class="dialog-title" *ngIf="data.locationMapping == 'sync'">Sync Images</div>
  </div>
  <div class="headerIcon float-right">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>
<div class="dialog-container modal-body">
  <div class="locationMappingDiv">
    <form [formGroup]="mapForm">
      <label>Study No.</label>
      <div class="reportStudy">{{data.studyData.studyNo}}</div>
      <div class="mapUnmap" *ngIf="data.studyData.mappedImagesCount > 0">
        <label>Unmapped Images</label>
        <div class="reportStudy">{{data.studyData.unmappedImagesCount}}</div>
        <label>Mapped Images</label>
        <div class="reportStudy">{{data.studyData.mappedImagesCount}}</div>
      </div>
      <hr>
      <div class="pageBody" *ngIf="showLoader" style="height: 50vh">
        <mat-spinner style="margin:0 auto;"></mat-spinner>
      </div>
      <div class="form-group row" *ngIf="!showLoader">
        <div class="col-sm-6">
          <label>Folder Name</label>
        </div>
        <div class="col-sm-6"> 
          <!-- <mat-form-field>
              <input matInput placeholder="Search Folder Location" id="filterSearch">
          </mat-form-field> -->
          <div class="searchMainDiv studySearchMainDiv">
            <input type="text" class="tags" id="filterSearch" placeholder="Search by folder Name" />
          </div>
        </div>
      </div>
      <div class="form-group row fLData" style="height: 45vh" *ngIf="!showLoader">
        <div class="col-md-12">
          <div formArrayName="folderLocation" *ngFor="let location of folderLocationArr;let i = index" class="locationMainDiv">
            <div *ngIf="location.accessPermission" class="folderLocation_border" [attr.data-locationname]="location.name.toLowerCase()">
              <mat-checkbox type="checkbox" color="primary" [formControlName]="i">
                <div>{{location.name}}</div>
                <div class="folderLocation" [title]="location.folderLocation">Location: {{location.folderLocation}}</div>
              </mat-checkbox>
            </div>
          </div>
          <div *ngIf="folderLocationArr.length == 0">
            No Data to Display
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="actions dialog-actions modal-footer">
  <button class="btn-default" (click)="closeDialog()">Cancel</button>
  <button [ngClass]="{ 'disablebtn_func' : (mapForm.invalid) }" class="btn-default searchbtn"
    *ngIf="data.locationMapping == 'start'" (click)="mapLocationFolder()">Map</button>
  <button [ngClass]="{ 'disablebtn_func' : (mapForm.invalid) }" class="btn-default searchbtn"
    *ngIf="data.locationMapping == 'sync'" (click)="mapLocationFolder()">Sync</button>
</div>