import { WebviewerWrapperService } from '../../../image-viewer/models/WebviewerWrapper.service';
import { AnnotationService } from '../../../image-viewer/annotation.service';
import { ImageViewerService } from '../../../image-viewer/image-viewer.service';
import { ConsultationService } from './../../consultation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GalleryService } from './../../../gallery/gallery.service';
import { Component, OnInit, Optional, Inject, EventEmitter } from '@angular/core';
import { ImageViewer } from '../../../image-viewer/models/ImageViewer.class';

@Component({
  selector: 'app-meeting-dialog',
  templateUrl: './meeting-dialog.component.html',
  styleUrls: ['./meeting-dialog.component.css']
})
export class MeetingDialogComponent implements OnInit {

  onClose = new EventEmitter();

  isViewerInitialized: boolean = false;
  isImageNotAvailable: boolean = false;
  imageViewer: any;
  openSeaDragon: any;
  osdObj: any;
  imageInViewObservable: any;
  selectedImageDetails:any;
  meetingImageObj={};

  constructor(private galleryService: GalleryService,
    private viewerService: ImageViewerService,
    private annotationService: AnnotationService,
    private webviewerWrapperService: WebviewerWrapperService,
    private consultationService: ConsultationService,
    @Optional() private dialogRef: MatDialogRef<MeetingDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.imageInViewObservable = this.consultationService.imageInViewer.subscribe((value: any) => {
      if (value) {
        this.selectedImageDetails = Object.assign({},value);
        this.data.paramValue = 'viewer';
        let sldieDetails = this.getSlideDetails(value.slidePk);
        sldieDetails.then((successData: any) => {
          
          $('#openseadragon_1').height($('.mat-dialog-container').height() - $('.viewerheader').height() - 15);
          let slideData = successData.data
          this.createViewerObject(slideData, (data: any) => {
            this.osdObj = data.osd;
            this.osdObj.navigator.element.style.display = "none";
          });
        });
      }
      this.imageInViewObservable = null;
    })
  }

  ngOnDestroy(){
    if (this.osdObj) {
      this.osdObj.destroy();
    }
  }

  closeDialog() {
    //this.consultationService.generateSelectedImageObj('removeAll',{});
    this.galleryService.setSelectedAddImage({});
    this.dialogRef.close('close');
  }
  openGalleryDialog() {
    this.consultationService.setSelectImageInViewer('');
    if (this.imageInViewObservable) {
      this.imageInViewObservable.unsubscribe();
    }
    this.data.paramValue = 'gallery';
  }

  private async getSlideDetails(id) {
    return await (this.viewerService.getSlideDetails(id)).toPromise();
  }

  private createViewerObject(slideData: any, callBack: any) {
    this.imageViewer = new ImageViewer(slideData, 'openseadragon_1',this.webviewerWrapperService, false, callBack);
    this.openSeaDragon = this.imageViewer.getOpenseadragon();
  }

  addImageToMeeting(){
    this.meetingImageObj = this.consultationService.getSelectedImageValue();
    if(!this.meetingImageObj[this.selectedImageDetails.slidePk]){
      this.meetingImageObj[this.selectedImageDetails.slidePk] = this.selectedImageDetails;
    }
    this.consultationService.setSelectedImageValueOnDialogOpen(this.meetingImageObj);
    this.consultationService.setSelectImageInViewer('');
    this.galleryService.setSelectedAddImage({});
    if (this.imageInViewObservable) {
      this.imageInViewObservable.unsubscribe();
    }
    this.data.paramValue = 'gallery';
    this.dialogRef.close();
  }

}
