import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../app-config.service';
import { Action } from './digital-signature/digital-signature.component';
@Injectable({
  providedIn: 'root'
})
export class DigitalSignatureService {

  private config: any;

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = this.configService.config;
  }
  getActionsByRole(role: string) {
    return this.http.get(`${this.config.digitalUrl}config/get-by-role?role=${role}`)
  }

  updateRolesConfig(action: Action, token?: string) {
    return this.http.put(`${this.config.digitalUrl}config/updateRolesConfig`, action, {
      headers: {
        'X-DSHeader': 'Bearer ' + token
      }
    })
  }

  updateMultipleActionsRoles(actions: any, token?: string, updateFlag?: any) {
    if (updateFlag != '') {
      return this.http.put(`${this.config.digitalUrl}config/ds-update`, actions)
    } else {
      return this.http.put(`${this.config.digitalUrl}config/bulk-update`, actions)
    }
  }

  generateToken(username: any, password: any, otp: any) {
    const body: any = { username };
    if (password) {
      body.password = password;
    }
    if (otp) {
      body.otp = Number.parseInt(otp, 10);
    }
    return this.http.post(`${this.config.digitalUrl}token-manager/generate-token`, body)
  }

  generateOtp() {
    return this.http.get(`${this.config.digitalUrl}token-manager/generate-otp`);
  }
}
