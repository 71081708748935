import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { CommonService } from './../../../../common.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/operators';
import { ConsultationService } from './../../../consultation.service';
import { ConsultationViewerService } from './../../../consultation-viewer/consultation-viewer.service';
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "./../../../../common/confirm-dialog/confirm-dialog.component";
import { email } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.css']
})
export class ParticipantListComponent implements OnInit {
  // @Input() imageViewerArr: any
  @Input() meetingDetails: any;
  @ViewChild('particpantInput') particpantInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  isHost: boolean = false;
  hostId: any;
  isMeetScheduleDateExceed: boolean = false;
  participantTextEntered: boolean = false;

  separatorKeysCodes = [ENTER, COMMA];
  participantCtrl = new FormControl();
  userArr = [];
  filteredParticipants: Observable<any[]>;
  participantList = [];

  pinLocked: boolean = false;
  participantTitleText: String = 'Participant List';
  participantObj: any = {};
  currentUser: any = {};
  isPresenter: any;
  imageViewer: any;
  imageViewerArr: any;
  osdObj: any;
  participantStatus: any = {};
  participantStatusEmailId: any = [];
  dummyParticipantObj: any = {};

  isInviteParticipantClicked: boolean = false;
  participantsInValid: boolean = false;

  constructor(private consultationService: ConsultationService, private consultationViewerService: ConsultationViewerService,
    private commonService: CommonService, public translate: TranslateService, private dialog: MatDialog) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.filteredParticipants = this.participantCtrl.valueChanges.pipe(
      startWith(null),
      map((participant: string | null) => typeof (participant) == "string" ? this._filter(participant) : this.userArr.slice()));
  }

  ngOnInit() {
    // setTimeout(() => {
    //   $(".participanList").height($(".participantMainDiv").height() - $(".participantHeader").height() - this.commonService.vwTOpx('0.3vw'));
    // }, 50);
    // this.participantArr = this.meetingDetails.invitees;
    // this.participantArr = this.consultationViewerService.getParticipantUserList();

    if (!localStorage.rightBarPinLocked) {
      localStorage.rightBarPinLocked = true;
    }

    this.consultationViewerService.imageViewerArr.subscribe((array) => {
      if (array.length > 0) {
        this.imageViewerArr = array;
      }
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.consultationViewerService.meetingDetails.subscribe((details: any) => {
      if (details) {
        this.meetingDetails = details;
        this.isHost = (this.currentUser?.userName == this.meetingDetails.creator);
        this.hostId = this.consultationViewerService.getHostID();
        this.isMeetScheduleDateExceed = (new Date(this.meetingDetails.meetingDate) <= new Date((new Date()).toDateString()));
      }
    });

    this.consultationViewerService.viewerInfocus.subscribe((value) => {
      if (this.imageViewerArr && this.imageViewerArr.length > 0) {
        this.imageViewer = this.imageViewerArr[value];
        if (this.imageViewer) {
          this.osdObj = this.imageViewer.getOsdObject();
        }
      }
    });

    this.consultationViewerService.participantUserList.subscribe((participantData: any) => {
      var participantStatus = {};
      for (let x = 0; x < $(".participanList .participantItem").length; x++) {
        participantStatus[$($(".participanList .participantItem")[x]).attr("participant-emailid")] = {
          "mute": $($(".participanList .participantItem")[x]).attr("mute"),
          "audio": $($(".participanList .participantItem")[x]).attr("audio")
        }
      }

      this.participantObj = participantData;

      // $.each(participantData, (emailId:string, objData) => {
      var participantStatusEmailId = Object.keys(participantData);
      for (let y = 0; y < participantStatusEmailId.length; y++) {
        if (participantStatus[participantStatusEmailId[y]]) {
          $(".participanList .participantItem[participant-emailid='" + participantStatusEmailId[y] + "']").attr("mute", participantStatus[participantStatusEmailId[y]]["mute"]);
          $(".participanList .participantItem[participant-emailid='" + participantStatusEmailId[y] + "']").attr("audio", participantStatus[participantStatusEmailId[y]]["audio"]);
        }
        this.updateMicIcons(participantStatusEmailId[y]);
      }
    });

    this.consultationService.getParticipantsList().subscribe((userData: any) => {
      this.userArr = userData.data.filter((userData: any) => {
        return userData.email != JSON.parse(localStorage.getItem('currentUser')).userName;
      });
      this.hostId = this.consultationViewerService.getHostID();
    });

    this.consultationViewerService.isPresenter.subscribe((val: any) => {
      this.isPresenter = val;
    });
  }

  pinLockToggle = (event) => {
    // sessionStorage.lastZoomLevel = this.osdObj.viewport.getZoom();

    if ($(event.currentTarget).hasClass("pinLocked")) {
      $(event.currentTarget).removeClass("pinLocked").addClass("pinUnlocked");
      $('app-participant-list').removeClass("pinLockedDiv").addClass("pinUnlockedDiv");
      $('app-participant-list .participantMainDiv').removeClass("pinLockedMainDiv").addClass("pinUnlockedMainDiv");
    } else {
      $(event.currentTarget).removeClass("pinUnlocked").addClass("pinLocked");
      $('app-participant-list').removeClass("pinUnlockedDiv").addClass("pinLockedDiv");
      $('app-participant-list .participantMainDiv').removeClass("pinUnlockedMainDiv").addClass("pinLockedMainDiv");
    }
    this.pinLocked = $(event.currentTarget).hasClass("pinLocked");
    $(".consultationRightBarToggle").css("display", this.pinLocked ? 'none' : 'block');
    $(".viewercontent.osdViewerMainDiv").width((this.pinLocked) ? "80%" : "100%");
    $(".mainRightBar").css({
      "height": (this.pinLocked) ? "100vh" : "calc(100vh - 3.08vw)",
      "top": (this.pinLocked) ? "0" : "3.08vw",
    });
    // this.osdObj.world.update();
    $(".imageStatusScalebarParent").hide();
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      $(".chatList").height($(".chatMainDiv").height() - $(".sendMsg").height() - $(".chatTitle").height() - this.commonService.vwTOpx('1vw'));
    }, 50);
    localStorage.rightBarPinLocked = this.pinLocked;
  }

  inviteParticipants() {
    this.participantTitleText = "Invite Participant";
    for (let key in this.participantObj) {
      let index = this.userArr.map(function (e) { return e.email; }).indexOf(key);
      if (index > -1) {
        this.userArr.splice(index, 1);
      }
    }
  }

  cancelAddParticipant() {
    this.participantTitleText = "Participant List";
    this.participantList = [];
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      let filteredData = this._filter(value.trim());
      if (filteredData.length > 0) {
        let index = this.userArr.indexOf(filteredData[0]);
        filteredData[0].valid = true;
        this.participantList.push(filteredData[0]);
        this.userArr.splice(index, 1);
      } else {
        let index = this.participantList.map((res: any) => {
          return res.email
        }).indexOf(value.trim());
        if (index > -1) {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.PARTICIPANT_ALREADY_EXIST"));
        } else {
          this.participantList.push({
            displayName: value.trim(),
            email: value.trim(),
            isExternal: true,
            valid: this.checkifEmailValid(value.trim()),
            alreadyExist: this.alreadyExistUser(value.trim())
          });
        }
      }
      this.autocomplete.closePanel();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.participantCtrl.setValue(null);
  }

  remove(participant: any, index): void {
    this.participantList.splice(index, 1);
    if (!participant.isExternal) {
      this.userArr.push(participant);
    }
  }

  _filter(name: string) {
    return this.userArr.filter(userData =>
      userData.displayName.toLowerCase().indexOf(name.toLowerCase()) >= 0 || userData.email.toLowerCase().indexOf(name.toLowerCase()) >= 0)
  }
  checkifEmailValid(value) {
    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(mailformat)) {
      return true;
    }
    return false;
  }

  alreadyExistUser(value) {
    var existFlag = false;
    $.each(this.participantObj, (emailId, participant) => {
      if (participant.emailId === value.trim()) {
        existFlag = true;
        return true;
      }
    });
    if (existFlag) {
      return true;
    } else {
      return false;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.userArr.indexOf(event.option.value);
    event.option.value.valid = true;
    this.participantList.push(event.option.value);
    this.particpantInput.nativeElement.value = '';
    this.participantCtrl.setValue(null);
    this.userArr.splice(index, 1);
    $(event.source["_elementRef"]['nativeElement']).focus();
    $(".mat-form-field.addParticipantFormField .mat-form-field-infix").animate({
      scrollTop: $(event.source["_elementRef"]['nativeElement']).offset().top
    }, 50);
  }

  inviteParticipantsServiceCall() {
    let interArr = [], externalArr = [], participantsInAlreadyExist = false;
    this.participantList.forEach(element => {
      if (!element.valid) {
        this.participantsInValid = true;
        return;
      }
      else if (element.alreadyExist) {
        participantsInAlreadyExist = true;
      }
      if (element.isExternal) {
        externalArr.push({
          "emailId": element.email,
          "isExternal": element.isExternal
        });
      } else {
        interArr.push({
          "emailId": element.email,
          "isExternal": false,
          "displayName": element.displayName
        })
      }
    });
    if (this.participantsInValid) {
      this.participantsInValid = false;
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.PARTICIPANT_INVALID"));
      return;
    } else if (participantsInAlreadyExist) {
      participantsInAlreadyExist = false;
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.PARTICIPANT_ALREADY_EXIST"));
      return;
    }

    // $.each(this.participantObj, (emailId, participant) => {
    //   if(participant.emailId === value.trim()) {

    //   }
    // });

    this.isInviteParticipantClicked = true;
    let saveInvitees = {
      "status": "IN_PROGRESS",
      "creator": JSON.parse(localStorage.getItem('currentUser')).userName,
      "invitees": interArr,
      "externalUser": externalArr
    }

    this.consultationViewerService.addNewParticipants(this.meetingDetails.meeting_pk, saveInvitees).subscribe((res: any) => {
      this.isInviteParticipantClicked = false;
      window.opener.postMessage(true, '*');
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONSULTATION.PARTICIPANT_ADDED"));
      let newInvitees = [];

      this.dummyParticipantObj = this.participantObj;
      res.data.invitees.forEach(element => {
        if (!this.dummyParticipantObj[element.emailId]) {
          this.dummyParticipantObj[element.emailId] = {
            'displayName': element.displayName,
            'emailId': element.emailId,
            'isPresenter': false,
            'isHost': false,
            'isExternal': element.isExternal,
            'isJoined': false,
            'isAccessRequested': false,
            // 'mute': 'no',
            // 'audio': 0
          };
          newInvitees.push(element);
          // this.updateMicIcons(element.emailId);
        }
      });

      this.participantStatus = {};
      for (let x = 0; x < $(".participanList .participantItem").length; x++) {
        this.participantStatus[$($(".participanList .participantItem")[x]).attr("participant-emailid")] = {
          "mute": $($(".participanList .participantItem")[x]).attr("mute"),
          "audio": $($(".participanList .participantItem")[x]).attr("audio")
        }
      }

      this.consultationViewerService.emitEvent('participantlist-change', {
        clientID: this.currentUser.userName,
        meetID: this.meetingDetails.meeting_pk,
        invitees: newInvitees
      });
      this.cancelAddParticipant();

      this.participantStatusEmailId = Object.keys(this.dummyParticipantObj);
      this.participantObj = this.dummyParticipantObj;
      this.consultationViewerService.setParticipantUserList(this.participantObj);

      setTimeout(() => {
        for (let y = 0; y < this.participantStatusEmailId.length; y++) {
          if (this.participantStatus[this.participantStatusEmailId[y]]) {
            $(".participanList .participantItem[participant-emailid='" + this.participantStatusEmailId[y] + "']").attr("mute", this.participantStatus[this.participantStatusEmailId[y]]["mute"]);
            $(".participanList .participantItem[participant-emailid='" + this.participantStatusEmailId[y] + "']").attr("audio", this.participantStatus[this.participantStatusEmailId[y]]["audio"]);
          }
          this.updateMicIcons(this.participantStatusEmailId[y]);
        }
      }, 100);
    });
  }

  updateMicIcons(emailId) {
    let participantElem = $(".participanList .participantItem[participant-emailid='" + emailId + "']");
    if ($(participantElem).attr("mute") == 'no' && $(participantElem).attr("audio") == "0") {
      $(participantElem).find(".micicons .micUnmute").show();
    } else {
      $(participantElem).find(".micicons .micUnmute").hide();
    }

    if ($(participantElem).attr("mute") == 'yes') {
      $(participantElem).find(".micicons .micMute").show();
    } else {
      $(participantElem).find(".micicons .micMute").hide();
    }

    if ($(participantElem).attr("mute") == 'no' && $(participantElem).attr("audio") != "0") {
      $(participantElem).find(".micicons .voiceIcon").show();
    } else {
      $(participantElem).find(".micicons .voiceIcon").hide();
    }
  }

  grantAccess(userName, userEmailID) {
    this.consultationViewerService.emitEvent('make-presenter', {
      clientID: this.currentUser.userName,
      presenterID: userEmailID,
      meetID: this.meetingDetails.meeting_pk
    });
    this.consultationViewerService.setSynchronization(false);
  }

  closeDialog() {
    // sessionStorage.lastZoomLevel = this.osdObj.viewport.getZoom();
    this.consultationViewerService.setparticipantChatPanelVisibeValue(false);
  }

  participantInputfocusIn(event) {
    this.participantTextEntered = false;
  }
  participantInputfocusOut(event) {
    this.participantTextEntered = Boolean(event.target.value);
  }
}
