import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './../../common.service';
import { UsersService } from './../users.service';
import { ConfirmDialogComponent } from './../../common/confirm-dialog/confirm-dialog.component';
import { UserCreateComponent } from './../user-create/user-create.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.css']
})
export class UserListingComponent implements OnInit {
  isAciveUser: string = 'Active';
  activeUserArr = [];
  activeUserCount: number = 0;
  inactiveUserCount: number = 0;
  inactiveUserArr = [];
  loggedInUserCount: number = 0;
  loggedInUserArr = [];
  dataTable: any;
  dtOptions: any;
  @ViewChild('dataTable', { static: true }) table;
  constructor(private dialog: MatDialog,
    private usersService: UsersService,
    public commonService: CommonService,
    public datepipe: DatePipe,
    public translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit() {
    this.rerender('Active');

    $('#userSearch').on('keyup click', (e) => {
      let table = this.dataTable.DataTable();
      table.search($(e.currentTarget).val()).draw();
    });

    $("#user_table").on("click", "#editUserIcon", e => {
      let table = this.dataTable.DataTable();
      var data = table.row($(e.currentTarget).parents("tr")).data();
      this.showCreateUserModal("Edit", data);
    });

    $("#user_table").on("click", "#deleteUserIcon", e => {
      let table = this.dataTable.DataTable();
      var data = table.row($(e.currentTarget).parents("tr")).data();
      //this.showDeleteUserModal(data);
    });

    $("#user_table").on("click", "#activateUserIcon", e => {
      let table = this.dataTable.DataTable();
      var data = table.row($(e.currentTarget).parents("tr")).data();
      this.showActivateUserModal(data);
    });

    $("#user_table").on("click", "#deActivateUserIcon", e => {
      let table = this.dataTable.DataTable();
      var data = table.row($(e.currentTarget).parents("tr")).data();
      this.showDeactivateUserModal(data);
    });
  }

  rerender(paramValue) {
    this.usersService.getAllUsers().subscribe((res: any) => {
      this.activeUserArr = res.data.ACTIVE;
      this.activeUserCount = this.activeUserArr.length;
      this.inactiveUserArr = res.data.INACTIVE;
      this.inactiveUserCount = this.inactiveUserArr.length;
      this.loggedInUserArr = res.data.LOGGEDIN;
      this.loggedInUserCount = this.loggedInUserArr.length;
      if (paramValue == 'Active') {
        this.isAciveUser = 'Active';
        this.getActiveUsers();
      } else if (paramValue == 'Inactive') {
        this.isAciveUser = 'Inactive';
        this.getInactiveUsers();
      } else {
        this.isAciveUser = 'LoggedIn';
        this.getLoggedInUsers();
      }
    })
  }
  getActiveUsers() {
    if (this.dataTable) {
      this.dataTable.DataTable().destroy();
      this.dataTable.empty();
    }
    this.dtOptions = {
      "bDestroy": true,
      data: this.activeUserArr,
      responsive: true,
      bAutoWidth: false,
      paging: false,
      scrollY: "calc(100vh - 19vw)",
      scrollCollapse: true,
      info: false,
      order: [[1, "asc"]],
      columns: [
        {
          title: '', data: '', sWidth: '6%',
          bSearchable: false,
          bSortable: false,
          render: function () {
            return "<a class='imgicontable' id='editUserIcon'><i class='fa fa-edit imageicon' title='Edit'></i></a>&nbsp;<a class='imgicontable' id='deActivateUserIcon'><i class='fa fa-user-o deactivateuser' imageicon aria-hidden='true' title='Deactivate User'></i></a>"
          }
        },
        { title: 'First Name', data: 'firstName', sWidth: '16%' },
        { title: 'Last Name', data: 'lastName', sWidth: '16%' },
        { title: 'Email ID', data: 'emailId', sWidth: '20%' },
        {
          title: 'Creation Date', data: 'creationDate', sWidth: '12%',
          render: (data) => {
            return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMdd') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy');
          }
        },
        {
          title: 'Roles', data: 'roles', sWidth: '30%',
          render: (data: any) => {
            let roles = []
            for (let i = 0; i < data.length; i++) {
              roles.push(this.commonService.camelCase(data[i]).replace("_", " "));
            }
            return roles.join(", ");
          }
        }
      ]
    };
    this.dataTable = $(this.table.nativeElement);
    let table = this.dataTable.DataTable(this.dtOptions);
  }
  getInactiveUsers() {
    if (this.dataTable) {
      this.dataTable.DataTable().destroy();
      this.dataTable.empty();
    }
    this.dtOptions = {
      "bDestroy": true,
      data: this.inactiveUserArr,
      responsive: true,
      bAutoWidth: false,
      paging: false,
      scrollY: "67vh",
      scrollCollapse: true,
      info: false,
      order: [[1, "asc"]],
      columns: [
        {
          title: '', data: '', sWidth: '4%',
          bSearchable: false,
          bSortable: false,
          render: function () {
            return "<a class='imgicontable' id='activateUserIcon'><i class='fa fa-user-o' imageicon aria-hidden='true' title='Activate User'></i></a>"
          }
        },
        { title: 'First Name', data: 'firstName', sWidth: '17%' },
        { title: 'Last Name', data: 'lastName', sWidth: '17%' },
        { title: 'Email ID', data: 'emailId', sWidth: '20%' },
        {
          title: 'Creation Date', data: 'creationDate', sWidth: '12%',
          render: (data) => {
            return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMdd') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy');
          }
        },
        {
          title: 'Roles', data: 'roles', sWidth: '30%',
          render: (data: any) => {
            let roles = []
            for (let i = 0; i < data.length; i++) {
              roles.push(this.commonService.camelCase(data[i]).replace("_", " "));
            }
            return roles.join(", ");
          }
        }
      ]
    };
    this.dataTable = $(this.table.nativeElement);
    let table = this.dataTable.DataTable(this.dtOptions);
  }

  getLoggedInUsers() {
    if (this.dataTable) {
      this.dataTable.DataTable().destroy();
      this.dataTable.empty();
    }
    this.dtOptions = {
      "bDestroy": true,
      data: this.loggedInUserArr,
      responsive: true,
      bAutoWidth: false,
      paging: false,
      scrollY: "67vh",
      scrollCollapse: true,
      info: false,
      order: [[1, "asc"]],
      columns: [
        {
          title: '', data: '', sWidth: '4%',
          bSearchable: false,
          bSortable: false,
          render: function () {
            return ""
          }
        },
        { title: 'First Name', data: 'firstName', sWidth: '17%' },
        { title: 'Last Name', data: 'lastName', sWidth: '17%' },
        { title: 'Email ID', data: 'emailId', sWidth: '20%' },
        {
          title: 'Creation Date', data: 'creationDate', sWidth: '12%',
          render: (data) => {
            return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMdd') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy');
          }
        },
        {
          title: 'Roles', data: 'roles', sWidth: '30%',
          render: (data: any) => {
            let roles = []
            for (let i = 0; i < data.length; i++) {
              roles.push(this.commonService.camelCase(data[i]).replace("_", " "));
            }
            return roles.join(", ");
          }
        }
      ]
    };
    this.dataTable = $(this.table.nativeElement);
    let table = this.dataTable.DataTable(this.dtOptions);
  }

  showCreateUserModal(param, userData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      userType: param,
      userData: userData
    }
    const dialogref = this.dialog.open(UserCreateComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe(result => {
      if (result) {
        this.rerender(this.isAciveUser)
      }
    });
  }

  showDeleteUserModal(userData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Delete Configuration",
      confirmMsg: this.translate.instant("GENERIC_MSG.USER.DELETE_USER"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        let index = this.activeUserArr.map(e => {
          return e.userid
        }).indexOf(userData.userid);
        if (index > -1) {
          this.activeUserArr.splice(index, 1);
          this.getActiveUsers();
        } else {
          let inactiveIndex = this.inactiveUserArr.map(e => {
            return e.userid
          }).indexOf(userData.userid);
          if (inactiveIndex > -1) {
            this.inactiveUserArr.splice(inactiveIndex, 1);
            this.getInactiveUsers();
          }
        }
      }
    });
  }
  showActivateUserModal(userData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Activate User",
      confirmMsg: this.translate.instant("GENERIC_MSG.USER.ACTIVATE_USER"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        let editData = {
          "email": userData.emailId,
          "roles": userData.roles,
          "firstName": userData.firstName,
          "lastName": userData.lastName,
        }
        // this.usersService.editUser(editData).subscribe((res: any) => { */
        this.usersService.activateUser(editData).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          this.rerender(this.isAciveUser);
        });
      }
    });
  }
  showDeactivateUserModal(userData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Deactivate User",
      confirmMsg: this.translate.instant("GENERIC_MSG.USER.DEACTIVATE_USER"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        let editData = {
          "email": userData.emailId,
          "roles": userData.roles,
          "active": false,
          "firstName": userData.firstName,
          "lastName": userData.lastName
        }
        this.usersService.editUser(editData).subscribe((res: any) => {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.USER.USER_DEACTIVATED"));
          this.rerender(this.isAciveUser);
        });
      }
    });
  }

}