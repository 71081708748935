import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inputError'
})
export class InputErrorPipe implements PipeTransform {

    transform(value: any, arg1): string {
        let rvalue: string = '';
        if (value !== null) {
            switch (arg1) {
                case 'meetingTitle': return value == true ? rvalue = 'GENERIC_MSG.CONSULTATION.MEETING_TITLE_REQUIRED' : null;            
            }
        }
    }
}